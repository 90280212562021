import { gql } from '@apollo/client';

export const GET_ENTITIES = gql`
  query GetEntities($page: Int, $limit: Int) {
    entities(page: $page, limit: $limit) {
      collection {
        id
        shortLegalName
        tin
        psrn
        trrc
        createdAt
      }
      metadata {
        totalCount
      }
    }
  }
`;