import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink, useLocation } from '@reach/router';

import { formatDate } from 'utils/dateTimeFormatters';

const VERSIONS_LIMIT = 5;

QuestionnaireVersions.propTypes = {
  entityId: PropTypes.string,
  questionnaireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  versions: PropTypes.object,
};

export default function QuestionnaireVersions({
  entityId,
  questionnaireId,
  versions,
}) {
  const location = useLocation();
  const currentURL = useMemo(
    () => [location.pathname, location.search].join(''),
    [location],
  );

  const [collapsed, setCollapsed] = useState(versions.length > VERSIONS_LIMIT);
  const handleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);
  const versionsCount = useMemo(
    () => versions.length > VERSIONS_LIMIT && collapsed
      ? VERSIONS_LIMIT
      : versions.length,
    [versions, collapsed],
  );

  return (
    <Box sx={{ mt: 3 }}>
      {versions.slice(0, versionsCount).map((version, idx) => {
        const versionURL = [
          `/entities/${entityId}/questionnaires/${questionnaireId}`,
          version?.version ? `?version=${version?.version}` : ''
        ].join('');

        return (
          <Box key={version?.version || idx} sx={{ mt: 2 }}>
            <Typography sx={{ display: 'inline' }} variant="subtitle2">
              Изменил(а)&nbsp;
              <Link
                component={RouterLink}
                sx={{
                  color: currentURL === versionURL ? '#ff6600' : '#1771e6'
                }}
                to={`${questionnaireId}?version=${version?.version}`}
                underline="hover"
              >
                {version?.createdBy?.contact?.fullName}&nbsp;
                {formatDate(version?.createdAt)}
                {currentURL === versionURL ? ' ▷' : ''}
              </Link>
            </Typography>
          </Box>
        );
      })}
      {versions.length > VERSIONS_LIMIT && (
        <Typography
          onClick={handleCollapse}
          sx={{ color: collapsed ? '#1771e6' : 'inherit', cursor: 'pointer' }}
          variant="caption"
        >
          {collapsed ? 'все версии' : 'свернуть'}
        </Typography>
      )}
    </Box>
  );
}