import { gql } from '@apollo/client/core';

export const CREATE_NOTES_TASK_TODO = gql`
  mutation NoteCreate(
    $body: String!
    $targetId: ID!
    $priority: NotePriority!
    $targetType: NoteTargetType!
  ) {
    noteCreate(
      body: $body
      targetId: $targetId
      targetType: $targetType
      priority: $priority
    ) {
        note {
          id
          body
          createdAt
          priority
          author {
            ... on User {
              avatar
              roles
              displayName
              contact {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
`;
