export const HOME_PATH = '/';

export const APPLICATIONS_PATH = '/applications';
export const APPLICATION_PATH = '/applications/:applicationId';

export const TASKS_PATH = '/tasks';
export const TASK_PATH = '/tasks/:taskId';

export const PAYMENT_SCHEDULE_PATH = '/payment-schedule';

export const SUPPLYING_ORDERS_GENERATE_DOCUMENT_PATH = '/supplying_orders/documents/create';

export const BANK_STATEMENTS_PATH = '/entities/:entityId/bank_statements';
export const BANK_STATEMENT_PATH = '/entities/:entityId/bank_statements/:bankStatementId';
export const BANK_STATEMENT_ORDERS_PATH =
  '/entities/:entityId/bank_statements/:bankStatementId/orders';
