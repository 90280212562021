import PropTypes from 'prop-types';
import React from 'react';

import { Box, Stack, Typography } from '@material-ui/core';
import FileUploadRoundedIcon from '@material-ui/icons/FileUploadRounded';

DropzoneDocuments.propTypes = {
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  isDragActive: PropTypes.bool,
};

export default function DropzoneDocuments({
  getInputProps,
  getRootProps,
  isDragActive,
}) {
  return (
    <Box
      {...getRootProps()}
      sx={{
        p: 3,
        backgroundColor: '#fafafa',
        cursor: 'pointer',
        outline: 'none',
        transition: 'box-shadow .15s ease-in-out, border .24s ease-in-out',

        '&:hover': {
          boxShadow: 'inset 0 1px 10px 1px rgba(208, 208, 208, 0.5)'
        },

        ...(isDragActive
          ? { boxShadow: 'inset 0 1px 10px 1px rgba(208, 208, 208, 0.5)' }
          : {}
        ),
      }}
    >
      <input {...getInputProps()} />
      <Stack direction="row" spacing={3}>
        <FileUploadRoundedIcon
          color="action"
          sx={{ ...(isDragActive ? { color: '#FF6600' } : {}) }}
        />
        <Typography
          color={isDragActive ? 'primary' : 'text.secondary'}
          variant="body1"
        >
          {isDragActive ? 'Отпустите для прикрепления' : 'Приложить документы'}
        </Typography>
      </Stack>
    </Box>
  );
}