import PropTypes from 'prop-types';
import React from 'react';

import { Link, Tooltip, Typography } from '@material-ui/core';

CellClassificators.propTypes = {
  row: PropTypes.shape({
    application: PropTypes.shape({
      id: PropTypes.string,
    }),
    classificators: PropTypes.array,
    description: PropTypes.string,
  }),
};
export default function CellClassificators({ row }) {
  if (!row) return null;
  const { application, classificators, description } = row;

  if (classificators?.length) {
    return (
      <>
        {classificators.map(({ code, name }) => (
          <Tooltip key={code} title={`${code}: ${name}`}>
            <Link
              href={`${process.env.REACT_APP_HOST}/applications/${application.id}/supplying_orders`}
              rel="noreferrer"
              target="_blank"
              variant="inherit"
            >
              <Typography component="div" noWrap variant="caption">
                {code}: {name}
              </Typography>
            </Link>
          </Tooltip>
        ))}

        <Typography component="i" noWrap variant="caption">
          {description}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Link
        href={`${process.env.REACT_APP_HOST}/applications/${application.id}/supplying_orders`}
        rel="noreferrer"
        target="_blank"
        variant="inherit"
      >
        Указать классификацию
      </Link>
      <br />
      <Typography component="i" noWrap variant="caption">
        {description}
      </Typography>
    </>
  );
}
