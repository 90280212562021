import React from 'react';

import { Box } from "@material-ui/core";

export default function PageTemplate() {
  return (
    <Box>
      Вкладка Шаблоны документов в разработке.
    </Box>
  )
}
