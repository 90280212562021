export default [
  {
    id: '1',
    name: 'Злые марсиане',
    inn: 7718958917,
  },
  {
    id: '2',
    name: 'Красиво будет дизайн',
    inn: 6674305031,
  },
  {
    id: '3',
    name: 'Академия психоголубетерапии - лечебный голубь',
    inn: 2341980057,
  },
].map((org) => ({ ...org, __typename: 'Organization' }));
