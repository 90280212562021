import PropTypes from "prop-types";
import React, {useEffect} from 'react';

import { useLazyQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  SvgIcon,
  Typography
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { GET_INVOICE_PDF } from "../graphql/queries/getInvoicePdf";
import { ReactComponent as IconDownload } from '../img/iconDownload.svg';

InvoiceNumberPDF.propTypes = {
  row: PropTypes.object,
  setMessageError: PropTypes.func,
  showErrors: PropTypes.func,
};

export default function InvoiceNumberPDF({ row, setMessageError, showErrors }) {
  const [getPDF, { data, error, loading }] = useLazyQuery(GET_INVOICE_PDF);

  const handlerViewingDocument = () => {
    getPDF({
      variables: {
        invoiceId: Number(row.id)
      },
    })
  }

  useEffect(() => {
    if (data?.invoicePdf?.base64Data) {
      const linkSource = `data:application/pdf;base64,${data.invoicePdf.base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = data.invoicePdf.base64Filename;
      downloadLink.click();
    }
  }, [data]);

  useEffect(() => {
    if (error && error.message) {
      showErrors();
      setMessageError(error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const setColor = status => {
    let color;

    switch (status){
      case "TRASH":
        color = "#ff0000";
        break;

      case "SALE":
        color = "#3651de";
        break;

      default:
        color = "#67BF3D";
    }

    return color;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {row.number}

        {loading ? (
          <Box sx={{ marginLeft: "5px" }}>
            <CircularProgress size={10} />
          </Box>
        ) : (
          <SvgIcon component={IconDownload} inheritViewBox onClick={handlerViewingDocument} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
        )}
      </Box>

      <Typography sx={{color: setColor(row.status) }}>
        <FormattedMessage id={'shop.order.invoice.status.' + row.status} />
      </Typography>
    </Box>
  )
}