import { gql } from '@apollo/client';

export const GET_VERIFICATION_PROCESS = gql`
  query GetVerificationProcess {
    verificationProcess {
      id
      resolutionText
      state
      application {
        id
        requestedAmount
        borrowerEntity {
          id
          legalName
          contacts {
            id
            email
            firstName
            middleName
            lastName
            fullName @client
            fsspCheckProcesses {
              id
              createdAt
              finishedAt
              itemsCount
              state
              unpaidItemsCount
              unpaidSum
            }
          }
        }
      }
    }
  }
`;