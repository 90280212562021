import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {
  Box,
  Typography,
  Link
} from '@material-ui/core';

import AvatarUser from "../../components/AvatarUser";
import Phone from "../../components/Phone";
import WazzupLink from "../../components/WazzupLink";

UserInformation.propTypes = {
  onToggleModal: PropTypes.func,
  row: PropTypes.object,
};

export default function UserInformation({ onToggleModal, row }) {
  let contact = row.contact;

  const [show, setShow] = useState(true);
  const onShowModal = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }

    if (onToggleModal && typeof onToggleModal === 'function') {
      onToggleModal(show, row?.id)
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
      <Box onClick={onShowModal} sx={{ cursor: 'pointer' }}>
        <AvatarUser avatar={row.avatar} firstName={contact?.firstName} lastName={contact?.lastName} />
      </Box>

      <Box sx={{marginLeft: '20px'}}>
        <Link href={`/collaborators/holiday/${row?.id}`}>
          <Typography>
            {contact?.firstName ? contact.firstName : null}
            {" "}
            {contact?.middleName ? contact.middleName : null}
            {" "}
            {contact?.lastName ? contact.lastName : null}
          </Typography>
        </Link>

        {contact?.email && (
          <Link
            href={`mailto:${contact?.email}`}
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            {contact?.email}
          </Link>
        )}

        {contact?.phone && (
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Typography sx={{ color: '#6E7884', marginRight: '4px', }}>
              Тел:
            </Typography>

            <Typography>
              <Phone phone={contact?.phone} />
            </Typography>

            <WazzupLink phone={contact?.phone} />
          </Box>
        )}
      </Box>
    </Box>
  );
}