/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';

import { InlineEdit } from 'components/InlineEdit';
import Prompt from 'components/Prompt';
import TemporalAlert from 'components/TemporalAlert';

import {
  TAXATION_TYPES,
} from './constants';
import {
  ENTITY_UPDATE_CONTACT_QUESTIONNAIRE,
} from '../graphql/mutations/entityUpdateContactQuestionnaire';

function Empty() {
  return <Box sx={{ color: 'text.disabled' }}>---</Box>;
}

function renderTypographyBoolean(value) {
  if (typeof value === 'undefined' || value === null) {
    return <Empty />;
  }

  return (
    <Typography sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
      <FormattedMessage id={`${value}`} />
    </Typography>
  );
}

function renderTypographyBooleanMarital(value) {
  if (typeof value === 'undefined' || value === null) {
    return <Empty />;
  }

  return (
    <Typography sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
      <FormattedMessage id={`entity.questionnaire.ceo_marital_status_${value}`} />
    </Typography>
  );
}

export default function QuestionnaireData({ entity, questionnaire: data, refetch }) {
  const ceos = entity.staff.filter(({ position }) => position === 'ceo');

  const [questionnaire, setQuestionnaire] = useState(data);
  const [persistQuestionnaire, setPersistQuestionnaire] = useState(data);
  const [isEditMode, setEditMode] = useState(false);

  const useStyles = makeStyles((theme) => {
    const row = {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 16,
      alignItems: 'center',
      padding: '4px 0',
      borderBottom: '1px solid lightgray',
    };

    return {
      finishEditButton: {
        marginLeft: 16,
      },
      row: {
        ...row,
        '&:hover': {
          backgroundColor: isEditMode ? theme.palette.grey[100] : 'transparent',
        },
      },
      rowHeader: {
        ...row,
        position: 'sticky',
        top: 0,
        marginTop: 20,
        backgroundColor: 'rgba(255, 255, 255, 0.92)',
        padding: '16px 0',
        fontWeight: 600,
        zIndex: 1,
      },
      selectMenu: {
        whiteSpace: 'normal',
      },
    };
  });

  const classes = useStyles();

  const handleStartEdit = useCallback(() => setEditMode(true), [setEditMode]);
  const handleFinishEdit = useCallback(() => {
    setEditMode(false);
    setQuestionnaire(persistQuestionnaire);
  }, [setEditMode, setQuestionnaire, persistQuestionnaire]);

  const handleChange = useCallback((fieldName) => (value) => {
    setQuestionnaire({ ...questionnaire, [fieldName]: value });
  }, [questionnaire, setQuestionnaire]);

  const handleSelect = useCallback((fieldName) => ({ target: { value } }) => {
    setQuestionnaire({ ...questionnaire, [fieldName]: value });
  }, [questionnaire, setQuestionnaire]);

  const [update, {
    data: updatedData,
    error: updateError,
    loading: isUpdateInProgress,
  }] = useMutation(
    ENTITY_UPDATE_CONTACT_QUESTIONNAIRE,
    {
      variables: {
        questionnaireId: questionnaire.id,
        attributes: questionnaire,
      },
      onCompleted: (res) => {
        const newData =
          res?.entityUpdateContactQuestionnaire?.contactEntityQuestionnaire?.data;

        if (newData) {
          setPersistQuestionnaire(newData);
          refetch();
        }
      },
      onError: (error) => {
        console.log(
          '🚀 ~ file: QuestionnaireData.jsx ~ line 133 ~ QuestionnaireData ~ error',
          error,
        );
        alert(`Произошла ошибка: ${error.message}`);
      },
    },
  );

  const handleUpdate = useCallback(() => {
    setEditMode(false);
    update();
  }, [setEditMode, update]);

  const isUpdateButtonDisabled =
    isEqual(persistQuestionnaire, questionnaire) || isUpdateInProgress;

  return (
    <Prompt
      message="Вы находитесь в режиме редактирования анкеты!"
      when={isEditMode && !isEqual(persistQuestionnaire, questionnaire)}
    >
      <Box>
        <Box className={classes.rowHeader}>
          <Box>
            <Box>
              {isEditMode && (
                <Button
                  disabled={isUpdateButtonDisabled}
                  onClick={handleUpdate}
                  variant="outlined"
                >
                  Сохранить
                </Button>
              )}
              {!isEditMode && <Button onClick={handleStartEdit}>Изменить</Button>}
              {isEditMode && (
                <Button
                  className={classes.finishEditButton}
                  color="info"
                  onClick={handleFinishEdit}
                  variant="outlined"
                >
                  Отменить
                </Button>
              )}
            </Box>
            <Box>
              {!isUpdateInProgress && updatedData && (
                <TemporalAlert severity="success" title="Анкета сохранена" />
              )}
              {!isUpdateInProgress && updateError && (
                <TemporalAlert
                  message={updateError.message}
                  severity="error"
                  title="Ошибка при сохранении"
                />
              )}
            </Box>
          </Box>
          <Box>АНКЕТА</Box>
          <Box>CRMPRO</Box>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.taxation_type" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('taxation_type')}
              sx={{ width: '100%' }}
              value={questionnaire.taxation_type}
            >
              {Object.entries(TAXATION_TYPES).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          ) : (
            <Typography>
              <FormattedMessage id={`taxation.${questionnaire.taxation_type}`} />
            </Typography>
          )}
          <Typography>
            <FormattedMessage id={`taxation.${entity.taxationType}`} />
          </Typography>
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.okveds" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('okveds')}
                type="text"
                value={questionnaire.okveds}
              />
            ) : questionnaire.okveds || <Empty />}
          </Typography>
          <Typography>{entity.okveds || <Empty />}</Typography>
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.description" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('description')}
                type="text"
                value={questionnaire.description}
              />
            ) : questionnaire.description || <Empty />}
          </Typography>
          <Typography>{entity.description || <Empty />}</Typography>
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.trademark" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('trademark')}
                type="text"
                value={questionnaire.trademark}
              />
            ) : questionnaire.trademark || <Empty />}
          </Typography>
          <Typography>{entity.trademark || <Empty />}</Typography>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.employees_count" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('employees_count')}
                type="text"
                value={questionnaire.employees_count}
              />
            ) : questionnaire.employees_count || <Empty />}
          </Typography>
          <Typography>{entity.employeesCount || <Empty />}</Typography>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.site" />
          <Box>
            {isEditMode ? (
              <InlineEdit
                ViewComponent={<>{questionnaire.site || <Empty />}</>}
                onChange={handleChange('site')}
                type="text"
                value={questionnaire.site || ''}
              />
            ) : (questionnaire.site || <Empty />)}
          </Box>
          <Box>{entity.site || <Empty />}</Box>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.ceo_name" />
          {questionnaire.ceo_name_ip ? (
            <Box>
              {isEditMode ? (
                <InlineEdit
                  onChange={handleChange('ceo_name_ip')}
                  type="text"
                  value={questionnaire.ceo_name_ip}
                />
              ) : questionnaire.ceo_name_ip || <Empty />}
            </Box>
          ) : (
            <Box>
              {isEditMode ? (
                <InlineEdit
                  onChange={handleChange('ceo_name')}
                  type="text"
                  value={questionnaire.ceo_name}
                />
              ) : questionnaire.ceo_name || <Empty />}
            </Box>
          )}
          <Box>{ceos.map(({ contact }) => contact.fullName)}</Box>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.ceo_email" />
          <Box>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('ceo_email')}
                type="text"
                value={questionnaire.ceo_email}
              />
            ) : questionnaire.ceo_email || <Empty />}
          </Box>
          <Box>{ceos.map(({ contact }) => contact.email)}</Box>
        </Box>

        {questionnaire?.founders?.length > entity?.founders?.length ? (
          questionnaire?.founders?.map((founder, index) => {
            return (
              <Box className={classes.row} key={index}>
                <FormattedMessage id="entity.questionnaire.founder" /> {index + 1}
                <Box>
                  {founder?.name}
                  <Box sx={{ display: 'flex' }}>
                    Доля в компании: {founder?.share || <Empty />}
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    Почта: {founder?.email || <Empty />}
                  </Box>
                </Box>

                <Box>
                  {entity?.founders[index] ? (
                    <>
                      {entity?.founders[index]?.name}
                      <Box sx={{ display: 'flex' }}>
                        Доля в компании: {entity?.founders[index]?.share || <Empty />}
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        Почта: {entity?.founders[index]?.email || <Empty />}
                      </Box>
                    </>
                  ) : (
                    <Empty />
                  )}
                </Box>
              </Box>
            )
          })
        ) : (
          entity?.founders?.map((founder, index) => {
            return (
              <Box className={classes.row} key={index}>
                <FormattedMessage id="entity.questionnaire.founder" /> {index + 1}

                <Box>
                  {founder[index] ? (
                    <>
                      {founder[index]?.name}
                      <Box sx={{ display: 'flex' }}>
                        Доля в компании: {founder[index]?.share || <Empty />}
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        Почта: {founder[index]?.email || <Empty />}
                      </Box>
                    </>
                  ) : (
                    <Empty />
                  )}
                </Box>

                <Box>
                  {founder?.name}
                  <Box sx={{ display: 'flex' }}>
                    Доля в компании: {founder?.share || <Empty />}
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    Почта: {founder?.email || <Empty />}
                  </Box>
                </Box>
              </Box>
            )
          })
        )}

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.ceo_marital_status" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('ceo_marital_status')}
              sx={{ width: '100%' }}
              value={questionnaire.ceo_marital_status}
            >
              <MenuItem value="married">
                <FormattedMessage id="entity.questionnaire.ceo_marital_status_married" />
              </MenuItem>
              <MenuItem value="single">
                <FormattedMessage id="entity.questionnaire.ceo_marital_status_single" />
              </MenuItem>
            </Select>
          ) : renderTypographyBooleanMarital(questionnaire.ceo_marital_status)}
          {renderTypographyBooleanMarital(entity.ceoMaritalStatus)}
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.is_business_partner_exists" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('is_business_partner_exists')}
              sx={{ width: '100%' }}
              value={questionnaire.is_business_partner_exists}
            >
              <MenuItem value="true">Да</MenuItem>
              <MenuItem value="false">Нет</MenuItem>
            </Select>
          ) : renderTypographyBoolean(questionnaire.is_business_partner_exists)}
          {renderTypographyBoolean(entity.isBusinessPartnerExists)}
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.attorney_name" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('attorney_name')}
                type="text"
                value={questionnaire.attorney_name}
              />
            ) : questionnaire.attorney_name || <Empty />}
          </Typography>
          <Typography>{entity.attorneyName || <Empty />}</Typography>
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.beneficiary_name" />
          <Box>
            {isEditMode ? (
              <InlineEdit
                ViewComponent={<>{questionnaire.beneficiary_name || <Empty />}</>}
                onChange={handleChange('beneficiary_name')}
                type="text"
                value={questionnaire.beneficiary_name || ''}
              />
            ) : (questionnaire.beneficiary_name || <Empty />)}
          </Box>
          <Box>{entity.beneficiaryName || <Empty />}</Box>
        </Box>

        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.legal_address" />
          <Box>
            {isEditMode ? (
              <InlineEdit
                ViewComponent={<>{questionnaire.legal_address || <Empty />}</>}
                onChange={handleChange('legal_address')}
                type="text"
                value={questionnaire.legal_address || ''}
              />
            ) : (questionnaire.legal_address || <Empty />)}
          </Box>
          <Box>
            {entity.addresses
              .filter(({ type }) => type === 'legal')
              .map(({ fullAddress, id }, idx) => (
                <Box key={id || idx} sx={{ display: 'flex' }}>
                  {idx + 1}.&nbsp;{fullAddress || <Empty />}
                </Box>
              ))}
          </Box>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.actual_address" />
          <Box>
            {questionnaire.is_same_actual_address === "true"
              ? 'Совпадает с юридическим'
              : (
                isEditMode ? (
                  <InlineEdit
                    onChange={handleChange('actual_address')}
                    type="text"
                    value={questionnaire.actual_address}
                  />
                ) : questionnaire.actual_address || <Empty />
              )
            }
          </Box>
          <Box>
            {entity.addresses.filter(({ type }) => type === 'actual').length ?
              entity.addresses
                .filter(({ type }) => type === 'actual')
                .map(({ fullAddress, id }, idx) => (
                  <Box key={id || idx} sx={{ display: 'flex' }}>
                    {idx + 1}.&nbsp;{fullAddress || <Empty />}
                  </Box>
                )) : <Empty />
            }
          </Box>
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.is_other_business_points_exists" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('is_other_business_points_exists')}
              sx={{ width: '100%' }}
              value={questionnaire.is_other_business_points_exists}
            >
              <MenuItem value="true">Да</MenuItem>
              <MenuItem value="false">Нет</MenuItem>
            </Select>
          ) : renderTypographyBoolean(questionnaire.is_other_business_points_exists)}
          {renderTypographyBoolean(entity.isOtherBusinessPointsExists)}
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.entity_money_freeze" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('entity_money_freeze')}
              sx={{ width: '100%' }}
              value={questionnaire.entity_money_freeze}
            >
              <MenuItem value="true">Да</MenuItem>
              <MenuItem value="false">Нет</MenuItem>
            </Select>
          ) : renderTypographyBoolean(questionnaire.entity_money_freeze)}
          {renderTypographyBoolean(entity.entity_money_freeze)}
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.money_freeze" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('money_freeze')}
              sx={{ width: '100%' }}
              value={questionnaire.money_freeze}
            >
              <MenuItem value="true">Да</MenuItem>
              <MenuItem value="false">Нет</MenuItem>
            </Select>
          ) : renderTypographyBoolean(questionnaire.money_freeze)}
          {renderTypographyBoolean(entity.money_freeze)}
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.money_freeze_judge" />
          {isEditMode ? (
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              onChange={handleSelect('money_freeze_judge')}
              sx={{ width: '100%' }}
              value={questionnaire.money_freeze_judge}
            >
              <MenuItem value="true">Да</MenuItem>
              <MenuItem value="false">Нет</MenuItem>
            </Select>
          ) : renderTypographyBoolean(questionnaire.money_freeze_judge)}
          {renderTypographyBoolean(entity.money_freeze_judge)}
        </Box>
        <Box className={classes.row}>
          <FormattedMessage id="entity.questionnaire.ipdl" />
          <Typography>
            {isEditMode ? (
              <InlineEdit
                onChange={handleChange('ipdl')}
                type="text"
                value={questionnaire.ipdl}
              />
            ) : questionnaire.ipdl || <Empty />}
          </Typography>
          <Typography>{entity.ipdl || <Empty />}</Typography>
        </Box>
      </Box>
    </Prompt>
  );
}