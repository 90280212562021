import PropTypes from 'prop-types';
import React, {Fragment, useMemo, useState} from 'react';

import { useSubscription } from '@apollo/client';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import StackedBarChartIcon from '@material-ui/icons/StackedBarChart';
import { Link } from '@reach/router';
import _groupBy from 'lodash.groupby';
import _orderBy from 'lodash.orderby';
import { DateTime } from 'luxon';

import ErrorAlert from 'components/ErrorAlert';
import Tooltip from 'components/Tooltip';

import { getBankLogo } from './HeaderBankStatement';
import { STATUS_PROCESSING } from "../constants";
import {
  ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED,
} from '../graphql/subscriptions/entityBankStatementsListUpdated';

LayoutListBankAccounts.propTypes = {
  children: PropTypes.node,
};
function LayoutListBankAccounts({ children }) {
  return (
    <Box
      sx={{
        bgcolor: '#F4F5F7',
        borderRadius: '4px',
        flexShrink: 0,
        mr: 2,
        pb: 2,
        px: 2,
        width: 1,
        maxWidth: '600px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar variant="dense">
        <Box alignSelf="center" flexGrow={1}>
          {/* FIXME: to locales */}
          <Typography variant="h4">Банки и счета</Typography>
        </Box>
      </Toolbar>
      <Box
        sx={{
          bgcolor: 'white',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '4px',
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

ListBankAccounts.propTypes = {
  entityId: PropTypes.string,
};
export default function ListBankAccounts({ entityId }) {
  const { data, error, loading, refetch } = useSubscription(
    ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED,
    { variables: { entityId } },
  );

  const groupsByBankByAccount = useMemo(() => {
    if (data) {
      const { bankStatementAccounts } = data.entityBankStatementsListUpdated;
      const groupsByBank = _groupBy(bankStatementAccounts, 'bank.bic');
      const sortedGroups = _orderBy(
        Object.values(groupsByBank),
        '[0].bankStatement.info.endDate',
        ['desc'],
      );

      const groupsByBankByAccount = Object.values(sortedGroups).map((group) =>
        Object.values(_groupBy(group, 'number')),
      );

      return groupsByBankByAccount;
    }

    return [];
  }, [data]);

  const [disabledEdit, setDisabledEdit] = useState(false);

  groupsByBankByAccount.forEach(group => {
    group.forEach(banks => {
      banks.forEach(bank => {
        if (STATUS_PROCESSING.includes(bank?.bankStatement?.status)) {
          if (disabledEdit) return false;

          setDisabledEdit(true);
        }
      })
    })
  });

  if (error) {
    return (
      <ErrorAlert
        action={
          <Button
            color="inherit"
            disabled={loading}
            onClick={() => refetch()}
            variant="outlined"
          >
            Повторить запрос
          </Button>
        }
        message={error.message}
        title="Ошибка при получении данных"
      />
    );
  }

  if (loading) {
    return (
      <LayoutListBankAccounts>
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
      </LayoutListBankAccounts>
    );
  }

  if (!groupsByBankByAccount.length) {
    return (
      <LayoutListBankAccounts>
        <Typography sx={{ color: 'text.secondary', p: 3 }}>
          Счетов не найдено
        </Typography>
      </LayoutListBankAccounts>
    );
  }

  return (
    <LayoutListBankAccounts>
      <List>
        {groupsByBankByAccount.map((groupByBank) => (
          <Fragment key={groupByBank[0][0].bank.bic}>
            <ListItem dense>
              <ListItemAvatar>
                <Box height={40} mr={2}>
                  <img
                    alt="Logo"
                    height={40}
                    src={getBankLogo(groupByBank[0][0].bank.title)}
                    width={40}
                  />
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={groupByBank[0][0].bank.title}
                primaryTypographyProps={{ noWrap: true }}
                secondary={`БИК ${groupByBank[0][0].bank.bic}`}
              />
            </ListItem>
            <List component="div" disablePadding>
              {groupByBank.map((acc) => (
                <Fragment key={acc[0].number}>
                  <ListItem dense sx={{ pl: 7 }}>
                    <ListItemIcon>
                      <Tooltip
                        title={
                          { 810: 'Рублевый счёт', 840: 'Долларовый счёт', 978: 'Евро счёт' }[
                          /^(\d{5})(\d{3})(\d{1})(\d{4})(\d{7})$/.exec(acc[0].number)[2]
                          ]
                        }
                      >
                        <Typography sx={{ textAlign: 'center', width: '100%' }} variant="h3">
                          {
                            { 810: '₽', 840: '$', 978: '€' }[
                            /^(\d{5})(\d{3})(\d{1})(\d{4})(\d{7})$/.exec(acc[0].number)[2]
                            ]
                          }
                        </Typography>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary={acc[0].number}
                      primaryTypographyProps={{
                        noWrap: true,
                        color: 'textSecondary',
                      }}
                    />
                  </ListItem>

                  <List component="div" disablePadding>
                    {acc.map(({ bankStatement, id }) => (
                      <ListItem
                        button
                        component={Link}
                        key={id}
                        state={{
                          disabledEdit: disabledEdit,
                        }}
                        sx={{ pl: 7 }}
                        to={id}
                      >
                        <ListItemText
                          inset
                          primary={
                            <Box
                              sx={{ display: 'flex', alignItems: 'flex-end' }}
                            >
                              <StackedBarChartIcon
                                color="primary"
                                sx={{ fontSize: '18px', mr: 1 }}
                              />
                              <Typography
                                color="textSecondary"
                                noWrap
                                sx={{ overflow: 'hidden', lineHeight: 1 }}
                                variant="subtitle2"
                              >
                                За&nbsp;
                                {(
                                  DateTime.fromISO(bankStatement.info.endDate)
                                    .toRelative({
                                      base: DateTime.fromISO(bankStatement.info.startDate),
                                    }) || ''
                                ).replace('через ', '')}
                                &nbsp;c{' '}
                                <Box
                                  color="text.primary"
                                  component="span"
                                  fontWeight={500}
                                >
                                  {DateTime.fromISO(bankStatement.info.startDate)
                                    .toLocaleString(DateTime.DATE_SHORT)}
                                </Box>
                                {' по '}
                                <Box
                                  color="text.primary"
                                  component="span"
                                  fontWeight={500}
                                >
                                  {DateTime.fromISO(bankStatement.info.endDate)
                                    .toLocaleString(DateTime.DATE_SHORT)}
                                </Box>{' '}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Fragment>
              ))}
            </List>
          </Fragment>
        ))}
      </List>
    </LayoutListBankAccounts>
  );
}
