import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import CompanyCategoryMarker from './CompanyCategoryMarker';
import { CATEGORIES } from '../constants';
import {
  BANK_STATEMENT_SET_CONTRACT_CATEGORY,
} from '../graphql/mutations/bankStatementSetContractorCategory';

CompanyCategorySelector.propTypes = {
  children: PropTypes.func,
  contractorId: PropTypes.string,
  defaultCategory: PropTypes.string,
  disabledEdit: PropTypes.bool,
};

export default function CompanyCategorySelector({
  children,
  contractorId,
  defaultCategory,
  disabledEdit
}) {
  const [setCategory] = useMutation(BANK_STATEMENT_SET_CONTRACT_CATEGORY, {
    onError: (error) => {
      alert(`Произошла ошибка при установке категории: ${error.message}`);
    },
  });
  // const client = useApolloClient();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(defaultCategory);

  const handleClickListItem = (event) => {
    if (disabledEdit) {
      return false;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, category) => {
    setSelectedOption(category);
    setCategory({ variables: { contractorId, category } });
    // client.writeFragment({
    //   id: client.cache.identify({
    //     __typename: 'BankStatementContractor',
    //     tin,
    //     category,
    //   }),
    //   fragment: gql`
    //     fragment BankStatementContractorCategory on BankStatementContractor {
    //       category
    //     }
    //   `,
    //   data: {
    //     category,
    //   },
    // });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {children ? (
        children({ onClick: handleClickListItem })
      ) : (
        <ButtonBase onClick={handleClickListItem} sx={{ cursor: disabledEdit ? 'default' : 'pointer' }}>
          <CompanyCategoryMarker bgcolor={`categories.${selectedOption}`} />
        </ButtonBase>
      )}

      <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
        {CATEGORIES.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(event) => handleMenuItemClick(event, option)}
            selected={index === selectedOption}
          >
            <Box
              sx={{
                bgcolor: `categories.${option}`,
                borderRadius: '50%',
                flexShrink: 0,
                height: 16,
                mr: 3,
                width: 16,
              }}
            />
            <FormattedMessage id={`categories.${option}`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
