import { gql } from '@apollo/client/core';

export const FEDRESURS_GENERATE_REPORTING_MESSAGES = gql`
  mutation FedresursGenerateXmlByReportingMessages(
    $fedresursReportingMessageIds: [ID!]!
  ) {
    fedresursGenerateXmlByReportingMessages(
      fedresursReportingMessageIds: $fedresursReportingMessageIds
    ) {
      fedresursReportingMessagesExport {
        id
        xmlFileUrl
        reportingMessagesIds
      }
    }
  }
`;