import PropTypes from 'prop-types';
import React from 'react';

import { IconButton } from '@material-ui/core';
import CommentOutlined from "@material-ui/icons/CommentOutlined";

ShowCommentTask.propTypes = {
  row: PropTypes.object,
  showModalComments: PropTypes.func,
};

export default function ShowCommentTask({ row, showModalComments }) {

  return (
    <IconButton
      disabled={row.notes.length === 0}
      onClick={showModalComments.bind(this, row.notes)}
      sx={{height: '30px', width: '30px', padding: '4px 0 0 0'}}
    >
      <span>
        <CommentOutlined sx={{ fontSize: '20px !important' }} />
      </span>
    </IconButton>
  );
}