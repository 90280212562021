export function addNumbersForMonthlyPayments(records) {
  let monthlyPaymentNumber = 0;

  return records.map((record) => {
    if (record.type === 'monthly') {
      monthlyPaymentNumber++;
      return { ...record, monthlyPaymentNumber };
    }
    return { ...record, monthlyPaymentNumber: null };
  });
}
