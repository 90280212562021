import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENT_AVAILABLE_ORDERS_FOR_ASSIGN = gql`
  query SourceDocumentAvailableOrdersForAssign(
    $lesseeId: ID!,
    $supplierId: ID!
  ) {
    sourceDocumentAvailableOrdersForAssign(
      lesseeId: $lesseeId,
      supplierId: $supplierId
    ) {
      displayNameForSourceDocumentsAssign
      id
    }
  }
`;