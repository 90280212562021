import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';

import CurrencyFormatter from 'components/CurrencyFormatter';

BlockAccountState.propTypes = {
  endRemainder: PropTypes.number,
  startRemainder: PropTypes.number,
  totalCredit: PropTypes.number,
  totalDebit: PropTypes.number,
};

export default function BlockAccountState({
  endRemainder,
  startRemainder,
  totalCredit,
  totalDebit,
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        fontSize: 16,
        gridColumnGap: 80,
        gridRowGap: 8,
        maxWidth: { xs: 375, lg: 720 },
      }}
    >
      <Box sx={{ display: 'flex', gridArea: '1/1', justifyContent: 'space-between' }}>
        <Box color="text.secondary">Поступления:</Box>
        <Box color="money.income" fontWeight={600}>
          <CurrencyFormatter value={totalDebit} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gridArea: '2/1', justifyContent: 'space-between' }}>
        <Box color="text.secondary">Платежи:</Box>
        <Box color="money.outcome" fontWeight={600}>
          <CurrencyFormatter value={totalCredit} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gridArea: { xs: '3/1', lg: '1/2' },
          justifyContent: 'space-between',
        }}
      >
        <Box color="text.secondary">Входящий остаток:</Box>
        <Box fontWeight={500}>
          <CurrencyFormatter value={startRemainder} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gridArea: { xs: '4/1', lg: '2/2' },
          justifyContent: 'space-between',
        }}
      >
        <Box color="text.secondary">Исходящий остаток:</Box>
        <Box fontWeight={500}>
          <CurrencyFormatter value={endRemainder} />
        </Box>
      </Box>
    </Box>
  );
}
