import { gql } from '@apollo/client/core';

export const CREATE_SCHEDULE_ITEM = gql`
  mutation FinanceCreateScheduleItem(
    $scheduleId: ID!,
    $item: ScheduleItemCreateInput!
  ) {
    financeCreateScheduleItem(scheduleId: $scheduleId, item: $item) {
      schedule {
        id
        items {
          key
          type
          baseAmount
          commissionAmount
          fullAmount
          vatAmount
          paid
          startAt
        }
      }
    }
  }
`;