import { gql } from '@apollo/client/core';

export const SHOP_GOODS_UPDATE = gql`
  mutation ShopGoodsUpdate (
    $goodId: ID!
    $params:ShopGoodUpdateParams!
  ) {
    shopGoodsUpdate(
      goodId: $goodId
      params: $params
    ) {
      good {
        description
        currentLocationAddress
        price
        vat
        vatPercent
        images {
          id
          file {
            id
            filename
            url
          }
        }
      }
    }
  }
`;