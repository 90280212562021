import React from 'react';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import inIframe from 'utils/inIframe';

export default function OpenInNewButton() {
  if (!inIframe()) {
    return null;
  }
  return (
    <Button
      href={`${process.env.REACT_APP_CLOUD_HOST}${window.location.pathname}${window.location.search}`}
      rel="noopener noreferrer"
      startIcon={<OpenInNewIcon />}
      sx={{zIndex: '2147483647'}}
      target="_blank"
      variant="text"
    >
      Открыть в новом окне
    </Button>
  );
}
