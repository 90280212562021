/* eslint-disable react/display-name, react/prop-types */
import React from 'react';

import { Link, Typography, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

import FormattedCurrency from "../../components/FormattedCurrency";
import Phone from "../../components/Phone";
import WazzupLink from "../../components/WazzupLink";

export const DEALS_PAGE_LIMIT = 20;

export const TABS = [
  'ALL',
  'INITIAL',
  'PROPOSAL',
  'SECURITY_CHECK',
  'PENDING',
  'AGREEMENT_WITH_SUPPLIER',
  'SIGNING',
  'PAYMENT',
  'READY_FOR_SHIPMENT',
  'SHIPMENT_COORDINATION',
  'SHIPPING',
  'PARTIALLY_SHIPPED',
  'DONE',
  'CLOSED'
];

export const COLUMN_EXTENSIONS = [
  {
    columnName: 'status_number',
    width: 160,
    wordWrapEnabled: true,
  },
  {
    columnName: 'action',
    width: 120,
    wordWrapEnabled: true,
  },
  {
    columnName: 'bid',
    width: 180,
    wordWrapEnabled: true,
  },
  {
    columnName: 'provider',
    width: 200,
    wordWrapEnabled: true,
  },
  {
    columnName: 'client',
    width: 250,
    wordWrapEnabled: true,
  },
  {
    columnName: 'partner',
    width: 230,
    wordWrapEnabled: true,
  },
  {
    columnName: 'relevance_delivery',
    width: 210,
    wordWrapEnabled: true,
  },
  {
    columnName: 'date',
    align: 'right',
    width: 110,
    wordWrapEnabled: false,
  },
];

export const COLUMNS_MODEL_CREATOR = [
  {
    name: 'status_number',
    title: '№ и Статус заказа',
    getCellValue: ({ application, id, number, status }) => {
      let bg = '';
      let color = '';

      switch (status) {
        case 'INITIAL':
          bg = '#F5EAFA';
          color = '#9B2ECE';

          break;
        case 'PROPOSAL':
          bg = '#ECEFF2';
          color = '#465B79';

          break;

        case 'SECURITY_CHECK':
          bg = '#CAE3EF';
          color = '#285673';

          break;
        case 'PENDING':
          bg = '#a8d6eb';
          color = '#0a506d';

          break;
        case 'AGREEMENT_WITH_SUPPLIER':
          bg = '#ADDB9E';
          color = '#386F40';

          break;
        case 'SIGNING':
          bg = '#ADDB9E';
          color = '#386F40';

          break;
        case 'PAYMENT':
          bg = '#b2e051';
          color = '#668d13';

          break;
        case 'READY_FOR_SHIPMENT':
          bg = '#D3DF3A';
          color = '#728212';

          break;
        case 'SHIPMENT_COORDINATION':
          bg = '#D3DF3A';
          color = '#728212';

          break;
        case 'SHIPPING':
          bg = '#f3dd23';
          color = '#7d7610';

          break;
        case 'DONE':
          bg = '#EAF5E6';
          color = '#299E00';

          break;
        case 'CLOSED':
          bg = '#FDEFEC';
          color = '#EE6344';

          break;
        case 'PARTIALLY_SHIPPED':
          bg = '#8ca94e';
          color = '#395007';

          break;
        default:
          bg = '';
          color = '';
      }

      return (
        <Box>
          <Link
            href={`${process.env.REACT_APP_HOST}/applications/${application.id}/supplying_orders/${id}`}
            sx={{
              marginBottom: '10px',
              display: 'inline-block'
          }}>
            {number}
          </Link>
          <Box sx={{
            background: bg,
            borderRadius: '3px',
            textAlign: 'center',
            padding: '4px 0',
            width: '120px',
            fontSize: '10px !important',
            lineHeight: '12px !important',
            color,
            fontWeight: 500,
          }}>
            <FormattedMessage id={`orders.messagesStatus.${status}`} />
          </Box>
        </Box>
      )
    },
  },
  {
    name: 'bid',
    title: 'Заявка',
    getCellValue: ({ application }) => {
      let bg = '',
          color = '';

      switch (application.status) {
        case 'ADDITIONAL_DOCUMENTS_REQUEST':
          bg = '#64a2bd';
          color = '#0a2834';

          break;
        case 'ADVANCE_PAYMENT_RECEIVED':
        case 'PARTIAL_PAYMENT':
          bg = '#8ca94e';
          color = '#395007';

          break;
        case 'APPROVED':
          bg = '#75803a';
          color = '#2f340c';

          break;
        case 'DEAL':
          bg = '#b0de81';
          color = '#456227';

          break;
        case 'EXTINGUISHED':
          bg = '#fab875';
          color = '#7c4710';

          break;
        case 'GOVERNANCEING':
          bg = '#bbb773';
          color = '#5b5718';

          break;
        case 'ISSUED':
          bg = '#6f934f';
          color = '#1e340b';

          break;
        case 'LEAD':
          bg = '#c3c3c3';
          color = '#5d5c5c';

          break;
        case 'LEASING_SALE':
          bg = '#2ec7ce';
          color = '#15585b';

          break;

        case 'PREAPPROVED':
          bg = '#9ba652';
          color = '#40480f';

          break;
        case 'PREPARING_DEAL':
          bg = '#6c672f';
          color = '#282505';

          break;
        case 'PRIMARY_CONSULTATION':
          bg = '#4adadf';
          color = '#256d70';

          break;

        case 'PROPOSAL_FAMILIARIZED':
        case 'PROPOSAL_SENT':
          bg = '#808080';
          color = '#3d3d3d';

          break;
        case 'TRASH':
          bg = '#f17761';
          color = '#721300';

          break;

        case 'VERIFICATION':
        case 'null':
        case 'undefined':
        case 'UNDERWRITING':
        case 'PREPARING_DOCUMENTS':
          bg = '#e0c1bb';
          color = '#a27770';

          break;
        default:
          bg = '';
          color = '';
      }

      return (
        <>
          <Link href={`${process.env.REACT_APP_HOST}/applications/${application?.id}`}>{application?.id}</Link>

          <Link href={`${process.env.REACT_APP_HOST}/contacts/${application?.contact?.id}`} sx={{ display: 'flex' }}>
            {application?.contact?.firstName}
            {" "}
            {application?.contact?.middleName}
            {" "}
            {application?.contact?.lastName}
          </Link>

          <Box
            sx={{
              background: bg,
              borderRadius: '3px',
              textAlign: 'center',
              padding: '4px',
              width: '120px',
              fontSize: '10px !important',
              lineHeight: '12px !important',
              color: color,
              fontWeight: 500,
              marginTop: '8px',
            }}
          >
            <FormattedMessage
              id={`application.substatus.${application.status}`}
            />
          </Box>
        </>
      )
    },
  },
  {
    name: 'provider',
    title: 'Поставщик',
    getCellValue: ({ supplier }) => {
      let entity = supplier?.bankDetails[supplier.bankDetails.length - 1]?.entity;

      return (
        <>
          {entity?.legalNameWithShortEntityType && (
            <Link href={`${process.env.REACT_APP_HOST}/entities/${supplier?.id}`}>
              <Typography>{entity?.legalNameWithShortEntityType}</Typography>
            </Link>
          )}

          {entity?.tin && (
            <Typography>ИНН: {entity.tin}</Typography>
          )}

          {entity?.site && (
            <Link href={entity.site} target="_blank">{entity.site}</Link>
          )}
        </>
      )
    },
  },
  {
    name: 'client',
    title: 'Клиент',
    getCellValue: ({ application }) => {
      let borrowerEntity = application?.borrowerEntity;
      let contact = application?.contact;

      return (
        <>
          {borrowerEntity?.contacts[0] && (
            <Link
              href={`${process.env.REACT_APP_HOST}/contacts/${borrowerEntity.contacts[0].id}`}
              sx={{ display: 'flex' }}>
              {borrowerEntity.contacts[0].firstName}
              {" "}
              {borrowerEntity.contacts[0].middleName}
              {" "}
              {borrowerEntity.contacts[0].lastName}
            </Link>
          )}

          <Link href={`${process.env.REACT_APP_HOST}/entities/${borrowerEntity?.id}`}>
            <Typography>{borrowerEntity?.legalNameWithShortEntityType}</Typography>
          </Link>

          {contact?.phone && (
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
              <Phone phone={contact?.phone} />

              <WazzupLink contactId={contact?.id} />
            </Box>
          )}
       </>
       )
    },
  },
  {
    name: 'partner',
    title: 'Партнерский менеджер',
    getCellValue: ({ arenzaManager }) => {
      if (arenzaManager?.contact?.id) {
        return <Link href={`${process.env.REACT_APP_HOST}/contacts/${arenzaManager.contact.id}`} target={'_blank'}>{arenzaManager?.displayName}</Link>
      } else {
        return <Typography sx={{ display: 'flex' }}>{arenzaManager?.displayName}</Typography>
      }
    },
  },
  {
    name: 'relevance_delivery',
    title: 'Актуальность поставки',
    getCellValue: ({ expiresAt }) => {
      if (expiresAt) {
        return (
          <Box sx={{ display: 'flex' }}>
            до <Typography sx={{ fontWeight: 500, marginLeft: '6px'}}>{formatDate(expiresAt)}</Typography>
          </Box>
        )
      } else {
        return '---'
      }
    },
  },
  {
    name: 'order_price',
    title: 'Сумма заказа',
    getCellValue: ({ amount, currency, vat }) => {
      if (amount) {
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500, }}>
                <FormattedCurrency style="decimal" value={amount} />
              </Typography>
              <Typography sx={{
                margin: 0,
                fontSize: '11px !important',
                background: '#E7E8EA',
                padding: '1px',
                borderRadius: '9px',
                height: '15px',
                width: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '5px'
              }}>
                {currency?.unit ? currency.unit : '₽'}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: '10px', fontWeight: '300', }}>(+НДС <FormattedCurrency style="decimal" value={amount * vat / 100} />)</Typography>
          </>
        )
      } else {
        return '---'
      }
    },
  },
  {
    name: 'date',
    title: 'Дата заказа',
    getCellValue: ({ createdAt, updatedAt }) => {
      return (
        <>
          <Typography sx={{ color: '#6E7884' }}>Создан:</Typography>
          <Typography>{createdAt? formatDate(createdAt) : '---'}</Typography>
          <Typography sx={{ color: '#6E7884' }}>Обновлен:</Typography>
          <Typography>{updatedAt ? formatDate(updatedAt) : '---'}</Typography>
        </>
      )
    },
  },
];

export const PAGING_PANEL_MESSAGES = {
  showAll: 'Все',
  rowsPerPage: 'Заказов на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Заказов нет';
    }

    return to === count
      ? `Заказов: ${to - from + 1} (всего ${count})`
      : `Заказы ${from}-${to} (всего ${count})`;
  },
};