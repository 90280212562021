import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Popover } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { DatePicker } from '@material-ui/pickers';
import _noop from 'lodash.noop';
import { DateTime } from 'luxon';

import EditableText from 'components/EditableText';

DateInlinePicker.propTypes = {
  ViewComponent: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function DateInlinePicker({
  ViewComponent,
  value = '',
  onChange = _noop,
}) {
  const [_value, setValue] = useState(DateTime.fromISO(value));
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  function handleChange(value) {
    setValue(value);
    onChange(value);
    setOpen(false);
    setAnchorEl(null);
  }

  function handleClose() {
    setOpen(false);
    setAnchorEl(null);
  }

  return (
    <>
      <EditableText onClick={handleClick}>
        {ViewComponent || _value.toLocaleString(DateTime.DATE_SHORT)}
      </EditableText>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper>
          <DatePicker
            autoOk
            disableToolbar
            onChange={handleChange}
            openTo="date"
            orientation="landscape"
            value={_value}
            variant="static"
          />
        </Paper>
      </Popover>
    </>
  );
}
