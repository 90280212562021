import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedNumberParts } from 'react-intl';

CurrencyFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function CurrencyFormatter({ value }) {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return null;
  }

  const props = {
    currency: 'RUB',
    currencyDisplay: 'symbol',
    style: 'currency',
  };

  return (
    <FormattedNumberParts value={value} {...props}>
      {(parts) => {
        return (
          <Box display="inline-block" whiteSpace="nowrap">
            {parts
              .slice(0, -4)
              .map(({ value }) => value)
              .join('')}
            <Box css={{ opacity: 0.6 }} display="inline-block">
              {parts
                .slice(-4, -2)
                .map(({ value }) => value)
                .join('')}
            </Box>
            <Box css={{ opacity: 0.8 }} display="inline-block">
              {parts
                .slice(-2)
                .map(({ value }) => value)
                .join('')}
            </Box>
          </Box>
        );
      }}
    </FormattedNumberParts>
  );
}
