import React, { useCallback, useMemo, useState } from 'react';

import { Collapse, IconButton, List, ListItemText } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SingleMenuItem from './SingleMenuItem';
import { NestedMenuItemProps } from '../types';

NestedMenuItem.propTypes = NestedMenuItemProps;

export default function NestedMenuItem({ children = [], title, url }) {
  const [expanded, setExpanded] = useState(true)

  const handleExpand = useCallback((e) => {
    e.preventDefault()
    setExpanded((prev) => !prev)
  }, [])

  const styles = useMemo(() => ({
    expandIcon: { color: 'currentColor' },
    subList: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: -5,
        bottom: 19,
        left: 18,
        display: 'block',
        width: 0,
        borderLeft: '1px dotted #a7b1c2',
      },
    },
    subListItem: {
      pl: 6,
      '& .MuiListItemText-primary': { fontWeight: 500 },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 18,
        left: 20,
        display: 'block',
        width: 15,
        height: 0,
        borderTop: '1px dotted #a7b1c2',
      }
    },
  }), [])

  return (
    <React.Fragment>
      <SingleMenuItem url={url}>
        <ListItemText primary={title} />
        <IconButton onClick={handleExpand} size="small" sx={styles.expandIcon}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </SingleMenuItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={styles.subList}>
          {children.map((item, idx) => (
            <li key={item?.url || idx}>
              <SingleMenuItem
                dense
                sx={styles.subListItem}
                title={item?.title}
                url={item?.url}
              />
            </li>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}