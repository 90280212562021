import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENT_SUPPLYING_ORDERS_FOR_ASSIGN = gql`
  query SourceDocumentSupplyingOrdersForAssign(
    $lesseeId: ID!,
    $supplierId: ID!
  ) {
    sourceDocumentSupplyingOrdersForAssign(
      lesseeId: $lesseeId,
      supplierId: $supplierId
    ) {
      displayNameForSourceDocumentsAssign
      id
    }
  }
`;