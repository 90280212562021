export const formatFileSize = (size) => {
  let fileSize = `${size} байт`;

  if (size >= 102 && size < 1024 * 1024) {
    fileSize = `${(size / 1024).toFixed(2)} КБ`;
  }

  if (size >= 1024 * 1024) {
    fileSize = `${(size / 1024 / 1024).toFixed(2)} МБ`;
  }

  return fileSize;
};

export const formatName = (contact) => {
  if (!contact) {
    return '';
  }
  const { firstName, lastName, middleName } = contact;
  return `${lastName || ''} ${firstName || ''} ${middleName || ''}`.trim();
};