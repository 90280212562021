import { gql } from '@apollo/client/core';

export const SHOP_STORAGE_ACCEPTANCES_ADD_GOOD = gql`
  mutation ShopStorageAcceptancesAddGood($params: ShopGoodCreateParams!) {
    shopStorageAcceptancesAddGood(params: $params) {
      good {
        id
        name
        shippedEquipment {
          dispayLoanSupplyDate
        }
        storageAcceptance { # приёмка
          acceptedAt # дата поступления только чтение
          dispayLoanLeaseAgreement # номер сделки
        }
        description # описание
        producedAt # дата производства
        quality # состояние
        serialNumber # серийный номер
      }
    }
  }
`;