import { gql } from '@apollo/client/core';

import { SUPPLY_FRAGMENT } from "../fragments";

export const SHOP_SUPPLIES_CHANGE_STATUS = gql`
  ${SUPPLY_FRAGMENT}
  mutation ShopSuppliesChangeStatus (
    $supplyId: ID!
    $transition: ShopSupplyTransition!
  ) {
    shopSuppliesChangeStatus (
      supplyId: $supplyId
      transition: $transition
    ) {
      supply {
        ...SupplyFragment
      }
    }
  }
`;