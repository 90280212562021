import { gql } from '@apollo/client/core';

export const DELETE_HOLIDAY = gql`
  mutation UserLeaveDelete($leaveId: ID!) {
    userLeaveDelete(leaveId: $leaveId) {
      leave {
        id
      }
    }
  }
`;