import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert, AlertTitle, Box, Grow } from '@material-ui/core';

import { useTimer } from 'utils/useTimer';

Notification.propTypes = {
  autoHide: PropTypes.bool,
  delay: PropTypes.number,
  id: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
};

export default function Notification({
  autoHide = false,
  delay = 3,
  id,
  message,
  onClose,
  title,
  type = 'info',
}) {
  const [open, setOpen] = useState(true);

  const handleCloseNotification = useCallback(() => {
    onClose({ id, message, type });
    setOpen(false);
  }, [onClose, id, message, type]);

  const seconds = useTimer(delay);

  useEffect(() => {
    if (!seconds && autoHide) {
      handleCloseNotification();
    }
  }, [handleCloseNotification, autoHide, seconds]);

  return (
    <Grow in={open}>
      <Box
        sx={{
          width: 500,
          minHeight: 50,
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          overflow: 'auto',
          scrollbarColor: '#e0e0e0 transparent',
          '&::-webkit-scrollbar': { width: 6, height: 6 },
          '&::-webkit-scrollbar-track': { background: 'none' },
          '&::-webkit-scrollbar-thumb': {
            background: '#e0e0e0',
            borderRadius: 6,
            '&:hover': { background: '#d0d0d0' },
          },
        }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={type}
          sx={{ width: 500 }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Box>
    </Grow>
  );
}