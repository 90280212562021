import PropTypes from "prop-types";
import React, {useContext, useMemo, useState} from 'react';

import {Box, Typography} from "@material-ui/core";
import { cloneDeep } from "lodash";
import {FormattedMessage} from "react-intl";

import {TABS} from "./constants";
import {OrdersContext} from "./OrdersContext";

OrdersTabs.propTypes = {
  infTabs: PropTypes.object,
};

const DROPDOWN_TABS = [[1, 4], [4, 8], [8, 11]]; // начало и конец массива табов промежуточных шагов для рендера

export default function OrdersTabs({ infTabs }) {
  const { state, updateFilter, updateSubTabs } = useContext(OrdersContext);

  const [activeTab, setActiveTab] = useState(state.filter.tab);
  const [activeSubTabs, setActiveSubTabs] = useState(state.subTabs);

  const styles = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '9px 24px',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)'
    },
    tabs: {
      padding: '0 15px',
      cursor: 'pointer',
      position: 'relative',

      '&.activeTab': {
        cursor: 'default',
      },
      '&.activeTab::after': {
        content: "''",
        backgroundColor: '#3D6CE7',
        display: 'block',
        height: '4px',
        width: '100%',
        position: 'absolute',
        bottom: '-10px',
        left: '0',
      },
      '&.activeTab .MuiTypography-root': {
        color: '#3D6CE7',
      },
      '&.isNonClickable': {
        cursor: 'default'
      },
      '&.subTab .MuiTypography-root': {
        fontSize: '12px',
        color: '#5b5b5b'
      },
      '&.subTab.activeTab .MuiTypography-root': {
        color: '#3D6CE7',
      },
      '&.tabsNonClickable': {
        cursor: 'default'
      }
    },
    tabsTitle: {
      width: '100%',
      color: '#000000',
    },
    tabsButtonTitle: {
      whiteSpace: 'nowrap',

    },
    tabsDescription: {
      width: '100%',
      color: '#6E7884',
    }
  }), []);

  const onChange = (isActive, element, clearActiveSubTab) => {
    if (isActive) return false;

    const newState = cloneDeep(state);
    newState.filter.tab = element;
    newState.filter.page = 1;

    if (clearActiveSubTab) {
      updateSubTabs(null);
      setActiveSubTabs(null);
    }

    updateFilter(newState);

    setActiveTab(element);
  };

  const onClickMainStep = (stepIndex) => {
    if(stepIndex === activeSubTabs) return false;

    setActiveSubTabs(stepIndex);

    const newState = cloneDeep(state);
    newState.subTabs = null;
    updateSubTabs(newState);

    newState.filter.tab = [];

    for (let index = DROPDOWN_TABS[stepIndex][0]; index < DROPDOWN_TABS[stepIndex][1]; index++) {
      newState.filter.tab.push(TABS[index]);
    }
    setActiveTab(newState.filter.tab);

    newState.filter.page = 1;
    updateFilter(newState);
  };

  const renderSubTabsElement = (element, index, isActive) => {
    return (
      <Box className={isActive ? 'activeTab subTab' : 'subTab'} key={`${element}_${index}`} onClick={onChange.bind(this, isActive, element, false)} sx={styles.tabs}>
        <Typography sx={styles.tabsTitle}>
          <FormattedMessage id={`orders.messagesStatus.${element}`} />
        </Typography>
        <Typography sx={styles.tabsDescription}>
          ({infTabs[element]?.count ? infTabs[element].count : 0})
        </Typography>
      </Box>
    );
  };

  const renderSubTabs = (subTabsIndex) => {
    if(activeSubTabs === subTabsIndex) {
      const arrayToRender = [];

      for (let index = DROPDOWN_TABS[subTabsIndex][0]; index < DROPDOWN_TABS[subTabsIndex][1]; index++) {
        arrayToRender.push(TABS[index]);
      }

      return arrayToRender.map((element, index) => (
        renderSubTabsElement(element, index, element === activeTab && !Array.isArray(activeTab))
      ));
    }
    
    return null;
  };

  const renderSingleMainTab = (element) => {
    const isActive = element === activeTab;

    return (
      <Box className={isActive ? 'activeTab' : null} onClick={onChange.bind(this, isActive, element, true)} sx={styles.tabs}>
        <Typography sx={styles.tabsTitle}>
          <FormattedMessage id={`orders.messagesStatus.${element}`} />
        </Typography>
        <Typography sx={styles.tabsDescription}>
          ({infTabs[element]?.count ? infTabs[element].count : 0})
        </Typography>
      </Box>
    );
  };

  const countMainTab = (stepIndex) => {
    let summ = 0;

    for (let index = DROPDOWN_TABS[stepIndex][0]; index < DROPDOWN_TABS[stepIndex][1]; index++) {
      if(infTabs[TABS[index]]?.count) {
        summ += infTabs[TABS[index]].count;
      }
    }

    return summ;
  };

  const renderMainStep = (element, index) => {
    const isActive = index === activeSubTabs && Array.isArray(activeTab);
    let isNonClickable = false;

    for (let id = DROPDOWN_TABS[index][0]; id < DROPDOWN_TABS[index][1]; id++) {
      if(TABS[id] === activeTab) {
        isNonClickable = true;
      }
    }

    return (
      <Box className={`${isActive ? 'activeTab' : null} ${isNonClickable ? 'isNonClickable' : ''}`} onClick={onClickMainStep.bind(this, index)} sx={styles.tabs}>
        <Typography sx={styles.tabsTitle}>
          <FormattedMessage id={`orders.messagesStatus.${element}`} />
        </Typography>
        <Typography sx={styles.tabsDescription}>
          ({countMainTab(index)})
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={styles.wrapperTabs}>
      {renderSingleMainTab('ALL')}
      {renderMainStep('INITIAL_MAIN', 0)}
      {renderSubTabs(0)}
      {renderMainStep('AGREEMENT', 1)}
      {renderSubTabs(1)}
      {renderMainStep('SHIPPING_MAIN', 2)}
      {renderSubTabs(2)}
      {renderSingleMainTab('PARTIALLY_SHIPPED')}
      {renderSingleMainTab('DONE')}
      {renderSingleMainTab('CLOSED')}
    </Box>
  )
};
