import { gql } from '@apollo/client/core';

export const GET_FSSP_CHECK_PROCESSES = gql`
  query GetFsspCheckProcesses($contactId: ID!) {
    fsspCheckProcesses(contactId: $contactId) {
      id
      createdAt
      finishedAt
      failedAt
      itemsCount
      state
      unpaidItemsCount
      unpaidSum
    }
  }
`;