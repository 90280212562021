import { gql } from '@apollo/client/core';

import { MANAGER_FRAGMENT, ENTITY_FRAGMENT } from "../fragments";

export const SHOP_ORDERS_UPDATE = gql`
  ${MANAGER_FRAGMENT}
  ${ENTITY_FRAGMENT}
  mutation ShopOrdersUpdate (
    $orderId: ID!
    $params: ShopOrderUpdateParams!
  ) {
    shopOrdersUpdate(
      orderId: $orderId
      params: $params
    ) {
      order {
        id
        manager {
          ...ManagerFragment
        }
        entity {
          ...EntityFragment
        }
      }
    }
  }
`;
