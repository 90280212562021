import PropTypes from "prop-types";
import React, {useMemo} from 'react';

import {Box} from "@material-ui/core";

import {formatInitials} from "./utils";

AvatarUser.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.string,
};

AvatarUser.defaultProps = {
  avatar: null,
  firstName: "",
  lastName: "",
  size: 40,
}

export default function AvatarUser({ avatar, firstName, lastName, size }) {
  const styles = useMemo(() => ({
    avatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size,
      height: size,
      flex: 'none',
      borderRadius: '50%',
      backgroundColor: '#ccc',
      color: 'white',
      fontSize: 18,
      fontWeight: 500,
      textTransform: 'uppercase',
      textShadow: '1px 1px 3px #1b2835',
      overflow: 'hidden',
    },
    wrapperImg: {
      width: size + 10,
      height: size + 10,

      '& > img': {
        pointerEvents: 'none',
      }
    }
  }), [size])

  return (
    <Box sx={styles.avatar}>
      {avatar && avatar !== null ? (
        <Box sx={styles.wrapperImg}>
          <img alt={`${lastName} ${firstName}`} src={avatar} style={{ width: "auto", height: "100%"}} />
        </Box>
      ) : (
        formatInitials(
          `${lastName} ${firstName}`
        )
      )}
    </Box>
  )
}