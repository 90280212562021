import PropTypes from 'prop-types';

export const FinmonitoringOperationParticipantProps = PropTypes.shape({
  ceo: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    phone: PropTypes.string,
  }),
  legalName: PropTypes.string,
  organizationalForm: PropTypes.oneOf([
    'LLC', 'OJSC', 'JSC', 'PJSC', 'COMPANY', 'ENTREPRENEUR', 'OTHER',
  ]),
  psrn: PropTypes.string,
  role: PropTypes.oneOf(['LESSOR', 'LESSEE', 'SUPPLIER']),
  tin: PropTypes.string,
  trrc: PropTypes.string,
});

export const FinmonitoringDocumentProps = PropTypes.shape({
  amount: PropTypes.number,
  date: PropTypes.string,
  number: PropTypes.string,
  type: PropTypes.oneOf([
    'LEASING_AGREEMENT', 'SUPPLYING_CONTRACT', 'SHIPPING_DOCUMENT',
  ]),
});

export const FinmonitoringProblemProps = PropTypes.shape({
  context: PropTypes.oneOf(['INPUT', 'OUTPUT']),
  fieldName: PropTypes.string,
  index: PropTypes.number,
  messages: PropTypes.array,
});