import { gql } from '@apollo/client/core';

export const SHOP_STORAGE_ACCEPTANCE_CHANGE_STATUS = gql`
  mutation ShopStorageAcceptanceChangeStatus($storageAcceptanceId: ID!, $transition: ShopStorageAcceptanceTransition!) {
    shopStorageAcceptancesChangeStatus(storageAcceptanceId: $storageAcceptanceId, transition: $transition) {
      storageAcceptance {
        id
        status
      }
    }
  }
`;