import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, ButtonBase, Popover, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import isEqual from 'lodash/isEqual';
import _noop from 'lodash.noop';

import { CustomTimePickerBox } from './CustomTimePickerBox';
import { DatePickerBox } from './DatePickerBox';
import {TimePickerBox} from "./TimePickerBox";
import {DATE_TIME_FORMAT} from "../core/constants/Formats";

dayjs.extend(utc)

DateTimeInlinePicker.propTypes = {
  ViewComponent: PropTypes.node,
  certainTime: PropTypes.bool,
  hardTime: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export function InlinePicker(obj = {}, certainTime, hardTime, value, onChange) {
  const param = {
    certainTime: obj.certainTime ? obj.certainTime : certainTime || false,
    hardTime: obj.hardTime ? obj.hardTime : hardTime || false,
    value: obj.value ? obj.value : value || '',
  }

  const [state] = useState({ certainTime: param.certainTime, date: param.value, time: param.value });
  const [persistState, setPersistState] = useState(state);
  const [hasDateError, setHasDateError] = useState(false);

  const handleChangeDate = useCallback((value) => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      dateValue = dayjs(value).utc(true).format(DATE_TIME_FORMAT);
    }

    setPersistState((prev) => ({ ...prev, date: dateValue }));
  }, []);

  const handleChangeTime = useCallback((value) => {
    let timeValue = null;

    if (value && dayjs(value).isValid()) {
      timeValue = new Date(value);
      timeValue = timeValue.toISOString();
      setPersistState((prev) => ({ ...prev, certainTime: true }));
    } else {
      setPersistState((prev) => ({ ...prev, certainTime: false }));
    }

    setPersistState((prev) => ({ ...prev, time: timeValue }));
  }, []);

  const resetChanges = useCallback(() => setPersistState(state), [state]);

  const saveChanges = useCallback(() => {
    if (onChange instanceof Function) {
      onChange({
        dueAt: persistState.date,
        dueAt2: persistState.time,
        certainTime: persistState.certainTime,
      });
    }

    if (obj.onChange instanceof Function) {
      obj.onChange({
        dueAt: persistState.date,
        dueAt2: persistState.time,
        certainTime: persistState.certainTime,
      });
    }
  }, [persistState, onChange, obj]);

  useEffect(() => {
    setHasDateError(persistState.date === null);
  }, [persistState]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', gap: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Typography mb={1}>Дата:</Typography>
          <DatePickerBox
            hasError={hasDateError}
            onChange={handleChangeDate}
            size="small"
            value={persistState.date || null}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography mb={1}>Время:</Typography>
          {param.hardTime ? (
            <CustomTimePickerBox
              onChange={handleChangeTime}
              value={persistState.certainTime ? persistState.time : null}
            />
          ) : (
            <TimePickerBox
              onChange={handleChangeTime}
              value={persistState.certainTime ? persistState.time : null}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3, mt: 3 }} >
        <Button
          disabled={isEqual(persistState, state)}
          onClick={resetChanges}
        >
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state) || hasDateError}
          onClick={saveChanges}
          sx={{ color: 'white', height: 40 }}
          variant="contained"
        >
          Применить
        </Button>
      </Box>
    </>
  )
}

export default function DateTimeInlinePicker({
  ViewComponent,
  certainTime = false,
  hardTime = false,
  value = '',
  onChange = _noop,
  onClose = _noop,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }, [onClose]);

  const handleChange = value => {
    onChange(value);
    handleClose();
  }

  return (
    <React.Fragment>
      {ViewComponent && (
        <ButtonBase onClick={handleOpen}>
          {ViewComponent}
        </ButtonBase>
      )}

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper sx={{ p: 3 }}>
          {InlinePicker({}, certainTime, hardTime, value, handleChange)}
        </Paper>
      </Popover>
    </React.Fragment>
  );
}