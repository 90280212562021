import { gql } from '@apollo/client';

import { CRIMINAL_CHECK_PROCCESS_FRAGMENT } from '../fragments';

export const CRIMINAL_ACTIVITY_START_CHECK_PROCESS = gql`
  mutation CriminalActivityStartCheckProcess($contactId: ID!) {
    criminalActivityStartCheckProcess(contactId: $contactId) {
      criminalActivityCheckProcess {
        ...CriminalCheckProcessFragment
      }
    }
  }
  ${CRIMINAL_CHECK_PROCCESS_FRAGMENT}
`;