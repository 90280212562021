import { gql } from '@apollo/client/core';

import { SCHEDULE_ITEM_FRAGMENT } from '../fragments';

export const LOAN_MIGRATION = gql`
  mutation FinanceLoanMigration($loanId: ID!) {
    financeLoanMigration(loanId: $loanId) {
      schedule {
        id
        state
        createdAt
        stornedAt
        activatedAt
        items {
          ...ScheduleItemFragment
        }
      }
    }
  }
  ${SCHEDULE_ITEM_FRAGMENT}
`;