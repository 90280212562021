import PropTypes from 'prop-types';
import React from 'react';

import { Alert, AlertTitle } from '@material-ui/core';

ErrorAlert.propTypes = {
  action: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
};

export default function ErrorAlert({ action, message, title }) {
  return (
    <Alert action={action} severity="error">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}
