import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Dialog, DialogContent } from '@material-ui/core';

import AssignDocumentDetails from './AssignDocumentDetails';
import AssignForm from './AssignForm';
import { AssignProcessProvider } from './AssignProcessContext';

const styles = {
  modalContainer: {
    '& .MuiDialog-scrollBody': {
      scrollbarColor: 'rgba(255, 102, 0, 0.7) transparent',
      '&::-webkit-scrollbar': { width: 6, height: 6 },
      '&::-webkit-scrollbar-track': { background: 'none' },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 102, 0, 0.7)',
        borderRadius: 6,
        '&:hover': { background: '#FF6600' },
      },
    },

    '& .MuiDialog-paper': {
      background: 'none',
      boxShadow: 'none',
    },
  },
  modalBox: {
    borderRadius: '1rem',
    width: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalLeft: {
    borderRadius: '1rem',
    width: '58%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
  },
  modalLeftInner: {
    height: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  modalRight: {
    height: 1,
    borderRadius: '1rem',
    width: '38%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'hidden',
  },
  modalRightInner: {
    height: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    p: 4,
    gap: '1.5rem',
  },
};

AssignModal.propTypes = {
  documentId: PropTypes.string,
  documentStatus: PropTypes.string,
  handleAssignModalClose: PropTypes.func
}

export default function AssignModal({
  documentId,
  documentStatus,
  handleAssignModalClose,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    handleAssignModalClose(null);
    setOpen(false);
  }, [handleAssignModalClose]);

  const handleForceClose = useCallback(() => {
    handleAssignModalClose(null, true);
    setOpen(false);
  }, [handleAssignModalClose]);

  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    if (documentId) {
      handleOpen();
    }
  }, [documentId, handleOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={styles.modalContainer}
    >
      <DialogContent>
        <AssignProcessProvider {...{ documentId, orderId }}>
          <Box sx={styles.modalBox}>
            <Box sx={styles.modalLeft}>
              <Box sx={styles.modalLeftInner}>
                <AssignDocumentDetails orderId={orderId} />
              </Box>
            </Box>

            <Box sx={styles.modalRight}>
              <Box sx={styles.modalRightInner}>
                <AssignForm
                  documentStatus={documentStatus}
                  onOrderIdChange={setOrderId}
                  onSessionClose={handleClose}
                  onSessionForceClose={handleForceClose}
                  orderId={orderId}
                />
              </Box>
            </Box>
          </Box>
        </AssignProcessProvider>
      </DialogContent>
    </Dialog>
  );
}