import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import RenewIcon from '@material-ui/icons/Autorenew';
import rosfinmonitoringLogo from 'assets/rosfinmonitoring.png';
import { FormattedMessage } from 'react-intl';
import { useTimer } from 'use-timer';

import { LastCheckTimeSubheader } from '../components/LastCheckTimeSubheader';
import {
  CRIMINAL_ACTIVITY_START_CHECK_PROCESS,
} from '../graphql/mutations/criminalActivityStartCheckProcess';
import {
  GET_CONTACT_CHECK_PROCESSES,
} from '../graphql/queries/contactCheckProcesses';
import {
  GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES,
} from '../graphql/queries/criminalActivityCheckProcesses';

PendingProcess.propTypes = {
  contactId: PropTypes.string,
};

function PendingProcess({ contactId }) {
  const { reset, start, time } = useTimer({
    initialTime: 5,
    timerType: 'DECREMENTAL',
  });

  const [refreshCriminalCheckProcess, { loading }] = useLazyQuery(
    GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES,
    {
      variables: { contactId },
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    refreshCriminalCheckProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      reset();
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <Typography variant="subtitle2">Запрос выполняется...</Typography>
      <Typography color="textSecondary" variant="caption">
        {loading
          ? 'Проверка статуса запроса'
          : `Автопроверка статуса через ${time} с.`}
      </Typography>
    </>
  );
}

const STATE_TO_TEMPLATE = {
  CREATED: PendingProcess,
  STARTED: PendingProcess,
  FINISHED: FinishedProcess,
  FAILED: FailedProcess,
};

FailedProcess.propTypes = {};

function FailedProcess() {
  return (
    <>
      <Box>Последний запрос завершен с ошибкой</Box>
    </>
  );
}

FinishedProcess.propTypes = {
  contactData: PropTypes.shape({
    passportNumber: PropTypes.string,
    passportSeries: PropTypes.string,
  }),
  contactId: PropTypes.string,
  financialFraud: PropTypes.array,
  terrorism: PropTypes.array,
  weaponDistribution: PropTypes.array,
};

function FinishedProcess({
  contactData,
  contactId,
  financialFraud,
  terrorism,
  weaponDistribution,
}) {
  const Found = (
    <ButtonBase
      href={
        `${process.env.REACT_APP_CLOUD_HOST}/contacts/${contactId}/rosfinmonitoring`
      }
      target="_blank"
    >
      <Typography color="error" variant="button">
        <Box display="flex">
          Найден
          <ChevronRight fontSize="small" />
        </Box>
      </Typography>
    </ButtonBase>
  );
  const NotFound = (
    <Box color="rgb(34, 160, 83)" component="span" fontWeight={500}>
      НЕ НАЙДЕН
    </Box>
  );
  const financialFraudContent = financialFraud.length ? Found : NotFound;
  const terrorismContent = terrorism.length ? Found : NotFound;
  const weaponDistributionContent = weaponDistribution.length ? Found : NotFound;

  return (
    <>
      <Box color="rgba(0,0,0,.8)" fontSize={13} lineHeight="20px" mb={2}>
        <FormattedMessage
          id={`passportCheckStatus.passportNumber`}
          values={{
            number: (
              <Box component="span" fontWeight={500}>
                {contactData.passportSeries}&nbsp;{contactData.passportNumber}
              </Box>
            ),
          }}
        />
      </Box>
      <Divider />
      <Box mt={2}>
        <Typography component="div" gutterBottom variant="body2">
          <FormattedMessage id={`rosfinmonitoring.financialFraud`} />
          :&nbsp; {financialFraudContent}
        </Typography>
        <Typography component="div" gutterBottom variant="body2">
          <FormattedMessage id={`rosfinmonitoring.terrorism`} />
          :&nbsp; {terrorismContent}
        </Typography>
        <Typography component="div" gutterBottom variant="body2">
          <FormattedMessage id={`rosfinmonitoring.weaponDistribution`} />
          :&nbsp; {weaponDistributionContent}
        </Typography>
      </Box>
    </>
  );
}

CriminalCheckWidget.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    failedAt: PropTypes.string,
    startedAt: PropTypes.string,
    state: PropTypes.oneOf(['CREATED', 'STARTED', 'FINISHED', 'FAILED']),
  }),
};

export default function CriminalCheckWidget({ contactId, data }) {
  const [startCriminalCheckProcess, { loading }] = useMutation(
    CRIMINAL_ACTIVITY_START_CHECK_PROCESS, {
    variables: { contactId },
    onError: (error) => {
      alert(`Произошла ошибка при запуске проверки в базе росфинмониторинга ${error.message}`);
    },
    // TODO: сделать обновление кэша вместо запроса контакта
    refetchQueries: [{
      query: GET_CONTACT_CHECK_PROCESSES,
      variables: { contactId },
    }],
  });

  function handleClick() {
    startCriminalCheckProcess();
  }

  const subheader = data ? (
    <LastCheckTimeSubheader
      {...{
        createdAt: data.createdAt,
        failedAt: data.failedAt,
        finishedAt: data.finishedAt,
        startedAt: data.startedAt,
      }}
    />
  ) : (
    <Typography color="textSecondary" variant="caption">
      <Box fontWeight="500">Проверок не выполнялось</Box>
    </Typography>
  );

  const Content = data ? (
    STATE_TO_TEMPLATE[data.state]
  ) : (
    <Box display="flex" height={102} m="auto">
      <Button disabled={loading} fullWidth onClick={handleClick}>
        <FormattedMessage id="verification.checkButton" />
      </Button>
    </Box>
  );

  const contentBody = data ? (
    <Box height={102}>
      <Content {...data} contactId={contactId} />
    </Box>
  ) : (
    <Box display="flex" height={102} m="auto">
      <Button disabled={loading} fullWidth onClick={handleClick}>
        <FormattedMessage id="verification.checkButton" />
      </Button>
    </Box>
  );

  return (
    <Card variant="outlined">
      <CardHeader
        action={
          data && (
            <IconButton
              disabled={loading}
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <RenewIcon />
            </IconButton>
          )
        }
        avatar={
          <Box
            alt="Logo"
            component="img"
            height={40}
            src={rosfinmonitoringLogo}
          />
        }
        subheader={subheader}
        title={
          <Box fontSize={16} fontWeight={500}>
            <FormattedMessage id={`rosfinmonitoring.title`} />
          </Box>
        }
      />
      <Box mt={-3} />
      <CardContent>{contentBody}</CardContent>
    </Card>
  );
}
