import { gql } from '@apollo/client/core';

export const TASKS_COMPLETE = gql`
  mutation TasksComplete($taskId: ID!) {
    tasksComplete(taskId: $taskId) {
      task {
        id
      }
    }
  }
`;