import { gql } from '@apollo/client/core';

export const GET_FINMONITORING_REPORTING_MESSAGES = gql`
  query FinmonitoringReportingMessages(
    $limit: Int
    $page: Int
    $filter: FinmonitoringReportingMessageFilter
  ) {
    finmonitoringReportingMessages(limit: $limit, page: $page, filter: $filter) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        externalId
        data {
          documents {
            amount
            date
            number
            type
          }
          operationParticipants {
            ceo {
              email
              firstName
              lastName
              middleName
              phone
            }
            legalName
            organizationalForm
            psrn
            role
            tin
            trrc
          }
        }
        loan {
          id
          fundingSource {
            source
          }
          amountForLeasingCompany
          interestRate
          amountFinancing  
          displayLeaseAgreement
          overdueDays
          application {
            id
            borrowerEntity {
              id
              legalNameWithShortEntityType
            }
          }
        }
        sentAt
        state
      }
    }
  }
`;