import { gql } from '@apollo/client';

export const SHOP_GOOD_COPY_VALUES = gql`
  query shopGoodCopyValues($goodId: ID!) {
    shopGoodCopyValues(goodId: $goodId) {
      description
      name
      producedAt
      quality
      serialNumber
      shippedEquipment {
        dispayLoanSupplyDate
      }
      storageAcceptance {
        acceptedAt
        dispayLoanLeaseAgreement
      }
    }
  }
`;