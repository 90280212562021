import { gql } from '@apollo/client/core';

export const FMS_START_PASSPORT_CHECK = gql`
  mutation FmsStartPassportCheck($contactId: ID!) {
    fmsStartPassportCheck(contactId: $contactId) {
      fmsPassportCheck {
        checkedPassportNumber
        id
        number
        series
        status
        createdAt
      }
    }
  }
`;