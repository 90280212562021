import { gql } from '@apollo/client/core';

export const GET_TASKS = gql`
  query GetTasks(
    $page: Int,
    $limit: Int,
    $filters: TasksFilter,
    $sort: TasksSort
  ) {
    tasks(page: $page, limit: $limit, filters: $filters, sort: $sort) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        dueAt
        assignee {
          contact {
            firstName
            lastName
          }
        }
        notes {
          author {
            ... on User {
              contact {
                lastName
                firstName
              }
              avatar
              displayName
              roles
            }
          }
          body
          id
          createdAt
        }
        title
        description
        status
        createdAt
        target {
          ... on Application {
            id
          }
        }
      }
    }
  }
`;