/* eslint-disable react/display-name, react/prop-types */
import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import MarkEmailReadIcon from '@material-ui/icons/MarkEmailRead';

import FormattedCurrency from 'components/FormattedCurrency';
import { formatDate } from 'utils/dateTimeFormatters';

export const DEFAULT_MESSAGES_LIMIT = 10;

export const FINMONITORING_FILTER_STATE_FIELDS = {
  date: 'Срок действия договора',
};

export const finmonitoringFilterStateRenderers = (fieldName, value = '') =>
  ({
    date: (
      <>
        {value?.from && value?.to && <>{`${formatDate(value.from)} — ${formatDate(value.to)}`}</>}

        {value?.from && !value?.to && <>{`с ${formatDate(value.from)}`}</>}

        {!value?.from && value?.to && <>{`по ${formatDate(value.to)}`}</>}
      </>
    ),
  }[fieldName]);

export const columnExtensions = [
  { columnName: 'id', width: 60 },
  { columnName: 'displayLeaseAgreement', width: 200, wordWrapEnabled: true },
  { columnName: 'borrower', width: 210, wordWrapEnabled: true },
  { columnName: 'amount', width: 140, wordWrapEnabled: true },
  { columnName: 'state', width: 150 },
  { columnName: 'actions', width: 240 },
  { columnName: 'sentAt', align: 'center', width: 200 },
];

export const columnsModelCreator = [
  { name: 'id', title: 'ID' },
  {
    name: 'displayLeaseAgreement',
    title: '№ договора',
    getCellValue: ({ loan: { application, displayLeaseAgreement } }) => {
      const host = process.env.REACT_APP_HOST;
      const applicationId = application?.id || '';
      return (
        <Link href={`${host}/applications/${applicationId}/deal`} target="_blank">
          {displayLeaseAgreement}
        </Link>
      );
    },
  },
  { name: "externalId", title: "№ сообщения в РФМ" },
  {
    name: 'borrower',
    title: 'Лизингополучатель',
    getCellValue: ({ loan: { application } }) => {
      const host = process.env.REACT_APP_HOST;
      const borrowerEntityId = application?.borrowerEntity?.id || '';
      return (
        <Link href={`${host}/entities/${borrowerEntityId}`} target="_blank">
          {application?.borrowerEntity?.legalNameWithShortEntityType || ''}
        </Link>
      );
    },
  },
  {
    name: 'amount',
    title: 'Сумма по отгрузке',
    getCellValue: ({ data }) => {
      let amountFinancing = null;

      if (!!data?.documents?.length) {
        data.documents.forEach(elem => {
          if (elem.type === 'SHIPPING_DOCUMENT') {
            amountFinancing += elem.amount;
          }
        })
      }

      return amountFinancing ? (
        <>
          <FormattedCurrency style="decimal" value={amountFinancing} /> руб.
        </>
      ) : (
        ''
      );
    },
  },
  {
    name: 'amountForLeasingCompany',
    title: 'Сумма по ДЛ',
    getCellValue: ({ loan: { amountForLeasingCompany } }) => {
      return amountForLeasingCompany ? (
        <>
          <FormattedCurrency style="decimal" value={amountForLeasingCompany} /> руб.
        </>
      ) : (
        ""
      );
    },
  },
  {
    name: 'state',
    title: 'Статус сообщения',
    getCellValue: ({ state }) => (
      <Typography
        color={
          {
            PENDING: { color: 'lightgray' },
            SENT: { color: 'green' },
            REJECTED: { color: orange[700] },
            ARCHIVED: { color: 'lightgray' },
          }[state]
        }
      >
        {
          {
            PENDING: 'Не отправлено',
            SENT: 'Отправлено',
            REJECTED: 'Отклонено',
            ARCHIVED: 'В архиве',
          }[state]
        }
      </Typography>
    ),
  },
  { name: 'actions', title: 'Действия' },
  {
    name: 'sentAt',
    title: 'Отправлено в Финмонитор',
    getCellValue: ({ sentAt }) => {
      if (sentAt) {
        return (
          <Box
            sx={{
              fontFamily: 'Open Sans',
              display: 'inline-flex',
              alignItems: 'center',
              fontWeight: 600,
              color: '#22a053',
            }}
          >
            <MarkEmailReadIcon />
            &nbsp;&nbsp;
            {formatDate(sentAt)}
          </Box>
        );
      }

      return '';
    },
  },
];

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Сообщений на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Сообщений нет';
    }

    return to === count
      ? `Сообщений: ${to - from + 1} (всего ${count})`
      : `Сообщения ${from}-${to} (всего ${count})`;
  },
};
