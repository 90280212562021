import { gql } from '@apollo/client';

import { CRIMINAL_CHECK_PROCCESS_FRAGMENT } from '../fragments';

export const GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES = gql`
  query GetCriminalActivityCheckProcesses($contactId: ID!) {
    criminalActivityCheckProcesses(contactId: $contactId) {
      ...CriminalCheckProcessFragment
    }
  }
  ${CRIMINAL_CHECK_PROCCESS_FRAGMENT}
`;