import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { Box } from '@material-ui/core';
import { v4 } from 'uuid';

import Notification from './Notification';

export const NotificationsContext = createContext({});

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'ADD_NOTIFICATION':
          return { notifications: [...state.notifications, action.payload] };
        case 'REMOVE_NOTIFICATION':
          return {
            ...state,
            notifications: state.notifications.filter(
              (el) => el.id !== action.payload.id
            ),
          };
        default:
          return state;
      }
    },
    { notifications: [] },
  );

  const handleClose = useCallback(
    (payload) => {
      dispatch({ type: 'REMOVE_NOTIFICATION', payload });
    },
    [dispatch],
  );

  useEffect(() => {
    if (state.notifications.length > 10) {
      handleClose(state.notifications[0]);
    }
  }, [state.notifications, handleClose]);

  const context = useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <NotificationsContext.Provider value={context}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: {
            xs: 0,
            lg: 12
          },
          width: {
            xs: '100%',
            lg: 506
          },
          height: state.notifications.length > 5 ? 1 : 'auto',
          py: {
            xs: 0,
            lg: '20px'
          },
          zIndex: 100000,
          overflow: 'auto',
          scrollbarColor: '#e0e0e0 transparent',
          '&::-webkit-scrollbar': { width: 6, height: 6 },
          '&::-webkit-scrollbar-track': { background: 'none' },
          '&::-webkit-scrollbar-thumb': {
            background: '#e0e0e0',
            borderRadius: 6,
            '&:hover': { background: '#d0d0d0' },
          },
        }}
      >
        {state.notifications.map((noty) => (
          <Notification key={noty.id} onClose={handleClose} {...noty} />
        ))}
      </Box>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node,
};

export const useNotification = () => {
  const { dispatch } = useContext(NotificationsContext);

  return useMemo(
    () => ({
      notify: (payload, type) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'ADD_NOTIFICATION':
            dispatch({
              type: 'ADD_NOTIFICATION',
              payload: { id: payload.id || v4(), ...payload },
            });
            break;
          case 'REMOVE_NOTIFICATION':
            dispatch({
              type: 'REMOVE_NOTIFICATION',
              payload: payload,
            });
            break;
        }
      },
    }),
    [dispatch],
  );
};

export default NotificationsProvider;