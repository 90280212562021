import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AppBar, Box, Typography } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';

import FormCreateResolution from './FormCreateResolution';

FixedResolution.propTypes = {
  bankStatementId: PropTypes.string,
};

export const ResolutionFormContext = React.createContext({
  formState: { locked: false, text: '' },
  toggleFormLock: () => {},
});

export default function FixedResolution({ bankStatementId }) {
  const { formState } = useContext(ResolutionFormContext);

  function handleSuccess() {
    // toggleFormLock({ locked: false, text: '' });
  }

  return (
    <AnimatePresence>
      {formState.locked && (
        <AppBar
          animate={{ opacity: 1, y: 0, bottom: 24, position: 'fixed', left: 24, right: 24 }}
          color="default"
          component={motion.div}
          exit={{ opacity: 0, scale: 0.5, y: 100, x: -50, transition: { duration: 0.2 } }}
          initial={{ opacity: 0, bottom: -100 }}
          position="fixed"
          style={{
            zIndex: 1301,
            bottom: 2,
            top: 'auto',
            left: 24,
            right: 24,
            width: 'unset',
            maxWidth: 1092,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          }}
        >
          <Box maxWidth={1140} px={4} py={3}>
            <Box mb={3}>
              <Typography component="span" variant="h5">
                Резолюция к выписке
              </Typography>
              &nbsp;&nbsp;
            </Box>
            <FormCreateResolution
              bankStatementId={bankStatementId}
              defaultValues={{ text: formState.text }}
              onSuccess={handleSuccess}
            />
          </Box>
        </AppBar>
      )}
    </AnimatePresence>
  );
}
