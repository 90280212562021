import React, { useCallback, useMemo, useState } from 'react';

import { Box, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { navigate, useLocation } from '@reach/router';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { useDebouncedCallback } from 'use-debounce';

export default function DocumentsFilter() {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [innNameOrDocumentNumber, setInnNameOrDocumentNumber] = useState(
    parsedQuery.q || '',
  );

  const debounced = useDebouncedCallback((value) => {
    const query = pickBy({ ...parsedQuery, page: 1, q: value }, identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);
  }, 500);

  const handleQueryChange = useCallback(({ target: { value } }) => {
    setInnNameOrDocumentNumber(value);
    debounced.callback(value);
  }, [debounced]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 34,
        mt: 2,
        mb: 3,
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
      }}
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
        fullWidth
        onChange={handleQueryChange}
        placeholder="ИНН, лизингополучатель, номер документа, имя поставщика, номер заказа"
        size="small"
        sx={{
          '& .MuiInputBase-root': { px: '22px' },
          '& input': { fontSize: 14, height: 'calc(100% + 2px)' },
          '& fieldset': { border: 'none' },
        }}
        value={innNameOrDocumentNumber}
      />
    </Box>
  );
}