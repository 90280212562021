import { gql } from '@apollo/client';

export const ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED = gql`
  subscription OnEntityBankStatementsListUpdated($entityId: ID!) {
    entityBankStatementsListUpdated(entityId: $entityId) {
      id
      bankStatementAccounts {
        id
        number
        bank {
          bic
          title
        }
        bankStatement {
          id
          status
          info {
            startDate
            endDate
          }
        }
      }
    }
  }
`;