import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { incline } from 'lvovich';

import ErrorAlert from 'components/ErrorAlert';

import FormServiceAcceptanceCertificate from './FormServiceAcceptanceCertificate';
import { GET_SUPPLYING_ORDER } from './graphql/queries/supplyingOrder';

function buildFullNameGenitive({ firstName, lastName, middleName }) {
  const { first, last, middle } = incline(
    {
      first: firstName || '',
      middle: middleName || '',
      last: lastName || '',
    },
    'genitive',
  );

  return `${last} ${first} ${middle}`.trim();
}

PageSupplyingOrderServiceAcceptanceCertificate.propTypes = {
  supplyingOrderId: PropTypes.string,
};

export default function PageSupplyingOrderServiceAcceptanceCertificate({ supplyingOrderId }) {
  const { data, error, loading } = useQuery(GET_SUPPLYING_ORDER, {
    variables: { supplyingOrderId },
    errorPolicy: 'all',
  });

  if (error && !data) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />;
  }

  if (loading) {
    return 'Загрузка...';
  }

  const {
    supplyingOrder,
    supplyingOrder: {
      application: { borrowerEntity },
      supplies
    },
  } = data;

  const borrowerInCharge = borrowerEntity.staff.find(({ position }) => position === 'ceo');

  const contact = borrowerInCharge?.contact;

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const todayDate = today.toISOString();

  const defaultValues = {
    documentDate: todayDate,
    basedOn: 'устава',
    borrowerInChargePositionGenitive: borrowerInCharge ? 'Генерального директора' : '',
    borrowerInChargePosition: borrowerInCharge ? 'Генеральный директор' : '',
    borrowerInChargeFullName: contact ? buildFullNameGenitive(contact) : '',
    borrowerInChargeShortName: borrowerInCharge?.contact?.shortName,
  };

  return (
    <Box px={4}>
      <Typography ml={8} variant="h2">
        Акт приема-передачи заказа №{supplyingOrder.number}
      </Typography>
      <FormServiceAcceptanceCertificate
        defaultValues={defaultValues}
        supplies={supplies}
      />
    </Box>
  );
}
