import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import { navigate, useLocation } from '@reach/router';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import qs from 'querystringify';

const defaultContextData = {};

const defaultContextValue = {
  state: defaultContextData,
  updateState: () => { },
};

export const TasksFilterContext = createContext(defaultContextValue);

export const TasksFilterProvider = ({ children }) => {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [state, setState] = useState(defaultContextData);

  const updateState = useCallback((newState) => {
    setState((prev) => {
      if (!isEqual(prev, newState)) {
        const query = pickBy({
          ...parsedQuery,
          page: 1,
          filters: JSON.stringify(newState),
        }, identity);

        const search = qs.stringify(query, true);
        navigate(search ? search : location.pathname);
      }

      return newState;
    });
  }, [location.pathname, parsedQuery]);

  return (
    <TasksFilterContext.Provider value={{ state, updateState }}>
      {children}
    </TasksFilterContext.Provider>
  )
};

TasksFilterProvider.propTypes = { children: PropTypes.node };