import { gql } from '@apollo/client/core';

export const GET_INVOICE_PDF = gql`
  query GetInvoicePdf (
    $invoiceId: ID!
  ) {
    invoicePdf (
      invoiceId: $invoiceId
    ) {
      id
      base64Filename
      base64Data
    }
  }
`;