import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import {
  CustomPaging,
  PagingState,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {Box, withStyles} from "@material-ui/core";
import { cloneDeep, findIndex } from "lodash";

import {
  COLUMN_EXTENSIONS,
  COLUMNS_MODEL_CREATOR,
  PAGING_PANEL_MESSAGES,
  TABS
} from "./constants";
import { GET_ORERS_TASK } from './graphql/queries/ordersTasks';
import {OrdersContext} from "./OrdersContext";
import ErrorAlert from "../../components/ErrorAlert";
import { GridRoot, Loader } from "../deals/components/helpers";

OrdersTable.propTypes = {
  updateInfTabs: PropTypes.func,
};

export default function OrdersTable({ updateInfTabs }) {
  const style = {
    wrapperTable: {
      maxWidth: '100%',

      '& table thead th': {
        paddingTop: '0',
      },
      '& table thead span': {
        color: "#6E7884 !important",
      },
      '& table thead th:nth-last-child(1) span': {
        color: "#3D6CE7 !important",
      },
      '& table thead th:nth-last-child(2) span': {
        color: "#3D6CE7 !important",
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    }
  };

  const { state, updateFilter } = useContext(OrdersContext);

  const [fetchMessages, { data, error, loading }] = useLazyQuery(
    GET_ORERS_TASK, {
      fetchPolicy: "network-only",
    }
  );

  const [rows, setRows] = useState([]);
  const [countRows, setCountRows] = useState({});

  useEffect(() => {
    if (data && data.supplyingOrders?.supplyingOrders) {
      setRows(data.supplyingOrders.supplyingOrders.collection);
    }
    if (data && data.supplyingOrders?.tabs) {
      setCountRows(data.supplyingOrders?.tabs);
    }
  }, [data])

  useEffect(() => {
    if (countRows.length > 0) {
      let infTabs = {},
          init = {
            sum: 0,
            count: 0,
          },
          all = 0,
          allSumm = 0;
      countRows.forEach(element => {
        all += element.count;
        allSumm += element.sum;
        infTabs[element.status] = { ...init };
        infTabs[element.status].count = element.count;
        infTabs[element.status].sum = element.sum;
      });
      infTabs['ALL'] = { ...init };
      infTabs['ALL'].count = all;
      infTabs['ALL'].sum = allSumm;

      updateInfTabs(infTabs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countRows])

  useEffect(() => {
    let filterStatusIn = [ ...TABS ].slice(1);

    if(Array.isArray(state.filter.tab)) {
      filterStatusIn = state.filter.tab;
    } else if(typeof(state.filter.tab) === 'string') {
      filterStatusIn = state.filter.tab === TABS[0] ? [ ...TABS ].slice(1) : [state.filter.tab]
    }

    let fetchVariables = {
      filter: {
        statusIn: filterStatusIn,
      },
      sort: {
        field: state.filter.sort.field,
        order: state.filter.sort.order
      },
      page: state.filter.page,
      limit: state.filter.limit,
    }

    if (state.filter.date?.to) {
      fetchVariables.filter['createdAtLteq'] = state.filter.date.to;
    }
    if (state.filter.date?.from) {
      fetchVariables.filter['createdAtGteq'] = state.filter.date.from;
    }

    if (state.filter.arenzaManagerUserIdIn) {
      fetchVariables.filter['arenzaManagerUserIdIn'] = state.filter.arenzaManagerUserIdIn.id;
    }

    if (state.filter.search) {
      fetchVariables.filter['supplyingOrderEntitiesFilterCont'] = state.filter.search;
    }

    if (state.filter.sort) {
      fetchVariables.sort.order = state.filter.sort.order;
    }

    fetchMessages({ variables: fetchVariables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = withStyles({ name: "MyPager" })(
    MyPager
  );

  const handleCurrentPageChange = (page) => {
    const newState = cloneDeep(state);
    newState.filter.page = page + 1;

    updateFilter(newState);
  };

  const getCountItems = () => {
    let count = 0;

    if (countRows && countRows.length > 0) {
      if(Array.isArray(state.filter.tab)) {
        state.filter.tab.forEach((tab) => {
          let index = findIndex(countRows, { 'status': tab });

          if (Number.isInteger(index) && index !== -1) {
            count += countRows[index].count;
          }
        });
      } else {
        let index = findIndex(countRows, { 'status': state.filter.tab });

        if (Number.isInteger(index) && index !== -1) {
          count = countRows[index].count;
        } else if (Number.isInteger(index) && index === -1 && state.filter.tab === 'ALL') {
          countRows.forEach(element => {
            count += element.count;
          });
        }
      }
    }

    return count;
  }

  if (loading) {
    return <Loader />
  }
  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <Box sx={style.wrapperTable}>
      <Grid
        columns={COLUMNS_MODEL_CREATOR}
        getRowId={(row) => row.id}
        rootComponent={GridRoot}
        rows={rows}
      >
        <RowDetailState />

        <PagingState
          currentPage={state.filter.page - 1}
          defaultCurrentPage={state.filter.page - 1}
          defaultPageSize={state.filter.limit}
          onCurrentPageChange={handleCurrentPageChange}
        />

        <CustomPaging totalCount={getCountItems()} />

        <VirtualTable
          cellComponent={(props) => (
            <VirtualTable.Cell className="OrdersTableCell" {...props} />
          )}
          columnExtensions={COLUMN_EXTENSIONS}
          messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
        />
        <TableHeaderRow />
        <PagingPanel containerComponent={MyPagerComponent} messages={PAGING_PANEL_MESSAGES} pageSizes={[ 10, 50, 0 ]} />
      </Grid>
    </Box>
  );
}