import { gql } from '@apollo/client/core';

import { SUPPLY_FRAGMENT } from "../fragments";

export const SHOP_SUPPLIES_CREATE = gql`
  ${SUPPLY_FRAGMENT}
  mutation ShopSuppliesCreate (
    $params: ShopSupplyCreateParams
  ) {
    shopSuppliesCreate (
      params: $params
    ) {
      supply {
        ...SupplyFragment
      }
    }
  }
`;
