import PropTypes from 'prop-types';
import React from 'react';

import { Box, Toolbar, Typography } from '@material-ui/core';

export default function PaymentPlanHeader({ actions, content, title }) {
  return (
    <Box position="sticky" top="0" zIndex={10}>
      <Box
        sx={{
          borderBottom: '1px solid #d2d2d2',
          borderRadius: '16px',
          mx: 2,
          background:
            'linear-gradient(to bottom,#f5f5f6,#f5f5f6),linear-gradient(80deg,rgba(255,255,255,0),rgba(61,70,86,.2))',
        }}
      >
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h3">{title}</Typography>
          </Box>

          <Box flex="0 0 auto">{actions}</Box>
        </Toolbar>
        <Box mb={3} px={3}>
          {content}
        </Box>
      </Box>
    </Box>
  );
}

PaymentPlanHeader.propTypes = {
  actions: PropTypes.node,
  content: PropTypes.node,
  title: PropTypes.node,
};
