import { gql } from '@apollo/client/core';

export const APPLICATION_TOGGLE_SUBSTATUS = gql`
  mutation ApplicationToggleSubstatus($applicationId: ID!, $substatusId: ID!) {
    applicationToggleSubstatus(
      applicationId: $applicationId,
      substatusId: $substatusId
    ) {
      application {
        id
        currentSubstatuses {
          id
        }
      }
    }
  }
`;