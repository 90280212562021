import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { ButtonBase, Link, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { DateFormatter } from './helpers';

CellName.propTypes = {
  row: PropTypes.object,
  selectPreviewCallback: PropTypes.func,
};

export default function CellName({ row, selectPreviewCallback }) {
  const handlePreview = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    selectPreviewCallback(row.id);
  }, [selectPreviewCallback, row.id]);

  return (
    <React.Fragment>
      <Typography>
        <Link
          component={ButtonBase}
          onClick={handlePreview}
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily,
            textAlign: 'left',
          }}
        >
          {row?.documentFilename}
        </Link>
      </Typography>
      <Typography color={grey[500]} fontSize="12px">
        {row?.author?.firstName}
        &nbsp;
        {row?.author?.lastName}
        &nbsp;
        <DateFormatter value={row?.createdAt} />
      </Typography>
    </React.Fragment>
  );
}
