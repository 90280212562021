import PropTypes from "prop-types";
import React from 'react';

import { Box } from '@material-ui/core';
import MarkEmailReadIcon from '@material-ui/icons/MarkEmailRead';

import { formatDate } from 'utils/dateTimeFormatters';

CellSentAt.propTypes = {
  row: PropTypes.object,
};

export default function CellSentAt({ row }) {
  if (row?.sentAt) {
    return (
      <Box
        sx={{
          fontFamily: 'Open Sans',
          display: 'inline-flex',
          alignItems: 'center',
          fontWeight: 600,
          color: '#22a053',
        }}
      >
        <MarkEmailReadIcon />
        &nbsp;&nbsp;
        {formatDate(row.sentAt)}
      </Box>
    );
  }

  return '';
}
