import PropTypes from 'prop-types';
import React from 'react';

import { IMaskInput } from "react-imask";

const defaultMask = "+{7} (000) 000-00-00";

// eslint-disable-next-line react/display-name
export const PhoneField = React.forwardRef(({ mask = defaultMask, onChange, ...props }, ref) => {
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask={mask}
      onAccept={(value) => onChange(value)}
      prepare={(appended, masked) => {
        if (appended === "8" && masked.value === "") {
          return "";
        }
        return appended;
      }}
    />
  );
});

PhoneField.propTypes = {
  mask: PropTypes.any,
  onChange: PropTypes.func,
};
