import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_UPLOAD = gql`
  mutation SourceDocumentsUpload(
    $file: Upload!,
    $isOriginalReceived: Boolean!,
    $sourceDocumentTypeId: ID!
    $comment: String
  ) {
    sourceDocumentsUpload(
      file: $file,
      isOriginalReceived: $isOriginalReceived,
      sourceDocumentTypeId: $sourceDocumentTypeId
      comment: $comment
    ) {
      sourceDocument {
        id
        documentUrl
        documentFilename
        isOriginalReceived
        recognizingProcess {
          status
        }
        status
        createdAt
      }
    }
  }
`;