import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from 'react';

import { useMutation } from "@apollo/client";
import {
  Button,
  Link,
  Box,
  Tooltip
} from "@material-ui/core";
import { useDropzone } from 'react-dropzone';

import { SHOP_SUPPLIES_UPDATE } from "../graphql/mutations/shopSuppliesUpdate";

ShipmentsModalUploadFile.propTypes = {
  row: PropTypes.object,
  setMessageError: PropTypes.func,
  showErrors: PropTypes.func,
  updateSupplies: PropTypes.func,
};

export default function ShipmentsModalUploadFile({ row, setMessageError, showErrors, updateSupplies }) {
  const styles = useMemo(() => ({
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '400px',
        borderRadius: '16px',
      }
    },
    button: {
      color: '#0D1D32',
      background: '#ECEDEE',
      borderRadius: '4px',
      textTransform: 'none',
      padding: '8px 17px',

      '& span': {
        fontSize: '14px !important',
        fontWeight: '500 !important',
      },

      '&:hover': {
        background: '#F3F4F5',
        color: '#3D6CE7'
      },

      '&:active': {
        background: '#E7E8EA',
        color: '3D6CE7',
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const [uploadOtherDocuments, { loading: uploading }] = useMutation(SHOP_SUPPLIES_UPDATE,
    {
      onCompleted: (res) => {
        updateSupplies(res.shopSuppliesUpdate.supply);
      },
      onError: error => {
        setMessageError(error)
        showErrors();
      },
    },
  );

  const [files, setFiles] = useState([]);
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: false
  });

  useEffect(() => {
    if (uploading) return false;

    if (files.length) {
      let assets = [];

      files.forEach(file => {
        assets.push({
          asset: file
        })
      })

      uploadOtherDocuments({
        variables: {
          supplyId: row.id,
          params: {
            shopSupplyMiscs: assets,
          }
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return (
    row?.shopSupplyMiscs?.length > 0 ? (
      <>
        {row?.shopSupplyMiscs.map(doc => (
          <Box key={doc?.id}>
            <Tooltip title={doc?.assetOriginalFilename}>
              <Link
                href={doc?.assetUrl}
                target={"_blank"}>
                Просмотреть
              </Link>
            </Tooltip>
          </Box>
        ))}

        <Box sx={{ fontSize: '12px', whiteSpace: "normal" }}>
          Описание: {row?.shopSupplyMiscs[0]?.explanation || "---"}
        </Box>
      </>
    ) : (
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />

        <Button disabled={uploading} sx={ styles.button }>
          Загрузить
        </Button>
      </div>
    )
  )
}