/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { PDFRendererSinglePage } from './PDFRendererSinglePage';
import { PDFRendererContext } from '../state';

export const PDFRendererAllPages = () => {
  const {
    state: { numPages },
  } = useContext(PDFRendererContext);

  const pages = [];
  for (let i = 0; i < numPages; i++) {
    pages.push(<PDFRendererSinglePage key={i + 1} pageNumber={i + 1} />);
  }

  return <>{pages}</>;
};