import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CurrencyFormatter from 'components/FormattedCurrency';
import LabelButton from 'components/LabelButton';

import CompanyCategorySelector from './CompanyCategorySelector';
import {
  GET_BANK_STATEMENT_CONTRACTOR,
} from '../graphql/queries/bankStatementContractor';

const FIELDS = ['Tin', 'Kpp', 'CheckingAccount', 'Account', 'Bic', 'Bank'];

DetailsOrder.propTypes = {
  disabledEdit: PropTypes.bool,
  onClose: PropTypes.func,
  row: PropTypes.any,
};

export default function DetailsOrder({ row = {}, onClose, disabledEdit }) {
  // Кем является Аренза в платеже
  const ourParty = row.income ? 'recipient' : 'payer';
  // Кем является контрагент в платеже
  const counterParty = row.income ? 'payer' : 'recipient';

  const { data } = useQuery(GET_BANK_STATEMENT_CONTRACTOR, {
    variables: { id: row[`${counterParty}Id`] },
    fetchPolicy: 'cache-only',
  });

  const contractorCategory = data?.bankStatementContractor.category || 'other';

  // Реквизиты контрагента
  const BankDetails = (
    <>
      <Typography sx={{ mt: 6, mb: 2 }} variant="h2">
        <FormattedMessage id={`bankStatement.${counterParty}`} />
      </Typography>
      <Typography paragraph variant="body1">
        {row[counterParty]}
      </Typography>
      <Box alignItems="center" display="flex" mb={4}>
        <Typography color="textSecondary" variant="body1">
          Категория
        </Typography>
        &nbsp;&nbsp;
        <CompanyCategorySelector
          contractorId={row[`${counterParty}Id`]}
          defaultCategory={contractorCategory}
          disabledEdit={disabledEdit}
        >
          {(props) => (
            <LabelButton
              bgcolor={`categories.${contractorCategory}`}
              sx={{
                cursor: disabledEdit ? 'default' : 'pointer'
              }}
              {...props}
            >
              <FormattedMessage id={`categories.${contractorCategory}`} />
            </LabelButton>
          )}
        </CompanyCategorySelector>
      </Box>
      {FIELDS.map((field) => {
        const sameAccounts =
          field === 'CheckingAccount' &&
          row[`${counterParty}CheckingAccount`] === row[`${counterParty}Account`];

        if (sameAccounts) {
          return null;
        }

        return (
          <React.Fragment key={field}>
            <Box display="flex" justifyContent="space-between" my={2}>
              <Typography color="textSecondary" variant="body1">
                <FormattedMessage id={`bankStatement.${field}`} />
              </Typography>
              <Typography variant="body1">
                {row[`${counterParty}${field}`]}
              </Typography>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <Box maxWidth={520} p={4}>
      <Box display="flex" justifyContent="flex-end" mb={2} mr={-2} mt={-3}>
        <IconButton edge="end" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={5}>
        <Box>
          <Typography variant="h2">№ {row.number}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">
            <FormattedDate
              day="numeric"
              month="numeric"
              value={row.orderDate}
              year="numeric"
            />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" my={2}>
        <Typography color="textSecondary" variant="body1">
          Сумма
        </Typography>
        <Typography variant="h3">
          {row.income ? '' : '−'}
          <CurrencyFormatter value={row.amount} />
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" my={2}>
        <Typography color="textSecondary" variant="body1">
          {row.income ? 'Счет пополнения' : 'Счет списания'}
        </Typography>
        <Typography variant="body1">
          {row[`${ourParty}CheckingAccount`]}
        </Typography>
      </Box>
      <Divider />
      {BankDetails}
      <Typography sx={{ mt: 6, mb: 2 }} variant="h2">
        Назначение
      </Typography>
      <Typography variant="body1">{row.paymentPurpose}</Typography>
    </Box>
  );
}
