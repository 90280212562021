import { gql } from '@apollo/client/core';

export const GET_FSSP_CHECK_PROCESS = gql`
  query GetFsspCheckProcess($id: ID!) {
    fsspCheckProcess(id: $id) {
      id
      createdAt
      finishedAt
      items {
        region {
          id
          name
        }
        isPaid
        subject
        ipEnd
        exeProduction
        exeProductionDate
        details
        department
        debtAmount
      }
      itemsCount
      state
      unpaidItemsCount
      unpaidSum
    }
  }
`;