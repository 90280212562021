import { gql } from '@apollo/client/core';

export const FSSP_START_CHECK_PROCESS = gql`
  mutation FsspStartCheckProcess($contactId: ID!) {
    fsspStartCheckProcess(contactId: $contactId) {
      fsspCheckProcess {
        id
        createdAt
        finishedAt
        itemsCount
        state
        unpaidItemsCount
        unpaidSum
      }
    }
  }
`;