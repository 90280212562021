import { gql } from '@apollo/client/core';

import { GOODS_FRAGMENT } from "../fragments";

export const SHOP_ORDERS_REMOVE_GOOD = gql`
  ${GOODS_FRAGMENT}
  
  mutation ShopOrdersRemoveGood (
    $orderId: ID!
    $goodId: ID!
  ) {
    shopOrdersRemoveGood (
      orderId: $orderId
      goodId: $goodId
    ) {
      order {
        amount
        amountWithDiscount
        goodsCount
        availableGoods {
          ...GoodsFragment

          storageAcceptance {
            displayDisputeUrl
            displayDisputeNumber
          }
        }
        unavailableGoods {
          ...GoodsFragment

          orders {
            id
          }
        }
      }
    }
  }
`;
