import PropTypes from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';

import {DEALS_PAGE_LIMIT, TABS} from "./constants";

const defaultState = {
  filter: {
    tab: TABS[0],
    page: 1,
    limit: DEALS_PAGE_LIMIT,
    sort: {
      field: 'CREATED_AT',
      order: 'DESC',
    },
  },
};

export const CollaboratorsContext = createContext({
  state: defaultState,
  updateFilter: () => {},
});

export const CollaboratorsProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const updateFilter = useCallback(
    (filters) => {
      setState(filters)
    }, []
  )

  return (
    <CollaboratorsContext.Provider value={{ state, updateFilter }}>
      {children}
    </CollaboratorsContext.Provider>
  );
};

CollaboratorsProvider.propTypes = {
  children: PropTypes.node,
};
