import PropTypes from 'prop-types';
import React from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import equifaxLogo from 'assets/equifax.svg';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';

import { LastCheckTimeSubheader } from './LastCheckTimeSubheader';
import {
  CREDIT_HISTORY_START_FETCH_PROCESS,
} from '../graphql/mutations/creditHistoryStartFetchProcess';
import {
  ON_CREDIT_HISTORY_FETCHING_PROCESS_UPDATED,
} from '../graphql/subscriptions/creditHistoryFetchingProcessUpdated';

CardContent.propTypes = {
  sx: PropTypes.object,
};
function CardContent({ sx, ...props }) {
  return <MuiCardContent sx={{ flex: 1, py: 0, ...sx }} {...props} />;
}

function PendingProcess() {
  return (
    <CardContent>
      <Typography variant="subtitle2">Запрос в БКИ выполняется...</Typography>
      <Typography variant="caption">
        Информация обновится автоматически
      </Typography>
    </CardContent>
  );
}

const STATE_TO_TEMPLATE = {
  CREATED: PendingProcess,
  STARTED: PendingProcess,
  FINISHED: FinishedProcess,
  FAILED: FailedProcess,
};

FailedProcess.propTypes = {};

function FailedProcess() {
  return (
    <CardContent>
      <Alert severity="error">
        Запрос был выполнен,
        <br />
        но БКИ вернул ошибку
      </Alert>
    </CardContent>
  );
}

FinishedProcess.propTypes = {
  summary: PropTypes.shape({
    activeCreditsSum: PropTypes.number,
    activeCreditsCount: PropTypes.number,
  }),
};

function FinishedProcess({ summary }) {
  return (
    <>
      <CardContent>
        <Typography
          sx={{ color: 'text.secondary', lineHeight: '20px', mb: 2 }}
          variant="caption"
        >
          Сумма активных кредитов
        </Typography>
        <Typography variant="h3">
          <Box
            sx={{
              color: summary.activeCreditsSum
                ? 'warning.dark'
                : 'success.dark',
            }}
          >
            <FormattedCurrency value={summary.activeCreditsSum} />
          </Box>
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id={`creditHistory.activeCreditsCount`}
            values={{ amount: summary.activeCreditsCount }}
          />
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          href={
            `${process.env.REACT_APP_CLOUD_HOST}${window.location.pathname}/credit-history`
          }
          rel="noopener noreferrer"
          size="small"
          target="_blank"
        >
          <FormattedMessage id="more" />
        </Button>
      </CardActions>
    </>
  );
}

CreditHistoryWidget.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    startedAt: PropTypes.string,
    failedAt: PropTypes.string,
    unpaidSum: PropTypes.number,
    unpaidItemsCount: PropTypes.number,
    state: PropTypes.oneOf(['CREATED', 'STARTED', 'FINISHED', 'FAILED']),
  }),
};
export default function CreditHistoryWidget({ contactId, data }) {
  const [startCreditHistoryCheckProcess, mutationResult] = useMutation(
    CREDIT_HISTORY_START_FETCH_PROCESS,
    {
      variables: { contactId },
      onError: (error) => {
        alert(`Произошла ошибка при запросе в БКИ Equifax ${error.message}`);
      },
    },
  );

  function handleClick() {
    startCreditHistoryCheckProcess();
  }

  if (mutationResult.data?.creditHistoryStartFetchProcess?.creditHistoryFetchProcess?.id) {
    return (
      <SubscriptionBlock
        processId={
          mutationResult.data.creditHistoryStartFetchProcess.creditHistoryFetchProcess.id
        }
      />
    );
  }

  if (!data) {
    return (
      <Layout>
        <CardContent>
          <Button
            disabled={mutationResult.loading}
            fullWidth
            onClick={handleClick}
            sx={{ height: '100%' }}
          >
            <FormattedMessage id="verification.checkButton" />
          </Button>
        </CardContent>
      </Layout>
    );
  }

  const { createdAt, failedAt, finishedAt, startedAt, state } = data;

  const Content = STATE_TO_TEMPLATE[state];

  return (
    <Layout
      action={
        (state === 'FINISHED' || state === 'FAILED') && (
          <IconButton
            disabled={mutationResult.loading}
            onClick={handleClick}
            sx={{ ml: 2 }}
          >
            <RenewIcon />
          </IconButton>
        )
      }
      subheader={
        <LastCheckTimeSubheader
          {...{ startedAt, createdAt, failedAt, finishedAt }}
        />
      }
    >
      <Content {...data} />
    </Layout>
  );
}

Layout.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  subheader: PropTypes.node,
};
function Layout({ action, children, subheader }) {
  return (
    <Card
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      variant="outlined"
    >
      <CardHeader
        action={action}
        avatar={
          <Box alt="Logo" component="img" src={equifaxLogo} width={64} />
        }
        subheader={
          subheader || (
            <Typography color="textSecondary" variant="caption">
              Запросов в БКИ не было
            </Typography>
          )
        }
        title={
          <Box fontSize={16} fontWeight={500}>
            БКИ Equifax
          </Box>
        }
      />
      {children}
    </Card>
  );
}

SubscriptionBlock.propTypes = {
  processId: PropTypes.any,
};
function SubscriptionBlock({ processId }) {
  const { data, error, loading } = useSubscription(
    ON_CREDIT_HISTORY_FETCHING_PROCESS_UPDATED,
    { variables: { processId } },
  );

  if (error) {
    return (
      <Layout>
        <CardContent>{error.message}</CardContent>
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout>
        <CardContent>
          <Skeleton />
          <Skeleton height={40} />
        </CardContent>
      </Layout>
    );
  }

  const { createdAt, failedAt, finishedAt, startedAt, state } =
    data.creditHistoryFetchingProcessUpdated;
  const Content = STATE_TO_TEMPLATE[state];

  return (
    <Layout
      subheader={
        <LastCheckTimeSubheader
          {...{ startedAt, createdAt, failedAt, finishedAt }}
        />
      }
    >
      <Content {...data.creditHistoryFetchingProcessUpdated} />
    </Layout>
  );
}
