import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';

import FormattedCurrency from 'components/FormattedCurrency';
import {
  LOAN_SUPPLIES_EXPORT_TO_ONEC,
} from 'modules/workspaces/graphql/mutations/loanSuppliesExportToOnec';
import { formatDate } from 'utils/dateTimeFormatters';

import DocumentCard from './DocumentCard';

const styles = {
  card: { boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)' },
  cardOrderContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  cardSupplyContent: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
  },
  cardDocSuccessAlert: {
    color: '#3DB205',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& svg': { width: 13, height: 13 },
  },
  cardDocWarningAlert: {
    color: '#D74E51',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& svg': { width: 13, height: 13 },
  },
  cardDocActionButton: {
    fontSize: 10,
    height: 16,
    backgroundColor: '#74B666',
    borderRadius: '2px',
    color: 'white',
    p: '6px 9px',
    transition: 'opacity .24s ease-in-out',

    '&[disabled]': { backgroundColor: 'rgba(47, 45, 40, 0.2)' },
    '&:hover': { opacity: 0.85 },
  },
  cardDocDeleteActionButton: {
    width: 16,
    height: 16,
    backgroundColor: '#D74E51',
    borderRadius: '2px',
    color: 'white',
    p: '6px 9px',
    transition: 'opacity .24s ease-in-out',

    '&:hover': { opacity: 0.85 },
    '& svg': { width: 13, height: 13 },
  },
  onecExportButton: {
    fontSize: 10,
    width: 'auto',
    height: 16,
    backgroundColor: '#74B666',
    borderRadius: '2px',
    color: 'white',
    p: '6px 9px',
    transition: 'opacity .24s ease-in-out',

    '&[disabled]': { backgroundColor: 'rgba(47, 45, 40, 0.2)' },
    '&:hover': { opacity: 0.85 },
  },
};

SupplyingOrder.propTypes = {
  order: PropTypes.object,
  refetchData: PropTypes.func,
};

function SupplyingOrder({ order, refetchData }) {
  const [supplyExportToOnec, { loading: supplyExporting }] = useMutation(
    LOAN_SUPPLIES_EXPORT_TO_ONEC,
    {
      onCompleted: (data) => {
        refetchData();
        alert(
          `Экспорт в 1C завершён со статусом: "${data.onecExportRequest?.status ||
          'нет данных'}"`,
        );
      },
      onError: () => alert('Ошибка экспорта в 1C, повторите позднее'),
    },
  );

  const handleExportToOnec = useCallback((supplyId) => () => {
    supplyExportToOnec({ variables: { supplyId } });
  }, [supplyExportToOnec]);

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardOrderContent}>
        <Typography>{order?.displayName}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Сумма
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              <FormattedCurrency value={order?.amount || ''} />
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Дата
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {formatDate(order?.date || '')}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {order?.supplies?.map((supply) => (
            <Box key={supply.id}>
              <Box sx={{ mb: 2 }}>
                <Divider sx={{ mb: 2 }} />
                {!supply?.onecExportRequest
                  ? (
                    <ButtonBase
                      disabled={supplyExporting}
                      onClick={handleExportToOnec(supply.id)}
                      sx={styles.onecExportButton}
                    >
                      Экспорт в 1C
                    </ButtonBase>
                  )
                  : (
                    <Typography sx={{ fontSize: 12 }}>
                      Статус экспорта в 1C:{' '}
                      <span style={{ color: '#856F42' }}>
                        {supply.onecExportRequest?.status || 'нет данных'}
                      </span>
                    </Typography>
                  )
                }
              </Box>

              <Box sx={styles.cardSupplyContent}>
                <Box
                  sx={{
                    width: 0.16,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                      Сумма
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      <FormattedCurrency value={supply?.amount || ''} />
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                      Дата
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatDate(supply?.date || '')}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ width: 0.42 }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    Документы отгрузки
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {!supply?.primaryDocuments?.length ? '—' : (
                      <React.Fragment>
                        {supply.primaryDocuments.map((document) => (
                          <DocumentCard key={document.id} {...{ document }} />
                        ))}
                      </React.Fragment>
                    )}
                  </Box>
                </Box>

                <Box sx={{ width: 0.42 }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    Прочие документы
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {!supply?.otherDocuments?.length ? '—' : (
                      <React.Fragment>
                        {supply.otherDocuments.map((document) => (
                          <DocumentCard key={document.id} {...{ document }} />
                        ))}
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card >
  );
}

SupplyingOrders.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  refetchData: PropTypes.func,
};

export default function SupplyingOrders({ data, loading, refetchData }) {
  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.length) {
    return 'Нет данных';
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {data.map((order) => (
        <SupplyingOrder key={order.id} {...{ order, refetchData }} />
      ))}
    </Box>
  );
}