import { gql } from '@apollo/client/core';

export const SUPPLYING_ORDERS_CREATE_SERVICE_ACCEPTANCE_CERTIFICATE = gql`
  mutation SupplyingOrdersCreateServiceAcceptanceCertificate(
    $attributes: SupplyingOrdersServiceAcceptanceCertificateTemplateAttributes!
    $loanSupplyIds: [ID!]!
  ) {
    supplyingOrdersCreateServiceAcceptanceCertificate(
      attributes: $attributes
      loanSupplyIds: $loanSupplyIds
    ) {
      document {
        assetUrl
        id
      }
    }
  }
`;
