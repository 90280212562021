/* eslint-disable react/display-name, react/prop-types */
import React  from 'react';

import { Link, Typography, Box, SvgIcon } from '@material-ui/core';
import { Link as RouterLink } from "@reach/router";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { ReactComponent as IconSeizureAct } from './img/seizureAct.svg';
import FormattedCurrency from "../../components/FormattedCurrency";
import WrapperButton from "../../components/WrapperButton";
import { DATE_TIME_FORMAT_VIEW } from "../../core/constants/Formats";

export const TABS_ACCEPTANCE = ['ACCEPTANCE_STARTED', 'ACCEPTED', 'READY_FOR_ACCEPTANCE']

/* start table */
export const COLUMN_EXTENSIONS_STRUCTURE_ACCEPTANCE_TO_WAREHOUSE = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'borrowerEntity',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'dispute',
    wordWrapEnabled: true,
    align: 'center',
    width: 190,
  },
  {
    columnName: 'dateSeizure',
    wordWrapEnabled: true,
    align: 'center',
    width: 210,
  },
  {
    columnName: 'numberSeizure',
    wordWrapEnabled: true,
    align: 'center',
    width: 230,
  },
  {
    columnName: 'dateAcceptance',
    wordWrapEnabled: true,
    align: 'center',
    width: 210,
  },
  {
    columnName: 'shippedEquipmentsCount',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'shippedEquipments',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'actions',
    width: 240,
    align: 'right',
  },
];
export const COLUMNS_MODEL_CREATOR_ACCEPTANCE_TO_WAREHOUSE = [
  {
    name: 'id',
    title: 'ID Акта',
    getCellValue: ({ application, id, seizureAct }) => {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
          <Link href={seizureAct?.dispayDocumentScanUrl} sx={{ lineHeight: "normal !important" }} target={"_blank"}>
            <SvgIcon component={IconSeizureAct} sx={{ width: "24px", height: "24px"  }} />
          </Link>

          <Link href={`${process.env.REACT_APP_HOST}/applications/${application?.id}/deal/dispute`}>
            <Typography>
              {id}
            </Typography>
          </Link>
        </Box>
      )
    },
  },
  {
    name: 'borrowerEntity',
    title: 'Лизингополучатель',
    getCellValue: ({ borrowerEntity }) => {
      return (
        borrowerEntity?.id ? (
          <Link href={`${process.env.REACT_APP_HOST}/entities/${borrowerEntity.id}`}>
            {borrowerEntity?.shortLegalName}
          </Link>
        ) : "Лизингополучатель отсутствует"
      )
    },
  },
  {
    name: 'dispute',
    title: '№ заявки',
    getCellValue: ({ application }) => {
      return (
        application?.id ? (
          <Link href={`${process.env.REACT_APP_HOST}/applications/${application?.id}/deal/`}>
            {application?.id}
          </Link>
        ) : "Номер дела отсутствует"
      )
    },
  },
  {
    name: 'dateSeizure',
    title: 'Дата изъятия / ФИО',
    getCellValue: ({ seizureAct }) => {
      return (
        <Box sx={{ fontSize: "12px", fontWeight: 300 }}>
          <Box sx={{ color: "#0D1D32" }}>
            {seizureAct?.date ? (
              dayjs(seizureAct?.date).utc(true).startOf("day").format(DATE_TIME_FORMAT_VIEW)
            ) : "Дата отсутствует"}
          </Box>

          <Typography sx={{ color: "#6E7884",  }}>
            {seizureAct?.collector?.displayName || "Коллектор отсутствует"}
          </Typography>
        </Box>
      )
    },
  },
  {
    name: 'numberSeizure',
    title: '№ Изъятия',
    getCellValue: ({ application, dispute }) => {
      return (
        dispute?.number && application?.id ? (
          <Link href={`${process.env.REACT_APP_HOST}/applications/${application?.id}/deal/dispute`}>
            {dispute?.number}
          </Link>
        ) : "Номер изъятия отсутствует"
      )
    },
  },
  {
    name: 'dateAcceptance',
    title: 'Дата приемки / ФИО',
    getCellValue: ({ acceptedAt, acceptedBy }) => {
      return (
        <Box sx={{ fontSize: "12px", fontWeight: 300 }}>
          <Box sx={{ color: "#0D1D32" }}>
            {acceptedAt ? (
              dayjs(acceptedAt).utc(true).startOf("day").format(DATE_TIME_FORMAT_VIEW)
            ) : "Дата отсутствует"}
          </Box>

          <Typography sx={{ color: "#6E7884",  }}>
            {acceptedBy?.displayName || "ФИО приемщика отсутствуют"}
          </Typography>
        </Box>
      )
    },
  },
  {
    name: 'shippedEquipmentsCount',
    title: 'Кол-во отгружено',
  },
  {
    name: 'shippedEquipments',
    title: 'Кол-во принято',
    getCellValue: ({ supplies }) => {
      let shopGoodsCount = 0;

      if (supplies?.length) {
        supplies.forEach(supplier => {
          if (supplier?.shippedEquipments?.length) {
            supplier?.shippedEquipments.forEach( good => {
              shopGoodsCount += good.shopGoodsCount
            });
          }
        })
      }

      return (
        <Box>
          {shopGoodsCount}
        </Box>
      )
    },
  },
  {
    name: 'actions',
    title: ' ',
    getCellValue: ({ id, status }) => {
      // eslint-disable-next-line default-case
      switch (status) {
        case "READY_FOR_ACCEPTANCE":
          return (
            <WrapperButton
              colorScheme={"orangeStroke"}
              component={RouterLink}
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              to={`/workspaces/receipt-to-warehouse/seizure-act/${id}`}
            >

              Начать приемку
            </WrapperButton>
          )

        case "ACCEPTANCE_STARTED":
          return (
            <WrapperButton
              colorScheme={"breeze"}
              component={RouterLink}
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              to={`/workspaces/receipt-to-warehouse/seizure-act/${id}`}
            >

              Продолжить приемку
            </WrapperButton>
          )

        case "ACCEPTED":
          return (
            <WrapperButton
              colorScheme={"green"}
              href={`/workspaces/receipt-to-warehouse/seizure-act/${id}`}
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              Принято на склад
            </WrapperButton>
        )
      }
    },
  }
];
/* end table */

/* start table */
export const COLUMN_EXTENSIONS_STRUCTURE_SEIZURE_ACT = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'name',
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'shopGoodsCount',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'quantity',
    align: 'center',
    width: 140,
  },
  {
    columnName: 'unit',
    align: 'center',
    width: 140,
  },
  {
    columnName: 'pricePerUnit',
    align: 'left',
    width: 140,
  },
  {
    columnName: 'totalPrice',
    align: 'left',
    width: 140,
  },
  {
    columnName: 'actions',
    align: 'right',
    width: 340,
  },
];
export const COLUMNS_MODEL_CREATOR_SEIZURE_ACT = [
  {
    name: 'id',
    title: '№'
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <FormattedMessage id={`seizureAct.shippedEquipments.status.${status}`} />
      )
    },
  },
  {
    name: 'name',
    title: 'Название'
  },
  {
    name: 'quantity',
    title: 'Кол-во',
    getCellValue: ({ quantity, shopGoodsCount }) => {
      return (
        <Box>
          {shopGoodsCount}/{quantity}
        </Box>
      )
    },
  },
  {
    name: 'unit',
    title: 'Единиц'
  },
  {
    name: 'pricePerUnit',
    title: 'Цена за шт. (руб.)',
    getCellValue: ({ pricePerUnit }) => {
      return (
        <FormattedCurrency value={pricePerUnit} />
      )
    },
  },
  {
    name: 'totalPrice',
    title: 'Сумма (руб.)',
    getCellValue: ({ totalPrice }) => {
      return (
        <FormattedCurrency value={totalPrice} />
      )
    },
  },
  {
    name: 'actions',
    title: ' ',
  }
];
/* end table */

/* start table */
export const COLUMN_EXTENSIONS_STRUCTURE_SEIZURE_ACT_GOODS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 240,
  },
  {
    columnName: 'name',
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'shopGoodsCount',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'price',
    align: 'left',
    width: 230,
  },
];
export const COLUMNS_MODEL_CREATOR_SEIZURE_ACT_GOODS = [
  {
    name: 'id',
    title: '№'
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <FormattedMessage id={`goods.status.${status}`} />
      )
    },
  },
  {
    name: 'name',
    title: 'Название'
  },
  {
    name: 'price',
    title: 'Цена',
    getCellValue: ({ priceWithDiscount, vatPercent }) => {
      const vatRate  = vatPercent / 100;
      const trueVatWithRound =  (priceWithDiscount * vatRate / (1 + vatRate)).toFixed(0);

      return (
        <Box>
          {priceWithDiscount > 0 ? (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}><FormattedCurrency minimumFractionDigits={0} value={parseFloat(priceWithDiscount).toFixed(0)} /></Typography>
          ) : (
            '--'
          )}

          {vatPercent > 0 && priceWithDiscount > 0 && (
            <>
              <br />
              <Typography component={"span"} sx={{ fontWeight: 300, color: '#6E7884', }}>(в т.ч. НДС <FormattedCurrency minimumFractionDigits={0} value={trueVatWithRound} />)</Typography>
            </>
          )}
        </Box>
      )
    },
  },
];
/* end table */


