import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import {
  DataTypeProvider,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Typography, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { cloneDeep, upperFirst, map } from "lodash";
import { DateTime } from 'luxon';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';
import { customSort } from 'utils/customSort';
import { formatDate } from 'utils/dateTimeFormatters';

CurrencyFormatter.propTypes = {
  row: PropTypes.shape({
    creditCurrencyText: PropTypes.string,
  }),
  value: PropTypes.any,
};
function CurrencyFormatter({ row, value }) {
  return (
    <b style={{ color: 'darkblue' }}>
      <FormattedNumber
        currency={row.creditCurrencyText}
        currencyDisplay="symbol"
        style="currency" // eslint-disable-line react/style-prop-object
        value={value}
      />
    </b>
  );
}

const DateFormatter = ({ value }) => (
  <FormattedDate
    day="numeric"
    hour="numeric"
    minute="numeric"
    month="numeric"
    value={value}
    year="numeric"
  />
);

DateFormatter.propTypes = {
  value: PropTypes.any,
};

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const sortBy = [
  '-',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'S',
  'R',
  'W',
  'U',
  'T',
  'I',
];

PaymentDiscipline.propTypes = {
  creditStartDate: PropTypes.string,
  paymentDiscipline: PropTypes.string,
};
function PaymentDiscipline({ creditStartDate, paymentDiscipline }) {
  const arr = paymentDiscipline?.split('')  || [];
  const monthNumber = Number(creditStartDate?.slice(5, 7) || 1);
  const yearBeg = Number(creditStartDate?.slice(0, 4));

  const emptyCells = monthNumber - 1;

  const newArr = [...arr, ...new Array(emptyCells)].reverse();

  const matrix = newArr.reduce(
    (rows, key, index) =>
      (index % 12 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
    [],
  );

  const uniq = [...new Set(arr)];

  const sortedItems = customSort({
    data: uniq.map((item) => (sortBy.includes(item) ? item : undefined)),
    sortBy: [...sortBy, undefined],
  });

  return (
    <Box>
      <Typography variant="subtitle1">Платежная дисциплина</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'repeat(13, auto)',
          gridTemplateColumns: '48px repeat(auto-fit, 64px)',
          gridGap: 2,
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        {[...new Array(12)].map((m, index) => (
          <Box
            key={index}
            sx={{
              gridRow: index + 2,
              gridColumn: 1,
              fontWeight: 600,
              justifySelf: 'flex-start',
            }}
          >
            <FormattedMessage id={`month.${index + 1}`} />
          </Box>
        ))}
        {matrix.map((months, indexColumn) => (
          <React.Fragment key={indexColumn}>
            <Box
              sx={{
                width: 48,
                gridRow: 1,
                gridColumn: indexColumn + 2,
                textAlign: 'center',
                fontWeight: 600,
                py: 2,
              }}
            >
              {yearBeg + indexColumn}
            </Box>
            {months.map((month, index) => (
              <Box
                key={index}
                sx={{
                  width: 48,
                  height: 20,
                  gridRow: index + 2,
                  gridColumn: indexColumn + 2,
                  textAlign: 'center',
                  borderRadius: 1,
                  bgcolor: `equifax.${month}`,
                  color: (theme) =>
                    month ? theme.palette.getContrastText(theme.palette.equifax[month]) : 'initial',
                  fontWeight: 600,
                }}
              >
                {month}
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography gutterBottom variant="subtitle2">
          Обозначения
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          }}
        >
          {sortedItems.map((item) => (
            <Box key={item} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Box
                sx={{
                  width: 48,
                  height: 20,
                  bgcolor: item ? `equifax.${item}` : 'initial',
                  fontWeight: 600,
                  textAlign: 'center',
                  lineHeight: '20px',
                  borderRadius: 1,
                  color: (theme) =>
                    item ? theme.palette.getContrastText(theme.palette.equifax[item]) : 'initial',
                }}
              >
                {item}
              </Box>
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <FormattedMessage id={`equifax.${item}`} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

RowDetail.propTypes = {
  row: PropTypes.object,
};
function RowDetail({ row }) {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography>
            Дата актуальности: <b>{row.creditLastUpdate}</b>
          </Typography>
          <Typography>
            Источник информации: <b>КБ Эквифакс</b>
          </Typography>
          <Typography>
            Текущая просроченная задолженность: <b> <FormattedCurrency currency={''} maximumFractionDigits={0} placeholder="---" value={row.currentOverdueDebt} /> </b>
          </Typography>
        </Box>
        <Box>
          <Typography>
            Закрытых просрочек до 30 дней: <b>{row.delay30}</b>
          </Typography>
          <Typography>
            Закрытых просрочек от 31 до 60 дней: <b>{row.delay60}</b>
          </Typography>
          <Typography>
            Закрытых просрочек от 61 до 90 дней: <b>{row.delay90}</b>
          </Typography>
          <Typography>
            Закрытых просрочек более 90 дней: <b>{row.delayMore}</b>
          </Typography>
        </Box>
      </Box>

      {row.showDiscipline && (
        <>
          <PaymentDiscipline
            creditStartDate={row.creditDatabeg}
            paymentDiscipline={row.paymentDiscipline}
          />
        </>
      )}
    </Box>
  );
}

function calcDiff(start, end) {
  if (start && end) {
    const startDateTime = DateTime.fromISO(start);
    const stopDateTime = DateTime.fromISO(end);

    return stopDateTime.diff(startDateTime, ['years', 'months']);
  }
  return {};
}

CreditTerm.propTypes = {
  months: PropTypes.number,
  years: PropTypes.number,
};
function CreditTerm({ months, years }) {
  if (!months && !years) {
    return null;
  }

  const monthsInt = Math.trunc(months);
  if (years < 1) {
    return <FormattedMessage id="month.plural" values={{ amount: monthsInt }} />;
  }

  if (monthsInt === 0) {
    return <FormattedMessage id="year.plural" values={{ amount: years }} />;
  }

  if (monthsInt > 6) {
    return (
      <>
        ≈ <FormattedMessage id="year.plural" values={{ amount: years + 1 }} />
      </>
    );
  }

  return (
    <>
      ≈ <FormattedMessage id="year.plural" values={{ amount: years }} />
    </>
  );
}

TableContactCreditHistory.propTypes = {
  data: PropTypes.array,
};
export default function TableContactCreditHistory({ data = [] }) {
  const styles = useMemo(() => ({
    wrapperTable: {
      border: '8px solid whitesmoke',
      borderRadius: '8px'
    },
    table: {
      px: 3,

      '& .guarantees_contract': {
        marginLeft: '0 !important'
      },
      '& .simple_contract': {
        marginLeft: '0 !important'
      },
      '& .hide': {
        display: 'none',
      },
      '& .iconB.MuiIconButton-root': {
        width: '14px',
        height: '14px',
        marginRight: '6px',
        transform: 'rotate(180deg)',

        '&.activeSpoiler': {
          transform: 'rotate(0deg)',
        }
      },
    },
    tableGrid: {
      marginTop: '20px',

      '& thead': {
        background: 'whitesmoke',
      }
    },
  }), [])
  const [sortData, updSortData] = useState({
    simple_contract: {
      show: false,
      active: [],
      closed: [],
      overdue: [],
      sold: [],
      unknown: [],
    },
    guarantees_contract: {
      show: false,
      responsibilitu_does_not_come: {
        show: false,
        active: [],
        closed: [],
        overdue: [],
        sold: [],
        unknown: [],
      },
      subject_pays: {
        show: false,
        active: [],
        closed: [],
        overdue: [],
        sold: [],
        unknown: [],
      },
    },
  });

  useEffect(() => {
    let draft = cloneDeep(sortData);

    const contractDistribution = (type, elem) => {
      let stArr = [...type.split(".")];

      const pushItem = (val) => {
        if (stArr.length > 1) {
          draft[stArr[0]].show = true;
          draft[stArr[0]][stArr[1]].show = true;
          draft[stArr[0]][stArr[1]][val].push(elem);
        } else {
          draft[stArr[0]].show = true;
          draft[stArr[0]][val].push(elem);
        }
      }

      switch (elem.creditStatusText.toLowerCase()) {
        case 'активен':
          pushItem('active');

          break;
        case 'закрыт':
          pushItem('closed');

          break;
        case 'просрочен':
          pushItem('overdue');

          break;
        case 'продан':
          pushItem('sold');

          break;
        default:
          pushItem('unknown');
      }
    }

    data.length && data.forEach(elem => {
      const cloneElem = cloneDeep(elem);
      cloneElem.showDiscipline = true;

      if (cloneElem?.relationText?.toLowerCase() === 'поручитель') {
        if (cloneElem.paymentDiscipline === '1') {
          cloneElem.showDiscipline = false;
          contractDistribution('guarantees_contract.responsibilitu_does_not_come', cloneElem);
        } else {
          contractDistribution('guarantees_contract.subject_pays', cloneElem);
        }
      } else {
        contractDistribution('simple_contract', cloneElem);
      }
    });

    updSortData(draft);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [columns] = useState([
    {
      name: 'dates',
      title: (
        <>
          Описание кредита
          <Typography color="text.secondary" component="div" variant="caption">
            Тип, ставка % по кредиту, срок
          </Typography>
        </>
      ),
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => {
        const { months, years } = calcDiff(row.creditDatabeg, row.creditDataend);

        return (
          <>
            <Typography sx={{ fontWeight: 500, mb: 1 }} variant="inherit">
              {row?.relationText?.toLowerCase() === 'поручитель' ? (
                'Поручительство'
              ) : (
                upperFirst(row.creditTypeText)
              )}
              ,&nbsp;&nbsp;
              {row.creditFullCost ? (
                <Typography
                  color="warning.dark"
                  component="span"
                  sx={{ fontWeight: 500 }}
                  variant="inherit"
                >
                  {row.creditFullCost}%
                </Typography>
              ) : (
                '---'
              )}
            </Typography>
            {row.creditDatabeg ? formatDate(row.creditDatabeg) : '__.__.____'} -{' '}

            {row.creditTypeText?.toLowerCase() === 'кредитная карта' ? (
              '__.__.____'
            ) : (
              <>
                {row.creditDataend ? formatDate(row.creditDataend) : '__.__.____'}
                {(years >= 1 || months >= 1) && ', '}
                <Typography component="span" sx={{ fontWeight: 500 }} variant="inherit">
                  <CreditTerm {...{ years, months }} />
                </Typography>
              </>
            )}
          </>
        );
      },
    },
    {
      name: 'discipline',
      title: 'Статусы платежей',
      // eslint-disable-next-line react/display-name,react/prop-types
      getCellValue: ({ overdueStateText, paymentDiscipline, relationText, worstOverdueStateText, }) => {
        //eslint-disable-next-line react/prop-types
        if ( relationText?.toLowerCase() === 'поручитель' && paymentDiscipline === '1')  return false;
        if (
          //eslint-disable-next-line react/prop-types
          overdueStateText.match(/платеж в срок/i) &&
          //eslint-disable-next-line react/prop-types
          worstOverdueStateText.match(/платеж в срок/i)
        ) {
          return (
            <Typography color="success.dark" sx={{ fontWeight: 500 }} variant="inherit">
              Все платежи в срок
            </Typography>
          );
        }
        return (
          <>
            {/* eslint-disable-next-line react/prop-types */}
            {overdueStateText.match(/платеж в срок/i)
              ? 'Текущий платеж в срок'
              : // eslint-disable-next-line react/prop-types
              'Текущая просрочка' + overdueStateText.replace('Просрочка', '')}
            <br />
            {/* eslint-disable-next-line react/prop-types */}
            Макс просрочка {worstOverdueStateText.replace('Просрочка', '').replace('от 6 до 29 дней', 'от 1 до 29 дней')}
          </>
        );
      },
    },
    {
      name: 'currentDebt',
      title: (
        <>
          Ежемесячный платеж
          <Typography color="text.secondary" component="div" variant="caption">
            Текущая задолженность
          </Typography>
        </>
      ),
      // eslint-disable-next-line react/display-name,react/prop-types
      getCellValue: ({ currentOutstandingDebt, currentSumPaid, nextPaymentSum }) => {
        return (
          <Typography sx={{ fontFamily: 'Open Sans', fontSize: '0.9rem' }}>
            <Typography
              color={nextPaymentSum > 0 ? 'warning.dark' : 'success.dark'}
              sx={{ fontWeight: 600 }}
              variant="inherit"
            >
              <FormattedCurrency value={nextPaymentSum} />
            </Typography>
            <Typography
              color={currentOutstandingDebt > 0 ? 'text.secondary' : 'text.disabled'}
              variant="inherit"
            >
              <FormattedCurrency value={currentOutstandingDebt} />
            </Typography>
          </Typography>
        );
      },
    },
    {
      name: 'creditSum',
      title: (
        <>
          Сумма займа
          <Typography color="text.secondary" component="div" variant="caption">
            Выплаченная сумма
          </Typography>
        </>
      ),
      // eslint-disable-next-line react/display-name,react/prop-types
      getCellValue: ({ creditSum, currentSumPaid }) => {
        return (
          <Typography sx={{ fontFamily: 'Open Sans', fontSize: '0.9rem' }}>
            <FormattedCurrency value={creditSum} />
            <Typography color="success.dark" sx={{ fontWeight: 600 }} variant="inherit">
              {typeof currentSumPaid === 'number' ? (
                <FormattedCurrency value={currentSumPaid} />
              ) : (
                '---'
              )}
            </Typography>
          </Typography>
        );
      },
    },
  ]);

  const [columnsOther] = useState([
    {
      name: 'creditStatusText',
      title: 'Статус',
    },
    ...columns,
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'creditSum', align: 'right', width: 180 },
    { columnName: 'currentDebt', align: 'right', width: 180 },
    { columnName: 'discipline' },
    { columnName: 'creditStatusText', width: 120, wordWrapEnabled: true },
  ]);
  const [dateColumns] = useState(['createdAt']);

  const onToggleHide = event => {
    let dom = event.target.closest("div");
    dom.querySelector('.MuiButtonBase-root').classList.toggle('activeSpoiler');
    dom.parentNode.querySelector('.children').classList.toggle('hide');
  }

  const renderTable = data => {
    return map(data, (value, key, index) => {
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        if (!data[key].show) return false;

        return (
          <Box className={key} sx={{ margin: '16px 0 16px 16px' }}>
            <Box sx={{ display: 'flex' }}>
              <IconButton className={'iconB'} onClick={onToggleHide}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
              <Typography>
                <FormattedMessage id={`equifax.${key}`}/>
              </Typography>
            </Box>

            <Box className={'children'}>
              {renderTable(data[key])}
            </Box>
          </Box>
        );
      } else if (Array.isArray(data[key]) && data[key].length) {
        return (
          <Box className={key} sx={{ margin: '16px 0 16px 16px' }}>
            <Box sx={{ display: 'flex' }} >
              <IconButton className={'iconB'} onClick={onToggleHide}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>

              <Typography>
                <FormattedMessage id={`equifax.${key}`}/>
              </Typography>
            </Box>

            <Box className={'children'} sx={ styles.tableGrid }>
              <Grid columns={key === 'unknown' ? columnsOther : columns} rows={data[key]}>
                <RowDetailState />
                <DateTypeProvider for={dateColumns} />
                <Table columnExtensions={tableColumnExtensions} />
                <TableHeaderRow />
                <TableRowDetail contentComponent={RowDetail} />
              </Grid>
            </Box>
          </Box>
        )
      }
    });
  }

  if (!data.length) {
    return (
      <Typography>Договоры отсутствуют</Typography>
    )
  }

  return (
    <Box sx={ styles.wrapperTable }>
      <Box sx={ styles.table }>
        {renderTable(sortData)}
      </Box>
    </Box>
  );
}
