import React, {useCallback, useContext, useEffect, useState} from 'react';

import {Box, Link} from '@material-ui/core';
import {cloneDeep} from "lodash";
import {useDebouncedCallback} from "use-debounce";

import {OrdersContext} from "./OrdersContext";
import OrdersFiltersModal from "./OrdersFiltersModal";
import Search from "../../components/Search";
import {SEARCH_DEBOUNCE} from "../../core/constants/searchDebounce";

export default function OrdersFilters() {
  const [ modalOpened, setModalOpened ] = useState(false);
  const { state, updateFilter } = useContext(OrdersContext);
  const [ countActiveFilters, setCountActiveFilters ] = useState(0)

  const handleOpenFilter = useCallback(() => setModalOpened(true), []);
  const handleCloseFilter = useCallback(() => setModalOpened(false), []);

  const debounced = useDebouncedCallback(value => {
    const newState = cloneDeep(state);

    newState.filter.search = value;
    updateFilter(newState);
  }, SEARCH_DEBOUNCE)

  useEffect(() => {
    let count = 0;

    if (state.filter.date) {
      count += 1;
    }

    if (state.filter.arenzaManagerUserIdIn) {
      count += 1;
    }

    setCountActiveFilters(count);
  }, [state])

  return (
    <>
      <Box sx={{ width: '120px', marginRight: '42px' }}>
        <Link
          component="button"
          onClick={handleOpenFilter}
          sx={{
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px dashed',
            mx: 3,
          }}
          underline="none"
          variant="body1"
        >
          Фильтр ({countActiveFilters})
        </Link>
      </Box>

      {modalOpened && (
        <OrdersFiltersModal onClose={handleCloseFilter} />
      )}

      <Search
        onChange={debounced.callback}
        placeholder={"Инн или название лизингополучателя"}
        sx={{ margin: '0 !important', width: 'calc( 100% - 140px - 102px)', height: '48px', fontSize: '15px', }}
        value={state.filter.search}
      />
    </>
  );
}
