import { gql } from '@apollo/client';

import { CREDIT_HISTORY_FETCH_PROCESS_FRAGMENT } from '../fragments';

export const CREDIT_HISTORY_CURRENT_FETCH_PROCESS = gql`
  query CreditHistoryCurrentFetchProcess($contactId: ID!, $id: ID) {
    creditHistoryCurrentFetchProcess(contactId: $contactId, id: $id) {
      ...CreditHistoryFetchProcessFragment
    }
  }
  ${CREDIT_HISTORY_FETCH_PROCESS_FRAGMENT}
`;