import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';

import DocumentsGroup from './DocumentsGroup';

OtherDocuments.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
};

OtherDocuments.defaultProps = {
  title: "Прочие документы",
}

export default function OtherDocuments({ onChange, title }) {
  return (
    <Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <DocumentsGroup onFilesDropped={onChange} />
        </Box>
        <Box
          component="fieldset"
          sx={{
            position: 'absolute',
            top: -5,
            left: -2,
            right: -2,
            bottom: 0,
            px: 2,
            minWidth: 0,
            border: '1px solid rgba(0, 0, 0, 0.26)',
            borderRadius: '4px',
          }}
        >
          <Box
            component="legend"
            sx={{ fontSize: 12, p: '0 5px', color: 'rgba(0, 0, 0, 0.6)' }}
          >
            {title}
          </Box>
        </Box>
      </Box>
      <Typography sx={{ color: '#838080', fontSize: 12, mt: 1 }}>
        Загрузите дополнительный документ к отгрузке{' '}
        (доверенность, акт п/п, и т.д.)
      </Typography>
    </Box>
  );
}