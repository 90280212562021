import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

AzDropzone.propTypes = {
  canGlobDrag: PropTypes.bool,
  multiple: PropTypes.bool,
  onFilesDropped: PropTypes.func,
  title: PropTypes.node
}

AzDropzone.defaultProps = {
  canGlobDrag: true,
  title: "Перетяните или нажмите на эту область",
  multiple: true,
}

const styles = {
  dropZoneCompactBox: {
    borderRadius: 1,
    mb: 2,
    p: 3,
    border: '2px dashed grey',
    background: 'rgb(234, 244, 252)',
    textAlign: 'center',
    cursor: 'pointer',
  },
  dropZoneFullBox: {
    p: 1,
    border: '10px dashed lightgrey',
    height: 1,
    borderRadius: 2,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dropZoneText: {
    fontSize: '2.5rem',
    verticalAlign: '1.5rem',
    display: 'inline-block',
  },
  dropZoneOverlay: {
    p: 8,
    position: 'absolute',
    background: 'rgba(0,0,0,0.8)',
    top: 0,
    left: 0,
    zIndex: '9999',
    width: '100%',
    height: 1,
  },
};

export default function AzDropzone({ canGlobDrag, multiple, onFilesDropped, title }) {
  const [isGlobalDrag, setIsGlobalDrag] = useState(false);

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (onFilesDropped instanceof Function) {
        onFilesDropped(
          acceptedFiles.map(
            (file) => Object.assign(file, {
              id: uuid(),
              preview: URL.createObjectURL(file),
              uploaded: false,
              uploading: false,
              isOriginalReceived: true,
              documentCategory: null,
              documentType: null
            }),
          ),
        );
      }
    },
    multiple
  });

  const handleDragIn = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isGlobalDrag) {
      setIsGlobalDrag(true);
    }
  }, [isGlobalDrag]);

  const handleDragOut = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!event.screenX && !event.screenY) {
      setIsGlobalDrag(false);
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsGlobalDrag(false);
  }, []);

  // В связи с тем, что в тз нарисована обработка `DragEvent` ивентов
  // на всем окне, обрабатываем все окно. Далее - смотрим что
  // происходит со стейтом `isGlobalDrag`
  useEffect(() => {
    if (canGlobDrag) {
      window.addEventListener('dragenter', handleDragIn);
      window.addEventListener('dragleave', handleDragOut);
      window.addEventListener('drop', handleDrop);
    }

    return () => {
      window.removeEventListener('dragenter', handleDragIn);
      window.removeEventListener('dragleave', handleDragOut);
      window.removeEventListener('drop', handleDrop);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGlobDrag]);

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <Box sx={styles.dropZoneCompactBox}>
        {title}
      </Box>
      {isGlobalDrag && (
        <Box sx={styles.dropZoneOverlay}>
          <Box sx={styles.dropZoneFullBox}>
            <Typography sx={{ color: 'white', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '5rem', display: 'inline-block' }}>
                <NoteAddIcon fontSize="inherit" />
              </Typography>
              <Typography sx={styles.dropZoneText}>
                Отпустите, чтобы загрузить
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}