import PropTypes from 'prop-types';
import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink} from "@reach/router";

import WrapperButton from "../../../components/WrapperButton";

LinkForSeizureAct.propTypes = {
  row: PropTypes.object,
  statusAcceptance: PropTypes.string,
  storageAcceptanceApplicationIdEq: PropTypes.string,
  storageAcceptanceId: PropTypes.string,
};

export default function LinkForSeizureAct({ row, statusAcceptance, storageAcceptanceApplicationIdEq, storageAcceptanceId }) {
  const renderButtonInProduct = () => {
    if (statusAcceptance === "ACCEPTANCE_STARTED" || statusAcceptance === "ACCEPTED") {
      if (row?.shopGoodsCount === 0) return false;

      let url = ''

      if (statusAcceptance === "ACCEPTED") {
        url = `/workspaces/shop?storageAcceptanceIdEq=${storageAcceptanceId}&shippedEquipmentIdEq=${row.id}`
      } else if (statusAcceptance === "ACCEPTANCE_STARTED") {
        url = `/workspaces/shop?storageAcceptanceApplicationIdEq=${storageAcceptanceApplicationIdEq}&shippedEquipmentIdEq=${row.id}`
      }

      return (
        <WrapperButton
          colorScheme={"breeze"}
          href={url}
          sx={{ marginRight: "10px" }}
          target="_blank"
        >
          К товарам
        </WrapperButton>
      )
    }
  }

  return (
    <>
      {renderButtonInProduct()}

      <WrapperButton
        colorScheme={"breeze"}
        component={RouterLink}
        disabled={row?.quantity <= row?.shopGoodsCount || statusAcceptance === "ACCEPTED"}
        to={`/workspaces/receipt-to-warehouse/${storageAcceptanceId}/parameters-warehouse/${row.id}`}
      >
        <AddIcon sx={{ marginRight: '8px', width: "17px", height: "auto", marginLeft: 0 }}/>
        Принять на склад
      </WrapperButton>
    </>
  );
}