import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, IconButton, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import CopiedText from 'components/CopiedText';
import DelayedRender from 'components/DelayedRender';

import BlockAccountHistory from './BlockAccountHistory';
import BlockAccountState from './BlockAccountState';
import DrawerOrders from './DrawerOrders';
import { SectionResolution } from './SectionResolution';
import {
  GET_BANK_STATEMENT_PERIOD_SUMMARY,
} from '../graphql/queries/bankStatementPeriodSummary';

TabSummary.propTypes = {
  bankStatementId: PropTypes.string,
  disabledEdit: PropTypes.bool,
  lockForm: PropTypes.func,
};

export default function TabSummary({ bankStatementId, disabledEdit, lockForm }) {
  const { data, error, loading } = useQuery(
    GET_BANK_STATEMENT_PERIOD_SUMMARY,
    {
      variables: {
        accountId: bankStatementId,
        period: 'WEEK',
      },
      errorPolicy: 'all',
    },
  );

  if (error) {
    return error.message;
  }

  if (loading) {
    return 'Загрузка...';
  }
  const {
    accountHistory,
    bankStatement: { additionalAccounts },
    periods,
    totalContractors,
    totalCredit,
    totalDebit,
  } = data.bankStatementAccount;

  return (
    <>
      <BlockAccountState
        endRemainder={accountHistory.slice(-1)[0]?.endRemainder}
        startRemainder={accountHistory[0]?.startRemainder}
        totalCredit={totalCredit}
        totalDebit={totalDebit}
      />

      <DelayedRender>
        <Box my={5}>
          <BlockAccountHistory
            bankStatementId={bankStatementId}
            data={periods}
            totalCredit={totalCredit}
            totalDebit={totalDebit}
          />
        </Box>
      </DelayedRender>

      <Box alignItems="baseline" display="flex" fontSize={16} mb={2}>
        <Box color="text.secondary">Всего контрагентов:</Box>&nbsp;
        <Box fontWeight={500}>{totalContractors}</Box>
      </Box>

      <Box
        alignItems="baseline"
        display={additionalAccounts.length ? 'block' : 'flex'}
        fontSize={16}
        mb={4}
      >
        <Box color="text.secondary">Найденные собственные счета:</Box>
        <Box fontWeight={500} ml={3} mt={2}>
          {additionalAccounts.length
            ? additionalAccounts.map((record) => (
              <ItemAdditionalAccount key={record.accountNumber} {...record} />
            ))
            : 'не найдено'}
        </Box>
      </Box>

      <SectionResolution
        bankStatementId={bankStatementId}
        disabledEdit={disabledEdit}
        lockForm={lockForm}
      />

      <DrawerOrders disabledEdit={disabledEdit} />
    </>
  );
}

ItemAdditionalAccount.propTypes = {
  accountNumber: PropTypes.string,
  bank: PropTypes.string,
  bik: PropTypes.string,
};

function ItemAdditionalAccount({ accountNumber, bank, bik }) {
  return (
    <Box>
      <Typography component="span" variant="body1">
        р/с&nbsp;
      </Typography>
      <CopiedText text={accountNumber} />,{' '}
      <Typography component="span" variant="body1">
        бик&nbsp;
      </Typography>{' '}
      <CopiedText text={bik} />:{' '}
      <Typography component="span" variant="body1">
        {bank}
      </Typography>
      <IconButton
        href={`https://bik10.ru/${bik}`}
        size="small"
        target="_blank"
      >
        <OpenInNewIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
}
