import { gql } from '@apollo/client';

export const GET_VERIFICATION_MY_PROCESSES = gql`
  query GetVerificationMyProcesses {
    verificationMyProcesses {
      id
      resolutionText
      state
      createdAt
      application {
        id
        requestedAmount
        borrowerEntity {
          id
          legalName
          contacts {
            id
            email
            firstName
            middleName
            lastName
            fullName @client
            fsspCheckProcesses {
              id
              createdAt
              finishedAt
              itemsCount
              state
              unpaidItemsCount
              unpaidSum
            }
          }
        }
      }
      verificator {
        id
        login
      }
    }
    verificationPendingProcesses {
      id
      resolutionText
      state
      createdAt
      application {
        id
        requestedAmount
        borrowerEntity {
          id
          legalName
          contacts {
            id
            email
            firstName
            middleName
            lastName
            fullName @client
            fsspCheckProcesses {
              id
              createdAt
              finishedAt
              itemsCount
              state
              unpaidItemsCount
              unpaidSum
            }
          }
        }
      }
      verificator {
        id
        login
      }
    }
  }
`;