import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import FormattedCurrency from 'components/FormattedCurrency';
import { formatDate } from 'utils/dateTimeFormatters';

OcrDataModal.propTypes = {
  handleOcrDataModalClose: PropTypes.func,
  ocrDataObject: PropTypes.object,
}

export default function OcrDataModal({
  handleOcrDataModalClose,
  ocrDataObject,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    handleOcrDataModalClose();
    setOpen(false);
  }, [handleOcrDataModalClose]);

  useEffect(() => {
    if (ocrDataObject) {
      handleOpen();
    }
  }, [ocrDataObject, handleOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#fffefd',
          border: '8px solid #f5f5f5',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box>
            <Typography noWrap sx={{ fontSize: 14, fontWeight: 600 }}>
              Номер документа:
            </Typography>
            <Typography sx={{ mt: 2, fontSize: 14 }}>
              {ocrDataObject?.documentNumber}
            </Typography>
          </Box>

          <Box>
            <Typography noWrap sx={{ fontSize: 14, fontWeight: 600 }}>
              Дата документа:
            </Typography>
            <Typography sx={{ mt: 2, fontSize: 14 }}>
              {formatDate(ocrDataObject?.date || '')}
            </Typography>
          </Box>

          <Box>
            <Typography noWrap sx={{ fontSize: 14, fontWeight: 600 }}>
              Плательщик:
            </Typography>
            <Typography sx={{ mt: 2, fontSize: 14 }}>
              {ocrDataObject?.buyerName}
            </Typography>
          </Box>

          <Box>
            <Typography noWrap sx={{ fontSize: 14, fontWeight: 600 }}>
              Поставщик:
            </Typography>
            <Typography sx={{ mt: 2, fontSize: 14 }}>
              {ocrDataObject?.sellerName}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 3, minHeight: 150 }}>
        <TableContainer
          sx={{
            position: 'relative',
            zIndex: 1,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 10, height: 10 },
            '&::-webkit-scrollbar-track': { background: 'none' },
            '&::-webkit-scrollbar-thumb': {
              background: '#e0e0e0',
              borderRadius: '4px',
              '&:hover': { background: '#d0d0d0' },
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell sx={{ minWidth: 350 }}>
                  Наименование товара / Распознанный товар
                </TableCell>
                <TableCell>Ед.изм.</TableCell>
                <TableCell sx={{ minWidth: 80 }}>Кол-во</TableCell>
                <TableCell>Цена</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>НДС</TableCell>
                <TableCell>Налог</TableCell>
                <TableCell>Итого</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!ocrDataObject?.orderItems?.length ? (
                <React.Fragment>
                  {ocrDataObject.orderItems.map((item) => {
                    const sum = item.price * item.quantity;
                    const totalVat = sum * item.vatRate / 100;

                    return (
                      <TableRow
                        key={item.index}
                        sx={{ '& th, & td': { fontSize: 14 } }}
                      >
                        <TableCell component="th" scope="row">
                          {item.index}
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.units}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          <FormattedCurrency hideCurrency value={item.price} />
                        </TableCell>
                        <TableCell>
                          <FormattedCurrency hideCurrency value={sum} />
                        </TableCell>
                        <TableCell>{item.vatRate}%</TableCell>
                        <TableCell>
                          <FormattedCurrency hideCurrency value={totalVat} />
                        </TableCell>
                        <TableCell>
                          <FormattedCurrency hideCurrency value={item.total} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="9" sx={{ border: 0 }}>
                    <Box sx={{ p: 4 }}>Нет данных</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan="7"
                  sx={{ border: 0, color: 'rgba(0, 0, 0, 0.87)' }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Всего
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0, color: 'rgba(0, 0, 0, 0.87)' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    <FormattedCurrency
                      hideCurrency
                      value={ocrDataObject?.totalVat}
                    />
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0, color: 'rgba(0, 0, 0, 0.87)' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    <FormattedCurrency
                      hideCurrency
                      value={ocrDataObject?.totalAmount}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}