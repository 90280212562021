import { gql } from '@apollo/client/core';

export const GET_USER_DETAIL = gql`
  query GetUserDetail (
    $userId: ID!
  ) {
    user (
      userId: $userId
    ) {
      avatar
      deputy {
        displayName
      }
      leaves (order: DESC) {
        kind
        startDate
        endDate
        userTasksStatus
      }
      contact {
        id
        lastName
        firstName
        middleName
        dateOfBirth
        email
        additionalEmail
        phone
        additionalPhoneNumbers {
          phone
          comment
        }
      }
      roles
    }
  }
`;