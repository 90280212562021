import { gql } from '@apollo/client/core';

export const GET_LOANS = gql`
  query GetLoans(
    $page: Int,
    $limit: Int,
    $filters: LoanFilters,
    $order: LoanOrder
  ) {
    loans(page: $page, limit: $limit, filters: $filters, order: $order) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        accountState
        leasingAgreementDate
        fundingSource {
          source
        }
        interestRate
        amountFinancing  
        displayLeaseAgreement
        overdueDays
        application {
          id
          borrowerEntity {
            id
            legalNameWithShortEntityType
          }
        }
        term
      }
    }
  }
`;