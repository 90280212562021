import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Link,
  Toolbar,
  Typography
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/Download';
import { navigate, useLocation } from '@reach/router';
import _identity from 'lodash.identity';
import _pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { FormattedMessage } from 'react-intl';
import {useDebouncedCallback} from "use-debounce";

import Search from 'components/Search';
import { SEARCH_DEBOUNCE } from 'core/constants/searchDebounce';

import {
  FEDRESURS_GENERATE_REPORTING_MESSAGES,
} from './graphql/mutations/fedresursGenerateXmlByReportingMessages';
import MessageKindFilter from './MessageKindFilter';
import MessageTypesFilter from './MessageTypesFilter';
import ProcessReportingXml from './ProcessReportingXml';
import TableFedresursReporting from './TableFedresursReporting';

// TODO: Стиль активной страницы пагинации
// TODO: Счетчики сообщений
// TODO: Что делать после отправки сообщений

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  files: {
    '& > div': {
      flexWrap: 'wrap'
    }
  },
  wrapperUpImg: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '24px',
  },
  blockImg: {
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #E7E8EA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '142px',
    height: '142px',

    '& .index': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .remove': {
      cursor: 'pointer',
      position: 'absolute',
      left: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '40px',
      height: 'auto'
    },
    'span': {
      display: 'block',
      position: 'absolute',
      bottom: '4px',
      left: '4px',
      right:'4px',
      width: 'calc(100% - 8px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '10px'
    }
  }
};

export default function PageFedresursReporting({ location }) {
  const windowLocation = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [selection, setSelection] = useState([]);
  const [xmlFileUrl, setXmlFilerUrl] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [search, setSearch] = useState(parsedQuery.q || '');

  const [generateFedresursReportingMessages] = useMutation(
    FEDRESURS_GENERATE_REPORTING_MESSAGES, {
    onError: alert,
    onCompleted: (data) => {
      if (data) {
        setXmlFilerUrl(
          data.fedresursGenerateXmlByReportingMessages.fedresursReportingMessagesExport.xmlFileUrl || null
        );
      }
    },
  });

  function handleGenerateButtonClick(selection) {
    return function () {
      generateFedresursReportingMessages({
        variables: {
          fedresursReportingMessageIds: selection,
        },
      });
    };
  };

  const debounced = useDebouncedCallback(value => {
    setSearch(value);
    const query = _pickBy({ ...parsedQuery, q: value }, _identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : windowLocation.pathname);
  }, SEARCH_DEBOUNCE);

  const handleClose = useCallback(() => {
    setUploadDialogOpen(false);
  }, []);

  useEffect(() => {
    if (xmlFileUrl) {
      const link = document.createElement('a');
      link.href = xmlFileUrl;
      link.setAttribute('download', 'file.xml');
      link.click();
    };
  }, [xmlFileUrl]);

  return (
    <Box
      sx={{
        mb: 8,
        px: 3,
        py: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography gutterBottom variant="h2">
          Отчетность в Федресурс
        </Typography>
        <MessageTypesFilter />
        <MessageKindFilter />
        <Button
          onClick={() => {setUploadDialogOpen(true)}}
          sx={{marginLeft: '16px'}}
          variant="outlined"
        >
          Загрузить XML на распознавание
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center', backgroundColor: '#ffffff', height: '100px', }}>
        <Typography gutterBottom sx={ styles.pageTitle } variant="h3">
          Поиск по номеру договора лизинга
        </Typography>

        <Box sx={{ width: 'calc( 100% - 480px - 200px)' }}>
          <Search
            onChange={debounced.callback}
            sx={{ height: '48px', margin: 0 }}
            value={search}
          />
        </Box>
      </Box>

      <Toolbar disableGutters sx={{ py: 3 }}>
        <Alert severity="info" sx={{ width: '100%' }}>
          <Typography variant="body1">
            {selection.length ? (
              <>
                Выбрано{' '}
                <Box component="span" sx={{ fontWeight: 500 }}>
                  <FormattedMessage
                    id="messages.plural"
                    values={{ amount: selection.length }}
                  />
                </Box>
                ,{' '}
                <Link
                  component="button"
                  onClick={handleGenerateButtonClick(selection)}
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                  underline="none"
                  variant="body1"
                >
                  скачать файл для передачи в Федресурс&nbsp;
                  <DownloadIcon fontSize="small" />
                </Link>
              </>
            ) : (
              'Выберите сообщения для передачи в Федресурс'
            )}
          </Typography>
        </Alert>
      </Toolbar>
      <TableFedresursReporting {...{ setSelection, selection }} />
      {uploadDialogOpen && (
        <ProcessReportingXml handleClose={handleClose} />
      )}
    </Box>
  );
};

PageFedresursReporting.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
