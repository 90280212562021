import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

import Tooltip from 'components/Tooltip';

import BodyResolution from './BodyResolution';

DialogResolutionHistory.propTypes = {
  comments: PropTypes.array,
};

export default function DialogResolutionHistory({ comments = [] }) {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Tooltip title="История изменений">
        <IconButton onClick={handleClickOpen}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">История изменений резолюции</Typography>
        </DialogTitle>
        <DialogContent>
          {[...comments].reverse().map((comment) => (
            <BodyResolution key={comment.id} {...comment} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
