import React, {useContext, useEffect, useMemo, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import {
  CustomPaging, DataTypeProvider,
  PagingState,
  RowDetailState
} from "@devexpress/dx-react-grid";
import {Grid, PagingPanel, TableHeaderRow, VirtualTable} from "@devexpress/dx-react-grid-material-ui";
import {Box, withStyles} from "@material-ui/core";
import {cloneDeep} from "lodash";

import ErrorAlert from "../../../components/ErrorAlert";
import Tabs from "../../../components/Tabs";
import {GridRoot, Loader} from "../../deals/components/helpers";
import {
  calcAllCount,
  COLUMN_EXTENSIONS_STRUCTURE_SHOP_GOODS,
  COLUMNS_MODEL_CREATOR_SHOP_GOODS,
  DEALS_PAGE_LIMIT,
  fI,
  PAGING_PANEL_MESSAGES,
  TABS_GOODS
} from "../constants";
import PrintGood from "../documentActionTypeProvider/PrintGood";
import { GET_SHOP_GOODS } from "../graphql/queries/getShopGoods";
import { ShopContext } from "../ShopContext";

export const PrintProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <PrintGood {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

const ContentGoods = () => {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)',
    },
    tab: {
      minWidth: 'auto !important',
      width: 'auto !important',
      color: '#0D1D32',
      fontSize: '14px',
      fontWeight: 500,
    },
  }), []);
  const styles = useMemo(() => ({
    wrapperTable: {
      margin: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '60px',

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
  }), []);

  const { state: query } = useContext(ShopContext);

  const [paramQuery, setParamQuery] = useState({
    page: 1,
    limit: DEALS_PAGE_LIMIT,
    sort: {
      field: 'price',
      order: 'ASC',
    },
    filter: {},
  });

  useEffect(() => {
    const contextQuery = cloneDeep(query);
    contextQuery.sort.field = 'price';
    const newQuery = cloneDeep(paramQuery)
    newQuery.filter = query.filter;
    newQuery.sort = contextQuery.sort;
    newQuery.page = 1;
    setParamQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = withStyles({ name: "MyPager" })(
    MyPager
  );

  const [rows, setRows] = useState([]);
  const [countTabs, setCountTabs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchMessages, {
    data: goods,
    error,
    loading
  }] = useLazyQuery(GET_SHOP_GOODS, {
    fetchPolicy: 'network-only',
  });
  const [activeTab, setActiveTab] = useState(0);
  const toggleTabs = activeTab => {
    setActiveTab(activeTab);

    const newParamRequest = cloneDeep(paramQuery);

    newParamRequest.page = 1;
    delete newParamRequest.filter.statusEq;

    if (activeTab === 0) {
      newParamRequest.filter = {};
    } else {
      newParamRequest.filter.statusEq = TABS_GOODS[activeTab];
    }

    setParamQuery(newParamRequest)
  };

  const handleCurrentPageChange = page => {
    const newQuery = cloneDeep(paramQuery);
    newQuery.page = page + 1;
    setParamQuery(newQuery);
  };

  useEffect(() => {
    if (!goods) {
      fetchMessages({ variables: paramQuery });
    }

    if (goods && goods?.shopGoods?.goods?.collection) {
      setRows(goods.shopGoods.goods.collection);
    }

    if (goods && goods?.shopGoods?.tabs) {
      let arr = [];

      TABS_GOODS.forEach( (elem, index) => {
        if (elem === 'ALL') {
          arr.push(calcAllCount(goods?.shopGoods?.tabs));
        } else {
          arr.push(fI(goods?.shopGoods?.tabs, TABS_GOODS[index]));
        }
      })

      setCountTabs(arr);
    }

    if (goods) {
      setTotalCount(goods?.shopGoods?.goods?.metadata?.totalCount || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMessages, goods]);

  useEffect(() => {
    fetchMessages({ variables: paramQuery})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramQuery]);

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Tabs
        counterTabs={countTabs}
        defaultActiveTab={activeTab}
        langPath={"shop.tabs."}
        onChange={toggleTabs}
        sx={{
          wrapperTabs: {
            borderBottom: '1px solid #E7E8EA',
          },
          tab: {
            ...stylesTabs.tab,
            fontSize: '16px',
            fontWeight: 500,
          }
        }}
        tabs={TABS_GOODS}
      />

      <Box sx={styles.wrapperTable}>
        <Grid
          columns={COLUMNS_MODEL_CREATOR_SHOP_GOODS}
          getRowId={(row) => row.id}
          rootComponent={GridRoot}
          rows={rows}
        >
          <PrintProvider for={['print']} />

          <RowDetailState />

          <PagingState
            currentPage={paramQuery.page - 1}
            defaultCurrentPage={paramQuery.page - 1}
            defaultPageSize={paramQuery.limit}
            onCurrentPageChange={handleCurrentPageChange}
          />

          <CustomPaging totalCount={totalCount} />

          <VirtualTable
            cellComponent={(props) => (
              <VirtualTable.Cell className="OrdersTableCell" {...props} />
            )}
            columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SHOP_GOODS}
            messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
          />
          <TableHeaderRow />
          <PagingPanel containerComponent={MyPagerComponent} messages={PAGING_PANEL_MESSAGES} pageSizes={[ 10, 20, 50, 0 ]} />
        </Grid>
      </Box>
    </>
  );
}

export default ContentGoods;