import { gql } from '@apollo/client/core';

export const GET_FUNDING_TRANCHES = gql`
  query GetFundingTranches ( $fundingSourceId: ID! ) {
    fundingSource (fundingSourceId: $fundingSourceId ) {
      id
      tranches {
        amount
        id
        number
      }
    }
  }
`;