import { gql } from '@apollo/client/core';

export const GET_SCHEDULE_LIST_FOR_LOAN = gql`
  query GetScheduleListForLoan($loanId: ID!) {
    loan(loanId: $loanId) {
      id
      schedules {
        id
        state
        createdAt
        stornedAt
        activatedAt
      }
    }
  }
`;