import React from 'react';

import { Box } from "@material-ui/core";

export default function PageGraphHolidays() {
  return (
    <Box>
      Вкладка График отпуско в разработке.
    </Box>
  )
}
