/* eslint-disable react/display-name, react/prop-types */
import React from 'react';

import { Link, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';
import { formatDate } from 'utils/dateTimeFormatters';

export const DEALS_PAGE_LIMIT = 10;

export const DEALS_FILTER_STATE_FIELDS = {
  accountStates: 'Статус счета',
  borrower: 'Компания-заёмщик',
  date: 'Срок действия договора',
  fundingSourceId: 'Источник финансирования',
  overdueMoreThanDays: 'Просрочка от (дней)',
  applicationFundingSourceId: 'Линия',
  trancheId: 'Транш',
  leasingAgreement: 'Ищем:',
};

export const filterStateRenderers = (fieldName, value = '', options = {}) => ({
  accountStates: (
    <>
      {Array.isArray(value) && value.reduce((acc, val) => {
        const formattedValue = options?.intl?.formatMessage({
          id: `deals.status.${val}`,
        }) || val;
        return acc !== '' ? `${acc}, ${formattedValue}` : formattedValue;
      }, '')}
    </>
  ),
  date: (
    <>
      {value?.from && value?.to && (
        <>{`${formatDate(value.from)} — ${formatDate(value.to)}`}</>
      )}

      {value?.from && !value?.to && (
        <>{`с ${formatDate(value.from)}`}</>
      )}

      {!value?.from && value?.to && (
        <>{`по ${formatDate(value.to)}`}</>
      )}
    </>
  ),
  fundingSourceId: options?.fundingSources
    ?.find((source) => source.id === value)
    ?.source || value,
  borrower: value.legalNameWithShortEntityType || '',
  applicationFundingSourceId: options?.lineFundingSource
    ?.find((source) => source.id === value)
    ?.source || value,
  trancheId: options?.tranches
    ?.find((tranche) => tranche.id === value)
    ?.number || value,
  overdueMoreThanDays: value,
  leasingAgreement: value,
}[fieldName]);

export const columnExtensions = [
  { columnName: 'id', width: 80 },
  {
    columnName: 'displayLeaseAgreement',
    align: 'center',
    width: 130,
    wordWrapEnabled: true,
  },
  {
    columnName: 'leasingAgreementDate',
    align: 'center',
    wordWrapEnabled: true,
  },
  { columnName: 'interestRate', width: 90, wordWrapEnabled: true },
  { columnName: 'borrower', width: 170, wordWrapEnabled: true },
  { columnName: 'accountState', width: 160 },
  { columnName: 'amountFinancing', width: 140, wordWrapEnabled: true },
  {
    columnName: 'term',
    align: 'center',
    width: 70,
    wordWrapEnabled: true,
  },
  {
    columnName: 'fundingSource',
    align: 'center',
    width: 150,
    wordWrapEnabled: true,
  },
  {
    columnName: 'overdueDays',
    align: 'center',
    width: 110,
    wordWrapEnabled: true,
  },
];

export const columnsModelCreator = [
  { name: 'id', title: 'ID' },
  {
    name: 'displayLeaseAgreement',
    title: '№ договора',
    getCellValue: ({ application, displayLeaseAgreement }) => {
      const host = process.env.REACT_APP_HOST;
      const applicationId = application?.id || '';
      return (
        <Link href={`${host}/applications/${applicationId}/deal`}>
          {displayLeaseAgreement}
        </Link>
      );
    },
  },
  {
    name: 'leasingAgreementDate',
    title: 'Дата договора',
    getCellValue: ({ leasingAgreementDate }) => leasingAgreementDate
      ? formatDate(leasingAgreementDate)
      : '',
  },
  {
    name: 'interestRate',
    title: 'Годовая ставка',
    getCellValue: ({ interestRate }) => `${interestRate * 100}%`,
  },
  {
    name: 'borrower',
    title: 'Компания-заёмщик',
    getCellValue: ({ application }) => {
      const host = process.env.REACT_APP_HOST;
      const borrowerEntityId = application?.borrowerEntity?.id || '';
      return (
        <Link href={`${host}/entities/${borrowerEntityId}`}>
          {application?.borrowerEntity?.legalNameWithShortEntityType || ''}
        </Link>
      );
    },
  },
  {
    name: 'accountState',
    title: 'Статус счета',
    getCellValue: ({ accountState }) => (
      <Typography
        color={{
          ACCOUNT_PENDING: { color: 'lightgray' },
          ACCOUNT_ACTIVE: { color: 'green' },
          ACCOUNT_CLOSED: { color: orange[700] },
        }[accountState]}
      >
        <FormattedMessage id={`deals.status.${accountState}`} />
      </Typography>
    ),
  },
  {
    name: 'amountFinancing',
    title: 'Сумма лизинга',
    getCellValue: ({ amountFinancing }) => amountFinancing
      ? <><FormattedCurrency style="decimal" value={amountFinancing} />{' '}руб</>
      : '',
  },
  { name: 'term', title: 'Срок (мес)' },
  {
    name: 'fundingSource',
    title: 'Источник финансирования',
    getCellValue: ({ fundingSource }) => fundingSource?.source || '',
  },
  { name: 'overdueDays', title: 'Просрочка от (дней)' },
];

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Сделок на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Сделок нет';
    }

    return to === count
      ? `Сделок: ${to - from + 1} (всего ${count})`
      : `Сделки ${from}-${to} (всего ${count})`;
  },
};