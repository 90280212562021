import { gql } from '@apollo/client/core';

export const FEDRESURS_UPDATE_REPORTING_MESSAGE = gql`
  mutation FedresursUpdateReportingMessage(
    $fedresursReportingMessageId: ID!
    $attributes: FedresursReportingMessageUpdateAttributes!
  ) {
    fedresursUpdateReportingMessage(
      fedresursReportingMessageId: $fedresursReportingMessageId
      attributes: $attributes
    ) {
      fedresursReportingMessage {
        id
        kind
        sentAt
        state
        updatedAt
      }
    }
  }
`;