import PropTypes from 'prop-types';
import React from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import BlockIcon from '@material-ui/icons/Block';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { FormattedMessage } from 'react-intl';

import ErrorAlert from 'components/ErrorAlert';
import Tooltip from 'components/Tooltip';
import {
  BANK_STATEMENT_REMOVE
} from 'modules/bankStatements/graphql/mutations/bankStatementRemove';
import {
  ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED_FULL_DATA,
} from 'modules/bankStatements/graphql/subscriptions/entityBankStatementsListUpdatedFullData';
import { formatDate } from 'utils/dateTimeFormatters';

import { formatName } from './utils';
import { getBankLogo } from '../HeaderBankStatement';

ListBankStatements.propTypes = {
  entityId: PropTypes.string,
};

export default function ListBankStatements({ entityId }) {
  const { data, error, loading } = useSubscription(
    ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED_FULL_DATA,
    { variables: { entityId } },
  );

  if (error) {
    return (
      <LayoutListBankStatements>
        <ErrorAlert
          action={
            <Button color="inherit" disabled={loading} variant="outlined">
              Повторить запрос
            </Button>
          }
          message={error.message}
          title="Ошибка при получении данных"
        />
      </LayoutListBankStatements>
    );
  }

  if (loading) {
    return (
      <LayoutListBankStatements>
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
        <Skeleton height={100} sx={{ mx: 3 }} />
      </LayoutListBankStatements>
    );
  }
  return (
    <LayoutListBankStatements>
      <List sx={{ minHeight: 'calc(100% - 100px)' }}>
        {data.entityBankStatementsListUpdated.bankStatements.map(
          ({
            createdAt,
            document,
            id,
            info,
            statementFile,
            status,
            uploaderContact,
          }) => {
            const processingIconNode = (
              <ListItemIcon>
                <AlarmIcon htmlColor="steelblue" sx={{ mx: 'auto' }} />
              </ListItemIcon>
            );

            const Icon = {
              RECOGNIZED: (
                <Tooltip title={info.sender}>
                  <Box sx={{ height: 40, textAlign: 'center' }}>
                    <img
                      alt="Logo"
                      height={40}
                      src={getBankLogo(info.sender)}
                      width={32}
                    />
                  </Box>
                </Tooltip>
              ),
              RECOGNIZE_FAILED: (
                <ListItemIcon>
                  <ReportProblemIcon
                    htmlColor="orange"
                    sx={{ mx: 'auto' }}
                  />
                </ListItemIcon>
              ),
              UNRECOGNIZABLE: (
                <ListItemIcon>
                  <BlockIcon
                    htmlColor="red"
                    sx={{ mx: 'auto' }}
                  />
                </ListItemIcon>
              ),
              DELETED: (
                <ListItemIcon>
                  <DeleteForeverIcon
                    htmlColor="darkgray"
                    sx={{ mx: 'auto' }}
                  />
                </ListItemIcon>
              ),
              REMOVING: (
                <ListItemIcon>
                  <DeleteForeverIcon
                    htmlColor="darkgray"
                    sx={{ mx: 'auto' }}
                  />
                </ListItemIcon>
              ),
              CREATED: processingIconNode,
              CREATING_ACCOUNTS: processingIconNode,
              CREATING_ORDERS: processingIconNode,
              ES_INDEXING: processingIconNode,
              PARSING: processingIconNode,
              UPDATING_INFO: processingIconNode,
            }[document || statementFile ? status : 'DELETED'];

            return (
              <ListItem dense key={id}>
                <ListItemAvatar sx={{ mr: 2 }}>{Icon}</ListItemAvatar>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id={
                        `bankStatement.${document || statementFile
                          ? status
                          : 'DELETED'}`
                      }
                    />
                  }
                  secondary={
                    document ? (
                      <>
                        <ListItemSecondaryText
                          author={uploaderContact}
                          createdAt={createdAt}
                          fileExt={document.assetFileExt}
                          filename={document.assetOriginalFilename}
                          id={id}
                          status={status}
                          url={document.assetUrl}
                        />
                      </>
                    ) : statementFile ? (
                      <ListItemSecondaryText
                        author={uploaderContact}
                        createdAt={createdAt}
                        fileExt={statementFile.fileExt}
                        filename={statementFile.filename}
                        id={id}
                        status={status}
                        url={statementFile.url}
                      />
                    ) : null
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </ListItem>
            );
          },
        )}
      </List>
    </LayoutListBankStatements>
  );
}

LayoutListBankStatements.propTypes = {
  children: PropTypes.node,
};
function LayoutListBankStatements({ children }) {
  return (
    <Box
      sx={{
        bgcolor: '#F4F5F7',
        borderRadius: '4px',
        flexShrink: 0,
        mr: 2,
        pb: 2,
        px: 2,
        width: 1,
        maxWidth: '600px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar variant="dense">
        <Box alignSelf="center" flexGrow={1}>
          {/* FIXME: to locales */}
          <Typography variant="h4">Файлы выписок</Typography>
        </Box>
      </Toolbar>
      <Box
        sx={{
          bgcolor: 'white',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '4px',
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

ListItemSecondaryText.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  fileExt: PropTypes.string,
  filename: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
  url: PropTypes.string,
};

function ListItemSecondaryText({
  author = null,
  createdAt,
  fileExt = '',
  filename = '',
  id = null,
  status = '',
  url = '',
}) {
  const [bankStatementRemove] = useMutation(BANK_STATEMENT_REMOVE, {
    onError: (error) => {
      alert(`Невозможно удалить выписку: ${error.message}`);
    }
  });

  const handleRemoveClick = e => {
    e.preventDefault();
    if (window.confirm('Вы уверены что хотите удалить файл выписки? Это действие невозможно отменить !')) {
      bankStatementRemove({ variables: { bankStatementId: Number(id) } });
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row'}}>
        <Link href={url} mr={5} noWrap sx={{ maxWidth: 160, display: 'flex' }}>
          <Typography
            noWrap
            sx={{ maxWidth: 140, display: 'block' }}
            title={filename}
            variant="inherit"
          >
            {filename.replace(`.${fileExt}`, '')}
          </Typography>
          <Typography variant="inherit">.{fileExt}</Typography>
        </Link>
        {
          ['UNRECOGNIZABLE', 'RECOGNIZED', 'RECOGNIZE_FAILED'].includes(status) &&
            <Link
              color="error"
              href="#"
              noWrap
              onClick={handleRemoveClick}
              sx={{ maxWidth: 160, display: 'flex' }}
            >
              удалить
            </Link>
        }
      </Box>
      <Box>
        загружен{' '}
        {author && (
          <>
            <Typography
              component={Link}
              href={`${process.env.REACT_APP_HOST}/contacts/${author?.id}`}
              target="_parent"
              variant="inherit"
            >
              {formatName(author)}
            </Typography>
            {' '}
          </>
        )}
        {formatDate(createdAt, 'short_date_time')}
      </Box>
    </Box>
  );
}
