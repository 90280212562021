import { gql } from '@apollo/client/core';

export const GET_ORERS_TASK = gql`
  query OrdersTasks (
    $limit: Int
    $page: Int
    $filter: SupplyingOrdersFilter
    $sort: SupplyingOrdersSort
  ) {
    supplyingOrders(
      limit: $limit,
      page: $page,
      filter: $filter,
      sort: $sort,
    ) {
      supplyingOrders {
        collection {
          id
          amount
          vat
          status
          number
          expiresAt
          createdAt
          updatedAt
          currency {
            unit
          }
          application {
            id
            status
            contact {
              id
              firstName
              lastName
              middleName
              phone
            }
            borrowerEntity {
              id
              legalNameWithShortEntityType
              phone
              contacts {
                id
                firstName
                lastName
                middleName
              }
            }
          }
          supplier {
            id # id поставщика
            bankDetails {
              entity {
                tin # ИНН Поставщика
                legalNameWithShortEntityType # насзвание организации Поставщика
                site # сайт Поставщика
              }
            }
          }
          arenzaManager {
            displayName
            contact {
              id
            }
          }
        }
      }
      tabs {
        count
        status
        sum
      }
    }
  }
`;