import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Button } from "@material-ui/core";

WrapperButton.propTypes = {
  children: PropTypes.any,
  colorScheme: PropTypes.oneOf([
    "grey",
    "orange",
    "white",
    "breeze",
    "green",
    "orangeStroke",
    "whiteOutline",
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default function WrapperButton({ children, colorScheme, disabled, onClick: onClickProps, sx, ...props }) {
  const styleDisabledButton = useMemo(() => ({
    button: {
      background: '#CFD2D6',
      color: '#868E98',
      border: '1px solid #CFD2D6',
      boxShadow: "none",

      '& path': {
        fill: '#868E98',
      },
    },
    buttonOutline: {
      background: '#ffffff',
      color: '#f9d8bb',
      border: '1px solid #fcecdd',

      '& path': {
        fill: '#f9d8bb',
      },
    }
  }), []);

  const styles = useMemo(() => ({
    button: {
      color: '#ffffff',
      background: '#ee8035',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'initial',
      padding: '14px 20px',
      display: 'inline-flex',
      gap: '12px',
      minWidth: '0',
      whiteSpace: 'nowrap',

      '& svg': {
        marginLeft: "8px",
      },

      '& path': {
        fill: '#fff',
      },

      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '&:hover': {
        background: '#d1712e',
      },
      '&:active': {
        background: '#f09249',
      },
      '&:disabled': {
        background: '#f8d8bb',
        color: '#ffffff',

        '& path': {
          fill: '#fff',
        },
      },

      '&.grey': {
        color: '#6E7884',
        background: '#f3f4f5',

        '& path': {
          fill: '#6E7884',
        },

        '&:hover': {
          background: '#fdf2e8',
          color: '#ee8035',

          '& path': {
            fill: '#ee8035',
          },
        },
        '&:active': {
          background: '#fbe5d2',
          color: '#d1702e',

          '& path': {
            fill: '#d1702e',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.button
        },
      },

      '&.white': {
        color: '#0D1D32',
        background: '#ffffff',
        border: '1px solid #ffffff',
        boxShadow: "0px 2px 4px 0px rgba(42, 25, 73, 0.10)",

        '& path': {
          fill: '#0D1D32',
        },

        '&:hover': {
          background: '#ffffff',
          color: '#3D6CE7',
          border: '1px solid #ffffff',
          boxShadow: "0px 10px 32px 0px rgba(42, 25, 73, 0.08)",

          '& path': {
            fill: '#3D6CE7',
          },
        },
        '&:active': {
          background: '#ffffff',
          color: '#3a55b3',
          border: '1px solid #ffffff',
          boxShadow: "0px 2px 4px 0px rgba(42, 25, 73, 0.10)",

          '& path': {
            fill: '#3a55b3',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.button
        }
      },

      '&.breeze': {
        background: '#21A2FF',
        color: '#FFFFFF',
        border: '1px solid #21A2FF',

        '& path': {
          fill: '#FFFFFF',
        },

        '&:hover': {
          background: '#6ab3f9',
          color: '#FFFFFF',
          border: '1px solid #6ab3f9',

          '& path': {
            fill: '#FFFFFF',
          },
        },
        '&:active': {
          background: '#4288d3',
          color: '#FFFFFF',
          border: '1px solid #4288d3',

          '& path': {
            fill: '#FFFFFF',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.button
        }
      },

      '&.green': {
        background: '#67BF3D',
        color: '#FFFFFF',
        border: '1px solid #67BF3D',

        '& path': {
          fill: '#FFFFFF',
        },

        '&:hover': {
          background: '#95ca71',
          color: '#FFFFFF',
          border: '1px solid #95ca71',
          boxShadow: "0px 6px 16px 0px rgba(111, 192, 61, 0.30)",

          '& path': {
            fill: '#FFFFFF',
          },
        },
        '&:active': {
          background: '#6da446',
          color: '#FFFFFF',
          border: '1px solid #6da446',
          boxShadow: "none",

          '& path': {
            fill: '#FFFFFF',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.button
        }
      },

      '&.orangeOutline': {
        background: '#fff',
        color: '#FF780F',
        border: '1px solid #FF780F',

        '& path': {
          fill: '#ee8035',
        },

        '&:hover': {
          background: '#fdf4ed',
          color: '#FF780F',
          border: '1px solid #f2a565',

          '& path': {
            fill: '#f19b4c',
          },
        },
        '&:active': {
          background: '#fcefe3',
          color: '#D9660D',
          border: '1px solid #ca6d2c',

          '& path': {
            fill: '#D9660D',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.buttonOutline
        }
      },

      '&.whiteOutline': {
        color: '#6E7884',
        background: '#ffffff',
        border: '1px solid #CFD2D6',
        boxShadow: "none",

        '& path': {
          fill: '#6E7884',
        },

        '&:hover': {
          background: '#fff',
          color: '#3D6CE7',
          border: '1px solid #3D6CE7',
          boxShadow: "none",

          '& path': {
            fill: '#3D6CE7',
          },
        },
        '&:active': {
          background: '#3D6CE7',
          color: '#fff',
          border: '1px solid #3D6CE7',
          boxShadow: "0px 2px 4px 0px rgba(42, 25, 73, 0.10)",

          '& path': {
            fill: '#3a55b3',
          },
        },
        '&:disabled, &.Mui-disabled': {
          ...styleDisabledButton.buttonOutline
        }
      },

      ...sx,
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const onClick = () => {
    if (onClickProps && typeof onClickProps === 'function') {
      onClickProps();
    }
  }

  return (
    <Button
      className={colorScheme}
      disabled={disabled}
      onClick={onClick}
      sx={ styles.button }
      {...props}
    >
      {children}
    </Button>
  );
}
