import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

const styles = {
  assetUrl: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  successAlert: {
    color: '#3DB205',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& svg': { width: 13, height: 13 },
  },
  warningAlert: {
    color: '#D74E51',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& svg': { width: 13, height: 13 },
  },
};

DocumentCard.propTypes = {
  document: PropTypes.shape({
    assetOriginalFilename: PropTypes.string,
    assetUrl: PropTypes.string,
    fullName: PropTypes.string,
    isOriginalReceived: PropTypes.bool,
    number: PropTypes.string,
    receivedAt: PropTypes.string,
    receivedBy: PropTypes.object,
    recognizingProcesses: PropTypes.array,
  }),
};

export default function DocumentCard({ document }) {
  const fullName = useMemo(
    () =>
      [document.receivedBy?.firstName || '', document.receivedBy?.lastName || ''].join(' ').trim(),
    [document.receivedBy],
  );

  return (
    <Box>
      <Link href={document.assetUrl} sx={styles.assetUrl} target="_blank">
        {document.number || document.assetOriginalFilename}
      </Link>

      {document.isOriginalReceived ? (
        <Box sx={styles.successAlert}>
          <CheckCircleIcon />
          <Box component="span" sx={{ fontSize: 10, lineHeight: '12px' }}>
            Оригинал получен {fullName} {document.receivedAt && formatDate(document.receivedAt)}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.warningAlert}>
          <WarningIcon />
          <Box component="span" sx={{ fontSize: 10, lineHeight: '12px' }}>
            Оригинал не получен
          </Box>
        </Box>
      )}

      <Typography sx={{ fontSize: 10 }}>
        {document.isOriginalReceived ? 'Доверенность на ' : null}
        {fullName} {document.receivedAt && formatDate(document.receivedAt, 'short_date_time')}
      </Typography>

      <Box>
        {document.recognizingProcesses.map((process, idx) => (
          <Box key={idx} sx={{ display: 'flex', alignItems: 'center', fontSize: 10, gap: 1 }}>
            <Typography sx={{ color: '#3DB205', fontSize: 10 }}>
              <FormattedMessage id={`document.recognizingStatus.${process.status}`} />
            </Typography>
            {idx !== document.recognizingProcesses.length - 1 ? <span> / </span> : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
