import PropTypes from "prop-types";
import React, { useMemo, useState } from 'react';

import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import dayjs from "dayjs";

import { ReactComponent as IconEdit } from './../img/editMLine.svg';
import { DatePickerBox } from "../../../components/DatePickerBox";
import { DATE_TIME_FORMAT_NOT_Z } from "../../../core/constants/Formats";
import { SHOP_SUPPLIES_CHANGE_STATUS } from "../graphql/mutations/shopSuppliesChangeStatus";
import { SHOP_SUPPLIES_UPDATE } from "../graphql/mutations/shopSuppliesUpdate";
import minRub from "../img/min-rub.svg";

ShipmentsModalActions.propTypes = {
  disabledButton: PropTypes.bool,
  row: PropTypes.object,
  showErrors: PropTypes.func,
  updateSupplies: PropTypes.func,
};

export default function ShipmentsModalActions({ disabledButton, row, showErrors, updateSupplies }) {
  const styles = useMemo(() => ({
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '400px',
        borderRadius: '16px',
      }
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    redButton: {
      background: '#FF5650',
      fontSize: '16px',
      fontWeight: 500,
      color: '#FFFFFF',
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        background: '#FF7773',
        boxShadow: '0px 6px 16px 0px rgba(229, 87, 87, 0.30)',
      },
      '&:active': {
        background: '#D94843',
      },
      '&:disabled': {
        background: '#CED2D6',
        color: '#868E98'
      },
    },
    iconButton: {
      borderRadius: '4px',
      background: '#F5F6F7',
      marginLeft: '8px',
      padding: '7px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },

      '& svg': {
        maxHeight: '21px',
      },

      '&:hover': {
        background: '#3D6CE7',
        color: '#ffffff',

        '& path': {
          fill: '#ffffff',
        },
      },
      '&:disabled': {
        background: '#CFD2D6',

        '& path': {
          fill: '#ffffff',
        },
      },
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '8px',
    },
    input: {
      maxHeight: '48px',

      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
        zIndex: 1,

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    inputDate: {
      marginTop: '1px',

      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    grayButton: {
      color: '#0C1C32',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#ECEDEF',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',
      border: '1px solid #ECEDEF',

      '&:hover': {
        background: '#F3F4F5',
        border: '1px solid #F3F4F5',
        color: '#3D6CE7',
        boxShadow: 'none',
      },
      '&:active': {
        background: '#E7E8EA',
        border: '1px solid #E7E8EA',
        color: '#3256B9',
      },
      '&:disabled': {
        background: '#CED2D6',
        border: '1px solid #CED2D6',
        color: '#868D98',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const [suppliesChangeStatus, { loading: loadingSuppliesChangeStatus }] = useMutation(SHOP_SUPPLIES_CHANGE_STATUS, {
    onCompleted: res => {
      updateSupplies(res.shopSuppliesChangeStatus.supply)
      toggleShowModalRemoveShipments();
    },
    onError: () => {
      showErrors();
    }
  });

  const [suppliesUpdate, { loading: loadingSuppliesUpdate }] = useMutation(SHOP_SUPPLIES_UPDATE, {
    onCompleted: res => {
      updateSupplies(res.shopSuppliesUpdate.supply)
      toggleShowModalChangeShipments();
    },
    onError: () => {
      showErrors();
    }
  });

  const onRemoveSupplies = () => {
    suppliesChangeStatus({
      variables: {
        supplyId: row.id,
        transition: "REJECT"
      }
    })
  }

  const [showModalRemoveShipments, setShowModalRemoveShipments] = useState(false);
  const toggleShowModalRemoveShipments = () => {
    setShowModalRemoveShipments(!showModalRemoveShipments)
  }

  const [deliveredAt, setDeliveredAt] = useState(row.deliveredAt);

  const [amount, setAmount] = useState(row.amount);

  const onChangeField = (type, event) => {
    if (type === "deliveredAt") {
      setDeliveredAt(dayjs(event).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z));
    } else {
      setAmount(event.target.value)
    }
  }

  const handlerSuppliesUpdate = () => {
    suppliesUpdate({
      variables: {
        supplyId: row.id,
        params: {
          deliveredAt,
          amount: Number(amount)
        }
      }
    })

    toggleShowModalChangeShipments();
  }

  const [showModalChangeShipments, setShowModalChangeShipments] = useState(false);
  const toggleShowModalChangeShipments = () => {
    setShowModalChangeShipments(!showModalChangeShipments)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5px' }}>
        <IconButton disabled={disabledButton} onClick={toggleShowModalChangeShipments} sx={ styles.iconButton }>
          <IconEdit />
        </IconButton>

        {row.status !== "REJECTED" && (
          <IconButton disabled={disabledButton} onClick={toggleShowModalRemoveShipments} sx={ styles.iconButton }>
            Отклонить
          </IconButton>
        )}
      </Box>

      {showModalRemoveShipments && (
        <Dialog fullWidth open={true} scroll="body" sx={ styles.modal }>
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
              Отклонить отгрузку № {row.id}
            </Typography>

            <IconButton onClick={toggleShowModalRemoveShipments}>
              <CloseIcon sx={{ color: '#B6BBC2' }} />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography>Вы уверены?</Typography>
          </DialogContent>

          <DialogActions sx={{ padding: '24px' }}>
            <Button
              disabled={loadingSuppliesChangeStatus}
              onClick={onRemoveSupplies}
              sx={ styles.redButton }
            >
              Да
            </Button>

            <Button
              onClick={toggleShowModalRemoveShipments}
              sx={ styles.blueButton }
            >
              Нет
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showModalChangeShipments && (
        <Dialog fullWidth open={true} scroll="body" sx={{ ...styles.modal, '& .MuiDialog-paper': { maxWidth: '600px' }  }}>
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
              Изменить поставку № {row.id}
            </Typography>

            <IconButton onClick={toggleShowModalChangeShipments}>
              <CloseIcon sx={{ color: '#B6BBC2' }} />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ display: 'flex', gap: '16px', paddingBottom: '25px' }}>
              <Box sx={{ width: '50%' }}>
                <Typography sx={ styles.label }>
                  Сумма:
                </Typography>

                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ zIndex: 1 }}>
                        <Box alt={'Рублей'} component={"img"} src={minRub} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={onChangeField.bind(this, 'amount')}
                  placeholder={'0.00'}
                  sx={{
                    ...styles.input,
                    '& fieldset': {
                      background: '#ffffff',
                      borderRadius: '8px',
                    }
                  }}
                  type="number"
                  value={amount}
                />
              </Box>

              <Box sx={{ width: '50%' }}>
                <Typography sx={ styles.label }>
                  Дата:
                </Typography>

                <DatePickerBox
                  onChange={onChangeField.bind(this, 'deliveredAt')}
                  placeholder={"15.02.2020"}
                  size="small"
                  sx={ styles.inputDate }
                  value={deliveredAt}
                />
              </Box>
            </Box>
          </DialogContent>

          <DialogActions sx={{ padding: '24px' }}>
            <Button
              onClick={toggleShowModalChangeShipments}
              sx={ styles.grayButton }
            >
              Отмена
            </Button>

            <Button
              disabled={loadingSuppliesUpdate || !amount || !deliveredAt}
              onClick={handlerSuppliesUpdate}
              sx={ styles.blueButton }
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}