import React, { useEffect, useMemo, useState, useRef } from 'react';

import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  SvgIcon,
  Autocomplete,
  Link,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from "@material-ui/icons/Close";
import { useParams, useNavigate, useLocation } from "@reach/router";
import dayjs from "dayjs";
import {cloneDeep, findIndex} from "lodash";
import { useReactToPrint } from "react-to-print";

import PrintOutput from './components/PrintOutput';
import { SHOP_STORAGE_ACCEPTANCES_ADD_GOOD } from "./graphql/mutations/shopStorageAcceptancesAddGood";
import { GET_SHOP_EQUIPMENT_TYPES } from "./graphql/queries/getShopEquipmentTypes";
import { SHOP_GOOD_COPY_VALUES } from "./graphql/queries/shopGoodCopyValues";
import { GET_SHOP_STORAGE_ACCEPTANCE_GOOD_DEFAULT_VALUES } from "./graphql/queries/shopStorageAcceptanceGoodDefaultValues";
import { GET_STORAGE_ACCEPTANCE_DEFAULT_VALUES } from "./graphql/queries/storageAcceptanceDefaultValues";
import iconInf from './img/inf.svg';
import { ReactComponent as IconLeftArrow } from './img/leftArrow.svg';
import minRub from "./img/min-rub.svg";
import print from "./img/print.svg";
import iconSuccess from './img/success.svg';
import { AmountField } from "../../components/AmountField/AmountField";
import { DatePickerBox } from "../../components/DatePickerBox";
import DropzoneImages from "../../components/DropzoneImages";
import ErrorAlert from "../../components/ErrorAlert";
import FormattedCurrency from "../../components/FormattedCurrency";
import WrapperButton from "../../components/WrapperButton";
import { DATE_TIME_FORMAT } from "../../core/constants/Formats";
import { GET_SHOP_STORAGES } from "../shop/graphql/queries/getShopStorages";
import { Loader } from "../workspaces/components/helpers";

export default function PageParametersWarehouse() {
  const styles = useMemo(() => ({
    pageTitleContainer: {
      alignItems: {
        xs: 'flex-start',
        lg: 'center'
      },
      display: 'flex',
      gap: '16px',
      padding: {
        xs: '16px 16px 0',
        lg: '0 24px'
      },
      backgroundColor: '#ffffff',
      height: '100px'
    },
    returnButton: {
      padding: "12px",
      borderRadius: "8px",
      mt: {
        xs: '3px',
        lg: 0
      },

      "& svg": {
        marginLeft: 0
      }
    },
    pageTitle: {
      fontSize: {
        xs: '24px !important',
        lg: '32px !important'
      },
      margin: '0 !important',
      fontWeight: 700,
      display: {
        xs: 'flex',
        lg: 'block'
      },
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },

      "& span": {
        color: "#3D6CE7",
        fontWeight: "inherit",
        fontSize: "inherit"
      }
    },
    wrapperContent: {
      margin: {
        xs: '16px',
        lg:'24px'
      },
      padding: {
        xs: '16px',
        lg:'24px'
      },
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      // height: 'calc(100% - 120px)',
      overflow: 'hidden',
    },
    blockTitle: {
      margin: {
        xs: '16px',
        lg:'24px'
      },
      display: "flex",
      justifyContent: "space-between",
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },
      gap: {
        xs: '16px',
        lg: '0'
      }
    },
    titleContainer: {
      flexDirection: {
        xs: 'column',
        lg: 'row'
      }
    },
    title: {
      marginBottom: "6px",
      fontSize: {
        xs: '16px',
        lg: '26px'
      },
      fontWeight: 700,
      display: "flex",
      gap: "6px",
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },
      alignItems: {
        xs: 'flex-start',
        lg: 'center'
      },

      "& a": {
        color: "#3D6CE7"
      }
    },
    titleElement: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '6px'
    },
    titleInfo: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      alignItems: {
        xs: 'flex-start',
        lg: 'flex-end'
      }
    },
    titleInfo1: {
      color: "#6E7884",
      fontSize: "14px",
      fontWeight: {
        xs: 400,
        lg: 500
      }
    },
    titleInfo2: {
      color: '#0D1D32',
      fontSize: {
        xs: '14px',
        lg: '32px'
      },
      fontWeight: {
        xs: 400,
        lg: 700
      }
    },
    titleInfo3: {
      color: "#6E7884",
      fontSize: "14px",
      fontWeight: {
        xs: 400,
        lg: 500
      }
    },
    description: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",

      '& span': {
        display: 'inline-block',
        fontSize: '16px',
        fontWeight: '300',
        color: '#0D1D32',
      },
      '& a': {
        color: "#3D6CE7"
      },
      "svg:hover": {
        "path": {
          fill: "#3D6CE7"
        }
      }
    },
    titleContent: {
      fontSize: {
        xs: '18px',
        lg: '22px'
      },
      fontWeight: 700,
      marginBottom: "24px",
    },
    wrapperInput: {
      marginBottom: "24px",
    },
    wrapperBaseInfo: {
      display: "flex",
      gap: {
        xs: 0,
        lg: '24px'
      },
      flexWrap: {
        xs: 'wrap',
        lg: 'nowrap'
      },

      '& > div': {
        flex: {
          xs: '100%',
          lg: '1 1 100%'
        }
      }
    },
    wrapperGrey: {
      padding: {
        xs: '16px',
        lg:'24px'
      },
      background: "#F5F6F7",
      borderRadius: "8px",
      marginBottom: "24px"
    },
    wrapperExpluatation: {
      display: "flex",
      gap: {
        xs: 0,
        lg: '24px'
      },
      flexWrap: {
        xs: 'wrap',
        lg: 'nowrap'
      },

      '& > div': {
        flex: {
          xs: '100%',
          lg: '1 1 25%'
        }
      }
    },
    wrapperPrice: {
      display: "flex",
      gap: {
        xs: 0,
        lg: '24px'
      },
      flexWrap: {
        xs: 'wrap',
        lg: 'nowrap'
      },

      '& > div': {
        flex: {
          xs: '100%',
          lg: '1 1 33%'
        }
      }
    },
    label: {
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: '8px',
    },
    input: {
      width: "100%",
      borderRadius: '8px',
      background: "#ffffff",

      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    inputDate: {
      marginTop: '1px',

      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',

      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '11.5px 0 11.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      }
    },
    wrapperUpImg: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      marginTop: '24px',
      marginBottom: '24px',
    },
    blockImg: {
      borderRadius: '8px',
      overflow: 'hidden',
      border: '1px solid #E7E8EA',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '142px',
      height: '142px',

      '&.PURGE': {
        opacity: '0.3'
      },
      '& .index': {
        position: 'absolute',
        right: '8px',
        top: '8px',
        background: '#67BF3D',
        borderRadius: '20px',
        color: '#ffffff',
        fontSize: '14px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .remove': {
        cursor: 'pointer',
        position: 'absolute',
        left: '8px',
        top: '8px',
        background: '#67BF3D',
        borderRadius: '20px',
        color: '#ffffff',
        fontSize: '14px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& img': {
        width: '100%',
      }
    },
    autocomplete: {
      '& .MuiInputBase-root': {
        background: '#ffffff',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '16px',

        '&::placeholder': {
          color: '#B6BBC2',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #3D6CE7 !important',
        },
        '&.Mui-disabled': {
          background: '#F5F6F7',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
      },
    },
    autocompleteList: {
      color: '#6E7884',
      display: 'flex',
      justifyContent: 'space-between !important',

      '&.MuiAutocomplete-option[aria-selected=true]': {
        backgroundColor: '#ffffff!important',
        color: '#3D6CE7 !important',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
    },
    ButtonCreateContainer: {
      margin: "24px",
      display: "flex",
      justifyContent: {
        xs: 'center',
        lg: 'flex-end'
      }
    },
    ButtonCreate: {
      width: {
        xs: '100%',
        lg: 'auto'
      }
    },
    buttonGroup: {
      display: 'flex',
      gap: '16px',
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },
      alignItems: {
        xs: 'center',
        lg: 'inherit'
      }
    },
    Button: {
      marginTop: "24px",
      marginBottom: "24px",
      color: "#6E7884",
      cursor: "pointer",
      width: {
        xs: '100%',
        lg: 'auto'
      }
    }
  }), []);

  const navigate = useNavigate();
  const location = useLocation();

  const { shippedEquipmentId, storageAcceptanceId } = useParams();
  const componentRef = useRef(null);

  const [shopGoodDefaultValues, setShopGoodDefaultValues] = useState(null);

  const [fetchEquipmentTypes, { data: equipmentTypes, loadingEquipmentTypes }] = useLazyQuery(GET_SHOP_EQUIPMENT_TYPES);
  const [putGoodCopy, { loading: loadingPutGoodCopy }] = useLazyQuery(SHOP_GOOD_COPY_VALUES, {
    onError: () => {
      setShowModalError(true)
    },
    onCompleted: () => {
      onToggleShowModal()

      setFiles([]);

      navigate(location.pathname);
    }
  });

  const [quantityOfAcceptedAndAvailableProducts, setQuantityOfAcceptedAndAvailableProducts] = useState({
    quantity: 0,
    shopGoodsCount: 0
  });

  const { error: errorGetGoodValue, loading: loadingGetGoodValue } = useQuery(GET_SHOP_STORAGE_ACCEPTANCE_GOOD_DEFAULT_VALUES, {
    variables: {
      storageAcceptanceId,
      shippedEquipmentId,
    },
    onCompleted: res => {
      const cloneShopGoodDefaultValues = { ...res?.shopStorageAcceptanceGoodDefaultValues };

      delete cloneShopGoodDefaultValues?.__typename;

      cloneShopGoodDefaultValues.receivingDate = res?.shopStorageAcceptanceGoodDefaultValues?.shippedEquipment?.loanSupply?.date;
      cloneShopGoodDefaultValues.producedAt = null;

      setShopGoodDefaultValues(cloneShopGoodDefaultValues || null);

      setQuantityOfAcceptedAndAvailableProducts({
        quantity: res?.shopStorageAcceptanceGoodDefaultValues?.shippedEquipment?.quantity,
        shopGoodsCount: res?.shopStorageAcceptanceGoodDefaultValues?.shippedEquipment?.shopGoodsCount
      })
    }
  });

  const [files, setFiles] = useState([]);
  const [equipment, setEquipment] = useState([]);

  const { error: errorGetAcceptanceValue, loading: loadingGetAcceptanceValue } = useQuery(GET_STORAGE_ACCEPTANCE_DEFAULT_VALUES, {
    variables: {
      storageAcceptanceId,
    },
    onCompleted: res => {
      // let img = [];
      //
      // res?.storageAcceptance?.goods?.forEach(good => {
      //   if (good?.images?.length) {
      //     good?.images.forEach(image => {
      //       img.push(image);
      //     });
      //   }
      // })

      // setFiles(img);
    }
  });

  const [storages, setStorages] = useState([]);

  const { data: dataStorages } = useQuery(GET_SHOP_STORAGES);

  useEffect(() => {
    if (dataStorages?.shopStorages?.collection?.length > 0) {
      setStorages(dataStorages?.shopStorages?.collection);
    }
  }, [dataStorages]);

  const [showModal, setShowModal] = useState(false);
  const onToggleShowModal = () => {
    setShowModal(!showModal);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [showModalError, setShowModalError] = useState(false);
  const onToggleShowModalError = () => {
    setShowModalError(!showModalError);
  }

  const [createGoods, {
    data: dataCreateGoods,
    error: errorCreateGoods,
    loading: loadingCreateGoods
  }] = useMutation(SHOP_STORAGE_ACCEPTANCES_ADD_GOOD, {
    onError: () => {
      setShowModalError(true)
    },
    onCompleted: () => {
      const cloneQuantityOfAcceptedAndAvailableProducts = cloneDeep(quantityOfAcceptedAndAvailableProducts);
      cloneQuantityOfAcceptedAndAvailableProducts.shopGoodsCount++;
      setQuantityOfAcceptedAndAvailableProducts(cloneQuantityOfAcceptedAndAvailableProducts);

      setShowModal(true);
    }
  });

  const onCreateGoods = () => {
    const newFiles = [];

    files.forEach(file => {
      newFiles.push({
        action: file.action || "UPLOAD",
        file: file,
        imageId: file.imageId,
      })
    });

    const cloneShopGoodDefaultValues = cloneDeep(shopGoodDefaultValues);
    cloneShopGoodDefaultValues.images = newFiles;
    if (cloneShopGoodDefaultValues.status === 'READY_FOR_PUBLISH') {
      cloneShopGoodDefaultValues.prepareToPublish = true;
    }

    if(cloneShopGoodDefaultValues?.equipmentType?.id) {
      cloneShopGoodDefaultValues.equipmentTypeId = parseInt(cloneShopGoodDefaultValues.equipmentType.id, 10);
    }

    delete cloneShopGoodDefaultValues?.equipmentType;
    delete cloneShopGoodDefaultValues.status;
    delete cloneShopGoodDefaultValues?.priceDiscounted;
    delete cloneShopGoodDefaultValues?.sellingPrice;
    delete cloneShopGoodDefaultValues?.shippedEquipment;
    delete cloneShopGoodDefaultValues?.receivingDate;
    delete cloneShopGoodDefaultValues?.vat;

    cloneShopGoodDefaultValues.discount = Number(cloneShopGoodDefaultValues.discount);
    cloneShopGoodDefaultValues.price = Number(cloneShopGoodDefaultValues.price);

    createGoods({
      variables: {
        params: { ...cloneShopGoodDefaultValues },
      }
    })
  }

  const onCreateDoubleGoods = id => {
    putGoodCopy({
      variables: {
        goodId: id
      }
    })
  }

  const onChangeFields = (field, event) => {
    const draftShopGoodDefaultValues = cloneDeep(shopGoodDefaultValues);
    let value;

    if (field ==="producedAt") {
      value = dayjs(event).utc(true).startOf("day").format(DATE_TIME_FORMAT)
    } else if(field ==="equipmentType") {
      const newValue = event.target.value;
      const index= findIndex(equipment, ["id", newValue.toString()]);
      value = equipment[index];
    } else {
      value = event.target.value;
    }



    if (field === "storageId") {
      let indexStorages = findIndex(storages, ["id", value.toString()]);

      draftShopGoodDefaultValues["locationAddressForSite"] = storages[indexStorages].addressForSite;
    }

    if (field === "currentLocation" && value === "CLIENT") {
      draftShopGoodDefaultValues["locationAddressForSite"] = "";
    }

    if (field === 'priceDiscounted') {
      if (draftShopGoodDefaultValues.price && value) {
        draftShopGoodDefaultValues.discount = (100 - (value * 100 / draftShopGoodDefaultValues.price)).toFixed(3);
      } else {
        draftShopGoodDefaultValues.discount = "";
      }
    }

    if (field === 'discount') {
      if (value > 100) return false;

      if (draftShopGoodDefaultValues.price && value) {
        draftShopGoodDefaultValues.priceDiscounted = (draftShopGoodDefaultValues.price - (draftShopGoodDefaultValues.price / 100 * value)).toFixed(0);
      } else {
        draftShopGoodDefaultValues.priceDiscounted = "";
      }
    }

    if (field === 'price') {
      if (draftShopGoodDefaultValues.discount && value) {
        draftShopGoodDefaultValues.priceDiscounted = (value - (value / 100 * draftShopGoodDefaultValues.discount)).toFixed(0);
      } else {
        draftShopGoodDefaultValues.priceDiscounted = "";
      }
    }

    draftShopGoodDefaultValues[field] = value;

    setShopGoodDefaultValues(draftShopGoodDefaultValues);
  }

  const handleDownloadedDeleteFile = id => {
    const cloneFiles = cloneDeep(files);

    let indexElem = findIndex(cloneFiles, ["imageId", id]);

    if (indexElem !== -1) {
      if (cloneFiles[indexElem].action === "PURGE") {
        cloneFiles[indexElem].action = "UPLOAD";
      } else {
        cloneFiles[indexElem].action = "PURGE";
      }
    }

    setFiles(cloneFiles);
  }

  const [disabledCreateButton, setDisabledCreateButton] = useState(true);

  useEffect(() => {
    let disabledByQuantity = shopGoodDefaultValues?.shippedEquipment?.quantity <= shopGoodDefaultValues?.shippedEquipment?.shopGoodsCount;
    let disabledDescription = !shopGoodDefaultValues?.description?.trim()?.length;
    let disabledCurrentLocation = shopGoodDefaultValues?.currentLocation === "NONE";
    let disabledAdress = true;

    if (shopGoodDefaultValues?.currentLocation === "STORAGE") {
      disabledAdress = !shopGoodDefaultValues?.storageId
    }

    if (shopGoodDefaultValues?.currentLocation === "CLIENT") {
      disabledAdress = !shopGoodDefaultValues?.locationAddressForSite?.trim()?.length
    }

    let currentLocationAddress = !shopGoodDefaultValues?.currentLocationAddress?.trim()?.length;
    let disabledQuality = shopGoodDefaultValues?.quality === "NONE";
    let disabledStatus = shopGoodDefaultValues?.status === "NONE";
    let disabledProducedAt = !shopGoodDefaultValues?.producedAt;
    let disabledPrice = !shopGoodDefaultValues?.price;
    let disabledFilesImages = !files?.length;
    let disabledSelectCategory = !shopGoodDefaultValues?.equipmentType?.id;

    if (disabledByQuantity || disabledDescription || disabledCurrentLocation || disabledAdress || disabledQuality || disabledStatus || disabledProducedAt || disabledPrice || disabledFilesImages || disabledSelectCategory || currentLocationAddress || quantityOfAcceptedAndAvailableProducts.quantity <= quantityOfAcceptedAndAvailableProducts.shopGoodsCount) {
      setDisabledCreateButton(true)
    } else {
      setDisabledCreateButton(false)
    }
  }, [files?.length, shopGoodDefaultValues?.currentLocation, shopGoodDefaultValues?.locationAddressForSite, shopGoodDefaultValues?.description, shopGoodDefaultValues?.price, shopGoodDefaultValues?.producedAt, shopGoodDefaultValues?.quality, shopGoodDefaultValues?.shippedEquipment?.quantity, shopGoodDefaultValues?.shippedEquipment?.shopGoodsCount, shopGoodDefaultValues?.status, shopGoodDefaultValues?.equipmentType?.id, shopGoodDefaultValues?.storageId, shopGoodDefaultValues?.currentLocationAddress, quantityOfAcceptedAndAvailableProducts.quantity, quantityOfAcceptedAndAvailableProducts.shopGoodsCount]);

  useEffect(() => {
    fetchEquipmentTypes({ variables: {topOnly: true}});

    if (equipmentTypes && equipmentTypes?.equipmentTypes) {
      setEquipment(equipmentTypes?.equipmentTypes);
    }
  }, [equipmentTypes, fetchEquipmentTypes])

  if (loadingGetGoodValue || loadingGetAcceptanceValue || loadingEquipmentTypes) {
    return <Loader />
  }

  if (errorGetGoodValue || errorGetAcceptanceValue) {
    return <ErrorAlert message={errorGetGoodValue.message || errorGetAcceptanceValue.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <>
      <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
        <Box sx={ styles.pageTitleContainer }>
          <WrapperButton colorScheme={"whiteOutline"} onClick={() => { navigate(-1) }} sx={styles.returnButton}>
            <SvgIcon component={IconLeftArrow} />
          </WrapperButton>

          <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
            Товары/<Typography component={"span"}>Приемка на склад</Typography>
          </Typography>
        </Box>

        <Box sx={ styles.blockTitle }>
          <Box sx={ styles.titleContainer }>
            <Box sx={ styles.title }>
              <Box sx={ styles.titleElement }>
                Заказ <Link href={shopGoodDefaultValues?.shippedEquipment?.loanSupply?.supplyingOrder?.url}>{shopGoodDefaultValues?.shippedEquipment?.loanSupply?.supplyingOrder?.number}</Link>

                <Typography component={"span"} sx={{ color: "#b7bbc1" }}>•</Typography>
              </Box>
              <Box sx={ styles.titleElement }>
                Поставщик <Link href={shopGoodDefaultValues?.shippedEquipment?.supplier?.url} target={"_blank"}>{shopGoodDefaultValues?.shippedEquipment?.supplier?.shortLegalName || "Поставщик отсутствует"}</Link>
              </Box>
            </Box>

            <Box sx={ styles.description }>
              <Typography component={"span"}>ID Отгруженного оборудования</Typography>
              &nbsp;
              <Typography component={"span"} style={{ color: "#3D6CE7", fontWeight: "bold" }}>
                {shippedEquipmentId}
              </Typography>
            </Box>
          </Box>

          <Box sx={ styles.titleInfo }>
            <Typography sx={ styles.titleInfo1 }>Стоимость закупки оборудования (за 1 ед. товара)</Typography>
            <Typography sx={ styles.titleInfo2 }><FormattedCurrency value={shopGoodDefaultValues?.sellingPrice} /></Typography>
            <Typography sx={ styles.titleInfo3 }>НДС <FormattedCurrency value={shopGoodDefaultValues?.vat} /></Typography>
          </Box>
        </Box>

        <Box sx={ styles.wrapperContent }>
          <Typography sx={ styles.titleContent }>Поместить в карту товара:</Typography>

          <Box sx={ styles.wrapperInput }>
            <Typography sx={ styles.label }>Название (Заголовок):</Typography>
            <TextField onChange={onChangeFields.bind(this, "name")} placeholder={"Не указана"} sx={ styles.input } value={shopGoodDefaultValues?.name} />
          </Box>

          <Box sx={ styles.wrapperInput }>
            <Typography sx={ styles.label }>Описание:</Typography>
            <TextField minRows={3} multiline onChange={onChangeFields.bind(this, "description")} placeholder={"Описание"}  sx={ styles.input } value={shopGoodDefaultValues?.description} />
          </Box>

          <Box sx={ styles.wrapperGrey }>
            <Box sx={ styles.wrapperBaseInfo }>
              <Box sx={ styles.wrapperInput }>
                <Typography sx={ styles.label }>Местонахождение:</Typography>
                <Select
                  fullWidth
                  onChange={onChangeFields.bind(this, 'currentLocation')}
                  sx={styles.select}
                  value={shopGoodDefaultValues?.currentLocation || "NONE"}
                >
                  <MenuItem disabled sx={ styles.selectItem } value={'NONE'}>Не выбрано</MenuItem>
                  <MenuItem sx={ styles.selectItem } value={'STORAGE'}>На складе</MenuItem>
                  <MenuItem sx={ styles.selectItem } value={'CLIENT'}>У клиента</MenuItem>
                </Select>
              </Box>

              <Box sx={ styles.wrapperInput }>
                {shopGoodDefaultValues?.currentLocation === "CLIENT" && (
                  <>
                    <Typography sx={ styles.label }>Фактический адрес:</Typography>
                    <TextField
                      onChange={onChangeFields.bind(this, "currentLocationAddress")}
                      placeholder={"Укажите фактический адрес"}
                      sx={ styles.input }
                      value={shopGoodDefaultValues?.currentLocationAddress}
                    />
                  </>
                )}

                {shopGoodDefaultValues?.currentLocation === "STORAGE" && (
                  <>
                    <Typography sx={ styles.label }>Склад:</Typography>
                    <Select
                      fullWidth
                      onChange={onChangeFields.bind(this, 'storageId')}
                      sx={styles.select}
                      value={shopGoodDefaultValues?.storageId || "NONE"}
                    >
                      <MenuItem disabled sx={ styles.selectItem } value={'NONE'}>Не выбрано</MenuItem>
                      {storages.map(storage => (
                        <MenuItem key={storage.id} sx={ styles.selectItem } value={+storage.id}>{storage.name}</MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Box>

              <Box sx={ styles.wrapperInput }>
                  <Typography sx={ styles.label }>Адрес для публикации:</Typography>
                  <TextField
                    disabled={shopGoodDefaultValues?.currentLocation === "STORAGE"}
                    onChange={onChangeFields.bind(this, "locationAddressForSite")}
                    placeholder={"Укажите город"}
                    sx={ styles.input }
                    value={shopGoodDefaultValues?.locationAddressForSite}
                  />
              </Box>
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Комментарий:</Typography>
              <TextField minRows={3} multiline onChange={onChangeFields.bind(this, "storageComment")} placeholder={"Опишите комментарий"} sx={ styles.input } value={shopGoodDefaultValues?.storageComment} />
            </Box>
          </Box>

          <Box sx={ styles.wrapperInput }>
            <Typography sx={ styles.label }>Серийный номер:</Typography>
            <TextField onChange={onChangeFields.bind(this, "serialNumber")} placeholder={"Не указан"} sx={ styles.input } value={shopGoodDefaultValues?.serialNumber} />
          </Box>

          <Box sx={styles.wrapperExpluatation}>
            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Начало эксплуатации:</Typography>
              <DatePickerBox
                disabled={true}
                onChange={onChangeFields.bind(this, 'receivingDate')}
                placeholder={"15.02.2020"}
                size="small"
                sx={ styles.inputDate }
                value={shopGoodDefaultValues?.receivingDate || null}
              />
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Состояние:</Typography>
              <Select
                fullWidth
                onChange={onChangeFields.bind(this, 'quality')}
                sx={styles.select}
                value={shopGoodDefaultValues?.quality || "NONE"}
              >
                <MenuItem disabled sx={ styles.selectItem } value={'NONE'}>Не выбрано</MenuItem>
                <MenuItem sx={ styles.selectItem } value={'SATISFACTORY'}>Удовлетворительное</MenuItem>
                <MenuItem sx={ styles.selectItem } value={'GOOD'}>Хорошее</MenuItem>
                <MenuItem sx={ styles.selectItem } value={'PERFECT'}>Отличное</MenuItem>
                <MenuItem sx={ styles.selectItem } value={'NEWEST'}>Новое</MenuItem>
              </Select>
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Готовность к продаже:</Typography>
              <Select
                fullWidth
                onChange={onChangeFields.bind(this, 'status')}
                sx={styles.select}
                value={shopGoodDefaultValues?.status || "NONE"}
              >
                <MenuItem disabled sx={ styles.selectItem } value={'NONE'}>Не выбрано</MenuItem>
                <MenuItem sx={styles.selectItem} value={"IN_STOCK"}>На складе</MenuItem>
                <MenuItem sx={styles.selectItem} value={"READY_FOR_PUBLISH"}>Готов к продаже</MenuItem>
              </Select>
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Дата производства:</Typography>
              <DatePickerBox
                onChange={onChangeFields.bind(this, 'producedAt')}
                placeholder={"15.02.2020"}
                size="small"
                sx={ styles.inputDate }
                value={shopGoodDefaultValues?.producedAt || null}
              />
            </Box>
          </Box>

          <Box sx={ styles.wrapperPrice }>
            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Стоимость:</Typography>
              <TextField
                InputProps={{
                  inputComponent: AmountField,
                  endAdornment: (
                    <InputAdornment position="end" sx={{ zIndex: 1 }}>
                      <Box alt={'Рублей'} component={"img"} src={minRub} />
                    </InputAdornment>
                  ),
                }}
                onChange={onChangeFields.bind(this, "price")}
                placeholder={"Не указана"}
                sx={ styles.input }
                value={shopGoodDefaultValues?.price}
              />
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Стоимость с учетом скидки:</Typography>
              <TextField
                InputProps={{
                  inputComponent: AmountField,
                  endAdornment: (
                    <InputAdornment position="end" sx={{ zIndex: 1 }}>
                      <Box alt={'Рублей'} component={"img"} src={minRub} />
                    </InputAdornment>
                  ),
                }}
                disabled={true}
                placeholder={"Не указана"}
                sx={ styles.input }
                value={shopGoodDefaultValues?.priceDiscounted}
              />
            </Box>

            <Box sx={ styles.wrapperInput }>
              <Typography sx={ styles.label }>Скидка %:</Typography>
              <TextField onChange={onChangeFields.bind(this, "discount")} placeholder={"Не указана"} sx={ styles.input } value={shopGoodDefaultValues?.discount} />
            </Box>
          </Box>

          <Box sx={{ marginBottom: "24px" }}>
            <Typography sx={ styles.label }>
              Категория:
            </Typography>

            <Autocomplete
              disableClearable
              disablePortal
              getOptionLabel={(option) => option.name}
              noOptionsText={loadingEquipmentTypes ? 'Поиск...' : 'Нет данных'}
              onChange={onChangeFields.bind(this, 'equipmentType')}
              options={equipment}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel={true}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  placeholder={'Не выбрано'}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  id={option.id}
                  sx={styles.autocompleteList}
                  value={option.id}
                  {...props}
                >
                  {option.name}
                </Box>
              )}
              sx={styles.autocomplete}
              value={shopGoodDefaultValues?.equipmentTypes?.length > 0 ? shopGoodDefaultValues.equipmentTypes[0] : undefined}
            />
          </Box>

          <Box>
            <Typography sx={ styles.label }>Фото:</Typography>
            <Box sx={ styles.wrapperUpImg }>
              {files?.length > 0 && files?.map((file, index) => (
                <Box className={file.action} key={index} sx={styles.blockImg}>
                  <Box className={'remove'} onClick={handleDownloadedDeleteFile.bind(this, file.imageId)}>x</Box>
                  <Box className={'index'}>{index + 1}</Box>
                    <img
                      alt={file?.name}
                      src={file?.preview || file.url}
                      width="120px"
                    />
                </Box>
              ))}
            </Box>

            <DropzoneImages onFilesDropped={setFiles} />
          </Box>
        </Box>

        <Box sx={styles.ButtonCreateContainer}>
          <WrapperButton
            colorScheme={"white"}
            disabled={loadingCreateGoods || disabledCreateButton }
            onClick={onCreateGoods}
            sx={ styles.ButtonCreate }>
            Создать
          </WrapperButton>
        </Box>
      </Box>

      {showModal && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onToggleShowModal}
          open={showModal}
          scroll="body"
          sx={{
            '& .MuiPaper-root': {
              margin: {
                xs: '8px',
                lg: 'inherit'
              },
              maxWidth: {
                xs: 'calc(100% - 16px)',
                lg: 'inherit'
              },
              width: {
                xs: 'calc(100% - 16px)',
                lg: 'inherit'
              }
            },
            '& .MuiPaper-root:not(.MuiAlert-root)': {
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle
            disableTypography
            sx={{
              display: 'flex',
              justifyContent: "flex-end"
            }}
          >
            <IconButton onClick={onToggleShowModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers sx={{ minHeight: 150, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', border: 'none', }}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column" }}>
              <Box component={"img"} src={iconSuccess} sx={{ marginBottom: "24px" }} />

              <Typography sx={{ fontSize: "26px", fontWeight: 900, marginBottom: "24px", textAlign: "center" }}>
                Товар ID {dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.id} <br />успешно добавлен
              </Typography>

              <Box sx={ styles.buttonGroup }>
                <WrapperButton
                  colorScheme={"breeze"}
                  disabled={loadingPutGoodCopy || quantityOfAcceptedAndAvailableProducts.quantity <= quantityOfAcceptedAndAvailableProducts.shopGoodsCount}
                  onClick={onCreateDoubleGoods.bind(this, dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.id)}
                >
                  Создать аналогичный товар <AddIcon />
                </WrapperButton>

                <IconButton onClick={handlePrint} sx={{ border: '1px solid #CFD2D6', borderRadius: '8px', width: '59px', height: '59px' }}>
                  <Box alt={'Печать информации о товаре'} component={"img"} src={print} sx={{ width: '24px', height: '24px' }} />
                </IconButton>
              </Box>

              <Typography onClick={onToggleShowModal} sx={{ marginTop: "24px", marginBottom: "24px", color: "#6E7884", cursor: "pointer" }}>Закрыть</Typography>

              <div style={{ display: 'none' }}>
                <PrintOutput
                  acceptedAt={new Date()}
                  description={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.description}
                  dispayLoanLeaseAgreement={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.storageAcceptance?.dispayLoanLeaseAgreement}
                  dispayLoanSupplyDate={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.shippedEquipment?.dispayLoanSupplyDate}
                  id={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.id}
                  name={dataCreateGoods?.shopStorageAcceptencasAddGood?.good?.name}
                  producedAt={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.producedAt}
                  quality={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.quality}
                  ref={componentRef}
                  serialNumber={dataCreateGoods?.shopStorageAcceptancesAddGood?.good?.serialNumber}
                />
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalError && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onToggleShowModalError}
          open={showModalError}
          scroll="body"
          sx={{
            '& .MuiPaper-root': {
              margin: {
                xs: '8px',
                lg: 'inherit'
              },
              maxWidth: {
                xs: 'calc(100% - 16px)',
                lg: 'inherit'
              },
              width: {
                xs: 'calc(100% - 16px)',
                lg: 'inherit'
              }
            },
            '& .MuiPaper-root:not(.MuiAlert-root)': {
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle
            disableTypography
            sx={{
              display: 'flex',
              justifyContent: "flex-end"
            }}
          >
            <IconButton onClick={onToggleShowModalError}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers sx={{ minHeight: 150, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', border: 'none', }}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column" }}>
              <Box component={"img"} src={iconInf} sx={{ marginBottom: "24px", width: "90px" }} />

              <Typography sx={{ fontSize: "26px", fontWeight: 900, marginBottom: "24px", textAlign: "center" }}>
                Произошла ошибка
              </Typography>

              <Typography sx={{ marginBottom: "24px", textAlign: "center" }}>
                {errorCreateGoods?.networkError?.result?.errors[0]?.message}
              </Typography>

              <Typography onClick={onToggleShowModalError} sx={ styles.Button }>Закрыть</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}