import {
  SET_CURRENT_PAGE,
  SET_NUM_PAGES,
  SET_PDF_PAGINATED,
  SET_ROTATION_DEGREE,
  SET_ZOOM_LEVEL,
} from './actions';

export const initialPDFRendererState = {
  zoomLevel: 1,
  rotationDegree: 0,
  paginated: true,
  numPages: 0,
  currentPage: 1,
};

export const reducer = (state = initialPDFRendererState, action) => {
  switch (action.type) {
    case SET_ZOOM_LEVEL: {
      const { value } = action;
      return { ...state, zoomLevel: value };
    }

    case SET_ROTATION_DEGREE: {
      const { value } = action;
      return { ...state, rotationDegree: value };
    }

    case SET_PDF_PAGINATED: {
      const { value } = action;
      return { ...state, paginated: value };
    }

    case SET_NUM_PAGES: {
      const { value } = action;
      return { ...state, numPages: value };
    }

    case SET_CURRENT_PAGE: {
      const { value } = action;
      return { ...state, currentPage: value };
    }

    default:
      return state;
  }
};