import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import DriveFileMoveOutlinedIcon from '@material-ui/icons/DriveFileMoveOutlined';
import {navigate, useLocation} from "@reach/router";
import { map, pickBy, identity, pick, reduce } from "lodash";
import qs from "querystringify";
import { FormattedMessage } from 'react-intl';

import { CATEGORY_DOCUMENTS } from "./constants";
import { SOURCE_DOCUMENTS_DELETE } from '../graphql/mutations/sourceDocumentsDelete';
import { GET_SOURCE_DOCUMENT_TYPES } from "../graphql/queries/sourceDocumentTypes";

IncomingDocumentsToolbar.propTypes = {
  refetchData: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  total: PropTypes.number,
};

export default function IncomingDocumentsToolbar({
  refetchData,
  selection,
  setSelection,
  total,
}) {
  const styles = useMemo(() => ({
    filterButton: {
      border: "1px solid #1771e6",
      padding: "6px 14px",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#1771e6",
      transition: "all 250ms",

      '&:hover': {
        background: "#1771e6",
        color: "#ffffff",
      }
    },
    button: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      color: '#6E7884',
      background: '#FFFFFF',
      padding: '12px',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      border: '1px solid #FFFFFF',
      height: '48px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        background: '#FFFFFF',
        color: '#3D6CE7',
        boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',

        '& path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        background: '#FFFFFF',
        color: '#365CBF',
        boxShadow: '0px 6px 16px transparent',
        borderColor: '#E7E8EA',
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        background: '#E7E8EA',
        border: '1px solid #E7E8EA',
        color: '#9EA5AD',
      }
    },
    modal: {
      '& .MuiDialog-paper': {
        minWidth: '600px',
        minHeight: '400px',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      },
    },
    wrapperDialogTitle: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      color: '#0D1D32',
    },
    dialogTitle: {
      fontSize: '20px',
      fontWeight: 700,
      marginTop: '40px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '8px',
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',
      maxHeight: '48px',

      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const [removeDocuments, { removingDocuments }] = useMutation(
    SOURCE_DOCUMENTS_DELETE,
  );

  const [fetchSourceDocumentTypes, { data: dataSourceDocumentTypes, loading: loadingSourceDocumentTypes }] = useLazyQuery(GET_SOURCE_DOCUMENT_TYPES);

  const [sourceDocumentTypes, setSourceDocumentTypes] = useState([]);

  useEffect(() => {
    if (dataSourceDocumentTypes?.sourceDocumentTypes?.length) {
      setSourceDocumentTypes(dataSourceDocumentTypes.sourceDocumentTypes)
    }
  }, [dataSourceDocumentTypes]);

  const removeSelected = useCallback(() => {
    removeDocuments({ variables: { selection } }).then(() => {
      setSelection([]);
      refetchData();
    });
  }, [selection, removeDocuments, refetchData, setSelection]);

  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [filters, setFilters] = useState({
    category: "NONE",
    type: "NONE"
  })

  useEffect(() => {
    const { category, type } = pick(parsedQuery, ['category', 'type']);

    if (category) {
      setFilters(prev => {
        return {
          category: category,
          type: type || prev.type
        }
      })

      fetchSourceDocumentTypes({
        variables: {
          filter: {
            categoryEq: category,
            assignableEq: true
          },
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showModal, setShowModal] = useState(false);

  const onToggleModal = () => {
    setShowModal(!showModal)
  }

  const onChangeFields = (type, event) => {
    let value = event?.target?.value;
    const cloneFilter = { ...filters };

    cloneFilter[type] = value || filters[type]

    setFilters(cloneFilter);

    if (type === "category") {
      fetchSourceDocumentTypes({
        variables: {
          filter: {
            categoryEq: value,
            assignableEq: true
          },
        }
      })
    }
  }

  const onResetParamsFilters = () => {
    setFilters({
      category: "NONE",
      type: "NONE"
    });

    const query = pickBy({ ...parsedQuery }, identity);

    if (query?.category) {
      delete query.category
    }

    if (query?.type) {
      delete query.type
    }

    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);

    onToggleModal();
  }

  const onSaveParamsFilters = () => {
    const query = pickBy({ ...parsedQuery, ...filters }, identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);

    onToggleModal();
  }

  const totalActiveFilter = useCallback(() => {
    return reduce(filters,(result,value) => {
      if (value !== "NONE") result++
      return result
    },0);

  }, [filters])

  return (
    <>
      <Toolbar disableGutters sx={{ py: 2 }}>
        <Typography variant="h4">
          {!!selection.length ? (
            <>
              <FormattedMessage
                id="documentsChoice.plural"
                values={{ amount: selection.length }}
              />
              &nbsp;
              <Box component="span" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="documents.plural"
                  values={{ amount: selection.length }}
                />
              </Box>
              <Button
                color="error"
                disableElevation
                disabled={removingDocuments}
                onClick={removeSelected}
                startIcon={<DriveFileMoveOutlinedIcon />}
                sx={{ ml: 3, color: 'white' }}
                variant="contained"
              >
                Переместить в прочее
              </Button>
            </>
          ) : (
            <>
              Всего&nbsp;
              <Box component="span" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="documents.plural"
                  values={{ amount: total }}
                />
              </Box>
              &nbsp;
              <Typography component="span" onClick={onToggleModal} sx={ styles.filterButton }>
                Фильтр ({totalActiveFilter()})
              </Typography>
            </>
          )}
        </Typography>
      </Toolbar>

      {showModal && (
        <Dialog
          fullWidth
          onClose={onToggleModal}
          open={true}
          scroll="body"
          sx={ styles.modal }
        >
          <DialogTitle
            disableTypography
            sx={ styles.wrapperDialogTitle }
          >
            <Typography sx={ styles.dialogTitle } variant="h3">
              Фильтр
            </Typography>

            <IconButton onClick={onToggleModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
              <Typography style={ styles.label }>Категория документа</Typography>

              <Select
                onChange={onChangeFields.bind(this, "category")}
                sx={ styles.select }
                value={filters.category}
              >
                <MenuItem disabled style={ styles.selectItem } value={"NONE"}>Укажите категорию</MenuItem>

                {map(CATEGORY_DOCUMENTS, category => (
                  <MenuItem key={category} style={ styles.selectItem } value={category}>
                    <FormattedMessage id={`incomingdocuments.category.${category}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography style={ styles.label }>Тип документа</Typography>

              <Select
                disabled={loadingSourceDocumentTypes || filters.category === "NONE"}
                onChange={onChangeFields.bind(this, "type")}
                sx={ styles.select }
                value={filters.type}
              >
                <MenuItem disabled style={ styles.selectItem } value={"NONE"}>Укажите тип документа</MenuItem>

                {sourceDocumentTypes.map(({id, name}) => (
                  <MenuItem key={id} style={ styles.selectItem } value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions sx={{ padding: "8px 24px" }}>
            <Button onClick={onResetParamsFilters} sx={{ ...styles.button, background: "red", color: "#fff" }}>
              Сбросить
            </Button>
            <Button onClick={onSaveParamsFilters} sx={{ ...styles.button, background: "blue", color: "#fff" }}>
              Применить
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}