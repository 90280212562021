import { gql } from '@apollo/client/core';

export const GET_SHOP_GOOD = gql`
  query ShopGood($goodId: ID!) {
    shopGood(goodId: $goodId) {
      id
      name
      createdAt
      status
      shippedEquipment {
        id
        name
        url
        code #Артикул
        loanSupply {
          id
          date
        } #отгрузка
        displaySupplyingOrderNumber #номер заказа
        displaySupplyingOrderUrl #url заказа
        displaySupplierLegalName # Поставщик
        displaySupplierLegalNameWithShortEntityType # Поставщик с типом организации
        displaySupplierUrl #url поставщика
        displayEquipmentTypeName #тип оборудования
        dispayLoanSupplyReceivingDate
        marketPrice #Рыночная цена
        vat #ндс
        vatRate
        totalPrice #Сумма
        dispayPurchaseInvoicesUrl #Ссылка на карту объектов лизинга
        dispayLoanSupplyDate
      }
      orders {
        id
        status
      }
      storageAcceptance { # приёмка
        id
        url
        acceptedAt # дата поступления только чтение
        displayAcceptedByLegalName # ответственный за приёмку
        displaySeizureActNumber
        displaySeizureActDate
        displaySeizureActCollectorName # коллектор
        displaySeizureActCollectorContactUrl # ссылка на коллектора
        displaySeizureActUrl # ссылка на акт изъятиия
        displaySeizureActScanUrl
        dispayLoanLeaseAgreement # номер сделки
        dispayLoanLeaseAgreementUrl #url сделки
        displayAcceptedByLegalName # ответственный за приёмку
			  displayAcceptedByContactUrl # ссылка на контакт ответственного за 
        displayDisputeNumber # номер дела
        displayDisputeUrl # ссылка на дело
      }
      storage {
        id
        address
      }
      equipmentType {
        id
        name
      }
      currentLocation # У клиента/на складе
      currentLocationAddress #адрес
      locationAddressForSite #адрес
      storageComment #комментарий на складе
      serialNumber # серийный номер
      producedAt # дата производства
      quality # состояние
      description # описание
      sellingPrice # цена реализации
      price
      vatPercent
      discount
      images {
        id
        file {
          id
          filename
          url
          externalUrl
        }
      }
    }
  }
`;