import { gql } from '@apollo/client';

export const GET_STORAGE_ACCEPTANCE = gql`
  query ShopStorageAcceptance($storageAcceptanceId: ID!) {
    storageAcceptance(storageAcceptanceId: $storageAcceptanceId) {
      id
      status
      acceptedAt
      acceptedSupplies {
        id
        acceptedShippedEquipments {
          id
          acceptedGoodsCount
          shippedEquipment {
            id
            name
            status
            quantity
            shopGoodsCount
            unit
            pricePerUnit
            totalPrice
          }
        }
        supplyingOrder {
          application {
            id
          }
          id
          number
          supplier {
            id
            shortLegalName
          }
        }
      }
      acceptedBy {
        id
        displayName
      }
      seizureAct {
        id
        number
        supplies {
          id
          shippedEquipments {
            id
            name
            status
            quantity
            shopGoodsCount
            unit
            pricePerUnit
            totalPrice
          }
          supplyingOrder {
            application {
              id
            }
            id
            number
            supplier {
              id
              shortLegalName
            }
          }
        }
      }
      shippedEquipmentsCount
      createdAt
      updatedAt
      application {
        id
      }
    }
  }
`;