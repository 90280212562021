import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import fsspLogo from 'assets/fssp.svg';
import { FormattedMessage } from 'react-intl';
import transformUrl from 'transform-url';
import { useTimer } from 'use-timer';

import FormattedCurrency from 'components/FormattedCurrency';
import {
  FSSP_START_CHECK_PROCESS,
} from 'modules/fssp/graphql/mutations/fsspStartCheckProcess';
import {
  GET_FSSP_CHECK_PROCESS,
} from 'modules/fssp/graphql/queries/fsspCheckProcess';

import { LastCheckTimeSubheader } from '../components/LastCheckTimeSubheader';
import {
  GET_CONTACT_CHECK_PROCESSES,
} from '../graphql/queries/contactCheckProcesses';

const FSSP_CONTACT_PATH = `${process.env.REACT_APP_HOST}/contacts/:contactId/fssp`;

PendingProcess.propTypes = {
  id: PropTypes.string,
};

function PendingProcess({ id }) {
  const { reset, start, time } = useTimer({
    initialTime: 30,
    timerType: 'DECREMENTAL',
  });

  const [refreshFsspCheckProcess, { loading }] = useLazyQuery(
    GET_FSSP_CHECK_PROCESS,
    {
      variables: { id },
      fetchPolicy: 'network-only',
      pollInterval: 30000,
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    refreshFsspCheckProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      reset();
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <Typography variant="subtitle2">Запрос в ФССП выполняется...</Typography>
      <Typography color="textSecondary" variant="caption">
        {loading ? 'Проверка статуса запроса' : `Автопроверка статуса через ${time} с.`}
      </Typography>
    </>
  );
}

FsspPassportCheckWidget.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    failedAt: PropTypes.string,
    startedAt: PropTypes.string,
    unpaidSum: PropTypes.number,
    unpaidItemsCount: PropTypes.number,
    state: PropTypes.oneOf(['created', 'started', 'finished', 'failed']),
  }),
};

const STATE_TO_TEMPLATE = {
  created: PendingProcess,
  started: PendingProcess,
  finished: FinishedProcess,
  failed: FailedProcess,
};

FailedProcess.propTypes = {};

function FailedProcess() {
  return (
    <>
      <Box>Последний запрос завершен с ошибкой</Box>
    </>
  );
}

FinishedProcess.propTypes = {
  unpaidItemsCount: PropTypes.number,
  unpaidSum: PropTypes.number,
};

function FinishedProcess({ unpaidItemsCount, unpaidSum }) {
  return (
    <>
      <Box color="rgba(0,0,0,.8)" fontSize={13} lineHeight="20px" mb={2}>
        Задолженность
      </Box>
      <Typography variant="h3">
        <Box color={unpaidSum ? 'rgb(221, 86, 86)' : 'rgb(34, 160, 83)'}>
          <FormattedCurrency value={unpaidSum} />
        </Box>
      </Typography>
      <Box color="rgba(0,0,0,.4)" fontSize={13} lineHeight="20px">
        <FormattedMessage
          id={`fsspCheckStatus.unpaidItemsMessage`}
          values={{ amount: unpaidItemsCount }}
        />
      </Box>
    </>
  );
}

export default function FsspPassportCheckWidget({ contactId, data }) {
  const [startFsspCheckProcess, { loading }] = useMutation(
    FSSP_START_CHECK_PROCESS, {
    variables: { contactId },
    onError: (error) => {
      alert(`Произошла ошибка при запуске проверки ФССП ${error.message}`);
    },
    refetchQueries: [{
      query: GET_CONTACT_CHECK_PROCESSES,
      variables: { contactId },
    }],
  });

  function handleClick() {
    startFsspCheckProcess();
  }

  const subheader = data ? (
    <LastCheckTimeSubheader
      {...{
        createdAt: data.createdAt,
        failedAt: data.failedAt,
        finishedAt: data.finishedAt,
        startedAt: data.startedAt,
      }}
    />
  ) : (
    <Typography color="textSecondary" variant="caption">
      <Box fontWeight="500">Проверок не выполнялось</Box>
    </Typography>
  );

  const Content = data ? (
    STATE_TO_TEMPLATE[data.state]
  ) : (
    <Box display="flex" height={102} m="auto">
      <Button disabled={loading} fullWidth onClick={handleClick}>
        <FormattedMessage id="verification.checkButton" />
      </Button>
    </Box>
  );

  const contentBody = data ? (
    <Box height={64}>
      <Content {...data} />
    </Box>
  ) : (
    <Box display="flex" height={102} m="auto">
      <Button disabled={loading} fullWidth onClick={handleClick}>
        <FormattedMessage id="verification.checkButton" />
      </Button>
    </Box>
  );

  return (
    <Card sx={{ minWidth: 256 }} variant="outlined">
      <CardHeader
        action={
          (data?.state === 'finished' || data?.state === 'failed') && (
            <IconButton
              disabled={loading}
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <RenewIcon />
            </IconButton>
          )
        }
        avatar={<Box alt="Logo" component="img" height={40} src={fsspLogo} />}
        subheader={subheader}
        title={
          <Box fontSize={16} fontWeight={500}>
            <FormattedMessage id={`fsspCheckStatus.title`} />
          </Box>
        }
      />
      <Box mt={-3} />
      <CardContent>{contentBody}</CardContent>
      {data ? (
        <CardActions>
          <Box px="3px">
            <Button
              href={transformUrl(FSSP_CONTACT_PATH, { contactId })}
              size="small"
              target="_parent"
            >
              <FormattedMessage id="more" />
            </Button>
          </Box>
        </CardActions>
      ) : null}
    </Card>
  );
}
