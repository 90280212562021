import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import NumberFormat from "react-number-format";

// eslint-disable-next-line react/display-name
export const AmountField = forwardRef(({ name, onChange, ...rest }, ref) => {
  return (
    <NumberFormat
      {...rest}
      decimalScale={2}
      decimalSeparator=","
      fixedDecimalScale={2}
      getInputRef={ref}
      isNumericString
      onValueChange={({value}) => {
        onChange({target: {name, value}});
      }}
      thousandSeparator=" "
    />
  )
});

AmountField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};