import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

SimpleMenu.propTypes = {
  button: PropTypes.node,
  icon: PropTypes.node,
  menuItems: PropTypes.node.isRequired,
};

export default function SimpleMenu({
  button,
  icon = <MoreVertIcon />,
  menuItems,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {button || (
        <IconButton edge="end" onClick={handleClick}>
          {icon}
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
}
