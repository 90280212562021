import React from 'react';

import { DataTypeProvider } from '@devexpress/dx-react-grid';

import CellActions from '../CellActions';
import CellName from '../CellName';
import CellOriginal from '../CellOriginal';
import CellStatus from '../CellStatus';

export const DocumentActionTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={
      (innerProps) => (<CellActions {...{ ...innerProps, ...props }} />)
    }
    {...props}
  />
);

export const DocumentNameTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={
      (innerProps) => (<CellName {...{ ...innerProps, ...props }} />)
    }
    {...props}
  />
);

export const DocumentOriginalTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CellOriginal} {...props} />
);

export const DocumentStatusTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CellStatus} {...props} />
);