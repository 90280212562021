import PropTypes from "prop-types";
import React from "react";

const styles = {
  container: {
    width: '24px',
    height: '24px',
    position: 'relative',
    display: 'inline-block',
    top: "4px"
  },
  number: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    left: 0,
    top: "2px",
    color: '#FF780F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

const IconWarehouse = ({ number }) => {
  return (
  <div style={{ ...styles.container }}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.25 1.23438C11.8546 1.23438 11.531 1.36643 11.2655 1.51549C11.0332 1.64598 10.7805 1.82852 10.5281 2.01088L3.4678 7.11001C3.28693 7.24059 3.10213 7.37401 2.95217 7.5043C2.78003 7.65385 2.60236 7.8414 2.46929 8.10165C2.33622 8.36189 2.28821 8.61574 2.26775 8.84285C2.24993 9.04072 2.24997 9.26864 2.25001 9.49173L2.25001 20.0552C2.24992 20.4793 2.24984 20.8914 2.29554 21.2314C2.34706 21.6146 2.47259 22.0518 2.8358 22.4151C3.19902 22.7783 3.63629 22.9038 4.01949 22.9553C4.35941 23.001 4.77155 23.0009 5.19562 23.0008H19.3044C19.7285 23.0009 20.1406 23.001 20.4805 22.9553C20.8637 22.9038 21.301 22.7783 21.6642 22.4151C22.0274 22.0518 22.153 21.6146 22.2045 21.2314C22.2502 20.8914 22.2501 20.4793 22.25 20.0552L22.25 9.49175C22.2501 9.26869 22.2501 9.04069 22.2323 8.84285C22.2118 8.61574 22.1638 8.36189 22.0307 8.10165C21.8977 7.8414 21.72 7.65385 21.5479 7.5043C21.3979 7.374 21.2131 7.2406 21.0322 7.11002L13.9719 2.0109C13.7195 1.82854 13.4669 1.64599 13.2345 1.51549C12.969 1.36643 12.6455 1.23438 12.25 1.23438ZM11.6645 3.65723C11.9658 3.43967 12.1244 3.32695 12.2448 3.25935L12.25 3.25644L12.2552 3.25935C12.3756 3.32695 12.5343 3.43967 12.8355 3.65723L19.8355 8.71278C20.053 8.86988 20.1626 8.95016 20.2362 9.01408L20.2399 9.01733L20.2403 9.02226C20.2491 9.11932 20.25 9.25516 20.25 9.52346V20.0008C20.25 20.5005 20.2479 20.7747 20.2223 20.9649L20.2213 20.9721L20.214 20.9731C20.0238 20.9987 19.7497 21.0008 19.25 21.0008H5.25001C4.75034 21.0008 4.47618 20.9987 4.28599 20.9731L4.27871 20.9721L4.27771 20.9649C4.25214 20.7747 4.25001 20.5005 4.25001 20.0008V9.52346C4.25001 9.25516 4.25095 9.11932 4.25969 9.02226L4.26014 9.01733L4.26387 9.01408C4.33744 8.95016 4.44701 8.86988 4.66452 8.71279L11.6645 3.65723Z"
        fill="#FF780F"
        fillRule="evenodd"
      />
    </svg>
    {number && (
      <div style={{ ...styles.number }}>{number}</div>
    )}
  </div>
  );
}

IconWarehouse.propTypes = {
  number: PropTypes.number
};

export default IconWarehouse;