import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { pick } from 'lodash';

import { TASKS_UPDATE } from 'modules/tasks/graphql/mutations/tasksUpdate';

export const TaskContext = createContext({
  refetch: () => { },
  state: {},
  task: {},
  type: undefined,
  updateInProgress: false,
  updateState: () => { },
  updateNotes: () => { },
});

export const TaskProvider = ({ children, refetch, task, type, updateTask, updateTaskNote }) => {
  const state = useMemo(() => {
    if (!task) {
      return {};
    }

    const taskState = pick(task, [
      'title',
      'description',
      'priority',
      'dueAt',
      'certainTime',
      'assignee',
      'attachments',
      'removeAttachmentsIds'
    ]);

    return { ...taskState, dueAt2: taskState.dueAt };
  }, [task]);

  const [update, { loading: updateInProgress }] = useMutation(
    TASKS_UPDATE,
    {
      onCompleted: (res) => {
        if (res.tasksUpdate && refetch instanceof Function) {
          refetch();
        }
      },
      onError: (error) => {
        console.log(
          '🚀 ~ file: TaskContext.jsx ~ line 45 ~ TaskProvider ~ error',
          error,
        );
        alert('Ошибка: Ошибка обновления задачи');
      },
    },
  );

  const updateState = useCallback((taskParams) => {
    const params = { ...taskParams };

    if (params.dueAt !== null && params.dueAt2 !== null && params.certainTime) {
      const taskDate = params.dueAt.substring(0, 10);
      const taskTime = params.dueAt2.substring(10, params.dueAt2.length);
      params.dueAt = `${taskDate}${taskTime}`;
    }

    delete params.dueAt2;

    update({
      variables: {
        taskId: task?.id || '',
        taskParams: params,
      },
    });
  }, [update, task]);

  const updateNotes = useCallback((note) => {
    updateTaskNote(task.id, note);
  }, [task, updateTaskNote])

  const updateTaskDetail = useCallback((detail_task) => {
    updateTask(task.id, detail_task);
  }, [task, updateTask])

  const contextValue = {
    refetch,
    state,
    task,
    type,
    updateInProgress,
    updateState,
    updateNotes,
    updateTaskDetail,
  };

  return (
    <TaskContext.Provider value={contextValue}>
      {children}
    </TaskContext.Provider>
  )
};

TaskProvider.propTypes = {
  children: PropTypes.node,
  refetch: PropTypes.func,
  task: PropTypes.object,
  type: PropTypes.oneOf(['past', 'today', 'tomorrow', 'further']),
  updateTask: PropTypes.func,
  updateTaskNote: PropTypes.func,
};