import React, {useEffect, useMemo, useState} from 'react';

import { useLazyQuery } from "@apollo/client";
import {
  CustomPaging,
  PagingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Typography,
} from "@material-ui/core";
import { cloneDeep } from "lodash";

import HelpTooltip from 'components/HelpTooltip';

import {
  COLUMN_EXTENSIONS_STRUCTURE_SHOP_ORDERS,
  COLUMNS_MODEL_CREATOR_SHOP_ORDERS,
  DEALS_PAGE_LIMIT,
  PAGING_PANEL_MESSAGES
} from "./constants";
import { GET_SHOP_ORDERS } from "./graphql/queries/getShopOrders";
import ModalCreateOrders from "./modals/ModalCreateOrders";
import ErrorAlert from "../../components/ErrorAlert";
import WrapperButton from "../../components/WrapperButton";
import { GridRoot } from "../deals/components/helpers";

export default function Orders() {
  const styles = useMemo(() => ({
    pageTitle: {
      fontSize: '32px !important',
      margin: '0 !important',
      fontWeight: 700,
    },
    wrapperContent: {
      margin: '24px',
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      // height: 'calc(100% - 120px)',
      overflow: 'hidden',
    },
    wrapperTable: {
      margin: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '60px',

      '& > div > div:nth-child(1)': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '698px',
        borderRadius: '16px',
      }
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = (
    MyPager
  );

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchOrders, {
    data: orders,
    error: errorOrders,
    loading: loadingOrders,
    refetch: refetchOrders
  }] = useLazyQuery(GET_SHOP_ORDERS);
  const [query, setQuery] = useState({
    page: 1,
    limit: DEALS_PAGE_LIMIT,
    sort: {
      field: 'createdAt',
      order: 'DESC',
    },
  });

  useEffect(() => {
    if (!orders) {
      fetchOrders({ variables: query });
    }

    if (orders && orders?.shopOrders?.collection) {
      setRows(orders?.shopOrders?.collection)
    }

    if (orders && orders?.shopOrders?.metadata?.totalCount) {
      setTotalCount(orders?.shopOrders?.metadata?.totalCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    fetchOrders({ variables: query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    fetchOrders({ variables: query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const handleCurrentPageChange = page => {
    const newQuery = cloneDeep(query);
    newQuery.page = page + 1;
    setQuery(newQuery);
  };

  const [showModalCreateOrder, setShowModalCreateOrder] = useState(false)

  const handleModalCreateOrder = () => {
    setShowModalCreateOrder(!showModalCreateOrder)
  }

  if (errorOrders) {
    return <ErrorAlert message={errorOrders.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <>
      <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center', padding: '0 24px', backgroundColor: '#ffffff', height: '100px', boxShadow: '0 9px 13px rgb(13 29 50 / 12%)',}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
              Arenza.Renew
            </Typography>
            <HelpTooltip title={(<a href={`${process.env.REACT_APP_HOST}/wiki_page/242`} rel="noreferrer" style={{color: '#fff', fontSize: '14px'}} target='_blank'>Перейти к описанию</a>)} />
          </Box>
          <WrapperButton
            colorScheme={"breeze"}
            onClick={handleModalCreateOrder}
          >
            Создать заказ
          </WrapperButton>
        </Box>

        <Box sx={ styles.wrapperContent }>
          <Box sx={styles.wrapperTable}>
            <Grid
              columns={COLUMNS_MODEL_CREATOR_SHOP_ORDERS}
              getRowId={(row) => row.id}
              rootComponent={GridRoot}
              rows={rows}
            >
              <PagingState
                currentPage={query.page - 1}
                defaultCurrentPage={query.page - 1}
                defaultPageSize={query.limit}
                onCurrentPageChange={handleCurrentPageChange}
              />

              <CustomPaging totalCount={totalCount} />

              <VirtualTable
                cellComponent={(props) => (
                  <VirtualTable.Cell className="OrdersTableCell" {...props} />
                )}
                columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SHOP_ORDERS}
                messages={{ noData: loadingOrders ? 'Загрузка...' : 'Нет данных' }}
              />
              <TableHeaderRow />

              <PagingPanel containerComponent={MyPagerComponent} messages={PAGING_PANEL_MESSAGES} pageSizes={[ 10, 50, 0 ]} />
            </Grid>
          </Box>
        </Box>
      </Box>

      {showModalCreateOrder && <ModalCreateOrders onCloseModal={handleModalCreateOrder} refetchOrders={refetchOrders} />}
    </>
  );
}