import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_ASSIGN_TO_SUPPLYING_ORDER = gql`
  mutation SourceDocumentsAssignToSupplyingOrder($assignParams: SourceDocumentSupplyingOrderAssignParams!) {
    sourceDocumentsAssignToSupplyingOrder(assignParams: $assignParams) {
      sourceDocument {
        id
      }
    }
  }
`;