import React from 'react';

import { Box } from '@material-ui/core';

export const NoDataMessage = () => (
  <Box
    className="noData"
    component="span"
    sx={{ color: '#91919c', fontWeight: 400 }}
  >
    Нет данных
  </Box>
);