import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DateTime } from 'luxon';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import DropzoneDocuments from './DropzoneDocuments';
import LayoutFileRow from './LayoutFileRow';

DocumentsGroup.propTypes = {
  onFilesDropped: PropTypes.func,
};

export default function DocumentsGroup({ onFilesDropped }) {
  const [files, setFiles] = useState([]);
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(
          (file) => Object.assign(file, {
            id: uuid(),
            createdAt: DateTime.now().setZone('utc'),
            explanation: '',
            isOriginalReceived: false,
          }),
        ),
      );
    },
  });

  const handleSetExplanation = useCallback((id, value) => {
    setFiles((currentFiles) => {
      const filesToUpload = [...(currentFiles || [])];
      filesToUpload.forEach((file) => {
        if (file.id === id) {
          Object.assign(file, { explanation: value });
        }
      });
      return [...filesToUpload];
    });
  }, []);

  const handleChangeOriginal = useCallback((id, value) => {
    setFiles((currentFiles) => {
      const filesToUpload = [...(currentFiles || [])];
      filesToUpload.forEach((file) => {
        if (file.id === id) {
          Object.assign(file, { isOriginalReceived: value });
        }
      });
      return [...filesToUpload];
    });
  }, []);

  const handleDeleteFile = useCallback((fileId) => {
    setFiles(
      (currentFiles) => currentFiles.filter((file) => file.id !== fileId),
    );
  }, []);

  useEffect(() => {
    if (onFilesDropped instanceof Function) {
      onFilesDropped(
        [...files].map(
          (file) => ({
            file,
            explanation: file.explanation,
            isOriginalReceived: file.isOriginalReceived,
          }),
        ),
      );
    }
  }, [files, onFilesDropped]);

  return (
    <Card elevation={5} sx={{ boxShadow: 'none' }}>
      {!!files.length && (
        <React.Fragment>
          <CardContent>
            <TableContainer>
              <Table padding="none" size="small" sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 325 }}>Файл</TableCell>
                    <TableCell>Оригинал</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file) => (
                    <LayoutFileRow
                      file={file}
                      icon={<InsertDriveFileIcon color="action" fontSize="small" />}
                      key={file.id}
                      onCommentAdd={handleSetExplanation}
                      onFileDelete={handleDeleteFile}
                      onOriginalChange={handleChangeOriginal}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <Divider />
        </React.Fragment>
      )}
      <DropzoneDocuments {...{ getInputProps, getRootProps, isDragActive }} />
    </Card>
  );
}