import PropTypes from 'prop-types';
import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client';
import { split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import * as ActionCable from '@rails/actioncable';
import { navigate } from "@reach/router";
import { RestLink } from 'apollo-link-rest';
import { createUploadLink } from 'apollo-upload-client';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import qs from 'querystringify';

import inIframe from 'utils/inIframe';
import { localStore } from 'utils/storageFactory';

import cache from './cache';
import { AUTH_TOKEN_STORAGE_KEY } from './constants/storageKeys';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ locations, message, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const { auth_token, ...query } = qs.parse(window.location.search);

  if (auth_token) {
    localStore.setItem(AUTH_TOKEN_STORAGE_KEY, auth_token);
    if (!inIframe()) {
      const search = qs.stringify(query);
      window.history.replaceState(
        null,
        null,
        window.location.pathname + search ? `?${search}` : '',
      );
    }
  } else if (!localStore.getItem(AUTH_TOKEN_STORAGE_KEY)) {
    return navigate(`${process.env.REACT_APP_API_HOST}/auth/sign_in`);
  }

  return {
    headers: {
      AuthType: 'Basic',
      Credentials: 'arenza:dGuM8KFtf9nn',
      'X-AUTH-TOKEN': localStore.getItem(AUTH_TOKEN_STORAGE_KEY),
      ...headers,
    },
  };
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
  // Локальный адрес Вадима
  // uri: `http://192.168.16.99:3000/graphql`,
});

const cable = ActionCable.createConsumer(
  `${process.env.REACT_APP_API_HOST}/cable`,
);
// Локальный адрес Вадима
// const cable = ActionCable.createConsumer(`http://192.168.16.99:3000/cable`);

const hasSubscriptionOperation = ({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === 'OperationDefinition' && operation === 'subscription';
};

const wsLink = new ActionCableLink({ cable });

const splitLink = split(hasSubscriptionOperation, wsLink, uploadLink);

const authFileFetch = (url, options) => new Promise((resolve, reject) => {
  fetch(url, {
    ...options,
    headers: {
      'X-AUTH-TOKEN': localStore.getItem(AUTH_TOKEN_STORAGE_KEY),
    },
  })
    .then((response) => {
      response.arrayBuffer()
        .then((text) => {
          const codes = new Uint8Array(text);
          const bin = codes.reduce(
            (acc, i) => acc += String.fromCharCode.apply(null, [i]),
            '',
          );

          const blob = new Blob([
            JSON.stringify({
              fileContent: response.headers.get('Content-Type'),
              data: btoa(bin),
            }),
          ]);

          resolve(
            new Response(blob, {
              status: response.status,
              headers: response.headers,
            }),
          );
        }).catch(reject)
    })
    .catch(reject);
});

// DOCS: https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#complete-options
const restLink = new RestLink({
  uri: process.env.REACT_APP_API_HOST,
  customFetch: authFileFetch,
})

const link = ApolloLink.from([errorLink, authLink, restLink, splitLink]);

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
  responseTransformer: async (response) => response,
});

export default function ApolloWrapper({ children }) {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

ApolloWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};