import PropTypes from 'prop-types';
import React from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import _noop from 'lodash.noop';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';

import { GET_FSSP_CHECK_PROCESS } from './graphql/queries/fsspCheckProcess';

export const useStyles = makeStyles((theme) => ({
  // FIXME: Брать цвета из темы
  root: {
    '&$selected': {
      backgroundColor: '#ffeede',
      '&:hover': {
        backgroundColor: '#ffeede',
      },
    },
    '&:hover': {
      backgroundColor: 'hsl(29 100% 94% / 0.5)',
    },
  },
  selected: {},
}));

FsspCheckProcessListItem.propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    failedAt: PropTypes.string,
    id: PropTypes.string,
    itemsCount: PropTypes.number,
    state: PropTypes.oneOf(['created', 'started', 'finished']),
    unpaidItemsCount: PropTypes.number,
    unpaidSum: PropTypes.number,
  }),
  index: PropTypes.number,
  onSelect: PropTypes.func,
  selectedItemId: PropTypes.string,
};

export function FsspCheckProcessListItem({ data, index, onSelect = _noop, selectedItemId }) {
  const classes = useStyles();

  const {
    createdAt,
    failedAt,
    finishedAt,
    id,
    itemsCount,
    state,
    unpaidItemsCount,
    unpaidSum,
  } = data;
  const finishedAtDate = DateTime.fromISO(finishedAt || failedAt).toFormat('d MMMM yyyy');
  const createdAtDate = DateTime.fromISO(createdAt).toFormat('d MMMM yyyy');
  const selected = selectedItemId === id;

  const [refreshFsspCheckProcess, { loading }] = useLazyQuery(GET_FSSP_CHECK_PROCESS, {
    variables: { id },
    fetchPolicy: 'network-only',
    pollInterval: 30000,
  });

  function handleClick() {
    onSelect(id);
  }

  if (state === 'created' || state === 'started') {
    // FIXME: to locales
    const message = {
      created: 'Запрос в очереди',
      started: 'Запрос выполняется...',
    };
    return (
      <Box display="flex" justifyContent="space-between" onClick={handleClick} p={3}>
        <Box>
          <Typography component="div" gutterBottom variant="h5">
            {message[state]}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            Создан: {createdAtDate}
          </Typography>
        </Box>
        <Tooltip title="Обновить статус запроса">
          <IconButton
            disabled={loading}
            onClick={(e) => {
              refreshFsspCheckProcess();
              e.stopPropagation();
            }}
          >
            <RenewIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  return (
    <React.Fragment key={id}>
      {index ? <Divider /> : null}
      <ListItem
        button
        classes={{ root: classes.root, selected: classes.selected }}
        disableRipple
        onClick={handleClick}
        selected={selected}
      >
        <ListItemText
          disableTypography
          primary={
            <Box display="flex" justifyContent="space-between">
              <Typography component="div" gutterBottom variant="h5">
                {finishedAtDate}
              </Typography>
              <Typography
                align="right"
                color={unpaidSum ? 'error' : 'textPrimary'}
                component="div"
                gutterBottom
                variant="h5"
              >
                {unpaidSum ? <FormattedCurrency value={unpaidSum} /> : '---'}
              </Typography>
            </Box>
          }
          secondary={
            <Box display="flex" justifyContent="space-between">
              {finishedAt && (
                <Typography color="textSecondary" variant="subtitle2">
                  <FormattedMessage id="record.plural" values={{ amount: itemsCount }} />
                </Typography>
              )}
              {failedAt && (
                <Typography color="textSecondary" variant="subtitle2">
                  Запрос завершен с ошибкой
                </Typography>
              )}

              {itemsCount !== 0 && (
                <Typography align="right" color="textSecondary" variant="subtitle2">
                  <FormattedMessage id="record.plural" values={{ amount: unpaidItemsCount }} />
                </Typography>
              )}
            </Box>
          }
        />
      </ListItem>
    </React.Fragment>
  );
}
