import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from "@apollo/client";
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  SvgIcon,
  CircularProgress,
  Link
} from '@material-ui/core';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { GET_WAZZUP_URL } from "./query/user";

WazzupLink.propTypes = {
  contactId: PropTypes.string,
  phone: PropTypes.string,
  sx: PropTypes.object,
};

export default function WazzupLink({ contactId, phone, sx }) {
  const styles = useMemo(() => ({
    modal: {
      '& .MuiDialog-paper': {
        minWidth: '1193px',
        minHeight: '600px',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      },
      '& .MuiDialogContent-root': {
        '& iframe': {
          minHeight: '600px',
          border: "1px solid #E0E0E0",
        }
      },
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    title: {
      fontSize: '20px',
      fontWeight: 700,
      width: '100%',
      marginTop: '40px',
    },
    iconW: {
      cursor: 'pointer',
      fontSize: '16px !important',
      color: '#67BF3D',
      marginLeft: '6px',
      ...sx,
    }
  }), [sx]);

  const [fetch, { data, error, loading }] = useLazyQuery(GET_WAZZUP_URL, {
    variables: {
      contactId,
    },
    fetchPolicy: 'network-only',
    pollInterval: 30000,
  });

  const [showModal, setShowModal] = useState(false);

  const onToggleModal = () => {
    setShowModal(!showModal);

    if (!showModal) {
      setUrlIframe(null);
    }
  }

  const onSendQuery = () => {
    fetch();

    onToggleModal();
  };

  const [urlIframe, setUrlIframe] = useState(null);

  useEffect(() => {
    if (data?.contact?.wazzupUrl) {
      if (!!data?.contact?.wazzupUrl?.match('wazzup24')) {
        setUrlIframe(data?.contact?.wazzupUrl);
      } else {
        onToggleModal();
        window.open(data?.contact?.wazzupUrl, '_blank');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!contactId && !phone) {
    return false;
  }

  if (!contactId) {
    return (
      <Link
        href={`https://web.whatsapp.com/send?phone=+${phone.replace(/[^\d;]/g, '')}`}
        sx={{
          height: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        target="_blank">
        <WhatsAppIcon alt={'Открыть WhatsApp'} sx={ styles.iconW } />
      </Link>
    )
  }

  return (
    <>
      <WhatsAppIcon alt={'Открыть Wazzup'} onClick={onSendQuery} sx={ styles.iconW } />

      {showModal && (
        <Dialog
          fullWidth
          onClose={onToggleModal}
          open={true}
          scroll="body"
          sx={ styles.modal }
        >
          <DialogTitle sx={ styles.dialogTitle }>
            <IconButton aria-label="close" onClick={onToggleModal}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {loading && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                  <CircularProgress />
                </Box>

                <Typography align={'center'} sx={ styles.title }>Загрузка окна Wazzup</Typography>
              </Box>
            )}

            {error && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Typography align={'center'} sx={ styles.title }>При загрузке окна Wazzup произошла ошибка</Typography>

                <Typography align={'center'} sx={{ width: '100%', marginTop: '20px' }}>{error?.message}</Typography>
              </Box>
            )}

            {urlIframe && (
              // eslint-disable-next-line jsx-a11y/iframe-has-title
              <iframe height="100%" src={urlIframe} width="100%"></iframe>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
