import PropTypes from 'prop-types';
import React, {useCallback, useContext, useState} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEqual } from 'lodash';

import AutocompleteTaskUsers from 'components/AutocompleteTaskUsers';

import { FilterModalBox } from './helpers';
import { TasksFilterContext } from '../TasksFilterContext';

TasksFilterModal.propTypes = {
  onClose: PropTypes.func,
};

export default function TasksFilterModal({ onClose }) {
  const { state, updateState } = useContext(TasksFilterContext);

  const [persistState, setPersistState] = useState(state);
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const updateFilterState = useCallback((fieldName, value) => {
    setPersistState((prev) => ({ ...prev, [fieldName]: value }));
  }, []);

  const applyFilter = useCallback(() => {
    updateState(persistState);
    handleClose();
  }, [persistState, updateState, handleClose]);

  const resetFilter = useCallback(() => {
    setPersistState({});
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      scroll="body"
      sx={{ '& .MuiDialog-paper': { maxWidth: 480 } }}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Фильтр задач</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FilterModalBox title="Исполнитель">
          <AutocompleteTaskUsers
            onChange={(value) => updateFilterState('assigneeId', value)}
            value={persistState.assigneeId || ''}
          />
        </FilterModalBox>

        <FilterModalBox title="Автор">
          <AutocompleteTaskUsers
            onChange={(value) => updateFilterState('creator', value)}
            value={persistState.creator || ''}
          />
        </FilterModalBox>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!Object.keys(persistState).length}
          onClick={resetFilter}
        >
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state)}
          onClick={applyFilter}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
}