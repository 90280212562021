import { gql } from '@apollo/client';

export const CREDIT_HISTORY_START_FETCH_PROCESS = gql`
  mutation CreditHistoryStartFetchProcess($contactId: ID!) {
    creditHistoryStartFetchProcess(contactId: $contactId) {
      creditHistoryFetchProcess {
        id
        createdAt
        startedAt
        failedAt
        finishedAt
        state
      }
    }
  }
`;