import { gql } from '@apollo/client';

export const GET_BANK_STATEMENT_COMMENTS = gql`
  query GetBankStatementComments($bankStatementId: ID!) {
    bankStatement(bankStatementId: $bankStatementId) {
      id
      comments {
        author {
          id
          login
          contact {
            id
            lastName
            firstName
          }
        }
        createdAt
        text
        updatedAt
        id
      }
    }
  }
`;