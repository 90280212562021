import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import CreditHistoryWidget from './CreditHistoryWidget';
import CriminalCheckWidget from './CriminalCheckWidget';
import FmsPassportCheckWidget from './FmsPassportCheckWidget';
import FsspPassportCheckWidget from './FsspPassportCheckWidget';
import {
  GET_CONTACT_CHECK_PROCESSES,
} from '../graphql/queries/contactCheckProcesses';

PageContactItem.propTypes = {
  contactId: PropTypes.string,
};

export default function PageContactItem({ contactId }) {
  const { data, loading } = useQuery(GET_CONTACT_CHECK_PROCESSES, {
    variables: { contactId },
  });

  if (loading) {
    return 'Загрузка...';
  }

  const fmsPassportCheck = data?.contact.fmsPassportChecks[0];
  const fsspCheckProcess = data?.contact.fsspCheckProcesses[0];
  const creditHistoryFetchProcess = data?.contact.creditHistoryFetchProcesses[0];
  const criminalCheckProcess = data?.contact.criminalActivityCheckProcesses[0];

  return (
    <Box sx={{ display: 'flex', overflowX: 'scroll', width: 'max-content' }}>
      <Box px={2} py={3}>
        <FmsPassportCheckWidget
          contactId={contactId}
          data={fmsPassportCheck}
        />
      </Box>
      <Box px={2} py={3}>
        <FsspPassportCheckWidget
          contactId={contactId}
          data={fsspCheckProcess}
        />
      </Box>
      <Box px={2} py={3}>
        <CriminalCheckWidget
          contactId={contactId}
          data={criminalCheckProcess}
        />
      </Box>
      <Box px={2} py={3}>
        <CreditHistoryWidget
          contactId={contactId}
          data={creditHistoryFetchProcess}
        />
      </Box>
    </Box>
  );
}
