import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import OriginalDocuments from './OriginalDocuments';

UploadOriginalModal.propTypes = {
  documentId: PropTypes.string,
  handleUploadOriginalModalClose: PropTypes.func,
}

export default function UploadOriginalModal({
  documentId,
  handleUploadOriginalModalClose,
}) {
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    handleUploadOriginalModalClose();
    setOpen(false);
  }, [handleUploadOriginalModalClose]);

  return (
    <Dialog fullWidth onClose={handleClose} open={open} scroll="body">
      <DialogTitle>
        <Typography
          component="h2"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          variant="h2"
        >
          Загрузка оригинала
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 3, minHeight: 150 }}>
        <OriginalDocuments {...{ documentId }} onUpload={handleClose} />
      </DialogContent>
    </Dialog>
  );
}