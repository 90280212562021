import PropTypes from 'prop-types';
import React, { forwardRef, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { MenuItem } from '@material-ui/core';

import { ScheduleContext } from './ScheduleContext';
import {
  DELETE_DRAFT_SCHEDULE,
} from '../graphql/mutations/financeDeleteDraftSchedule';

const propTypes = {
  scheduleId: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/display-name
const DeleteScheduleMenuItem = forwardRef(({ scheduleId }, ref) => {
  const {
    dispatch,
    state: { loanId },
  } = useContext(ScheduleContext);

  const [deleteDraft] = useMutation(DELETE_DRAFT_SCHEDULE, {
    variables: { scheduleId },
    onCompleted: () => {
      dispatch({ type: 'DELETE_ITEM', payload: { id: scheduleId } });
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify({ __typename: 'Loan', id: loanId }),
        fields: {
          schedules(existingScheduleRefs = [], { readField }) {
            return existingScheduleRefs.filter(
              (scheduleRef) => scheduleId !== readField('id', scheduleRef),
            );
          },
        },
      });
    },
  });

  function handleDeleteScheduleClick() {
    deleteDraft();
  }
  // FIXME: to locales
  return <MenuItem onClick={handleDeleteScheduleClick}>Удалить проект</MenuItem>;
});

export default DeleteScheduleMenuItem;

DeleteScheduleMenuItem.propTypes = propTypes;
