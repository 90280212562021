import PropTypes from "prop-types";
import React from "react";

import {FormattedMessage} from "react-intl";

const QualityValue = ({ quality }) => {
  if(quality && ['GOOD', 'PERFECT', 'NEWEST', 'SATISFACTORY', 'NONE'].includes(quality)) {
    return <FormattedMessage id={`shop.status.${quality}`} />;
  }

  return <FormattedMessage id={'shop.status.WRONG'} />;
}

QualityValue.propTypes = {
  quality: PropTypes.string
};

export default QualityValue;