import PropTypes from 'prop-types';
import React from 'react';

import { ClickAwayListener, Tooltip as MuiTooltip } from '@material-ui/core';

Tooltip.propTypes = {
  children: PropTypes.element,
  title: PropTypes.node,
};

export default function Tooltip({ children, title }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip onClose={handleTooltipClose} onOpen={handleTooltipOpen} open={open} title={title}>
        {children}
      </MuiTooltip>
    </ClickAwayListener>
  );
}
