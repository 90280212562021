/* ISC License (ISC). Copyright 2017 Michal Zalecki
 * https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea
 */

function storageFactory(storage) {
  let inMemoryStorage = {};
  const length = 0;

  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      console.error('localStorage is not supported');
      return false;
    }
  }

  function clear() {
    if (isSupported()) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name) {
    if (isSupported()) {
      return storage.getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index) {
    if (isSupported()) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name) {
    if (isSupported()) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name, value) {
    if (isSupported()) {
      storage.setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length,
  };
}

function supportsLocalStorage() {
  return typeof Storage !== 'undefined';
}

export const localStore = storageFactory(supportsLocalStorage() ? localStorage : undefined);
export const sessionStore = storageFactory(supportsLocalStorage() ? sessionStorage : undefined);
