import PropTypes from 'prop-types';
import React from 'react';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FormattedDate } from 'react-intl';

const DateFormatter = ({ value }) => value
  ? (
    <FormattedDate
      day="numeric"
      hour="numeric"
      minute="numeric"
      month="numeric"
      value={value}
      year="numeric"
    />
  )
  : '—';
DateFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

export const TaskDateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);