import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileDownloadOutlinedIcon from '@material-ui/icons/FileDownloadOutlined';
import { FormattedMessage } from 'react-intl';

import FinmonitoringDocument from './FinmonitoringDocument';
import FinmonitoringOperationParticipant from './FinmonitoringOperationParticipant';
import { FinmonitoringContext } from '../../FinmonitoringContext';
import { useProblems } from '../useProblems';

DealDetailsModal.propTypes = {
  dealDetailsObject: PropTypes.object,
  onGenerateXML: PropTypes.func,
  onModalClose: PropTypes.func,
}

export default function DealDetailsModal({
  dealDetailsObject,
  onGenerateXML,
  onModalClose,
}) {
  const { setProblems, state } = useContext(FinmonitoringContext);
  const problems = useProblems(state.problems);

  const operationParticipants = useMemo(
    () => dealDetailsObject?.operationParticipants || [],
    [dealDetailsObject],
  );

  const documents = useMemo(
    () => dealDetailsObject?.documents || [],
    [dealDetailsObject],
  );

  const handleGenerateXML = useCallback(() => {
    onGenerateXML(dealDetailsObject.id);
  }, [onGenerateXML, dealDetailsObject.id]);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    onModalClose();
    setOpen(false);
    setProblems([]);
  }, [onModalClose, setProblems]);

  useEffect(() => {
    if (dealDetailsObject) {
      handleOpen();
    }
  }, [dealDetailsObject, handleOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={{
        '& .MuiPaper-root:not(.MuiAlert-root)': {
          backgroundColor: '#fffefd',
          border: '8px solid #f5f5f5',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h3">
            Детали сообщения #{dealDetailsObject.id}
          </Typography>
          <Tooltip title="Категория сообщения">
            <Chip
              label={
                <FormattedMessage
                  id={`finmonitoring.messagesStatus.${dealDetailsObject.state}`}
                />
              }
              size="small"
              sx={{ fontWeight: 500, borderRadius: '4px', px: 1 }}
            />
          </Tooltip>
          <Button
            disableElevation
            onClick={handleGenerateXML}
            size="small"
            startIcon={<FileDownloadOutlinedIcon fontSize="small" />}
            sx={{ color: 'white' }}
            variant="contained"
          >
            Скачать XML
          </Button>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ minHeight: 150 }}>
        {!!problems.general.length ? (
          <Alert severity="error" sx={{ mb: 4 }}>
            <AlertTitle>
              {problems.general.length > 1
                ? <Box>Возникли ошибки</Box>
                : <Box>Возникла ошибка</Box>}
            </AlertTitle>
            {problems.general.map((problem, idx) => {
              return (
                <Box key={idx}>
                  {problem.messages.join('<br />')}
                </Box>
              );
            })}
          </Alert>
        ) : null}

        <Stack spacing={5}>
          <Box>
            <Typography mb={3} variant="h4">
              Документы
            </Typography>
            <Stack spacing={3}>
              <Card>
                <CardContent sx={{ px: 2, py: 3 }}>
                  <TableContainer
                    sx={{
                      position: 'relative',
                      zIndex: 1,
                      overflow: 'auto',
                      '&::-webkit-scrollbar': { width: 10, height: 10 },
                      '&::-webkit-scrollbar-track': { background: 'none' },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#e0e0e0',
                        borderRadius: '4px',
                        '&:hover': { background: '#d0d0d0' },
                      },
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 140 }}>
                            № документа
                          </TableCell>
                          <TableCell sx={{ minWidth: 160 }}>
                            Дата подписания
                          </TableCell>
                          <TableCell>
                            Сумма, указанная в документе
                          </TableCell>
                          <TableCell sx={{ minWidth: 200 }}>
                            Тип документа
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!documents.length ? (
                          <React.Fragment>
                            {documents.map((document, idx) => (
                              <FinmonitoringDocument
                                {...{ document }}
                                documentProblems={
                                  problems.special.documents.filter(
                                    (problem) => problem.index === idx,
                                  )
                                }
                                key={idx}
                              />
                            ))}
                          </React.Fragment>
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan="4" sx={{ border: 0 }}>
                              <Box sx={{ p: 4 }}>Нет данных</Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Stack>
          </Box>
          <Box>
            <Typography mb={3} variant="h4">
              Участники операции
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={4} justifyContent="center">
              {operationParticipants.map((participant, idx) => (
                <FinmonitoringOperationParticipant
                  {...{ participant }}
                  key={idx}
                  participantProblems={
                    problems.special.operationParticipants.filter(
                      (problem) => problem.index === idx,
                    )
                  }
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}