/* eslint-disable react/display-name, react/prop-types */
import React  from 'react';

import { Link, Typography, Box } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import FormattedCurrency from "../../components/FormattedCurrency";
import Phone from "../../components/Phone";
import WazzupLink from "../../components/WazzupLink";
import WrapperButton from "../../components/WrapperButton";
import { DATE_TIME_FORMAT_VIEW } from "../../core/constants/Formats";
export const DEALS_PAGE_LIMIT = 20;

/* start table */
export const COLUMN_EXTENSIONS_STRUCTURE_SHOP_ORDERS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'left',
    width: 110,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'client',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'manager',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'set',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'price',
    wordWrapEnabled: true,
    align: 'center',
    width: 150,
  },
  {
    columnName: 'data',
    wordWrapEnabled: true,
    align: 'right',
    width: 150,
  },
  {
    columnName: 'action',
    wordWrapEnabled: true,
    align: 'right',
    width: 180,
  },
];

export const COLUMNS_MODEL_CREATOR_SHOP_ORDERS = [
  {
    name: 'id',
    title: '№ Заявки',
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Box>
          {status ? (
            <FormattedMessage id={`shop.order.status.${status}`} />
          ) : (
            '--'
          )}
        </Box>
      )
    },
  },
  {
    name: 'client',
    title: 'Клиент',
    getCellValue: ({ contact }) => {
      return (
        <Box>
          <Typography>{contact?.firstName} {contact?.middleName} {contact?.lastName}</Typography>
          <br />
          <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
            <Phone phone={contact?.phone} />

            <WazzupLink contactId={contact?.id}/>
          </Box>
        </Box>
      )
    },
  },
  {
    name: 'manager',
    title: 'Менеджер Арензы',
    getCellValue: ({ manager }) => {
      return (
        <Link href={`${process.env.REACT_APP_API_HOST}/contacts/${manager?.contact?.id}`}>
          <Typography>{manager?.displayName}</Typography>
        </Link>
      )
    },
  },
  {
    name: 'price',
    title: 'Стоимость',
    getCellValue: ({ amountWithDiscount }) => {
      return (
        <Box>
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}> <FormattedCurrency value={amountWithDiscount} /></Typography>
        </Box>
      )
    },
  },
  {
    name: 'data',
    title: 'Дата обращения',
    getCellValue: ({ createdAt }) => {
      return (
        <Box>
          {createdAt ? (
            dayjs(createdAt).format(DATE_TIME_FORMAT_VIEW)
          ) : (
            "--"
          )}
        </Box>
      )
    },
  },
  {
    name: 'action',
    title: ' ',
    getCellValue: ({ id }) => {
      return (
        <WrapperButton
          colorScheme={"breeze"}
          component={RouterLink}
          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          to={`/workspaces/shop/orders/details/${id}`}
        >

          Открыть
        </WrapperButton>
      )
    },
  },
];

export const COLUMN_EXTENSIONS_STRUCTURE_BILLS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'left',
    width: 80,
  },
  {
    columnName: 'status_number',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'createdAt',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'datePayment',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'comment',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'amount',
    wordWrapEnabled: true,
    align: 'right',
    width: 140,
  },
  {
    columnName: 'cashlessPayments',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'reject',
    align: 'center',
    width: 140,
  },
];
export const COLUMNS_MODEL_CREATOR_BILLS = [
  {
    name: 'id',
    title: 'ID'
  },
  {
    name: 'status_number',
    title: (
      <>
        Номер счета
        <br />
        Статус
      </>
    ),
  },
  {
    name: 'createdAt',
    title: 'Дата создания',
    getCellValue: ({ createdAt }) => {
      return (
        <Typography>
          {dayjs(createdAt).format(DATE_TIME_FORMAT_VIEW)}
        </Typography>
      )
    },
  },
  {
    name: 'datePayment',
    title: (
      <>
        Срок оплаты /
        <br />
        Дата оплаты
      </>
    ),
    getCellValue: ({ expirationDate }) => {
      return (
        <Typography>
          {expirationDate ? (
            dayjs(expirationDate).format(DATE_TIME_FORMAT_VIEW)
          ) : (
            "---"
          )}
        </Typography>
      )
    },
  },
  {
    name: 'comment',
    title: 'Комментарий',
    getCellValue: ({ comment }) => {
      return (
        <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#0D1D32' }}>
          {comment?.body}
        </Typography>
      )
    },
  },
  {
    name: 'amount',
    title: 'Сумма',
    getCellValue: ({ amount, vatRate }) => {
      return (
        <>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#0D1D32' }}>
            <FormattedCurrency value={amount} />
          </Typography>

          <Typography sx={{ fontSize: '12px', fontWeight: 300, color: '#6E7884' }}>
            НДС <FormattedCurrency value={amount - amount / (1 + vatRate)} />
          </Typography>
        </>
      )
    },
  },
  {
    name: 'cashlessPayments',
    title: 'Безналичный расчет',
    getCellValue: ({ cashless }) => {
      return (
        <Typography>
          {cashless ? 'Нет' : 'Да'}
        </Typography>
      )
    },
  },
  {
    name: 'reject',
    title: ' '
  },
];

export const COLUMN_EXTENSIONS_STRUCTURE_SKU_BILLING = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 52,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 160,
  },
  {
    columnName: 'stock',
    align: 'center',
    width: 90,
  },
  {
    columnName: 'name',
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'priceWithDiscount',
    wordWrapEnabled: true,
    align: 'right',
    width: 130,
  },
];
export const COLUMNS_MODEL_CREATOR_SKU_BILLING = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Box sx={{ color: status === "SHIPPED"? "#67BF3D": null }}>
          <FormattedMessage id={'goods.status.' + status} />
        </Box>
      )
    },
  },
  {
    name: 'name',
    title: 'Название товара',
  },
  {
    name: 'priceWithDiscount',
    title: 'Сумма, ¤',
    getCellValue: ({ priceWithDiscount }) => {
      return (
        <Box>
          <FormattedCurrency value={priceWithDiscount} />
        </Box>
      )
    },
  },
];

export const COLUMN_EXTENSIONS_STRUCTURE_SHIPMENTS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'left',
    width: 80,
  },
  {
    columnName: 'amount',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'createdAt',
    wordWrapEnabled: true,
    align: 'left',
    width: 140,
  },
  {
    columnName: 'shippingDocument',
    align: 'left',
    width: 240,
  },
  {
    columnName: 'uploadFile',
    align: 'left',
    width: 160,
  },
  {
    columnName: 'actions',
    width: 160,
  },
];
export const COLUMNS_MODEL_CREATOR_SHIPMENTS = [
  {
    name: 'id',
    title: 'ID',
    getCellValue: ({ id }) => {
      return (
        <Typography>
          {id}
        </Typography>
      )
    },
  },
  {
    name: 'amount',
    title: 'Сумма (руб.)',
    getCellValue: ({ amount }) => {
      return (
        <Box>
          <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
            <FormattedCurrency value={amount} />
          </Typography>
        </Box>
      )
    },
  },
  {
    name: 'shippingDocument',
    title: 'Документ отгрузки',
  },
  {
    name: 'createdAt',
    title: 'Дата',
    getCellValue: ({ deliveredAt }) => {
      return (
        <Typography>
          {dayjs(deliveredAt).format(DATE_TIME_FORMAT_VIEW)}
        </Typography>
      )
    },
  },
  {
    name: 'uploadFile',
    title: 'Прочие документы',
  },
  {
    name: 'actions',
    title: ' ',
  },
];
/* end table */

export const PAGING_PANEL_MESSAGES = {
  showAll: 'Все',
  rowsPerPage: 'Заказов на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Заказов нет';
    }

    return to === count
      ? `Заказов: ${to - from + 1} (всего ${count})`
      : `Заказы ${from}-${to} (всего ${count})`;
  },
};