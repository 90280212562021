import React, { useCallback, useContext, useEffect, useState } from 'react';

import {useLazyQuery, useQuery} from '@apollo/client';
import { Link } from '@material-ui/core';
import { cloneDeep } from "lodash";
import {useDebouncedCallback} from "use-debounce";

import DealsFilterModal from './DealsFilterModal';
import DealsFilterState from './DealsFilterState';
import {GET_FUNDING_TRANCHES} from "./query/fundingSourcesTranches";
import Search from "../../../components/Search";
import {SEARCH_DEBOUNCE} from "../../../core/constants/searchDebounce";
import { DealsFilterContext } from '../DealsFilterContext';
import { GET_FUNDING_SOURCES } from '../graphql/queries/fundingSources';

export default function DealsFilter() {
  const { lineFundingSource, setFundingSources, setLineFundingSource, setTranches, state, tranches, updateState } = useContext(DealsFilterContext);

  const {
    data: fundingSourcesData,
    loading: isFundingSourcesLoading,
  } = useQuery(GET_FUNDING_SOURCES);

  const { data: dateFundingSources } = useQuery(GET_FUNDING_SOURCES);

  const [ fetchTranches, { data: dateTranches }] = useLazyQuery(GET_FUNDING_TRANCHES);

  useEffect(() => {
    if (!isFundingSourcesLoading && fundingSourcesData?.fundingSources) {
      setFundingSources(fundingSourcesData.fundingSources);
    }

    if (state.applicationFundingSourceId && !lineFundingSource.length) {
      if (dateFundingSources?.fundingSources && dateFundingSources?.fundingSources.length > 0) {
        setLineFundingSource(dateFundingSources.fundingSources);
      }
    }

    if (state.trancheId && !tranches.length) {
      fetchTranches({
        variables: {
          fundingSourceId: state.applicationFundingSourceId,
        }
      });

      if (dateTranches?.fundingSource?.tranches) {
        setTranches(dateTranches?.fundingSource?.tranches);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingSourcesData, isFundingSourcesLoading, setFundingSources, dateFundingSources, state, dateTranches]);

  const [modalOpened, setModalOpened] = useState(false);

  const handleOpenFilter = useCallback(() => setModalOpened(true), []);
  const handleCloseFilter = useCallback(() => setModalOpened(false), []);

  const debounced = useDebouncedCallback(value => {
    const newState = cloneDeep(state);

    newState.leasingAgreement = value.trim();

    if (!newState.leasingAgreement.length) {
      delete newState.leasingAgreement;
    }

    updateState(newState);
  }, SEARCH_DEBOUNCE)

  return (
    <>
      <Link
        component="button"
        onClick={handleOpenFilter}
        sx={{
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px dashed',
          mx: 3,
        }}
        underline="none"
        variant="body1"
      >
        Фильтр
      </Link>

      <Search
        onChange={debounced.callback}
        placeholder={"Введите номер договора"}
        sx={{ margin: '0 !important', width: 'calc( 100% - 175px )', height: '48px', fontSize: '15px', }}
        value={state?.leasingAgreement}
      />

      {modalOpened && <DealsFilterModal onClose={handleCloseFilter} />}
      <DealsFilterState />
    </>
  );
}
