import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_ASSIGN_TO_LOAN_SUPPLY = gql`
  mutation SourceDocumentsAssignToLoanSupply($assignParams: SourceDocumentLoanSupplyAssignParams!) {
    sourceDocumentsAssignToLoanSupply(assignParams: $assignParams) {
      sourceDocument {
        id
      }
    }
  }
`;