import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import _get from 'lodash.get';
import _noop from 'lodash.noop';
import { useForm } from 'react-hook-form';

import { UnpinIcon } from 'components/UnpinIcon';
import {
  BANK_STATEMENT_CREATE_COMMENT,
} from 'modules/bankStatements/graphql/mutations/bankStatementCreateComment';

import { ResolutionFormContext } from './FixedResolution';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eef2f7',
  },
  focused: {
    backgroundColor: '#fff',
  },
}));

FormCreateResolution.propTypes = {
  bankStatementId: PropTypes.string,
  defaultValues: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default function FormCreateResolution({
  bankStatementId,
  defaultValues,
  onSuccess = _noop,
}) {
  const { formState, toggleFormLock } = useContext(ResolutionFormContext);
  const { formState: fs, getValues, handleSubmit, register, reset } = useForm({
    defaultValues,
  });

  const [createComment, { loading }] = useMutation(
    BANK_STATEMENT_CREATE_COMMENT, {
    onCompleted: (result) => {
      const text = _get(
        result,
        'bankStatementCreateComment.bankStatementComment.text',
      );

      onSuccess();
      reset({ text }, { isSubmitted: true });
    },
    update: (cache, result) => {
      const newItemId = _get(
        result,
        'data.bankStatementCreateComment.bankStatementComment.id',
      );

      cache.modify({
        id: cache.identify({
          __typename: 'BankStatement',
          id: bankStatementId,
        }),
        fields: {
          comments(existingRefs = [], { readField, toReference }) {
            const newItemRef = toReference(
              `BankStatementComment:${newItemId}`,
            );

            if (existingRefs.some((ref) => readField('id', ref) === newItemRef)) {
              return existingRefs;
            }

            return [...existingRefs, newItemRef];
          },
        },
      });
    },
  });

  const onSubmit = ({ text }) => {
    createComment({
      variables: {
        text,
        bankStatementId,
      },
    });
  };
  const classes = useStyles();

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} width={1}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{
                marginBottom: 'auto',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <IconButton
                edge="start"
                onClick={() => {
                  const text = getValues('text');
                  toggleFormLock({ locked: !formState.locked, text });
                }}
              >
                {formState.locked
                  ? <UnpinIcon />
                  : <VerticalAlignBottomIcon />}
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ marginTop: 'auto', paddingBottom: 10, paddingTop: 10 }}
            >
              {loading ? (
                <CircularProgress />
              ) : !fs.isDirty && fs.isSubmitted ? (
                <Tooltip title="Изменения сохранены">
                  <DoneIcon />
                </Tooltip>
              ) : (
                <IconButton edge="end" type="submit">
                  <SendOutlinedIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          classes: { focused: classes.focused },
        }}
        autoFocus
        classes={{ root: classes.root }}
        color="secondary"
        fullWidth
        inputRef={register}
        multiline
        name="text"
        placeholder="Напишите текст резолюции..."
        // rowsMax={4}
        variant="outlined"
      />
    </Box>
  );
}
