import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { TaskContext } from './TaskContext';
import { getTaskDetailsStyles } from './TaskDetails.styles';
import TaskEdit from './TaskEdit';
import CommentList from "../../../../components/CommentList";
import Dropdown from "../../../../components/Dropdown";
import ErrorAlert from "../../../../components/ErrorAlert";
import Phone from "../../../../components/Phone";
import WazzupLink from "../../../../components/WazzupLink";
import findURL from "../../../../utils/findURL";
import { Loader } from "../../../deals/components/helpers";
import { CREATE_NOTES_TASK_TODO } from "../../../deals/graphql/mutations/createNotesTaskToDo";
import { GET_TASK_DETAIL } from "../../graphql/queries/task";

TaskDetails.propTypes = {
  boardColor: PropTypes.string,
  onClose: PropTypes.func,
  onCompleteTask: PropTypes.func,
  open: PropTypes.bool,
  priorityColor: PropTypes.string,
};

export default function TaskDetails({
  boardColor,
  onClose,
  onCompleteTask,
  open,
  priorityColor,
}) {
  const PRIORITY_NOTES = [
    "INFORM",
    "URGENT",
    "ACTION",
    "CONFIDENTIALLY",
  ]

  const [fetchTaskDetail, { data, error, loading }] = useLazyQuery(GET_TASK_DETAIL);

  const { task, type, updateNotes, updateTaskDetail } = useContext(TaskContext);

  const [editMode, setEditMode] = useState(false);
  const handleStartEdit = useCallback(() => setEditMode(true), []);
  const handleFinishEdit = useCallback(() => setEditMode(false), []);

  useEffect(() => {
    fetchTaskDetail({
      variables: {
        taskId: task.id,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      updateTaskDetail(data.task);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleClose = useCallback(() => {
    if (!editMode && onClose instanceof Function) {
      onClose();
    }

    handleFinishEdit();
  }, [editMode, handleFinishEdit, onClose]);

  const handleCompleteTask = useCallback(() => {

    if (!editMode && onCompleteTask instanceof Function) {
      onCompleteTask();
    }

    handleFinishEdit();
  }, [editMode, handleFinishEdit, onCompleteTask]);

  const handleForceClose = useCallback(() => {
    if (onClose instanceof Function) {
      onClose();
    }

    handleFinishEdit();
  }, [handleFinishEdit, onClose]);

  const taskDetailsStyles = getTaskDetailsStyles(boardColor, priorityColor);

  const contactFullName = useMemo(() => [
    task?.target?.contact?.lastName || '',
    task?.target?.contact?.firstName || '',
  ].join(' ').trim(), [task]);

  const [comment, setComment] = useState("");
  const [priority, setPriority] = useState(PRIORITY_NOTES[0]);

  const [createNotesTaskToDo] = useMutation(
    CREATE_NOTES_TASK_TODO, {
      onError: (error) => {
        console.log("error", error)
      },
      onCompleted: (data) => {
        if (data) {
          setComment("");
          updateNotes(data.noteCreate.note);
        }
      },
    }
  );

  const onChangeComment = event => {
    setComment(event.target.value);
  }

  const onSendComment = () => {
    createNotesTaskToDo({variables: {
      body: comment,
      targetId: task.target.id,
      priority,
      targetType: "APPLICATION"
    }});
  }

  const onChangePriority = priority => {
    setPriority(priority)
  }

  const renderMarkApplication = (status) => {
    let bg = '';
    let color = '';

    switch (status) {
      case 'ADDITIONAL_DOCUMENTS_REQUEST':
        bg = '#64a2bd';
        color = '#0a2834';

        break;
      case 'ADVANCE_PAYMENT_RECEIVED':
      case 'PARTIAL_PAYMENT':
        bg = '#8ca94e';
        color = '#395007';

        break;
      case 'APPROVED':
        bg = '#75803a';
        color = '#2f340c';

        break;
      case 'DEAL':
        bg = '#b0de81';
        color = '#456227';

        break;
      case 'EXTINGUISHED':
        bg = '#fab875';
        color = '#7c4710';

        break;
      case 'GOVERNANCEING':
        bg = '#bbb773';
        color = '#5b5718';

        break;
      case 'ISSUED':
        bg = '#6f934f';
        color = '#26420e';

        break;
      case 'LEAD':
        bg = '#c3c3c3';
        color = '#5d5c5c';

        break;
      case 'LEASING_SALE':
        bg = '#2ec7ce';
        color = '#15585b';

        break;
      case 'PREAPPROVED':
        bg = '#9ba652';
        color = '#40480f';

        break;
      case 'PREPARING_DEAL':
        bg = '#6c672f';
        color = '#282505';

        break;
      case 'PRIMARY_CONSULTATION':
        bg = '#4adadf';
        color = '#256d70';

        break;
      case 'PROPOSAL_FAMILIARIZED':
      case 'PROPOSAL_SENT':
        bg = '#808080';
        color = '#3d3d3d';

        break;
      case 'TRASH':
        bg = '#f17761';
        color = '#721300';

        break;
      case 'VERIFICATION':
      case 'null':
      case 'undefined':
      case 'UNDERWRITING':
      case 'PREPARING_DOCUMENTS':
        bg = '#e0c1bb';
        color = '#a27770';

        break;
      default:
        bg = '';
        color = '';
    }

    return (
      <Typography component="span" sx={{ ...taskDetailsStyles.markStatus, background: bg, color, }} variant="subtitle2">
        <FormattedMessage
          id={`application.substatus.${status}`}
        />
      </Typography>
    )
  }

  const renderMarkOrder = (status) => {
    let bg = '';
    let color = '';

    switch (status) {
      case 'INITIAL':
        bg = '#F5EAFA';
        color = '#9B2ECE';

        break;
      case 'PROPOSAL':
        bg = '#ECEFF2';
        color = '#465B79';

        break;
      case 'PENDING':
        bg = '#a8d6eb';
        color = '#0a506d';

        break;
      case 'PAYMENT':
        bg = '#b2e051';
        color = '#668d13';

        break;
      case 'SHIPPING':
        bg = '#f3dd23';
        color = '#7d7610';

        break;
      case 'DONE':
        bg = '#EAF5E6';
        color = '#299E00';

        break;
      case 'CLOSED':
        bg = '#FDEFEC';
        color = '#EE6344';

        break;
      default:
        bg = '';
        color = '';
    }

    return (
      <Typography component="span" sx={{ ...taskDetailsStyles.markStatus, background: bg, color, }} variant="subtitle2">
        <FormattedMessage
          id={`orders.messagesStatus.${status}`}
        />
      </Typography>
    )
  }

  if (loading) {
    return <Loader />
  }
  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <Dialog fullWidth open={open} scroll="body">
      <DialogTitle disableTypography sx={taskDetailsStyles.header}>
        <Box>
          <Typography
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            variant="h3"
          >
            <Box>
              {editMode ? 'Редактирование задачи' : 'Детали задачи'}
              {' '}
              <Box component="span" sx={{ color: '#a7b1c2' }}>
                #{task.id}
              </Box>
            </Box>
            {task?.priority && (
              <Chip
                label={(
                  <FormattedMessage id={`task.priority.${task.priority}`} />
                )}
                size="small"
                sx={taskDetailsStyles.priority}
              />
            )}
          </Typography>

          {task?.title && <Typography>{task.title}</Typography>}
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {editMode ? <TaskEdit onClose={handleForceClose} /> : (
          <Box sx={taskDetailsStyles.table}>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Заявка
                {task?.target?.borrowerEntity && (
                  <Link
                    color="#ff6600"
                    href={`${process.env.REACT_APP_HOST}/entities/${task?.target?.borrowerEntity?.id}`}
                    sx={{marginLeft: '10px'}}
                    target={"_blank"}
                  >
                    {task?.target?.borrowerEntity?.shortLegalName}
                  </Link>
                )}
              </Typography>

              {task?.target?.status && (
                renderMarkApplication(task?.target?.status)
              )}

              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                <Link
                  color="#000"
                  href={`${process.env.REACT_APP_HOST}/applications/${task?.target?.id}`}
                  target={"_blank"}
                >
                  #{task?.target?.id}
                  {task?.target?.subject && (
                    <>
                      ;
                      {' '}
                      {task?.target?.subject}
                    </>
                  )}

                </Link>
              </Typography>
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Поставщик
              </Typography>
              {task?.target?.supplyingEntityContracts && task?.target?.supplyingEntityContracts.length > 0 && task?.target?.supplyingEntityContracts[0]?.supplyingOrder && task?.target?.supplyingEntityContracts[0]?.supplyingOrder?.status && (
                renderMarkOrder(task?.target?.supplyingEntityContracts[0]?.supplyingOrder.status.toUpperCase())
              )}

              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                {task?.target?.supplyingEntityContracts && task?.target?.supplyingEntityContracts.length > 0 && task?.target?.supplyingEntityContracts[0]?.supplyingOrder ? (
                  <Link
                    color="#000"
                    href={`${process.env.REACT_APP_HOST}/entities/${task?.target?.supplyingEntityContracts[0]?.supplyingOrder?.supplier?.id}`}
                  >
                    {task?.target?.supplyingEntityContracts[0]?.supplyingOrder?.supplier?.legalNameWithShortEntityType}
                  </Link>
                ) : (
                  '—'
                )}
              </Typography>
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Контактное лицо
              </Typography>
              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                <Link
                  color="#000"
                  href={`${process.env.REACT_APP_HOST}/contacts/${task?.target?.contact?.id}`}
                  target={"_blank"}
                >
                  {contactFullName}
                </Link>
                <br />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ color: '#91919c' }}>тел.:</Box>
                  &nbsp;
                  <Phone phone={task?.target?.contact?.phone} />

                  <WazzupLink contactId={task?.target?.contact?.id} />,
                  &nbsp;
                  <Box component="span" sx={{ color: '#91919c' }}>email.:</Box>
                  &nbsp;
                  {task?.target?.contact?.email || '---'}
                </Box>
              </Typography>
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Срок до
              </Typography>
              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                <Box sx={{ color: boardColor }}>
                  {type === 'today' && (
                    <React.Fragment>
                      Сегодня
                      {task?.dueAt && task?.certainTime && (
                        <React.Fragment>
                          , <FormattedTime value={task.dueAt} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {type === 'tomorrow' && (
                    <React.Fragment>
                      Завтра
                      {task?.dueAt && task?.certainTime && (
                        <React.Fragment>
                          , <FormattedTime value={task.dueAt} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {(type === 'past' || type === 'further') && (
                    <React.Fragment>
                      {task?.dueAt ? (
                        <FormattedDate
                          {...task?.certainTime
                            ? { hour: 'numeric', minute: 'numeric' }
                            : {}
                          }
                          day="numeric"
                          month="numeric"
                          value={task.dueAt}
                          year="2-digit"
                        />
                      ) : 'нет данных'}
                    </React.Fragment>
                  )}
                </Box>
              </Typography>
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Описание
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{__html: findURL(task?.description?.replace(/\n/gi, " <br/> ")) }}
                sx={taskDetailsStyles.tableValue}
                variant="subtitle2"
              />
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Автор
              </Typography>
              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                {task?.createdBy?.displayName}
              </Typography>
            </Box>
            <Box sx={taskDetailsStyles.tableRow}>
              <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                Исполнитель
              </Typography>
              <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                {task?.assignee?.displayName}
              </Typography>
            </Box>
            {task?.substituteUser?.displayName && (
              <Box sx={taskDetailsStyles.tableRow}>
                <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                  Замещенный сотрудник
                </Typography>
                <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                  {task.substituteUser.displayName}
                </Typography>
              </Box>
            )}
            {task?.attachments?.length > 0 && (
              <Box sx={taskDetailsStyles.tableRow}>
                <Typography sx={taskDetailsStyles.tableKey} variant="body2">
                  Прикреплённые файлы
                </Typography>
                <Typography sx={taskDetailsStyles.tableValue} variant="subtitle2">
                  {task.attachments.map((element, id) => (
                    <Box key={`file_${id}`} sx={taskDetailsStyles.file}>
                      <Link href={element.url} target="_blank">{element.filename}</Link>
                    </Box>
                  ))}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>

      {!editMode && (
        <>
          <DialogContent>
            <DialogActions sx={{paddingRight: 0}}>
              <Button
                onClick={handleStartEdit}
                variant="outlined"
              >
                Редактировать
              </Button>

              <Button
                onClick={handleCompleteTask}
                sx={taskDetailsStyles.buttonComplete}
                variant="outlined"
              >
                Завершить
              </Button>
            </DialogActions>
          </DialogContent>

          <DialogContent dividers>
            <TextField
              fullWidth
              minRows={3}
              multiline
              onChange={onChangeComment}
              placeholder={"Добавить комментарий"}
              size="small"
              sx={{
                fontSize: '14px',
                marginTop: '26px',
              }}
              value={comment}
            />

            <DialogActions sx={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'space-between' }}>
              <Dropdown
                items={PRIORITY_NOTES}
                label={"Укажите приоритет:"}
                langPath={"note.type."}
                onChange={onChangePriority}
              />

              <Button
                disabled={comment.length === 0 || comment.trim().length === 0}
                onClick={onSendComment}
                variant="outlined"
              >
                Добавить
              </Button>
            </DialogActions>
          </DialogContent>

          <CommentList messages={task?.target?.notes || []} />
        </>
      )}

    </Dialog>
  );
}