import PropTypes from 'prop-types';
import React from 'react';

import { Chip } from '@material-ui/core';

export const ApplicationStatus = ({ active = false, status }) => (
  <Chip
    color={active ? 'success' : 'default'}
    label={status}
    size="small"
    sx={{
      fontSize: 12,
      color: active ? 'white' : 'inherit',
      borderRadius: '4px',
      height: 16,
    }}
  />
);

ApplicationStatus.propTypes = {
  active: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};