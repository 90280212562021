import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import {cloneDeep, isEqual} from 'lodash';

import { DatePickerBox } from 'components/DatePickerBox';
import { formatDate } from 'utils/dateTimeFormatters';

import { OrdersContext } from './OrdersContext';
import AutocompleteTaskUsers from "../../components/AutocompleteTaskUsers";
import {DATE_TIME_FORMAT_NOT_Z} from "../../core/constants/Formats";
import {FilterModalBox} from "../deals/components/helpers";

OrdersFiltersModal.propTypes = {
  onClose: PropTypes.func,
};

export default function OrdersFiltersModal({ onClose }) {
  const { state, updateFilter } = useContext(OrdersContext);

  const [persistState, setPersistState] = useState(state.filter);
  const [hasDateError, setHasDateError] = useState(false);

  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);

    onClose();
  }, [onClose]);

  const updateFilterState = useCallback((fieldName, value) => {
    setPersistState((prev) => {
      const data = { ...prev, [fieldName]: value };
      if (fieldName === 'date' && value) {
        if (!value?.from === null) {
          delete data.date.from;
        }

        if (!value?.to === null) {
          delete data.date.to;
        }

        if (!value?.from && !value?.to) {
          delete data.date;
        }
      }
      return data;
    });
  }, []);

  const applyFilter = useCallback(() => {
    const newState = cloneDeep(state)
    newState.filter = persistState;
    updateFilter(newState);

    handleClose();
  }, [persistState, updateFilter, handleClose, state]);

  const resetFilter = useCallback(() => {
    const newPersistState = cloneDeep(persistState);

    if (newPersistState.arenzaManagerUserIdIn) {
      delete newPersistState.arenzaManagerUserIdIn;
    }
    
    if (newPersistState.date) {
      delete newPersistState.date
    }

    newPersistState.page = 1;

    setPersistState(newPersistState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = useCallback(
    (fieldName) => (value) => {
      let dateValue = null;

      if (value && dayjs(value).isValid()) {
        if (fieldName === 'from') {
          dateValue = dayjs(value).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z);
        } else {
          dateValue = dayjs(value).utc(true).endOf("day").format(DATE_TIME_FORMAT_NOT_Z);
        }
      }

      updateFilterState('date', {
        ...persistState?.date,
        [fieldName]: dateValue,
      });
    },
    [persistState, updateFilterState],
  );

  return (
    <Dialog fullWidth open={open} scroll="body" sx={{ '& .MuiDialog-paper': { maxWidth: 480 } }}>
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Фильтр</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FilterModalBox title="Дата создания">
          <DatePickerBox
            maxDate={persistState?.date?.to}
            maxDateMessage={
              persistState?.date?.to
                ? `Не может быть более ${formatDate(persistState.date.to)}`
                : undefined
            }
            onChange={handleChangeDate('from')}
            onError={setHasDateError}
            placeholder="с"
            value={persistState?.date?.from || null}
          />
          &nbsp;—&nbsp;
          <DatePickerBox
            minDate={persistState?.date?.from}
            minDateMessage={
              persistState?.date?.from
                ? `Не может быть ранее ${formatDate(persistState.date.from)}`
                : undefined
            }
            onChange={handleChangeDate('to')}
            onError={setHasDateError}
            placeholder="по"
            value={persistState?.date?.to || null}
          />
        </FilterModalBox>

        <FilterModalBox title="Менеджер">
          <AutocompleteTaskUsers
            onChange={(value) => updateFilterState('arenzaManagerUserIdIn', value)}
            value={persistState.arenzaManagerUserIdIn || ''}
          />
        </FilterModalBox>

      </DialogContent>
      <DialogActions>
        <Button disabled={!Object.keys(persistState).length} onClick={resetFilter}>
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state.filter) || hasDateError}
          onClick={applyFilter}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
}
