export function customSort({ data, sortBy, sortField }) {
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  if (sortField) {
    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
  } else {
    return data.sort((a, b) => sortByObject[a] - sortByObject[b]);
  }
}
