import PropTypes from "prop-types";
import React, { useMemo, useState } from 'react';

import {Box, Tab, Tabs as WrapperTabs, Typography} from '@material-ui/core';
import {FormattedMessage} from "react-intl";

Tabs.propTypes = {
  counterTabs: PropTypes.array, //examply:  ['3', '20']
  defaultActiveTab: PropTypes.number, //examply:  0
  disabledTabs: PropTypes.array, //examply:  ['COMMENTS']
  langPath: PropTypes.string, //examply:  'task.tabs.'
  onChange: PropTypes.func,
  sx: PropTypes.object,
  tabs: PropTypes.array.isRequired, //examply:  ['COMMENTS', 'NOTES']
};

Tabs.defaultProps = {
  placeholder: "Введите поисковой запрос",
  value: "",
  defaultActiveTab: 0,
  sx: {
    wrapperTabs: {},
    tab: {},
  },
  counterTabs: [],
  disabledTabs: [],
}

export default function Tabs({ counterTabs, defaultActiveTab, disabledTabs, langPath, onChange, sx, tabs }) {
  const styles = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      width: '100%',
      padding: '0 24px',

      '& > div': {
        width: '100%',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#3651DE',
        height: '4px',
      },

      ...sx.wrapperTabs,
    },
    tab: {
      cursor: 'pointer',
      borderBottom: '3px solid transparent',
      textAlign: 'center',
      padding: '16px 18px',
      minWidth: `calc(100% / ${tabs.length}) !important`,
      width: `calc(100% / ${tabs.length}) !important`,
      textTransform: 'none',
      fontWeight: '400',
      fontSize: '14px',

      '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
      },
      '& .counter-tabs': {
        paddingLeft: '6px',
        fontWeight: 300,
        display: 'inline-block',
        color: '#6E7884',
      },
      '&.Mui-selected': {
        cursor: 'default',
        color: '#3651DE',
      },
      '& > span:nth-child(2)': {
        display: 'none'
      },

      ...sx.tab,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const onToggleTab = (event, newValue) => {
    setActiveTab(newValue);

    if (onChange && typeof onChange === 'function') {
      onChange(newValue);
    }
  }

  return (
    <Box sx={styles.wrapperTabs}>
      <WrapperTabs onChange={onToggleTab} value={activeTab}>
        {tabs.map((elem, index) => {
          return (
            <Tab
              disabled={disabledTabs.includes(elem)}
              key={index}
              label={
                langPath ? (
                  <>
                    <FormattedMessage id={langPath+elem} />
                    {counterTabs.length > 0 && (
                      <Typography className={'counter-tabs'} component={"span"}>{counterTabs[index]}</Typography>
                    )}
                  </>
                ) : (
                  <>
                    {elem}
                    {counterTabs.length > 0 && (
                      <Typography className={'counter-tabs'} component={"span"}>{counterTabs[index]}</Typography>
                    )}
                  </>
                )
              }
              sx={styles.tab}
              value={index}
            />
          )
        })}
      </WrapperTabs>
    </Box>
  );
}