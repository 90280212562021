import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import { DataTypeProvider, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Typography
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import DetailsModalInfUser from "./DetailsModalInfUser";
import ErrorAlert from "../../../components/ErrorAlert";
import {GridRoot, Loader} from "../../deals/components/helpers";
import {
  COLUMN_EXTENSIONS_STRUCTURE_USERS,
  COLUMNS_MODEL_CREATOR_STRUCTURE_USERS
} from "../constants";
import { USERS_GROUPS } from "../graphql/queries/usersGroups";
import DetailInfUser from "../TableProvidererInfUser";

export const DocumentActionTypeProviderDetailUser = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <DetailInfUser {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export default function CollaboratorsStructureUsers() {
  const styles = useMemo(() => ({
    wrapper: {
      width: '100%',
    },
    wrapperContainer: {
      width: '100%',

      '&:first-child > p': {
        marginTop: 0,
      },
      '&:last-child > p': {
        marginBottom: 0,
      }
    },
    title: {
      fontSize: '24px',
      margin: '19px 0',
      color: '#0D1D32',
      fontWeight: 400,
      cursor: 'pointer',
      userSelect: 'none',
      fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif',
      borderRadius: '8px',
      padding: '18px 27px',
      position: 'relative',
      zIndex: '2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #ffffff',

      '&:hover': {
        border: '1px solid #3D6CE7',
        background: '#ffffff',
        color: '#0D1D32',
      },
      '&:hover svg': {
        color: '#3D6CE7',
      }
    },
    wrapperTables: {
      margin: '0 36px',
      marginBottom: '43px',
      position: 'relative',

      '&:before': {
        content: "''",
        display: 'block',
        width: '1px',
        height: '100%',
        borderLeft: '1px dashed #CFD2D6',
        position: 'absolute',
        left: 0,
        bottom: '30px',
        zIndex: '1'
      },
      '&:after': {
        content: "''",
        display: 'block',
        width: '7px',
        height: '7px',
        position: 'absolute',
        background: '#CFD2D6',
        left: '-3px',
        bottom: '30px',
        zIndex: '1',
        borderRadius: '4px',
      }
    },
    titleBeforeTable: {
      color: '#0D1D32',
      fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      padding: '23px 36px',
      position: 'relative',

      '&:before': {
        content: "''",
        width: '28px',
        height: '1px',
        position: 'absolute',
        left: '0',
        top: '50%',
        borderTop: '1px dashed #CFD2D6',
      }
    },
    wrapperTable: {
      paddingLeft: '36px',

      '& > div': {
        borderRadius: '16px',
        border: '1px solid #E7E8EA',
        overflow: 'hidden',
      },
      '& > div > div': {
        height: 'auto !important',
      },
      '& table': {
        marginBottom: '0 !important',
      },
      '& table thead span': {
        color: "#6E7884 !important",
      },
      '& thead th:last-child': {
        paddingRight: '16px',
      },
      '& tbody tr td:last-child': {
        paddingRight: '16px',
      },
    },
  }), []);

  const [fetchMessages, { data, error, loading }] = useLazyQuery(USERS_GROUPS);
  const [ allUsers, setAllUsers ] = useState(null);

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      setAllUsers(data?.userGroups);
    }
  }, [data])

  const [ showUsers, setShowUsers ] = useState([]);

  const [ showModal, setShowModal] = useState(false);
  const [ detailModalUser, setDetailModalUser ] = useState(null);
  const onShowModal = (bool, userId) => {
    setDetailModalUser(userId);
    setShowModal(bool);
  }

  const renderUsers = useCallback((elem) => {
   if (!showUsers.includes(elem.id)) return false;

    const rendetTable = users => {
      return (
        <Grid
          columns={COLUMNS_MODEL_CREATOR_STRUCTURE_USERS}
          getRowId={(row) => row.id}
          rootComponent={GridRoot}
          rows={users}
        >
          <RowDetailState />

          <DocumentActionTypeProviderDetailUser
            for={['user']}
            onToggleModal={onShowModal}
          />

          <VirtualTable
            cellComponent={(props) => (
              <VirtualTable.Cell className="HolidayTableCell" {...props} />
            )}
            columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_USERS}
            messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
          />

          <TableHeaderRow />
        </Grid>
      )
    }

    let leader = elem?.administrator?.user ? [elem?.administrator?.user] : [];

    return (
      <Box sx={styles.wrapperTables}>
        <Typography sx={styles.titleBeforeTable}>Руководитель группы:</Typography>
        <Box sx={styles.wrapperTable}>
          {rendetTable(leader)}
        </Box>

        <Typography sx={styles.titleBeforeTable}>Участники группы:</Typography>
        <Box sx={styles.wrapperTable}>
          {rendetTable(elem?.users || [])}
        </Box>
      </Box>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsers])

  const toggleShowBlock = (elem) => {
    const newShowUsers = [...showUsers];

    if (newShowUsers.includes(elem)) {
      newShowUsers.splice(newShowUsers.indexOf(elem), 1)
    } else {
      newShowUsers.push(elem)
    }

    setShowUsers(newShowUsers);
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <>
      <Box sx={styles.wrapper}>
        {allUsers && allUsers.map(elem => {
          return (
            <Box
              key={elem.id}
              sx={styles.wrapperContainer}
            >
              <Typography
                onClick={toggleShowBlock.bind(this, elem.id)}
                sx={{
                  ...styles.title,
                  background: showUsers.includes(elem.id) ? '#ffffff' : '#F5F6F7',
                  color: showUsers.includes(elem.id) ? '#0D1D32' : '#6E7884',
                  boxShadow: showUsers.includes(elem.id) ? '0px 10px 32px rgba(13, 29, 50, 0.12)' : 'none',

                  '&:before': {
                    content: "''",
                    position: 'absolute',
                    left: '-3px',
                    top: '50%',
                    marginTop: '-16px',
                    zIndex: '3',
                    width: '6px',
                    height: '32px',
                    borderRadius: '4px',
                    background: '#3D6CE7',
                    display: showUsers.includes(elem.id) ? 'block' : 'none'
                  }
                }}
              >
                {elem.title}

                {showUsers.includes(elem.id) ? <KeyboardArrowUpIcon sx={{ color: '#3D6CE7', }} /> : <KeyboardArrowDownIcon sx={{ color: '#CFD2D6' }} /> }
              </Typography>

              {renderUsers(elem)}
            </Box>
          )
        })}
      </Box>

      {showModal && (
        <DetailsModalInfUser
          onModalClose={onShowModal.bind(this, false)}
          userId={detailModalUser}
        />
      )}
    </>
  )
}
