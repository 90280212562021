export default function findURL(text) {
  if (!text?.length) return

  let res = text?.split(" ");
  let regex = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/gi;

  res.forEach((elem, index) => {
    if (elem.match(regex)) {
      if (!elem.includes('www') && !elem.includes('http')) {
        try {
          new URL(`http://www.${elem}`)

          res[index] = `<a target="_blank" href="http://www.${elem}">${elem}</a>`;
        } catch { }
      } else {
        if (!elem.includes('http')) {
          res[index] = `<a target="_blank" href="http://${elem}">${elem}</a>`;
        } else {
          res[index] = `<a target="_blank" href="${elem}">${elem}</a>`;
        }
      }
    }
  })

  return res.join(" ")
}