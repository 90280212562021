import { gql } from '@apollo/client/core';

export const UPDATE_HOLIDAY = gql`
  mutation UserLeaveUpdate ($leaveParams: UserLeaveUpdateParams!) {
    userLeaveUpdate(leaveParams: $leaveParams) {
      leave {
        id
        startDate
        endDate
        kind
        userTasksStatus
        deputy {
          id
          roles
          avatar
          displayName
          contact {
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;