import { gql } from '@apollo/client/core';

import { SCHEDULE_FRAGMENT, SCHEDULE_ITEM_FRAGMENT } from '../fragments';

export const GET_SCHEDULE = gql`
  query GetSchedule {
    schedule {
      ...ScheduleFragment
      items {
        ...ScheduleItemFragment
      }
    }
  }
  ${SCHEDULE_ITEM_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
`;