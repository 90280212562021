import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Link, Typography } from '@material-ui/core';
import transformUrl from 'transform-url';

import { FmsPassportCheck } from 'modules/fmsPassportCheck';
import { Fssp } from 'modules/fssp';

import { VerificationListItem } from './VerificationProcessList';
import {
  GET_VERIFICATION_PROCESS,
} from '../graphql/queries/verificationProcess';

FsspWidget.propTypes = {
  contact: PropTypes.object,
};
function FsspWidget({ contact }) {
  return <Fssp contactId={contact.id} />;
}

const CONTACT_PATH = `${process.env.REACT_APP_HOST}/contacts/:contactId`;

VerificationProcess.propTypes = {
  processId: PropTypes.string,
};

export default function VerificationProcess({ processId }) {
  const { data, error, loading } = useQuery(GET_VERIFICATION_PROCESS, {
    variables: { id: processId },
    fetchPolicy: 'cache-only',
  });
  const { application } = data.verificationProcess;

  if (error) {
    return error.message;
  }

  if (loading) {
    return 'Загрузка...';
  }

  return (
    <Box>
      <VerificationListItem data={data.verificationProcess} />
      <Typography variant="h4">Проверка юридического лица</Typography>
      <Typography variant="h4">Проверка физлиц</Typography>
      {application.borrowerEntity.contacts.map((contact, index) => {
        return (
          <Box key={index}>
            <Typography component="div" variant="body1">
              <Link
                href={transformUrl(CONTACT_PATH, { contactId: contact.id })}
                target="_blank"
              >
                {contact.fullName}
              </Link>
            </Typography>
            <Typography
              color="textSecondary"
              component="div"
              variant="caption"
            >
              e-mail:{' '}
              <Link href={`mailto:${contact.email}`} target="_blank">
                {contact.email}
              </Link>
            </Typography>
            <Typography variant="h5">Сверка анкеты</Typography>
            <FsspWidget contact={contact} />
            <FmsPassportCheck contactId={contact.id} />
          </Box>
        );
      })}
    </Box>
  );
}
