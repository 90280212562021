import PropTypes from 'prop-types';
import React, { forwardRef, useContext, useState } from 'react';

import {
  Box,
  ButtonBase,
  Divider,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _noop from 'lodash.noop';
import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'react-intl';

import { ScheduleContext } from './ScheduleContext';

const ITEM_HEIGHT = 60;

const scheduleItemPropTypes = PropTypes.shape({
  createdAt: PropTypes.string,
  activatedAt: PropTypes.string,
  stornedAt: PropTypes.string,
  id: PropTypes.string,
  state: PropTypes.oneOf(['active', 'draft', 'archived']),
});

const MenuItemPropTypes = {
  disabled: PropTypes.bool,
  item: scheduleItemPropTypes,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

// eslint-disable-next-line react/display-name
const MenuItem = forwardRef((
  { item, onSelect = _noop, selected, disabled },
  ref,
) => {
  // if (!groupedOptions[groupName]) return null;
  const intl = useIntl();
  const { activatedAt, createdAt, id, state, stornedAt } = item;

  const title = {
    draft: intl.formatMessage({ id: `${state}.one` }) + ' №' + id,
    archived: intl.formatMessage({ id: `${state}.one` }) + ' №' + id,
    active: intl.formatMessage({ id: `${state}.one` }),
  };

  const subtitle = {
    draft: 'Создан ' + DateTime.fromISO(createdAt)
      .toLocaleString(DateTime.DATE_SHORT),
    archived: 'Архивирован ' + DateTime.fromISO(stornedAt)
      .toLocaleString(DateTime.DATE_SHORT),
    active: 'Активирован ' + DateTime.fromISO(activatedAt)
      .toLocaleString(DateTime.DATE_SHORT),
  };

  function handleClick() {
    onSelect(id);
  }

  return (
    <ListItem
      button
      dense
      disabled={disabled}
      onClick={handleClick}
      selected={selected}>

      <ListItemText primary={title[state]} secondary={subtitle[state]} />
    </ListItem>
  );
});

MenuItem.propTypes = MenuItemPropTypes;

PaymentPlanSelector.propTypes = {
  activeSchedule: PropTypes.object,
  groupedSchedules: PropTypes.shape({
    active: PropTypes.array,
    archived: PropTypes.array,
    draft: PropTypes.array,
  }),
};

export default function PaymentPlanSelector({ activeSchedule }) {
  const intl = useIntl();

  const { dispatch, state } = useContext(ScheduleContext);

  const { active = [], archived = [], draft = [] } = state.groupedSchedules;
  const { activeScheduleId, comparedScheduleId } = state;

  const isMainSchedule = activeSchedule.id === activeScheduleId;
  const isComparedSchedule = activeSchedule.id === comparedScheduleId;

  function handleSelect(id) {
    if (isMainSchedule) {
      dispatch({ type: 'SELECT_ITEM', payload: { activeScheduleId: id } });
    }
    if (isComparedSchedule) {
      dispatch({ type: 'SELECT_ITEM', payload: { comparedScheduleId: id } });
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const title = {
    draft: intl.formatMessage({
      id: `${activeSchedule.state}.one`,
    }) + ' №' + activeSchedule.id,
    archived: intl.formatMessage({
      id: `${activeSchedule.state}.one`,
    }) + ' №' + activeSchedule.id,
    active: intl.formatMessage({
      id: `${activeSchedule.state}.one`,
    }),
  };

  function renderMenuItem(item, index) {
    return (
      <MenuItem
        disabled={
          activeSchedule.id === item.id ||
          (isMainSchedule && comparedScheduleId === item.id) ||
          (isComparedSchedule && activeScheduleId === item.id)
        }
        item={item}
        key={index}
        onSelect={handleSelect}
        selected={activeSchedule.id === item.id}
      />
    );
  }

  return (
    <>
      <ButtonBase
        color="primary"
        disableRipple
        onClick={handleClick}
        variant="text"
      >
        <Box color="#1771e6" display="flex">
          <Typography variant="h3">{title[activeSchedule.state]}</Typography>
          <Box ml="2px">
            <KeyboardArrowDownIcon />
          </Box>
        </Box>
      </ButtonBase>

      <Menu
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
          },
        }}
        anchorEl={anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {active.map(renderMenuItem)}
        <Divider />
        {draft.length ? (
          <ListSubheader disableSticky>
            <FormattedMessage id="draft.many" />
          </ListSubheader>
        ) : null}
        {draft.map(renderMenuItem)}
        {archived.length ? (
          <ListSubheader disableSticky>
            <FormattedMessage id="archived.many" />
          </ListSubheader>
        ) : null}
        {archived.map(renderMenuItem)}
      </Menu>
    </>
  );
}
