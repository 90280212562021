import { gql } from '@apollo/client';

export const GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES_FULL_DATA = gql`
  query GetCriminalActivityCheckProcessesFullData($contactId: ID!) {
    criminalActivityCheckProcesses(contactId: $contactId) {
      id
      createdAt
      failedAt
      finishedAt
      startedAt
      contactData {
        id
        lastName
        firstName
        middleName
        dateOfBirth
        passportNumber
        passportSeries
      }
      state
      financialFraud
      terrorism
      weaponDistribution
    }
  }
`;