import PropTypes from 'prop-types';
import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ReadMoreIcon from '@material-ui/icons/ReadMore';
import { Link as RouterLink } from '@reach/router';
import { FormattedMessage } from 'react-intl';

import ErrorAlert from 'components/ErrorAlert';
import { formatDate } from 'utils/dateTimeFormatters';

import {
  ENTITY_CREATE_CONTACT_QUESTIONNAIRE,
} from './graphql/mutations/entityCreateContactQuestionnaire';
import { GET_ENTITY_FULL_DATA } from './graphql/queries/entityFullData';
import QuestionnaireVersions from './QuestionnaireVersions';

PageQuestionnaires.propTypes = {
  children: PropTypes.node,
  entityId: PropTypes.string,
};

export default function PageQuestionnaires({ children, entityId }) {
  const { data, error, loading, refetch } = useQuery(GET_ENTITY_FULL_DATA, {
    variables: { entityId },
  });

  const [create, { loading: isCreateInProgress }] = useMutation(
    ENTITY_CREATE_CONTACT_QUESTIONNAIRE,
    {
      variables: { entityId },
      onCompleted: (res) => {
        const newData =
          res?.entityCreateContactQuestionnaire?.contactEntityQuestionnaire?.data;

        if (newData) {
          refetch();
        }
      },
      onError: (error) => {
        console.log(
          '🚀 ~ file: PageQuestionnaires.jsx ~ line 54 ~ PageQuestionnaires ~ error',
          error,
        );
        alert(`Ошибка создания анкеты: ${error.message}`);
      },
    },
  );

  if (error && !data) {
    return (
      <ErrorAlert
        message={error.message}
        title="Ошибка при получении данных"
      />
    );
  }

  if (loading) {
    return 'Загрузка...';
  }

  if (!data.entity) {
    return `Компания с id = ${entityId} не найдена`;
  }

  const { entity } = data;

  return (
    <Box sx={{ mb: 8, px: 3, py: 3 }}>
      <Typography gutterBottom variant="h2">
        Анкеты компании
      </Typography>
      <Button
        component={Link}
        href={`${process.env.REACT_APP_HOST}/entities/${entityId}`}
        variant="outlined"
      >
        Назад
      </Button>
      <Button
        disabled={isCreateInProgress}
        onClick={create}
        sx={{ ml: 3 }}
        variant="outlined"
      >
        Добавить анкету
      </Button>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: 5,
          mt: 5,
        }}
      >
        <Box>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              maxHeight: 620,
              bgcolor: 'background.paper',
              position: 'sticky',
              top: 0,
              overflow: 'auto',
              scrollbarColor: 'rgba(33, 150, 243, 0.7) transparent',
              '&::-webkit-scrollbar': {
                width: 6,
                height: 6,
              },
              '&::-webkit-scrollbar-track': {
                background: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(33, 150, 243, 0.7)',
                borderRadius: 6,
                '&:hover': {
                  background: '#2196f3',
                },
              },
            }}
          >
            {entity.questionnaires.map((q) => {
              const versions = [...q.versions]?.sort(
                (v1, v2) => v2.version - v1.version
              );

              return (
                <ListItem
                  key={q.id}
                  sx={{
                    border: '8px solid whitesmoke',
                    borderRadius: '8px',
                    alignSelf: 'flex-start',
                    mb: 3,
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ mb: 1 }} variant="inherit">
                        <Chip
                          label={
                            <FormattedMessage
                              id={`entity.questionnaire.state.${q.state}`}
                            />
                          }
                          size="small"
                          sx={{
                            mr: 2,
                            ...{
                              // DRAFT: { bgcolor: 'text.disabled' },
                              COMPLETED: {
                                color: 'white',
                                bgcolor: 'info.main',
                              },
                              ACCEPTED: {
                                color: 'white',
                                bgcolor: 'success.main',
                              },
                            }[q.state],
                          }}
                        />
                        № <b>{q.id}</b> от <b>{formatDate(q.createdAt)}</b>{' '}
                      </Typography>
                    }
                    secondary={
                      <>
                        {q?.contact && (
                          <>
                            Заполнил(a)&nbsp;
                            <Link
                              href={
                                `${process.env.REACT_APP_HOST}/contacts/${q.contact?.id}`
                              }
                              underline="hover"
                            >
                              {q.contact?.fullName}
                            </Link>
                          </>
                        )}

                        <QuestionnaireVersions
                          entityId={entityId}
                          questionnaireId={q.id}
                          versions={versions}
                        />
                      </>
                    }
                  />

                  <ListItemSecondaryAction sx={{ top: 40 }}>
                    <IconButton
                      component={RouterLink}
                      edge="end"
                      sx={{ border: 'none', borderRadius: '50%' }}
                      to={q.id}
                    >
                      <ReadMoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box>
          {React.cloneElement(children, {
            children: React.Children.map(children.props.children, (child) => {
              return React.cloneElement(child, { entity, refetch });
            }),
          })}
        </Box>
      </Box>
    </Box>
  );
}