import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

ConfirmDialog.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default function ConfirmDialog({ message, onCancel, onConfirm }) {
  const handleConfirm = useCallback(onConfirm, [onConfirm]);
  const handleCancel = useCallback(onCancel, [onCancel]);

  return (
    <Dialog fullWidth onClose={handleCancel} open scroll="body">
      <DialogTitle>Подтвердите действие</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} variant="outlined">
          Да
        </Button>
        <Button
          onClick={handleCancel}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}