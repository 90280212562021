import React, { useCallback, useEffect, useState } from 'react';

import {navigate, useLocation} from '@reach/router';

import { TASKS_VIEW_STORAGE_KEY } from 'core/constants/storageKeys';
import { localStore } from 'utils/storageFactory';

import TasksDashboard from './components/TasksDashboard';
import TasksTable from './components/TasksTable';
import TasksLayout from './layout';

export default function PageTasks() {
  const [tasksView, setTasksView] = useState('table');
  const location = useLocation();

  const handleChangeView = useCallback((view) => {
    setTasksView(view);
    localStore.setItem(TASKS_VIEW_STORAGE_KEY, view);
    navigate(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const storageTasksView = localStore.getItem(TASKS_VIEW_STORAGE_KEY);
    if (storageTasksView === null) {
      localStore.setItem(TASKS_VIEW_STORAGE_KEY, 'table');
    } else {
      setTasksView(storageTasksView);
    }
  }, []);

  return (
    <TasksLayout onChangeView={handleChangeView} view={tasksView}>
      {tasksView === 'table' && <TasksTable />}
      {tasksView === 'line' && <TasksDashboard />}
    </TasksLayout>
  );
}