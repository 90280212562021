export const getBoardStyles = (boardColor, isMobile) => ({
  board: {
    flex: 'none',
    width: isMobile ? '100%' : 'calc(25% - 12px)',
    minWidth: 300,
    maxWidth: isMobile ? 'unset' : 420,
    backgroundColor: '#f1f2f6',
    transition: '0.2s ease-in-out',
    overflowY: isMobile ? 'unset' : 'scroll',
    height: '100%',
  },
  boardHeader: {
    height: 40,
    backgroundColor: boardColor,
    color: 'white',
    px: 3,
    py: 2,
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2,
    position: 'sticky',
    top: 0,
    boxShadow: (theme) => theme.shadows[2],
    zIndex: 1,
  },
  boardTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: '14px',
  },
  boardTaskCount: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
  },
  boardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    position: 'sticky',
    top: 48,
    p: 2,
  },
});