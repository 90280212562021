/* eslint-disable react/display-name, react/prop-types */
import React  from 'react';

import { Link, Typography, Box } from '@material-ui/core';
import dayjs from "dayjs";
import { findIndex } from "lodash";
import { FormattedMessage } from "react-intl";

import GoodAdditionalInfo from './contents/components/GoodAdditionalInfo';
import FormattedCurrency from "../../components/FormattedCurrency";
import Phone from "../../components/Phone";
import WazzupLink from "../../components/WazzupLink";
import { DATE_TIME_FORMAT_VIEW } from "../../core/constants/Formats";

export const DEALS_PAGE_LIMIT = 20;

/* start tabs */
export const TABS_GENERAL = ['GOODS', 'LOAN_SUPPLIES'];

export const TABS_GOODS = ['ALL', 'IN_STOCK', 'READY_FOR_PUBLISH', 'PUBLISHED', 'RESERVED', 'SOLD'];
/* end tabs */

/* start table */
export const COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'number',
    wordWrapEnabled: true,
    align: 'center',
    width: 250,
  },
  {
    columnName: 'company',
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'location',
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'date',
    wordWrapEnabled: true,
    align: 'center',
    width: 150,
  },
  {
    columnName: 'shippedEquipmentsCount',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'summa',
    wordWrapEnabled: true,
    align: 'right',
    width: 140,
  },
];
export const COLUMNS_MODEL_CREATOR_SHOP_LEASING = [
  {
    name: 'id',
    title: 'ID',
    getCellValue: ({ id }) => {
      return (
        // <Link href={`/workspaces/shop/leasing/${id}`}>
          <Typography>
            {id}
          </Typography>
        // </Link>
      )
    },
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Typography sx={{color: '#67BF3D' }}>
          <FormattedMessage id={'shop.tabs.' + status.toUpperCase()} />
        </Typography>
      )
    },
  },
  {
    name: 'number',
    title: '№ сделки',
    getCellValue: ({ loan }) => {
      return (
        <Box>
          {loan?.application?.id ? (
            <Link href={`${process.env.REACT_APP_HOST}/applications/${loan?.application?.id}/deal`}>{loan?.application?.id}</Link>
          ) : (
            '--'
          )}
        </Box>
      )
    },
  },
  {
    name: 'company',
    title: 'Компания получатель',
    getCellValue: ({ supplyingEntityContract }) => {
      return (
        <Box>
          {supplyingEntityContract?.application?.borrowerEntity?.shortLegalName && (
            <Link href={`${process.env.REACT_APP_HOST}/entities/${supplyingEntityContract?.application?.borrowerEntity?.id}`}>
              {supplyingEntityContract?.application?.borrowerEntity?.shortLegalName}
            </Link>
          )}

          {supplyingEntityContract?.application?.borrowerEntity?.shortLegalName && (
            <>
              <br />
              ИНН: {supplyingEntityContract?.application?.borrowerEntity?.tin}
            </>
          )}

          {supplyingEntityContract?.application?.borrowerEntity?.site && (
            <>
              <br />
              <Link href={supplyingEntityContract?.application?.borrowerEntity?.site} target="_blank">
                {supplyingEntityContract?.application?.borrowerEntity?.site}
              </Link>
            </>
          )}
        </Box>
      )
    },
  },
  {
    name: 'location',
    title: 'Локация / Адрес',
    getCellValue: ({ address, location }) => {
      return (
        <Box>
          {location ? location : "--"}
          /
          {address ? address : "--"}
        </Box>
      )
    }
  },
  {
    name: 'date',
    title: 'Дата отгрузки',
  },
  {
    name: 'shippedEquipmentsCount',
    title: 'Кол-во SKU',
  },
  {
    name: 'summa',
    title: 'Сумма',
    getCellValue: ({ amount }) => {
      return (
        <Box>
          {amount ? (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}><FormattedCurrency value={amount} /></Typography>
          ) : (
            '--'
          )}
        </Box>
      )
    },
  },
];

export const COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING_MODAL_SKU = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 80,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 90,
  },
  {
    columnName: 'number',
    wordWrapEnabled: true,
    align: 'center',
    width: 190,
  },
  {
    columnName: 'name',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'quantity',
    wordWrapEnabled: true,
    align: 'center',
    width: 50,
  },
  {
    columnName: 'unit',
    wordWrapEnabled: true,
    align: 'center',
    width: 60,
  },
  {
    columnName: 'pricePerUnit',
    wordWrapEnabled: true,
    align: 'right',
    width: 120,
  },
  {
    columnName: 'purchasePrice',
    wordWrapEnabled: true,
    align: 'right',
    width: 120,
  },
];
export const COLUMNS_MODEL_CREATOR_SHOP_LEASING_MODAL_SKU = [
  {
    name: 'id',
    title: 'ID SKU',
    getCellValue: ({ id }) => {
      return (
        // <Link href={`/workspaces/shop/leasing/${id}`}>
          <Typography>
            {id}
          </Typography>
        // </Link>
      )
    },
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Typography sx={{color: '#67BF3D' }}>
          <FormattedMessage id={'shop.tabs.' + status} />
        </Typography>
      )
    },
  },
  {
    name: 'number',
    title: '№ сделки / № заказа / ID Дела',
    getCellValue: ({ loanSupply  }) => {
      return (
        <Box>
          {loanSupply?.supplyingOrder?.application?.id ? (
            <Link href={`${process.env.REACT_APP_HOST}/applications/${loanSupply?.supplyingOrder?.application?.id}/deal`}>{loanSupply?.supplyingOrder?.application?.id}</Link>
          ) : (
            '--'
          )}
          {" "}/{" "}
          {loanSupply?.supplyingOrder?.id ? (
            <Link href={`${process.env.REACT_APP_HOST}/applications/${loanSupply?.supplyingOrder?.application?.id}/supplying_orders/${loanSupply?.supplyingOrder?.id}`}>{loanSupply?.supplyingOrder?.number}</Link>
          ) : '--'}
          {" "}/{" "}
          --
        </Box>
      )
    },
  },
  {
    name: 'name',
    title: 'Название',
  },
  {
    name: 'quantity',
    title: 'Кол-во',
  },
  {
    name: 'unit',
    title: 'Единиц',
  },
  {
    name: 'pricePerUnit',
    title: 'Цена за ед.',
    getCellValue: ({ pricePerUnit, vatRate}) => {
      return (
        <Box>
          {pricePerUnit > 0 ? (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}> <FormattedCurrency value={pricePerUnit} /></Typography>
          ) : (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}> -- </Typography>
          )}
          {vatRate > 0 && pricePerUnit > 0 && (
            <>
              <br />
              <Typography component={"span"} sx={{ fontWeight: 300, color: '#6E7884', }}>(+НДС <FormattedCurrency value={pricePerUnit - pricePerUnit / (1 + vatRate)} />)</Typography>
            </>
          )}
        </Box>
      )
    },
  },
  {
    name: 'purchasePrice',
    title: 'Сумма',
    getCellValue: ({ purchasePrice, vatRate }) => {
      return (
        <Box>
          {purchasePrice > 0 ? (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}><FormattedCurrency value={purchasePrice} /></Typography>
          ) : (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}> -- </Typography>
          )}

          {vatRate > 0 && purchasePrice > 0 && (
            <>
              <br />
              <Typography component={"span"} sx={{ fontWeight: 300, color: '#6E7884', }}>(+НДС <FormattedCurrency value={purchasePrice - purchasePrice / (1 + vatRate)} />)</Typography>
            </>
          )}
        </Box>
      )
    },
  },
];

export const COLUMN_EXTENSIONS_STRUCTURE_SHOP_GOODS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'name',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'print',
    align: 'center',
    width: 160,
  },
  {
    columnName: 'number',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'summa',
    wordWrapEnabled: true,
    align: 'right',
    width: 160,
  }
];

export const COLUMNS_MODEL_CREATOR_SHOP_GOODS = [
  {
    name: 'id',
    title: 'ID Товара',
    getCellValue: ({ id, shopUid }) => {
      return (
        <Link href={`/workspaces/shop/good/${id}`}>
          <Typography sx={{ wordWrap: "break-word", marginRight: "12px" }}>
            {shopUid || 'Идентификатор товара отсутствует'}
          </Typography>
        </Link>
      )
    },
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      let color;

      switch (status) {
        case 'TRASH' :
          color = "red"
          break;
        case  'READY_FOR_SALE' :
          color = "#67BF3D"
          break;
        case  'SALE' :
          color = "#3651DE"
          break;
        case  'SOLD' :
          color = "rgb(109, 107, 105)"
          break;

        default:
          color = "rgba(0, 0, 0, 0.87)"
      }


      return (
        <Typography sx={{ color }}>
          <FormattedMessage id={'shop.tabs.' + status} />
        </Typography>
      )
    },
  },
  {
    name: 'name',
    title: 'Название',
  },
  {
    name: 'print',
    title: ' ',
  },
  {
    name: 'number',
    title: '№ сделки / № заказа / ID дела',
    getCellValue: ({ shippedEquipment, storageAcceptance }) => {
      return <GoodAdditionalInfo goodInfo={{ shippedEquipment, storageAcceptance }}/>;
    }
  },
  {
    name: 'summa',
    title: 'Цена',
    getCellValue: ({ priceWithDiscount, vatPercent }) => {
      const vatRate  = vatPercent / 100;
      const trueVatWithRound =  (priceWithDiscount * vatRate / (1 + vatRate)).toFixed(0);

      return (
        <Box>
          {priceWithDiscount > 0 ? (
            <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}><FormattedCurrency minimumFractionDigits={0} value={parseFloat(priceWithDiscount).toFixed(0)} /></Typography>
          ) : (
            '--'
          )}

          {vatPercent > 0 && priceWithDiscount > 0 && (
            <>
              <br />
              <Typography component={"span"} sx={{ fontWeight: 300, color: '#6E7884', }}>(в т.ч. НДС <FormattedCurrency minimumFractionDigits={0} value={trueVatWithRound} />)</Typography>
            </>
          )}
        </Box>
      )
    },
  }
];
export const COLUMN_MODEL_STRUCTURE_SHOP_GOOD = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'center',
    width: 52,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
  }
];

export const COLUMNS_MODEL_CREATOR_SHOP_GOOD = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Box sx={{ color: status === "SHIPPED"? "#67BF3D": null }}>
          {<FormattedMessage id={'shop.order.status.' + status} />}
        </Box>
      )
    },
  }
];

export const COLUMN_EXTENSIONS_STRUCTURE_SHOP_ORDERS = [
  {
    columnName: 'id',
    wordWrapEnabled: true,
    align: 'left',
    width: 110,
  },
  {
    columnName: 'status',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'client',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'set',
    wordWrapEnabled: true,
    align: 'center',
    width: 110,
  },
  {
    columnName: 'price',
    wordWrapEnabled: true,
    align: 'center',
    width: 150,
  },
  {
    columnName: 'data',
    wordWrapEnabled: true,
    align: 'right',
    width: 150,
  },
  {
    columnName: 'action',
    wordWrapEnabled: true,
    align: 'right',
    width: 80,
  },
];

export const COLUMNS_MODEL_CREATOR_SHOP_ORDERS = [
  {
    name: 'id',
    title: '№ Заявки',
    getCellValue: ({ id }) => {
      return (
        <Link href={`/workspaces/shop/orders/details/${id}`}>
          {id}
        </Link>
      )
    },
  },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => {
      return (
        <Box>
          {status ? (
            <FormattedMessage id={`shop.order.status.${status}`} />
          ) : (
            '--'
          )}
        </Box>
      )
    },
  },
  {
    name: 'client',
    title: 'Клиент',
    getCellValue: ({ contact }) => {
      return (
        <Box>
          <Typography>{contact?.firstName} {contact?.middleName} {contact?.lastName}</Typography>
          <br />
          <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
            <Phone phone={contact?.phone} />

            <WazzupLink contactId={contact?.id}/>
          </Box>
        </Box>
      )
    },
  },
  {
    name: 'price',
    title: 'Стоимость',
    getCellValue: ({ amount }) => {
      return (
        <Box>
          <Typography component={"span"} sx={{ fontWeight: 500, color: '#0D1D32', }}> <FormattedCurrency value={amount} /></Typography>
        </Box>
      )
    },
  },
  {
    name: 'data',
    title: 'Дата обращения',
    getCellValue: ({ createdAt }) => {
      return (
        <Box>
          {createdAt ? (
            dayjs(createdAt).format(DATE_TIME_FORMAT_VIEW)
          ) : (
            "--"
          )}
        </Box>
      )
    },
  },
];
/* end table */

export const calcAllCount = tabs => {
  let allCount = 0;

  tabs.forEach( el => {
    allCount += el.count
  })

  return allCount
}

export const fI = (tabs, type) => {
  if (findIndex(tabs, ['status', type]) !== -1) {
    return tabs[findIndex(tabs, ['status', type])].count;
  } else {
    return 0
  }
}

export const PAGING_PANEL_MESSAGES = {
  showAll: 'Все',
  rowsPerPage: 'Заказов на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Заказов нет';
    }

    return to === count
      ? `Заказов: ${to - from + 1} (всего ${count})`
      : `Заказы ${from}-${to} (всего ${count})`;
  },
};