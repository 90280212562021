import PropTypes from "prop-types";
import React, {useMemo, useState} from 'react';

import {Box, Dialog, DialogContent, DialogTitle, IconButton, List, SvgIcon} from '@material-ui/core';

import NestedMenuItem from './NestedMenuItem';
import SingleMenuItem from './SingleMenuItem';
import { MenuItemsProps } from '../types';

AppMenu.propTypes = {
  countTasks: PropTypes.object,
  items: MenuItemsProps,
  setUrlIframe: PropTypes.func,
};

AppMenu.defaultProps = {
  countTasks: {
    past: 0,
    today: 0,
  },
}

export default function AppMenu({ countTasks, items }) {
  const styles = useMemo(() => ({
    menu: {
      pb: 4,
      height: 'calc(100vh - 80px)',
      overflow: 'auto',
      scrollbarColor: 'rgba(33, 150, 243, 0.7) transparent',
      '&::-webkit-scrollbar': { width: 6, height: 6 },
      '&::-webkit-scrollbar-track': { background: 'none' },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(33, 150, 243, 0.7)',
        borderRadius: 6,
        '&:hover': { background: '#2196f3' }
      }
    },
    modal: {
      '& .MuiDialog-paper': {
        minWidth: '1193px',
        minHeight: '600px',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      },
      '& .MuiDialogContent-root': {
        '& iframe': {
          minHeight: '600px',
          border: "1px solid #E0E0E0",
        }
      },
    },
  }), [])

  const [showModalWazzup, setShowModalWazzup] = useState(false)
  const onToggleModalWazzup = e => {
    e.preventDefault()

    setShowModalWazzup(!showModalWazzup);
  }

  const totalCount = () => {
    return countTasks.past + countTasks.today
  }

  if (!items?.length) {
    return null;
  }

  return (
    <>
      <List disablePadding sx={styles.menu}>
        {items.map((item, idx) => {
          const props = {}

          if (item?.title === 'Messenger') {
            props.onClick = onToggleModalWazzup
          }

          return (
            <li key={item?.url || idx} style={{ position: 'relative' }} {...props}>
              {item?.children
                ? <NestedMenuItem {...item} />
                : <SingleMenuItem title={item?.title} url={item?.url} />
              }

              {item.title === 'To Do' && (
                <Box
                  component={'span'}
                  sx={{
                    background: '#d9534f',
                    position: 'absolute',
                    top: '8px',
                    right: '10px',
                    padding: '4px 6px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    lineHeight: '12px',
                    color: '#FFF',
                    borderRadius: '10px',
                  }}
                >
                  {totalCount()}
                </Box>
              )}
            </li>
          )
        })}
      </List>

      <Dialog
        fullWidth
        onClose={onToggleModalWazzup}
        open={showModalWazzup}
        scroll="body"
        sx={ styles.modal }
      >
        <DialogTitle sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          <IconButton aria-label="close" onClick={onToggleModalWazzup}>
            <SvgIcon>
              <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
            </SvgIcon>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe height="100%" src={`${process.env.REACT_APP_CLOUD_HOST}/wazzup`} style={{border: 'none', overflow: 'hidden'}} width="100%"></iframe>
        </DialogContent>
      </Dialog>
    </>
  )
}