import { gql } from '@apollo/client/core';

import { INVOICE_FRAGMENT } from "../fragments";

export const INVOICES_CHANGE_STATUS = gql`
  ${INVOICE_FRAGMENT}
  mutation invoicesChangeStatus (
    $invoiceId: ID!
    $transition: InvoiceTransition!
  ) {
    invoicesChangeStatus (
      invoiceId: $invoiceId
      transition: $transition
    ) {
      invoice {
        ...InvoiceFragment
      }
    }
  }
`;