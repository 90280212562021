import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import _pick from 'lodash.pick';

import { SimpleMenu } from 'components/SimpleMenu';

import {
  CREATE_SCHEDULE_ITEM,
} from '../graphql/mutations/financeCreateScheduleItem';
import {
  DELETE_SCHEDULE_ITEM,
} from '../graphql/mutations/financeDeleteScheduleItem';

export default function TimelineItemMenu({
  options = [],
  paymentData,
  scheduleId,
}) {
  const [deleteScheduleItem, deletedItem] = useMutation(DELETE_SCHEDULE_ITEM, {
    variables: { scheduleId, key: paymentData.key },
    onError: (error) => {
      alert(`Произошла ошибка: ${error}`);
    },
  });
  const [createScheduleItem, newItem] = useMutation(CREATE_SCHEDULE_ITEM, {
    onError: (error) => {
      alert(`Произошла ошибка: ${error}`);
    },
  });

  const handleDeleteClick = () => {
    deleteScheduleItem();
  };

  const handleCreateClick = () => {
    const item = _pick(paymentData, [
      'type',
      'baseAmount',
      'commissionAmount',
      'vatAmount',
      'startAt',
    ]);

    createScheduleItem({
      variables: {
        scheduleId,
        item,
      },
    });
  };

  return (
    <SimpleMenu
      menuItems={[
        <MenuItem
          disabled={newItem.loading}
          key={1}
          onClick={handleCreateClick}
        >
          <Typography noWrap variant="inherit">
            Копировать платеж
          </Typography>
          {/* <ListItemIcon>
            {deletedItem.loading ? <Delete fontSize="small" /> : <
          </ListItemIcon> */}
        </MenuItem>,
        <MenuItem
          disabled={deletedItem.loading}
          key={2}
          onClick={handleDeleteClick}
        >
          <Typography noWrap variant="inherit">
            Удалить
          </Typography>
          {/* <ListItemIcon>
            {deletedItem.loading ? <Delete fontSize="small" /> : <
          </ListItemIcon> */}
        </MenuItem>,
      ]}
    />
  );
}

TimelineItemMenu.propTypes = {
  options: PropTypes.array,
  paymentData: PropTypes.object,
  scheduleId: PropTypes.string,
};
