import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { Typography } from '@material-ui/core';

import ApplicationData from './ApplicationData';
import { AssignProcessContext } from './AssignProcessContext';
import { CATEGORY_DOCUMENTS } from "../constants";
import { ViewDocument } from '../helpers';
import SupplyingOrders from '../SupplyingOrders';

AssignDocumentDetails.propTypes = {
  orderId: PropTypes.string,
};

export default function AssignDocumentDetails({ orderId }) {
  const {
    assignedObject,
    fetchingSupplyingOrders,
    refetchSupplyingOrders,
    supplyingEntityContracts,
  } = useContext(AssignProcessContext);

  const previewNode = useMemo(
    () => (assignedObject ? <ViewDocument uri={assignedObject.documentUrl} /> : null),
    [assignedObject],
  );

  const renderTitle = () => {
    let title;

    switch (assignedObject?.type?.category) {
      case CATEGORY_DOCUMENTS.loanSupply:
        title = "Договоры поставки и отгрузки";
        break;

      case CATEGORY_DOCUMENTS.applicationSupplyingOrder:
        title = "Уведомление о готовности к отгрузке";
        break;

      case CATEGORY_DOCUMENTS.dispute:
        title = "Дела";
        break;

      default:
        title = "Неопознанный тип";
    }

    return title;
  }

  return (
    <React.Fragment>
      {orderId ? (
        <React.Fragment>
          <Typography
            component="h2"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            variant="h2"
          >
            {renderTitle()}
          </Typography>

          {assignedObject?.type?.category === CATEGORY_DOCUMENTS.loanSupply && (
            <>
              <ApplicationData />
              <SupplyingOrders
                data={supplyingEntityContracts}
                loading={fetchingSupplyingOrders}
                refetchData={refetchSupplyingOrders}
              />
            </>
          )}
        </React.Fragment>
      ) : null}
      {previewNode}
    </React.Fragment>
  );
}
