import PropTypes from 'prop-types';
import React from 'react';

import { Box, Divider, Toolbar, Typography } from '@material-ui/core';

LayoutResolution.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
};

export default function LayoutResolution({ actions, children }) {
  return (
    <>
      <Divider />
      <Toolbar disableGutters>
        <Typography variant="h3">Резолюция к выписке</Typography>
        <Box display="flex" ml={3}>
          {actions}
        </Box>
      </Toolbar>
      {children}
    </>
  );
}
