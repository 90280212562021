import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { DatePickerBox } from 'components/DatePickerBox';

export const AssignReceivingDateField = ({ date, documentStatus, onChange, required, title }) => {
  const [newDate, setNewDate] = useState(date);
  const [hasDateError, setHasDateError] = useState(false);

  const handleChangeDate = value => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      dateValue = dayjs(value).format();
    }

    setNewDate(dateValue);
  }

  useEffect(() => {
    if (onChange instanceof Function) {
      onChange(!hasDateError ? newDate : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDate, hasDateError]);

  return (
    <DatePickerBox
      label={title}
      onChange={handleChangeDate}
      onError={setHasDateError}
      required={required}
      value={newDate ? new Date(newDate) : newDate}
    />
  );
};

AssignReceivingDateField.propTypes = {
  date: PropTypes.string,
  documentStatus: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
};

AssignReceivingDateField.defaultProps = {
  title: "Дата документа",
  date: null,
}