import { gql } from '@apollo/client/core';

export const UPDATE_SCHEDULE_ITEM = gql`
  mutation FinanceUpdateScheduleItem(
    $scheduleId: ID!,
    $key: ID!,
    $item: ScheduleItemUpdateInput!
  ) {
    financeUpdateScheduleItem(
      scheduleId: $scheduleId,
      key: $key,
      item: $item
    ) {
      item {
        key
        type
        baseAmount
        commissionAmount
        fullAmount
        vatAmount
        paid
        startAt
      }
    }
  }
`;