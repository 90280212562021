import { gql } from '@apollo/client/core';

export const CANCEL_SCHEDULE_PAYMENT = gql`
  mutation FinanceCancelScheduleItemPayment($key: ID!) {
    financeCancelScheduleItemPayment(key: $key) {
      schedule {
        id
        items {
          key
          type
          paid
        }
      }
    }
  }
`;