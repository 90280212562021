import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

CellOriginal.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default function CellOriginal({ value }) {
  const { color, icon, label } = useMemo(() => ({
    color: value ? 'green' : orange[700],
    icon: value ? <CheckIcon /> : <WarningIcon />,
    label: value ? 'Оригинал' : 'Скан',
  }), [value]);

  return (
    <Typography
      alignItems="center"
      color={color}
      display="flex"
      fontWeight="bold"
    >
      {icon}
      <Typography sx={{ display: { md: 'block', xs: 'none' } }}>
        &nbsp;{label}
      </Typography>
    </Typography>
  );
}
