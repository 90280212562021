import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {
  Box,
  Typography,
  Link
} from '@material-ui/core';
import {FormattedMessage} from "react-intl";

import AvatarUser from "../../components/AvatarUser";

UserInformation.propTypes = {
  onToggleModal: PropTypes.func,
  row: PropTypes.object,
};

export default function UserInformation({ onToggleModal, row }) {
  let deputy = row.deputy;

  const [show, setShow] = useState(true);
  const onShowModal = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }

    if (onToggleModal && typeof onToggleModal === 'function') {
      onToggleModal(show, row?.deputy?.id);
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
      <Box onClick={onShowModal} sx={{ cursor: 'pointer' }}>
        <AvatarUser avatar={deputy.avatar} firstName={deputy.contact?.firstName} lastName={deputy.contact?.lastName} />
      </Box>

      <Box sx={{marginLeft: '20px'}}>
        <Link href={`/collaborators/holiday/${deputy?.id}`}>
          <Typography>
            {deputy.contact?.firstName ? deputy.contact.firstName : null}
            {" "}
            {deputy.contact?.middleName ? deputy.contact.middleName : null}
            {" "}
            {deputy.contact?.lastName ? deputy.contact.lastName : null}
          </Typography>
        </Link>

        {deputy?.roles.map((element, index) => (
           <Typography key={index}>
             <FormattedMessage id={`user.role.${element}`} />
           </Typography>
        ))}
      </Box>
    </Box>
  );
}