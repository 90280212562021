/* eslint-disable react/prop-types */
import React, { useContext, useMemo } from 'react';

import { Box } from '@material-ui/core';
import { Page } from 'react-pdf';

import { PDFRendererContext } from '../state';

export const PDFRendererSinglePage = ({ pageNumber }) => {
  const {
    state: {
      currentPage,
      mainState,
      numPages,
      paginated,
      rotationDegree,
      zoomLevel,
    },
  } = useContext(PDFRendererContext);

  const rendererRect = useMemo(
    () => mainState?.rendererRect || null,
    [mainState],
  );

  const pageNum = useMemo(
    () => pageNumber || currentPage,
    [pageNumber, currentPage],
  );

  return (
    <PageWrapper>
      {!paginated && <PageTag>Страница {pageNum} / {numPages}</PageTag>}
      <Page
        height={(rendererRect?.height || 100) - 100}
        pageNumber={pageNum || currentPage}
        rotate={rotationDegree}
        scale={zoomLevel}
        width={(rendererRect?.width || 100) - 100}
      />
    </PageWrapper>
  );
};

const PageWrapper = ({ children, ...rest }) => (
  <Box {...rest} sx={{ margin: '20px 0' }}>
    {children}
  </Box>
);

const PageTag = ({ children, ...rest }) => (
  <Box
    {...rest}
    sx={{
      p: '0 0 10px 10px',
      fontSize: { xs: 10, md: 14 },
    }}
  >
    {children}
  </Box>
);