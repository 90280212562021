import { gql } from '@apollo/client/core';

export const GET_FEDRESURS_REPORTING_MESSAGES = gql`
  query FedresursReportingMessages(
    $limit: Int
    $page: Int
    $filter: FedresursReportingMessageFilter
    $order: SortOrder
  ) {
    fedresursReportingMessages(limit: $limit, page: $page, filter: $filter, order: $order) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        application {
          id
          borrowerEntity {
            shortLegalName
          }
          supplyingEntityContracts {
            id
            amount
            date
            supplies {
              id
              date
              amount
            }
          }
        }
        classificators {
          code
          name
        }
        createdAt
        id
        fedresursMessageId
        kind
        sentAt
        state
        updatedAt
        description
        endDate
        startDate
        contractNumber
        contractDate
      }
    }
  }
`;