import { gql } from '@apollo/client/core';

export const GET_SHOP_GOODS = gql`
  query ShopGoods (
    $page: Int,
    $limit: Int,
    $sort: Sort,
    $filter: ShopGoodFilter!
  ) {
    shopGoods (
      page: $page,
      limit: $limit,
      sort: $sort,
      filter: $filter
    ) {
      goods {
        collection {
          id
          name
          priceWithDiscount
        }
        metadata {
          totalCount
        } 
      }
    }
  }
`;