import PropTypes from 'prop-types';
import React, { useState, useCallback, Fragment } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';

import DropzoneImages from 'components/DropzoneImages';

import { FEDRESURS_PROCESS_REPORTING_XML } from './graphql/mutations/fedresursProcessReportingXml';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  files: {
    '& > div': {
      flexWrap: 'wrap'
    }
  },
  wrapperUpImg: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '24px',
  },
  blockImg: {
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #E7E8EA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '142px',
    height: '142px',

    '& .index': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .remove': {
      cursor: 'pointer',
      position: 'absolute',
      left: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '40px',
      height: 'auto'
    },
    'span': {
      display: 'block',
      position: 'absolute',
      bottom: '4px',
      left: '4px',
      right:'4px',
      width: 'calc(100% - 8px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '10px'
    }
  }
};

export default function ProcessReportingXml({ handleClose }) {
  const [files, setFiles] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [processReportingXml] = useMutation(
    FEDRESURS_PROCESS_REPORTING_XML, {
    onError: alert,
    onCompleted: (data) => {
      setLoaded(true);
    },
  });

  const startProcessReporting = () => {
    processReportingXml({
      variables: {
        xmlFiles: files
      }
    })
  };

  const onFilesDropped = useCallback((evt) => {
    const newFiles = [...files, ...evt];

    setFiles(newFiles);
  }, [files]);

  const handleDownloadedDeleteFile = (id) => {
    const newFiles = [...files];
    const notLoadedRemove = newFiles.findIndex((element) => (element?.uploaded === false && element?.imageId === id));

    if(notLoadedRemove !== -1) {
      newFiles.splice(notLoadedRemove, 1);
      setFiles(newFiles);
    }
  };

  return (
    <Dialog fullWidth open={true} scroll="body">
      {loaded ? (
        <Fragment>
          <DialogTitle disableTypography sx={styles.header}>
            <Box>
              <Typography>Результаты распознавания будут направлены на вашу почту</Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogActions sx={{ padding: '16px 24px', justifyContent: 'space-between' }}>
            <Button
              onClick={handleClose}
              variant="outlined"
            >
              Закрыть
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogTitle disableTypography sx={styles.header}>
            <Box>
              <Typography>Загрузить XML</Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{width: '100%'}}>
              <DropzoneImages
                accept="text/xml, application/xml"
                onFilesDropped={onFilesDropped}
              />
            </Box>
            {files && files.length > 0 && (
              <Box sx={ styles.wrapperUpImg }>
                {files.map((fileItem, index) => (
                  <Box key={index} sx={styles.blockImg}>
                  <Box className={'remove'} onClick={handleDownloadedDeleteFile.bind(this, fileItem?.attachmentId ? parseInt(fileItem.attachmentId, 10) : fileItem.imageId)}>x</Box>
                  <Box className={'index'}>{index + 1}</Box>
                  <AttachFile sx={{fill: '#ccc'}} />
                  <span>{fileItem?.file?.name || fileItem?.name || fileItem.filename}</span>
                  </Box>
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: '16px 24px', justifyContent: 'space-between' }}>
            <Button
              onClick={handleClose}
              variant="outlined"
            >
              Отмена
            </Button>
            <Button
              onClick={startProcessReporting}
              sx={{ color: 'white' }}
              variant="contained"
            >
              Загрузить
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

ProcessReportingXml.propTypes = {
  handleClose: PropTypes.func,
};
