import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import {
  Box,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DoneIcon from '@material-ui/icons/Done';
import dayjs from "dayjs";

import connection from './images/connection.svg';
import pen from './images/pen.svg';

ActionsHoliday.propTypes = {
  onDeleteHoliday: PropTypes.func,
  onLinkHoliday: PropTypes.func,
  onToggleModal: PropTypes.func,
  onUnlinkHoliday: PropTypes.func,
  row: PropTypes.object,
};

export default function ActionsHoliday({ onDeleteHoliday, onLinkHoliday, onToggleModal, onUnlinkHoliday, row }) {
  const styles = useMemo(() => ({
    typeAction: {
      display: 'flex',
      alignItems: 'center',
      color: '#3D6CE7',
      marginRight: '26px',
      cursor: 'pointer',
      fontSize: '14px !important',

      '& img': {
        marginRight: '6px',
        width: '24px',
        height: '24px',
        padding: '4px',
      },
      '& svg': {
        marginRight: '6px',
      },
      '&:last-child': {
        marginRight: 0,
      }
    }
  }), []);
  const [ showButtons, setShowButtons ] = useState({
    editButtons: false,
    deleteButtons: false,
    cancelButtons: false,
    handOverButtons: false,
  })

  const onShowModal = () => {
    if (onToggleModal && typeof onToggleModal === 'function') {
      onToggleModal(true, row.id)
    }
  };

  const linkHoliday = () => {
    if (onLinkHoliday && typeof onLinkHoliday === 'function') {
      onLinkHoliday(row.id);
    }
  }

  const unlinkHoliday = () => {
    if (onUnlinkHoliday && typeof onUnlinkHoliday === 'function') {
      onUnlinkHoliday(row.id);
    }
  }

  const deleteHoliday = () => {
    if (onDeleteHoliday && typeof onDeleteHoliday === 'function') {
      onDeleteHoliday(row.id);
    }
  }

  useEffect(() => {
    if (row) {
      const newShowButtons = { ...showButtons };
      let nowDate = dayjs().startOf("day");

      if (dayjs(row?.startDate) <= nowDate && nowDate <= dayjs(row?.endDate) && row?.deputy?.id && row?.userTasksStatus === 'USER_TASKS_UNLINKED') {
        newShowButtons.handOverButtons = true;
      }

      if (dayjs(row?.startDate) <= nowDate && nowDate === dayjs(row?.endDate) && row?.deputy?.id && row?.userTasksStatus === 'USER_TASKS_UNLINKED') {
        newShowButtons.handOverButtons = true;
        newShowButtons.editButtons = true;
      }

      if ((dayjs(row?.startDate) < nowDate || dayjs(row?.endDate) <= nowDate) && row?.userTasksStatus === 'USER_TASKS_LINKED') {
        newShowButtons.cancelButtons = true;
      }

      if (row?.userTasksStatus === 'USER_TASKS_LINKED') {
        newShowButtons.cancelButtons = true;
      }

      if (row?.userTasksStatus === 'USER_TASKS_UNLINKED' && nowDate <= dayjs(row?.endDate)) {
        newShowButtons.editButtons = true;
      }

      if (dayjs(row?.startDate) > nowDate && dayjs(row?.startDate) < nowDate && row?.userTasksStatus === 'USER_TASKS_UNLINKED') {
        newShowButtons.deleteButtons = true;
      }

      if (dayjs(row?.startDate) > nowDate) {
        newShowButtons.deleteButtons = true;
      }

      if (dayjs(row?.startDate) <= nowDate && row?.deputy?.id && row?.userTasksStatus === 'USER_TASKS_UNLINKED' && row?.kind === "DISMISSAL") {
        newShowButtons.handOverButtons = true;
      }

      setShowButtons(newShowButtons)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row])

  return (
    <Box key={row.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
      {showButtons.cancelButtons && (
        <>
          <Typography sx={{...styles.typeAction, color: '#67BF3D', cursor: 'default'}}>
            <DoneIcon sx={{fontSize: '20px !important'}}/>
            Переданы
          </Typography>

          <Typography
            onClick={unlinkHoliday}
            sx={{...styles.typeAction, color: '#6E7884'}}
          >
            <CloseIcon sx={{fontSize: '20px !important'}}/>
            Отменить
          </Typography>
        </>
      )}

      {showButtons.handOverButtons && (
        <Typography
          onClick={linkHoliday}
          sx={styles.typeAction}
        >
          <Box alt={'Передать отпуск'} component={"img"} src={connection} />
          Передать
        </Typography>
      )}

      {showButtons.editButtons && (
        <Typography
          onClick={onShowModal}
          sx={styles.typeAction}
        >
          <Box alt={'Изменить отпуск'} component={"img"} src={pen} />
          Изменить
        </Typography>
      )}

      {showButtons.deleteButtons && (
        <Typography
          onClick={deleteHoliday}
          sx={{ ...styles.typeAction, color: '#d9534f' }}
        >
          <DeleteForeverOutlinedIcon sx={{fontSize: '20px !important'}}/>
          Удалить
        </Typography>
      )}
    </Box>
  );
}