import { gql } from '@apollo/client/core';

import { TASK_FRAGMENT } from '../fragments';

export const GET_TASKS_TODO = gql`
  query GetTasksToDo(
      $filters: TasksFilter,
  ) {
    tasksToDo (
        filters: $filters
    ) {
      past {
        ...TaskFragment
      }
      today {
        ...TaskFragment
      }
      tomorrow {
        ...TaskFragment
      }
      further {
        ...TaskFragment
      }
    }
  }
  ${TASK_FRAGMENT}
`;