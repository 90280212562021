import { gql } from '@apollo/client/core';

export const FINMONITORING_GENERATE_XML = gql`
  mutation FinmonitoringGenerateXmlByReportingMessage($messageId: ID!) {
    finmonitoringGenerateXmlByReportingMessage(
      finmonitoringReportingMessageId: $messageId
    ) {
      downloadUrl
      problems {
        context
        messages
        path
        type
      }
    }
  }
`;