import { gql } from '@apollo/client/core';

export const ACTIVATE_DRAFT_SCHEDULE = gql`
  mutation FinanceActivateSchedule($scheduleId: ID!) {
    financeActivateSchedule(scheduleId: $scheduleId) {
      schedule {
        id
        state
        createdAt
        stornedAt
        activatedAt
      }
    }
  }
`;