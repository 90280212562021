import React, {useContext} from 'react';

import {Box, Typography} from "@material-ui/core";

import CollaboratorsStructureUsers from "./CollaboratorsStructureUsers";
import {CollaboratorsContext} from "../CollaboratorsContext";
import CollaboratorsTabs from "../CollaboratorsTabs";
import { TABS } from "../constants";
import PageGraphHolidays from "../PageGraphHolidays";
import PageTemplate from "../PageTemplate";

export default function PageCollaborators() {
  const { state } = useContext(CollaboratorsContext);

  return (
    <Box sx={{paddingBottom: '20px', height: '100%', overflow: 'auto'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 24px', backgroundColor: '#ffffff', height: '100px' }}>
        <Typography gutterBottom sx={{ fontSize: '32px !important', margin: '0 !important', width: '140px', }} variant="h2">
          Сотрудники
        </Typography>
      </Box>

      <CollaboratorsTabs />

      <Box sx={{
        display: 'flex',
        margin: '24px',
        padding: '24px',
        background: '#ffffff',
        borderRadius: '16px',
        boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.12)'
      }}>

        {state.filter.tab === TABS[0] && (
          <CollaboratorsStructureUsers />
        )}

        {state.filter.tab === TABS[1] && (
          <PageTemplate />
        )}

        {state.filter.tab === TABS[2] && (
          <PageGraphHolidays />
        )}

      </Box>
    </Box>
  );
}