import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Dialog,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { navigate, useLocation } from '@reach/router';
import { pick, cloneDeep } from 'lodash';
import qs from 'querystringify';

import { GridRoot, Loader } from './helpers';
import { TaskDateTypeProvider } from './helpers/taskDataProviders';
import TasksDashboardFilter from "./TasksDashboard/TasksDashboardFilter";
import CommentList from "../../../components/CommentList";
import ShowCommentTask from "../components/helpers/ShowCommentTask"
import {
  TASKS_PAGE_LIMIT,
  columnExtensions,
  columnsModelCreator,
  columnsModelCreatorStyle,
  pagingPanelMessages,
} from '../constants';
import { GET_TASKS } from '../graphql/queries/tasks';
import { TasksFilterContext } from '../TasksFilterContext';

TasksTable.propTypes = {
  setError: PropTypes.func,
};

export const DocumentActionTypeProviderShowCommentTask = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ShowCommentTask {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export default function TasksTable({ setError }) {
  const { updateState: updateFilterState } = useContext(TasksFilterContext);

  const [fetchTasks, { data, error, loading }] = useLazyQuery(GET_TASKS);

  const location = useLocation();

  useEffect(() => {
    updateFilterState({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { currentPage, pageSize, sorting } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      currentPage: query.page ? Number(query.page) - 1 : 0,
      pageSize: query.limit ? Number(query.limit) : TASKS_PAGE_LIMIT,
      sorting: [
        {
          fieldName: query.sort_by || 'dueAt',
          direction: query.order || 'desc',
        },
      ],
    };
  }, [location.search]);

  const [sortingState, setSortingState] = useState([
    {
      columnName: 'dueAt',
      direction: 'desc',
    },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [sortingStateColumnExtensions] = useState([
    { columnName: 'target', sortingEnabled: false },
    { columnName: 'title', sortingEnabled: false },
    { columnName: 'assignee', sortingEnabled: false },
    { columnName: 'status', sortingEnabled: false },
    { columnName: 'description', sortingEnabled: false },
    { columnName: 'notes', sortingEnabled: false },
  ]);

  const updateSearchLocation = useCallback(
    (params) => {
      const parsedQuery = qs.parse(location.search);
      const query = { ...parsedQuery, ...params };
      navigate(qs.stringify(query, true));
    },
    [location.search],
  );

  const handleSortingChange = useCallback(
    (sortingChanges) => {
      const processedSortingChanges = sortingChanges.map(({ columnName, direction }) => ({
        fieldName: columnName,
        direction: columnName === sorting[0].fieldName ? direction : 'desc',
      }));

      const [sortingConfig] = processedSortingChanges.map(({ direction, fieldName }) => ({
        sort_by: fieldName,
        order: direction,
      }));

      updateSearchLocation(sortingConfig);
    },
    [sorting, updateSearchLocation],
  );

  const handleCurrentPageChange = useCallback(
    (page) => {
      updateSearchLocation({ page: page + 1 });
    },
    [updateSearchLocation],
  );

  const handlePageSizeChange = useCallback(
    (value) => {
      updateSearchLocation({ limit: value === 0 ? totalCount : value });
    },
    [updateSearchLocation, totalCount],
  );

  const [dateColumns] = useState(['dueAt', 'createdAt']);

  useEffect(() => {
    const parsedQuery = qs.parse(location.search);

    const { filters, limit, order, page, q, sort_by } = pick(parsedQuery, [
      'limit',
      'order',
      'page',
      'q',
      'sort_by',
      'filters',
    ]);

    const fetchVariables = {
      page: Number(page || 1),
      limit: Number(limit || TASKS_PAGE_LIMIT),
      sort: { field: 'DUE_AT', order: 'DESC' },
    };

    if (sort_by && order) {
      setSortingState([{ columnName: sort_by, direction: order }]);

      fetchVariables['sort'] = {
        field:
          {
            dueAt: 'DUE_AT',
            createdAt: 'CREATED_AT',
          }[sort_by] || 'DUE_AT',
        order: { asc: 'ASC', desc: 'DESC' }[order] || 'DESC',
      };
    }

    if (filters) {
      if (filters.search("createdById") !== -1 || filters.search("assigneeId") !== -1) {
        updateFilterState({});
        return
      };
      const jsonFilters = JSON.parse(filters) || {};
      updateFilterState(jsonFilters);

      const modJsonFilters = { ...jsonFilters };
      if (modJsonFilters?.assignee) {
        delete modJsonFilters.assignee;
        modJsonFilters.assigneeId = jsonFilters.assignee.id;
      }

      if (modJsonFilters?.creator) {
        delete modJsonFilters.creator;
        modJsonFilters.createdById = jsonFilters.creator.id;
      }

      fetchVariables['filters'] = modJsonFilters;
    }

    if (q) {
      let filters = cloneDeep(fetchVariables.filters);

      if (!filters) {
        filters = {}
      }

      filters.titleOrDescription = q;

      fetchVariables['filters'] = filters;
    }

    fetchTasks({ variables: fetchVariables });
  }, [fetchTasks, location.search, updateFilterState]);

  useEffect(() => {
    if (data) {
      const { tasks } = data;
      setRows(tasks.collection);
      setTotalCount(tasks.metadata.totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (setError instanceof Function) {
      setError(error || null);
    }
  }, [error, setError]);

  const [isShowModal, setIsShowModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const showModalComments = (content) => {
    setMessageModal(content)
    setIsShowModal(true)
  }

  const handleClose = () => {
    setIsShowModal(false)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Box sx={{ flex: 0.999, minHeight: 0 }}>
        <TasksDashboardFilter />
        <Box
          sx={{
            border: '8px solid whitesmoke',
            borderRadius: '8px',
            height: 1,
          }}
        >
          <Box sx={{ px: 3, height: 1 }}>
            <Grid
              columns={columnsModelCreator}
              rootComponent={GridRoot}
              rows={rows}>
              <DocumentActionTypeProviderShowCommentTask
                for={['notes']}
                showModalComments={showModalComments}
              />
              <SortingState
                columnExtensions={sortingStateColumnExtensions}
                onSortingChange={handleSortingChange}
                sorting={sortingState}
              />
              <PagingState
                currentPage={currentPage}
                defaultPageSize={pageSize}
                onCurrentPageChange={handleCurrentPageChange}
                onPageSizeChange={handlePageSizeChange}
              />
              <CustomPaging totalCount={totalCount} />
              <TaskDateTypeProvider for={dateColumns} />
              <VirtualTable
                columnExtensions={columnExtensions}
                messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
              />
              <Table columnExtensions={columnsModelCreatorStyle}  />
              <TableHeaderRow messages={{ sortingHint: 'Сортировать' }} showSortingControls />
              <PagingPanel messages={pagingPanelMessages} pageSizes={[10, 25, 50, 100, 0]} />
            </Grid>
          </Box>
        </Box>
      </Box>

      <Dialog fullWidth open={isShowModal} scroll="body" >
        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: '16px 24px' }}>
          <Typography variant="h3" >Комментарии</Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <CommentList messages={messageModal || []} />
      </Dialog>
    </>
  );
}
