import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';

const getRowId = (row) => row.id;

SubjectsFormatter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.any,
  }),
  row: PropTypes.any,
  value: PropTypes.any,
};
function SubjectsFormatter({ column, row, value }) {
  const { subjects } = row;

  function renderSubject({ classifierCode, classifierName, description }, i) {
    return (
      <div key={i}>
        {classifierCode ? <span>{classifierCode}</span> : null}
        {classifierCode && classifierName ? ':' : null}
        {classifierName ? <span>{classifierName}</span> : null}
        <div style={{ fontStyle: 'italic' }}>{description}</div>
      </div>
    );
  }
  return subjects.length > 2 ? (
    <>
      {subjects.slice(0, 2).map(renderSubject)}
      <div>еще {subjects.length - 2}</div>
    </>
  ) : (
    subjects.map(renderSubject)
  );
}

TableLegalEntities.propTypes = {
  data: PropTypes.array,
};

export default function TableLegalEntities({ data = [] }) {
  const [columns] = useState([
    {
      name: 'id',
      title: '#',
    },
    {
      name: 'shortLegalName',
      title: 'Тип',
    },

    {
      name: 'tin',
      title: 'tin',
    },
    {
      name: 'psrn',
      title: 'psrn',
    },
    {
      name: 'trrc',
      title: 'trrc',
    },
    {
      name: 'createdAt',
      title: 'createdAt',
    },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'id', width: 60 },
    { columnName: 'kind', width: 180 },
    { columnName: 'dates', width: 184 },
  ]);

  const [rows] = useState(data);

  return (
    <Box mx={3}>
      <Grid columns={columns} getRowId={getRowId} rows={rows}>
        <RowDetailState />
        <VirtualTable columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
      </Grid>
    </Box>
  );
}
