import { gql } from '@apollo/client';

import { FEDRESURS_PROCESS_FRAGMENT } from '../fragments';

export const FEDRESURS_FETCHING_PROCESSES = gql`
  query FedresursFetchingProcesses($entityId: ID!) {
    fedresursFetchingProcesses(entityId: $entityId) {
      ...FedresursProcessFragment
    }
  }
  ${FEDRESURS_PROCESS_FRAGMENT}
`;