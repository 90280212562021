import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import { CollaboratorsProvider } from "./CollaboratorsContext";
import HolidayUser from "./users/HolidayUser";
import PageCollaborators from "./users/PageCollaborators";

export default function DealsRoutes() {
  return (
    <CollaboratorsProvider>
      <Box component={Router} sx={{ height: '100%',  backgroundColor: '#f3f4f5', overflow: 'auto', }}>
        <PageCollaborators path="/" />
        <HolidayUser path="/holiday/:userId" />
      </Box>
    </CollaboratorsProvider>
  );
}