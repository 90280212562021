import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEqual from 'lodash/isEqual';

import AutocompleteTaskUsers from 'components/AutocompleteTaskUsers';
import { DatePickerBox } from 'components/DatePickerBox';
import {DATE_TIME_FORMAT_NOT_Z} from 'core/constants/Formats';
import { formatDate } from 'utils/dateTimeFormatters';

import { FilterModalBox } from './helpers';
import { TasksFilterContext } from '../TasksFilterContext';

TasksFilterModal.propTypes = {
  onClose: PropTypes.func,
};

dayjs.extend(utc)

export default function TasksFilterModal({ onClose }) {
  const { state, updateState } = useContext(TasksFilterContext);

  const [persistState, setPersistState] = useState(state);
  const [hasDateError, setHasDateError] = useState(false);

  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const updateFilterState = useCallback((fieldName, value) => {
    setPersistState((prev) => ({ ...prev, [fieldName]: value }));
  }, []);

  const applyFilter = useCallback(() => {
    updateState(persistState);
    handleClose();
  }, [persistState, updateState, handleClose]);

  const resetFilter = useCallback(() => {
    setPersistState({});
  }, []);

  const handleChangeDate = useCallback((fieldName) => (value) => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      switch (fieldName) {
        case "createdAtStart":
          dateValue = dayjs(value).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z)

          break;
        case "createdAtEnd":
          dateValue = dayjs(value).utc(true).endOf("day").format(DATE_TIME_FORMAT_NOT_Z);

          break;
        case "dueAtStart":
          dateValue = dayjs(value).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z);

          break;
        case "dueAtEnd":
          dateValue = dayjs(value).utc(true).endOf("day").format(DATE_TIME_FORMAT_NOT_Z)

          break;

        default:
          dateValue = dayjs(value).utc(true).format(DATE_TIME_FORMAT_NOT_Z);
      }
    }

    updateFilterState(fieldName, dateValue);
  }, [updateFilterState]);

  return (
    <Dialog
      fullWidth
      open={open}
      scroll="body"
      sx={{ '& .MuiDialog-paper': { maxWidth: 480 } }}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Фильтр задач</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FilterModalBox title="Дата постановки">
          <DatePickerBox
            maxDate={persistState?.createdAtEnd}
            maxDateMessage={
              persistState?.createdAtEnd
                ? `Не может быть позднее ${formatDate(persistState.createdAtEnd)}`
                : undefined
            }
            onChange={handleChangeDate('createdAtStart')}
            onError={setHasDateError}
            placeholder={"с"}
            value={persistState?.createdAtStart || null}
          />
          <span style={{padding: "0 10px"}}>-</span>
          <DatePickerBox
            minDate={persistState?.createdAtStart}
            minDateMessage={
              persistState?.createdAtStart
                ? `Не может быть ранее ${formatDate(persistState.createdAtStart)}`
                : undefined
            }
            onChange={handleChangeDate('createdAtEnd')}
            onError={setHasDateError}
            placeholder={"по"}
            value={persistState?.createdAtEnd || null}
          />
        </FilterModalBox>

        <FilterModalBox title="Дата исполнения">
          <DatePickerBox
            maxDate={persistState?.dueAtEnd}
            maxDateMessage={
              persistState?.dueAtEnd
                ? `Не может быть позднее ${formatDate(persistState.dueAtEnd)}`
                : undefined
            }
            onChange={handleChangeDate('dueAtStart')}
            onError={setHasDateError}
            placeholder={"с"}
            value={persistState?.dueAtStart || null}
          />
          <span style={{padding: "0 10px"}}>-</span>
          <DatePickerBox
            minDate={persistState?.dueAtStart}
            minDateMessage={
              persistState?.dueAtStart
                ? `Не может быть ранее ${formatDate(persistState.dueAtStart)}`
                : undefined
            }
            onChange={handleChangeDate('dueAtEnd')}
            onError={setHasDateError}
            placeholder={"по"}
            value={persistState?.dueAtEnd || null}
          />
        </FilterModalBox>

        <FilterModalBox title="Исполнитель">
          <AutocompleteTaskUsers
            onChange={(value) => updateFilterState('assignee', value)}
            value={persistState.assignee || ''}
          />
        </FilterModalBox>

        <FilterModalBox title="Автор">
          <AutocompleteTaskUsers
            onChange={(value) => updateFilterState('creator', value)}
            value={persistState.creator || ''}
          />
        </FilterModalBox>

        <FilterModalBox title="Статус">
          <Select
            displayEmpty
            onChange={({ target: { value } }) => {
              updateFilterState('status', value);
            }}
            sx={{ width: '100%' }}
            value={persistState.status || ''}
          >
            <MenuItem disabled value="">Выбрать</MenuItem>
            <MenuItem value="OPENED">Открыта</MenuItem>
            <MenuItem value="COMPLETED">Завершена</MenuItem>
            <MenuItem value="CANCELED">Отменена</MenuItem>
          </Select>
        </FilterModalBox>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!Object.keys(persistState).length}
          onClick={resetFilter}
        >
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state) || hasDateError}
          onClick={applyFilter}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
}