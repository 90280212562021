import { gql } from '@apollo/client/core';

import { GOODS_FRAGMENT } from "../fragments";

export const SHOP_ORDERS_REJECT = gql`
  ${GOODS_FRAGMENT}
  mutation ShopOrdersReject (
    $orderId: ID!
    $rejectionComment: String!
  ) {
    shopOrdersReject(
      orderId: $orderId
      rejectionComment: $rejectionComment
    ) {
      order {
        id
        status
        rejectionComment
        availableGoods {
          ...GoodsFragment

          storageAcceptance {
            displayDisputeUrl
            displayDisputeNumber
          }
        }
        unavailableGoods {
          ...GoodsFragment

          orders {
            id
          }
        }
      }
    }
  }
`;
