import PropTypes from 'prop-types';
import React from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import CurrencyFormatter from 'components/FormattedCurrency';

import CompanyCategoryMarker from './CompanyCategoryMarker';

CategoriesPieChart.propTypes = {
  categories: PropTypes.any,
  companies: PropTypes.any,
};

export default function CategoriesPieChart({ categories, companies }) {
  const theme = useTheme();

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={categories}
          dataKey="categoryAmount"
          label={CustomizedLabel}
          labelLine={false}
          legendType="circle"
          outerRadius={60}
        >
          {categories.map((entry, index) => (
            <Cell fill={theme.palette.categories[entry.name]} key={index} />
          ))}
        </Pie>
        <Pie
          data={companies}
          dataKey="companyAmount"
          fill="#8884d8"
          innerRadius={70}
          label={false}
          legendType="none"
          outerRadius={90}
        />
        <Tooltip content={CustomTooltip} />
      </PieChart>
    </ResponsiveContainer>
  );
}

const RADIAN = Math.PI / 180;

CustomizedLabel.propTypes = {
  cx: PropTypes.any,
  cy: PropTypes.any,
  index: PropTypes.any,
  innerRadius: PropTypes.any,
  midAngle: PropTypes.any,
  outerRadius: PropTypes.any,
  percent: PropTypes.number,
};

function CustomizedLabel({ cx, cy, index, innerRadius, midAngle, outerRadius, percent }) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent < 0.1) {
    return null;
  }
  return (
    <Box
      component="text"
      dominantBaseline="central"
      fill="white"
      fontWeight={600}
      textAnchor="middle"
      x={x}
      y={y}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </Box>
  );
}

CustomTooltip.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.any,
};

function CustomTooltip({ active, payload, ...rest }) {
  if (active && payload[0].dataKey === 'categoryAmount') {
    return (
      <Paper component={Box} px={3} py={2}>
        <Box alignItems="center" display="flex" mb={2}>
          <CompanyCategoryMarker bgcolor={`categories.${payload[0].name}`} mr={2} />
          <Typography>
            <FormattedMessage id={`categories.${payload[0].name}`} />
          </Typography>
        </Box>
        <Typography variant="h6">
          <CurrencyFormatter value={payload[0].value} />
        </Typography>
      </Paper>
    );
  }

  if (active && payload[0].dataKey === 'companyAmount') {
    return (
      <Paper component={Box} px={3} py={2}>
        <Box alignItems="center" display="flex" mb={2}>
          <Typography>{payload[0].name}</Typography>
        </Box>
        <Typography variant="h6">
          <CurrencyFormatter value={payload[0].value} />
        </Typography>
      </Paper>
    );
  }

  return null;
}
