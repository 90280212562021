import { gql } from '@apollo/client/core';

export const GET_FMS_PASSPORT_CHECKS = gql`
  query GetFmsPassportChecks($contactId: ID!) {
    fmsPassportChecks(contactId: $contactId) {
      checkedPassportNumber
      id
      number
      series
      status
      createdAt
    }
  }
`;