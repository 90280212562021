import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import FileDownloadOutlined from '@material-ui/icons/FileDownloadOutlined';

import { useNotification } from 'components/Notifications/NotificationsProvider';

import { BANK_STATEMENT_DOWNLOAD } from './graphql/mutations/bankStatementDownload';

SaveButton.propTypes = {
  statementId: PropTypes.string
};

export default function SaveButton({
  statementId
}) {
  const { notify } = useNotification();

  const [setBlankStatementDownload] = useMutation(BANK_STATEMENT_DOWNLOAD, {
    onCompleted: (res) => {
      if(res.bankStatementDownload.status === 'ok') {
        notify(
          {
            title: 'Выписка успешно выгружена на ваш email',
            type: 'success',
          },
          'ADD_NOTIFICATION',
        );
      } else {
        notify(
          {
            title: 'Ошибка отправки выписки на email',
            type: 'error',
          },
          'ADD_NOTIFICATION',
        );
      }
    },
    onError: (error) => {
      notify(
        {
          title: 'Ошибка отправки выписки на email',
          type: 'error',
        },
        'ADD_NOTIFICATION',
      );
    },
  });

  const handleMenuItemClick = () => {
    setBlankStatementDownload({ variables: { bankStatementId: statementId } });
  };

  return (
    <IconButton
      onClick={handleMenuItemClick}
      size="small"
      sx={{margin: '0 10px', zIndex: '2147483647', minWidth: '36px', borderRadius: '4px'}}
    >
      <FileDownloadOutlined sx={{fill: 'rgb(255, 102, 0)'}} />
    </IconButton>
  );
}
