import { gql } from '@apollo/client/core';

export const TASKS_CANCEL = gql`
  mutation TasksCancel($taskId: ID!) {
    tasksCancel(taskId: $taskId) {
      task {
        id
      }
    }
  }
`;