import PropTypes from 'prop-types';
import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Typography } from '@material-ui/core';

import ContainerFedresursProcess from './ContainerFedresursProcess';
import {
  FEDRESURS_START_FETCHING_PROCESS,
} from './graphql/mutations/fedresursStartFetchingProcess';
import {
  FEDRESURS_FETCHING_PROCESSES,
} from './graphql/queries/fedresursFetchingProcesses';

PageFedresurs.propTypes = {
  entityId: PropTypes.string,
};

export default function PageFedresurs({ entityId }) {
  const queryResult = useQuery(FEDRESURS_FETCHING_PROCESSES, {
    variables: {
      entityId,
    },
  });

  const [startFedresursProcess, mutationResult] = useMutation(
    FEDRESURS_START_FETCHING_PROCESS,
    { variables: { entityId } },
  );

  if (queryResult.loading) {
    return 'loading';
  }

  const lastProcess =
    mutationResult.data?.fedresursStartFetchingProcess.fedresursFetchingProcess ||
    queryResult.data?.fedresursFetchingProcesses[0];

  function renderButtonStartFedresursProcess(label) {
    return (
      <Button
        color="inherit"
        disabled={mutationResult.loading}
        onClick={startFedresursProcess}
      >
        {label}
      </Button>
    );
  }

  if (!lastProcess) {
    return (
      <Box display="flex" flexDirection="column" p={3}>
        <Alert
          action={renderButtonStartFedresursProcess('Запросить')}
          severity="info"
        >
          <Typography variant="body1">
            Данные не запрашивались
          </Typography>
        </Alert>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" p={3}>
      <ContainerFedresursProcess
        {...lastProcess}
        renderButtonStartFedresursProcess={renderButtonStartFedresursProcess}
      />
    </Box>
  );
}
