import React, {useEffect, useState} from 'react';

import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@material-ui/core";

import { GET_WAZZUP_URL } from "./graphql/queries/getWazzupUrl";

export default function Wazzup() {
  const { data, error, loading } = useQuery(GET_WAZZUP_URL, );

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (data?.getWazzupUrl?.url?.length > 0) {
      setUrl(data?.getWazzupUrl?.url);
    }
  }, [data]);

  if (error) {
    return (
      <Box> При загрузке Wazzup возникла ошибка {error.message}</Box>
    )
  }

  if (loading) {
    return (
      <Box
        fontSize={20}
        left="calc(50% - 10px)"
        position="absolute"
        textAlign={'center'}
        top="calc(45% - 10px)"
      >
        <CircularProgress />
        <Typography sx={{ marginTop: '10px' }}>Загружаем Wazzup</Typography>
      </Box>
    )
  }

  return (
    url?.length > 0 ? (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe height="100%" src={url} width="100%"></iframe>
    ) : (
      <Typography sx={{ marginTop: '10px' }}>У данного пользователя отсуствтует ссылка на Wazzup</Typography>
    )
  )
}