import PropTypes from "prop-types";
import React, {useCallback} from "react";

import {
  Box,
  Typography
} from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {FormattedMessage} from "react-intl";
import QRCode from "react-qr-code";

import { DATE_TIME_FORMAT_VIEW } from "../../../../core/constants/Formats";

const styles = {
  wrapperBlock: {
    marginBottom: '24px'
  },
  wrapperBlockMinMargin: {
    marginBottom: '10px'
  },
  label: {
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  labelPrint: {
    fontSize: '22px',
    fontWeight: 700,
  },
  valuePrint: {
    fontSize: '18px',
    fontWeight: 300,
  },
  divider: {
    height: '4px',
    margin: '0 0 16px',
    background: '#000'
  },
  qr: {
    minWidth: '200px'
  }
}

dayjs.extend(utc);

const PrintOutput = React.forwardRef(function PrintOutput (props, ref) {
  const qualityValue = useCallback((quality) => {
    if(quality && ['GOOD', 'PERFECT', 'NEWEST', 'SATISFACTORY', 'NONE'].includes(quality)) {
      return <FormattedMessage id={`shop.status.${quality}`} />;
    }
  
    return <FormattedMessage id={'shop.status.WRONG'} />;
  }, []);

  const elShowDateFormatted = useCallback((date) => {
    if(date && dayjs(date).format(DATE_TIME_FORMAT_VIEW) !== 'Invalid Date') {
      return (
        dayjs(date).format(DATE_TIME_FORMAT_VIEW)
      );
    }

    return 'Нет даты';
  }, []);

  return (
    <Box ref={ref} sx={{ padding: '14px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ color: "#0D1D32", fontSize: '32px', fontWeight: 700, marginBottom: '16px' }}>
            {props.name}
          </Typography>

          <Box sx={{ ...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              ID товара:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {props.id}
            </Typography>
          </Box>

          <Box sx={{...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              Серийный номер:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {props.serialNumber}
            </Typography>
          </Box>

          <Box sx={{...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              Дата производства:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {elShowDateFormatted(props.producedAt)}
            </Typography>
          </Box>

          <Box sx={{...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              Дата начала эксплуатации:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {elShowDateFormatted(props.dispayLoanSupplyDate)}
            </Typography>
          </Box>

          <Box sx={{...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              Состояние товара:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {qualityValue(props.quality)}
            </Typography>
          </Box>

          <Box sx={{...styles.wrapperBlockMinMargin, display: 'flex', alignItems: 'end'}}>
            <Typography sx={ styles.labelPrint }>
              Номер сделки:
            </Typography>
            &nbsp;
            <Typography sx={ styles.valuePrint }>
              {props.dispayLoanLeaseAgreement}
            </Typography>
          </Box>
        </Box>
        <QRCode size={200} style={{...styles.qr}} value={`${process.env.REACT_APP_CLOUD_HOST}/workspaces/shop/good/${props.id}`} />
      </Box>

      {props.description && (
        <Box sx={{...styles.wrapperBlock}}>
          <Box sx={ styles.labelPrint }>Описание:</Box>
          <Typography sx={{ color: "#0D1D32", fontSize: '18px', fontWeight: 300, marginBottom: '16px' }}>
          {props.description}
          </Typography>
        </Box>
      )}

      <Box sx={{...styles.wrapperBlock, display: 'flex', alignItems: 'end'}}>
        <Typography sx={ styles.labelPrint }>
          Дата приёмки на склад:
        </Typography>
        &nbsp;
        <Typography sx={ styles.valuePrint }>
          {elShowDateFormatted(props.acceptedAt)}
        </Typography>
      </Box>
    </Box>
  );
});

PrintOutput.propTypes = {
  acceptedAt: PropTypes.string,
  description: PropTypes.string,
  dispayLoanLeaseAgreement: PropTypes.string,
  dispayLoanSupplyDate: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  producedAt: PropTypes.string,
  quality: PropTypes.string,
  serialNumber: PropTypes.string
};

export default PrintOutput;