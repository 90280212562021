import { gql } from '@apollo/client';

export const GET_BANK_STATEMENT_HISTORY = gql`
  query GetBankStatementHistory(
    $accountId: ID!,
    $period: BankStatementPeriodGroupBy
  ) {
    bankStatementAccount(accountId: $accountId) {
      id
      periods(groupBy: $period) {
        debitedFunds
        endDate
        endRemainder
        receivedFunds
        startDate
        startRemainder
      }
    }
  }
`;