import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageContactCreditHistory from './components/PageContactCreditHistory';
import PageContactItem from './components/PageContactItem';

function PageContacts() {
  return 'PageContacts';
}

export default function ContactsRoutes() {
  return (
    <Box component={Router} sx={{ maxWidth: 1280 }}>
      <PageContacts path="/" />
      <PageContactItem path=":contactId" />
      <PageContactCreditHistory path=":contactId/credit-history/" />
      <PageContactCreditHistory path=":contactId/credit-history/:historyId" />
    </Box>
  );
}
