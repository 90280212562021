import PropTypes from 'prop-types';
import React, {useEffect, useState, Fragment} from 'react';

import { useQuery } from '@apollo/client';
import { Box, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import {Link, Router, useLocation} from '@reach/router';

import OpenInNewButton from 'components/OpenInNewButton';
import SaveStatementButton from 'components/SaveStatementButton';
import { formatDate } from 'utils/dateTimeFormatters';

import HeaderBankStatement from './HeaderBankStatement';
import { FixedResolution } from './SectionResolution';
import { ResolutionFormContext } from './SectionResolution/FixedResolution';
import TabCounterparties from './TabCounterparties';
import TabOrders from './TabOrders';
import TabSummary from './TabSummary';
import {STATUS_PROCESSING} from "../constants";
import {
  GET_BANK_STATEMENT_SUMMARY,
} from '../graphql/queries/bankStatementSummary';

const PATH_TO_TAB_VALUE = {
  '': 0,
  counterparties: 1,
  orders: 2,
};

PageBankStatementItem.propTypes = {
  '*': PropTypes.string,
  bankStatementId: PropTypes.any,
  entityId: PropTypes.any,
};

export default function PageBankStatementItem({
  '*': path,
  bankStatementId,
  entityId,
}) {
  const location = useLocation();

  const [formState, toggleFormLock] = useState({ locked: false, text: '' });
  const [disabledEdit, setDisabledEdit] = useState(location?.state?.disabledEdit);

  const { data, error, loading } = useQuery(GET_BANK_STATEMENT_SUMMARY, {
    variables: {
      accountId: bankStatementId,
    },
  });

  useEffect(() => {
    if (!location?.state?.hasOwnProperty('disabledEdit')) {
      if (STATUS_PROCESSING.includes(data?.bankStatementAccount?.bankStatement?.status)) {
        setDisabledEdit(true);
      }
    }
  }, [location?.state, data]);

  if (error) {
    return error.message;
  }

  if (loading) {
    return 'Загрузка...';
  }

  const { document, info, status } = data?.bankStatementAccount?.bankStatement;

  function renderContent() {
    if (status === 'RECOGNIZED') {
      return (
        <ResolutionFormContext.Provider value={{ formState, toggleFormLock }}>
          {disabledEdit && (
            <Box sx={{
              margin: '16px 0',
              border: '2px dashed #e0e0e0',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px',
              borderRadius: '6px',
            }}>
              Одна из выписок находится в обработке. Только просмотр.
            </Box>
          )}

          <Tabs
            indicatorColor="secondary"
            textColor="inherit"
            value={PATH_TO_TAB_VALUE[path] || 0}
          >
            <Tab component={Link} disableRipple label="Обзор" to="" />
            <Tab
              component={Link}
              disableRipple
              label="Обороты по контрагентам"
              to="counterparties"
            />
            <Tab
              component={Link}
              disableRipple
              label="Все платежные поручения"
              to="orders"
            />
          </Tabs>
          <Box mb={3} mt="-1px">
            <Divider />
          </Box>
          <Router>
            <TabSummary disabledEdit={disabledEdit} path="/*" />
            <TabCounterparties disabledEdit={disabledEdit} path="counterparties" />
            <TabOrders disabledEdit={disabledEdit} path="orders" />
          </Router>
          <FixedResolution bankStatementId={bankStatementId} />
        </ResolutionFormContext.Provider>
      );
    }

    if (status === null) {
      return 'Выписка в процессе распознавания...';
    }

    if (document) {
      return (
        <Box>
          <Typography gutterBottom>
            Файл загружен: {formatDate(document.createdAt)}
          </Typography>
          <Typography gutterBottom>
            Название файла:{' '}
            <Box component="span" fontWeight={500}>
              {document.category.name}
            </Box>
          </Typography>
          <Typography gutterBottom>
            Ссылка на файл:{' '}
            <a color="inherit" href={document.assetUrl}>
              {document.assetUrl}
            </a>
          </Typography>
        </Box>
      );
    }

    return 'Файл не найден или удален';
  }

  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px'
        }}
      >
        <OpenInNewButton />
        {bankStatementId && (
          <SaveStatementButton
            statementId={bankStatementId}
          />
        )}
      </Box>
      <Box maxWidth={1140} px={4} style={{ paddingBottom: formState.locked ? 150 : 5 }}>
        <HeaderBankStatement
          accountNumber={data.bankStatementAccount.number}
          accountTin={data.bankStatementAccount.tin}
          entityTin={data.bankStatementAccount.bankStatement.entity.tin}
          info={info}
          status={status}
        />
        {renderContent()}
      </Box>
    </Fragment>
  );
}
