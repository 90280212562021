import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import DetailGood from './ChildPages/DetailGood';
import DetailLeasing from "./ChildPages/DetailLeasing";
import PageShop from "./PageShop";
import { ShopProvider } from "./ShopContext";

export default function ShopRoutes() {
  return (
    <ShopProvider>
      <Box component={Router} sx={{ height: '100%',  backgroundColor: '#f3f4f5', overflow: 'auto', }}>
        <PageShop path="/" />
        <DetailLeasing id={"leasing"} path="/leasing/:id"/>
        <DetailGood id={'good'} path="/good/:id" />
      </Box>
    </ShopProvider>
  );
}