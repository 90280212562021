import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { navigate, useLocation } from '@reach/router';
import { identity, pick, pickBy, findIndex, isEqual } from "lodash";
import qs from 'querystringify';

import ErrorAlert from 'components/ErrorAlert';
import { useNotification } from 'components/Notifications/NotificationsProvider';

import CellActions from './CellActions';
import {
  columnExtensions,
  columnsModelCreator,
  DEFAULT_MESSAGES_LIMIT,
  pagingPanelMessages,
} from './constants';
import DealDetailsModal from './DealDetailsModal';
import EditNumberRFM from "./EditNumberRFM";
import { GridRoot, Loader } from '../components/helpers';
import { FinmonitoringContext } from '../FinmonitoringContext';
import { FINMONITORING_CHANGE_MESSAGE_STATUS_TO_REJECTED } from '../graphql/mutations/finmonitoringChangeReportingMessageStatusToRejected';
import { FINMONITORING_CHANGE_MESSAGE_STATUS_TO_SENT } from '../graphql/mutations/finmonitoringChangeReportingMessageStatusToSent';
import { FINMONITORING_GENERATE_XML } from '../graphql/mutations/finmonitoringGenerateXmlByReportingMessage';
import { FINMONITORING_UPDATE_REPORTING_MESSAGE } from "../graphql/mutations/finmonitoringUpdateReportingMessage";
import { GET_FINMONITORING_REPORTING_MESSAGES } from '../graphql/queries/finmonitoringReportingMessages';

export const DocumentActionTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <CellActions {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentActionTypeProviderRFM = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <EditNumberRFM {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export default function TableFinmonitoringReporting() {
  const { notify } = useNotification();

  const { setProblems, updateFilter } = useContext(FinmonitoringContext);

  const [fetchMessages, { data, error, loading, refetch }] = useLazyQuery(
    GET_FINMONITORING_REPORTING_MESSAGES, {
      fetchPolicy: "network-only",
    }
  );

  const [dealDetailsObject, setDealDetailsObject] = useState(null);
  const [dealDetailsId, setdealDetailsId] = useState(null);

  const handleShowDealDetails = useCallback((target) => {
    setdealDetailsId(target.id)
    setDealDetailsObject(target);
  }, []);
  const handleDealDetailsModalClose = useCallback(() => {
    setdealDetailsId(null);
    setDealDetailsObject(null);
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (data && dealDetailsObject) {
      if (dealDetailsId.length === 0) return false;

      let index = findIndex(data.finmonitoringReportingMessages.collection, { 'id': dealDetailsId });
      let element = data.finmonitoringReportingMessages.collection[index];

      let equalDocuments = isEqual(dealDetailsObject.documents, element.data.documents);
      let equalOperationParticipants = isEqual(dealDetailsObject.operationParticipants, element.data.operationParticipants);
      let equalId = isEqual(dealDetailsObject.id, element.id);

      if (equalDocuments === false || equalOperationParticipants === false || equalId === false) {
        let obj = {
          documents: element.data.documents,
          id: element.id,
          operationParticipants: element.data.operationParticipants,
          state: element.state,
          __typename: element.__typename,
        };

        setDealDetailsObject(obj)
      };
    }
  });

  const [generateFinmonitoringReportingMessages] = useMutation(FINMONITORING_GENERATE_XML, {
    onError: (error) => {
      notify(
        {
          message: error.message,
          title: 'Ошибка при генерации XML документа',
          type: 'error',
        },
        'ADD_NOTIFICATION',
      );
    },
    onCompleted: (response) => {
      const result = response?.finmonitoringGenerateXmlByReportingMessage;

      if (result?.downloadUrl) {
        window.open(process.env.REACT_APP_HOST + result.downloadUrl);

        refetch();

        notify(
          {
            autoHide: true,
            title: 'Файл скачен',
            type: 'success',
          },
          'ADD_NOTIFICATION',
        );
      }

      if (result?.problems?.length) {
        const invalidMessageStateProblem = result.problems.find(
          (problem) => problem.type === 'INVALID_MESSAGE_STATE',
        );

        if (!invalidMessageStateProblem) {
          setProblems(result.problems);
        }

        notify(
          {
            autoHide: !invalidMessageStateProblem,
            message: invalidMessageStateProblem ? (
              <>{invalidMessageStateProblem.messages.join('<br />')}</>
            ) : undefined,
            title: 'Возникли ошибки при генерации XML документа',
            type: 'error',
          },
          'ADD_NOTIFICATION',
        );
      } else {
        setProblems([]);
      }
    },
  });

  const handleGenerateXML = useCallback(
    (id) => {
      notify(
        {
          autoHide: true,
          message: 'Ожидайте...',
          title: 'Идёт генерация XML документа',
          type: 'info',
        },
        'ADD_NOTIFICATION',
      );

      generateFinmonitoringReportingMessages({
        variables: { messageId: id },
        id: "138",
      });
    },
    [generateFinmonitoringReportingMessages, notify],
  );

  const [finmonitoringChangeReporingMessageStatusToSent] = useMutation(
    FINMONITORING_CHANGE_MESSAGE_STATUS_TO_SENT,
    {
      onError: (error) => {
        notify(
          {
            message: error.message,
            title: 'Возникла ошибка при переводе статуса сообщения',
            type: 'error',
          },
          'ADD_NOTIFICATION',
        );
      },
      onCompleted: (data) => {
        const result = data?.finmonitoringChangeReportingMessageStatusToSent;

        if (result?.problems?.length) {
          const noMessageExportProblem = result.problems.find(
            (problem) => problem.type === 'NO_MESSAGE_EXPORT',
          );

          notify(
            {
              message: noMessageExportProblem ? (
                <>{noMessageExportProblem.messages.join('<br />')}</>
              ) : undefined,
              title: 'Возникла ошибка при переводе сообщения в статус Отправлено',
              type: 'error',
            },
            'ADD_NOTIFICATION',
          );
        } else {
          notify(
            {
              autoHide: true,
              title: 'Сообщение переведено в статус Отправлено',
              type: 'success',
            },
            'ADD_NOTIFICATION',
          );
          refetch();
        }
      },
    },
  );

  const markMessageAsSent = useCallback(
    (messageId, numberRFM) => {
      finmonitoringChangeReporingMessageStatusToSent({
        variables: {
          messageId,
          externalId: numberRFM,
        },
      });
    },
    [finmonitoringChangeReporingMessageStatusToSent],
  );

  const [finmonitoringChangeReporingMessageStatusToRejected] = useMutation(
    FINMONITORING_CHANGE_MESSAGE_STATUS_TO_REJECTED,
    {
      onError: (error) => {
        notify(
          {
            message: error.message,
            title: 'Возникла ошибка при переводе статуса сообщения',
            type: 'error',
          },
          'ADD_NOTIFICATION',
        );
      },
      onCompleted: () => {
        notify(
          {
            autoHide: true,
            title: 'Сообщение переведено в статус Отклонено',
            type: 'success',
          },
          'ADD_NOTIFICATION',
        );
        refetch();
      },
    },
  );

  const markMessageAsRejected = useCallback(
    (messageId) => {
      finmonitoringChangeReporingMessageStatusToRejected({
        variables: { messageId },
      });
    },
    [finmonitoringChangeReporingMessageStatusToRejected],
  );

  const location = useLocation();

  const { currentPage, pageSize } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      currentPage: query.page ? Number(query.page) - 1 : 0,
      pageSize: query.limit ? Number(query.limit) : DEFAULT_MESSAGES_LIMIT,
    };
  }, [location.search]);

  const [pageSizes] = useState([10, 50, 0]);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const updateSearchLocation = useCallback(
    (params) => {
      const parsedQuery = qs.parse(location.search);
      const query = pickBy({ ...parsedQuery, ...params }, identity);
      const search = qs.stringify(query, true);
      navigate(search ? search : location.pathname);
    },
    [location],
  );

  const handleCurrentPageChange = useCallback(
    (page) => {
      updateSearchLocation({ page: page + 1 });
    },
    [updateSearchLocation],
  );

  const handlePageSizeChange = useCallback(
    (value) => {
      updateSearchLocation({ limit: value === 0 ? totalCount : value });
    },
    [updateSearchLocation, totalCount],
  );

  useEffect(() => {
    const parsedQuery = qs.parse(location.search);

    const { filters, limit, page, q, state} = pick(parsedQuery, [
      'filters',
      'limit',
      'page',
      'q',
      'state',
    ]);

    const fetchVariables = {
      filter: {
        state: state || ['PENDING', 'SENT', 'REJECTED'],
        searchQuery: q || '',
      },
      page: Number(page || 1),
      limit: Number(limit || DEFAULT_MESSAGES_LIMIT),
    };

    if (filters) {
      const jsonFilters = JSON.parse(filters) || {};
      updateFilter(jsonFilters);

      // TODO: Раскомментить чтобы включить в FinmonitoringReportingMessageFilter
      // другие значения фильтра помимо state

      fetchVariables['filter'] = {
        ...fetchVariables.filter,
        ...jsonFilters,
      };
    }

    fetchMessages({ variables: fetchVariables });
  }, [fetchMessages, location.search, updateFilter]);

  useEffect(() => {
    if (data) {
      const parsedQuery = qs.parse(location.search);
      const { finmonitoringReportingMessages } = data;

      setRows(finmonitoringReportingMessages.collection);
      setTotalCount(finmonitoringReportingMessages.metadata.totalCount);
      if (
        finmonitoringReportingMessages.metadata.currentPage >
        finmonitoringReportingMessages.metadata.totalPages
      ) {
        const nextQuery = {
          ...parsedQuery,
          page: finmonitoringReportingMessages.metadata.totalPages || 1,
        };
        navigate(qs.stringify(nextQuery, true));
      }
    }
  }, [data, location.search]);

  const onEditNumberRFM = (messageID, numberRFM) => {
    onUpdateNumberRFM(messageID, numberRFM)
  }

  const [finmonitoringChangeReporingMessageEditRFM] = useMutation(
    FINMONITORING_UPDATE_REPORTING_MESSAGE,
    {
      onError: (error) => {
        notify(
          {
            message: error.message,
            title: 'Возникла ошибка при изменении номера сообщения Росфинмониторинга',
            type: 'error',
          },
          'ADD_NOTIFICATION',
        );
      },
      onCompleted: (data) => {
        notify(
          {
            autoHide: true,
            title: 'Номер сообщения Росфинмониторинга успешно обновлен',
            type: 'success',
          },
          'ADD_NOTIFICATION',
        );
        refetch();
      },
    },
  );

  const onUpdateNumberRFM = useCallback(
    (messageId, numberRFM) => {
      finmonitoringChangeReporingMessageEditRFM({
        variables: {
          messageId,
          externalId: numberRFM,
        },
      });
    },
    [finmonitoringChangeReporingMessageEditRFM],
  );

  if (loading) {
    return <Loader />
  }
  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <React.Fragment>
      <Grid
        columns={columnsModelCreator}
        getRowId={(row) => row.id}
        rootComponent={GridRoot}
        rows={rows}
      >
        <DocumentActionTypeProvider
          for={['actions']}
          onGenerateXML={handleGenerateXML}
          onMessageReject={markMessageAsRejected}
          onMessageSent={markMessageAsSent}
          onShowDetails={handleShowDealDetails}
        />

        <DocumentActionTypeProviderRFM
          for={['externalId']}
          onEditNumberRFM={onEditNumberRFM}
        />

        <RowDetailState />
        <PagingState
          currentPage={currentPage}
          defaultCurrentPage={currentPage}
          defaultPageSize={pageSize}
          onCurrentPageChange={handleCurrentPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalCount} />

        <VirtualTable
          cellComponent={(props) => (
            <VirtualTable.Cell className="FinmonitoringTableCell" {...props} />
          )}
          columnExtensions={columnExtensions}
          messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
        />
        <TableHeaderRow />
        <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
      </Grid>

      {dealDetailsObject && (
        <DealDetailsModal
          onGenerateXML={handleGenerateXML}
          onModalClose={handleDealDetailsModalClose}
          {...{ dealDetailsObject }}
        />
      )}
    </React.Fragment>
  );
}
