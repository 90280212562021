export const sortTasks = (tasks = []) => {
  const sortedAndGroupedTasks = [...tasks]
    .map(({ dueAt, ...rest }) => [dueAt.substring(0, 10), { dueAt, ...rest }])
    .reduce((prev, [date, taskObject]) => ({
      ...prev,
      [date]: [...(prev[date] || []), taskObject]
    }), {});

  const result = [];
  Object.values(sortedAndGroupedTasks).map((item) => result.push(...item));

  return result;
}

export const filterTasks = (tasks = null, term = '') => {
  if (tasks) {
    const filterFunc = (task) => {
      const filterString = term.toLowerCase();
      const title = (task?.title || '').toLowerCase();
      const description = (task?.description || '').toLowerCase();
      return title.includes(filterString) || description.includes(filterString);
    };

    return term === ''
      ? tasks
      : {
        past: tasks?.past?.filter(filterFunc),
        today: tasks?.today?.filter(filterFunc),
        tomorrow: tasks?.tomorrow?.filter(filterFunc),
        further: tasks?.further?.filter(filterFunc),
      };
  }

  return null;
};