import { gql } from '@apollo/client';

export const GET_BANK_ACCOUNT_TOPS = gql`
  query GetBankAccountTops($accountId: ID!) {
    bankStatementAccount(accountId: $accountId) {
      id
      topPayers {
        ordersCount
        ordersSum
        contractor {
          category
          id
          tin
          title
        }
      }
      topRecipients {
        ordersCount
        ordersSum
        contractor {
          category
          id
          tin
          title
        }
      }
    }
  }
`;