import { gql } from '@apollo/client/core';

export const GET_DISPUTES = gql`
  query GetDisputes($leasingEntityId: ID!) {
    disputes(leasingEntityId: $leasingEntityId) {
      id
      number
      type
    }
  }
`;
