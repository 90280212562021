import { gql } from '@apollo/client';

export const GET_STORAGE_ACCEPTANCE_DEFAULT_VALUES = gql`
  query ShopStorageAcceptance($storageAcceptanceId: ID!) {
    storageAcceptance(storageAcceptanceId: $storageAcceptanceId) {
      goods {
        images {
          file {
            id
            url
            filename
          }
        }
      }
    }
  }
`;