import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Stack, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import OtherDocuments from './OtherDocuments';
import {
  SOURCE_DOCUMENTS_UPLOAD_OTHER_DOCUMENTS,
} from '../graphql/mutations/sourceDocumentsUploadOtherDocuments';

UploadOtherDocuments.propTypes = {
  onClose: PropTypes.func,
  previewObject: PropTypes.object,
}

export default function UploadOtherDocuments({ onClose, previewObject }) {
  const [otherDocuments, setOtherDocuments] = useState([]);
  const handleOtherDocumentsChange = useCallback(
    (value) => setOtherDocuments(value),
    [],
  );

  const [uploadOtherDocuments, { loading: uploading }] = useMutation(
    SOURCE_DOCUMENTS_UPLOAD_OTHER_DOCUMENTS,
    {
      onCompleted: (res) => {
        const data = res?.sourceDocumentsUploadOtherDocuments?.sourceDocument;

        if (data?.id) {
          alert('Документы успешно загружены');
          onClose();
        }
      },
      onError: () => alert('Ошибка загрузки документов'),
    },
  );

  const handleUploadOtherDocuments = useCallback(() => {
    if (!!otherDocuments.length) {
      uploadOtherDocuments({
        variables: {
          documentId: previewObject?.id || '',
          otherDocuments,
        },
      });
    }
  }, [previewObject, otherDocuments, uploadOtherDocuments]);

  return (
    <React.Fragment>
      <Typography
        component="h2"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        variant="h2"
      >
        Загрузить прочие документы
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Typography>
      <OtherDocuments onChange={handleOtherDocumentsChange} />
      {!!otherDocuments.length && (
        <Stack justifyContent="center">
          <Button
            disabled={uploading}
            onClick={handleUploadOtherDocuments}
            sx={{ color: 'white' }}
            variant="contained"
          >
            Загрузить
          </Button>
        </Stack>
      )}
    </React.Fragment>
  )
}