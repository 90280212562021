import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import alfaLogo from 'assets/alfa.svg';
import raiffeisenLogo from 'assets/bank-logos/raiffeisen.svg';
import sdmBankLogo from 'assets/bank-logos/sdm.svg';
import sovcombankLogo from 'assets/bank-logos/sovcombank.svg';
import tochkaLogo from 'assets/bank-logos/tochka.jpeg';
import bankLogo from 'assets/bank.svg';
import sberLogo from 'assets/sber.svg';
import tinkoffLogo from 'assets/tinkoff.svg';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'components/Tooltip';

export function getBankLogo(str) {
  switch (true) {
    case /альфа/i.test(str):
      return alfaLogo;
    case /сбер/i.test(str):
      return sberLogo;
    case /tinkoff/i.test(str):
      return tinkoffLogo;
    case /совкомбанк/i.test(str):
      return sovcombankLogo;
    case /райффайзенбанк/i.test(str):
      return raiffeisenLogo;
    case /сдм-банк/i.test(str):
      return sdmBankLogo;
    case /точка/i.test(str):
      return tochkaLogo;
    default:
      return bankLogo;
  }
}

HeaderBankStatement.propTypes = {
  accountNumber: PropTypes.string,
  accountTin: PropTypes.string,
  entityTin: PropTypes.string,
  info: PropTypes.shape({
    createdAt: PropTypes.string,
    endDate: PropTypes.string,
    sender: PropTypes.string,
    startDate: PropTypes.string,
  }),
  status: PropTypes.oneOf(['UNRECOGNIZABLE', 'RECOGNIZED', 'RECOGNIZE_FAILED']),
};

export default function HeaderBankStatement({
  accountNumber,
  accountTin,
  entityTin,
  info,
  status
}) {
  if (status === 'RECOGNIZE_FAILED' || status === 'UNRECOGNIZABLE') {
    return (
      <Box
        sx={{
          bgcolor: '#f6f7f8',
          borderRadius: '4px',
          my: { xs: 3 },
          px: { xs: 2, lg: 4 },
          py: { xs: 2, lg: 3 },
        }}
      >
        <Box alignItems="center" display="flex" width={{ xs: 1, lg: 1 / 2 }}>
          <Tooltip title={info.sender}>
            <Box height={40} mr={3}>
              <img alt="Alfa-bank logo" height={40} src={getBankLogo(info.sender)} width={40} />
            </Box>
          </Tooltip>
          <Box>
            <Typography gutterBottom variant="h3">
              Выписка не распознана
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {status === 'RECOGNIZE_FAILED'
                ? 'Ошибка при распознавании'
                : 'Формат не поддерживается'}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const startDateObj = DateTime.fromISO(info.startDate);
  const endDateObj = DateTime.fromISO(info.endDate);
  const diff = endDateObj.diff(startDateObj, ['months', 'days']);

  return (
    <Box
      sx={{
        bgcolor: '#f6f7f8',
        borderRadius: '4px',
        my: { xs: 3 },
        px: { xs: 2, lg: 4 },
        py: { xs: 2, lg: 3 },
      }}
    >
      <Box alignItems="center" display="flex" flexWrap="wrap">
        <Box alignItems="center" display="flex" width={{ xs: 1, lg: 1 / 2 }}>
          <Tooltip title={info.sender}>
            <Box height={40} mr={3}>
              <img alt="Alfa-bank logo" height={40} src={getBankLogo(info.sender)} width={40} />
            </Box>
          </Tooltip>
          <Box>
            <Typography gutterBottom variant="h3">
              {info.sender || 'Банк не определен'}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Выписка по счету {accountNumber} (ИНН: {accountTin})
            </Typography>
            {
              accountTin !== entityTin &&
              <Typography color="error" variant="caption">
                ИНН СЧЕТА НЕ СОВПАДАЕТ С ИНН КОМПАНИИ
              </Typography>
            }
          </Box>
        </Box>
        <Box
          ml={{ lg: 'auto' }}
          mt={{ xs: 2, lg: 0 }}
          textAlign={{ lg: 'right' }}
          width={{ xs: 1, lg: 1 / 2 }}
        >
          <Typography color="textSecondary" gutterBottom variant="h5">
            c{' '}
            <Box color="text.primary" component="span" fontWeight={500}>
              {DateTime.fromISO(info.startDate).toLocaleString(DateTime.DATE_SHORT)}
            </Box>
            {' по '}
            <Box color="text.primary" component="span" fontWeight={500}>
              {DateTime.fromISO(info.endDate).toLocaleString(DateTime.DATE_SHORT)}
            </Box>{' '}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <FormattedMessage
              id={`bankStatement.period`}
              values={{
                months: diff?.values?.months,
                days: diff?.values?.days,
              }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
