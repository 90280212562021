import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  Link,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { SingleMenuItemProps } from '../types';

SingleMenuItem.propTypes = {
  ...SingleMenuItemProps,
  children: PropTypes.node,
};

export default function SingleMenuItem({ children, title, url, ...rest }) {
  const locationUrl = useMemo(() => {
    const location = window.location;
    return [location.origin, location.pathname].join('');
  }, []);

  const transformedUrl = useMemo(() => {
    const matches = url.match(/https:\/\/(dev|prod)-crm-frontend.arenza.ru/);

    if (!Boolean(matches)) {
      return url;
    }

    return url.replace(matches[0], process.env.REACT_APP_CLOUD_HOST);
  }, [url]);

  const ACTIVE_ITEM_REG_EXP = new RegExp(locationUrl + "(\\?\\w+|\\/$)")

  if (!url) {
    return null;
  }

  return (
    <ListItem
      {...rest}
      button
      component={Link}
      href={transformedUrl}
      selected={Boolean(transformedUrl.match(ACTIVE_ITEM_REG_EXP))}
      sx={{
        ...rest?.sx,
        py: '5px',
        transitionProperty: 'color, background-color',

        '&.Mui-selected': {
          backgroundColor: '#293846',
          color: 'white',

          '&:hover': { backgroundColor: '#293846' },
        },

        '&:hover': { color: 'white' },
      }}
    >
      {children ? children : <ListItemText primary={title} />}
    </ListItem>
  )
}