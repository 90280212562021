import { gql } from '@apollo/client/core';

export const LOAN_SUPPLIES_EXPORT_TO_ONEC = gql`
  mutation LoanSuppliesExportToOnec($supplyId: ID!) {
    loanSuppliesExportToOnec(loanSupplyId: $supplyId) {
      onecExportRequest {
        id
        status
        error
      }
    }
  }
`;