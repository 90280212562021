import { gql } from '@apollo/client';

export const GET_BANK_STATEMENT_SUMMARY = gql`
  query GetBankStatementSummary($accountId: ID!) {
    bankStatementAccount(accountId: $accountId) {
      id
      tin
      number
      bankStatement {
        status
        entity {
          tin
        }
        document {
          createdAt
          assetUrl
          category {
            id
            name
          }
          id
        }
        info {
          accountNumber
          createdAt
          sender
          receiver
          startDate
          endDate
        }
      }
    }
  }
`;
