// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: '13360',
    loan: {
      advanceRate: 10.0,
      advanceVatRate: 20.0,
      amountFinancing: 90000.0,
      amountForLeasingCompany: 100000.0,
      discountFromSupplier: 0.0,
      effectiveRate: 114.1645,
      forIssuingCommission: 0.0,
      forfeitRate: 0.01,
      id: '13591',
      interestRate: 40.0,
      monthlyInterestRate: 3.33,
      redemptionPayment: 149000.0,
      term: 15,
      vatRate: 20.0,
      withVat: true,
      schedules: [
        {
          state: 'draft',
          items: [
            {
              type: 'advance',
              baseAmount: 8333.33,
              commissionAmount: 0.0,
              fullAmount: 10000.0,
              penaltiesAmount: 0.0,
              vatAmount: 1666.67,
              dueAt: '2020-09-28',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-10-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-11-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-01-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-02-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-03-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-04-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-05-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-06-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-07-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-08-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-09-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-10-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-11-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'redemption',
              baseAmount: 124166.67,
              commissionAmount: 0.0,
              fullAmount: 149000.0,
              penaltiesAmount: 0.0,
              vatAmount: 24833.33,
              dueAt: '2021-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
          ],
          __typename: 'Schedule',
        },
        {
          state: 'saved',
          items: [
            {
              type: 'advance',
              baseAmount: 8333.33,
              commissionAmount: 0.0,
              fullAmount: 10000.0,
              penaltiesAmount: 0.0,
              vatAmount: 1666.67,
              dueAt: '2020-09-28',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-10-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-11-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2020-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-01-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-02-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-03-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-04-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-05-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-06-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-07-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-08-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-09-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-10-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-11-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              baseAmount: 5000.0,
              commissionAmount: 2997.0,
              fullAmount: 9596.4,
              penaltiesAmount: 0.0,
              vatAmount: 1599.4,
              dueAt: '2021-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'redemption',
              baseAmount: 124166.67,
              commissionAmount: 0.0,
              fullAmount: 149000.0,
              penaltiesAmount: 0.0,
              vatAmount: 24833.33,
              dueAt: '2021-12-25',
              paid: false,
              __typename: 'ScheduleItem',
            },
          ],
          __typename: 'Schedule',
        },
      ],
      __typename: 'Loan',
    },
    __typename: 'Application',
  },
  {
    id: '25315',
    loan: {
      advanceRate: 0.17,
      advanceVatRate: 0.2,
      amountFinancing: 403497.81,
      // amountForLeasingCompany: 486141.94,
      amountForLeasingCompany: 486141.94, // 482191.94 // 462811.94,
      discountFromSupplier: 0.0,
      effectiveRate: 0.6163,
      forIssuingCommission: 0.025,
      forfeitRate: 0.01,
      id: '25315-loan',
      interestRate: 0.24,
      monthlyInterestRate: 0.02,
      redemptionPayment: 1000.0,
      term: 18,
      vatRate: 0.2,
      withVat: true,
      schedules: [
        {
          state: 'archived',
          id: '25315-loan-01',
          __typename: 'Schedule',
          items: [
            {
              type: 'advance',
              baseAmount: 65565.03,
              commissionAmount: 0,
              fullAmount: 78678.03,
              vatAmount: 13113.01,
              paid: true,
              startAt: '2020-06-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-07-02',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-08-02',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-09-02',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-02',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 30559.99,
              baseAmount: 17783.98,
              commissionAmount: 7682.68,
              vatAmount: 5093.33,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
            {
              type: 'redemption',
              baseAmount: 833.33,
              commissionAmount: 0,
              fullAmount: 1000,
              vatAmount: 166.67,
              paid: false,
              startAt: '2020-10-05',
              __typename: 'ScheduleItem',
            },
          ],
        },
        {
          state: 'active',
          id: '25315-loan-02',
          __typename: 'Schedule',
          items: [
            {
              __typename: 'ScheduleItem',
              type: 'advance',
              baseAmount: 65565.03,
              commissionAmount: 0,
              fullAmount: 78678.03,
              vatAmount: 13113.01,
              paid: true,
              startAt: '2020-06-05',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-07-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-08-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-09-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-10-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-11-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-01-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-02-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-03-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-04-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-05-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-06-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-07-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-08-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-09-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-10-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-11-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'redemption',
              baseAmount: 833.33,
              commissionAmount: 0,
              fullAmount: 1000,
              vatAmount: 166.67,
              startAt: '2021-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
          ],
        },
        {
          state: 'draft',
          id: '25315-loan-02',
          __typename: 'Schedule',
          items: [
            {
              __typename: 'ScheduleItem',
              type: 'advance',
              baseAmount: 65565.03,
              commissionAmount: 0,
              fullAmount: 78678.03,
              vatAmount: 13113.01,
              paid: true,
              startAt: '2020-06-05',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-07-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-08-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-09-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-10-02',
              paid: true,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-11-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2020-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-01-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-02-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-03-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-04-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-05-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-06-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-07-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-08-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-09-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-10-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-11-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'monthly',
              fullAmount: 32416.02,
              baseAmount: 18864.07,
              commissionAmount: 8149.28,
              vatAmount: 5402.67,
              startAt: '2021-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
            {
              type: 'redemption',
              baseAmount: 833.33,
              commissionAmount: 0,
              fullAmount: 1000,
              vatAmount: 166.67,
              startAt: '2021-12-02',
              paid: false,
              __typename: 'ScheduleItem',
            },
          ],
        },
      ],
      __typename: 'Loan',
    },
    __typename: 'Application',
  },
];
