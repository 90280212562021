import { gql } from '@apollo/client/core';

export const GET_TASK_DETAIL = gql`
  query GetTaskDetail( $taskId: ID! ) {
    task(taskId: $taskId) {
      id
      assignee {
        displayName
        id
      }
      substituteUser {
        displayName
      }
      certainTime
      createdBy {
        displayName
        id
      }
      attachments {
        attachmentId
        filename
        fileExt
        url
      }
      priority
      dueAt
      title
      description
      target {
        ... on Application {
          id
          contact {
            id
            firstName
            lastName
            middleName
            phone
            email
          }
          notes (order: DESC) {
            amocrmAuthor
            author {
              ... on Contact {
                lastName
                firstName
              }
              ... on User {
                contact {
                  lastName
                  firstName
                }
                avatar
                displayName
                roles
              }
            }
            body
            priority
            id
            createdAt
          }
          status
          subject
          borrowerEntity {
            id
            shortLegalName
          }
          supplyingEntityContracts {
            supplyingOrder {
              status
              supplier {
                id
                legalNameWithShortEntityType
              }
            }
          }
        }
      }
    }
  }
`;