import { useMemo, useContext } from 'react';

import { AppContext } from 'core/AppContext';

import { CATEGORY_DOCUMENTS } from "./components/constants";

export const useAssignPermissions = () => {
  const { appData } = useContext(AppContext);

  const PERMISSIONS_ROLES_LOAN_SUPPLY = ['ADMIN', 'FINANCIAL_CONTROLLER'];
  const PERMISSIONS_ROLES_DISPUTE = [
    'ADMIN',
    'COLLECTOR',
    'LAWYER',
    'UNDERWRITER',
    'CLIENT_MANAGER',
    'PARTNER_MANAGER',
    'VERIFICATOR',
    'BUYER',
    'ACCOUNTANT',
    'FINANCIAL_CONTROLLER',
    'SUPPORT_MANAGER',
    'GOVERNANCE'
  ];

  const PERMISSIONS_ROLES_APPLICATION_SUPPLYING_ORDER = [
    'ADMIN',
    'COLLECTOR',
    'LAWYER',
    'UNDERWRITER',
    'CLIENT_MANAGER',
    'PARTNER_MANAGER',
    'VERIFICATOR',
    'BUYER',
    'ACCOUNTANT',
    'FINANCIAL_CONTROLLER',
    'SUPPORT_MANAGER',
    'GOVERNANCE'
  ];

  return useMemo(() => {
    const roles = appData.currentUser?.roles || [];
    let allow = {
      [CATEGORY_DOCUMENTS.loanSupply]: false,
      [CATEGORY_DOCUMENTS.dispute]: false,
      [CATEGORY_DOCUMENTS.supplyingOrder]: false,
    };

    roles.forEach(role => {
      if (PERMISSIONS_ROLES_LOAN_SUPPLY.includes(role)) {
        allow[CATEGORY_DOCUMENTS.loanSupply] = true;
      }

      if (PERMISSIONS_ROLES_DISPUTE.includes(role)) {
        allow[CATEGORY_DOCUMENTS.dispute] = true;
      }

      if (PERMISSIONS_ROLES_APPLICATION_SUPPLYING_ORDER.includes(role)) {
        allow[CATEGORY_DOCUMENTS.applicationSupplyingOrder] = true;
      }
    })

    return allow;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);
};