import PropTypes from "prop-types";
import React, {useMemo, useState} from 'react';

import {
  Box,
  Typography,
  Link,
  Button,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputAdornment,
  SvgIcon
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {cloneDeep} from "lodash";

import { ReactComponent as BasketIcon } from './../img/basket.svg';
import Tabs from "../../../components/Tabs";
import rub from "../img/rub.svg";

DetailLeasing.propTypes = {
  id: PropTypes.number.isRequired,
};

export default function DetailLeasing({ id }) {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)',
    },
    tab: {
      minWidth: 'auto !important',
      width: 'auto !important',
      color: '#0D1D32',
      fontSize: '14px',
      fontWeight: 500,
    },
  }), []);
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: '32px !important',
      fontWeight: '700 !important',
      width: 'auto',
      margin: '0 16px 0 16px !important',
    },
    status: {
      borderRadius: '4px',
      fontSize: '12px',
      padding: '4px 5px',
      maxHeight: '24px',

      '& span': {
        width: '8px',
        height: '8px',
        display: 'inline-block',
        marginRight: '5px',
        borderRadius: '8px',
      },
      '&.active': {
        background: 'rgba(103, 191, 61, 0.15)',
        color: '#67BF3D',

        '& span': {
          background: '#67BF3D',
        }
      }
    },
    button: {
      background: '#FFFFFF',
      border: '1px solid #CFD2D6',
      borderRadius: '8px',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'none',
      width: 'auto',
      minWidth: 'auto',
      height: '48px',
      padding: '12px 24px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        color: '#3D6CE7',
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',

        '& path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        background: '#365CBF',
        color: '#ffffff',
        border: '1px solid #365CBF',

        '& path': {
          fill: '#ffffff',
        },
      },
    },
    wrapperContent: {
      margin: '24px',
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      border: '1px solid #E7E8EA',
      padding: '24px',
      // height: 'calc(100% - 120px)',
      overflow: 'hidden',
    },
    titleContent: {
      fontSize: '26px',
      fontWeight: 700,
      marginBottom: '24px',
    },
    wrapperBlock: {
      marginBottom: '25px'
    },
    label: {
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '8px',
    },
    input: {
      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    inputImgRub: {
      background: '#F5F6F7',
      borderRadius: '4px',
      padding: '9px 11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2,
    },
    radioInput: {
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0D1D32',
      },
      '& .MuiButtonBase-root': {
        color: '#CFD2D6',
      },
      '& .Mui-checked': {
        color: '#3651DE !important',
      },
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',

      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      }
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const [activeTab, setActiveTab] = useState(0);
  const onChangeTab = event => {
    setActiveTab(event);
  };

  const [state, setState] =  useState({
    id: 1,
    number: 123,
    company: 'ООО “Электроника”',
    status: 'Текст статуса 2',
    count: 1,
    edenic: 10,
    priceCount: 20000,
    symma: 400000
  })

  const onChangeField = (type, event) => {
    const newState = cloneDeep(state);
    newState[type] = event.target.value;
    setState(newState);
  }

  const [publicProduct, setPublicProduct] = useState(false);
  const onPublicProduct = () => {
    setPublicProduct(!publicProduct);
  }

  const onSave = () => {
    console.log('state', state);
  }

  return (
    <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 24px', backgroundColor: '#ffffff', height: '100px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button href={`/workspaces/shop/`} sx={{ ...styles.button, width: '48px' }}>
            <ArrowBackIcon sx={{marginRight: '0 !important'}} />
          </Button>

          <Typography gutterBottom sx={ styles.titlePage } variant="h2">
            Карта объекта лизинга / <Link>{id}</Link>
          </Typography>

          <Box className={'active'} sx={ styles.status }>
            <span></span>
            {state.status}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: '16px', }}>
          <Button sx={{ ...styles.button, width: '48px' }}>
            <SvgIcon component={BasketIcon} inheritViewBox sx={{marginRight: '0px !important'}} />
          </Button>
          
          {publicProduct ? (
            <Button onClick={onPublicProduct} sx={ styles.button }>
              Убрать с сайта
            </Button>
          ) : (
            <Button onClick={onPublicProduct} sx={ styles.button }>
              Выложить на сайт
            </Button>
          )}
          
          <Button href={`/workspaces/shop/leasing/preview/${state.id}`} sx={ styles.button }>Предпросмотр</Button>
        </Box>
      </Box>

      <Tabs
        defaultActiveTab={0}
        onChange={onChangeTab}
        sx={stylesTabs}
        tabs={[
          'Редактировать',
          'Рассрочка',
          'Страховка',
          'Доставка',
          'Удаленное управление',
        ]}
      />

      <Box sx={ styles.wrapperContent }>
        {activeTab === 0 && (
          <Box>
            <Typography sx={ styles.titleContent }>Информация о SKU:</Typography>

            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.label }>
                № Сделки:
              </Typography>

              <TextField
                disabled
                sx={{ ...styles.input, width: '50%'}}
                type="number"
                value={state.number}
              />
            </Box>

            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.label }>
                Статус
              </Typography>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}
              >
                <FormControlLabel
                  checked={state.status === 'Текст статуса 2'}
                  control={<Radio />}
                  label="Текст статуса 2"
                  onChange={onChangeField.bind(this, 'status')}
                  sx={styles.radioInput}
                  value="Текст статуса 2"
                />
                <FormControlLabel
                  checked={state.status === 'Текст статуса 3'}
                  control={<Radio />}
                  label="Текст статуса 3"
                  onChange={onChangeField.bind(this, 'status')}
                  sx={styles.radioInput}
                  value="Текст статуса 3"
                />
                <FormControlLabel
                  checked={state.status === 'Текст статуса 4'}
                  control={<Radio />}
                  label="Текст статуса 4"
                  onChange={onChangeField.bind(this, 'status')}
                  sx={styles.radioInput}
                  value="Текст статуса 4"
                />
              </RadioGroup>
            </Box>

            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.label }>
                Компания получатель:
              </Typography>

              <TextField
                disabled
                fullWidth
                sx={ styles.input }
                type="text"
                value={state.company}
              />
            </Box>

            <Box sx={{ ...styles.wrapperBlock, display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ width: '100%', maxWidth: '130px', marginRight: '16px', }}>
                <Typography sx={ styles.label }>
                  Количество:
                </Typography>

                <TextField
                  disabled
                  fullWidth
                  onChange={onChangeField.bind(this, 'count')}
                  sx={ styles.input }
                  type="text"
                  value={state.count}
                />
              </Box>

              <Box sx={{ width: '100%', maxWidth: '340px', marginRight: '16px', }}>
                <Typography sx={ styles.label }>
                  Единиц:
                </Typography>

                <Select
                  fullWidth
                  onChange={onChangeField.bind(this, 'edenic')}
                  sx={styles.select}
                  value={state.edenic}
                >
                  <MenuItem sx={styles.selectItem} value={10}>Штук</MenuItem>
                  <MenuItem sx={styles.selectItem} value={20}>Штуки</MenuItem>
                  <MenuItem sx={styles.selectItem} value={30}>Штуковины</MenuItem>
                </Select>
              </Box>

              <Box sx={{ width: '100%', maxWidth: '480px', marginRight: '16px', }}>
                <Typography sx={ styles.label }>
                  Цена за единицу:
                </Typography>

                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box sx={ styles.inputImgRub }>
                          <Box alt={'Рублей'} component={"img"} src={rub} />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  fullWidth
                  onChange={onChangeField.bind(this, 'priceCount')}
                  sx={ styles.input }
                  type="number"
                  value={state.priceCount}
                />
              </Box>

              <Box sx={{ width: '100%', maxWidth: '480px' }}>
                <Typography sx={ styles.label }>
                  Сумма:
                </Typography>

                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box sx={ styles.inputImgRub }>
                          <Box alt={'Рублей'} component={"img"} src={rub} />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  fullWidth
                  onChange={onChangeField.bind(this, 'symma')}
                  sx={ styles.input }
                  type="number"
                  value={state.symma}
                />
              </Box>
            </Box>

            <Button onClick={onSave} sx={ styles.blueButton }>
              Сохранить
            </Button>
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            <Typography sx={ styles.titleContent }>Информация о рассрочке:</Typography>

            <Box>
             <Typography>Данный раздел в разработке</Typography>
            </Box>
          </Box>
        )}

        {activeTab === 2 && (
          <Box>
            <Typography sx={ styles.titleContent }>Страховка:</Typography>

            <Box>
              <Typography>Данный раздел в разработке</Typography>
            </Box>
          </Box>
        )}

        {activeTab === 3 && (
          <Box>
            <Typography sx={ styles.titleContent }>Доставка:</Typography>

            <Box>
              <Typography>Данный раздел в разработке</Typography>
            </Box>
          </Box>
        )}

        {activeTab === 4 && (
          <Box>
            <Typography sx={ styles.titleContent }>Удаленное управление:</Typography>

            <Box>
              <Typography>Данный раздел в разработке</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}