import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Box, Divider, ListItem, ListItemText, ListSubheader, makeStyles } from '@material-ui/core';
import _noop from 'lodash.noop';
import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'react-intl';

import CreateScheduleButton from './CreateScheduleButton';
import { ScheduleContext } from './ScheduleContext';

const useStyles = makeStyles((theme) => ({
  // FIXME: Брать цвета из темы
  root: {
    '&$selected': {
      backgroundColor: '#ffeede',
      '&:hover': {
        backgroundColor: '#ffeede',
      },
    },
    '&:hover': {
      backgroundColor: 'hsl(29 100% 94% / 0.5)',
    },
  },
  selected: {},
}));

const scheduleItemPropTypes = PropTypes.shape({
  createdAt: PropTypes.string,
  activatedAt: PropTypes.string,
  stornedAt: PropTypes.string,
  id: PropTypes.string,
  state: PropTypes.oneOf(['active', 'draft', 'archived']),
});

ScheduleListItem.propTypes = {
  item: scheduleItemPropTypes,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

function ScheduleListItem({ item, onSelect = _noop, selected }) {
  const classes = useStyles();
  const intl = useIntl();

  const { activatedAt, createdAt, id, state, stornedAt } = item;

  function handleClick() {
    onSelect(id);
  }

  const title = {
    draft: intl.formatMessage({ id: `${state}.one` }) + ' №' + id,
    archived: intl.formatMessage({ id: `${state}.one` }) + ' №' + id,
    active: intl.formatMessage({ id: `${state}.one` }),
  };

  const subtitle = {
    draft: 'Создан ' + DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT),
    archived: 'Архивирован ' + DateTime.fromISO(stornedAt).toLocaleString(DateTime.DATE_SHORT),
    active: 'Активирован ' + DateTime.fromISO(activatedAt).toLocaleString(DateTime.DATE_SHORT),
  };

  return (
    <ListItem
      button
      classes={{ root: classes.root, selected: classes.selected }}
      disableRipple
      onClick={handleClick}
      selected={selected}
    >
      <ListItemText primary={title[state]} secondary={subtitle[state]} />
    </ListItem>
  );
}

ScheduleList.propTypes = {
  createButton: PropTypes.node,
  groupedSchedules: PropTypes.shape({
    active: PropTypes.array,
    archived: PropTypes.array,
    draft: PropTypes.array,
  }),
  loanId: PropTypes.string,
};

export default function ScheduleList({ createButton, loanId, ...props }) {
  const { dispatch, state } = useContext(ScheduleContext);
  const {
    groupedSchedules: { active = [], archived = [], draft = [] },
    activeScheduleId,
  } = state;

  function handleScheduleCreate(newScheduleId) {
    dispatch({ type: 'ADD_ITEM', payload: { id: newScheduleId } });
  }

  function handleSelect(id) {
    dispatch({ type: 'SELECT_ITEM', payload: { activeScheduleId: id } });
  }

  function renderItems(item, index) {
    return (
      <ScheduleListItem
        item={item}
        key={index}
        {...props}
        onSelect={handleSelect}
        selected={activeScheduleId === item.id}
      />
    );
  }

  return (
    <Box
      sx={{
        bgcolor: '#F4F5F7',
        borderRadius: '4px',
        flexShrink: 0,
        mr: 2,
        p: 2,
        position: 'sticky',
        top: 0,
        width: 320,
      }}
    >
      <Box sx={{ bgcolor: 'white', borderRadius: '4px' }}>
        {active.map(renderItems)}
        <Divider />
        <ListSubheader disableSticky>
          <FormattedMessage id="draft.many" />
        </ListSubheader>
        <CreateScheduleButton loanId={loanId} onCreate={handleScheduleCreate} />
        {draft.map(renderItems)}
        {archived.length ? (
          <ListSubheader disableSticky>
            <FormattedMessage id="archived.many" />
          </ListSubheader>
        ) : null}
        {archived.map(renderItems)}
      </Box>
    </Box>
  );
}
