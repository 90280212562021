import { gql } from '@apollo/client';

import { QUESTIONNAIRE_FRAGMENT } from '../fragments';

export const GET_ENTITY_FULL_DATA = gql`
  query GetEntityFullData($entityId: ID!) {
    entity(entityId: $entityId) {
      id
      psrn
      shortLegalName
      tin
      trrc
      staff {
        id
        position
        contact {
          id
          firstName
          middleName
          lastName
          fullName @client
          phone
          email
        }
      }
      addresses {
        id
        fullAddress
        main
        type
      }
      industryType
      trademark
      actualBusinessOwnership
      employeesCount
      customerTypes
      site
      socialAccounts
      ceoIncome
      attorneyName
      founders
      isFoundersReadyToBeGuarantors
      attorneyName
      beneficiaryName
      isEdocUsing
      taxationType
      accountingMaintainerType
      accountingMaintainerPhone
      accountingMaintainerEmail
      # netProfit
      # isSameActualAddress FIXME: такого поля нет. Надо смотреть addresses
      isWarehouseExists
      isOtherBusinessPointsExists
      phone
      email
      questionnaires {
        ...QuestionnaireFragment
        versions {
          createdAt
          createdBy {
            id
            contact {             
              id
              firstName
              middleName
              lastName
              fullName @client
            }
          }
          version
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;