import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import _upperFirst from 'lodash.upperfirst';
import { DateTime } from 'luxon';

import HelpTooltip from 'components/HelpTooltip';
import { TitleContentForProcess } from 'modules/legalEntities/ContainerFedresursProcess';

LastCheckTimeSubheader.propTypes = {
  createdAt: PropTypes.string,
  failedAt: PropTypes.string,
  finishedAt: PropTypes.string,
  startedAt: PropTypes.string,
};
export function LastCheckTimeSubheader({ createdAt, failedAt, finishedAt, startedAt }) {
  const relativeDate = DateTime.fromISO(
    finishedAt || failedAt || startedAt || createdAt,
  ).toRelativeCalendar();

  return (
    <Box display="inline-flex">
      <Typography color="textSecondary" variant="caption">
        <Box fontWeight="500">{_upperFirst(relativeDate)}</Box>
      </Typography>
      <Box ml={2}>
        <HelpTooltip
          title={
            <>
              <Typography px={2} variant="subtitle2">
                — время последней проверки
              </Typography>
              <TitleContentForProcess {...{ createdAt, failedAt, finishedAt, startedAt }} />
            </>
          }
        />
      </Box>
    </Box>
  );
}
