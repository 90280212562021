import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Chip } from '@material-ui/core';

import { FinmonitoringFilterStateField } from './FinmonitoringFilterStateField';
import { FinmonitoringContext } from '../FinmonitoringContext';

export default function FinmonitoringFilterState() {
  const { state, updateFilter } = useContext(FinmonitoringContext);
  const [values, setValues] = useState(Object.entries(state.filter));

  const handleResetFilterKey = useCallback(
    (fieldName) => () => {
      const currentState = { ...state.filter };
      delete currentState[fieldName];
      updateFilter(currentState);
    },
    [state.filter, updateFilter],
  );

  useEffect(() => setValues(Object.entries(state.filter)), [state.filter]);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {values.map(([fieldName, value]) => (
        <Chip
          key={fieldName}
          label={<FinmonitoringFilterStateField fieldName={fieldName} value={value} />}
          onDelete={handleResetFilterKey(fieldName)}
          variant="outlined"
        />
      ))}
    </Box>
  );
}
