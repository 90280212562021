import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

import { initialPDFRendererState, reducer } from './reducer';

export const PDFRendererContext = createContext({
  state: initialPDFRendererState,
  dispatch: () => null,
});

export const PDFRendererProvider = ({ children, mainState }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialPDFRendererState,
    mainState,
  });

  return (
    <PDFRendererContext.Provider value={{ state, dispatch }}>
      {children}
    </PDFRendererContext.Provider>
  );
};

PDFRendererProvider.propTypes = {
  children: PropTypes.node,
  mainState: PropTypes.any,
};