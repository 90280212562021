import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";

import CurrencyFormatter from "../../../components/FormattedCurrency/FormattedCurrency";
import { PhoneField } from "../../../components/PhoneField/PhoneField";
import { SHOP_ORDERS_CREATE } from "../graphql/mutations/shopOrdersCreate";
import { GET_SHOP_GOODS } from "../graphql/queries/getShopGoods";

ModalCreateOrders.propTypes = {
  onCloseModal: PropTypes.func,
  refetchOrders: PropTypes.func,
};

export default function ModalCreateOrders({ onCloseModal, refetchOrders }) {
  const {
    control,
    errors,
    handleSubmit,
    reset
  } = useForm({
    mode: "all",
    shouldFocusError: false,
  });

  const onHandlerCloseModal = () => {
    if (onCloseModal && typeof onCloseModal === "function") {
      onCloseModal()
    }

    if (refetchOrders && typeof refetchOrders === "function" && status === "success") {
      refetchOrders();
    }

    reset();
    setStatus('init');
  }

  const [fetchShopGoods, {
    data: dataShopGoods,
    loading: loadingShopGoods
  }] = useLazyQuery(GET_SHOP_GOODS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchShopGoods({
      variables: {
        sort: {
          field: "createdAt",
          order: "DESC"
        },
        filter: {
          statusEq: "PUBLISHED"
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [goods, setGoods] = useState([]);

  useEffect(() => {
    if (dataShopGoods?.shopGoods?.goods?.collection) {
      setGoods(dataShopGoods.shopGoods.goods.collection);
    }
  }, [dataShopGoods]);

  const [status, setStatus] = useState('init')

  const [shopOrdersCreate, { loading: loadingShopOrdersCreate,  }] = useMutation(
    SHOP_ORDERS_CREATE,
    {
      onCompleted: () => setStatus('success'),
      onError: () => setStatus('error'),
    },
  );

  const onSubmit = data => {
    const { email, fullName, phone, shopGoodId } = data

    shopOrdersCreate({
      variables: {
        params: {
          fullName,
          phone: phone.replace(/[^\d;]/g, ''),
          email,
          shopGoodId: +shopGoodId.id,
        }
      },
    })
  };

  const renderContentInit = () => {
    return (
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box>
            <Typography sx={{ marginBottom: '6px' }}>Фамилия Имя Отчество</Typography>

            <Controller
              control={control}
              name="fullName"
              render={({ onChange, ...props }) => (
                <TextField
                  fullWidth
                  helperText={errors?.fullName?.message}
                  onChange={val => onChange(val)}
                  placeholder="Фамилия Имя Отчество"
                  type="text"
                  {...props}
                />
              )}
              rules={{
                required: "Укажите ФИО"
              }}
            />
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Typography sx={{ marginBottom: '6px' }}>Телефон</Typography>

            <Controller
              control={control}
              name="phone"
              render={({ onChange, ...props }) => (
                <TextField
                  InputProps={{ inputComponent: PhoneField }}
                  fullWidth
                  helperText={errors?.phone?.message}
                  onChange={val => onChange(val)}
                  placeholder="+7 (***) ***-**-**"
                  type="text"
                  {...props}
                />
              )}
              rules={{
                required: "Укажите номер телефона",
                pattern: {
                  value: /(^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$)/i,
                  message: "Введите корректный номер телефона"
                }
              }}
            />
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Typography sx={{ marginBottom: '6px' }}>Электронная почта</Typography>

            <Controller
              control={control}
              name="email"
              render={({ onChange, ...props }) => (
                <TextField
                  fullWidth
                  helperText={errors?.email?.message}
                  onChange={val => onChange(val)}
                  placeholder="mail@mail.ru"
                  type="text"
                  {...props}
                />
              )}
              rules={{
                required: "Укажите почту",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Введите корректную почту"
                }
              }}
            />
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Typography sx={{ marginBottom: '6px' }}>Товары</Typography>

            <Controller
              control={control}
              name="shopGoodId"
              render={({ onChange, ...props }) => (
                <Autocomplete
                  disablePortal
                  disabled={loadingShopGoods}
                  fullWidth
                  getOptionLabel={(option) => {
                    return `ID: ${option?.id || '---'} | ${option?.name}`
                  }}
                  onChange={(e, data) => onChange(data)}
                  options={goods}
                  renderInput={(params) => <TextField {...params} helperText={errors?.shopGoodId?.message} placeholder={"Выберите товар"} />}
                  renderOption={(props,option) => {
                    return (
                      <li {...props}>
                        {/* eslint-disable-next-line react/prop-types */}
                        ID: {option?.id || '---'} | {option?.name} | Цена: <CurrencyFormatter value={option?.priceWithDiscount} />
                      </li>
                    )
                  }}
                  {...props}
                />
              )}
              rules={{
                required: "Обязательно для заполнения"
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={loadingShopOrdersCreate}
            type="submit"
          >
            Продолжить
          </Button>
        </DialogActions>
      </Box>
    )
  }

  const renderContentError = () => {
    return (
      <Box sx={{ padding: '16px 24px' }}>
        Произошла ошибка
      </Box>
    )
  }

  const renderContentSuccess = () => {
    return (
      <Box sx={{ padding: '16px 24px' }}>
        Заказ создан
      </Box>
    )
  }

  return (
    <Dialog
      onClose={onHandlerCloseModal}
      open={true}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '682px',
          margin: {
            xs: '0',
            sm: '32px',
          }
        },
      }}
    >
      <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Создание заказа</Typography>

        <IconButton onClick={onHandlerCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {status === 'init' && renderContentInit()}
      {status === 'error' && renderContentError()}
      {status === 'success' && renderContentSuccess()}
    </Dialog>
  )
}