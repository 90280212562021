import PropTypes from "prop-types";
import React from 'react';

import { Link } from '@material-ui/core';

const GoodAdditionalInfo = ({ goodInfo }) => {
  const elSuppliingOrderNumber = () => { // номер заказ
    if(goodInfo?.shippedEquipment?.displaySupplyingOrderNumber && goodInfo?.shippedEquipment?.displaySupplyingOrderUrl) {
      return (
        <Link href={goodInfo.shippedEquipment.displaySupplyingOrderUrl}>{goodInfo.shippedEquipment.displaySupplyingOrderNumber}</Link>
      );
    }

    return '--';
  }

  const elDispute = () => { // номер дела
    if(goodInfo?.storageAcceptance?.displayDisputeNumber && goodInfo?.storageAcceptance?.displayDisputeUrl) {
      return (
        <Link href={goodInfo.storageAcceptance.displayDisputeUrl}>{goodInfo.storageAcceptance.displayDisputeNumber}</Link>
      );
    }

    return '--';
  }

  const elLeaseAgreement = () => { // номер сделки
    if(goodInfo?.storageAcceptance?.dispayLoanLeaseAgreement) {
      return goodInfo.storageAcceptance.dispayLoanLeaseAgreement;
    }

    return '--';
  };

  return (
    <>
      {elLeaseAgreement()} / {elSuppliingOrderNumber()} / {elDispute()}
    </>
  );
}

GoodAdditionalInfo.propTypes = {
  goodInfo: PropTypes.object
};

export default GoodAdditionalInfo;