import PropTypes from "prop-types";
import React, { useState, useCallback, useMemo } from 'react';

import { useMutation } from "@apollo/client";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {
  styled, alpha
} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { SHOP_GOOD_SET_STATUS } from '../../../graphql/mutations/shopGoodSetsStatus';

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color: '#6E7884',
    backgroundColor: '#fff',
    padding: '9px 0',
    boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.1)',
    marginTop: '52px',

    '& .MuiMenuItem-root': {
      fontSize: 16,
      color: theme.palette.text.secondary,
      padding: '12px 16px',

      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ChangeStatus = ({ id, onChangeStatus, onChangeStatusError, status }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [canClick, setCanClick] = useState(true);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [changeStatus] = useMutation(SHOP_GOOD_SET_STATUS, {
    onCompleted: (result) => {
      onChangeStatus(result);
      handleClose();
      setCanClick(true);
    },
    onError: () => {
      onChangeStatusError();
      handleClose();
      setCanClick(true);
    },
  });

  const onSetStatus = useCallback((setStatus) => (e) => {
    if(canClick) {
      setCanClick(false);
      changeStatus({
        variables: {
          transition: setStatus,
          goodId: id,
        }
      });
    }
  }, [canClick, id, changeStatus]);

  const elMenuItems = useMemo(() => {
    if(status === 'READY_FOR_PUBLISH') {
      return (
        <MenuItem disableRipple onClick={onSetStatus('PUBLISH')}>
          Опубликовать
        </MenuItem>
      );
    }

    if(status === 'PUBLISHED') {
      return (
        <MenuItem disableRipple onClick={onSetStatus('UNPUBLISH')}>
          Снять с публикации
        </MenuItem>
      );
    }

    if(status === 'IN_STOCK') {
      return (
        <MenuItem disableRipple onClick={onSetStatus('PREPARE_TO_PUBLISH')}>
          Подготовить к публикации
        </MenuItem>
      );
    }
  }, [onSetStatus, status]);

  if(status === 'READY_FOR_PUBLISH' || status === 'PUBLISHED' || status === 'IN_STOCK') {
    return (
      <div style={{ height: '36px'}}>
        <Button
          aria-controls={open ? 'status-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          disableElevation
          endIcon={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          id="status-button"
          onClick={handleClick}
          sx={{
            background: '#3D6CE7',
            borderRadius: '6px',
            width: '137px',
            height: '48px',
            fontSize: '16px',
            fontWeight: 500,
            color: '#ffffff',
            textTransform: 'none',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            '&:hover': {
              background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #3D6CE7',
              boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
            },
            '&:active': {
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #3D6CE7',
              boxShadow: 'none',
            },
            '&:disabled': {
              background: '#e8e8e8',
              color: '#868E98',
            },
          }}
          variant="contained"
        >
          Действия
        </Button>
        <StyledMenu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          id="status-menu"
          onClose={handleClose}
          open={open}
        >
          {elMenuItems}
        </StyledMenu>
      </div>
    );
  }
  
  return '';
}

ChangeStatus.propTypes = {
  id: PropTypes.number,
  onChangeStatus: PropTypes.func,
  onChangeStatusError: PropTypes.func,
  status: PropTypes.string
};

export default ChangeStatus;