import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { FormattedMessage } from 'react-intl';

const Tab = ({ isActive, onChange, value }) => {
  const handleChange = useCallback(() => {
    if (onChange instanceof Function) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <Box
      onClick={handleChange}
      sx={{
        cursor: isActive ? 'default' : 'pointer',
        backgroundColor: isActive ? 'whitesmoke' : '#e0e0e0',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        p: 2,
        height: 1,
        display: 'flex',
        alignItems: 'center',
        transition: 'background-color 0.15s ease',
        userSelect: 'none',
        whiteSpace: 'nowrap',

        ...(!isActive ? { '&:hover': { backgroundColor: '#edeaea' } } : {}),
      }}
    >
      <FormattedMessage id={`finmonitoring.messagesStatus.${value}`} />
    </Box>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default function MessageTypesStatusBar() {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const statuses = ['SENT', 'PENDING', 'REJECTED', ''];

  const updateSearchLocation = useCallback((state) => {
    const query = pickBy({ ...parsedQuery, page: 1, state }, identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);
  }, [location.pathname, parsedQuery]);

  const [activeTab, setActiveTab] = useState(parsedQuery.state || statuses[1]);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
    updateSearchLocation(tab);
  }, [updateSearchLocation]);

  useEffect(() => {
    if (!parsedQuery.state) {
      updateSearchLocation('PENDING');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1, height: 36 }}
    >
      {statuses.map((tab) => (
        <Tab
          isActive={activeTab === tab}
          key={tab}
          onChange={activeTab !== tab ? handleTabChange : undefined}
          value={tab}
        />
      ))}
    </Box>
  );
}