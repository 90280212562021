import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import DayJsUtils from '@date-io/dayjs';
import { makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import noop from 'lodash/noop';

dayjs.locale({
  ...ru,
  weekStart: 0,
})

const INVALID_DATE_ERROR_MESSAGE = 'Неверный формат даты';

export const DatePickerBox = ({
  disabled,
  hasError,
  label,
  maxDate,
  maxDateMessage = INVALID_DATE_ERROR_MESSAGE,
  minDate,
  minDateMessage = INVALID_DATE_ERROR_MESSAGE,
  onChange,
  onError,
  placeholder,
  required,
  size,
  value,
  variant = 'inline',
  sx={},
}) => {
  const styles = {
    picker: {
      width: 1,

      '& .MuiFormHelperText-root': {
        position: 'absolute',
        top: '100%',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        m: 0,
        left: 0,
        p: 0,
      },

      ...sx,
    },
  };

  const handleError = useCallback((error, value) => {
    if (onError instanceof Function) {
      onError(Boolean(error));
    }
  }, [onError]);

  return (
    <MuiPickersUtilsProvider libInstance={dayjs} locale="ru" utils={DayJsUtils}>
      <KeyboardDatePicker
        ToolbarComponent={DatePickerToolbar}
        autoOk
        disabled={disabled}
        error={hasError}
        format="DD.MM.YYYY"
        invalidDateMessage={INVALID_DATE_ERROR_MESSAGE}
        label={Boolean(label) ? label : undefined}
        mask="__.__.____"
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        minDate={minDate}
        minDateMessage={minDateMessage}
        onChange={onChange}
        onError={handleError}
        openTo="date"
        placeholder={!Boolean(label) ? placeholder : undefined}
        required={required}
        size={size}
        sx={styles.picker}
        value={value}
        variant={variant}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerBox.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.object,
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  sx: PropTypes.object,
  value: PropTypes.string,
  variant: PropTypes.string,
};

DatePickerBox.defaultProps = {
  disabled: false,
  hasError: false,
  onChange: noop,
  placeholder: '',
  size: 'medium',
  value: '',
  sx: {},
};

const useStyles = makeStyles({
  toolbar: {
    minHeight: 'auto',
    height: 'auto',
    marginTop: 16,
  },
});

const DatePickerToolbar = ({
  date,
  isLandscape,
  openView,
  setOpenView,
  title,
}) => {
  const handleChangeView = useCallback(
    (view) => () => setOpenView(view),
    [setOpenView],
  );

  const classes = useStyles();

  return (
    <PickerToolbar
      className={classes.toolbar}
      isLandscape={isLandscape}
      title={title}
    >
      <ToolbarButton
        label={date.format(openView === 'year' ? 'dd, DD MMM' : 'YYYY')}
        onClick={handleChangeView(openView === 'year' ? 'date' : 'year')}
        selected={false}
        variant="h5"
      />
    </PickerToolbar>
  );
};

DatePickerToolbar.propTypes = {
  date: PropTypes.object,
  isLandscape: PropTypes.bool.isRequired,
  openView: PropTypes.object.isRequired,
  setOpenView: PropTypes.func.isRequired,
  title: PropTypes.string,
};