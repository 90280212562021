import React from 'react';

import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Settings } from 'luxon';

import NotificationsProvider from 'components/Notifications/NotificationsProvider';

import ApolloWrapper from './ApolloWrapper';
import App from './App';
import { AppProvider } from './AppContext';
import IntlWrapper from './IntlWrapper';
import ThemeWrapper from './ThemeWrapper';

Settings.defaultLocale = 'ru';

export default function Root() {
  return (
    <React.StrictMode>
      <ApolloWrapper>
        <ThemeWrapper>
          <IntlWrapper>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <AppProvider>
                <NotificationsProvider>
                  <App />
                </NotificationsProvider>
              </AppProvider>
            </MuiPickersUtilsProvider>
          </IntlWrapper>
        </ThemeWrapper>
      </ApolloWrapper>
    </React.StrictMode>
  );
}