import { gql } from '@apollo/client/core';

export const SHOP_ORDERS_CREATE = gql`
  mutation ShopOrdersCreate($params: ShopOrderCreateParams!) {
    shopOrdersCreate(params: $params) {
      order {
        id
      }
    }
  }
`;