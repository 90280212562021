import currency from 'currency.js';

export function calcPaymentScheduleTally(rows) {
  if (!rows.length) {
    return { fullAmount: 0, baseAmount: 0, commissionAmount: 0, vatAmount: 0 };
  }
  return rows.reduce(
    (prev, tally, index) => ({
      fullAmount: currency((prev.fullAmount || 0) + tally.fullAmount).value,
      baseAmount: currency((prev.baseAmount || 0) + tally.baseAmount).value,
      commissionAmount: currency((prev.commissionAmount || 0) + tally.commissionAmount).value,
      vatAmount: currency((prev.vatAmount || 0) + tally.vatAmount).value,
    }),
    {},
  );
}

// Выделить НДС
export function selectVAT(amount, vatRate) {
  return currency(amount - amount / (1 + vatRate)).value;
}

// Начислить НДС
export function chargeVAT(amount, vatRate) {
  return currency(amount * vatRate).value;
}

// Сумма основного долга без НДС
export function calcNIL(amount, vatRate) {
  return currency(amount / (1 + vatRate)).value;
}

export function calcMonthlyNIL(leasingAmount, term, vatRate) {
  const monthlyAmount = currency(leasingAmount / term).value;
  return calcNIL(monthlyAmount, vatRate);
}

export function calcMonthlyFEE(leasingAmount, monthlyInterestRate) {
  return currency(leasingAmount * monthlyInterestRate).value;
}

export function calcMonthlyVAT(monthlyNIL, monthlyFEE, vatRate) {
  const amount = currency(monthlyNIL + monthlyFEE).value;
  return chargeVAT(amount, vatRate);
}

export function calcAdvance(totalAmount, advanceRate) {
  return currency(totalAmount * advanceRate).value;
}

export function calcLeasingAmount(totalAmount, advance) {
  return currency(totalAmount - advance).value;
}

function buildAdvancePaymentRecord({ advance, advanceVatRate }) {
  const advanceNIL = calcNIL(advance, advanceVatRate); // 78 678.03 руб.
  const advanceVAT = chargeVAT(advanceNIL, advanceVatRate); // 78 678.03 руб.

  return {
    type: 'advance',
    baseAmount: advanceNIL,
    commissionAmount: 0,
    fullAmount: advance,
    vatAmount: advanceVAT,
  };
}

function buildMonthlyPaymentRecord({
  leasingAmount,
  monthlyInterestRate,
  monthlyPayment,
  vatRate,
}) {
  const monthlyNIL = calcNIL(monthlyPayment, vatRate);
  const monthlyFEE = calcMonthlyFEE(leasingAmount, monthlyInterestRate);
  const monthlyVAT = chargeVAT(monthlyNIL + monthlyFEE, vatRate);
  const fullAmount = currency(monthlyNIL + monthlyFEE + monthlyVAT).value;

  return {
    type: 'monthly',
    fullAmount,
    baseAmount: monthlyNIL,
    commissionAmount: monthlyFEE,
    vatAmount: monthlyVAT,
  };
}

function buildRedemptionPaymentRecord({ redemptionPayment, vatRate }) {
  const redemptionNIL = calcNIL(redemptionPayment, vatRate);
  const redemptionVAT = chargeVAT(redemptionNIL, vatRate);

  return {
    type: 'redemption',
    baseAmount: redemptionNIL,
    commissionAmount: 0,
    fullAmount: redemptionPayment,
    vatAmount: redemptionVAT,
  };
}

export function buildPaymentSchedule({
  advanceRate,
  advanceVatRate,
  monthlyInterestRate,
  redemptionPayment,
  term,
  totalAmount,
  vatRate,
}) {
  let paymentSchedule = [];

  const advance = calcAdvance(totalAmount, advanceRate);
  const leasingAmount = calcLeasingAmount(totalAmount, advance);
  const monthlyPayment = currency(leasingAmount / term).value;

  const advancePaymentRecord = buildAdvancePaymentRecord({ advance, advanceVatRate });

  paymentSchedule.push(advancePaymentRecord);

  const monthlyPaymentRecord = buildMonthlyPaymentRecord({
    leasingAmount,
    monthlyPayment,
    vatRate,
    monthlyInterestRate,
  });

  paymentSchedule.push(...Array(term).fill(monthlyPaymentRecord));

  const redemptionPaymentRecord = buildRedemptionPaymentRecord({ redemptionPayment, vatRate });

  paymentSchedule.push(redemptionPaymentRecord);

  return paymentSchedule;
}

export function rebuildPaymentSchedule({
  advance,
  currentPaymentSchedule,
  monthlyInterestRate,
  newTotalAmount,
  paymentIndex,
  redemptionPayment,
  term,
  totalAmount,
  vatRate,
}) {
  const persistedRecords = currentPaymentSchedule.slice(0, paymentIndex);
  // const advance = persistedRecords.find(record => record.type === 'advance');
  const { baseAmount: paidNIL } = calcPaymentScheduleTally(persistedRecords);

  const { monthlyPaymentNumber } = currentPaymentSchedule[paymentIndex];
  const period = monthlyPaymentNumber ? term - monthlyPaymentNumber + 1 : term;

  const leasingAmount = calcLeasingAmount(newTotalAmount || totalAmount, advance);

  const restAmount = monthlyPaymentNumber
    ? currency((newTotalAmount || totalAmount) - paidNIL * (1 + vatRate)).value
    : currency((newTotalAmount || totalAmount) - paidNIL * (1 + vatRate) - advance).value;

  const monthlyPayment = currency(restAmount / period).value;

  const monthlyPaymentRecord = buildMonthlyPaymentRecord({
    leasingAmount,
    monthlyPayment,
    vatRate,
    monthlyInterestRate,
  });

  if (newTotalAmount) {
    const newPaymentTimeline = currentPaymentSchedule.map((record, index) => {
      if (index < paymentIndex) {
        return record;
      }
      if (record.type === 'advance') {
        const advancePaymentRecord = buildAdvancePaymentRecord({
          advance,
          advanceVatRate: vatRate,
        });
        return { ...record, ...advancePaymentRecord };
      }

      if (record.type === 'monthly') {
        return { ...record, ...monthlyPaymentRecord };
      }

      if (record.type === 'redemption') {
        const redemptionPaymentRecord = buildRedemptionPaymentRecord({
          redemptionPayment,
          vatRate,
        });
        return { ...record, ...redemptionPaymentRecord };
      }

      return record;
    });

    return newPaymentTimeline;
  } else {
    const redemptionPaymentRecord = buildRedemptionPaymentRecord({
      redemptionPayment: restAmount + redemptionPayment,
      vatRate,
    });

    return [
      ...persistedRecords,
      { ...redemptionPaymentRecord, startAt: persistedRecords.slice(-1)[0].startAt },
    ];
  }
}
