import { gql } from '@apollo/client';

export const GET_SHOP_STORAGE_ACCEPTANCE_GOOD_DEFAULT_VALUES = gql`
  query shopStorageAcceptanceGoodDefaultValues($storageAcceptanceId: ID!, $shippedEquipmentId: ID!) {
    shopStorageAcceptanceGoodDefaultValues(storageAcceptanceId: $storageAcceptanceId, shippedEquipmentId: $shippedEquipmentId) {
      currentLocation
      currentLocationAddress
      locationAddressForSite
      description
      discount
      name
      producedAt
      quality
      serialNumber
      shippedEquipmentId
      status
      storageAcceptanceId
      storageComment
      vat
      sellingPrice
      shippedEquipment {
        quantity
        shopGoodsCount
        supplier {
          shortLegalName
          url
        }
        loanSupply {
          id
          date
          supplyingOrder {
            id
            number
            url
          }
        }
      }
    }
  }
`;