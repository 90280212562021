import { gql } from '@apollo/client/core';

export const GET_ENTITIES = gql`
  query GetEntities(
    $page: Int,
    $limit: Int,
    $filters: EntityFilters
  ) {
    entities(page: $page, limit: $limit, filters: $filters) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        legalNameWithShortEntityType
        tin
      }
    }
  }
`;