import 'react-json-pretty/themes/monikai.css';
import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import JSONPretty from 'react-json-pretty';

import BackdropLoader from 'components/BackdropLoader';
import ErrorAlert from 'components/ErrorAlert';

import {
  GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES_FULL_DATA,
} from '../graphql/queries/criminalActivityCheckProcessesFullData';

CriminalActivityCheckProcess.propTypes = {
  contactData: PropTypes.shape({
    id: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    passportNumber: PropTypes.string,
    passportSeries: PropTypes.string,
  }),
  financialFraud: PropTypes.array,
  id: PropTypes.any,
  terrorism: PropTypes.array,
  weaponDistribution: PropTypes.array,
};

const mainStyle =
  'white-space:pre-wrap; padding-left:16px;padding-right:16px;border-radius:4px;margin-bottom:32px;margin-top:16px;';

function CriminalActivityCheckProcess({
  contactData,
  financialFraud,
  id,
  terrorism,
  weaponDistribution,
}) {
  return (
    <Box key={id} mb={6} mt={4} px={4}>
      <Typography variant="h1">Проверка по базам Росфинмониторинга</Typography>
      <Box my={4}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          {contactData.lastName} {contactData.firstName} {contactData.middleName}
        </Typography>
        <Typography color="textSecondary" variant="subtitle2">
          Дата рождения:&nbsp;
          <Typography color="textPrimary" component="span" variant="h5">
            {DateTime.fromISO(contactData.dateOfBirth)
              .toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        </Typography>
        <Typography color="textSecondary" variant="subtitle2">
          Паспорт:&nbsp;
          <Typography color="textPrimary" component="span" variant="h5">
            {contactData.passportSeries}&nbsp;{contactData.passportNumber}
          </Typography>
        </Typography>
      </Box>
      <Typography paragraph variant="h2">
        Причастность к финансированию терроризма
      </Typography>
      {financialFraud.length ? (
        <JSONPretty data={financialFraud} mainStyle={mainStyle} />
      ) : (
        'Информации не найдено'
      )}
      <Typography paragraph variant="h2">
        Причастность к экстремистской деятельности или терроризму
      </Typography>
      {terrorism.length ? (
        <JSONPretty data={terrorism} mainStyle={mainStyle} />
      ) : (
        'Информации не найдено'
      )}
      <Typography paragraph variant="h2">
        Причастность к распространению оружия массового уничтожения
      </Typography>
      {weaponDistribution.length ? (
        <JSONPretty data={weaponDistribution} mainStyle={mainStyle} />
      ) : (
        'Информации не найдено'
      )}
    </Box>
  );
}

Rosfinmonitoring.propTypes = {
  contactId: PropTypes.any,
};

export default function Rosfinmonitoring({ contactId }) {
  const { data, error, loading, refetch } = useQuery(
    GET_CRIMINAL_ACTIVITY_CHECK_PROCESSES_FULL_DATA,
    { variables: { contactId } },
  );

  if (error) {
    return (
      <ErrorAlert
        action={
          <Button
            color="inherit"
            disabled={loading}
            onClick={() => refetch()}
            variant="outlined"
          >
            Повторить запрос
          </Button>
        }
        message={error.message}
        title="Ошибка при получении данных"
      />
    );
  }

  if (loading) {
    return <BackdropLoader />;
  }

  return (
    <Box>
      {data.criminalActivityCheckProcesses.map(CriminalActivityCheckProcess)}
    </Box>
  );
}
