import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Badge,
  Button,
  Collapse,
  IconButton,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { formatDate } from 'utils/dateTimeFormatters';

LayoutFileRow.propTypes = {
  file: PropTypes.shape({
    createdAt: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  icon: PropTypes.node,
  onCommentAdd: PropTypes.func,
  onFileDelete: PropTypes.func,
  onOriginalChange: PropTypes.func,
};

export default function LayoutFileRow({
  file,
  icon,
  onCommentAdd,
  onFileDelete,
  onOriginalChange,
}) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [original, setOriginal] = useState(false);

  const handleChangeComment = useCallback(
    ({ target: { value } }) => setComment(value),
    [],
  );

  const handleChangeOriginal = useCallback(
    ({ target: { checked } }) => setOriginal(checked),
    [],
  );

  const handleToggleComments = useCallback(() => setOpen((prev) => !prev), []);

  const handleDeleteFile = useCallback(
    () => onFileDelete(file.id),
    [file, onFileDelete],
  );

  useEffect(
    () => onCommentAdd(file.id, comment),
    [file, comment, onCommentAdd],
  );

  useEffect(
    () => onOriginalChange(file.id, original),
    [file, original, onOriginalChange],
  );

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={{ py: '10px' }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            {icon}
            <Typography
              noWrap
              sx={{ fontSize: 12, maxWidth: 180 }}
              variant="body1"
            >
              {file.name}
            </Typography>&nbsp;
            {file.createdAt && (
              <Typography sx={{ fontSize: 12 }}>
                {formatDate(file.createdAt, 'short_date_time')}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell sx={{ py: '10px' }}>
          <Switch
            checked={original}
            onChange={handleChangeOriginal}
            size="small"
          />
          <Button
            color="primary"
            onClick={handleToggleComments}
            sx={{
              fontSize: 18,
              pt: 1,
              pb: '5px',
              px: '6px',
              minWidth: 'auto',
              ml: 1,
              color: open ? 'white' : 'primary',
            }}
            variant={open ? 'contained' : 'text'}
          >
            <Badge
              color="primary"
              invisible={comment.trim() === '' || open}
              sx={{ height: 15, minWidth: 15, fontWeight: 600 }}
              variant={comment.trim() !== '' ? 'dot' : 'standard'}
            >
              <ChatBubbleOutlineOutlinedIcon fontSize="inherit" />
            </Badge>
          </Button>
          <IconButton onClick={handleDeleteFile} size="small">
            <DeleteForeverIcon fontSize="small" htmlColor="#D74E51" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell colSpan="2" sx={{ py: open ? 1 : 0 }}>
          <Collapse in={open} sx={{ mb: 1 }} timeout="auto" unmountOnExit>
            <TextField
              autoFocus
              fullWidth
              label="Комментарий"
              multiline
              onChange={handleChangeComment}
              size="small"
              sx={{ mt: 2 }}
              value={comment}
            />
            <Typography sx={{ color: '#838080', fontSize: 12, mt: 1 }}>
              Укажите особые примечания к документу или дополнительную{' '}
              информацию.
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}