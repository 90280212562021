/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Card, CardActionArea, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';

import ErrorAlert from 'components/ErrorAlert';

import { GET_ENTITY_DATA } from './graphql/queries/entityData';

PageLegalEntityItem.propTypes = {
  entityId: PropTypes.any,
};

export default function PageLegalEntityItem({ entityId }) {
  const { data, error, loading } = useQuery(GET_ENTITY_DATA, {
    variables: { entityId },
  });

  if (error && !data) {
    return (
      <ErrorAlert
        message={error.message}
        title="Ошибка при получении данных"
      />
    );
  }

  if (loading) {
    return 'Загрузка...';
  }

  if (!data.entity) {
    return `Компания с id = ${entityId} не найдена`;
  }

  const { entity } = data;

  return (
    <Box>
      <Link
        color="inherit"
        component={RouterLink}
        to="/entities"
        underline="none"
        variant="h3"
      >
        {entity.shortLegalName}
      </Link>
      <Typography variant="body1">{entity.psrn}</Typography>
      <Typography variant="body1">{entity.tin}</Typography>
      <Typography variant="body1">{entity.trrc}</Typography>
      <Box>
        <Card>
          <CardActionArea component={RouterLink} to="questionnaires">
            Анкеты
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
}
