import PropTypes from "prop-types";
import React, { useMemo } from 'react';

import {
  Box,
  DialogContent,
  Typography
} from "@material-ui/core";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import findURL from "../../utils/findURL";
import AvatarUser from "../AvatarUser";

CommentList.propTypes = {
  messages: PropTypes.array,
};

CommentList.defaultProps = {
  messages: [],
}

export default function CommentList ({ messages }) {
  const styles = useMemo(() => ({
    wrapperComment: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '20px 24px',
    },
    boxUser: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    infUser: {
      display: 'flex',
    },
    amocrmAuthor: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 500,

      '& span': {
        paddingLeft: '6px',
        color: '#27b394',
      }
    },
    userData: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 40,
      lineHeight: '16px',
      marginLeft: '15px',
    },
    userName: {
      fontSize: 14,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',

    },
    userRole: {
      color: '#91919c',
      fontSize: 14,
    },
    dateUserComment: {
      display: 'flex',
      flexDirection: 'column-reverse',
      fontSize: '14px',
      color: '#91919c',
      position: 'relative',
    },
    dateUserPriority: {
      position: 'absolute',
      top: '-5px',
      right: 0,
      fontSize: '10px',
      padding: '0 4px',
      color: '#ffffff',
      borderRadius: '3px',
    },
    userComment: {
      fontSize: '14px',
      wordWrap: 'break-word',
    }
  }), [])

  const bgColorPriority = type => {
    let color = null;

    switch (type) {
      case 'INFORM':
        color = '#5bc0de';
        break;
      case 'URGENT':
        color = '#d9534f';
        break;
      case 'ACTION':
        color = '#5cb85c';
        break;
      case 'CONFIDENTIALLY':
        color = '#000000';
        break;

      default:
        color = '#5bc0de';
    }

    return color;
  }

  return (
    messages.length > 0 && (
      messages.map(elem => {
        return <DialogContent key={elem.id} sx={styles.wrapperComment} >
          <Box sx={styles.boxUser}>
            <Box sx={styles.infUser}>
              <AvatarUser
                avatar={elem.author?.avatar}
                firstName={elem.author?.contact?.firstName}
                lastName={elem.author?.contact?.lastName}
              />

              {elem.amocrmAuthor && (
                <Box sx={styles.userData}>
                  <Box sx={styles.amocrmAuthor}>
                    {elem.amocrmAuthor} <span>(amoCRM)</span>
                  </Box>
                </Box>
              )}

              {elem.author && (
                <Box sx={styles.userData}>
                  <Box sx={styles.userName}>
                    {elem.author.displayName}
                  </Box>

                  <Box sx={styles.userRole}>
                    {elem.author.roles[0] && (
                      <FormattedMessage id={`user.role.${elem.author.roles[0]}`} />
                    )}
                  </Box>
                </Box>
              )}
            </Box>

            <Box sx={styles.dateUserComment}>
              {dayjs(elem.createdAt).format('DD.MM.YYYY HH:mm')}

              {elem.priority && (
                <Box sx={{ ...styles.dateUserPriority, backgroundColor: bgColorPriority(elem.priority)}}>
                  <FormattedMessage id={`note.type.${elem.priority}`} />
                </Box>
              )}
            </Box>
          </Box>

          <Typography dangerouslySetInnerHTML={{__html: findURL(elem?.body?.replace(/\n/gi, " <br/> ")) }} sx={styles.userComment} />
        </DialogContent>
      })
    )
  )
}