export const useProblems = (problems) => {
  const general = [];
  const special = {
    documents: [],
    operationParticipants: [],
  };

  problems.forEach((problem) => {
    if ((!problem.path.length || problem.path.length < 5) &&
      problem.type === 'INVALID_DATA'
    ) {
      general.push(problem);
    } else {
      if (problem.type === 'INVALID_DATA') {
        const [, , dataArea, dataIndex, ...fieldName] = problem.path;

        const problemData = {
          context: problem.context,
          fieldName: fieldName.join('.'),
          index: Number(dataIndex),
          messages: problem.messages,
        };

        special[dataArea].push(problemData);
      }
    }
  });

  return { general, special };
};