import { gql } from '@apollo/client';

export const ENTITY_ACCEPT_CONTACT_QUESTIONNAIRE = gql`
  mutation EntityAcceptContactQuestionnaire($questionnaireId: ID!) {
    entityAcceptContactQuestionnaire(contactEntityQuestionnaireId: $questionnaireId) {
      entity {
        id
        questionnaires {
          state
          updatedAt
        }
      }
    }
  }
`;