import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import {
  CustomPaging,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Checkbox } from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import identity from 'lodash.identity';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { useIntl } from 'react-intl';

import ErrorAlert from 'components/ErrorAlert';
import { client } from 'core/ApolloWrapper';

import AssignModal from './AssignModal';
import {
  columnExtensions,
  columnsModelCreator,
  DOCUMENTS_LIMIT,
  pagingPanelMessages,
  getRowId,
  FilterStatusMap,
  DocumentStatusMap,
} from './constants';
import { GridRoot, Loader } from './helpers';
import {
  DocumentActionTypeProvider,
  DocumentNameTypeProvider,
  DocumentOriginalTypeProvider,
  DocumentStatusTypeProvider,
} from './helpers/documentDataProviders';
import OcrDataModal from './OcrDataModal';
import PreviewModal from './PreviewModal';
import UploadOriginalModal from './UploadOriginalModal';
import {
  SOURCE_DOCUMENTS_CHECK_RECOGNITION_RESULT,
} from '../graphql/mutations/sourceDocumentsCheckRecognitionResult';
import { GET_SOURCE_DOCUMENTS } from '../graphql/queries/sourceDocuments';

class PatchedIntegratedSelection extends React.Component {
  render() {
    return (
      <Plugin>
        <Getter
          computed={({ rows }) => {
            this.rows = rows;
            return rows.filter(
              (row) => row.status !== DocumentStatusMap.canceled,
            );
          }}
          name="rows"
        />
        <IntegratedSelection {...this.props} />
        <Getter computed={() => this.rows} name="rows" />
      </Plugin>
    )
  }
};

DocumentsTable.propTypes = {
  fetchPolicy: PropTypes.string,
  selection: PropTypes.array,
  setCanGlobDrag: PropTypes.func,
  setSelection: PropTypes.func,
  setToolbarTotal: PropTypes.func,
};

export default function DocumentsTable({
  fetchPolicy = 'cache-first',
  selection,
  setCanGlobDrag,
  setSelection,
  setToolbarTotal,
}) {
  const intl = useIntl();

  const [fetchDocuments, { data, error, loading }] = useLazyQuery(
    GET_SOURCE_DOCUMENTS,
    { fetchPolicy },
  );

  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [assignedObject, setAssignedId] = useState(null);
  const [previewObject, setPreviewObject] = useState(null);
  const [ocrDataObject, setOcrDataObject] = useState(null);
  const [
    uploadOriginalDocumentId,
    setUploadOriginalDocumentId,
  ] = useState(null);

  const { currentPage, pageSize } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      currentPage: query.page ? Number(query.page) - 1 : 0,
      pageSize: query.limit ? Number(query.limit) : DOCUMENTS_LIMIT,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [pageSizes] = useState([10, 25, 50]);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [checkRecognitionResult] = useMutation(
    SOURCE_DOCUMENTS_CHECK_RECOGNITION_RESULT,
    {
      onCompleted: (res) => {
        const status = res?.sourceDocumentsCheckRecognitionResult?.sourceDocument?.recognizingProcess?.status;
        if (status) {
          alert(
            `Текущий статус: ${intl.formatMessage({
              id: `document.recognizingStatus.${status}`,
            })}`,
          );
        }
      },
      onError: () => alert(
        'Возникла ошибка при проверке статуса распознавания',
      ),
    },
  );

  const handleSetAssignedId = useCallback((id) => {
    const [targetId] = rows.filter((row) => row.id === id);
    setAssignedId(targetId);
    setCanGlobDrag(false);
  }, [rows, setCanGlobDrag]);

  const handleSetPreviewId = useCallback((id) => {
    const [targetId] = rows.filter((row) => row.id === id);
    setPreviewObject(targetId);
    setCanGlobDrag(false);
  }, [rows, setCanGlobDrag]);

  const handleShowOcrData = useCallback((target) => {
    setOcrDataObject(target);
    setCanGlobDrag(false);
  }, [setCanGlobDrag]);

  const handleCheckRecogitionResult = useCallback((documentId) => {
    checkRecognitionResult({ variables: { documentId } });
  }, [checkRecognitionResult]);

  const handleUploadOriginal = useCallback((documentId) => {
    setUploadOriginalDocumentId(documentId);
    setCanGlobDrag(false);
  }, [setCanGlobDrag]);

  const updateSearchLocation = useCallback((params) => {
    const parsedQuery = qs.parse(location.search);
    const query = pickBy({ ...parsedQuery, ...params }, identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);
  }, [location.search, location.pathname]);

  // Pagination handlers
  const handleCurrentPageChange = useCallback(
    (page) => updateSearchLocation({ page: page + 1 }),
    [updateSearchLocation],
  );

  const handlePageSizeChange = useCallback(
    (value) => updateSearchLocation({ limit: value === 0 ? totalCount : value }),
    [updateSearchLocation, totalCount],
  );

  const handleAssignModalClose = useCallback((value, forceReload) => {
    handleSetAssignedId(value);
    setCanGlobDrag(true);
    client.refetchQueries({ include: 'active' });
  }, [handleSetAssignedId, setCanGlobDrag]);

  const handlePreviewModalClose = useCallback((value) => {
    setPreviewObject(value);
    setCanGlobDrag(true);
  }, [setCanGlobDrag]);

  const handleOcrDataModalClose = useCallback(() => {
    setOcrDataObject(null);
    setCanGlobDrag(true);
  }, [setCanGlobDrag]);

  const handleUploadOriginalModalClose = useCallback(() => {
    setUploadOriginalDocumentId(null);
    setCanGlobDrag(true);
  }, [setCanGlobDrag]);

  useEffect(() => {
    const { limit, page, q, status, type } = pick(parsedQuery, [
      'limit',
      'page',
      'q',
      'status',
      'type'
    ]);

    setSelection([]);
    fetchDocuments({
      variables: {
        filter: status || q || type
          ? {
            ...(status ? FilterStatusMap[status] : {}),
            ...(q ? { innNameOrDocumentNumber: q } : {}),
            ...(type !== "NONE" ? { typeIdEq: Number(type) } : {})
          }
          : undefined,
        page: Number(page || 1),
        limit: Number(limit || DOCUMENTS_LIMIT),
      },
    });
  }, [setSelection, fetchDocuments, location.search, parsedQuery]);

  useEffect(() => {
    if (data) {
      const { sourceDocuments } = data;

      setRows(sourceDocuments.collection);
      setTotalCount(sourceDocuments.metadata.totalCount);
      setToolbarTotal(sourceDocuments.metadata.totalCount)
    }
  }, [data, setToolbarTotal]);

  return (
    <React.Fragment>
      <Grid
        {...{ getRowId, rows }}
        columns={columnsModelCreator}
        rootComponent={GridRoot}
      >
        <DocumentNameTypeProvider
          for={['name']}
          selectPreviewCallback={handleSetPreviewId}
        />
        <DocumentStatusTypeProvider for={['status']} />
        <DocumentOriginalTypeProvider for={['isOriginalReceived']} />
        <DocumentActionTypeProvider
          for={['actions']}
          selectAssignCallback={handleSetAssignedId}
          selectCheckRecognitionResult={handleCheckRecogitionResult}
          selectPreviewCallback={handleSetPreviewId}
          selectShowOcrDataCallback={handleShowOcrData}
          selectUploadOriginalCallback={handleUploadOriginal}
        />
        <SelectionState
          onSelectionChange={setSelection}
          selection={selection}
        />
        <PatchedIntegratedSelection />
        <RowDetailState />
        <PagingState
          currentPage={currentPage}
          defaultPageSize={pageSize}
          onCurrentPageChange={handleCurrentPageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalCount} />

        <VirtualTable
          cellComponent={(props) => (
            <VirtualTable.Cell className="DocumentsTableCell" {...props} />
          )}
          columnExtensions={columnExtensions}
          messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
        />
        <TableHeaderRow />
        <TableSelection
          cellComponent={(props) => {
            // eslint-disable-next-line react/prop-types
            return props?.row?.status === DocumentStatusMap.canceled
              ? (
                <VirtualTable.StubCell
                  {...props}
                  sx={{
                    textAlign: 'center',
                    opacity: 0.7,
                    pl: '4px !important',
                  }}
                >
                  <Checkbox disabled />
                </VirtualTable.StubCell>
              )
              : <TableSelection.Cell  {...props} />;
          }}
          showSelectAll
        />
        <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
        {error && (
          <ErrorAlert
            message={error.message}
            title="Ошибка при выполнении запроса"
          />
        )}
        {loading && <Loader />}
      </Grid>

      {assignedObject && (
        <AssignModal
          documentId={assignedObject.id}
          documentStatus={assignedObject.status}
          {...{ handleAssignModalClose }}
        />
      )}
      {previewObject && (
        <PreviewModal {...{ previewObject, handlePreviewModalClose }} />
      )}
      {ocrDataObject && (
        <OcrDataModal {...{ ocrDataObject, handleOcrDataModalClose }} />
      )}
      {uploadOriginalDocumentId && (
        <UploadOriginalModal
          documentId={uploadOriginalDocumentId}
          {...{ handleUploadOriginalModalClose }}
        />
      )}
    </React.Fragment>
  );
}