import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  CardContent,
  Divider,
  Stack,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DateTime } from 'luxon';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import {
  SOURCE_DOCUMENTS_UPLOAD_ORIGINAL,
} from 'modules/workspaces/graphql/mutations/sourceDocumentsUploadOriginal';

import DropzoneDocuments from './DropzoneDocuments';
import LayoutFileRow from './LayoutFileRow';

DocumentsGroup.propTypes = {
  documentId: PropTypes.string,
  onUpload: PropTypes.func,
};

export default function DocumentsGroup({ documentId = '', onUpload }) {
  const [files, setFiles] = useState([]);
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles, rejectionFiles) => {
      if (!!rejectionFiles.length) {
        alert('Допускается загрузка только одного файла!');
      }

      setFiles(
        acceptedFiles.map(
          (file) => Object.assign(file, {
            id: uuid(),
            createdAt: DateTime.now().setZone('utc'),
          }),
        ),
      );
    },
  });

  const handleDeleteFile = useCallback((fileId) => {
    setFiles(
      (currentFiles) => currentFiles.filter((file) => file.id !== fileId),
    );
  }, []);

  const [uploadOriginalDocuments, { loading: uploading }] = useMutation(
    SOURCE_DOCUMENTS_UPLOAD_ORIGINAL,
    {
      onCompleted: (res) => {
        const data = res?.sourceDocumentsUploadOriginal?.sourceDocument;
        const documentName = data
          ? `#${data?.id}.${data?.documentFilename}`
          : '';

        alert(`Оригинал документа ${documentName} успешно загружен`);

        if (onUpload instanceof Function) {
          onUpload();
        }
      },
      onError: () => alert('Ошибка загрузки оригинала'),
    },
  );

  const handleUploadOriginal = useCallback(() => {
    if (!!files.length) {
      uploadOriginalDocuments({
        variables: {
          file: files[0],
          documentId,
        },
      });
    }
  }, [documentId, files, uploadOriginalDocuments]);

  return (
    <Card elevation={5} sx={{ boxShadow: 'none' }}>
      {uploading && (
        <Box
          sx={{
            backgroundColor: '#fafafa',
            position: 'absolute',
            inset: 0,
            opacity: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!!files.length && (
        <React.Fragment>
          <CardContent>
            <Stack spacing={1}>
              {files.map((file, idx) => (
                <React.Fragment key={idx}>
                  <LayoutFileRow
                    file={file}
                    icon={
                      <InsertDriveFileIcon color="action" fontSize="small" />
                    }
                    isUploading={uploading}
                    onFileDelete={handleDeleteFile}
                  />
                  {idx !== files.length - 1 ? <Divider /> : null}
                </React.Fragment>
              ))}
            </Stack>
          </CardContent>
          <Divider />
        </React.Fragment>
      )}
      {!!files.length
        ? (
          <Stack justifyContent="center">
            <Button disabled={uploading} onClick={handleUploadOriginal}>
              Загрузить
            </Button>
          </Stack>
        )
        : (
          <DropzoneDocuments
            {...{ getInputProps, getRootProps, isDragActive }}
          />
        )
      }
    </Card>
  );
}