import { gql } from '@apollo/client/core';

export const GET_SOURCE_DOCUMENT_TYPES = gql`
  query GetSourceDocumentTypes($filter: SourceDocumentTypeFilter) {
    sourceDocumentTypes(filter: $filter) {
      id
      name
    }
  }
`;
