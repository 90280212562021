import { gql } from '@apollo/client';

export const GET_STORAGE_ACCEPTANCES = gql`
  query StorageAcceptances($page: Int, $limit: Int, $sort: Sort, $filter: ShopStorageAcceptanceFilter!) {
    storageAcceptances(page: $page, limit: $limit, sort: $sort, filter: $filter) {
      tabs {
        count
        status
      }
      storageAcceptances{
        collection {
          id
          borrowerEntity {
            id
            shortLegalName
          }
          dispute {
            id
            number
          }
          seizureAct {
            id
            date
            collector {
              displayName
            }
            number
            dispayDocumentScanUrl
          }
          acceptedAt
          acceptedBy {
            displayName
          }
          shippedEquipmentsCount
          application {
            id
          }
          supplies {
            shippedEquipments {
              id
              quantity
              shopGoodsCount
            }
          }
          status
        }
        metadata {
          currentPage
          limitValue
          totalCount
          totalPages
        }
      }
    }
  }
`;