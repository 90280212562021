import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useLazyQuery, useQuery} from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import { sortBy, isEqual} from 'lodash';
import { FormattedMessage } from 'react-intl';

import AutocompleteEntities from 'components/AutocompleteEntities';
import { DatePickerBox } from 'components/DatePickerBox';
import { formatDate } from 'utils/dateTimeFormatters';

import { FilterModalBox } from './helpers';
import { GET_FUNDING_SOURCES } from "./query/fundingSources";
import { GET_FUNDING_TRANCHES } from "./query/fundingSourcesTranches";
import FormattedCurrency from "../../../components/FormattedCurrency";
import { DealsFilterContext } from '../DealsFilterContext';

DealsFilterModal.propTypes = {
  onClose: PropTypes.func,
};

export default function DealsFilterModal({ onClose }) {
  const {
    fundingSources,
    lineFundingSource,
    setLineFundingSource,
    setTranches,
    state,
    tranches,
    updateState,
  } = useContext(DealsFilterContext);

  const [persistState, setPersistState] = useState(state);
  const [hasDateError, setHasDateError] = useState(false);

  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const [statusList, setStatusList] = useState(state.accountStates || []);

  const { data: dateFundingSources, loading: loadingFundingSources } = useQuery(GET_FUNDING_SOURCES);

  const [collectionLine, setCollectionLine] = useState([]);

  useEffect(() => {
    if (lineFundingSource.length > 0 && state.applicationFundingSourceId) {
      setCollectionLine(lineFundingSource);

      fetchTranches({
        variables: {
          fundingSourceId: state.applicationFundingSourceId,
        }
      });
    } else {
      if (dateFundingSources?.fundingSources && dateFundingSources?.fundingSources.length > 0) {
        setCollectionLine(dateFundingSources.fundingSources);
        setLineFundingSource(dateFundingSources.fundingSources);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFundingSources]);

  const [ fetchTranches, { data: dateTranches, loading: loadingTranches }] = useLazyQuery(GET_FUNDING_TRANCHES);

  const [collectionTranches, setCollectionTranches] = useState([]);

  const updateFilterState = useCallback((fieldName, value) => {
    setPersistState((prev) => {
      const data = { ...prev, [fieldName]: value };
      if (fieldName === 'accountStates' && !value?.length) {
        delete data.accountStates;
      }
      if (fieldName === 'date' && value) {
        if (!value?.from === null) {
          delete data.date.from;
        }

        if (!value?.to === null) {
          delete data.date.to;
        }

        if (!value?.from && !value?.to) {
          delete data.date;
        }
      }
      return data;
    });
  }, []);

  const applyFilter = useCallback(() => {
    updateState(persistState);
    handleClose();
  }, [persistState, updateState, handleClose]);

  const resetFilter = useCallback(() => {
    setPersistState({});
    setStatusList([]);
  }, []);

  const handleChangeDate = useCallback((fieldName) => (value) => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      dateValue = new Date(value);
      dateValue.setHours(0, 0, 0, 0);
      dateValue = dateValue.toISOString();
    }

    updateFilterState('date', {
      ...persistState?.date,
      [fieldName]: dateValue,
    });
  }, [persistState, updateFilterState]);

  const handleSelect = useCallback(({ target: { value } }) => {
    updateFilterState('fundingSourceId', value);
  }, [updateFilterState]);

  const handleChangeWithLine = useCallback(({ target: { value } }) => {
    updateFilterState('applicationFundingSourceId', value);
    updateFilterState('trancheId', undefined);

    fetchTranches({
      variables: {
        fundingSourceId: value,
      }
    });

  }, [updateFilterState, fetchTranches]);

  useEffect(() => {
    if (tranches.length > 0 && state.trancheId && !collectionTranches.length) {
      return setCollectionTranches(tranches);
    } else {
      if (dateTranches?.fundingSource?.tranches) {
        setCollectionTranches(dateTranches?.fundingSource?.tranches);
        setTranches(dateTranches?.fundingSource?.tranches);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTranches])

  const handleChangeWithTranche = useCallback(({ target: { value } }) => {
    updateFilterState('trancheId', value);
  }, [updateFilterState]);

  const handleMultiSelect = useCallback(({ target: { value } }) => {
    const list = typeof value === 'string' ? value.split(',') : value;
    setStatusList(list);
    updateFilterState('accountStates', list);
  }, [updateFilterState]);

  const [ ignoreCollectionLine ] = useState(["arenza", "аренза"])

  return (
    <Dialog
      fullWidth
      open={open}
      scroll="body"
      sx={{ '& .MuiDialog-paper': { maxWidth: 480 } }}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">Фильтр сделок</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FilterModalBox title="Срок действия договора">
          <DatePickerBox
            maxDate={persistState?.date?.to}
            maxDateMessage={
              persistState?.date?.to
                ? `Не может быть более ${formatDate(persistState.date.to)}`
                : undefined
            }
            onChange={handleChangeDate('from')}
            onError={setHasDateError}
            placeholder="с"
            value={persistState?.date?.from || null}
          />&nbsp;—&nbsp;
          <DatePickerBox
            minDate={persistState?.date?.from}
            minDateMessage={
              persistState?.date?.from
                ? `Не может быть ранее ${formatDate(persistState.date.from)}`
                : undefined
            }
            onChange={handleChangeDate('to')}
            onError={setHasDateError}
            placeholder="по"
            value={persistState?.date?.to || null}
          />
        </FilterModalBox>

        <FilterModalBox title="Компания-заёмщик">
          <AutocompleteEntities
            onChange={(value) => updateFilterState('borrower', value)}
            value={persistState.borrower || {}}
          />
        </FilterModalBox>

        <FilterModalBox title="Просрочка от (дней)">
          <TextField
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              min: 0,
              pattern: '[0-9]*',
              type: 'number',
            }}
            onChange={({ target: { value } }) => {
              updateFilterState('overdueMoreThanDays', Number(value));
            }}
            value={persistState.overdueMoreThanDays || 0}
          />
        </FilterModalBox>

        <FilterModalBox title="Статус счета">
          <Select
            displayEmpty
            multiple
            onChange={handleMultiSelect}
            renderValue={
              (selected) => !!selected.length
                ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value, idx) => (
                      <Chip
                        key={idx}
                        label={
                          <FormattedMessage id={`deals.status.${value}`} />
                        }
                      />
                    ))}
                  </Box>
                )
                : (
                  <span style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Выбрать</span>
                )
            }
            sx={{ width: '100%' }}
            value={statusList}
          >
            <MenuItem disabled value="">Выбрать</MenuItem>
            <MenuItem value="ACCOUNT_PENDING">Не активирован</MenuItem>
            <MenuItem value="ACCOUNT_ACTIVE">Активирован</MenuItem>
            <MenuItem value="ACCOUNT_CLOSED">Закрыт</MenuItem>
          </Select>
        </FilterModalBox>

        <FilterModalBox title="Источник финансирования">
          <Select
            disabled={!fundingSources.length}
            displayEmpty
            onChange={handleSelect}
            sx={{ width: '100%' }}
            value={persistState?.fundingSourceId || ''}
          >
            <MenuItem disabled value="">Выбрать</MenuItem>
            {fundingSources.map((fundingSource, idx) => (
              <MenuItem key={fundingSource?.id || idx} value={fundingSource?.id}>
                {fundingSource?.source}
              </MenuItem>
            ))}
          </Select>
        </FilterModalBox>

        <FilterModalBox title="Залог">
          <Select
            disabled={loadingFundingSources}
            displayEmpty
            fullWidth
            onChange={handleChangeWithLine}
            value={persistState?.applicationFundingSourceId || ''}
          >
            <MenuItem disabled value="">Выбрать Линию</MenuItem>

            {/* eslint-disable-next-line array-callback-return */}
            {collectionLine.map(line => {
              if (!ignoreCollectionLine.includes(line.source.toLowerCase())) {
                return (
                  <MenuItem key={line?.id} value={line?.id}>
                    {line?.source}
                  </MenuItem>
                )
              }})}
          </Select>
        </FilterModalBox>

        <FilterModalBox>
          <Select
            disabled={!persistState?.applicationFundingSourceId || loadingTranches}
            displayEmpty
            fullWidth
            onChange={handleChangeWithTranche}
            value={persistState?.trancheId || ''}
          >
            <MenuItem disabled value="">Выбрать Транш</MenuItem>

            {sortBy(collectionTranches, ['number']).map(tranche => (
              <MenuItem key={tranche?.id} value={tranche?.id}>
                Транш {tranche?.number} ( <FormattedCurrency style="decimal" value={tranche?.amount}/> руб.)
              </MenuItem>
            ))}
          </Select>
        </FilterModalBox>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!Object.keys(persistState).length}
          onClick={resetFilter}
        >
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state) || hasDateError}
          onClick={applyFilter}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
}