import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useRef, useState} from 'react';

import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import DoDisturbAltOutlinedIcon from '@material-ui/icons/DoDisturbAltOutlined';
import FileDownloadOutlinedIcon from '@material-ui/icons/FileDownloadOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SendIcon from '@material-ui/icons/Send';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import ConfirmDialog from 'components/ConfirmDialog';

CellActions.propTypes = {
  onGenerateXML: PropTypes.func,
  onMessageReject: PropTypes.func,
  onMessageSent: PropTypes.func,
  onShowDetails: PropTypes.func,
  row: PropTypes.object,
};
export default function CellActions({
  onGenerateXML,
  onMessageReject,
  onMessageSent,
  onShowDetails,
  row,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  const [confirmDialogShown, setConfirmDialogShown] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [onConfirmCallback, setConfirmCallback] = useState(undefined);
  const handleCloseConfirmationDialog = useCallback(() => {
    setConfirmDialogShown(false);
  }, []);
  const numberRFM = useRef("");
  const errorNumberRFM = useRef(false);

  const onChangeInput = (event) => {
    let value = event.target.value.trim();

    if (value.length === 0) {
      errorNumberRFM.current = true;
    } else {
      errorNumberRFM.current = false;
      numberRFM.current = value;
    }
  };

  const handleShowDetails = useCallback(() => {
    onShowDetails({
      ...(row?.data || {}),
      id: row.id,
      state: row.state,
    });
  }, [onShowDetails, row]);

  const handleGenerateXML = useCallback(() => {
    onGenerateXML(row.id);
  }, [onGenerateXML, row.id]);

  const handleMessageSent = useCallback(() => {
    onMessageSent(row.id, row.externalId);
    handleCloseConfirmationDialog();
  }, [onMessageSent, handleCloseConfirmationDialog, row.id, row.externalId]);

  const messageNumberRFM = useCallback((error = false) => {
    return (
      <>
        Для изменения статуса сообщения необходимо в таблице сообщений добавить поле &quot;Номер сообщения в РФМ&quot;

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 34,
            backgroundColor: 'white',
            border: error ? '1px solid #e70b39' : '1px solid #E0E0E0',
            borderRadius: '4px',
            marginTop: '25px',
            '& .MuiInputBase-root': {
              paddingLeft: '6px !important',
              paddingRight: '6px !important',
            }
          }}
        >
          <TextField
            fullWidth
            onChange={onChangeInput}
            placeholder={"Введите номер сообщения в РФМ"}
            size="small"
            sx={{
              '& .MuiInputBase-root': { px: 3 },
              '& input': { fontSize: 14, height: 'calc(100% + 2px)', padding: '0px', },
              '& fieldset': { border: 'none' },
            }}
            type={"text"}
          />
        </Box>
      </>
    )
  }, [])

  const handleAddNumberRFM = useCallback(() => {
    if (numberRFM.current.length === 0) {
      handleCloseConfirmationDialog();
      errorNumberRFM.current = true;

      setConfirmMessage(messageNumberRFM(true));
      setConfirmDialogShown(true);
    }

    if (errorNumberRFM.current === true) return;

    onMessageSent(row.id, numberRFM.current);
    handleCloseConfirmationDialog();
  }, [handleCloseConfirmationDialog, messageNumberRFM, onMessageSent, row.id])

  const handleConfirmMessageSent = useCallback(() => {
    handleMenuClose();

    if (!row.externalId) {
      setConfirmMessage(messageNumberRFM(false));
      setConfirmDialogShown(true);
      setConfirmCallback(() => handleAddNumberRFM);
    } else {
      setConfirmMessage(`Перевести сообщение #${row.id} в статус "Отправлено"?`);
      setConfirmDialogShown(true);
      setConfirmCallback(() => handleMessageSent);
    }
  }, [handleMenuClose, handleMessageSent, messageNumberRFM, handleAddNumberRFM, row.id, row.externalId]);

  const handleMessageReject = useCallback(() => {
    onMessageReject(row.id);
    handleCloseConfirmationDialog();
  }, [onMessageReject, handleCloseConfirmationDialog, row.id]);

  const handleConfirmMessageReject = useCallback(() => {
    handleMenuClose();
    setConfirmMessage(`Перевести сообщение #${row.id} в статус "Отклонено"?`);
    setConfirmDialogShown(true);
    setConfirmCallback(() => handleMessageReject);
  }, [handleMenuClose, handleMessageReject, row.id]);

  const actionsMenu = useMemo(() => ([
    {
      action: handleGenerateXML,
      disabled: false,
      icon: FileDownloadOutlinedIcon,
      title: 'Скачать XML',
    },
    {
      action: handleConfirmMessageSent,
      disabled: row?.state !== 'PENDING',
      icon: SendIcon,
      title: 'Отправить сообщение',
    },
    {
      action: handleConfirmMessageReject,
      disabled: row?.state !== 'PENDING',
      icon: DoDisturbAltOutlinedIcon,
      title: 'Отклонить сообщение',
    },
  ]), [
    handleGenerateXML,
    handleConfirmMessageSent,
    handleConfirmMessageReject,
    row,
  ]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
      <Button
        disableElevation
        onClick={handleShowDetails}
        size="small"
        startIcon={<InfoOutlinedIcon fontSize="small" />}
        sx={{ textTransform: 'unset' }}
        variant="outlined"
      >
        Детали сообщения
      </Button>

      <IconButton onClick={handleMenuOpen} sx={{ p: '6px' }}>
        <SettingsOutlinedIcon sx={{ fontSize: '20px !important' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleMenuClose}
        open={open}
        sx={{ '& li': { px: '12px' } }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {actionsMenu.map(({ action, disabled, icon, title }, idx) => (
          <MenuItem dense disabled={disabled} key={idx} onClick={action}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <Box component={icon} fontSize="inherit" />
            </ListItemIcon>
            {title}
          </MenuItem>
        ))}
      </Menu>

      {confirmDialogShown && (
        <ConfirmDialog
          message={confirmMessage}
          onCancel={handleCloseConfirmationDialog}
          onConfirm={onConfirmCallback}
        />
      )}
    </Box>
  );
}