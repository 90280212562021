import { gql } from '@apollo/client/core';

export const GET_SOURCE_DOCUMENTS = gql`
  query GetSourceDocuments($limit: Int, $page: Int, $filter: SourceDocumentFilter) {
    sourceDocuments(page: $page, limit: $limit, filter: $filter) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        author {
          firstName
          lastName
        }
        document {
          id
          category {
            name
          }
          target {
            ... on LoanSupply {
              supplyingOrder {
                id
                number
                application {
                  id
                  borrowerEntity {
                    id
                    legalNameWithShortEntityType
                  }
                }
                supplier {
                  id
                  legalNameWithShortEntityType
                }
              }
            }
            ... on Dispute {
              id
              number
              judgementAmount
              application {
                id
                borrowerEntity {
                  id
                  legalNameWithShortEntityType
                }
              }
            }
            ... on ApplicationSupplyingOrder {
              id
              number
              application {
                id
                borrowerEntity {
                  id
                  legalNameWithShortEntityType
                }
              }
              supplier {
                id
                legalNameWithShortEntityType
              }
            }
          }
        }
        documentFilename
        documentNumber
        documentUrl
        isOriginalReceived
        status
        createdAt
        amount
        recognizingProcess {
          ocrData {
            documentNumber
            date
            buyerName
            seller {
              id
              legalNameWithShortEntityType
            }
            sellerName
            orderItems {
              index
              name
              units
              quantity
              price
              total
              vatRate
            }
            totalVat
            totalAmount
          }
          longTimeRecognizing
          status
        }
        type {
          assignable
          category
          name
          id
          recognizable
        }
      }
    }
  }
`;
