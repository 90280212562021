import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { Timeline } from '@material-ui/lab';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import _get from 'lodash.get';

import { SimpleMenu } from 'components/SimpleMenu';
import {
  addNumbersForMonthlyPayments,
} from 'utils/addNumbersForMonthlyPayments';

import ActivateScheduleMenuItem from './ActivateScheduleMenuItem';
import DeleteScheduleMenuItem from './DeleteScheduleMenuItem';
import PaymentPlanHeader from './PaymentPlanHeader';
import PaymentPlanSelector from './PaymentPlanSelector';
import ScheduleTally from './ScheduleTally';
import TimelineItemContainer from './TimelineItemContainer';
import { GET_SCHEDULE } from '../graphql/queries/schedule';

Schedule.propTypes = {
  comparedScheduleId: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default function Schedule({ comparedScheduleId, id }) {
  const [getSchedule, { data }] = useLazyQuery(GET_SCHEDULE, {
    variables: { id },
    fetchPolicy: 'cache-only',
  });

  const [getComparedSchedule, comparedSchedule] = useLazyQuery(GET_SCHEDULE, {
    variables: { id: comparedScheduleId },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    getSchedule();
  }, [id, getSchedule]);

  useEffect(() => {
    if (comparedScheduleId) {
      getComparedSchedule();
    }
  }, [comparedScheduleId, getComparedSchedule]);

  const schedule = _get(data, 'schedule');
  const scheduleState = _get(data, 'schedule.state');
  const items = addNumbersForMonthlyPayments(_get(data, 'schedule.items', []));
  const isDraftSchedule = scheduleState === 'draft';

  const timelineItems = useMemo(
    () =>
      items.map((item, index) => ({
        paymentData: item,
        meta: {
          isLast: items.length - 1 === index,
          isEditable: isDraftSchedule && !item.paid,
        },
      })),
    [items, isDraftSchedule],
  );

  if (!schedule) {
    return null;
  }

  return (
    <Box>
      <PaymentPlanHeader
        actions={
          <>
            <IconButton disabled edge="end">
              <PrintIcon />
            </IconButton>
            {isDraftSchedule && (
              <SimpleMenu
                menuItems={[
                  <ActivateScheduleMenuItem key={0} scheduleId={id} />,
                  <DeleteScheduleMenuItem key={1} scheduleId={id} />,
                ]}
              />
            )}
          </>
        }
        content={
          <ScheduleTally
            comparedItems={_get(comparedSchedule, 'data.schedule.items', [])}
            items={items}
          />
        }
        title={<PaymentPlanSelector activeSchedule={schedule} />}
      />
      {timelineItems.length ? (
        <Timeline>
          <AnimateSharedLayout>
            <AnimatePresence>
              {timelineItems.map(({ meta, paymentData }, index) => {
                return (
                  <TimelineItemContainer
                    key={paymentData.key}
                    meta={meta}
                    paymentData={paymentData}
                    scheduleId={id}
                  />
                );
              })}
            </AnimatePresence>
          </AnimateSharedLayout>
        </Timeline>
      ) : (
        'Платежей нет'
      )}
    </Box>
  );
}
