import { gql } from '@apollo/client/core';

export const GET_SUPPLYING_ORDER = gql`
  query GetSupplyingOrder($orderId: ID!) {
    supplyingOrder(supplyingOrderId: $orderId) {
      id
      application {
        id
        currentSubstatuses {
          id
        }
        status
        supplyingEntityContracts {
          id
          amount
          date
          displayName
          supplies {
            id
            amount
            date
            onecExportRequest {
              status
            }
            otherDocuments {
              id
              assetOriginalFilename
              assetUrl
              isOriginalReceived
              receivedAt
              number
              receivedBy {
                firstName
                lastName
              }
              recognizingProcesses {
                id
                createdAt
                status
                updatedAt
              }
            }
            primaryDocuments {
              id
              assetOriginalFilename
              assetUrl
              isOriginalReceived
              receivedAt
              number
              receivedBy {
                firstName
                lastName
              }
              recognizingProcesses {
                id
                createdAt
                status
                updatedAt
              }
            }
          }
        }
      }
      supplies {
        id
        amount
        date
        onecExportRequest {
          status
        }
        otherDocuments {
          id
          assetOriginalFilename
          assetUrl
          isOriginalReceived
          receivedAt
          receivedBy {
            firstName
            lastName
          }
          recognizingProcesses {
            createdAt
            status
            updatedAt
          }
        }
        primaryDocuments {
          id
          assetOriginalFilename
          assetUrl
          isOriginalReceived
          number
          receivedAt
          receivedBy {
            firstName
            lastName
          }
          recognizingProcesses {
            createdAt
            status
            updatedAt
          }
        }
      }
    }
  }
`;
