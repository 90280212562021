import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ReplayIcon from '@material-ui/icons/Replay';

import Tooltip from 'components/Tooltip';
import {
  GET_BANK_STATEMENT_COMMENTS,
} from 'modules/bankStatements/graphql/queries/bankStatementComments';

import BodyResolution from './BodyResolution';
import DialogResolutionHistory from './DialogResolutionHistory';
import { ResolutionFormContext } from './FixedResolution';
import FormCreateResolution from './FormCreateResolution';
import LayoutResolution from './LayoutResolution';

SectionResolution.propTypes = {
  bankStatementId: PropTypes.string,
  disabledEdit: PropTypes.bool,
};

export default function SectionResolution({ bankStatementId, disabledEdit }) {
  const { data, error, loading } = useQuery(GET_BANK_STATEMENT_COMMENTS, {
    variables: { bankStatementId },
  });

  const [formOn, setFormOn] = useState(false);
  const { formState } = useContext(ResolutionFormContext);

  function handleEditClick() {
    setFormOn(!formOn);
  }

  if (error) {
    return error.message;
  }

  if (loading) {
    return 'Загрузка...';
  }

  const emptyResolution = !data.bankStatement.comments.length;
  const lastComment = data.bankStatement.comments.slice(-1)[0];

  if (formState.locked) {
    return null;
  }

  return (
    <LayoutResolution
      actions={
        <>
          <Tooltip
            title={formOn ? 'Отменить изменения' : 'Изменить резолюцию'}
          >
            <IconButton disabled={disabledEdit} onClick={handleEditClick}>
              {formOn ? <ReplayIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
          {data.bankStatement.comments.length > 1 ? (
            <DialogResolutionHistory comments={data.bankStatement.comments} />
          ) : null}
        </>
      }
    >
      <Box>
        {formOn ? (
          <FormCreateResolution
            bankStatementId={bankStatementId}
            defaultValues={{ text: formState.text || lastComment?.text }}
            onSuccess={() => setFormOn(false)}
          />
        ) : emptyResolution ? (
          <Box alignItems="baseline" display="flex">
            <Button disabled={disabledEdit} onClick={handleEditClick} variant="outlined">
              Добавить
            </Button>
          </Box>
        ) : (
          <BodyResolution {...lastComment} />
        )}
      </Box>
    </LayoutResolution>
  );
}