import { gql } from '@apollo/client/core';

import { GOODS_FRAGMENT, INVOICE_FRAGMENT } from "../fragments";

export const SHOP_ORDERS_CHANGE_STATUS = gql`
  ${INVOICE_FRAGMENT}
  ${GOODS_FRAGMENT}
  mutation ShopOrdersChangeStatus (
    $orderId: ID!
    $transition: ShopOrderTransition!
  ) {
    shopOrdersChangeStatus (
      orderId: $orderId
      transition: $transition
    ) {
      order {
        status
        invoices {
          ...InvoiceFragment
        }
        availableGoods {
          ...GoodsFragment
          
          storageAcceptance {
            displayDisputeUrl
            displayDisputeNumber
          }
        }
      }
    }
  }
`;