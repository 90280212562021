import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {Box, Menu, MenuItem, TextField, Typography} from "@material-ui/core";
import {
  experimentalStyled as styled,
} from '@material-ui/core/styles';
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import { times } from 'lodash';

dayjs.extend(duration)
dayjs.extend(minMax)

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    maxWidth: 320,
    maxHeight: 300,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      width: '100px',
      float: 'left',
      justifyContent: 'center',

      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {

      },
    },
  },
}));

export const CustomTimePickerBox = ({ onChange, value }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputTime, setInputTime] = useState(value || null);

  useEffect(() => {
    setInputTime(value);
  }, [value])

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (dayjs(inputTime).isValid() === false) {
      setInputTime(dayjs().hour(9).minute(0).second(0))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (time) => {
    if (time && dayjs(time).isValid()) {
      if (onChange && typeof onChange === 'function') {
        onChange(time)
      }

      setInputTime(time)
    }
    setAnchorEl(null);
  };

  const renderButtonTime = () => {
    if (open === false) {
      return false
    }

    let startTime = dayjs().hour(8).minute(45).second(0);
    let addTime = dayjs.duration(15, 'minutes');

    return times(41, () => {
      startTime = startTime.add(addTime)

      return (
        <MenuItem key={startTime.format('HH:mm')} onClick={handleClose.bind(this, startTime)}>
          {startTime.format('HH:mm')}
        </MenuItem>
      )
    })
  }

  return (
    <Box sx={{ width: '100%', '& > div': { width: '100%' } }}>
      <TextField
        disabled={true}
        onClick={handleClick}
        sx={{
          '& input': {
            padding: '0 14px',
            height: '40px',
            WebkitTextFillColor: '#000000 !important',
            cursor: 'pointer',
            width: '100%',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.87) !important'
          }
        }}
        value={dayjs(inputTime).format('HH:mm')}
      />
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        id="demo-customized-menu"
        onClose={handleClose}
        open={open}
      >
        <Typography sx={{ textAlign: 'center', padding: '15px 0', borderBottom: '1px solid whitesmoke', marginBottom: '12px' }}>{dayjs(inputTime).format('HH:mm')}</Typography>
        {renderButtonTime()}
      </StyledMenu>
    </Box>
  );
};

CustomTimePickerBox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
