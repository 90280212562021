import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, LinearProgress, Alert, AlertTitle } from '@material-ui/core';
import _get from 'lodash.get';

import { FsspCheckProcessList } from './FsspCheckProcessList';
import { FsspHeader } from './FsspHeader';
import FsspTable from './FsspTable';
import {
  GET_FSSP_CHECK_PROCESSES,
} from './graphql/queries/fsspCheckProcesses';

export default function FsspContainer({ contactId }) {
  const [selectedItemId, setSelectedItemId] = useState('');

  function onCompleted(data) {
    const id = _get(data, 'fsspCheckProcesses[0].id', '');

    setSelectedItemId(id);
  }

  const { data, error, loading, refetch } = useQuery(GET_FSSP_CHECK_PROCESSES, {
    variables: { contactId },
    onCompleted,
  });

  const fsspCheckProcesses = _get(data, 'fsspCheckProcesses', []);
  const lastFinishedCheck = fsspCheckProcesses.find(({ state }) => state === 'finished');

  return (
    <Box maxWidth={1256} px={4} py={3}>
      <FsspHeader lastFinishedCheck={lastFinishedCheck} />
      {error ? (
        <Alert
          action={
            <Button color="inherit" disabled={loading} onClick={() => refetch()}>
              {/* FIXME: to locales */}
              Повторить запрос
            </Button>
          }
          severity="error"
        >
          {/* FIXME: to locales */}
          <AlertTitle>Ошибка при получении данных</AlertTitle>
          {error.message}
        </Alert>
      ) : loading ? (
        <LinearProgress />
      ) : (
        <Box alignItems="flex-start" display="flex">
          <FsspCheckProcessList
            contactId={contactId}
            items={fsspCheckProcesses}
            onSelect={setSelectedItemId}
            selectedItemId={selectedItemId}
          />
          {selectedItemId && <FsspTable id={selectedItemId} />}
        </Box>
      )}
    </Box>
  );
}

FsspContainer.propTypes = {
  contactId: PropTypes.string,
};
