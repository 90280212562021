import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Dialog, DialogContent } from '@material-ui/core';

import { DocumentStatusMap } from './constants';
import { ViewDocument } from './helpers';
import UploadOtherDocuments from './UploadOtherDocuments';

const styles = {
  modalContainer: {
    '& .MuiDialog-scrollBody': {
      scrollbarColor: 'rgba(255, 102, 0, 0.7) transparent',
      '&::-webkit-scrollbar': { width: 6, height: 6 },
      '&::-webkit-scrollbar-track': { background: 'none' },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 102, 0, 0.7)',
        borderRadius: 6,
        '&:hover': { background: '#000' },
      },
    },

    '& .MuiDialog-paper': {
      background: 'none',
      boxShadow: 'none',
    },
  },
  modalBox: {
    borderRadius: '1rem',
    width: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalLeft: {
    borderRadius: '1rem',
    width: '58%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
  },
  modalLeftInner: {
    height: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  modalRight: {
    height: 1,
    borderRadius: '1rem',
    width: '38%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'hidden',
  },
  modalRightInner: {
    height: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    p: 4,
    gap: '1.5rem',
  },
};

PreviewModal.propTypes = {
  handlePreviewModalClose: PropTypes.func,
  previewObject: PropTypes.object,
}

export default function PreviewModal({
  handlePreviewModalClose,
  previewObject,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    handlePreviewModalClose(null);
    setOpen(false);
  }, [handlePreviewModalClose]);

  useEffect(() => {
    if (previewObject) {
      handleOpen();
    }
  }, [previewObject, handleOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth={
        previewObject.status === DocumentStatusMap.assigned ? 'xl' : 'md'
      }
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={styles.modalContainer}
    >
      <DialogContent>
        {previewObject.status === DocumentStatusMap.assigned ? (
          <Box sx={styles.modalBox}>
            <Box sx={styles.modalLeft}>
              <Box sx={styles.modalLeftInner}>
                <ViewDocument uri={previewObject.documentUrl} />
              </Box>
            </Box>

            {previewObject?.type?.recognizable && (
              <Box sx={styles.modalRight}>
                <Box sx={styles.modalRightInner}>
                  <UploadOtherDocuments
                    {...{ previewObject, onClose: handleClose }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: '1rem',
              bgcolor: 'background.paper',
              p: 4,
            }}
          >
            <ViewDocument uri={previewObject.documentUrl} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}