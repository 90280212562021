import PropTypes from "prop-types";
import React, {useContext, useState} from 'react';

import {Box, Menu, MenuItem, Typography} from '@material-ui/core';
import {cloneDeep} from "lodash";

import {OrdersContext} from "./OrdersContext";
import FormattedCurrency from "../../components/FormattedCurrency";

OrdersSort.propTypes = {
  infTabs: PropTypes.object,
};

export default function OrdersSort({ infTabs }) {
  const { state, updateFilter } = useContext(OrdersContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (value) => {
    const newState = cloneDeep(state);
    newState.filter.sort.order = value;
    newState.filter.page = 1;
    updateFilter(newState);

    handleClose();
  }

  const renderFilterCount = () => {
    if(Array.isArray(state.filter.tab)) {
      let summ = 0;

      state.filter.tab.forEach((tab) => {
        if(infTabs[tab]?.count) {
          summ += infTabs[tab].count;
        }
      });

      return summ;
    }

    if(infTabs[state.filter.tab]?.count) {
      return infTabs[state.filter.tab].count;
    }

    return 0;
  };

  const renderSummCount = () => {
    if(Array.isArray(state.filter.tab)) {
      let summ = 0;

      state.filter.tab.forEach((tab) => {
        if(infTabs[tab]?.sum) {
          summ += infTabs[tab].sum;
        }
      });

      return summ;
    }

    if(infTabs[state.filter.tab]?.sum) {
      return infTabs[state.filter.tab].sum;
    }

    return 0;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: "0 24px 0", padding: '23px 0', }}>
      <Box sx={{ fontSize: '16px', fontWeight: '500', display: 'flex' }}>
        {renderFilterCount()}
        <Typography
          sx={{
            color: '#6E7884',
            fontWeight: '500',
            padding: '0 5px'
          }}
        >
          {" "}заказов на сумму
        </Typography>
        {renderSummCount() ? <FormattedCurrency style="decimal" value={renderSummCount()} /> : 0} руб.
      </Box>

      <Box sx={{ display: 'flex', fontSize: '16px', fontWeight: '500', }}>
        <Typography
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          id="basic-button"
          onClick={handleClick}
          sx={{ color: '#6E7884', fontWeight: '500', padding: '0 5px 0 0', cursor: 'pointer' }}
        >
          Сортировать:

          <span style={{ color: '#000000', paddingLeft: '10px', }}>
            {state.filter.sort.order === 'DESC' ? 'По дате создания (Новые - старые)' : 'По дате создания (Cтарые - новые)'}
          </span>

        </Typography>


        <Menu
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorEl={anchorEl}
          id="basic-menu"
          onClose={handleClose}
          open={open}
        >
          <MenuItem onClick={onSelect.bind(this, "DESC")}>По дате создания (Новые - старые)</MenuItem>
          <MenuItem onClick={onSelect.bind(this, "ASC")}>По дате создания (Cтарые - новые)</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
