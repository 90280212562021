/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { Box } from '@material-ui/core';
import { Document } from 'react-pdf';

import { PDFRendererAllPages } from './PDFRendererAllPages';
import { PDFRendererSinglePage } from './PDFRendererSinglePage';
import { PDFRendererContext } from '../state';
import { setNumPages } from '../state/actions';
import { initialPDFRendererState } from '../state/reducer';

export const PDFRendererPages = () => {
  const {
    dispatch,
    state: { mainState, paginated },
  } = useContext(PDFRendererContext);

  const currentDocument = useMemo(
    () => mainState?.currentDocument || null,
    [mainState],
  );

  const handleLoadSuccess = useCallback(({ numPages }) => {
    dispatch(setNumPages(numPages));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setNumPages(initialPDFRendererState.numPages));
  }, [dispatch, currentDocument]);

  if (!currentDocument || currentDocument?.fileData === undefined) {
    return null;
  }

  return (
    <DocumentPDF
      file={currentDocument.fileData}
      onLoadSuccess={handleLoadSuccess}
    >
      {paginated ? <PDFRendererSinglePage /> : <PDFRendererAllPages />}
    </DocumentPDF>
  );
};

const DocumentPDF = ({ children, ...rest }) => (
  <Box
    {...rest}
    component={Document}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
    }}
  >
    {children}
  </Box>
);