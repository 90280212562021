import { gql } from '@apollo/client';

export const GET_ENTITY_DATA = gql`
  query GetEntityData($entityId: ID!) {
    entity(entityId: $entityId) {
      id
      psrn
      shortLegalName
      tin
      trrc
    }
  }
`;