import { gql } from '@apollo/client/core';

export const TASKS_UPDATE = gql`
  mutation TasksUpdate($taskId: ID!, $taskParams: TaskUpdateParams!) {
    tasksUpdate(taskId: $taskId, taskParams: $taskParams) {
      task {
        id
        assignee {
          id
          openedTasksCounter {
            past
            today
          }
        }
      }
    }
  }
`;