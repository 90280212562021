import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Box } from '@material-ui/core';

import OpenInNewButton from 'components/OpenInNewButton';

import ListBankAccounts from './ListBankAccounts';
import ListBankStatements from './ListBankStatements';

PageBankStatementsRoot.propTypes = {
  entityId: PropTypes.string,
};

export default function PageBankStatementsRoot({ entityId }) {
  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px'
        }}
      >
        <OpenInNewButton />
      </Box>
      <Box sx={{ display: 'flex', height: '100%', p: 3 }}>
        <ListBankAccounts entityId={entityId} />
        <ListBankStatements entityId={entityId} />
      </Box>
    </Fragment>
  );
}
