import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Box, ButtonBase, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { AssignProcessContext } from './AssignProcessContext';
import {
  APPLICATION_TOGGLE_SUBSTATUS,
} from '../../graphql/mutations/applicationToggleSubstatus';
import { ApplicationStatus } from '../helpers';

export default function ApplicationData() {
  const { applicationId, applicationState } = useContext(AssignProcessContext);
  const [currentSubstatuses, setCurrentSubstatuses] = useState([]);

  const [toggleSubstatus, { loading: togglingSubstatus }] = useMutation(
    APPLICATION_TOGGLE_SUBSTATUS,
    {
      onCompleted: (data) => {
        setCurrentSubstatuses(
          data?.applicationToggleSubstatus?.application?.currentSubstatuses
            ?.map((s) => s.id),
        );
      },
    },
  );

  const handleToggleSubstatus = useCallback((substatusId) => () => {
    toggleSubstatus({ variables: { substatusId, applicationId } });
  }, [applicationId, toggleSubstatus]);

  useEffect(() => {
    setCurrentSubstatuses(applicationState?.currentSubstatuses);
  }, [applicationState]);

  if (!applicationId) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Link
          href={
            `${process.env.REACT_APP_HOST}/applications/${applicationId}/deal`
          }
          sx={{ fontSize: 14 }}
          target="_blank"
        >
          Заявка №{applicationId}
        </Link>
        <ApplicationStatus
          active
          status={
            <FormattedMessage
              id={`application.substatus.${applicationState.status}`}
            />
          }
        />
      </Box>
      {!!applicationState?.substatuses?.length && (
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', my: 1 }}>
          {applicationState?.substatuses?.map((substatus) => (
            <ButtonBase
              disabled={togglingSubstatus}
              key={substatus.id}
              onClick={handleToggleSubstatus(substatus.id)}
              sx={{
                '& .MuiChip-root': {
                  cursor: togglingSubstatus ? 'not-allowed' : 'inherit',
                },
              }}
            >
              <ApplicationStatus
                active={currentSubstatuses?.includes(substatus.id)}
                status={substatus.name}
              />
            </ButtonBase>
          ))}
        </Box>
      )}
    </Box>
  );
}