import PropTypes from 'prop-types';

// AppMenu
export const SingleMenuItemProps = {
  title: PropTypes.string,
  url: PropTypes.string,
}

export const NestedMenuItemProps = PropTypes.shape({
  children: PropTypes.arrayOf(
    PropTypes.shape(SingleMenuItemProps),
  ),
  ...SingleMenuItemProps,
})

export const MenuItemsProps = PropTypes.arrayOf(NestedMenuItemProps)

// CurrentUser
export const CurrentUserProps = PropTypes.shape({
  id: PropTypes.string,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
})

// Sidebar
export const SidebarProps = {
  currentUser: CurrentUserProps,
  error: PropTypes.string,
  loading: PropTypes.bool,
  menuItems: MenuItemsProps,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
}