// SET_ZOOM_LEVEL
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL';
export const setZoomLevel = (value) => ({
  type: SET_ZOOM_LEVEL,
  value,
});

// SET_ROTATION_DEGREE
export const SET_ROTATION_DEGREE = 'SET_ROTATION_DEGREE';
export const setRotationDegree = (value) => ({
  type: SET_ROTATION_DEGREE,
  value,
});

// SET_PAGINATED
export const SET_PDF_PAGINATED = 'SET_PDF_PAGINATED';
export const setPDFPaginated = (value) => ({
  type: SET_PDF_PAGINATED,
  value,
});

// SET_NUM_PAGES
export const SET_NUM_PAGES = 'SET_NUM_PAGES';
export const setNumPages = (value) => ({
  type: SET_NUM_PAGES,
  value,
});

// SET_CURRENT_PAGE
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const setCurrentPage = (value) => ({
  type: SET_CURRENT_PAGE,
  value,
});