import { gql } from '@apollo/client/core';

export const SCHEDULE_ITEM_FRAGMENT = gql`
  fragment ScheduleItemFragment on ScheduleItem {
    key
    type
    baseAmount
    commissionAmount
    fullAmount
    vatAmount
    paid
    startAt
    forfeitAmount
    past
    unpaidAmount
  }
`;

export const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFragment on Schedule {
    id
    state
    createdAt
    stornedAt
    activatedAt
  }
`;