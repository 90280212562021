import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageGoods from "./PageGoods";
import PageParametersWarehouse from "./PageParametersWarehouse";
import PageSeizureAct from "./PageSeizureAct";

export default function ShopRoutes() {
  return (
      <Box component={Router} sx={{ height: '100%',  backgroundColor: '#f3f4f5', overflow: 'auto', }}>
        <PageGoods path="/" />
        <PageSeizureAct id={"seizure-act"} path="/seizure-act/:storageAcceptanceId" />
        <PageParametersWarehouse id={"parameters-warehouse"} path="/:storageAcceptanceId/parameters-warehouse/:shippedEquipmentId" />
      </Box>
  );
}