import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_DELETE = gql`
  mutation SourceDocumentsDelete($selection: [ID!]!) {
    sourceDocumentsDelete(sourceDocumentIds: $selection) {
      sourceDocuments {
        id
      }
    }
  }
`;