import { gql } from '@apollo/client/core';

import { CONTACT_FRAGMENT } from "../fragments";

export const GET_SHOP_ORDERS = gql`
  ${CONTACT_FRAGMENT}
  query ShopOrders (
    $page: Int
    $limit: Int
    $sort: Sort
  ) {
    shopOrders (
      page: $page
      limit: $limit
      sort: $sort
    ) {
      collection {
        id
        status
        amountWithDiscount
        contact {
          ...ContactFragment
        }
        manager {
          displayName
          contact {
            id
            url
          }
        }
        createdAt
      }
      metadata {
        totalCount
      }
    }
  }
`;