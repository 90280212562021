import PropTypes from 'prop-types';
import React from 'react';

import { InputBase, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  text: {
    cursor: 'pointer',
    padding: '0 0 1px',
    margin: '6px 4px',
    fontSize: 'inherit',
    lineHeight: 1.2,
    borderBottom: '1px dashed #1771e6',
    color: '#1771e6',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#1464cc',
    },
  },
  inputWrapper: {
    backgroundColor: '#eef2f7',
    fontSize: 'inherit',
    minWidth: 64,
    width: '100%',
  },
  input: {
    padding: '6px 4px 7px',
  },
}));

export default function NakedInput({ onBlur, onChange, onFocus, type, value, ...props }) {
  const classes = useStyles();
  const inputProps = {
    'aria-label': 'naked',
    type: type || 'number',
    className: classes.input,
  };

  return (
    <InputBase
      className={classes.inputWrapper}
      inputProps={inputProps}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      {...props}
    />
  );
}

NakedInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
