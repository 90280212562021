import PropTypes from "prop-types";
import React from 'react';

import { Box } from "@material-ui/core";
import {FormattedMessage} from "react-intl";

const statuses = {
    'IN_STOCK': {
        color: '#3D6CE7',
        backgroundColor: 'rgba(61, 108, 230, 0.12)'
    },
    'READY_FOR_PUBLISH': {
        color: '#FF780F',
        backgroundColor: 'rgba(255, 136, 25, 0.12)'
    },
    'PUBLISHED': {
        color: '#67BF3D',
        backgroundColor: 'rgba(132, 198, 26, 0.12)'
    },
    'RESERVED': {
        color: '#21A2FF',
        backgroundColor: 'rgba(33, 162, 255, 0.12)'
    },
    'SOLD': {
        color: '#7F7592',
        backgroundColor: 'rgba(33, 162, 255, 0.12)'
    }
};

const styles = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '6px 8px',
    borderRadius: '4px',
    marginLeft: '16px'
};

const GoodStatus = ({ status }) => {
  if(status && statuses[status]) {
    return (
      <Box sx={{...styles, color: statuses[status].color, backgroundColor: statuses[status].backgroundColor}}>
        <FormattedMessage id={`shop.goodStatus.${status}`} />
      </Box>
    );
  }

  return (
    <Box sx={{...styles, color: statuses['SOLD'].color, backgroundColor: statuses['SOLD'].backgroundColor}}>Некорректный статус</Box>
  );
}

GoodStatus.propTypes = {
  status: PropTypes.string
};

export default GoodStatus;