import PropTypes from 'prop-types';
import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import FormattedCurrency from 'components/FormattedCurrency';
import { formatDate } from 'utils/dateTimeFormatters';

import DocumentCard from './SupplyingOrders/DocumentCard';

Supplies.propTypes = {
  supplies: PropTypes.array,
  title: PropTypes.string,
};

Supplies.defaultProps = {
  title: "Отгрузки",
}

export default function Supplies({ supplies, title }) {
  if (!supplies) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        <TableContainer
          sx={{
            position: 'relative',
            zIndex: 1,
            overflow: 'auto',
            '&::-webkit-scrollbar': { width: 10, height: 10 },
            '&::-webkit-scrollbar-track': { background: 'none' },
            '&::-webkit-scrollbar-thumb': {
              background: '#e0e0e0',
              borderRadius: '4px',
              '&:hover': { background: '#d0d0d0' },
            },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Документы отгрузки</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Прочие документы</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!supplies.length ? (
                <React.Fragment>
                  {supplies.map((supply, idx) => (
                    <TableRow
                      key={supply.id}
                      sx={{
                        '& th, & td': { fontSize: 12 },
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {idx + 1}
                      </TableCell>
                      <TableCell padding="checkbox">{formatDate(supply.date)}</TableCell>
                      <TableCell>
                        <FormattedCurrency value={supply.amount} />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          {!supply?.primaryDocuments?.length ? (
                            '—'
                          ) : (
                            <React.Fragment>
                              {supply.primaryDocuments.map((document) => (
                                <DocumentCard
                                  key={`${supply.id}.${document.id}`}
                                  {...{ document }}
                                />
                              ))}
                            </React.Fragment>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          {!supply?.otherDocuments?.length ? (
                            '—'
                          ) : (
                            <React.Fragment>
                              {supply.otherDocuments.map((document) => (
                                <DocumentCard
                                  key={`${supply.id}.${document.id}`}
                                  {...{ document }}
                                />
                              ))}
                            </React.Fragment>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan="6" sx={{ border: 0 }}>
                    <Box sx={{ p: 4 }}>Нет данных</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          component="fieldset"
          sx={{
            position: 'absolute',
            top: -5,
            left: -2,
            right: -2,
            bottom: 0,
            px: 2,
            minWidth: 0,
            border: '1px solid rgba(0, 0, 0, 0.26)',
            borderRadius: '4px',
          }}
        >
          <Box component="legend" sx={{ fontSize: 12, p: '0 5px', color: 'rgba(0, 0, 0, 0.6)' }}>
            <span>{title}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
