import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageBankStatementItem from 'modules/bankStatements/components/PageBankStatementItem';
import PageBankStatementsRoot from 'modules/bankStatements/components/PageBankStatementsRoot';

import PageFedresurs from './PageFedresurs';
import PageLegalEntities from './PageLegalEntities';
import PageLegalEntityItem from './PageLegalEntityItem';
import PageQuestionnaires from './PageQuestionnaires';
import PageQuestionnairesItem from './PageQuestionnairesItem';

export default function LegalEntitiesRoutes() {
  return (
    <Box component={Router} primary={false} sx={{ height: '100%' }}>
      <PageLegalEntities path="/" />
      <PageLegalEntityItem path="/:entityId" />
      <PageQuestionnaires path="/:entityId/questionnaires">
        <PageQuestionnairesItem path=":questionnaireId" />
      </PageQuestionnaires>
      <PageBankStatementsRoot path="/:entityId/bank_statements" />
      <PageBankStatementItem path="/:entityId/bank_statements/:bankStatementId/*" />
      <PageFedresurs path="/:entityId/fedresurs/*" />
    </Box>
  );
}
