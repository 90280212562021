import { gql } from '@apollo/client/core';

import { GOODS_FRAGMENT } from "../fragments";

export const SHOP_ORDERS_ADD_GOOD = gql`
  ${GOODS_FRAGMENT}
  mutation ShopOrdersAddGood (
    $orderId: ID!
    $goodId: ID!
  ) {
    shopOrdersAddGood (
      orderId: $orderId
      goodId: $goodId
    ) {
      order {
        amount
        amountWithDiscount
        goodsCount
        availableGoods {
          ...GoodsFragment

          storageAcceptance {
            displayDisputeUrl
            displayDisputeNumber
          }
        }
      }
    }
  }
`;