import { gql } from '@apollo/client';

import { CREDIT_HISTORY_FETCH_PROCESS_FRAGMENT } from '../fragments';

export const ON_CREDIT_HISTORY_FETCHING_PROCESS_UPDATED = gql`
  subscription onCreditHistoryFetchingProcessUpdated($processId: ID!) {
    creditHistoryFetchingProcessUpdated(processId: $processId) {
      ...CreditHistoryFetchProcessFragment
    }
  }
  ${CREDIT_HISTORY_FETCH_PROCESS_FRAGMENT}
`;