import PropTypes from 'prop-types';
import React from 'react';

import { Box, TableCell, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';
import HelpTooltip from 'components/HelpTooltip';
import { formatDate } from 'utils/dateTimeFormatters';

import {
  FinmonitoringDocumentProps,
  FinmonitoringProblemProps,
} from './types';
import { NoDataMessage } from '../../components/helpers';

FinmonitoringDocument.propTypes = {
  document: FinmonitoringDocumentProps,
  documentProblems: PropTypes.arrayOf(FinmonitoringProblemProps),
};

const Field = ({ problem, value }) => {
  if (problem) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ '&, & .noData': { color: '#ff1744', fontWeight: 500 } }}>
          {value}
        </Box>
        <HelpTooltip
          title={(
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontSize: 14 }}>
                <FormattedMessage
                  id={`finmonitoring.problem.context.${problem.context}`}
                />
              </Box>
              <Box sx={{ fontSize: 12 }}>
                {problem.messages.join('<br />')}
              </Box>
            </Box>
          )}
        />
      </Box>
    );
  }

  return value;
};

Field.propTypes = {
  problem: FinmonitoringProblemProps,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function FinmonitoringDocument({ document, documentProblems }) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Field
          problem={
            !!documentProblems.length
              ? documentProblems.find((problem) => problem.fieldName === 'number')
              : undefined
          }
          value={document?.number || <NoDataMessage />}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <Field
          problem={
            !!documentProblems.length
              ? documentProblems.find((problem) => problem.fieldName === 'date')
              : undefined
          }
          value={document?.date ? formatDate(document.date) : <NoDataMessage />}
        />
      </TableCell>
      <TableCell>
        <Field
          problem={
            !!documentProblems.length
              ? documentProblems.find((problem) => problem.fieldName === 'amount')
              : undefined
          }
          value={
            document?.amount
              ? <FormattedCurrency value={document.amount} />
              : <NoDataMessage />
          }
        />
      </TableCell>
      <TableCell>
        <Field
          problem={
            !!documentProblems.length
              ? documentProblems.find((problem) => problem.fieldName === 'type')
              : undefined
          }
          value={
            <FormattedMessage
              id={`finmonitoring.document.type.${document?.type}`}
            />
          }
        />
      </TableCell>
    </TableRow>
  );
};