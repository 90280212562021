import { gql } from '@apollo/client/core';

export const GET_SHOP_EQUIPMENT_TYPES = gql`
  query equipmentTypes (
    $topOnly: Boolean
  ) {
    equipmentTypes (
      topOnly: $topOnly 
    ) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;