import PropTypes from 'prop-types';
import React, {useState} from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { navigate, useLocation } from '@reach/router';
import qs from 'querystringify';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  GET_BANK_STATEMENT_HISTORY,
} from '../graphql/queries/bankStatementHistory';

BlockAccountHistory.propTypes = {
  bankStatementId: PropTypes.string,
  data: PropTypes.array,
};

export default function BlockAccountHistory({ bankStatementId, data }) {
  const location = useLocation();
  const [period, setPeriod] = useState('WEEK');

  const [
    fetchBankStatementHistory,
    { data: periods },
  ] = useLazyQuery(GET_BANK_STATEMENT_HISTORY);

  function handleClick(chartData) {
    if (chartData?.activePayload) {
      const parsedQuery = qs.parse(location.search);
      const [{ payload }] = chartData.activePayload;

      // Изменения фильтрации требуют добавления фильтра в FILTERS
      // src/modules/bankStatements/components/GridOrders.jsx
      const to = qs.stringify(
        {
          ...parsedQuery,
          periodStart: payload.startDate,
          periodEnd: payload.endDate,
        },
        true,
      );
      navigate(to);
    }
  }

  function handlePeriodChange(event, period) {
    setPeriod(period);
    fetchBankStatementHistory({
      variables: {
        accountId: bankStatementId,
        period,
      },
    });
  }

  if (data.length === 0) {
    return "Нет данных для построения графика";
  }

  const recs = (periods?.bankStatementAccount.periods || data).map(
    (record) => ({
      ...record,
      debitedFunds: -record.debitedFunds,
    })
  );

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={4}
        mt={5}
      >
        <Typography variant="h3">Изменения по счету</Typography>
        <ToggleButtonGroup
          exclusive
          onChange={handlePeriodChange}
          size="small"
          value={period}
        >
          <ToggleButton value="DAY">&nbsp;&nbsp;День&nbsp;&nbsp;</ToggleButton>
          <ToggleButton value="WEEK">Неделя</ToggleButton>
          <ToggleButton value="MONTH">Месяц</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box height={280}>
        <ResponsiveContainer>
          <ComposedChart data={recs} onClick={handleClick} stackOffset="sign">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="endDate" />
            <YAxis width={100} />
            <Tooltip />
            <Legend />
            <Bar
              barSize={20}
              dataKey="debitedFunds"
              fill="rgba(224,31,25,.5)"
              name="Платежи"
              stackId="funds"
            />
            <Bar
              barSize={20}
              dataKey="receivedFunds"
              fill="rgba(44,165,58,.5)  "
              name="Поступления"
              stackId="funds"
            />
            <Line
              dataKey="endRemainder"
              name="Остатки на конец периода"
              stroke="#ff7300"
              type="monotone"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}
