import { gql } from '@apollo/client/core';

export const GET_SHOP_STORAGES = gql`
  query ShopStorages {
    shopStorages {
      collection {
        icon {
          url
        }
        id
        name
      }
    }
  }
`;