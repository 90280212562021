import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

Prompt.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string.isRequired,
  when: PropTypes.bool.isRequired,
};
export default function Prompt({ children, message, when }) {
  const [open, setOpen] = useState(when);

  const handleContinue = useCallback(() => setOpen(false), [setOpen]);

  const onBeforeUnload = useCallback((event) => {
    if (when) {
      setOpen(true);
      return (event.returnValue = message);
    }
  }, [message, when]);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return (
    <>
      {children}
      <Dialog open={open} scroll="paper">
        <DialogTitle disableTypography>
          <Typography variant="h3">Уведомление</Typography>
        </DialogTitle>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleContinue}>
            Продолжить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}