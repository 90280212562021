import { DateTime } from 'luxon';

const FORMAT_TO_HELPER = {
  short: DateTime.DATE_SHORT,
  full: DateTime.DATE_FULL,
  short_date_time: DateTime.DATETIME_SHORT,
};

export function formatDate(date, format = 'short') {
  return DateTime.fromISO(date).toLocaleString(FORMAT_TO_HELPER[format] || format);
}
