import PropTypes from 'prop-types';
import React from 'react';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import { PDFRenderer } from './PDFRenderer';

export const ViewDocument = ({ uri }) => {
  const viewConfig = {
    header: {
      disableHeader: true,
    },
  };

  const renderers = [
    ...DocViewerRenderers,
    PDFRenderer,
  ];

  return (
    <DocViewer
      config={viewConfig}
      documents={[{ uri }]}
      pluginRenderers={renderers}
      style={{ height: '100%' }}
    />
  );
};

ViewDocument.propTypes = {
  uri: PropTypes.string,
};