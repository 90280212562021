import { gql } from '@apollo/client/core';

export const CREATE_HOLIDAY = gql`
  mutation UserLeaveCreate ($leaveParams: UserLeaveCreateParams!) {
    userLeaveCreate(leaveParams: $leaveParams) {
      leave {
        id
        startDate
        endDate
        kind
        userTasksStatus
        deputy {
          id
          roles
          avatar
          displayName
          contact {
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;