import { gql } from '@apollo/client/core';

export const SUPPLYING_ORDERS_CREATE_SPECIFICATION = gql`
  mutation SupplyingOrdersCreateSpecification(
    $attributes: SupplyingOrdersSpecificationTemplateAttributes!
    $supplyingOrderId: ID!
  ) {
    supplyingOrdersCreateSpecification(
      attributes: $attributes
      supplyingOrderId: $supplyingOrderId
    ) {
      document {
        assetUrl
        id
      }
    }
  }
`;