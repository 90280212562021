import React from 'react';

import { Router } from '@reach/router';

import PaymentPlansContainer from './pages';

export default function PaymentScheduleRoutes() {
  return (
    <Router>
      <PaymentPlansContainer path=":applicationId" />
    </Router>
  );
}
