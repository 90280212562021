import PropTypes from "prop-types";
import React, {useContext, useEffect, useMemo, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import {
  CustomPaging,
  DataTypeProvider,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {Box, withStyles} from "@material-ui/core";
import {cloneDeep} from "lodash";

import ErrorAlert from "../../../components/ErrorAlert";
import { GridRoot, Loader } from "../../deals/components/helpers";
import {
  COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING,
  COLUMNS_MODEL_CREATOR_SHOP_LEASING,
  PAGING_PANEL_MESSAGES,
} from "../constants";
import ContentModalSKU from "../documentActionTypeProvider/ContentModalSKU";
import { GET_LOAN_SUPPLIES } from "../graphql/queries/getLoanSupplies";
import { ShopContext } from "../ShopContext";

export const DocumentActionTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ContentModalSKU {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

ContentLeasing.propTypes = {
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default function ContentLeasing({selection, setSelection}) {
  const styles = useMemo(() => ({
    wrapperTable: {
      margin: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '60px',

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
  }), []);

  const { state: query, updateFilter } = useContext(ShopContext);

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = withStyles({ name: "MyPager" })(
    MyPager
  );

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchMessages, { data: loans, error, loading }] = useLazyQuery(GET_LOAN_SUPPLIES);
  useEffect(() => {
    if (!loans) {
      query.sort.field = 'amount';

      fetchMessages({ variables: query});
    }

    if (loans && loans?.loanSupplies?.collection) {
      setRows(loans?.loanSupplies?.collection);
    }
    if (loans && loans?.loanSupplies) {
      setTotalCount(loans?.loanSupplies?.metadata?.totalCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loans, query]);

  useEffect(() => {
    fetchMessages({ variables: query})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const handleCurrentPageChange = page => {
    const newQuery = cloneDeep(query);
    newQuery.page = page;
    updateFilter(newQuery)
  };

  if (loading) {
    return <Loader />
  }
  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <>
      <Box sx={styles.wrapperTable}>
        <Grid
          columns={COLUMNS_MODEL_CREATOR_SHOP_LEASING}
          getRowId={(row) => row.id}
          rootComponent={GridRoot}
          rows={rows}
        >
          <DocumentActionTypeProvider
            for={['skusCount']}
          />

          <SelectionState
            onSelectionChange={setSelection}
            selection={selection}
          />
          <IntegratedSelection />
          <RowDetailState />

          <PagingState
            currentPage={query.page}
            defaultCurrentPage={query.page}
            defaultPageSize={query.limit}
            onCurrentPageChange={handleCurrentPageChange}
          />

          <CustomPaging totalCount={totalCount} />

          <VirtualTable
            cellComponent={(props) => (
              <VirtualTable.Cell className="OrdersTableCell" {...props} />
            )}
            columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING}
            messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
          />
          <TableHeaderRow />
          {/*<TableSelection selectByRowClick showSelectAll />*/}
          <PagingPanel containerComponent={MyPagerComponent} messages={PAGING_PANEL_MESSAGES} pageSizes={[0, 10, 50 ]} />
        </Grid>
      </Box>
    </>
  );
}