import { gql } from '@apollo/client';

export const GET_BANK_STATEMENT_PERIOD_SUMMARY = gql`
  query GetBankStatementPeriodSummary(
    $accountId: ID!,
    $period: BankStatementPeriodGroupBy
  ) {
    bankStatementAccount(accountId: $accountId) {
      id
      totalCredit
      totalDebit
      totalContractors
      accountHistory {
        endRemainder
        startRemainder
      }
      periods(groupBy: $period) {
        debitedFunds
        endDate
        endRemainder
        receivedFunds
        startDate
        startRemainder
      }
      bankStatement {
        id
        additionalAccounts {
          accountNumber
          bank
          bik
        }
      }
    }
  }
`;