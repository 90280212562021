import { gql } from '@apollo/client';

export const BANK_STATEMENT_CREATE_COMMENT = gql`
  mutation BankStatementCreateComment($bankStatementId: ID!, $text: String!) {
    bankStatementCreateComment(
      bankStatementId: $bankStatementId,
      text: $text
    ) {
      bankStatementComment {
        author {
          id
          login
        }
        createdAt
        text
        updatedAt
        id
      }
    }
  }
`;