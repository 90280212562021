import { gql } from '@apollo/client/core';

export const GET_LOAN_SUPPLIES = gql`
  query LoanSupplies (
    $limit: Int
    $page: Int
    $filter: LoanSupplyFilter
    $sort: Sort
  )  {
    loanSupplies(
      limit: $limit,
      page: $page,
      filter: $filter,
      sort: $sort,
    ) {
      collection {
        id
        status
        amount
        supplyingOrder {
          application {
            id
          }
        }
        supplyingEntityContract {
          application {
            borrowerEntity {
              id
              shortLegalName
              tin
              site
            }
          }
        }
        location
        address
        date
        loan {
          application {
            id
          }
        }
        shippedEquipments {
          id
          status
          name
          quantity
          unit
          pricePerUnit
          vatRate
          purchasePrice
        }
        shippedEquipmentsCount
      }
      metadata {
        totalCount
      }
    }
  }
`;