import { useEffect, useRef, useState } from 'react';

export const useTimer = (delay) => {
  const [seconds, setSeconds] = useState(delay);
  const timeoutId = useRef();

  useEffect(() => {
    if (seconds > 0) {
      timeoutId.current = setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [seconds]);

  return seconds;
};