import React, { useState } from 'react';

import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

export const useFedresursTableHeading = (data) => {
  const intl = useIntl();

  const calcMonthDiff = (start, end) => {
    if (start && end) {
      const startDateTime = DateTime.fromISO(start);
      const stopDateTime = DateTime.fromISO(end);

      const { months } = stopDateTime.diff(startDateTime, 'months');
      return Math.round(months);
    }
    return;
  };

  const [columns] = useState([
    {
      name: 'contractNumber',
      title: 'Договор',
      getCellValue: (row) =>
        `${row.contractNumber}, ${intl.formatMessage({
          id: `fedresurs.leasingStatus.${row.leasingStatus}`,
        })}, ${row.stopReason}`,
    },
    {
      name: 'dates',
      title: 'Срок',
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => {
        const diffMonth = calcMonthDiff(row.startDate, row.stopDate);

        return (
          <>
            {row.startDate ? formatDate(row.startDate) : '__.__.____'} - {row.stopDate ? formatDate(row.stopDate) : '__.__.____'}
            <br />
            {diffMonth && (
              <FormattedMessage
                id="month.plural"
                values={{ amount: diffMonth }}
              />
            )}
          </>
        );
      },
    },
    {
      name: 'lessors',
      title: 'Лизингодатель',
      getCellValue: (row) => {
        if (row.lessors.length > 1) {
          console.info('Лизингодателей больше одного', row.lessors);
        }

        const { inn, name, ogrn } = row.lessors[0] || {};
        return `${name}, ${inn}, ${ogrn}`;
      },
    },
    {
      name: 'lessees',
      title: 'Лизингополучатель',
      getCellValue: (row) => {
        if (row.lessees.length > 1) {
          console.warn('Лизингополучателей больше одного', row.lessees);
        }

        const { inn, name, ogrn } = row.lessees[0] || {};
        return `${name}, ${inn}, ${ogrn}`;
      },
    },
    {
      name: 'subjects',
      title: 'Предметы лизинга',
      getCellValue: (row) => {
        return row.subjects
          .map(({ classifierName, description }) =>
            classifierName ? classifierName + ': ' + description : description,
          )
          .join(',');
      },
    },
  ]);

  const [columnExtensions] = useState([
    { columnName: 'contractNumber', width: 180, wordWrapEnabled: true },
    { columnName: 'dates', width: 184 },
    { columnName: 'lessors', wordWrapEnabled: true },
    { columnName: 'lessees', wordWrapEnabled: true },
    { columnName: 'subjects', wordWrapEnabled: true },
  ]);

  const [rows] = useState(data);
  const [entityColumns] = useState(['lessors', 'lessees']);
  const [subjectsColumns] = useState(['subjects']);
  const [contractInfoColumns] = useState(['contractNumber']);

  return {
    columnExtensions,
    columns,
    contractInfoColumns,
    entityColumns,
    rows,
    subjectsColumns,
  };
};