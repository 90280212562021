import { gql } from '@apollo/client/core';

export const GET_USER_HOLIDAYS = gql`
  query GetUserHolidays (
    $userId: ID!
  ) {
    user (
      userId: $userId
    ) {
      contact {
        lastName
        firstName
        middleName
      }
      leaves (order: DESC) {
        id
        startDate
        endDate
        kind
        userTasksStatus
        deputy {
          id
          roles
          avatar
          displayName
          contact {
            firstName
            middleName
            lastName
          }
        }
        user {
          deputy {
            id  #нужо для отображения кнопки линковка/разлиновка. Т.е. если есть этот id, то задачи переданы
          }
        }
      }
    }
  }
`;