import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

CopiedText.propTypes = {
  feedback: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default function CopiedText({
  feedback = 'Скопировано!',
  text,
  title = 'Копировать',
}) {
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState(title || '');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setMessage(title);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CopyToClipboard
      onCopy={(a) => {
        setMessage(feedback);
      }}
      text={text}
    >
      <Tooltip
        enterTouchDelay={100}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        title={message}
      >
        <Box
          component="span"
          sx={{
            cursor: 'pointer',
            borderBottom: '1px dashed',
            borderColor: 'text.secondary',
            color: 'text.secondary',
          }}
        >
          {text}
        </Box>
      </Tooltip>
    </CopyToClipboard>
  );
}
