import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  Switch,
  Toolbar,
  Typography,
  Alert,
  AlertTitle,
} from '@material-ui/core';
import _get from 'lodash.get';
import _orderBy from 'lodash.orderby';

import Schedule from './Schedule';
import ScheduleState, { ScheduleContext } from './ScheduleContext';
import ScheduleList from './ScheduleList';
import { LOAN_MIGRATION } from '../graphql/mutations/financeLoanMigration';
import { GET_APPLICATION } from '../graphql/queries/application';

ScheduleContainer.propTypes = {
  applicationId: PropTypes.string,
};

export function orderSchedules(schedules) {
  const iterator = ({ state }) => ({
    draft: 0,
    archive: 1,
    active: -1,
  }[state]);
  return _orderBy(schedules, [iterator, 'id'], ['asc', 'desc']);
}

export default function ScheduleContainer({ applicationId }) {
  const { data, error, loading, refetch } = useQuery(GET_APPLICATION, {
    variables: { applicationId },
  });

  const loanId = _get(data, 'application.loan.id');
  const schedules = _get(data, 'application.loan.schedules', []);

  const isComparedModeEnable = schedules.length > 1;

  if (error || loading) {
    return (
      <Box maxWidth={1256} px={4} py={3}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h2">График платежей</Typography>
          </Box>
        </Toolbar>
        {error && (
          <Alert
            action={
              <Button
                color="inherit"
                disabled={loading}
                onClick={() => refetch()}
              >
                {/* FIXME: to locales */}
                Повторить запрос
              </Button>
            }
            severity="error"
          >
            {/* FIXME: to locales */}
            <AlertTitle>Ошибка при получении данных</AlertTitle>
            {error.message}
          </Alert>
        )}
        {loading && <LinearProgress />}
      </Box>
    );
  }

  return (
    <ScheduleState loanId={loanId}>
      <Box maxWidth={1256} px={4} py={3}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h2">График платежей</Typography>
          </Box>
          {isComparedModeEnable && <ScheduleContainerActions />}
          <LoanMigrationButton loanId={loanId} />
        </Toolbar>
        <ScheduleWrapper loanId={loanId} />
      </Box>
    </ScheduleState>
  );
}

LoanMigrationButton.propTypes = {
  loanId: PropTypes.any,
};

function LoanMigrationButton({ loanId }) {
  const { dispatch } = useContext(ScheduleContext);

  const [migrate, { loading }] = useMutation(LOAN_MIGRATION, {
    variables: { loanId },
    onCompleted: (data) => {
      const newScheduleId = data?.financeLoanMigration?.schedule?.id;
      dispatch({ type: 'MIGRATE_COMPLETE', payload: { id: newScheduleId } });
    },
    onError: (error) => {
      alert(`Произошла ошибка при переносе графика ${error.message}`);
    },
  });

  function handleClick() {
    migrate();
  }

  return (
    <Button
      color="primary"
      disableElevation
      disabled={loading}
      onClick={handleClick}
      variant="outlined"
    >
      Перенести старый график
    </Button>
  );
}

function ScheduleContainerActions() {
  const { dispatch, state } = useContext(ScheduleContext);
  const { comparedScheduleId } = state;

  function handleModeChange() {
    if (comparedScheduleId) {
      dispatch({ type: 'COMPARED_MODE_OFF' });
    } else {
      dispatch({ type: 'COMPARED_MODE_ON' });
    }
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={!!comparedScheduleId}
          color="primary"
          name="compare"
          onChange={handleModeChange}
        />
      }
      label="Сравнение"
    />
  );
}

ScheduleWrapper.propTypes = {
  loanId: PropTypes.string,
};

function ScheduleWrapper({ loanId }) {
  const {
    state: { activeScheduleId, comparedScheduleId },
  } = useContext(ScheduleContext);

  if (!activeScheduleId) {
    return <ScheduleList loanId={loanId} />;
  }

  return (
    <Box alignItems="flex-start" display="flex">
      {!comparedScheduleId && <ScheduleList loanId={loanId} />}
      <Box width={0.5}>
        <Schedule id={activeScheduleId} />
      </Box>
      {comparedScheduleId && (
        <Box width={0.5}>
          <Schedule comparedScheduleId={activeScheduleId} id={comparedScheduleId} />
        </Box>
      )}
    </Box>
  );
}
