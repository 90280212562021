import { gql } from '@apollo/client';

export const CREDIT_HISTORY_FETCH_PROCESS_FRAGMENT = gql`
  fragment CreditHistoryFetchProcessFragment on CreditHistoryFetchProcess {
    id
    state
    createdAt
    startedAt
    finishedAt
    failedAt
    resultData
    summary {
      activeCreditsCount
      activeCreditsSum
      endedCreditsSum
      soldCreditsSum
      currentMonthlyPayments
      maxOverdueInRub
      maxOverdueInDays
      daysFromLastUpdate
    }
    verificationData {
      dateOfBirth
      firstName
      lastName
      middleName
      passportIssueDate
      passportNumber
      passportSeries
    }
  }
`;

export const CRIMINAL_CHECK_PROCCESS_FRAGMENT = gql`
  fragment CriminalCheckProcessFragment on CriminalActivityCheckProcess {
    id
    createdAt
    failedAt
    finishedAt
    startedAt
    contactData {
      id
      lastName
      firstName
      middleName
      dateOfBirth
      passportNumber
      passportSeries
    }
    state
    financialFraud
    terrorism
    weaponDistribution
  }
`;