import PropTypes from 'prop-types';
import React, { forwardRef, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { MenuItem } from '@material-ui/core';

import { ScheduleContext } from './ScheduleContext';
import {
  ACTIVATE_DRAFT_SCHEDULE,
} from '../graphql/mutations/financeActivateSchedule';
import {
  GET_SCHEDULE_LIST_FOR_LOAN,
} from '../graphql/queries/scheduleListForLoan';

const propTypes = {
  scheduleId: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/display-name
const ActivateScheduleMenuItem = forwardRef(({ scheduleId }, ref) => {
  const {
    dispatch,
    state: { loanId },
  } = useContext(ScheduleContext);

  const [activateDraft] = useMutation(ACTIVATE_DRAFT_SCHEDULE, {
    variables: { scheduleId },
    onError: (error) => {
      alert(`Произошла ошибка при активации графика: ${error.message}`);
    },
    onCompleted: () => {
      dispatch({ type: 'ACTIVATE_ITEM', payload: { id: scheduleId } });
    },
    refetchQueries: [{
      query: GET_SCHEDULE_LIST_FOR_LOAN,
      variables: { loanId },
    }],
  });

  //   const [deleteDraft] = useMutation(ACTIVATE_DRAFT_SCHEDULE, {
  //     variables: { scheduleId },
  //     update: (cache) => {
  //       cache.modify({
  //         id: cache.identify({ __typename: 'Loan', id: loanId }),
  //         fields: {
  //           schedules(existingScheduleRefs = [], { readField }) {
  //             return existingScheduleRefs.filter(
  //               (scheduleRef) => scheduleId !== readField('id', scheduleRef),
  //             );
  //           },
  //         },
  //       });
  //     },
  //   });

  function handleDeleteScheduleClick() {
    activateDraft();
  }
  // FIXME: to locales
  return (
    <MenuItem onClick={handleDeleteScheduleClick}>
      Активировать график
    </MenuItem>
  );
});

export default ActivateScheduleMenuItem;

ActivateScheduleMenuItem.propTypes = propTypes;
