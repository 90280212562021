import { gql } from '@apollo/client/core';

export const FINMONITORING_UPDATE_REPORTING_MESSAGE = gql`
    mutation FinmonitoringUpdateReportingMessage($messageId: ID!, $externalId: ID!) {
        finmonitoringUpdateReportingMessage(
            externalId: $externalId
            finmonitoringReportingMessageId: $messageId
        ) {
            finmonitoringReportingMessage {
                id
                externalId
            }
        }
    }
`;