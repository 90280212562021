import { gql } from '@apollo/client/core';

export const FINMONITORING_CHANGE_MESSAGE_STATUS_TO_SENT = gql`
  mutation FinmonitoringChangeReporingMessageStatusToSent(
    $messageId: ID!
    $externalId: ID!
  ) {
    finmonitoringChangeReportingMessageStatusToSent(
      finmonitoringReportingMessageId: $messageId
      externalId: $externalId
    ) {
      finmonitoringReportingMessage {
        id
        sentAt
        state
        updatedAt
      }      
      problems {
        context
        messages
        path
        type
      }
    }
  }
`;