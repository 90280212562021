/* eslint-disable react/prop-types */
import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Link, Toolbar, Typography } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';
import _get from 'lodash.get';
import { DateTime } from 'luxon';
import transformUrl from 'transform-url';

import BackdropLoader from 'components/BackdropLoader';
import FormattedCurrency from 'components/FormattedCurrency';

import {
  VERIFICATION_ASSIGN_PROCESS,
} from '../graphql/mutations/verificationAssignProcess';
import {
  VERIFICATION_CANCEL_PROCESS,
} from '../graphql/mutations/verificationCancelProcess';
import {
  GET_VERIFICATION_MY_PROCESSES,
} from '../graphql/queries/verificationMyProcesses';

// const FINISH_PROCESS_MUTATION = gql`
//   mutation FinishProcess($id: ID!) {
//     verificationFinishProcess(verificationProcessId: $id) {
//       verificationProcess {
//         id
//         resolutionText
//         state
//         verificator {
//           id
//           login
//         }
//       }
//     }
//   }
// `;

const APPLICATION_PATH = `${process.env.REACT_APP_HOST}/applications/:applicationId`;
const LEGAL_ENTITY_PATH = `${process.env.REACT_APP_HOST}/entities/:legalEntityId`;
// const CONTACT_PATH = `${process.env.REACT_APP_HOST}/contacts/:contactId`;
const VERIFICATION_PROCESS_PATH = '/verification-process/:processId';

export default function VerificationProcessList() {
  const { data, loading } = useQuery(GET_VERIFICATION_MY_PROCESSES);

  if (loading) {
    return <BackdropLoader />;
  }

  const myProcesses = _get(data, 'verificationMyProcesses', []).filter(
    ({ state }) => state === 'in_progress',
  );
  const pendingProcesses = _get(data, 'verificationPendingProcesses', []);

  return (
    <Box>
      <Typography variant="h2">Заявки на верификации</Typography>
      <Box display="flex">
        <Box
          bgcolor="#F4F5F7"
          borderRadius={4}
          flexShrink={0}
          mr={2}
          pb={2}
          px={2}
          width={320}
        >
          <Toolbar>
            <Box alignSelf="center" flexGrow={1}>
              {/* FIXME: to locales */}
              <Typography variant="h4">Не взятые в работу</Typography>
            </Box>
          </Toolbar>
          <Box bgcolor="white" borderRadius={4}>
            {pendingProcesses.map((p) => {
              return <VerificationProcessListItem data={p} key={p.id} />;
            })}
          </Box>
        </Box>
        <Box
          bgcolor="#F4F5F7"
          borderRadius={4}
          flexShrink={0}
          mr={2}
          pb={2}
          px={2}
          width={320}
        >
          <Toolbar>
            <Box alignSelf="center" flexGrow={1}>
              {/* FIXME: to locales */}
              <Typography variant="h4">У меня в работе</Typography>
            </Box>
          </Toolbar>
          <Box bgcolor="white" borderRadius={4}>
            {myProcesses.map((p) => {
              return <MyVerificationProcessListItem data={p} key={p.id} />;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export function VerificationListItem({ data }) {
  const { application } = data;

  return (
    <Box>
      {/* <Box>{data.id}</Box>
      <Box>resolutionText {data.resolutionText}</Box>
      <Box>state {data.state}</Box> */}
      <Typography variant="h4">
        {DateTime.fromISO(data.createdAt).toFormat('d MMMM yyyy')}
      </Typography>
      <Typography variant="h4">
        Заявка{' '}
        <Link
          href={
            transformUrl(APPLICATION_PATH, { applicationId: application.id })
          }
          target="_blank"
        >
          {application.id}
        </Link>{' '}
        от{' '}
        <Link
          href={
            transformUrl(LEGAL_ENTITY_PATH, {
              legalEntityId: application.borrowerEntity.id,
            })
          }
          target="_blank"
        >
          {application.borrowerEntity.legalName}
        </Link>{' '}
        на сумму <FormattedCurrency value={application.requestedAmount} />
      </Typography>
      <Link
        component={RouterLink}
        to={transformUrl(VERIFICATION_PROCESS_PATH, {
          processId: data.id,
        })}
      >
        # {data.id}
      </Link>
    </Box>
  );
}

function MyVerificationProcessListItem({ data }) {
  const [cancelProcess] = useMutation(VERIFICATION_CANCEL_PROCESS);

  function handleClick() {
    cancelProcess({ variables: { id: Number(data.id) } });
  }

  return (
    <Box>
      <VerificationListItem data={data} />
      <Button
        onClick={handleClick}
        size="small"
        value={data.id}
        variant="outlined"
      >
        Вернуть
      </Button>
    </Box>
  );
}

function VerificationProcessListItem({ data }) {
  const [assignProcess] = useMutation(VERIFICATION_ASSIGN_PROCESS);

  function handleClick() {
    assignProcess({ variables: { id: Number(data.id) } });
  }

  return (
    <Box key={data.id}>
      <VerificationListItem data={data} />
      <Button
        onClick={handleClick}
        size="small"
        value={data.id}
        variant="outlined"
      >
        Взять в работу
      </Button>
    </Box>
  );
}
