import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';

import CopiedText from 'components/CopiedText';

import { useFedresursTableHeading } from './useFedresursTableHeading';
import { GridRoot } from "../deals/components/helpers";

EntityFormatter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
  }),
  row: PropTypes.shape({
    lessors: PropTypes.array,
  }),
  value: PropTypes.string,
};
function EntityFormatter({ column, row, value }) {
  const { inn, name, ogrn } = row[column.name][0] || {};
  //   if (row.lessors.length > 1) {
  //     console.warn('Лизингодателей больше одного', row.lessors);
  //   }
  //   return row.lessors[0].name;
  return (
    <span>
      <span style={{ fontWeight: 500 }}>{name || 'Компания не определена'}</span>
      <div>
        <CopiedText
          feedback="ИНН скопирован!"
          text={inn || 'ИНН отсутствует'}
          title="Скопировать ИНН"
        /> /{' '}
        <CopiedText
          feedback="ОГРН скопирован!"
          text={ogrn || 'ОГРН отсутствует'}
          title="Скопировать ОГРН"
        />
      </div>
    </span>
  );
}

function EntityTypeProvider(props) {
  return <DataTypeProvider formatterComponent={EntityFormatter} {...props} />;
}

SubjectsFormatter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.any,
  }),
  row: PropTypes.any,
  value: PropTypes.any,
};
function SubjectsFormatter({ column, row, value }) {
  const { subjects } = row;

  function renderSubject({ classifierCode, classifierName, description }, i) {
    return (
      <div key={i}>
        {classifierCode ? <span>{classifierCode}</span> : null}
        {classifierCode && classifierName ? ': ' : null}
        {classifierName ? <span>{classifierName}</span> : null}
        <div style={{ fontStyle: 'italic' }}>{description}</div>
      </div>
    );
  }
  return subjects.length > 2 ? (
    <>
      {subjects.slice(0, 2).map(renderSubject)}
      <div>еще {subjects.length - 2}</div>
    </>
  ) : (
    subjects.map(renderSubject)
  );
}

function SubectsTypeProvider(props) {
  return <DataTypeProvider formatterComponent={SubjectsFormatter} {...props} />;
}

ContractInfoFormatter.propTypes = {
  row: PropTypes.shape({
    leasingStatus: PropTypes.string,
    stopReason: PropTypes.string,
    contractNumber: PropTypes.string,
  }),
};
function ContractInfoFormatter({ row }) {
  return (
    <>
      <div>{row.contractNumber}</div>
      <b>
        <FormattedMessage id={`fedresurs.leasingStatus.${row.leasingStatus}`} />
      </b>{' '}
      {row.stopReason && `(${row.stopReason})`}
    </>
  );
}

function ContractInfoTypeProvider(props) {
  return (
    <DataTypeProvider formatterComponent={ContractInfoFormatter} {...props} />
  );
}

function RowDetail({ row }) {
  return row.messages.map(({ data, details, id, kind }) => (
    <Box key={id} ml="36px">
      <a
        href={`https://fedresurs.ru/sfactmessage/${data.guid}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {data.title} от {data.datePublish}
      </a>
      {/* {details.content.text} */}
    </Box>
  ));
}

TableFedresurs.propTypes = {
  data: PropTypes.array,
};
export default function TableFedresurs({ data = [] }) {
  const styles = useMemo(() => ({
    wrapperTable: {
      margin: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '60px',

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
    },
  }), []);

  const {
    columnExtensions,
    columns,
    contractInfoColumns,
    entityColumns,
    rows,
    subjectsColumns,
  } = useFedresursTableHeading(data);

  rows.forEach(row => {
    row["id"] = uuid();
  })

  return (
    <Box sx={ styles.wrapperTable }>
      <Grid
        columns={columns}
        getRowId={(row) => row.id}
        rootComponent={GridRoot}
        rows={rows}
      >
        <ContractInfoTypeProvider for={contractInfoColumns} />
        <EntityTypeProvider for={entityColumns} />
        <SubectsTypeProvider for={subjectsColumns} />
        <FilteringState />
        <RowDetailState />
        <IntegratedFiltering />
        <VirtualTable columnExtensions={columnExtensions} />
        <TableHeaderRow />
        <TableRowDetail contentComponent={RowDetail} />
        <TableFilterRow />
      </Grid>
    </Box>
  );
}
