import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Chip, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink, useLocation } from '@reach/router';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

import {
  ENTITY_ACCEPT_CONTACT_QUESTIONNAIRE,
} from './graphql/mutations/entityAcceptContactQuestionnaire';
import {
  ENTITY_COMPLETE_CONTACT_QUESTIONNAIRE,
} from './graphql/mutations/entityCompleteContactQuestionnaire';
import {
  GET_CONTACT_ENTITY_QUESTIONNAIRE_DATA,
} from './graphql/queries/contactEntityQuestionnaire';
import QuestionnaireData from './QuestionnaireData';

PageQuestionnaireItem.propTypes = {
  entity: PropTypes.object,
  questionnaireId: PropTypes.string,
  refetch: PropTypes.func,
};

export default function PageQuestionnaireItem({
  entity,
  questionnaireId,
  refetch,
}) {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const defaultQueryVariables = useMemo(() => ({
    contactEntityQuestionnaireId: questionnaireId,
  }), [questionnaireId]);
  const [queryVariables, setQueryVariables] = useState(defaultQueryVariables);

  const { data, loading, refetch: refetchCurrentQuestionnaire } = useQuery(
    GET_CONTACT_ENTITY_QUESTIONNAIRE_DATA,
    {
      fetchPolicy: 'no-cache',
      variables: queryVariables,
    },
  );

  useEffect(() => {
    if (searchParams.has('version')) {
      setQueryVariables((prev) => ({
        ...prev,
        contactEntityQuestionnaireId: questionnaireId,
        version: Number(searchParams.get('version')),
      }));
    } else {
      setQueryVariables(defaultQueryVariables);
    }
  }, [location, searchParams, questionnaireId, defaultQueryVariables]);

  if (loading) {
    return 'Загрузка...';
  }

  if (!data?.contactEntityQuestionnaire) {
    return `Анкета с id = ${questionnaireId} не найдена`;
  }

  const { contactEntityQuestionnaire: questionnaire } = data;

  const createdAt = searchParams.has('version')
    ? questionnaire.versions.find(
      (v) => v.version === Number(searchParams.get('version')),
    )?.createdAt
    : null;

  return (
    <Box sx={{ mb: 4 }}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">
            № {questionnaire.id}
            {createdAt && ` от ${formatDate(createdAt)}`}
          </Typography>
          <IconButton
            component={RouterLink}
            to={`/entities/${entity.id}/questionnaires`}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Chip
            label={
              <FormattedMessage
                id={`entity.questionnaire.state.${questionnaire.state}`}
              />
            }
            sx={{
              ...{
                // DRAFT: { bgcolor: 'text.disabled' },
                COMPLETED: { color: 'white', bgcolor: 'info.main' },
                ACCEPTED: { color: 'white', bgcolor: 'success.main' },
              }[questionnaire.state],
            }}
          />
          {questionnaire.state === 'COMPLETED' && (
            <AcceptButton
              questionnaireId={questionnaire.id}
              refetchCurrentQuestionnaire={refetchCurrentQuestionnaire}
              refetchQuestionnaireList={refetch}
            />
          )}
          {questionnaire.state === 'DRAFT' && (
            <CompleteButton
              questionnaireId={questionnaire.id}
              refetchCurrentQuestionnaire={refetchCurrentQuestionnaire}
              refetchQuestionnaireList={refetch}
            />
          )}
        </Box>
      </Box>

      <QuestionnaireData
        entity={entity}
        questionnaire={{ ...questionnaire.data, id: questionnaire.id }}
        refetch={refetch}
      />
    </Box>
  );
}

const AcceptButton = ({
  questionnaireId,
  refetchCurrentQuestionnaire,
  refetchQuestionnaireList,
}) => {
  const [accept, { loading }] = useMutation(
    ENTITY_ACCEPT_CONTACT_QUESTIONNAIRE,
    {
      variables: { questionnaireId },
      onCompleted: () => {
        refetchQuestionnaireList();
        refetchCurrentQuestionnaire();
      },
      onError: (error) => {
        console.log(
          '🚀 ~ file: PageQuestionnairesItem.jsx ~ line 158 ~ AcceptButton ~ error',
          error,
        );
        alert(`Произошла ошибка: ${error.message}`);
      },
    },
  );

  return (
    <Button disabled={loading} onClick={accept}>
      Принять
    </Button>
  );
};

AcceptButton.propTypes = {
  questionnaireId: PropTypes.string,
  refetchCurrentQuestionnaire: PropTypes.func,
  refetchQuestionnaireList: PropTypes.func,
};

const CompleteButton = ({
  questionnaireId,
  refetchCurrentQuestionnaire,
  refetchQuestionnaireList,
}) => {
  const [complete, { loading }] = useMutation(
    ENTITY_COMPLETE_CONTACT_QUESTIONNAIRE,
    {
      variables: { questionnaireId },
      onCompleted: () => {
        refetchQuestionnaireList();
        refetchCurrentQuestionnaire();
      },
      onError: (error) => {
        console.log(
          '🚀 ~ file: PageQuestionnairesItem.jsx ~ line 194 ~ CompleteButton ~ error',
          error,
        );
        alert(`Произошла ошибка: ${error.message}`);
      },
    },
  );

  return (
    <Button disabled={loading} onClick={complete}>
      Отметить как заполненную
    </Button>
  );
};

CompleteButton.propTypes = {
  questionnaireId: PropTypes.string,
  refetchCurrentQuestionnaire: PropTypes.func,
  refetchQuestionnaireList: PropTypes.func,
};