import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  IntegratedPaging,
  RowDetailState,
  PagingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Typography,
  Button,
  TextField,
  DialogTitle,
  IconButton,
  DialogContent,
  Dialog,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Autocomplete,
  withStyles,
  Link,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import { Link as RouterLink } from '@reach/router';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { cloneDeep, findIndex } from "lodash";
import { useReactToPrint } from "react-to-print";

import ChangeGoodProperties from './components/ChangeGoodProperties';
import ChangeStatus from './components/ChangeStatus';
import IconWarehouse from "./components/IconWarehouse";
import PrintOutput from "./components/PrintOutput";
import QualityValue from "./components/Quality";
import Status from './components/Status';
import { AmountField } from "../../../components/AmountField/AmountField";
import DropzoneImages from "../../../components/DropzoneImages";
import ErrorAlert from "../../../components/ErrorAlert";
import FormattedCurrency from '../../../components/FormattedCurrency';
import { DATE_TIME_FORMAT_VIEW } from "../../../core/constants/Formats";
import { GridRoot, Loader } from "../../deals/components/helpers";
import {
  COLUMN_MODEL_STRUCTURE_SHOP_GOOD,
  COLUMNS_MODEL_CREATOR_SHOP_GOOD,
  PAGING_PANEL_MESSAGES
} from "../constants";
import { SHOP_GOODS_UPDATE } from "../graphql/mutations/shopGoodsUpdate";
import { GET_SHOP_EQUIPMENT_TYPES } from "../graphql/queries/getShopEquipmentTypes";
import { GET_SHOP_GOOD } from "../graphql/queries/getShopGood";
import minPercent from "../img/min-percent.svg";
import minRub from "../img/min-rub.svg";
import print from "../img/print.svg";

dayjs.extend(utc);

const GOOD_INIT = {
  title: null,
  description: null,
  price: null,
  discount_percentage: "",
  discount: null,
  vatPercent: 0,
  images: [],
  equipmentTypes: [],
  equipmentTypeId: [],
};

const DetailGood = ({ id }) => {
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: '32px !important',
      fontWeight: '700 !important',
      width: 'auto',
      margin: '0 16px 0 16px !important',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'left'
    },
    button: {
      background: '#FFFFFF',
      border: '1px solid #CFD2D6',
      borderRadius: '8px',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'none',
      width: 'auto',
      minWidth: 'auto',
      height: '48px',
      padding: '12px 24px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&.Mui-disabled': {
        color: '#6E7884',
        border: '1px solid #CFD2D6',

        '& svg path': {
          fill: '#B6BBC2'
        },
      },
      '&:hover': {
        color: '#3D6CE7',
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',

        '& path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        background: '#365CBF',
        color: '#ffffff',
        border: '1px solid #365CBF',

        '& path': {
          fill: '#ffffff',
        },
      },
    },
    wrapperContent: {
      margin: '24px',
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      border: '1px solid #E7E8EA',
      padding: '24px',
      overflow: 'hidden',
    },
    titleContent: {
      fontSize: '26px',
      fontWeight: 700,
      marginBottom: '24px',
    },
    deal: {
      background: '#F5F6F7',
      borderRadius: '8px',
      padding: '24px',
      marginBottom: '24px',
    },
    wrapperBlock: {
      marginBottom: '24px'
    },
    wrapperBlockRounded: {
      position: 'relative',
      marginBottom: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      padding: '19px 16px',
      minWidth: {
        lg: '24%',
        md: '49%'
      },
      width: {
        lg: 'calc(100% / 4 - 48px)',
        md: 'calc(100% / 2 - 16px)'
      },

      '& b': {
        fontWeight: 700
      },
      '& span': {
        fontSize: '14px',
        color: '#6E7884',
        fontWeight: 300
      }
    },
    infoEdit: {
      position: 'absolute',
      top: '16px',
      right: '16px'
    },
    infoString: {
      marginBottom: '8px',
      wordWrap: 'break-word'
    },
    label: {
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '8px',
      lineHeight: '24px'
    },
    labelSmall: {
      fontSize: '14px',
      fontWeight: 600,
      marginBottom: '8px',
      lineHeight: '24px'
    },
    input: {
      maxHeight: '48px',

      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
        zIndex: 1,

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    chekedInput: {
      '& .MuiButtonBase-root': {
        color: '#CFD2D6',
      },
      '& .Mui-checked': {
        color: '#3651DE !important',
      },
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0D1D32',
      }
    },
    radioInput: {
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0D1D32',
      },
      '& .MuiButtonBase-root': {
        color: '#CFD2D6',
      },
      '& .Mui-checked': {
        color: '#3651DE !important',
      },
    },
    inputDate: {
      marginTop: '1px',

      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',
      maxHeight: '48px',

      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    autocomplete: {
      '& .MuiInputBase-root': {
        background: '#ffffff',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        borderRadius: '8px',
        fontWeight: '400',
        fontSize: '16px',

        '&::placeholder': {
          color: '#B6BBC2',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #3D6CE7 !important',
        },
        '&.Mui-disabled': {
          background: '#F5F6F7',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
      },
    },
    autocompleteList: {
      color: '#6E7884',
      display: 'flex',
      justifyContent: 'space-between !important',

      '&.MuiAutocomplete-option[aria-selected=true]': {
        backgroundColor: '#ffffff!important',
        color: '#3D6CE7 !important',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05) !important',
      },
    },
    redButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#FF5550',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#FF6660',
        boxShadow: '0px 6px 16px rgba(255, 67, 79, 0.3)',
      },
      '&:active': {
        background: '#FF423D',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    whiteButton: {
      background: '#FFFFFF',
      border: '1px solid #3D6CE7',
      borderRadius: '8px',
      color: '#3D6CE7',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'none',
      padding: '12px 0 12px 0',
      maxHeight: '48px',

      '&:hover': {
        background: 'rgba(61, 108, 230, 0.05)',
        border: '1px solid #365CBF',
        color: '#365CBF',
      },
      '&:active': {
        background: '#3D6CE7',
        border: '1px solid #3D6CE7',
        color: '#ffffff',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    modalWrapper: {
      borderBottom: '1px solid #CFD2D6',
      display: 'flex',
      padding: '8px 0',
    },
    modalLabel: {
      fontSize: '14px',
      fontWeight: 500,
      width: '180px',
    },
    modalValue: {
      fontSize: '14px',
      fontWeight: 300,
    },
    wrapperTable: {
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '24px',
      overflow: 'hidden',
      background: '#FFFFFF',
      minHeight: '120px',
      position: 'relative',

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#ffffff !important',

        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .Mui-checked': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    wrapperUpImg: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      marginTop: '24px',
    },
    blockImg: {
      borderRadius: '8px',
      overflow: 'hidden',
      border: '1px solid #E7E8EA',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '142px',
      height: '142px',

      '&.PURGE': {
        opacity: '0.3'
      },
      '& .index': {
        position: 'absolute',
        right: '8px',
        top: '8px',
        background: '#67BF3D',
        borderRadius: '20px',
        color: '#ffffff',
        fontSize: '14px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .remove': {
        cursor: 'pointer',
        position: 'absolute',
        left: '8px',
        top: '8px',
        background: '#67BF3D',
        borderRadius: '20px',
        color: '#ffffff',
        fontSize: '14px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& img': {
        width: '100%',
      }
    }
  }), []);

  const MyPager = ({ ...restProps }) => {
    return <PagingPanel.Container
      {...restProps}
      className={`CustomPaging`}
    />;
  };

  const MyPagerComponent = withStyles({ name: "MyPager" })(
    MyPager
  );

  const componentRef = useRef(null);

  const [fetchEquipmentTypes, { data: equipmentTypes, loadingEquipmentTypes }] = useLazyQuery(GET_SHOP_EQUIPMENT_TYPES);
  const [fetchGood, { data: good, error, loading }] = useLazyQuery(GET_SHOP_GOOD, {
    fetchPolicy: 'network-only'
  });

  const [updateGood, { loading: loadingUpdateGood }] = useMutation(SHOP_GOODS_UPDATE, {
    onCompleted: (result) => {
      fetchGood({ variables: { goodId: parseInt(id, 10) }});
      setDescriptionModalStatus(`Товар ${id} успешно обновлен`)
      setTitleModalStatus("Товар обновлен");
      toggleModalStatus("", true);
    },
    onError: (error) => {
      setDescriptionModalStatus("Ошибка обновления")
      setTitleModalStatus("Ошибка");
      toggleModalStatus("", true);
    },
  });

  const [loadingGood, setLoadingGood] = useState(true);
  const [goodItem, setGoodItem] = useState({ ...GOOD_INIT});
  const [files, setFiles] = useState([]);

  const [descriptionModalStatus, setDescriptionModalStatus] = useState(null);
  const [titleModalStatus, setTitleModalStatus] = useState(null);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [showChangeGood, setShowChangeGood] = useState(false);
  const [equipment, setEquipment] = useState([]);

  const onChangeField = (type, event) => {
    let value;
    const newGood = cloneDeep(goodItem);
    switch (type) {
      case 'vatPercent':
        value = Number(event.target.value);
        break;
      case 'price':
        value = event.target.value;

        if (newGood.discount && value) {
          newGood.discount_percentage = (value - ( value / 100 * newGood.discount )).toFixed(0);
        } else {
          newGood.discount_percentage = "";
        }
        break;
      case 'discount_percentage':
        value = event.target.value;

        if (newGood.price && value) {
          newGood.discount = (100 - (value * 100 / newGood.price)).toFixed(3);
        } else {
          newGood.discount = "";
        }
        break;
      case 'discount':
        value = event.target.value;

        if (newGood.price && value) {
          newGood.discount_percentage = (goodItem.price - ( newGood.price / 100 * value )).toFixed(0);
        } else {
          newGood.discount_percentage = "";
        }
        break;
      case 'equipmentTypesTop':
        {
          const newValue = event.target.value;
          const index = findIndex(equipment, ["id", newValue.toString()]);

          newGood.equipmentTypes[0] = equipment[index];
          newGood.equipmentTypeId[0] = equipment[index].id;
        }
        break;
      default:
        value = event.target.value;
        break;
    }

    if(type !== 'equipmentTypesTop') {
      newGood[type] = value;
    }

    setGoodItem(newGood);
  };

  const toggleModalStatus = (_, show) => {
    if (!show) {
      setTitleModalStatus(null);
      setDescriptionModalStatus(null);
    }

    setShowModalStatus(show);
  };

  const toggleModalChangeGood = (_, show) => (e) => {
    if (good?.shopGood?.status === 'PUBLISHED') {
      setDescriptionModalStatus("Снимите товар с продажи перед редактированием.")
      setTitleModalStatus("Ошибка");
      toggleModalStatus("", true);

      return false;
    }

    setShowChangeGood(show);
  };

  const handleDownloadedDeleteFile = (id) => {
    const newGood = cloneDeep(goodItem);

    let indexElem = findIndex(newGood.images, ["imageId", id]);

    if (indexElem !== -1) {
      if (newGood.images[indexElem].action === "PURGE") {
        newGood.images[indexElem].action = "UPLOAD";
      } else {
        newGood.images[indexElem].action = "PURGE";
      }
    }

    setGoodItem(newGood);
  };

  const onSave = () => {
    if (
      goodItem.price === 0 ||
      (goodItem.title && !goodItem.title.length) ||
      (goodItem.description && !goodItem.description.length)
    ) {
      setDescriptionModalStatus("Укажите основную информацию по товару.")
      setTitleModalStatus("Ошибка");
      toggleModalStatus("", true);

      return false;
    }

    if (!goodItem.equipmentTypeId.length) {
      setDescriptionModalStatus("Укажите категорию товара.")
      setTitleModalStatus("Ошибка");
      toggleModalStatus("", true);

      return false;
    }

    if (good?.shopGood?.status === 'PUBLISHED') {
      setDescriptionModalStatus("Снимите товар с продажи перед редактированием.")
      setTitleModalStatus("Ошибка");
      toggleModalStatus("", true);

      return false;
    }

    const newGood = cloneDeep(goodItem);

    newGood.discount = Number(newGood.discount);
    newGood.price = Number(newGood.price);
    newGood.name = newGood.title;

    delete newGood.title;
    delete newGood.discount_percentage;
    delete newGood.equipmentTypes;

    let newImg = [];

    newGood.images.forEach((elem) => {
      delete elem.url;
      delete elem.filename;

      if (elem.action) {
        newImg.push(elem)
      }
    });

    newGood.images = newImg;

    if(newGood?.equipmentTypeId[0]) {
      newGood.equipmentTypeId = parseInt(newGood?.equipmentTypeId[0], 10);
    } else {
      delete newGood.equipmentTypeId;
    }

    updateGood({
      variables: {
        params: newGood,
        goodId: id,
      }
    });
  };

  const onChangeStatus = (result) => {
    const statusName = (stat) => {
      switch (stat) {
        case 'IN_STOCK':
          return '"На складе"';
        case 'READY_FOR_PUBLISH':
          return '"Готово к продаже"';
        case 'PUBLISHED':
          return '"В продаже"';
        case 'RESERVED':
          return '"Зарезервировано"';
        case 'SOLD':
          return '"Продано"';
        default:
          return 'Некорректное значение';
      }
    }

    fetchGood({ variables: { goodId: parseInt(id, 10) }});
    setDescriptionModalStatus(`Статус успешно изменён на ${statusName(result?.shopGoodsChangeStatus?.good?.status)}.`);
    setTitleModalStatus("Изменение статуса");
    toggleModalStatus("", true);
  };

  const onChangeStatusError = () => {
    setDescriptionModalStatus("Ошибка изменения статуса товара");
    setTitleModalStatus("Ошибка");
    toggleModalStatus("", true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if(goodItem && goodItem.images) {
      const newGood = cloneDeep(goodItem);
      let newFiles = [];

      files.forEach((file) => {
        newFiles.push({
          action: "UPLOAD",
          file: file,
          imageId: file.imageId
        });
      });

      newGood.images = newGood.images.concat(newFiles);

      setGoodItem(newGood);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (id && id !== 'good_create' && good && good?.shopGood && !loading) {
      setLoadingGood(false);
      setGoodItem({
        title: good?.shopGood?.name || GOOD_INIT.name,
        description: good?.shopGood?.description || GOOD_INIT.description,
        price: good?.shopGood?.price || GOOD_INIT.price,
        equipmentTypes: [
          good?.shopGood?.equipmentType ? good?.shopGood?.equipmentType : undefined
        ],
        equipmentTypeId: good?.shopGood?.equipmentType  ? [
          good?.shopGood?.equipmentType?.id
        ] : [],
        discount_percentage: (() => {
          if (good?.shopGood?.price && good?.shopGood?.discount) {
            return (good?.shopGood?.price - ( good?.shopGood?.price / 100 * good?.shopGood?.discount)).toFixed(2);
          } else {
            return '';
          }
        })(),
        discount: good?.shopGood?.discount || GOOD_INIT.discount,
        vatPercent: good?.shopGood?.vatPercent || GOOD_INIT.vatPercent,
        images: (() => {
          let arr = []
          good?.shopGood?.images.forEach((elem) => {
            arr.push({
              filename: elem.file.filename,
              url: elem.file.externalUrl,
              imageId: elem.id,
              action: null
            })
          });

          return arr;
        })()
      });
    }

    if(id && id !== 'good_create' && !good) {
      fetchGood({ variables: { goodId: parseInt(id, 10) }});
    } else {
      setLoadingGood(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, good, loading]);

  useEffect(() => {
    fetchEquipmentTypes({ variables: {topOnly: true}});

    if (equipmentTypes && equipmentTypes?.equipmentTypes) {
      setEquipment(equipmentTypes?.equipmentTypes);
    }
  }, [equipmentTypes, fetchEquipmentTypes])

  const elModalStatus = () => {
    return (
      <Dialog fullWidth open={showModalStatus} scroll="body">
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
            {titleModalStatus}
          </Typography>
          <IconButton onClick={toggleModalStatus.bind(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {descriptionModalStatus}
          <Box sx={{ marginTop: '40px', textAlign: 'right' }}>
            <Button onClick={toggleModalStatus.bind(false)} sx={ styles.blueButton }>
              Закрыть
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const elShowDateFormatted = useCallback((date) => {
    if(date && dayjs(date).format(DATE_TIME_FORMAT_VIEW) !== 'Invalid Date') {
      return (
        dayjs(date).format(DATE_TIME_FORMAT_VIEW)
      );
    }

    return 'Нет даты';
  }, []);

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />;
  }

  if (loading || loadingGood || loadingEquipmentTypes) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 24px', backgroundColor: '#ffffff', height: '100px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Link
              component={RouterLink}
              sx={{ ...styles.button, width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              to={'/workspaces/shop/'}
            >
              <ArrowBackIcon sx={{marginRight: '0 !important'}} />
            </Link>

            <Typography gutterBottom sx={ styles.titlePage } variant="h2">
              Товар / <Typography component={'span'} sx={{color: '#3D6CE7', fontSize: '32px!important', fontWeight: '700!important'}}>{id}</Typography>
              <Status status={good?.shopGood?.status} />
            </Typography>
          </Box>
          <ChangeStatus
            id={id}
            onChangeStatus={onChangeStatus}
            onChangeStatusError={onChangeStatusError}
            status={good?.shopGood?.status}
          />
        </Box>
        <Box sx={ styles.wrapperContent }>
          <Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 48px', alignItems: 'top', gap: '16px' }}>
              <Typography sx={ styles.titleContent }>Информация о товаре:</Typography>
              <IconButton onClick={handlePrint} sx={{ border: '1px solid #CFD2D6', borderRadius: '8px', width: '48px', height: '48px' }}>
                <Box alt={'Печать информации о товаре'} component={"img"} src={print} sx={{ width: '24px', height: '24px' }} />
              </IconButton>
              <div style={{ display: 'none' }}>
                <PrintOutput
                  acceptedAt={good?.shopGood?.createdAt}
                  description={goodItem.description}
                  dispayLoanLeaseAgreement={good?.shopGood?.storageAcceptance.dispayLoanLeaseAgreement}
                  dispayLoanSupplyDate={good?.shopGood?.shippedEquipment?.dispayLoanSupplyDate}
                  id={parseInt(id, 10)}
                  name={goodItem.title}
                  producedAt={good?.shopGood?.producedAt}
                  quality={good?.shopGood?.quality}
                  ref={componentRef}
                  serialNumber={good?.shopGood?.serialNumber}
                />
              </div>
            </Box>

            <Box sx={ styles.wrapperBlock }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>
                <Box sx={ styles.wrapperBlockRounded }>
                  <Typography sx={ styles.label }>
                    Общая
                  </Typography>
                  <Box sx={ styles.infoString }><b>ID отгруженного оборудования:</b> {good?.shopGood?.shippedEquipment?.id}</Box>
                  <Box sx={ styles.infoString }><b>Поставщик:</b> <Link href={good?.shopGood?.shippedEquipment?.displaySupplierUrl}>{good?.shopGood?.shippedEquipment?.displaySupplierLegalNameWithShortEntityType}</Link></Box>
                  <Box sx={ styles.infoString }><b>№ заказа:</b> <Link href={good?.shopGood?.shippedEquipment?.displaySupplyingOrderUrl}>{good?.shopGood?.shippedEquipment?.displaySupplyingOrderNumber}</Link></Box>
                  <Box sx={ styles.infoString }><b>№ отгрузки:</b> <Link href={good?.shopGood?.shippedEquipment?.dispayPurchaseInvoicesUrl}>{good?.shopGood?.shippedEquipment?.loanSupply?.id}</Link></Box>
                  <Box sx={ styles.infoString }>
                    <b>Цена реализации:</b> <FormattedCurrency value={good?.shopGood?.sellingPrice} /> <span>
                      (НДС <FormattedCurrency value={good?.shopGood?.shippedEquipment?.vat} />)
                    </span>
                  </Box>
                </Box>
                <Box sx={ styles.wrapperBlockRounded }>
                  <Typography sx={ styles.label }>
                    Местонахождение
                  </Typography>
                  <Box sx={ styles.infoString }><b>ID приемки:</b> <Link href={good?.shopGood?.storageAcceptance?.url}>{good?.shopGood?.storageAcceptance?.id}</Link></Box>
                  <Box sx={ styles.infoString }><b>Дата поступления:</b> {elShowDateFormatted(good?.shopGood?.createdAt)}</Box>
                  <Box sx={{ ...styles.infoString, color: 'green'}}>
                    <b style={{ color: "#212121" }}>Фактический адрес:</b>
                    {" "}
                    {good?.shopGood?.currentLocation === 'CLIENT' && good?.shopGood?.currentLocationAddress}

                    {good?.shopGood?.currentLocation === 'STORAGE' && (
                      <>
                        <IconWarehouse number={good?.shopGood?.storage?.id}  />
                        {" "}
                        {good?.shopGood?.storage?.address}
                      </>
                    )}
                  </Box>
                  <Box sx={{ ...styles.infoString, color: 'green'}}>
                    <b style={{ color: "#212121" }}>Адрес для публикации:</b>
                    {" "}

                    {good?.shopGood?.locationAddressForSite || '---'}
                  </Box>
                  <Box sx={ styles.infoString }><b>Ответственный за приемку на склад:</b> <Link href={good?.shopGood?.storageAcceptance?.displayAcceptedByContactUrl}>{good?.shopGood?.storageAcceptance?.displayAcceptedByLegalName}</Link></Box>
                  <Box sx={ styles.infoString }><b>Комментарий:</b> {good?.shopGood?.storageComment}</Box>
                </Box>
                <Box sx={ styles.wrapperBlockRounded }>
                  <Typography sx={ styles.label }>
                    Изъятие
                  </Typography>
                  <Box sx={ styles.infoString }><b>ID изъятия:</b> <Link href={good?.shopGood?.storageAcceptance?.displaySeizureActScanUrl}>{good?.shopGood?.storageAcceptance?.displaySeizureActNumber}</Link></Box>
                  <Box sx={ styles.infoString }><b>Дата изъятия:</b> {elShowDateFormatted(good?.shopGood?.storageAcceptance?.displaySeizureActDate)}</Box>
                  <Box sx={ styles.infoString }><b>Ответственный за изъятие:</b> <Link href={good?.shopGood?.storageAcceptance?.displaySeizureActCollectorContactUrl}>{good?.shopGood?.storageAcceptance?.displaySeizureActCollectorName}</Link></Box>
                </Box>
                <Box sx={ styles.wrapperBlockRounded }>
                  <Typography sx={ styles.label }>
                    Характеристики
                  </Typography>
                  <Box sx={{ ...styles.infoString, color: 'green' }}>
                    <b style={{ color: '#212121' }}>Дата производства:</b>
                    {" "}
                    {elShowDateFormatted(good?.shopGood?.producedAt)}
                  </Box>
                  <Box sx={{ ...styles.infoString, color: 'green' }}>
                    <b style={{ color: '#212121' }}>Начало эксплуатации:</b>
                    {" "}
                    {elShowDateFormatted(good?.shopGood?.shippedEquipment?.dispayLoanSupplyDate)}
                  </Box>
                  <Box sx={ styles.infoString }><b>Состояние:</b> <QualityValue quality={good?.shopGood?.quality} /></Box>
                  <Box sx={ styles.infoString }><b>Серийный номер:</b> {good?.shopGood?.serialNumber}</Box>
                  <IconButton
                    onClick={toggleModalChangeGood('', true)}
                    size="small"
                    sx={ styles.infoEdit }
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box sx={ styles.wrapperBlock }>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>
                <Box sx={{ ...styles.wrapperBlock, minWidth: '24%', width: 'calc(100% / 4 - 48px)' }}>
                  <Typography sx={ styles.labelSmall }>
                    Цена:
                  </Typography>
                  <TextField
                    InputProps={{
                      inputComponent: AmountField,
                      endAdornment: (
                        <InputAdornment position="end" sx={{ zIndex: 1 }}>
                          <Box alt={'Рублей'} component={"img"} src={minRub} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    onChange={onChangeField.bind(this, 'price')}
                    sx={{
                      ...styles.input,
                      '& fieldset': {
                        background: '#ffffff',
                        borderRadius: '8px',
                      }
                    }}
                    value={goodItem?.price}
                  />
                </Box>

                <Box sx={{ ...styles.wrapperBlock, minWidth: '24%', width: 'calc(100% / 4 - 48px)' }}>
                  <Typography sx={ styles.labelSmall }>
                    Цена с учетом скидки:
                  </Typography>
                  <TextField
                    InputProps={{
                      inputComponent: AmountField,
                      endAdornment: (
                        <InputAdornment position="end" sx={{ zIndex: 1 }}>
                          <Box alt={'Рублей'} component={"img"} src={minRub} />
                        </InputAdornment>
                      ),
                    }}
                    disabled={true}
                    fullWidth
                    onChange={onChangeField.bind(this, 'discount_percentage')}
                    sx={{
                      ...styles.input,
                      '& fieldset': {
                        background: '#ffffff',
                        borderRadius: '8px',
                      }
                    }}
                    value={goodItem?.discount_percentage}
                  />
                </Box>

                <Box sx={{ ...styles.wrapperBlock, minWidth: '24%', width: 'calc(100% / 4 - 48px)' }}>
                  <Typography sx={ styles.labelSmall }>
                    В процентах:
                  </Typography>

                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ zIndex: 1 }}>
                          <Box alt={'Рублей'} component={"img"} src={minPercent} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    onChange={onChangeField.bind(this, 'discount')}
                    sx={{
                      ...styles.input,
                      '& fieldset': {
                        background: '#ffffff',
                        borderRadius: '8px',
                      }
                    }}
                    type="number"
                    value={goodItem.discount}
                  />
                </Box>

                <Box sx={{ ...styles.wrapperBlock, minWidth: '24%', width: 'calc(100% / 4 - 48px)' }}>
                  <FormControl sx={{ display: 'flex', flexDirection: "column" }}>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      sx={{ marginRight: '16px', marginBottom: "8px", fontSize: '14px', color: '#0D1D32 !important', fontWeight: 'bold' }}
                    >
                      НДС
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={goodItem.vatPercent || GOOD_INIT.vatRate}
                      name="radio-buttons-group"
                      onChange={onChangeField.bind(this, 'vatPercent')}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        checked={goodItem.vatPercent === 0}
                        control={<Radio />}
                        label="0%"
                        sx={styles.radioInput}
                        value="0"
                      />
                      <FormControlLabel
                        checked={goodItem.vatPercent === 20}
                        control={<Radio />}
                        label="20%"
                        sx={styles.radioInput}
                        value="20"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.labelSmall }>
                Название (заголовок):
              </Typography>

              <TextField
                fullWidth
                multiline={true}
                onChange={onChangeField.bind(this, 'title')}
                sx={{ ...styles.input, maxHeight: 'auto' }}
                type="text"
                value={goodItem.title}
              />
            </Box>
            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.labelSmall }>
                Описание:
              </Typography>

              <TextField
                fullWidth
                minRows={3}
                multiline={true}
                onChange={onChangeField.bind(this, 'description')}
                sx={{ ...styles.input, maxHeight: 'auto' }}
                type="text"
                value={goodItem.description}
              />
            </Box>
            <Box sx={ styles.wrapperBlock } >
              <Typography sx={ styles.labelSmall }>
                Категория:
              </Typography>

              <Autocomplete
                disableClearable
                disablePortal
                getOptionLabel={(option) => option.name}
                noOptionsText={loadingEquipmentTypes ? 'Поиск...' : 'Нет данных'}
                onChange={onChangeField.bind(this, 'equipmentTypesTop')}
                options={equipment}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    hiddenLabel={true}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    placeholder={'Не выбрано'}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    id={option.id}
                    sx={styles.autocompleteList}
                    value={option.id}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                sx={styles.autocomplete}
                value={goodItem.equipmentTypes?.length > 0 ? goodItem.equipmentTypes[0] : undefined}
              />
            </Box>

            <Box sx={ styles.wrapperBlock }>
              <Typography sx={ styles.labelSmall }>
                Фото товара:
              </Typography>
              <Typography sx={{ fontWeight: 300, fontSize: '14px', marginBottom: '24px' }}>
                Размер фото не более 1200x1200px. Порядок фото можно менять простым перетаскиванием.
              </Typography>

              <DropzoneImages onFilesDropped={setFiles} />

              {goodItem.images && goodItem.images.length > 0 && (
                <>
                  <Box sx={ styles.wrapperUpImg }>
                    {goodItem.images.map((file, index) => (
                      <Box className={file.action} key={index} sx={styles.blockImg}>
                        <Box className={'remove'} onClick={handleDownloadedDeleteFile.bind(this, file?.file?.imageId || file?.imageId || file.imageId)}>x</Box>
                        <Box className={'index'}>{index + 1}</Box>

                          <img
                            alt={file?.file?.name || file?.name || file.filename}
                            src={file?.file?.preview || file?.preview || file.url}
                            width="120px"
                          />
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>

            <Box sx={styles.wrapperTable}>
              <Grid
                columns={COLUMNS_MODEL_CREATOR_SHOP_GOOD}
                getRowId={(row) => row.id}
                rootComponent={GridRoot}
                rows={ good?.shopGood?.orders || [] }
              >
                <PagingState />

                <IntegratedPaging />
                <RowDetailState />

                <VirtualTable
                  cellComponent={(props) => (
                    <VirtualTable.Cell className="OrdersTableCell" {...props} />
                  )}
                  columnExtensions={COLUMN_MODEL_STRUCTURE_SHOP_GOOD}
                  messages={{ noData: 'Нет данных' }}
                />
                <TableHeaderRow />
                <PagingPanel
                  containerComponent={MyPagerComponent}
                  messages={PAGING_PANEL_MESSAGES}
                  pageSizes={[0, 10, 50 ]}
                />
              </Grid>
            </Box>

            <Button
              disabled={loadingUpdateGood}
              onClick={onSave}
              sx={ styles.blueButton }
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Box>
      {showModalStatus && elModalStatus()}
      {showChangeGood && (
        <ChangeGoodProperties
          data={{
            quality: good?.shopGood?.quality || 'NONE',
            producedAt: good?.shopGood?.producedAt || null,
            serialNumber: good?.shopGood?.serialNumber || null
          }}
          id={parseInt(id, 10)}
          onChangeGood={() => {
            setDescriptionModalStatus(`Товар ${id} успешно обновлен`)
            setTitleModalStatus("Товар обновлен");
            toggleModalStatus("", true);
            fetchGood({ variables: { goodId: parseInt(id, 10) }});
          }}
          onChangeGoodError={() => {
            setDescriptionModalStatus("Ошибка обновления");
            setTitleModalStatus("Ошибка");
            toggleModalStatus("", true);
          }}
          onCloseEvent={toggleModalChangeGood("", false)}
          open={true}
        />
      )}
    </>
  );
}

DetailGood.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DetailGood;