import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import PendingIcon from '@material-ui/icons/Pending';

import { DocumentRecognizingStatusMap } from './constants';

const statusList = {
  [DocumentRecognizingStatusMap.pending]: {
    icon: <PendingIcon />,
    label: 'Ожидает распознавания',
  },
  [DocumentRecognizingStatusMap.recognizing]: {
    icon: <AccessTimeIcon />,
    label: 'Скоро распознается',
  },
  [DocumentRecognizingStatusMap.processFailed]: {
    icon: <HelpIcon />,
    label: 'Длительное распознавание',
  },
  [DocumentRecognizingStatusMap.recognitionFailed]: {
    icon: <HelpIcon />,
    label: 'Длительное распознавание',
  },
  [DocumentRecognizingStatusMap.succeed]: {
    icon: <CheckIcon />,
    label: 'Распознавание завершено',
  },
  default: {
    icon: <HelpIcon />,
    label: 'Нет данных',
  },
};

CellStatus.propTypes = {
  value: PropTypes.string,
};

export default function CellStatus({ value }) {
  const [mark] = useState(statusList[value] || statusList.default);

  return (
    <Typography color="#a9b1c0" fontWeight="bold">
      <Typography sx={{ display: { md: 'none', xs: 'block' } }}>
        {mark.icon}
      </Typography>
      <Typography sx={{ display: { md: 'block', xs: 'none' } }}>
        {mark.label}
      </Typography>
    </Typography>
  );
}