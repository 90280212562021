import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { incline } from 'lvovich';
import { useIntl } from "react-intl";

import ErrorAlert from 'components/ErrorAlert';

import FormSpec from './FormSpec';
import { GET_SUPPLYING_ORDER } from './graphql/queries/supplyingOrder';

PageSupplyingOrderItem.propTypes = {
  supplyingOrderId: PropTypes.string,
};

function buildFullNameGenitive({ firstName, lastName, middleName }) {
  const { first, last, middle } = incline(
    {
      first: firstName || '',
      middle: middleName || '',
      last: lastName || '',
    },
    'genitive',
  );

  return `${last} ${first} ${middle}`.trim();
}

export default function PageSupplyingOrderItem({ supplyingOrderId }) {
  const intl = useIntl();

  const { data, error, loading } = useQuery(GET_SUPPLYING_ORDER, {
    variables: { supplyingOrderId },
    errorPolicy: 'all',
  });

  if (error && !data) {
    return (
      <ErrorAlert
        message={error.message}
        title="Ошибка при выполнении запроса"
      />
    );
  }

  if (loading) {
    return 'Загрузка...';
  }

  const {
    supplyingOrder: {
      application: { borrowerEntity, id },
      invoiceDocumentUrl,
      invoiceDocumentCreatedAt,
      sellerBankData,
      sellerDiff,
      sellerDiffIgnored,
      supplier
    },
  } = data;

  const vendorInCharge = supplier.staff.find(
    ({ position }) => position === 'ceo'
  );

  const contact = vendorInCharge?.contact;
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const todayDate = today.toISOString();

  const defaultValues = {
    annexDate: todayDate,
    specDate: todayDate,
    vendorInChargePositionGenitive: vendorInCharge
      ? 'Генерального директора'
      : '',
    vendorInChargePosition: vendorInCharge ? 'Генеральный директор' : '',
    vendorInChargeFullName: contact ? buildFullNameGenitive(contact) : '',
    vendorInChargeShortName: vendorInCharge?.contact?.shortName,
    businessEntityShort: intl.formatMessage({ id: `shortOrganizationalForm.${supplier.organizationalForm?.toLowerCase()}`}),
    businessEntityFull: intl.formatMessage({ id: `fullOrganizationalForm.${supplier.organizationalForm?.toLowerCase()}`}),
    vendorNameFull: supplier.dadataStorage?.name.full,
    vendorNameShort: supplier.dadataStorage?.name.short,
    vendorINNnum: supplier.tin,
    leaseholderBusinessEntityShort: borrowerEntity.dadataStorage?.opf.short,
    leaseholderBusinessEntityFull: borrowerEntity.dadataStorage?.opf.full,
    leaseholderINNnum: borrowerEntity.tin,
    leaseholderOGRNnum: borrowerEntity.psrn,
    leaseholderNameFull: borrowerEntity.dadataStorage?.name.full,
    leaseholderNameShort: borrowerEntity.dadataStorage?.name.short,
    leaseholderLegalAddress: borrowerEntity.addresses
      .find(({ type }) => type === 'legal')
      ?.fullAddress,
    leaseholderFactAddress: borrowerEntity.addresses
      .find(({ type }) => type === 'actual')
      ?.fullAddress,
    paymentTerms:
      '- Внесение предоплаты в размере 80% от суммы выставленного Счета, включая НДС 20%;\n- 20% - по факту предоставления Поставщиком следующих документов: ТОРГ-12, счет-фактура, товарно-транспортная накладная, включая НДС 20%;',
  };

  return (
    <Box px={4}>
      <FormSpec
        applicationId={id}
        defaultValues={defaultValues}
        frameAgreements={supplier?.contracts || []}
        idForFrameAgres={supplier.id}
        invoiceDocumentUrl={invoiceDocumentUrl}
        invoiceDocumentCreatedAt={invoiceDocumentCreatedAt}
        sellerBankData={sellerBankData}
        sellerDiff={sellerDiff}
        supplyingOrderId={supplyingOrderId}
        sellerDiffIgnored={sellerDiffIgnored}
      />
    </Box>
  );
}
