import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from "@apollo/client";
import {Box, MenuItem, Select, Typography} from "@material-ui/core";
import { useLocation } from "@reach/router";
import { cloneDeep } from "lodash";
import qs from "querystringify";
import { useDebouncedCallback } from "use-debounce";

import HelpTooltip from 'components/HelpTooltip';

import { TABS_GENERAL } from "./constants";
import ContentGoods from './contents/ContentGoods';
import ContentLeasing from "./contents/ContentLeasing";
import FilterBlock from "./FilterBlock";
import { GET_COUNT_SHOP_MAIN_TABS } from './graphql/queries/getCountShopMainTabs';
import { ShopContext } from "./ShopContext";
import Search from "../../components/Search";
import Tabs from "../../components/Tabs";
import { SEARCH_DEBOUNCE } from "../../core/constants/searchDebounce";

export default function PageShop() {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)'
    },
    tab: {
      minWidth: 'auto !important',
      width: 'max-content !important',
      color: '#0D1D32',
      fontSize: '14px',
      fontWeight: 500,
      whiteSpace: 'nowrap'
    },
  }), []);
  const styles = useMemo(() => ({
    pageTitle: {
      fontSize: '32px !important',
      margin: '0 !important',
      fontWeight: 700,
    },
    wrapperButton: {
      margin: '24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      color: '#6E7884',
      background: '#FFFFFF',
      padding: '12px 24px 12px 12px',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      border: '1px solid #FFFFFF',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        background: '#FFFFFF',
        color: '#3D6CE7',
        boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',
      },
      '&:active': {
        background: '#FFFFFF',
        color: '#365CBF',
        boxShadow: '0px 6px 16px transparent',
        borderColor: '#E7E8EA',
      }
    },
    modalContentSelect: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,

      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        marginRight: "8px",
        background: 'transparent !important',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      }
    },
    dropDown: {
      background: '#FFFFFF',
      padding: '0',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      border: '1px solid #FFFFFF',
      overflow: 'hidden',

      '&:hover': {
        background: '#FFFFFF',
        boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',

        '& p': {
          color: '#3D6CE7 !important',
        },
      },
      '&:active': {
        background: '#FFFFFF',
        boxShadow: '0px 6px 16px transparent',
        borderColor: '#E7E8EA',
      },
      '& .MuiListItem-button': {
        padding: '12px 24px 12px 12px',
        height: '100%',

        '&:hover': {
         background: '#ffffff',
        },
        '& p': {
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          color: '#6E7884',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',

          '& svg': {
            marginRight: '8px',
          }
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    dropDownMenu: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      color: '#6E7884',
      background: '#FFFFFF',

      '& svg': {
        marginRight: '8px',
      }
    },
    wrapperContent: {
      margin: '24px',
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      overflow: 'hidden',
    }
  }), []);

  const { state: query, updateFilter } = useContext(ShopContext);

  const { data: countMainTabs } = useQuery(GET_COUNT_SHOP_MAIN_TABS);
  const [countTabs, setCountTabs] = useState({});

  useEffect(() => {
    if (countMainTabs) {
      setCountTabs({...countMainTabs.shopMainTabs});
    }
  }, [countMainTabs])

  const [activeTab, setActiveTab] = useState(0);

  const { search } = useLocation();
  const { shippedEquipmentIdEq, storageAcceptanceApplicationIdEq, storageAcceptanceIdEq } = qs.parse(search);

  useEffect(() => {
    const newQuery = cloneDeep(query);

    newQuery.page = 0;

    if ((storageAcceptanceApplicationIdEq || storageAcceptanceIdEq) && shippedEquipmentIdEq) {
      let filter = {
        shippedEquipmentIdEq: +shippedEquipmentIdEq,
      }

      if (storageAcceptanceApplicationIdEq) {
        filter = {
          ...filter,
          storageAcceptanceApplicationIdEq: +storageAcceptanceApplicationIdEq
        }
      }

      if (storageAcceptanceIdEq) {
        filter = {
          ...filter,
          storageAcceptanceIdEq: +storageAcceptanceIdEq
        }
      }

      newQuery.filter = filter;
    }

    updateFilter(newQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleTabs = activeTab => {
    setActiveTab(activeTab);

    const newQuery = cloneDeep(query);

    newQuery.page = 0;
    newQuery.filter = {};

    setTerm("")

    updateFilter(newQuery);
  }

  const [typeSearch, setTypeSearch] = useState('nameCont');

  const onChangeTypeSearch = event => {
    if (Object.keys(query?.filter).length) {
      const newQuery = cloneDeep(query);

      delete newQuery?.filter?.nameCont;
      delete newQuery?.filter?.loanLeasingAgreementCont;
      delete newQuery?.filter?.shopUidCont;
      delete newQuery?.filter?.storageAcceptanceIdEq;
      delete newQuery?.filter?.shippedEquipmentIdEq;
      delete newQuery?.filter?.storageAcceptanceApplicationIdEq;

      newQuery.page = 0;

      let value = term;

      if (event.target.value === "storageAcceptanceIdEq") {
        if (value?.trim() === "") {
          value = null
        } else {
          value = +value
        }
      }

      newQuery.filter[event.target.value] = value;

      updateFilter(newQuery);
    }

    setTypeSearch(event.target.value)
  }

  const [term, setTerm] = useState(null);

  const debounced = useDebouncedCallback(value => {
    const newQuery = cloneDeep(query);

    let valueForFilter = value;

    if (typeSearch === 'storageAcceptanceIdEq') {
      if (value?.trim() === "") {
        valueForFilter = null
      } else {
        valueForFilter = +value
      }
    }

    newQuery.filter[typeSearch] = valueForFilter;

    setTerm(value);
    newQuery.page = 0;

    updateFilter(newQuery);
  }, SEARCH_DEBOUNCE)

  return (
    <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto', }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center', padding: '0 24px', backgroundColor: '#ffffff', height: '100px', }}>
        <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
          Работа с объектами лизинга
        </Typography>

        <HelpTooltip title={(<a href={`${process.env.REACT_APP_HOST}/wiki_page/229`} rel="noreferrer" style={{color: '#fff', fontSize: '14px'}} target='_blank'>Перейти к описанию</a>)} />

        {!shippedEquipmentIdEq && (!storageAcceptanceIdEq || !storageAcceptanceApplicationIdEq) && (
          <Box sx={{ width: 'calc( 100% - 500px)' }}>
            {activeTab === 0 && (
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Select
                    fullWidth
                    onChange={onChangeTypeSearch}
                    sx={{
                      ...styles.modalContentSelect,
                      borderRadius: '8px 0 0 8px',
                      height: '48px',
                    }}
                    value={typeSearch}
                  >
                    <MenuItem sx={styles.selectItem} value={"nameCont"}>По названию</MenuItem>
                    <MenuItem sx={styles.selectItem} value={"loanLeasingAgreementCont"}>По договору лизинга</MenuItem>
                    <MenuItem sx={styles.selectItem} value={"shopUidCont"}>По пользовательскому идентификатору</MenuItem>
                    <MenuItem sx={styles.selectItem} value={"storageAcceptanceIdEq"}>По ID приёмки</MenuItem>
                  </Select>
                </Box>

                <Search
                  onChange={debounced.callback}
                  sx={{
                    height: '48px',
                    margin: 0,
                    width: '100%',
                    borderRadius: '0 8px 8px 0',
                    borderLeft: 'none'
                  }}
                  value={term}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Tabs
        counterTabs={[
          countTabs['shopGoods'],
          countTabs['loanSupplies'],
        ]}
        defaultActiveTab={0}
        disabledTabs={['LOAN_SUPPLIES']}
        langPath={"shop.tabs."}
        onChange={toggleTabs}
        sx={stylesTabs}
        tabs={TABS_GENERAL}
      />

      {!shippedEquipmentIdEq && (!storageAcceptanceIdEq || !storageAcceptanceApplicationIdEq) && (
        <FilterBlock />
      )}

      <Box sx={ styles.wrapperContent }>
        {activeTab === 0 && <ContentGoods />}
        {activeTab === 1 && <ContentLeasing/>}
      </Box>
    </Box>
  );
}