import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  TASKS_FILTER_STATE_FIELDS,
  filterStateRenderers,
} from 'modules/tasks/constants';

export const FilterStateField = ({ fieldName, value }) => {
  const intl = useIntl();

  return (
    <Box>
      {TASKS_FILTER_STATE_FIELDS[fieldName]}{' '}
      <Box component="strong">
        {filterStateRenderers(fieldName, value, { intl }) || '—'}
      </Box>
    </Box>
  );
};

FilterStateField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
};