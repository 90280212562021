import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ClickAwayListener, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

HelpTooltip.propTypes = {
  title: PropTypes.node,
};

export default function HelpTooltip({ title }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={open}
        sx={{
          color: 'rgba(0,0,0,.3)',
          verticalAlign: 'bottom',
          display: 'inline-block',
          fontSize: 17,
          lineHeight: '17px',
          alignItems: 'baseline',
        }}
        title={title}
      >
        <HelpIcon
          color="inherit"
          fontSize="inherit"
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
}
