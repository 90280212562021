import PropTypes from "prop-types";
import React, {useContext, useMemo, useState} from 'react';

import {Box, Typography} from "@material-ui/core";
import { cloneDeep } from "lodash";
import {FormattedMessage} from "react-intl";

import {CollaboratorsContext} from "./CollaboratorsContext";
import {TABS} from "./constants";

CollaboratorsTabs.propTypes = {
  infTabs: PropTypes.object,
};

export default function CollaboratorsTabs({ infTabs }) {
  const { state, updateFilter } = useContext(CollaboratorsContext);

  const [activeTab, setActiveTab] = useState(state.filter.tab);

  const styles = useMemo(() => ({
    wrapperTabs: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '9px 24px',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #E7E8EA',
      textAlign: 'center',
      fontSize: '14px',
      boxShadow: '0 9px 13px rgb(13 29 50 / 12%)',
    },
    tabs: {
      padding: '11px 15px',
      cursor: 'pointer',
      position: 'relative',

      '&.activeTab': {
        cursor: 'default',
      },
      '&.activeTab::after': {
        content: "''",
        backgroundColor: '#3D6CE7',
        display: 'block',
        height: '4px',
        width: '100%',
        position: 'absolute',
        bottom: '-10px',
        left: '0',
      },
      '&.activeTab .MuiTypography-root': {
        color: '#3D6CE7',
      },
    },
    tabsTitle: {
      width: '100%',
      color: '#000000',
    },
  }), [])

  const onChange = (isActive, element) => {
    if (isActive) return false;

    const newState = cloneDeep(state);
    newState.filter.tab = element;
    newState.filter.page = 1;
    updateFilter(newState)

    setActiveTab(element);
  };

  const renderTabs = (element, index, isActive) => {
    return (
      <Box className={isActive === true ? 'activeTab' : null} key={index} onClick={onChange.bind(this, isActive, element)} sx={styles.tabs}>
        <Typography sx={styles.tabsTitle}>
          <FormattedMessage id={`collaborators.tab.${element}`} />
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={styles.wrapperTabs}>
      {TABS.map((element, index) => (
        renderTabs(element, index, element === activeTab)
      ))}
    </Box>
  )
}
