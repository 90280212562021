import { gql } from '@apollo/client';

export const GET_CONTACT_ENTITY_QUESTIONNAIRE_DATA = gql`
  query GetContactEntityQuestionnaireData(
    $contactEntityQuestionnaireId: ID!,
    $version: Int
  ) {
    contactEntityQuestionnaire(
      contactEntityQuestionnaireId: $contactEntityQuestionnaireId,
      version: $version
    ) {
      # createdAt FIXME: Cannot return null
      # for non-nullable field ContactEntityQuestionnaire.createdAt 
      data
      id
      state
      versions {
        createdAt
        version
      }
    }
  }
`;