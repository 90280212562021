import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Box, List, ListItem, ListItemText, Typography, Link } from '@material-ui/core';
import { Link as RouterLink, useLocation } from '@reach/router';
import _sortBy from 'lodash.sortby';
import qs from 'querystringify';
import { FormattedMessage } from 'react-intl';

import CurrencyFormatter from 'components/CurrencyFormatter';
import ErrorAlert from 'components/ErrorAlert';
import HelpTooltip from 'components/HelpTooltip';
import LabelButton from 'components/LabelButton';

import CategoriesPieChart from './CategoriesPieChart';
import CompanyCategorySelector from './CompanyCategorySelector';

WidgetTopCounterparties.propTypes = {
  disabledEdit: PropTypes.bool,
  operationsType: PropTypes.oneOf(['income', 'outcome']),
  topCompanies: PropTypes.array,
};

export default function WidgetTopCounterparties({ disabledEdit, operationsType, topCompanies }) {
  const location = useLocation();

  const categories = useMemo(
    () =>
      Object.entries(
        _sortBy(topCompanies, 'contractor.category').reduce(
          (prev, curr) => ({
            ...prev,
            [curr.contractor.category]: (prev[curr.contractor.category] || 0) + curr.ordersSum,
          }),
          {},
        ),
      ).map(([category, ordersSum]) => ({ name: category, categoryAmount: ordersSum })),
    [topCompanies],
  );

  const companies = useMemo(
    () =>
      _sortBy(topCompanies, 'contractor.category').map((c) => ({
        name: c.contractor.title,
        companyAmount: c.ordersSum,
      })),
    [topCompanies],
  );

  return (
    <>
      <Box>
        <Typography color="textPrimary" variant="h4">
          <FormattedMessage id={`bankStatement.${operationsType}`} />
          &nbsp;
          <HelpTooltip
            title={<FormattedMessage id={`bankStatement.${operationsType}.tooltip`} />}
          />
        </Typography>
      </Box>

      {topCompanies ? (
        <>
          <Box height={260}>
            <CategoriesPieChart categories={categories} companies={companies} />
          </Box>

          <Box display="flex" flexWrap="wrap" mt={2} px={3}>
            {_sortBy(categories, 'categoryAmount')
              .reverse()
              .map(({ categoryAmount, name }, index) => {
                const parsedQuery = qs.parse(location.search);

                const params = {
                  ...parsedQuery,
                  operation: operationsType,
                }

                switch (operationsType) {
                  case 'income':
                    params['payerCategory'] = name;
                    break;
                  case 'outcome':
                    params['recipientCategory'] = name;
                    break;
                  default:
                    console.info("Category empty.");
                }

                const to = qs.stringify( params, true );

                return (
                  <LabelButton
                    bgcolor={`categories.${name}`}
                    component={RouterLink}
                    key={index}
                    to={to}
                  >
                    <FormattedMessage id={`categories.${name}`} />
                    &nbsp;&nbsp;
                    <CurrencyFormatter value={categoryAmount} />
                  </LabelButton>
                );
              })}
          </Box>

          <List>
            {topCompanies.map((c, index) => {
              const parsedQuery = qs.parse(location.search);

              const to = qs.stringify(
                {
                  ...parsedQuery,
                  ...(operationsType === 'income' && { payerId: c.contractor.id }),
                  ...(operationsType === 'outcome' && { recipientId: c.contractor.id }),
                  operation: operationsType,
                },
                true,
              );

              return (
                c.contractor.id !== "0" && (
                  <ListItem dense key={index}>
                    <CompanyCategorySelector
                      contractorId={c.contractor.id}
                      defaultCategory={c.contractor.category}
                      disabledEdit={disabledEdit}
                    />

                    <ListItemText
                      primary={c.contractor.title}
                      primaryTypographyProps={{ noWrap: true, variant: 'body2' }}
                      secondary={
                        <Link component={RouterLink} fontSize={15} to={to}>
                          <Box color="inherit" component="span" fontWeight={500}>
                            {c.ordersCount}
                          </Box>
                          &nbsp;
                          <FormattedMessage
                            id={`bankStatement.orders.plural`}
                            values={{ amount: c.ordersCount }}
                          />
                        </Link>
                      }
                    />
                    <Box flexShrink={0} ml={5}>
                      <Typography variant="h5">
                        <CurrencyFormatter value={c.ordersSum} />
                      </Typography>
                    </Box>
                  </ListItem>
                )
              );
            })}
          </List>
        </>
      ) : (
        <Box my={3}>
          <ErrorAlert title="Ошибка при распознавании выписки" />
        </Box>
      )}
    </>
  );
}
