import { gql } from '@apollo/client/core';

export const GET_SOURCE_DOCUMENT = gql`
  query GetSourceDocument($documentId: ID!) {
    sourceDocument(id: $documentId) {
      id
      availableLoansForAssign {
        id
        displayLeaseAgreement
      }
      isOriginalReceived
      recognizingProcess {
        status
        ocrData {
          date
          documentNumber
          seller {
            id
            legalNameWithShortEntityType
            tin
          }
          totalAmount
        }
      }
      documentUrl
      form
      type {
        category
      }
    }
  }
`;