import React, { useCallback, useContext, useState } from 'react';

import {
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Router } from '@reach/router';

import BreadcrumbsNav from 'components/BreadcrumbsNav';
import { APPLICATIONS_PATH } from 'core/paths';
import Sidebar from 'layout/Sidebar';
import Collaborators from 'modules/collaborators';
import ContactsRoutes from 'modules/contacts';
import DealsRoutes from 'modules/deals';
import FmsPassportCheckRoutes from 'modules/fmsPassportCheck';
import FsspRoutes from 'modules/fssp';
import LegalEntitiesRoutes from 'modules/legalEntities';
import Orders from 'modules/orders';
import PaymentScheduleRoutes from 'modules/paymentSchedule';
import SupplyingOrdersRoutes from 'modules/supplyingOrders';
import TasksRoutes from 'modules/tasks';
import { Rosfinmonitoring } from 'modules/verification';
import WorkspacesRoutes from 'modules/workspaces';
import inIframe from 'utils/inIframe';

import { AppContext } from './AppContext';
import './App.css';
import WazzupRoutes from "../modules/wazzup";

function HomePage() {
  return '';
}

function NotFound() {
  return 'Not Found';
}

const styles = {
  ToolbarHeader: {
    gridArea: 'header',
    display: {
      xs: 'grid',
      lg: 'flex'
    },
    gridTemplateColumns: {
      xs: '1fr auto',
      lg: 'none'
    },
    flexDirection: {
      xs: 'row-reverse',
      lg: 'row'
    }
  },
  ToolbarButton: {
    order: {
      xs: -1,
      lg: 1
    },
    ml: {
      xs: 0,
      lg: 'auto'
    },
    mr: {
      xs: 'auto',
      lg: 0
    }
  },
  BreadcrumbsContainer: {
    maxWidth: '100%',
    overflow: {
      xs: 'scroll',
      lg: 'auto'
    },

    '& ol': {
      flexWrap: 'nowrap'
    },

    '& p, & span': {
      whiteSpace: 'nowrap'
    }
  }
}

export default function App() {
  const { appData, error, loading } = useContext(AppContext);

  const [open, setOpen] = useState(true);
  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
          "sidebar header header"
          "sidebar main main"
        `,
          height: '100%',
          gridTemplateColumns: 'auto 1fr',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        {!inIframe() ? (
          <Sidebar
            currentUser={appData.currentUser}
            error={error ? error.message : null}
            loading={loading}
            menuItems={appData.sidebarElements}
            onClose={handleDrawerClose}
            onOpen={handleDrawerOpen}
            open={open}
          />
        ) : null}
        {!inIframe() ? (
          <Toolbar sx={ styles.ToolbarHeader }>
            <Box component={Router} sx={ styles.BreadcrumbsContainer }>
              <BreadcrumbsNav default />
            </Box>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={ styles.ToolbarButton }
            >
              <Hidden lgUp>
                <IconButton onClick={handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Box>
          </Toolbar>
        ) : null}
        <Box component={Router} sx={{ gridArea: 'main', overflow: 'auto' }}>
          <HomePage path="/" />
          <TasksRoutes path="/tasks/*" />
          <WazzupRoutes path="/wazzup/*" />
          <DealsRoutes path="/deals/*" />
          <PaymentScheduleRoutes path={`${APPLICATIONS_PATH}/*`} />
          <Rosfinmonitoring path="/contacts/:contactId/rosfinmonitoring" />
          <FsspRoutes path="/contacts/:contactId/fssp" />
          <FmsPassportCheckRoutes path="/contacts/:contactId/fms-passport-check" />
          <ContactsRoutes path="/contacts/*" />
          <LegalEntitiesRoutes path="/entities/*" />
          <SupplyingOrdersRoutes path="/supplying_orders/*" />
          <WorkspacesRoutes path="/workspaces/*" />
          <Orders path="/orders" />
          <Collaborators path="/collaborators/*" />
          <NotFound default />
        </Box>
      </Box>
    </>
  );
}
