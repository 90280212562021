export const getTaskStyles = (boardColor) => ({
  task: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    fontSize: 14,
    position: 'relative',
    borderRadius: '16px',
    boxShadow: '0px 2px 11px rgba(13, 29, 50, 0.1)',
    padding: '16px',
    border: '1px solid #E7E8EA',

    '&:before': {
      content: '""',
      width: '2px',
      height: '80%',
      background: boardColor,
      position: 'absolute',
      left: 0,
      top: '10%',
    }
  },
  updateProgressBox: {
    backgroundColor: '#fafafa',
    position: 'absolute',
    inset: 0,
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  taskHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  taskNumber: {
    fontWeight: 400,
    color: boardColor,
    backgroundColor: `${boardColor}33`,
    borderRadius: '4px',
    fontSize: '12px',

    '&:hover': {
      backgroundColor: boardColor,
      color: '#ffffff',
      opacity: 0.9,
    },
  },
  taskDueAt: {
    borderRadius: '4px',
    fontWeight: 400,
    color: '#B6BBC2',
    background: '#F5F6F7',
    fontSize: '12px',
  },
  taskFooter: {
    width: 'calc(100% + 16px)',
    ml: -2,
    mb: -2,
    fontSize: 13,
    p: 2,
  },
  markStatus: {
    fontSize: '12px',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '4px',
    width: 'auto',
    fontWeight: 400,
    marginRight: '4px',
  },
  wrapperLink: {
    fontSize: '12px',
    marginBottom: '6px',

    '& span': {
      fontWeight: 600,
      color: '#0D1D32',
    },
    '& a': {
      fontWeight: 400,
      color: '#3D6CE7'
    }
  }
});