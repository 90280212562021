import { gql } from '@apollo/client/core';

export const DELETE_DRAFT_SCHEDULE = gql`
  mutation FinanceDeleteDraftSchedule($scheduleId: ID!) {
    financeDeleteDraftSchedule(scheduleId: $scheduleId) {
      schedule {
        id
      }
    }
  }
`;