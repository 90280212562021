import PropTypes from 'prop-types';
import React from 'react';

import GridOrders from './GridOrders';

TabOrders.propTypes = {
  bankStatementId: PropTypes.any,
  disabledEdit: PropTypes.bool,
};

export default function TabOrders({ bankStatementId, disabledEdit }) {
  return <GridOrders bankStatementId={bankStatementId} disabledEdit={disabledEdit} />;
}
