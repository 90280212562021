import { gql } from '@apollo/client/core';

export const GET_SHOP_STORAGES = gql`
  query getShopStorages {
    shopStorages {
      collection {
        address
        addressForSite
        icon {
          url
          filename
        }
        id
        name 
      }
    }
  }
`;