export const getTaskDetailsStyles = (boardColor, priorityColor) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  priority: {
    backgroundColor: priorityColor,
    borderRadius: '4px',
    fontWeight: 500,
    color: 'white',
  },
  table: {
    mt: 4,
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  tableKey: {
    color: '#91919c',
    fontWeight: 500,
    pr: 2,
  },
  tableValue: {
    textAlign: 'right',
  },
  buttonComplete: {
    borderColor: '#81c784',
    color: '#81c784',

    '&:hover': {
      borderColor: '#60c564',
      backgroundColor: 'rgba(94, 197, 97, 0.04)',
    },
  },
  markStatus: {
    fontSize: '12px',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '4px',
    width: 'auto',
    fontWeight: 400,
    marginRight: '4px',
    lineHeight: '1.1',
  },
});