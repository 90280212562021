/* eslint-disable react/prop-types */
import React from 'react';

import { Box } from '@material-ui/core';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { pdfjs } from 'react-pdf';

import { PDFRendererControls } from './components/PDFRendererControls';
import { PDFRendererPages } from './components/PDFRendererPages';
import { PDFRendererProvider } from './state';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PDFRenderer = ({ mainState }) => (
  <PDFRendererProvider mainState={mainState}>
    <Container>
      <PDFRendererControls />
      <PDFRendererPages />
    </Container>
  </PDFRendererProvider>
);

PDFRenderer.fileTypes = ['pdf', 'application/pdf'];
PDFRenderer.weight = 1;

const Container = ({ children, ...rest }) => (
  <Box
    {...rest}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto',

      '&::-webkit-scrollbar': {
        width: 10,
        height: 10,
      },

      '&::-webkit-scrollbar-track': {
        background: 'none',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#e0e0e0',

        '&:hover': {
          background: '#d0d0d0',
        },
      },
    }}
  >
    {children}
  </Box>
);