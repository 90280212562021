import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LogoutIcon from '@material-ui/icons/Logout';
import { navigate } from "@reach/router";

import AvatarUser from "../../../components/AvatarUser";
import { AUTH_TOKEN_STORAGE_KEY } from "../../../core/constants/storageKeys";
import { localStore } from "../../../utils/storageFactory";
import { CurrentUserProps } from '../types';

Profile.propTypes = {
  data: CurrentUserProps,
  loading: PropTypes.bool,
};

export default function Profile({ data, loading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const shown = Boolean(anchorEl);

  const handleMenuShow = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  const indicatorColorsMap = useMemo(() => ({
    active: '#238d35',
    inactive: '#88949c',
    offline: '#9c393d',
  }), []);

  const styles = useMemo(() => ({
    box: {
      height: 80,
      backgroundColor: '#1b2835',
      px: 3,
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      position: 'sticky',
      top: 0,
      cursor: 'pointer',
      zIndex: 1,
    },
    userData: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 40,
      lineHeight: '16px',
    },
    userName: {
      fontSize: 14,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      color: 'white',

      '& svg': {
        alignSelf: 'flex-start',
      }
    },
    userStatus: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      color: 'white',
    },
    userStatusIndicator: {
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
    menuPaper: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
      mt: 8,
      ml: 5,

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }), []);

  const logOut = () => {
    localStore.removeItem(AUTH_TOKEN_STORAGE_KEY);
    navigate(`${process.env.REACT_APP_HOST}/auth/sign_out?logout=true`);
  }

  if (loading) {
    return (
      <Box sx={{ width: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box onClick={handleMenuShow} sx={styles.box}>
        <AvatarUser avatar={data?.avatar} firstName={data?.contact.firstName} lastName={data?.contact.lastName} />
        <Box sx={styles.avatar}>
        </Box>
        <Box sx={styles.userData}>
          <Box sx={styles.userName}>
            {data?.contact.lastName} {data?.contact.firstName}
            {shown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          <Box sx={styles.userStatus}>
            <Box
              backgroundColor={indicatorColorsMap.active}
              component="span"
              sx={styles.userStatusIndicator}
            />
            Активен
          </Box>
        </Box>
      </Box>
      <Menu
        PaperProps={{ elevation: 0, sx: styles.menuPaper }}
        anchorEl={anchorEl}
        autoFocus={false}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
        open={shown}
      >
        <MenuItem
          onClick={logOut}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}