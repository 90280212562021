import React, { useMemo, useState } from 'react';

import { Link, Menu, MenuItem } from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import _identity from 'lodash.identity';
import _pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { FormattedMessage } from 'react-intl';

export default function MessageTypesFilter() {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );
  const [messagesStateFilter, setMessagesStateFilter] = useState(
    parsedQuery.state || 'CREATED',
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value) => {
    setMessagesStateFilter(value);
    const query = _pickBy({ ...parsedQuery, state: value }, _identity);
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        component="button"
        onClick={handleClickListItem}
        sx={{
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px dashed',
          ml: 3,
        }}
        underline="none"
        variant="body1"
      >
        <FormattedMessage
          id={`fedresurs.messagesStatus.${messagesStateFilter}`}
        />
      </Link>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem
          onClick={(event) => handleMenuItemClick(event, 'SENT')}
          value="SENT"
        >
          <FormattedMessage id={`fedresurs.messagesStatus.SENT`} />
        </MenuItem>
        <MenuItem
          onClick={(event) => handleMenuItemClick(event, 'CREATED')}
          value="CREATED"
        >
          <FormattedMessage id={`fedresurs.messagesStatus.CREATED`} />
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, '')} value="">
          <FormattedMessage id={`fedresurs.messagesStatus.`} />
        </MenuItem>
      </Menu>
    </>
  );
}
