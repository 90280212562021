import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';

import { FINMONITORING_FILTER_STATE_FIELDS, finmonitoringFilterStateRenderers } from './constants';

export const FinmonitoringFilterStateField = ({ fieldName, value }) => {
  return (
    <Box>
      {FINMONITORING_FILTER_STATE_FIELDS[fieldName]}{' '}
      <Box component="strong">{finmonitoringFilterStateRenderers(fieldName, value) || '—'}</Box>
    </Box>
  );
};

FinmonitoringFilterStateField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
};
