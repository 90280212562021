import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const FilterModalBox = ({ children, subtitle, title }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="subtitle1">
        {title}{' '}
        {subtitle && (
          <Typography color={grey[500]} variant="overline">
            ({subtitle})
          </Typography>
        )}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

FilterModalBox.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};