import { gql } from '@apollo/client/core';

import { INVOICE_FRAGMENT } from "../fragments";

export const INVOICES_CREATE = gql`
  ${INVOICE_FRAGMENT}
  mutation InvoicesCreate (
    $params: InvoiceCreateParams!
    $targetId: ID!
    $targetType: InvoiceTargetType!
  ) {
    invoicesCreate(
      params: $params
      targetId: $targetId
      targetType: $targetType
    ) {
      invoice {
        ...InvoiceFragment
      }
    }
  }
`;
