import PropTypes from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';

import { DEALS_PAGE_LIMIT } from "./constants";

export const DEFAULT_QUERY = {
  page: 0,
  limit: DEALS_PAGE_LIMIT,
  sort: {
    field: 'PRICE',
    order: 'ASC',
  },
  filter: {},
}
export const ShopContext = createContext({
  state: DEFAULT_QUERY,
  updateFilter: () => {},
});

export const ShopProvider = ({ children }) => {
  const [state, setState] = useState(DEFAULT_QUERY);

  const updateFilter = useCallback(
    (filters) => {
      setState(filters)
    }, []
  )

  return (
    <ShopContext.Provider value={{ state, updateFilter }}>
      {children}
    </ShopContext.Provider>
  );
};

ShopProvider.propTypes = {
  children: PropTypes.node,
};
