import { gql } from '@apollo/client/core';

import { SCHEDULE_FRAGMENT, SCHEDULE_ITEM_FRAGMENT } from '../fragments';

export const GET_APPLICATION = gql`
  query GetApplication($applicationId: ID!) {
    application(applicationId: $applicationId) {
      id
      loan {
        id
        schedules {
          ...ScheduleFragment
          items {
            ...ScheduleItemFragment
          }
        }
      }
    }
  }
  ${SCHEDULE_ITEM_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
`;