import React from 'react';

import { Box } from '@material-ui/core';

export default function CompanyCategoryMarker(props) {
  return <Box {...props} />;
}

CompanyCategoryMarker.defaultProps = {
  borderRadius: '50%',
  flexShrink: 0,
  height: 16,
  mr: 3,
  width: 16,
};
