import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect } from 'react';

import { TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';

import { DocumentStatusMap } from '../constants';

// eslint-disable-next-line react/display-name
const NumberFormatInput = forwardRef(({ name, onChange, ...rest }, ref) => (
  <NumberFormat
    {...rest}
    decimalScale={2}
    decimalSeparator=","
    fixedDecimalScale={2}
    getInputRef={ref}
    isNumericString
    onValueChange={({ value }) => {
      onChange({ target: { name, value } });
    }}
    suffix=" руб"
    thousandSeparator=" "
  />
));

NumberFormatInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export const AssignAmountField = ({ amount, documentStatus, onChange, required, title }) => {
  const handleChange = useCallback((e) => {
    if (onChange instanceof Function) {
      onChange(e?.target?.value || null);
    }
  }, [onChange]);

  useEffect(() => {
    if (onChange instanceof Function) {
      onChange(amount ? amount.toString() : null);
    }
  }, [amount, onChange]);

  if (documentStatus === DocumentStatusMap.pending) { // recognizing
    return (
      <TextField
        InputProps={{ inputComponent: NumberFormatInput }}
        defaultValue={amount ? amount : ''}
        label={title}
        onChange={handleChange}
        required={required}
        variant="outlined"
      />
    );
  }

  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatInput,
        sx: { bgcolor: grey[50] },
      }}
      defaultValue={amount ? amount : '—'}
      disabled
      label={title}
      variant="outlined"
    />
  );
};

AssignAmountField.propTypes = {
  amount: PropTypes.number,
  documentStatus: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
};

AssignAmountField.defaultProps = {
  title: "Сумма документа",
}