import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Skeleton, Typography } from '@material-ui/core';

import { GET_ENTITIES } from './graphql/queries/entities';
import TableLegalEntities from './TableLegalEntities';

export default function PageLegalEntities(props) {
  const { data, error, loading } = useQuery(GET_ENTITIES, {
    errorPolicy: 'all',
  });

  if (error) {
    return error.message;
  }

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Box>
      <Typography variant="h1">Организации и ИП</Typography>
      <TableLegalEntities data={data.entities.collection} />
    </Box>
  );
}
