import React, {useEffect, useMemo, useState} from 'react';

import { useMutation, useQuery } from "@apollo/client";
import {DataTypeProvider, RowDetailState} from "@devexpress/dx-react-grid";
import { Grid, TableHeaderRow, VirtualTable } from "@devexpress/dx-react-grid-material-ui";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, SvgIcon, Typography, Link } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "@reach/router";
import dayjs from "dayjs";
import { ReactSpoiler } from "react-simple-spoiler";

import {
  COLUMN_EXTENSIONS_STRUCTURE_SEIZURE_ACT,
  COLUMNS_MODEL_CREATOR_SEIZURE_ACT
} from "./constants";
import { SHOP_STORAGE_ACCEPTANCE_CHANGE_STATUS } from "./graphql/mutations/shopStorageAcceptanceChangeStatus";
import { GET_STORAGE_ACCEPTANCE } from "./graphql/queries/storageAcceptance";
import { ReactComponent as IconInf } from './img/inf.svg';
import LinkForSeizureAct from "./tableProviderer/linkForSeizureAct"
import ErrorAlert from "../../components/ErrorAlert";
import WrapperButton from "../../components/WrapperButton";
import { DATE_TIME_FORMAT_VIEW } from "../../core/constants/Formats";
import { GridRoot } from "../deals/components/helpers";
import { Loader } from "../workspaces/components/helpers";

export const DocumentActionTypeProviderLink = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <LinkForSeizureAct {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export default function PageSeizureAct() {
  const styles = useMemo(() => ({
    wrapperPageTitle: {
      display: 'flex',
      gap: '16px',
      alignItems: {
        xs: 'flex-start',
        lg: 'center'
      },
      padding: {
        xs: '16px',
        lg: '0 24px'
      },
      backgroundColor: '#ffffff',
      height: {
        xs: 'auto',
        lg: '100px'
      },
      flexDirection: {
        xs: 'column',
        lg: 'row'
      }
    },
    pageTitle: {
      fontSize: {
        xs: '24px !important',
        lg: '32px !important'
      },
      margin: '0 !important',
      // width: '460px',
      fontWeight: 700,
      display: {
        xs: 'flex',
        lg: 'block'
      },
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },

      "& span": {
        fontSize: "inherit",
        fontWeight: "inherit",
        color: "#3D6CE7",
        textDecoration: "underline",
        wordBreak: {
          xs: 'break-word',
          lg: 'normal'
        }
      }
    },
    pageTitleDate: {
      background: "#F5F6F7",
      color: "#6E7884",
      borderRadius: "4px",
      padding: "8px",
      fontSize: "16px",
      fontWeight: 300,
      textAlign: {
        xs: 'center',
        lg: 'left'
      },
      width: {
        xs: '100%',
        lg: 'inherit'
      }
    },
    wrapperContent: {
      margin: {
        xs: '16px',
        lg: '24px'
      },
      // height: 'calc(100% - 120px)',
      overflow: 'hidden',
    },
    title: {
      fontSize: {
        xs: '16px',
        lg: '24px'
      },
      fontWeight: 700,
      display: "flex",
      alignItems: {
        xs: 'flexStart',
        lg: 'center'
      },
      gap: "6px",
      flexDirection: {
        xs: 'column',
        lg: 'row'
      }
    },
    titleElement: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',

      "& a": {
        color: "#3D6CE7"
      }
    },
    wrapperTable: {
      background: '#ffffff',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      overflow: 'hidden',
      marginBottom: {
        xs: '8px',
        lg: '60px'
      },
      marginTop: {
        xs: '16px',
        lg: '36px'
      },

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        '& span': {
          color: '#6E7884',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    wrapperButtons: {
      margin: "0 24px",
      display: 'flex',
      alignItems: 'center',
      gap: '20px',

      flexDirection: {
        xs: 'column',
        lg: 'row'
      },

      '& button': {
        width: {
          xs: '100%',
          lg: 'auto'
        } 
      }
    },
  }), []);

  const { storageAcceptanceId } = useParams();

  const [storageAcceptance, setStorageAcceptance] = useState(null);

  const { error, loading } = useQuery(GET_STORAGE_ACCEPTANCE, {
    variables: {
      storageAcceptanceId,
    },
    onCompleted: res => {
      setStorageAcceptance(res?.storageAcceptance || null);

      if (res?.storageAcceptance?.status === "READY_FOR_ACCEPTANCE") {
        changeStatusAcceptance({
          variables: {
            storageAcceptanceId,
            transition: "START_ACCEPTANCE",
          }
        })
      }
    }
  });

  const [showModal, setShowModal] = useState(false );

  const [titleModal, setTitleModal] = useState('');
  const [descriptionModal, setDescriptionModal] = useState('');

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setTitleModal("");
    setDescriptionModal("");
  }

  const [changeStatusAcceptance, {
    data: dataChangeStatusAcceptance,
    loading: loadingChangeStatusAcceptance,
  }] = useMutation(SHOP_STORAGE_ACCEPTANCE_CHANGE_STATUS, {
    onError: error => {
      setTitleModal("Произошла ошибка");
      setDescriptionModal(`Произошла ошибка. Попробуйте позже. ${error.message}`);
      setShowModal(true);
    }
  });

  useEffect(() => {
    if (storageAcceptance?.status === "ACCEPTANCE_STARTED" && dataChangeStatusAcceptance?.shopStorageAcceptancesChangeStatus?.storageAcceptance?.id) {
      setTitleModal("Акт изъятия сохранен.");
      setDescriptionModal(`Акт изъятия успешно сохранен.`);
      setShowModal(true);
    }
  }, [storageAcceptance, dataChangeStatusAcceptance])

  const onSaveAndComplete = () => {
    changeStatusAcceptance({
      variables: {
        storageAcceptanceId,
        transition: "ACCEPT",
      }
    })
  }

  const renderTable = (supplie) => {
    return (
      <>
        <Box sx={styles.title}>
          <Box sx={styles.titleElement}>
            Заказ <Link
            href={`${process.env.REACT_APP_HOST}/applications/${supplie?.supplyingOrder?.application?.id}/supplying_orders/${supplie?.supplyingOrder?.id}`}
            target={"_blank"}
          >
            {supplie?.supplyingOrder?.number}
          </Link>

            <Typography component={"span"} sx={{color: "#b7bbc1"}}>•</Typography>
          </Box>
          <Box sx={styles.titleElement}>
            Поставщик <Link
            href={`${process.env.REACT_APP_HOST}/entities/${supplie?.supplyingOrder?.supplier?.id}`}
            target={"_blank"}
          >
            {supplie?.supplyingOrder?.supplier?.shortLegalName || "Поставщик отсутствует"}
          </Link>
          </Box>
        </Box>

        <Box sx={styles.wrapperTable}>
          <Grid
            columns={COLUMNS_MODEL_CREATOR_SEIZURE_ACT}
            getRowId={(row) => row.id}
            rootComponent={GridRoot}
            rows={supplie?.shippedEquipments || []}
          >
            <RowDetailState/>

            <DocumentActionTypeProviderLink
              for={['actions']}
              statusAcceptance={storageAcceptance?.status}
              storageAcceptanceApplicationIdEq={storageAcceptance?.application?.id}
              storageAcceptanceId={storageAcceptanceId}
            />

            <VirtualTable
              cellComponent={(props) => (
                <VirtualTable.Cell className="OrdersTableCell" {...props} />
              )}
              columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SEIZURE_ACT}
              messages={{noData: 'Нет данных'}}
            />
            <TableHeaderRow/>
          </Grid>
        </Box>
      </>
    )
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <>
      <Box sx={{ paddingBottom: '20px', height: '100%', overflow: 'auto' }}>
        <Box sx={ styles.wrapperPageTitle }>
          <Typography gutterBottom sx={ styles.pageTitle } variant="h2">
            Акт изъятия: <Typography component={"span"}>{storageAcceptance?.seizureAct?.number || "Акт изъятия отсутствует"}</Typography>
          </Typography>

          <Box sx={ styles.pageTitleDate }>
            Дата изъятия: <Typography component={"span"} sx={{ fontWeight: 600, color: "#0D1D32" }}>
            {storageAcceptance?.acceptedAt ? (
              dayjs(storageAcceptance?.acceptedAt).utc(true).startOf("day").format(DATE_TIME_FORMAT_VIEW)
            ) : (
              "Дата изъятия отсутствует"
            )}
          </Typography>
          </Box>
        </Box>

        {storageAcceptance?.status === "ACCEPTANCE_STARTED" && (
          <>
            {storageAcceptance?.seizureAct?.supplies?.length ? (
              storageAcceptance.seizureAct.supplies.map(supplie => (
                <Box key={supplie.id} sx={styles.wrapperContent}>
                  {renderTable(supplie)}
                </Box>
              ))
            ) : (
              <Box sx={styles.wrapperContent}>
                Заказы отсутствуют
              </Box>
            )}

            <Box sx={styles.wrapperButtons}>
              <WrapperButton colorScheme={"green"} disabled={loadingChangeStatusAcceptance || storageAcceptance?.status === "ACCEPTED"} onClick={onSaveAndComplete}>
                Завершить приемку
              </WrapperButton>

              <Box sx={{display: "flex", gap: "6px"}}>
                <SvgIcon component={IconInf} sx={{"& path": {fill: "#67BF3D"}}}/>
                В случае завершения, продолжить приемку будет невозможно
              </Box>
            </Box>
          </>
        )}

        {storageAcceptance?.status === "READY_FOR_ACCEPTANCE" && (
          storageAcceptance?.seizureAct?.supplies?.length && storageAcceptance.seizureAct.supplies.map(supplie => (
            <Box key={supplie.id} sx={styles.wrapperContent}>
              {renderTable(supplie)}
            </Box>
          ))
        )}

        {storageAcceptance?.status === "ACCEPTED" && (
          <>
            {storageAcceptance?.acceptedSupplies?.length ? (
              <>
                {storageAcceptance.acceptedSupplies.map(supplie => (
                  <Box key={supplie.id} sx={styles.wrapperContent}>
                    <Box sx={styles.title}>
                      <Box sx={styles.titleElement}>
                        Заказ <Link
                        href={`${process.env.REACT_APP_HOST}/applications/${supplie?.supplyingOrder?.application?.id}/supplying_orders/${supplie?.supplyingOrder?.id}`}
                        target={"_blank"}
                      >
                        {supplie?.supplyingOrder?.number}
                      </Link>

                        <Typography component={"span"} sx={{color: "#b7bbc1"}}>•</Typography>
                      </Box>
                      <Box sx={styles.titleElement}>
                        Поставщик <Link
                        href={`${process.env.REACT_APP_HOST}/entities/${supplie?.supplyingOrder?.supplier?.id}`}
                        target={"_blank"}
                      >
                        {supplie?.supplyingOrder?.supplier?.shortLegalName || "Поставщик отсутствует"}
                      </Link>
                      </Box>
                    </Box>

                    <Box sx={styles.wrapperTable}>
                      {supplie?.acceptedShippedEquipments?.length ? (
                        supplie?.acceptedShippedEquipments.map(item => (
                          <Grid
                            columns={COLUMNS_MODEL_CREATOR_SEIZURE_ACT}
                            getRowId={(row) => row.id}
                            key={item.id}
                            rootComponent={GridRoot}
                            rows={[item.shippedEquipment] || []}
                          >
                            <RowDetailState/>

                            <DocumentActionTypeProviderLink
                              for={['actions']}
                              statusAcceptance={storageAcceptance?.status}
                              storageAcceptanceId={storageAcceptanceId}
                            />

                            <VirtualTable
                              cellComponent={(props) => (
                                <VirtualTable.Cell className="OrdersTableCell" {...props} />
                              )}
                              columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SEIZURE_ACT}
                              messages={{noData: 'Нет данных'}}
                            />
                            <TableHeaderRow/>
                          </Grid>
                        ))
                      ) : (
                        <Box sx={styles.wrapperContent}>
                          Заказы отсутствуют
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              <Box sx={styles.wrapperContent}>
                Заказы отсутствуют
              </Box>
            )}

            {storageAcceptance?.seizureAct?.supplies?.length > 0 && (
              <Box sx={styles.wrapperContent}>
                <ReactSpoiler
                  noOfLines={0}
                  showLessComponent={<Typography sx={{fontSize: '16px', fontWeight: 300, color: '#3D6CE7', display: 'inline'}}>Скрыть</Typography>}
                  showMoreComponent={<Typography sx={{fontSize: '16px', fontWeight: 300, color: '#3D6CE7', display: 'inline'}}>Всё отгруженное оборудование из заявки</Typography>}
                >
                  {storageAcceptance.seizureAct.supplies.map(supplie => renderTable(supplie))}
                </ReactSpoiler>
              </Box>
            )}
          </>
        )}
      </Box>

      {showModal && (
        <Dialog fullWidth open={showModal} scroll="body" sx={ styles.modal }>
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
              {titleModal}
            </Typography>
            {" "}
            <IconButton onClick={toggleShowModal}>
              <CloseIcon sx={{ color: '#B6BBC2' }} />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={styles.label}>{descriptionModal}</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}