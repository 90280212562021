import { gql } from '@apollo/client/core';

import {
  NOTES_FRAGMENT,
  GOODS_FRAGMENT,
  MANAGER_FRAGMENT,
  ENTITY_FRAGMENT,
  INVOICE_FRAGMENT,
  SUPPLY_FRAGMENT,
  CONTACT_FRAGMENT
} from "../fragments";

export const GET_SHOP_ORDER = gql`
  ${NOTES_FRAGMENT}
  ${GOODS_FRAGMENT}
  ${MANAGER_FRAGMENT}
  ${ENTITY_FRAGMENT}
  ${INVOICE_FRAGMENT}
  ${SUPPLY_FRAGMENT}
  ${CONTACT_FRAGMENT}
  query ShopOrder (
    $orderId: ID!,
  ) {
    shopOrder (
      orderId: $orderId
    ) {
      id
      status
      amount
      application {
        id,
        status
      }
      amountWithDiscount
      suppliesAmountLeft
      rejectionComment
      availableGoods {
        ...GoodsFragment
        
        storageAcceptance {
          displayDisputeUrl
          displayDisputeNumber
        }
      }
      unavailableGoods {
        ...GoodsFragment

        orders {
          id
        }
      }
      entity {
        ...EntityFragment
      }
      contact {
        ...ContactFragment
      }
      invoices {
        ...InvoiceFragment
      }
      supplies {
        ...SupplyFragment
      }
      manager {
        ...ManagerFragment
      }
      notes {
        ...NoteFragment
      }
    }
  }
`;