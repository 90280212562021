import PropTypes from "prop-types";
import React, { useRef } from 'react';

import {
  Box,
  IconButton,
} from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

import PrintOutput from "../ChildPages/components/PrintOutput";
import print from "../img/print.svg";

PrintGood.propTypes = {
  row: PropTypes.object,
};

export default function PrintGood({ row }) {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <IconButton onClick={handlePrint} sx={{border: '1px solid #CFD2D6', borderRadius: '8px', width: '48px', height: '48px'}}>
        <Box alt={'Печать информации о товаре'} component={"img"} src={print} sx={{width: '24px', height: '24px'}}/>
      </IconButton>

      <div style={{display: 'none'}}>
        <PrintOutput
          acceptedAt={row?.createdAt}
          description={row?.description}
          dispayLoanLeaseAgreement={row?.storageAcceptance.dispayLoanLeaseAgreement}
          dispayLoanSupplyDate={row?.shippedEquipment?.dispayLoanSupplyDate}
          id={parseInt(row?.id, 10)}
          name={row?.name}
          producedAt={row?.producedAt}
          quality={row?.quality}
          ref={componentRef}
          serialNumber={row?.serialNumber}
        />
      </div>
    </>
  );
}