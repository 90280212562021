import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageDeals from './PageDeals';
import PageFedresursReporting from './PageFedresursReporting';
import PageFinmonitoringReporting from './PageFinmonitoringReporting';

export default function DealsRoutes() {
  return (
    <Box component={Router} sx={{ height: '100%' }}>
      <PageDeals path="/" />
      <PageFedresursReporting path="/fedresurs-reporting" />
      <PageFinmonitoringReporting path="/finmonitoring-reporting" />
    </Box>
  );
}