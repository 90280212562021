import PropTypes from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';

const defaultContextData = {
  currentDraggableTask: null,
  updatingTask: null,
};

const defaultContextValue = {
  state: defaultContextData,
  setDraggableTask: () => { },
  setUpdatingTask: () => { },
};

export const TasksDashboardContext = createContext(defaultContextValue);

export const TasksDashboardProvider = ({ children }) => {
  const [state, setState] = useState(defaultContextData);

  const setDraggableTask = useCallback((task) => {
    setState((prev) => ({ ...prev, currentDraggableTask: task }));
  }, []);

  const setUpdatingTask = useCallback((task) => {
    setState((prev) => ({ ...prev, updatingTask: task }));
  }, []);

  return (
    <TasksDashboardContext.Provider
      value={{ setDraggableTask, setUpdatingTask, state }}
    >
      {children}
    </TasksDashboardContext.Provider>
  )
};

TasksDashboardProvider.propTypes = { children: PropTypes.node };