import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import noop from 'lodash/noop';
import { useDebouncedCallback } from 'use-debounce';

import { GET_TASK_USERS } from './users';

AutocompleteTaskUsers.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

AutocompleteTaskUsers.defaultProps = {
  disabled: false,
  label: '',
  onChange: noop,
};

export default function AutocompleteTaskUsers({
  disabled,
  label,
  onChange,
  value,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(value?.contact?.lastName || '');

  const [fetchTaskUsers, { data, loading }] = useLazyQuery(GET_TASK_USERS);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleChange = useCallback(
    (_, value) => onChange(value),
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((value) => {
    setSearchTerm(value);
    setOptions([]);
  }, 400);

  const handleSearch = useCallback(({ target: { value } }) => {
    debouncedSearch.callback(value);
  }, [debouncedSearch]);

  useEffect(() => {
    fetchTaskUsers({
      variables: { q: searchTerm },
    });
  }, [fetchTaskUsers, searchTerm]);

  useEffect(() => {
    if (data) {
      setOptions(data?.tasksUsersAutocomplete || []);
    }
  }, [data]);

  return (
    <Autocomplete
      autoComplete
      closeText="Скрыть список"
      defaultValue={value}
      disableClearable
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option?.displayName || ''}
      noOptionsText={loading ? 'Поиск...' : 'Нет данных'}
      onChange={handleChange}
      onClose={handleClose}
      onInput={handleSearch}
      onOpen={handleOpen}
      open={open}
      openText="Показать список"
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? <CircularProgress color="inherit" size={20} />
                  : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          disabled={loading || disabled || !options.length}
          label={label}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option?.displayName, inputValue);
        const parts = parse(option?.displayName, matches);

        return (
          <li {...props}>
            <div>
              {parts.map(({ highlight, text }, idx) => (
                <span key={idx} style={{ fontWeight: highlight ? 700 : 400 }}>
                  {text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};