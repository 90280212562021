import { gql } from '@apollo/client/core';

export const GET_TASK_USERS = gql`
  query GetTaskUsers($q: String!) {
    tasksUsersAutocomplete(q: $q) {
      id
      contact {
        lastName
      }
      displayName
    }
  }
`;