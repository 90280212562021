/* eslint-disable react/display-name, react/prop-types */
import React from 'react';

import {Link, Typography} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

export const taskBoardColorMap = {
  past: '#ff1744',
  today: '#81c784',
  tomorrow: '#1771e6',
  further: '#6d6b69',
};

export const taskPriorityColorMap = {
  URGENT: '#ff1744',
  MEDIUM: '#ff6600',
  LOW: '#c7c7c7',
};

export const TASKS_PAGE_LIMIT = 10;

export const TASKS_FILTER_STATE_FIELDS = {
  createdAtStart: 'Дата постановки c',
  createdAtEnd: 'Дата постановки по',
  dueAtStart: 'Дата исполнения с',
  dueAtEnd: 'Дата исполнения по',
  assignee: 'Исполнитель',
  assigneeId: 'Исполнитель',
  creator: 'Автор',
  status: 'Статус',
};

export const filterStateRenderers = (fieldName, value = '', options = {}) => {
  switch (fieldName) {
    case 'createdAtStart': return value ? formatDate(value) : '';
    case 'createdAtEnd': return value ? formatDate(value) : '';
    case 'dueAtStart': return value ? formatDate(value) : '';
    case 'dueAtEnd': return value ? formatDate(value) : '';
    case 'assignee': return value?.displayName || '';
    case 'assigneeId': return value?.displayName || '';
    case 'creator': return value?.displayName || '';
    case 'status': return options?.intl?.formatMessage({
      id: `tasks.status.${value}`,
    }) || value;
    default: return '';
  }
};

export const columnExtensions = [
  { columnName: 'title', wordWrapEnabled: true },
];

export const columnsModelCreator = [
  {
    name: 'target',
    title: '№',
    getCellValue: ({ target }) => {
      if (target?.id) {
        return (
          <Link
            color="#ff6600"
            href={`${process.env.REACT_APP_HOST}/applications/${target.id}`}
            target={"_blank"}
          >
            #{target.id}
          </Link>
        )
      }

      return '—';
    },
  },
  {
    name: 'title',
    title: 'Заголовок',
    getCellValue: ({ title }) => title || '—',
  },
  {
    name: 'description',
    title: 'Описание',
    getCellValue: ({ description }) => description || '—',
  },
  {
    name: 'assignee',
    title: 'Исполнитель',
    getCellValue: ({ assignee }) => {
      if (assignee?.contact) {
        return `${assignee.contact?.lastName} ${assignee.contact?.firstName}`
          .trim();
      }

      return '—';
    },
  },
  { name: 'dueAt', title: 'Дата исполнения' },
  {
    name: 'status',
    title: 'Статус',
    getCellValue: ({ status }) => (
      <Typography
        color={{
          OPENED: { color: 'lightgray' },
          COMPLETED: { color: 'green' },
          CANCELED: { color: orange[700] },
        }[status]}
      >
        <FormattedMessage id={`tasks.status.${status}`} />
      </Typography>
    ),
  },
  { name: 'createdAt', title: 'Дата постановки' },
  { name: 'notes', title: ' '},
];

export const columnsModelCreatorStyle = [
  { columnName: 'target', width: 120},
  { columnName: 'dueAt', width: 160 },
  { columnName: 'status', width: 140 },
  { columnName: 'createdAt', width: 160 },
  { columnName: 'notes', width: 80, align: 'center'},
]

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Задач на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Задач нет';
    }

    return to === count
      ? `Задач: ${to - from + 1} (всего ${count})`
      : `Задач ${from}-${to} (всего ${count})`;
  },
};