import { gql } from '@apollo/client/core';

export const SHOP_GOOD_SET_STATUS = gql`
  mutation ShopGoodsChangeStatus($goodId: ID!, $transition: ShopGoodTransition!) {
    shopGoodsChangeStatus(goodId: $goodId, transition: $transition) {
      good {
        id
        status
        __typename
      }
      __typename
    }
  }
`;