import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_CHECK_RECOGNITION_RESULT = gql`
  mutation SourceDocumentsCheckRecognitionResult($documentId: ID!) {
    sourceDocumentsCheckRecognitionResult(sourceDocumentId: $documentId) {
      sourceDocument {
        id
        recognizingProcess {
          status
        }
      }
    }
  }
`;