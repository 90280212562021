import PropTypes from 'prop-types';
import React from 'react';

import messages from 'locales/ru';
import { IntlProvider } from 'react-intl';

export default function IntlWrapper({ children }) {
  return (
    <IntlProvider defaultLocale="ru" locale="ru" messages={messages}>
      {children}
    </IntlProvider>
  );
}

IntlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
