import React, {useContext, useMemo, useState} from 'react';

import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography,
  SvgIcon
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { cloneDeep } from "lodash";

import { GET_SHOP_STORAGES } from "./graphql/queries/getStorageCollection";
import { ReactComponent as FilterIcon } from './img/filter.svg';
import { ReactComponent as SortIcon } from './img/sort.svg';
import { ShopContext } from "./ShopContext";

export default function FilterBlock() {
  const styles = useMemo(() => ({
    wrapperButton: {
      margin: '24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      color: '#6E7884',
      background: '#FFFFFF',
      padding: '12px 24px 12px 12px',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      border: '1px solid #FFFFFF',
      height: '48px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        background: '#FFFFFF',
        color: '#3D6CE7',
        boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',

        '& path': {
          fill: '#3D6CE7',
        },
      },
      '&:active': {
        background: '#FFFFFF',
        color: '#365CBF',
        boxShadow: '0px 6px 16px transparent',
        borderColor: '#E7E8EA',
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        background: '#E7E8EA',
        border: '1px solid #E7E8EA',
        color: '#9EA5AD',
      }
    },
    dropDown: {
      background: '#FFFFFF',
      padding: '0',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      border: '1px solid #FFFFFF',
      overflow: 'hidden',
      minWidth: '280px',
      WebkitTransition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        background: '#FFFFFF',
        boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',

        '& p': {
          color: '#3D6CE7 !important',
        },
        '& path': {
          fill: '#3D6CE7 !important',
        }
      },
      '&:active': {
        background: '#FFFFFF',
        boxShadow: '0px 6px 16px transparent',
        borderColor: '#E7E8EA',
      },
      '& .MuiListItem-button': {
        padding: '12px 4px 12px 12px',
        height: '48px',

        '&:hover': {
          background: '#ffffff',
        },
        '& p': {
          fontSize: '16px',
          fontWeight: '500',
          textTransform: 'none',
          color: '#6E7884',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',

          '& svg': {
            marginRight: '8px',
          }
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    dropDownMenu: {
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'none',
      color: '#6E7884',
      background: '#FFFFFF !important',

      '&:hover': {
        background: 'rgba(61, 108, 231, 0.05) !important',
        color: '#0D1D32 !important',

        '& path': {
          fill: '#0D1D32',
        }
      },
      '&:active': {
        background: '#FFFFFF',
        color: '#365CBF',

        '& path': {
          fill: '#365CBF',
        }
      },
      '& svg': {
        marginRight: '8px',
      },
      '&.activeClassList': {
        color: '#365CBF',

        '& path': {
          fill: '#365CBF',
        }
      },
    },
    wrapperContent: {
      margin: '24px',
      background: '#ffffff',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.1)',
      borderRadius: '16px',
      height: 'calc(100% - 120px)',
      overflow: 'hidden',
    },
    kitInputs: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      marginTop: '16px',

      '& > div': {
        width: '48%',
      }
    },
    kitSelects: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      marginTop: '16px',

      '& > div': {
        width: '100%',
      }
    },
    kitButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '40px',
      gap: '16px',
    },
    modalContentLabel: {
      fontWeight: 700,
      fontSize: '14px',
      marginBottom: '8px',
    },
    modalContentInput: {
      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    modalContentInputImgRub: {
      background: '#F5F6F7',
      borderRadius: '4px',
      padding: '9px 11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContentInputDate: {
      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    modalContentSelect: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,

      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '& svg': {
        marginRight: '8px',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      }
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    grayButton: {
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#ffffff',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',
      border: '1px solid #CFD2D6',

      '&:hover': {
        background: '#FFFFFF',
        border: '1px solid #3D6CE7',
        color: '#3D6CE7',
        boxShadow: 'none',
      },
      '&:active': {
        background: '#365CBF',
        border: '1px solid #365CBF',
        color: '#FFFFFF',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);
  
  const { state: query, updateFilter } = useContext(ShopContext);

  const {
    data: dataStorages,
    loading: loadingStorages
  } = useQuery(GET_SHOP_STORAGES);

  const [state, setState] = useState({
    storage: 0,
    location: 0,
    stock: false,
  })

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    const newQuery = cloneDeep(query);

    newQuery.sort.field = options[index].field;
    newQuery.sort.order = options[index].order;

    updateFilter(newQuery)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {title: 'По цене (вначале дешевле)', field: 'PRICE', order: 'ASC'},
    {title: 'По цене (вначале дороже)', field: 'PRICE', order: 'DESC'}
  ];

  const optionsImg = [
    ()=> (<SvgIcon component={SortIcon} inheritViewBox sx={{ transform: 'scale(1, -1)', }} />),
    ()=> (<SvgIcon component={SortIcon} inheritViewBox />),
  ];

  const [showModalFilter, setShowModalFilter] = useState(false);
  const toggleModalFilter = () => {
    setShowModalFilter(!showModalFilter);
  }

  const onChangeField = (type, event) => {
    const newState = cloneDeep(state);

    newState[type] = event.target.value;

    setState(newState);
  }

  const resetFilter = () => {
    const newQuery = cloneDeep(query);
    const newState = cloneDeep(state);

    newQuery.page = 0;
    newQuery.filter = {};

    newState.location = 0;
    newState.storage = 0;

    setState(newState);
    updateFilter(newQuery);
  }

  const applyFilter = () => {
    const newQuery = cloneDeep(query);

    newQuery.page = 0;

    if (state.location) {
      newQuery.filter = {
        ...newQuery.filter,
        currentLocationEq: state.location,
      };
    }

    if (state.storage) {
      newQuery.filter = {
        ...newQuery.filter,
        storageIdEq: +state.storage,
      };
    }

    updateFilter(newQuery)
  }

  const renderModalFilter = () => {
    return (
      <Dialog fullWidth open={showModalFilter} scroll="body">
        <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
            Фильтр
          </Typography>
          <IconButton>
            <CloseIcon onClick={toggleModalFilter} />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={ styles.kitSelects }>
            <Box>
              <Typography sx={ styles.modalContentLabel }>
                Локация:
              </Typography>

              <Select
                fullWidth
                onChange={onChangeField.bind(this, 'location')}
                sx={{
                  ...styles.modalContentSelect,
                  color: state.location === 0 ? '#B6BBC2' : null,
                }}
                value={state.location}
              >
                <MenuItem disabled sx={{ display: 'none' }} value={0}>Выбрать локацию</MenuItem>

                <MenuItem sx={styles.selectItem} value={"STORAGE"}>Склад</MenuItem>
                <MenuItem sx={styles.selectItem} value={"CLIENT"}>Клиент</MenuItem>
              </Select>
            </Box>

            <Box sx={{ display: 'none' }}>
              <Typography sx={ styles.modalContentLabel }>
                Склад:
              </Typography>

              <Select
                disabled={loadingStorages || state.location !== "STORAGE"}
                fullWidth
                onChange={onChangeField.bind(this, 'storage')}
                sx={{
                  ...styles.modalContentSelect,
                  color: state.storage === 0 ? '#B6BBC2' : null,
                }}
                value={state.storage}
              >
                <MenuItem disabled sx={{ display: 'none' }} value={0}>Выбрать склад</MenuItem>

                {dataStorages?.shopStorages?.collection?.map(storage => (
                  <MenuItem
                    key={storage.id}
                    sx={styles.selectItem}
                    value={storage.id}
                  >
                    {storage?.icon?.url && (
                      <img alt={storage.name} src={storage.icon.url} style={{ marginRight: '6px' }} />
                    )}

                    {storage.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box sx={ styles.kitButtons }>
            <Button
              // disabled={isEqual(persistState, state)}
              onClick={resetFilter}
              sx={ styles.grayButton }
              variant="contained"
            >
              Сбросить
            </Button>
            <Button
              // disabled={isEqual(persistState, state)}
              onClick={applyFilter}
              sx={ styles.blueButton }
              variant="contained"
            >
              Применить
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <Box sx={ styles.wrapperButton }>
        <Box></Box>

        <Box sx={{ display: 'flex', gap: '17px', }}>
          <Button
            onClick={toggleModalFilter}
            sx={ styles.button }
          >
            <SvgIcon component={FilterIcon} inheritViewBox />
            Фильтр
          </Button>

          <List
            component="nav"
            sx={ styles.dropDown }
          >
            <ListItem
              aria-controls="lock-menu"
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="listbox"
              button
              onClick={handleClickListItem}
            >
              <ListItemText
                secondary={
                  <>
                    {optionsImg[selectedIndex]()}
                    {options[selectedIndex].title}
                    <KeyboardArrowDownIcon sx={{marginLeft: '6px'}} />
                  </>}
              />
            </ListItem>
          </List>

          <Menu
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
            anchorEl={anchorEl}
            id="lock-menu"
            onClose={handleClose}
            open={open}
          >
            {options.map((option, index) => (
              <MenuItem
                className={index === selectedIndex ? 'activeClassList' : ''}
                key={option}
                onClick={(event) => handleMenuItemClick(event, index)}
                selected={index === selectedIndex}
                sx={ styles.dropDownMenu }
              >
                {optionsImg[index]()}
                {option.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      {showModalFilter && renderModalFilter()}
    </>
  );
}