import { gql } from '@apollo/client/core';

import { NOTES_FRAGMENT } from "../fragments";

export const NOTE_CREATE = gql`
  ${NOTES_FRAGMENT}
  mutation NoteCreate (
    $body: String!
    $priority: NotePriority!
    $targetType: NoteTargetType!
    $targetId: ID!
  ) {
    noteCreate(
      body: $body
      priority: $priority
      targetType: $targetType
      targetId: $targetId
    ) {
      note {
        ...NoteFragment
      }
    }
  }
`;
