import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';

import {
  GET_APPLICATION_SUBSTATUSES_BY_STATUS,
} from '../../graphql/queries/applicationSubstatusesByStatus';
import {
  GET_SOURCE_DOCUMENT,
} from '../../graphql/queries/sourceDocument';
import { GET_SUPPLYING_ORDER } from '../../graphql/queries/supplyingOrder';

export const AssignProcessContext = createContext({
  assignedObject: null,
  fetchingSupplyingOrders: false,
  refetchSupplyingOrders: () => { },
});

export const AssignProcessProvider = ({ children, documentId, orderId }) => {
  const [state, setState] = useState({});

  const [assignedObject, setAssignedObject] = useState(null);

  const [assignedObjectFetch, {
    loading: fetchingSourceDocument,
  }] = useLazyQuery(GET_SOURCE_DOCUMENT, {
    onCompleted: (data) => setAssignedObject(data.sourceDocument),
  });

  const [applicationSubstatusesFetch] = useLazyQuery(
    GET_APPLICATION_SUBSTATUSES_BY_STATUS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ applicationSubstatusesByStatus }) => {
        setState((prevState) => ({
          ...prevState,
          applicationState: {
            ...prevState.applicationState,
            substatuses: applicationSubstatusesByStatus,
          },
        }));
      },
      onError: () => console.log('Ошибка получения субстатусов заявки'),
    },
  );

  const [supplyingOrdersFetch, {
    loading: fetchingSupplyingOrders,
    refetch: refetchSupplyingOrders,
  }] = useLazyQuery(GET_SUPPLYING_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ supplyingOrder }) => {
      setState((prevState) => ({
        ...prevState,
        applicationId: supplyingOrder?.application?.id,
        applicationState: {
          status: supplyingOrder?.application?.status,
          currentSubstatuses: supplyingOrder?.application?.currentSubstatuses
            ?.map((s) => s.id),
          substatuses: [],
        },
        supplyingEntityContracts: supplyingOrder?.application?.supplyingEntityContracts || [],
        supplies: supplyingOrder?.supplies || [],
      }));

      if (supplyingOrder?.application?.status) {
        applicationSubstatusesFetch({
          variables: { status: supplyingOrder.application.status },
        });
      }
    },
    onError: () => console.log('Ошибка получения договоров поставки и отгрузок'),
  });

  useEffect(() => {
    if (documentId && !assignedObject) {
      assignedObjectFetch({ variables: { documentId } });
    }
  }, [documentId, assignedObject, assignedObjectFetch]);

  useEffect(() => {
    if (orderId) {
      supplyingOrdersFetch({ variables: { orderId } });
    }
  }, [orderId, supplyingOrdersFetch]);

  if (fetchingSourceDocument) {
    return (
      <Box sx={{ height: 60, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (!assignedObject) {
    return null;
  }

  return (
    <AssignProcessContext.Provider
      value={{
        ...state,
        assignedObject,
        fetchingSupplyingOrders,
        refetchSupplyingOrders,
      }}
    >
      {children}
    </AssignProcessContext.Provider>
  );
};

AssignProcessProvider.propTypes = {
  children: PropTypes.node,
  documentId: PropTypes.string,
  orderId: PropTypes.string,
};