import React, { useMemo } from 'react';

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ErrorAlert from 'components/ErrorAlert';

import AppMenu from './AppMenu';
import Profile from './Profile';
import { SidebarProps } from './types';

const useStyles = makeStyles(
  (theme) => ({
    drawerPaper: {
      width: 225,
      backgroundColor: '#2f4050',
      color: '#a7b1c2',
      overflowY: 'hidden',
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    collapseButton: {
      boxShadow: 'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.07) 0px 2px 4px 1px',
      background: 'white',
      position: 'absolute',
      top: '50%',
      right: -14,
      transform: 'translateY(-50%)',
      zIndex: 1201,
      transition: 'background 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s',
      '&:hover': {
        background: '#2196f3',
        color: 'white',
      },
    },
  })
);

Sidebar.propTypes = SidebarProps;

export default function Sidebar({
  currentUser,
  error = null,
  loading = false,
  menuItems = [],
  open,
  onClose,
  onOpen,
}) {
  const classes = useStyles();

  const drawerContent = useMemo(() => {
    if (error !== null && !loading) {
      return (
        <Box
          className={classes.drawerContent}
          sx={{ p: 2, '& .MuiAlertTitle-root': { fontSize: 14 } }}
        >
          <ErrorAlert message={error} title="Ошибка при выполнении запроса" />
        </Box>
      );
    }

    return (
      <Box className={classes.drawerContent}>
        <Profile data={currentUser} loading={loading} />
        <Divider />
        <AppMenu
          countTasks={currentUser?.openedTasksCounter}
          items={menuItems}
        />
      </Box>
    );
  }, [classes, error, loading, menuItems, currentUser]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: { lg: open ? 225 : 15 },
        gridArea: 'sidebar',
        position: 'relative',
        boxShadow: { lg: 'inset -5px 0 5px -5px rgba(51, 51, 51, 0.39)' },
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        ...(open ? {
          '&:hover .collapse-button': { opacity: 1 },
        } : {}),
      }}
    >
      <Hidden lgUp>
        <Drawer
          // Better open performance on mobile.
          ModalProps={{ keepMounted: true }}
          classes={{ paper: classes.drawerPaper }}
          onClose={onClose}
          open={open}
          variant="temporary"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <IconButton
          className="collapse-button"
          classes={{ root: classes.collapseButton }}
          onClick={open ? onClose : onOpen}
          size="small"
          sx={{ opacity: open ? 0 : 1 }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          open={open}
          variant="persistent"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
}