import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { DocumentStatusMap } from '../constants';

export const AssignDocumentNumberField = ({ documentNumber, documentStatus, onChange, required, title }) => {
  const handleChange = useCallback((e) => {
    if (onChange instanceof Function) {
      onChange(e?.target?.value || null);
    }
  }, [onChange]);

  useEffect(() => {
    if (onChange instanceof Function) {
      onChange(documentNumber ? documentNumber.toString() : null);
    }
  }, [documentNumber, onChange]);

  if (documentStatus === DocumentStatusMap.pending) {
    // recognizing
    return (
      <TextField
        defaultValue={documentNumber ? documentNumber : ''}
        label={title}
        onChange={handleChange}
        required={required}
        variant="outlined"
      />
    );
  }

  return (
    <TextField
      InputProps={{ sx: { bgcolor: grey[50] } }}
      defaultValue={documentNumber ? documentNumber : '—'}
      disabled
      label={title}
      variant="outlined"
    />
  );
};

AssignDocumentNumberField.propTypes = {
  documentNumber: PropTypes.string,
  documentStatus: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
};

AssignDocumentNumberField.defaultProps = {
  title: "Номер документа",
}