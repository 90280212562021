import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';

import ErrorAlert from 'components/ErrorAlert';

import DealsFilter from './components/DealsFilter';
import DealsTable from './components/DealsTable';
import { DealsFilterProvider } from './DealsFilterContext';

export default function PageDeals() {
  const [error, setError] = useState(null);

  return (
    <Box
      sx={{
        mb: 8,
        p: 3,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DealsFilterProvider>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
          }}
        >
          <Typography gutterBottom variant="h2">Сделки</Typography>
          <DealsFilter />
        </Box>
        <Box sx={{ py: 3 }}>
          {error && (
            <ErrorAlert
              message={error.message}
              title="Ошибка при выполнении запроса"
            />
          )}
        </Box>
        <DealsTable {...{ setError }} />
      </DealsFilterProvider>
    </Box>
  );
}