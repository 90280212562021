import { gql } from '@apollo/client/core';

import { SUPPLY_FRAGMENT } from "../fragments";

export const SHOP_SUPPLIES_UPDATE = gql`
  ${SUPPLY_FRAGMENT}
  mutation ShopSuppliesUpdate (
    $supplyId: ID!
    $params: ShopSupplyUpdateParams!
  ) {
    shopSuppliesUpdate (
      supplyId: $supplyId
      params: $params
    ) {
      supply {
        ...SupplyFragment
      }
    }
  }
`;
