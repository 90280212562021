import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  text: {
    display: 'inline-flex',
    cursor: 'pointer',
    padding: '0 0 1px',
    margin: '6px 4px',
    fontSize: 'inherit',
    lineHeight: 1.2,
    borderBottom: '1px dashed #1771e6',
    color: '#1771e6',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#1464cc',
    },
  },
}));

export default function EditableText({ children, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.text} onClick={onClick}>
      {children}
    </div>
  );
}

EditableText.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};
