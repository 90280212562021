/* eslint-disable react/display-name, react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CustomPaging,
  DataTypeProvider,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import MarkEmailReadIcon from '@material-ui/icons/MarkEmailRead';
import { navigate, useLocation } from '@reach/router';
import _identity from 'lodash.identity';
import _pick from 'lodash.pick';
import _pickBy from 'lodash.pickby';
import qs from 'querystringify';

import ErrorAlert from 'components/ErrorAlert';
import { Loading } from 'modules/bankStatements/components/GridOrders';
import { formatDate } from 'utils/dateTimeFormatters';

import CellClassificators from './CellClassificators';
import CellContract from './CellContract';
import CellSentAt from './CellSentAt';
import {
  FEDRESURS_UPDATE_REPORTING_MESSAGE,
} from '../graphql/mutations/fedresursUpdateReportingMessage';
import {
  GET_FEDRESURS_REPORTING_MESSAGES,
} from '../graphql/queries/fedresursReportingMessages';

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />;

const getRowId = (row) => row.id;

const DEFAULT_LIMIT = 50;

function CustomPagingPanel({ markMessageAsSend, selection, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 2,
      }}
    >
      <Button
        disableElevation
        disabled={!selection.length}
        onClick={markMessageAsSend}
        startIcon={<MarkEmailReadIcon />}
        sx={{ ml: 3, color: 'white', display: 'none' }}
        variant="contained"
      >
        &nbsp; Передано в Федресурс
      </Button>
      <span />
      <PagingPanel.Container {...props} />
    </Box>
  );
}

const PatchedTableSelection = (props) => {
  return (
    <TableSelection
      cellComponent={(props) => {
        return props.tableRow.row.state !== 'SENT' ? (
          <TableSelection.Cell {...props} />
        ) : (
          <Table.StubCell {...props} />
        )}
      }
      {...props}
    />
  );
};

const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Сообщений на страницу',
  info: ({ count, from, to }) => {
    if (to === count) {
      return `${count} сообщений`;
    }

    return `Сообщения ${from}-${to} (всего ${count})`;
  },
};

const ContractTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CellContract} {...props} />
);

const ClassificatorsTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CellClassificators} {...props} />
);
const CellSentAtTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CellSentAt} {...props} />
);

export default function TableFedresursReporting({ selection, setSelection }) {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [fetchMessages, { data, error, loading }] = useLazyQuery(
    GET_FEDRESURS_REPORTING_MESSAGES,
  );

  const [columns] = useState([
    {
      name: 'shortLegalName',
      title: 'Лизингополучатель',
      getCellValue: ({ application }) =>
        application?.borrowerEntity?.shortLegalName,
    },
    {
      name: 'contract',
      title: 'Договор',
      getCellValue: ({ contractDate, contractNumber, kind }) =>
        `${contractNumber}, ${contractDate}, ${kind}`,
    },
    {
      name: 'classificators',
      title: 'Классификаторы',
    },
    {
      name: 'term',
      title: 'Срок финансовой аренды',
      getCellValue: ({ endDate, startDate }) => (
        <Typography sx={{ fontFamily: 'Open Sans' }} variant="inherit">
          {startDate ? `${formatDate(startDate)} - ` : 'до '}
          {formatDate(endDate)}
        </Typography>
      ),
    },
    {
      name: 'sentAt',
      title: 'Отправлено в Федресурс',
    },
    {
      name: 'fedresursMessageId',
      title: '№ сообщения в Федресурс',
      getCellValue: ({ fedresursMessageId }) => (
        <Typography sx={{ fontFamily: 'Open Sans' }} variant="inherit">
          {fedresursMessageId ? fedresursMessageId : '--'}
        </Typography>
      ),
    }
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'shortLegalName', width: 256 },
    { columnName: 'sentAt', align: 'center' },
  ]);

  const [pageSizes] = useState([10, 50, 0]);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      const { fedresursReportingMessages } = data;

      setRows(fedresursReportingMessages.collection);
      setTotalCount(fedresursReportingMessages.metadata.totalCount);
      if (
        fedresursReportingMessages.metadata.currentPage >
        fedresursReportingMessages.metadata.totalPages
      ) {
        const query = qs.parse(location.search);
        const nextQuery = {
          ...query,
          page: fedresursReportingMessages.metadata.totalPages || 1,
        };
        navigate(qs.stringify(nextQuery, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [sendFedresursReportingMessage] = useMutation(
    FEDRESURS_UPDATE_REPORTING_MESSAGE, {
    onError: (err) => {
      alert(err);
    },
  });

  useEffect(() => {
    const { limit, page, ...filter } = _pick(parsedQuery, [
      'limit',
      'page',
      'state',
      'kind',
      'q'
    ]);

    if(filter?.state) {
      filter.state = [filter.state];
    }

    if(filter?.kind) {
      filter.kind = [filter.kind];
    }

    fetchMessages({
      variables: {
        filter: !!Object.keys(filter).length ? filter : undefined,
        page: Number(page || 1),
        limit: Number(limit || DEFAULT_LIMIT),
        order: 'DESC'
      },
    });
  }, [fetchMessages, location.search, parsedQuery]);

  const { currentPage, pageSize } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      searchValue: query.titleAndPurpose || '',
      currentPage: query.page ? Number(query.page) - 1 : 0,
      pageSize: query.limit ? Number(query.limit) : DEFAULT_LIMIT,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function markMessageAsSend() {
    selection.forEach((messageId) => {
      sendFedresursReportingMessage({
        variables: {
          fedresursReportingMessageId: messageId,
          attributes: {
            state: 'SENT',
          },
        },
      });
    });
  }

  function updateSearchLocation(params) {
    const parsedQuery = qs.parse(location.search);

    const query = _pickBy(
      {
        ...parsedQuery,
        ...params,
      },
      _identity,
    );

    const search = qs.stringify(query, true);

    navigate(search ? search : location.pathname);
  }

  // Pagination handlers
  function handleCurrentPageChange(page) {
    updateSearchLocation({ page: page + 1 });
  }

  function handlePageSizeChange(value) {
    updateSearchLocation({ limit: value === 0 ? totalCount : value });
  }

  const handleChangeSelection = (sel) => {
    const newSelection = [];
    
    rows.forEach((row) => {
      sel.forEach((id) => {
        if(id === row.id && row.state !== 'SENT') {
          newSelection.push(id);
        }
      })
    });

    setSelection(newSelection);
  };

  return (
    <Box sx={{ flex: 0.999, minHeight: 0 }}>
      <Box
        sx={{
          border: '8px solid whitesmoke',
          borderRadius: '8px',
          height: '100%',
        }}
      >
        <Box sx={{ px: 3, height: '100%' }}>
          <Grid
            columns={columns}
            getRowId={getRowId}
            rootComponent={Root}
            rows={rows}
          >
            <ContractTypeProvider for={['contract']} />
            <ClassificatorsTypeProvider for={['classificators']} />
            <CellSentAtTypeProvider for={['sentAt']} />
            <SelectionState
              onSelectionChange={handleChangeSelection}
              selection={selection}
            />
            <IntegratedSelection />
            <RowDetailState />
            <PagingState
              defaultCurrentPage={currentPage}
              defaultPageSize={pageSize}
              onCurrentPageChange={handleCurrentPageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalCount} />

            <VirtualTable columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <PatchedTableSelection
              selectByRowClick
              showSelectAll
            />
            <PagingPanel
              containerComponent={(props) => (
                <CustomPagingPanel
                  {...{ selection, markMessageAsSend }}
                  {...props}
                />
              )}
              messages={pagingPanelMessages}
              pageSizes={pageSizes}
            />
            {error && (
              <ErrorAlert
                message={error.message}
                title="Ошибка при выполнении запроса"
              />
            )}
            {loading && <Loading />}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
