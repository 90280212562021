import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import noop from 'lodash/noop';
import { useDebouncedCallback } from 'use-debounce';

import { GET_ENTITIES } from './entities';

AutocompleteEntities.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

AutocompleteEntities.defaultProps = {
  disabled: false,
  required: false,
  label: '',
  onChange: noop,
};

export default function AutocompleteEntities({
  disabled,
  label,
  onChange,
  required,
  value,
 ...props
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    value?.tin ? value.tin : (value?.legalNameWithShortEntityType || '')
  );

  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [fetchEntities, { data, loading }] = useLazyQuery(GET_ENTITIES);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleChange = useCallback(
    (_, value) => onChange(value),
    [onChange],
  );

  const handleScrollList = useCallback(({ target }) => {
    const { clientHeight, scrollHeight, scrollTop } = target;
    setIsScrollBottom(
      Math.round(scrollHeight - scrollTop) - 25 <= clientHeight,
    );
  }, []);

  const debouncedSearch = useDebouncedCallback((value) => {
    setSearchTerm(value);
    setOptions([]);
    setCurrentPage(1);
  }, 400);

  const handleSearch = useCallback(({ target: { value } }) => {
    debouncedSearch.callback(value);
  }, [debouncedSearch]);

  useEffect(() => {
    if (isScrollBottom) {
      setCurrentPage((prev) => prev < totalPages ? prev + 1 : prev);
    }
  }, [isScrollBottom, totalPages]);

  useEffect(() => {
    fetchEntities({
      variables: {
        page: currentPage,
        limit: 10,
        filters: { innOrLegalNameLike: searchTerm },
      },
    });
  }, [fetchEntities, currentPage, searchTerm]);

  useEffect(() => {
    if (data) {
      const { entities } = data;
      setOptions((prev) => prev.concat(entities.collection));
      setTotalPages(entities.metadata.totalCount);
    }
  }, [data]);

  return (
    <Autocomplete
      ListboxProps={{ onScroll: handleScrollList }}
      autoComplete
      closeText="Скрыть список"
      defaultValue={value}
      disableClearable
      disabled={disabled}
      fullWidth
      getOptionLabel={
        (option) => option?.legalNameWithShortEntityType && option?.tin
          ? `${option.legalNameWithShortEntityType} ${option.tin}`
          : option.legalNameWithShortEntityType || ''
      }
      noOptionsText={loading ? 'Поиск...' : 'Нет данных'}
      onChange={handleChange}
      onClose={handleClose}
      onInput={handleSearch}
      onOpen={handleOpen}
      open={open}
      openText="Показать список"
      options={options}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? <CircularProgress color="inherit" size={20} />
                  : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          disabled={loading || disabled || !options.length}
          label={label}
          required={required}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(
          `${option?.legalNameWithShortEntityType} ${option?.tin}`,
          inputValue,
        );

        const parts = parse(
          `${option?.legalNameWithShortEntityType} ${option?.tin}`,
          matches,
        );

        return (
          <li {...props}>
            <div>
              {parts.map(({ highlight, text }, idx) => (
                <span key={idx} style={{ fontWeight: highlight ? 700 : 400 }}>
                  {text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};