import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Box, IconButton, Stack, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { formatDate } from 'utils/dateTimeFormatters';

LayoutFileRow.propTypes = {
  file: PropTypes.shape({
    createdAt: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  icon: PropTypes.node,
  isUploading: PropTypes.bool,
  onFileDelete: PropTypes.func,
};

export default function LayoutFileRow({
  file,
  icon,
  isUploading,
  onFileDelete,
}) {
  const handleDeleteFile = useCallback(
    () => onFileDelete(file.id),
    [file, onFileDelete],
  );

  return (
    <Box>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2}>
          {icon}
          <Typography sx={{ fontSize: 12 }} variant="body1">
            {file.name}
          </Typography>&nbsp;
          {file.createdAt && (
            <Typography sx={{ fontSize: 12 }}>
              {formatDate(file.createdAt, 'short_date_time')}
            </Typography>
          )}
        </Stack>
        <IconButton
          disabled={isUploading}
          onClick={handleDeleteFile}
          size="small"
        >
          <DeleteForeverIcon fontSize="small" htmlColor="#D74E51" />
        </IconButton>
      </Stack>
    </Box>
  );
}