import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { FormattedMessage } from "react-intl";

import FormattedCurrency from 'components/FormattedCurrency';

import { DateFormatter } from './helpers';

export const DOCUMENTS_LIMIT = 10;

export const DocumentStatusMap = {
  pending: 'PENDING',
  assigned: 'ASSIGNED',
  recognizing: 'RECOGNIZING',
  waitingForAssignment: 'WAITING_FOR_ASSIGNMENT',
  canceled: 'CANCELED',
};

export const CATEGORY_DOCUMENTS = {
  applicationSupplyingOrder: 'APPLICATION_SUPPLYING_ORDER',
  loanSupply: 'LOAN_SUPPLY',
  dispute: 'DISPUTE',
}

export const DocumentRecognizingStatusMap = {
  pending: 'PENDING',
  recognizing: 'RECOGNIZING',
  processFailed: 'PROCESS_FAILED',
  recognitionFailed: 'RECOGNITION_FAILED',
  succeed: 'SUCCEED',
};

export const documentStatusTabs = [
  { label: 'Нераспределенные', value: 'unallocated' },
  { label: 'Ожидает оригинала', value: 'waitingForOriginal' },
  { label: 'Распределенные', value: 'allocated' },
  { label: 'Распознан', value: 'recognized' },
  { label: 'Распознается', value: 'recognition' },
  { label: 'Все документы', value: 'all' },
  { label: 'Прочее', value: 'other' },
  { label: 'Длительное распознавание', value: 'longTimeRecognizing' },
];

export const FilterStatusMap = {
  unallocated: {
    status: ['PENDING'],
  },
  waitingForOriginal: {
    status: ['PENDING', 'ASSIGNED'],
    isOriginalReceived: false,
  },
  allocated: {
    status: ['ASSIGNED'],
  },
  recognized: {
    status: ['PENDING', 'ASSIGNED'],
    recognitionStatus: 'SUCCEED',
  },
  recognition: {
    status: ['PENDING', 'ASSIGNED'],
    recognitionStatus: 'RECOGNIZING',
  },
  all: {},
  other: {
    status: ['CANCELED'],
  },
  longTimeRecognizing: {
    longTimeRecognizing: true,
  },
};

export const columnExtensions = [
  { columnName: 'id', width: 50 },
  { columnName: 'name', width: 250, wordWrapEnabled: true },
  { columnName: 'whereIsPosted', wordWrapEnabled: true },
  { columnName: 'category', wordWrapEnabled: true },
  { columnName: 'lessee', width: 280, wordWrapEnabled: true },
  { columnName: 'supplier', width: 200, wordWrapEnabled: true },
  { columnName: 'documentNumber', width: 140 },
  { columnName: 'status', width: 130, wordWrapEnabled: true },
  { columnName: 'documentType', width: 140, wordWrapEnabled: true },
  { columnName: 'isOriginalReceived', width: 120 },
  { columnName: 'amount', width: 130 },
  { columnName: 'actions', width: 280 },
];

export const columnsModelCreator = [
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Наименование документа' },
  {
    name: 'whereIsPosted',
    title: 'Куда разнесен',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      if (row?.status === DocumentStatusMap.assigned) {
        let path;
        let href;
        let text;

        if (row?.type?.category === CATEGORY_DOCUMENTS.dispute) {
          path = row?.document?.target;
          href = `applications/${path?.application?.id}/deal/dispute#dispute-${path?.id}`;
          text= "Дело";
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.loanSupply) {
          path = row?.document?.target?.supplyingOrder;
          href = `applications/${path?.application?.id}/supplying_orders/${path?.id}`;
          text= "Заказ";
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.applicationSupplyingOrder) {
          path = row?.document?.target;
          href = `applications/${path?.application?.id}/supplying_orders/${path?.id}`;
          text= "Заказ";
        }

        return (
          <>
            <Box>
              <Link
                fontSize={14}
                href={`${process.env.REACT_APP_HOST}/${href}`}
                target="_blank"
              >
                {text} №{path?.number}
              </Link>
            </Box>

            <Link
              color="#a9b1c0"
              fontSize={14}
              href={`${process.env.REACT_APP_HOST}/applications/${path?.application?.id}/deal`}
              target="_blank"
            >
              {row?.document?.target?.supplyingEntityContract?.loan?.displayLeaseAgreement}
            </Link>
          </>
        )
      }

      return '';
    },
  },
  {
    name: 'category',
    title: 'Категория',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      return (
        <>
          <FormattedMessage id={`incomingdocuments.category.${row.type.category}`} />
        </>
      )
    },
  },
  {
    name: 'documentType',
    title: 'Тип документа',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      return (
        <Typography>
          {row?.type?.name}
        </Typography>
      )
    },
  },
  {
    name: 'lessee',
    title: 'Лизингополучатель',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      if (row?.status === DocumentStatusMap.assigned) {
        let path;

        if (row?.type?.category === CATEGORY_DOCUMENTS.dispute) {
          path = row?.document?.target;
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.loanSupply) {
          path = row?.document?.target?.supplyingOrder;
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.applicationSupplyingOrder) {
          path = row?.document?.target;
        }

        return (
          <Box>
            <Link
              fontSize={14}
              href={`${process.env.REACT_APP_HOST}/entities/${path?.application?.borrowerEntity?.id}`}
              target="_blank"
            >
              {path?.application?.borrowerEntity?.legalNameWithShortEntityType}
            </Link>
          </Box>
        );
      }

      return '';
    },
  },
  {
    name: 'supplier',
    title: 'Поставщик',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      if (row?.status === DocumentStatusMap.assigned) {
        let path;

        if (row?.type?.category === CATEGORY_DOCUMENTS.dispute) {
          path = row?.document?.target;
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.loanSupply) {
          path = row?.document?.target?.supplyingOrder;
        }

        if (row?.type?.category === CATEGORY_DOCUMENTS.applicationSupplyingOrder) {
          path = row?.document?.target;
        }

        return (
          <Link
            fontSize={14}
            href={`${process.env.REACT_APP_HOST}/entities/${path?.supplier?.id}`}
            target="_blank"
          >
            {path?.supplier?.legalNameWithShortEntityType}
          </Link>
        );
      }

      if (row?.recognizingProcess?.ocrData) {
        return (
          <Link
            fontSize={14}
            href={`${process.env.REACT_APP_HOST}/entities/${row?.recognizingProcess?.ocrData?.seller?.id}`}
            target="_blank"
          >
            {row?.recognizingProcess?.ocrData?.seller?.legalNameWithShortEntityType}
          </Link>
        );
      }

      return '';
    },
  },
  {
    name: 'documentNumber',
    title: 'Номер документа',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      const documentNumber =
        row?.status === DocumentStatusMap.assigned
          ? row?.documentNumber
          : row?.recognizingProcess?.ocrData?.documentNumber;

      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {documentNumber ? <Box>№{documentNumber}</Box> : null}
          {row?.recognizingProcess?.ocrData ? (
            <Box>
              <Typography color={grey[500]} fontSize="12px">
                от <DateFormatter value={row.recognizingProcess.ocrData.date} />
              </Typography>
            </Box>
          ) : null}
          {row?.document?.target?.receivingDate ? (
            <Box>
              <Typography fontSize="12px">Дата получения:</Typography>
              <DateFormatter value={row?.document?.target?.receivingDate} />
            </Box>
          ) : null}
        </Box>
      );
    },
  },
  {
    name: 'status',
    title: 'Статус',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => row?.recognizingProcess?.status || '',
  },
  { name: 'isOriginalReceived', title: 'Оригинал/скан' },
  {
    name: 'amount',
    title: 'Сумма',
    // eslint-disable-next-line react/display-name
    getCellValue: (row) => {
      if (
        row?.recognizingProcess?.status === DocumentRecognizingStatusMap.succeed &&
        row?.status !== DocumentStatusMap.assigned
      ) {
        return <FormattedCurrency value={row?.recognizingProcess?.ocrData?.totalAmount} />;
      }

      if (row?.status === DocumentStatusMap.assigned) {
        return <FormattedCurrency value={row?.amount} />;
      }

      return '';
    },
  },
  { name: 'actions', title: 'Действия' },
];

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Документов на страницу',
  info: ({ count, from, to }) => {
    if (!count) {
      return 'Документов нет';
    }

    return to === count
      ? `Документов: ${to - from + 1} (всего ${count})`
      : `Документы ${from}-${to} (всего ${count})`;
  },
};

export const getRowId = (row) => row.id;
