import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageOrders from './PageOrders';

export default function DealsRoutes() {
  return (
    <Box component={Router} sx={{ height: '100%' }}>
      <PageOrders path="/" />
    </Box>
  );
}