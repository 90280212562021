import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TaskAltIcon from '@material-ui/icons/TaskAlt';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import DateTimeInlinePicker from 'components/DateTimeInlinePicker';
import { TASKS_CANCEL } from 'modules/tasks/graphql/mutations/tasksCancel';
import { TASKS_COMPLETE } from 'modules/tasks/graphql/mutations/tasksComplete';

import { getTaskStyles } from './Task.styles';
import { TaskContext } from './TaskContext';
import TaskDetails from './TaskDetails';
import { TasksDashboardContext } from './TasksDashboardContext';
import Phone from "../../../../components/Phone";
import WazzupLink from "../../../../components/WazzupLink";
import findURL from "../../../../utils/findURL";
import { taskBoardColorMap, taskPriorityColorMap } from '../../constants';

export default function Task() {
  const { state } = useContext(TasksDashboardContext);
  const { refetch, task, type, updateInProgress, updateState } = useContext(TaskContext);

  const boardColor = useMemo(() => taskBoardColorMap[type], [type]);
  const priorityColor = useMemo(
    () => taskPriorityColorMap[task?.priority] || '#c7c7c7',
    [task],
  );
  const taskStyles = getTaskStyles(boardColor);

  const contactFullName = useMemo(() => [
    task?.target?.contact?.lastName || '',
    task?.target?.contact?.firstName || '',
  ].join(' ').trim(), [task]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  const [openDetails, setOpenDetails] = useState(false);
  const handleOpenDetails = useCallback(() => setOpenDetails(true), []);
  const handleCloseDetails = useCallback(() => setOpenDetails(false), []);

  const [complete, { loading: completeInProgress }] = useMutation(
    TASKS_COMPLETE,
    {
      onCompleted: (res) => {
        if (res.tasksComplete) {
          if (refetch instanceof Function) {
            refetch();
          }
          alert(`Задача #${res.tasksComplete?.task?.id} завершена`);
        }
      },
      onError: (error) => {
        console.log('🚀 ~ file: Task.jsx ~ line 75 ~ Task ~ error', error);
        alert(`Ошибка: Задачу #${task?.id} не удалось завершить`);
      },
    },
  );

  const handleTaskComplete = useCallback(() => {
    handleMenuClose();

    if (task) {
      complete({
        variables: { taskId: task.id || '' },
      });
    }
  }, [complete, handleMenuClose, task]);

  const [cancel, { loading: cancelInProgress }] = useMutation(
    TASKS_CANCEL,
    {
      onCompleted: (res) => {
        if (res.tasksCancel) {
          if (refetch instanceof Function) {
            refetch();
          }
          alert(`Задача #${res.tasksCancel?.task?.id} отменена`);
        }
      },
      onError: (error) => {
        console.log('🚀 ~ file: Task.jsx ~ line 103 ~ Task ~ error', error);
        alert(`Ошибка: Задачу #${task?.id} не удалось отменить`);
      },
    },
  );

  const handleTaskCancel = useCallback(() => {
    handleMenuClose();

    if (task) {
      cancel({
        variables: { taskId: task.id || '' },
      });
    }
  }, [cancel, handleMenuClose, task]);

  const handleTaskUpdateDate = useCallback(
    (value) => {
      updateState(value);
      handleMenuClose();
    },
    [updateState, handleMenuClose],
  );

  const taskActionsMenu = useMemo(() => ([
    {
      action: handleTaskComplete,
      icon: TaskAltIcon,
      title: 'Завершить',
      show: false,
    },
    {
      action: handleTaskCancel,
      icon: CancelIcon,
      title: 'Отменить',
      show: false,
    },
    {
      action: 'updateData',
      icon: UpdateOutlinedIcon,
      title: 'Отложить',
      show: true,
    },
  ]), [handleTaskComplete, handleTaskCancel]);

  if (!task) {
    return null;
  }

  const renderItemMenu = (idx, action, icon, title) => {
    return (
      <MenuItem
        dense
        key={idx}
        onClick={ action === "updateData" ? null : action }
      >
        <ListItemIcon sx={{ minWidth: 28 }}>
          <Box component={icon} fontSize="inherit" />
        </ListItemIcon>
        {title}
      </MenuItem>
    )
  };

  const renderMark = (status) => {
    let bg = '';
    let color = '';

    switch (status) {
      case 'ADDITIONAL_DOCUMENTS_REQUEST':
        bg = '#64a2bd';
        color = '#0a2834';

        break;
      case 'ADVANCE_PAYMENT_RECEIVED':
      case 'PARTIAL_PAYMENT':
        bg = '#8ca94e';
        color = '#395007';

        break;
      case 'APPROVED':
        bg = '#75803a';
        color = '#2f340c';

        break;
      case 'DEAL':
        bg = '#b0de81';
        color = '#456227';

        break;
      case 'EXTINGUISHED':
        bg = '#fab875';
        color = '#7c4710';

        break;
      case 'GOVERNANCEING':
        bg = '#bbb773';
        color = '#5b5718';

        break;
      case 'ISSUED':
        bg = '#6f934f';
        color = '#26420e';

        break;
      case 'LEAD':
        bg = '#c3c3c3';
        color = '#5d5c5c';

        break;
      case 'LEASING_SALE':
        bg = '#2ec7ce';
        color = '#15585b';

        break;
      case 'PREAPPROVED':
        bg = '#9ba652';
        color = '#40480f';

        break;
      case 'PREPARING_DEAL':
        bg = '#6c672f';
        color = '#282505';

        break;
      case 'PRIMARY_CONSULTATION':
        bg = '#4adadf';
        color = '#256d70';

        break;
      case 'PROPOSAL_FAMILIARIZED':
      case 'PROPOSAL_SENT':
        bg = '#808080';
        color = '#3d3d3d';

        break;
      case 'TRASH':
        bg = '#f17761';
        color = '#721300';

        break;
      case 'VERIFICATION':
      case 'null':
      case 'undefined':
      case 'UNDERWRITING':
      case 'PREPARING_DOCUMENTS':
        bg = '#e0c1bb';
        color = '#a27770';

        break;
      default:
        bg = '';
        color = '';
    }

    return (
      <Typography component="span" sx={{ ...taskStyles.markStatus, background: bg, color, }} variant="subtitle2">
        <FormattedMessage
          id={`application.substatus.${status}`}
        />
      </Typography>
    )
  }

  return (
    <React.Fragment>
      <Card sx={taskStyles.task}>
        {(state?.updatingTask?.id === task?.id || updateInProgress) && (
          <Box sx={taskStyles.updateProgressBox}>
            <CircularProgress />
          </Box>
        )}

        <Box sx={taskStyles.taskHeader}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Chip
              label={`#${task.id}`}
              onClick={handleOpenDetails}
              size="small"
              sx={taskStyles.taskNumber}
            />

            <Chip
              label={(
                <React.Fragment>
                  {type === 'today' && (
                    <React.Fragment>
                      Сегодня
                      {task?.dueAt && task?.certainTime && (
                        <React.Fragment>
                          , <FormattedTime value={task.dueAt} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {type === 'tomorrow' && (
                    <React.Fragment>
                      Завтра
                      {task?.dueAt && task?.certainTime && (
                        <React.Fragment>
                          , <FormattedTime value={task.dueAt} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {(type === 'past' || type === 'further') && (
                    <React.Fragment>
                      {task?.dueAt ? (
                        <FormattedDate
                          {...task?.certainTime
                            ? { hour: 'numeric', minute: 'numeric' }
                            : {}
                          }
                          day="numeric"
                          month="numeric"
                          value={task.dueAt}
                          year="2-digit"
                        />
                      ) : 'нет данных'}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              size="small"
              sx={taskStyles.taskDueAt}
            />

            {(completeInProgress || cancelInProgress) && (
              <CircularProgress size={16} />
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton onClick={handleMenuOpen} size="small">
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={handleMenuClose}
              open={open}
              sx={{ '& li': { px: '12px' } }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {taskActionsMenu.map(({ action, icon, show, title }, idx) => (
                show && (
                  action === 'updateData' ? (
                    <DateTimeInlinePicker
                      ViewComponent={
                        renderItemMenu(idx, action, icon, title)
                      }
                      certainTime={task.certainTime}
                      hardTime={true}
                      key={`dateTimeline_${idx}`}
                      onChange={handleTaskUpdateDate}
                      onClose={handleMenuClose}
                      value={task.dueAt}
                    />
                  ) : (
                    renderItemMenu(idx, action, icon, title)
                  )
                )
              ))}
            </Menu>

            {task?.priority && (
              <Tooltip
                title={(
                  <React.Fragment>
                    приоритет:{' '}
                    <strong>
                      <FormattedMessage id={`task.priority.${task.priority}`} />
                    </strong>
                  </React.Fragment>
                )}
              >
                <BookmarkIcon htmlColor={priorityColor} />
              </Tooltip>
            )}
          </Box>
        </Box>

        <Typography sx={{ fontSize: 16, fontWeight: 600, mt: 1 }} variant="subtitle2">
          {task?.title}
        </Typography>

        {task?.description && (
          <Typography
            dangerouslySetInnerHTML={{__html: findURL(task?.description?.length >= 88 ? task?.description?.slice(0, 87).replace(/\n/gi, " ") + "..." : task?.description.replace(/\n/gi, " ")) }}
            sx={{ textTransform: 'unset', fontWeight: 400, lineHeight: '1.8' }}
            variant="overline"
          />
        )}

        <Box sx={taskStyles.taskFooter}>
          <Box sx={{ marginBottom: '6px', }}>
            {renderMark(task?.target?.status)}

            <Link
              href={`${process.env.REACT_APP_HOST}/applications/${task?.target?.id}`}
            >
              Заявка №{task?.target?.id}
            </Link>
          </Box>

          {task?.target?.subject && (
            <Typography sx={{ color: '#6E7884', fontSize: '12px', fontWeight: '400', padding: '2px 0 8px', }} variant="subtitle2">
              {task?.target?.subject}
            </Typography>
          )}

          <Box sx={taskStyles.wrapperLink}>
            <span>Поставщик:</span>
            {' '}

            {task?.target?.supplyingEntityContracts && task?.target?.supplyingEntityContracts.length > 0 && task?.target?.supplyingEntityContracts[0]?.supplyingOrder ? (
              <Link
                href={`${process.env.REACT_APP_HOST}/entities/${task?.target?.supplyingEntityContracts[0]?.supplyingOrder?.supplier?.id}`}
              >
                {task?.target?.supplyingEntityContracts[0]?.supplyingOrder?.supplier?.legalNameWithShortEntityType}
              </Link>
            ) : (
              '—'
            )}
          </Box>

          <Box sx={taskStyles.wrapperLink}>
            <span>Клиент:</span>
            {' '}

            {task?.target?.borrowerEntity?.shortLegalName ? (
              <Link
                color='#3D6CE7'
                href={`${process.env.REACT_APP_HOST}/entities/${task?.target?.borrowerEntity?.id}`}
                target={"_blank"}
              >
                {task?.target?.borrowerEntity?.shortLegalName}
              </Link>
            ) : (
              '—'
            )}
          </Box>

          <Box sx={taskStyles.wrapperLink}>
            <span>Контактное лицо:</span>
            {' '}
            {task?.target?.contact?.id ? (
              <Link href={`${process.env.REACT_APP_HOST}/contacts/${task?.target?.contact?.id}`}>
                {contactFullName}
              </Link>
            ) : (
              '—'
            )}
          </Box>

          {task?.target?.contact?.phone && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Phone phone={task?.target?.contact?.phone} />

              <WazzupLink contactId={task?.target?.contact?.id} />
            </Box>
          )}
        </Box>
      </Card>

      {openDetails && (
        <TaskDetails
          {...{ boardColor, priorityColor }}
          onClose={handleCloseDetails}
          onCompleteTask={handleTaskComplete}
          open={openDetails}
        />
      )}
    </React.Fragment>
  );
}