import PropTypes from 'prop-types';
import React from 'react';

import { useSubscription } from '@apollo/client';
import { Alert, Box, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Tooltip from 'components/Tooltip';
import { formatDate } from 'utils/dateTimeFormatters';

import {
  FEDRESURS_FETCHING_PROCESS_UPDATED,
} from './graphql/subscriptions/fedresursFetchingProcessUpdated';
import TableFedresurs from './TableFedresurs';

TitleContentForProcess.propTypes = {
  createdAt: PropTypes.string,
  failedAt: PropTypes.string,
  finishedAt: PropTypes.string,
  startedAt: PropTypes.string,
};
export function TitleContentForProcess({
  createdAt,
  failedAt,
  finishedAt,
  startedAt,
}) {
  return (
    <Box fontSize={12} p={2}>
      <Box mb={2}>
        {formatDate(createdAt, 'short_date_time')} - запрос добавлен в очередь
      </Box>
      {startedAt && (
        <Box mb={2}>
          {formatDate(startedAt, 'short_date_time')} - запрос начал выполняться
        </Box>
      )}
      {finishedAt ? (
        <Box>
          {formatDate(finishedAt, 'short_date_time')} - запрос выполнен
        </Box>
      ) : (
        failedAt && (
          <Box>
            {formatDate(failedAt, 'short_date_time')} - запрос завершился с ошибкой
          </Box>
        )
      )}
    </Box>
  );
}

ContainerFedresursProcess.propTypes = {
  id: PropTypes.string,
  renderButtonStartFedresursProcess: PropTypes.func,
};
export default function ContainerFedresursProcess({
  id: processId,
  renderButtonStartFedresursProcess,
}) {
  const { data, error, loading } = useSubscription(
    FEDRESURS_FETCHING_PROCESS_UPDATED,
    { variables: { processId } },
  );

  if (loading) {
    return 'Загрузка...';
  }

  if (error) {
    return error.message;
  }

  const { fedresursFetchingProcessUpdated } = data;

  if (!fedresursFetchingProcessUpdated) {
    return 'fedresursFetchProcessUpdated null';
  }

  const { contracts, createdAt, failedAt, finishedAt, startedAt, state } =
    fedresursFetchingProcessUpdated;

  const InfoTooltip = (
    <Tooltip
      title={
        <TitleContentForProcess
          {...{ createdAt, failedAt, finishedAt, startedAt }}
        />
      }
    >
      <HelpOutlineIcon
        color="action"
        fontSize="small"
        style={{ verticalAlign: 'text-bottom' }}
      />
    </Tooltip>
  );

  const STATE_TO_PROPS = {
    CREATED: {
      message: 'Запрос добавлен в очередь в ',
      getTime: (time) => formatDate(time, 'short_date_time'),
    },
    STARTED: {
      message: 'Запрос начал выполняться в ',
      getTime: (time) => formatDate(time, 'short_date_time'),
    },
    FAILED: {
      message: 'Последний запрос завершился с ошибкой в ',
      getTime: (time) => formatDate(time, 'short_date_time'),
      severity: 'error',
      action: renderButtonStartFedresursProcess('Повторить запрос'),
    },
    FINISHED: {
      message: 'Последнее обновление данных было в ',
      getTime: (time) => formatDate(time, 'short_date_time'),
      action: renderButtonStartFedresursProcess('Обновить'),
    },
  };

  const { action, getTime, message, severity = 'info' } = STATE_TO_PROPS[state];

  return (
    <>
      <Alert action={action} severity={severity} sx={{ mb: 3 }}>
        <Typography variant="body1">
          {message}{' '}
          <Box component="span" fontWeight={500} mr={2}>
            {getTime(createdAt)}
          </Box>
          {InfoTooltip}
        </Typography>
      </Alert>
      {state === 'FINISHED' && <TableFedresurs data={contracts} />}
    </>
  );
}
