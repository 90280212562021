import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import Wazzup from "./Wazzup";

export default function WazzupRoutes() {
  return (
    <Box component={Router} sx={{ height: '100%',  backgroundColor: '#f3f4f5', overflow: 'auto', }}>
      <Wazzup path="/" />
    </Box>
  );
}