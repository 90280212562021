import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function UnpinIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect fill="none" fillRule="nonzero" height="24" width="24" x="0" y="0" />
      <path
        d="M8,7L11,7L11,17L13,17L13,7L16,7L12,3L8,7ZM4,19L4,21L20,21L20,19L4,19Z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}
