import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

export default function TableLoader() {
  return (
    <Box
      bgcolor="rgba(255, 255, 255, .5)"
      height="100%"
      left="0"
      position="absolute"
      top="0"
      width="100%"
      zIndex={1000}
    >
      <Box fontSize="20px" left="calc(50% - 10px)" position="absolute" top="calc(45% - 10px)">
        <CircularProgress />
      </Box>
    </Box>
  );
}
