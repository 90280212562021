import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  CustomPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import pick from 'lodash/pick';
import qs from 'querystringify';

import { GridRoot, Loader } from './helpers';
import {
  columnExtensions,
  columnsModelCreator,
  DEALS_PAGE_LIMIT,
  pagingPanelMessages,
} from '../constants';
import { DealsFilterContext } from '../DealsFilterContext';
import { GET_LOANS } from '../graphql/queries/loans';

DealsTable.propTypes = {
  setError: PropTypes.func,
};

export default function DealsTable({ setError }) {
  const { updateState: updateFilterState } = useContext(DealsFilterContext);

  const [fetchLoans, { data, error, loading }] = useLazyQuery(GET_LOANS);

  const location = useLocation();

  const { currentPage, pageSize, sorting } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      currentPage: query.page ? Number(query.page) - 1 : 0,
      pageSize: query.limit ? Number(query.limit) : DEALS_PAGE_LIMIT,
      sorting: [{
        fieldName: query.sort_by || 'id',
        direction: query.order || 'desc',
      }],
    };
  }, [location.search]);

  const [sortingState, setSortingState] = useState([{
    columnName: 'id',
    direction: 'desc',
  }]);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [sortingStateColumnExtensions] = useState([
    { columnName: 'borrower', sortingEnabled: false },
    { columnName: 'interestRate', sortingEnabled: false },
    { columnName: 'amountFinancing', sortingEnabled: false },
    { columnName: 'fundingSource', sortingEnabled: false },
    { columnName: 'overdueDays', sortingEnabled: false },
  ]);

  const updateSearchLocation = useCallback((params) => {
    const parsedQuery = qs.parse(location.search);
    const query = { ...parsedQuery, ...params };
    navigate(qs.stringify(query, true));
  }, [location.search]);

  const handleSortingChange = useCallback((sortingChanges) => {
    const processedSortingChanges = sortingChanges.map(
      ({ columnName, direction }) => ({
        fieldName: columnName,
        direction: columnName === sorting[0].fieldName
          ? direction
          : 'desc',
      }),
    );

    const [sortingConfig] = processedSortingChanges.map(
      ({ direction, fieldName }) => ({ sort_by: fieldName, order: direction }),
    );

    updateSearchLocation(sortingConfig);
  }, [sorting, updateSearchLocation]);

  const handleCurrentPageChange = useCallback((page) => {
    updateSearchLocation({ page: page + 1 });
  }, [updateSearchLocation]);

  const handlePageSizeChange = useCallback((value) => {
    updateSearchLocation({ limit: value === 0 ? totalCount : value });
  }, [updateSearchLocation, totalCount]);

  useEffect(() => {
    const parsedQuery = qs.parse(location.search);

    const { filters, limit, order, page, sort_by, } = pick(parsedQuery, [
      'limit',
      'order',
      'page',
      'sort_by',
      'filters',
    ]);

    const fetchVariables = {
      page: Number(page || 1),
      limit: Number(limit || DEALS_PAGE_LIMIT),
      order: { fieldName: 'ID', direction: 'DESC' },
    };

    if (sort_by && order) {
      setSortingState([{ columnName: sort_by, direction: order }]);

      fetchVariables['order'] = {
        fieldName: {
          id: 'ID',
          displayLeaseAgreement: 'LEASING_AGREEMENT',
          leasingAgreementDate: 'LEASING_AGREEMENT_DATE',
          term: 'TERM',
          accountState: 'ACCOUNT_STATE',
        }[sort_by] || 'ID',
        direction: { asc: 'ASC', desc: 'DESC' }[order] || 'DESC',
      };
    }

    if (filters) {
      const jsonFilters = JSON.parse(filters) || {};
      updateFilterState(jsonFilters);

      const modJsonFilters = { ...jsonFilters };
      if (modJsonFilters?.borrower) {
        delete modJsonFilters.borrower;
        modJsonFilters.lesseeId = jsonFilters.borrower.id;
      }

      fetchVariables['filters'] = modJsonFilters;
    }

    fetchLoans({ variables: fetchVariables });
  }, [fetchLoans, location.search, updateFilterState]);

  useEffect(() => {
    if (data) {
      const { loans } = data;
      setRows(loans.collection);
      setTotalCount(loans.metadata.totalCount);
    }
  }, [data]);

  useEffect(() => setError(error || null), [error, setError]);

  return (
    <Box sx={{ flex: 0.999, minHeight: 0 }}>
      <Box
        sx={{
          border: '8px solid whitesmoke',
          borderRadius: '8px',
          height: 1,
        }}
      >
        <Box sx={{ px: 3, height: 1 }}>
          <Grid
            columns={columnsModelCreator}
            rootComponent={GridRoot}
            rows={rows}
          >
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={handleSortingChange}
              sorting={sortingState}
            />
            <PagingState
              currentPage={currentPage}
              defaultPageSize={pageSize}
              onCurrentPageChange={handleCurrentPageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalCount} />

            <VirtualTable
              columnExtensions={columnExtensions}
              messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
            />
            <TableHeaderRow
              messages={{ sortingHint: 'Сортировать' }}
              showSortingControls
            />

            <PagingPanel
              messages={pagingPanelMessages}
              pageSizes={[10, 25, 50, 100, 0]}
            />

            {loading && <Loader />}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}