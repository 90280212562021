import PropTypes from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';

import {DEALS_PAGE_LIMIT, TABS} from "./constants";



const defaultState = {
  filter: {
    tab: TABS[0],
    page: 1,
    limit: DEALS_PAGE_LIMIT,
    sort: {
      field: 'CREATED_AT',
      order: 'DESC',
    },
  },
  subTabs: null,
  problems: [],
};

export const OrdersContext = createContext({
  state: defaultState,
  setProblems: () => {},
  updateFilter: () => {},
  updateSubTabs: () => {}
});

export const OrdersProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const setProblems = (problems = []) => {
    setState((prev) => ({ ...prev, problems }));
  };

  const updateFilter = useCallback(
    (filters) => {
      setState(filters);
    }, []
  )

  const updateSubTabs = useCallback(
    (subTabs) => {
      setState(subTabs);
    }, []
  )

  return (
    <OrdersContext.Provider value={{ state, setProblems, updateFilter, updateSubTabs }}>
      {children}
    </OrdersContext.Provider>
  );
};

OrdersProvider.propTypes = {
  children: PropTypes.node,
};
