import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, MenuItem, Select, TextField } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { DatePickerBox } from 'components/DatePickerBox';

import { TaskContext } from './TaskContext';
import AutocompleteTaskUsers from "../../../../components/AutocompleteTaskUsers";
import {CustomTimePickerBox} from "../../../../components/CustomTimePickerBox";
import DropzoneImages from '../../../../components/DropzoneImages';
import { FilterModalBox } from '../helpers';

TaskEdit.propTypes = {
  onClose: PropTypes.func,
};

const styles = {
  files: {
    '& > div': {
      flexWrap: 'wrap'
    }
  },
  wrapperUpImg: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '24px',
  },
  blockImg: {
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #E7E8EA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '142px',
    height: '142px',

    '&.PURGE': {
      opacity: '0.3'
    },
    '& .index': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .remove': {
      cursor: 'pointer',
      position: 'absolute',
      left: '8px',
      top: '8px',
      background: '#67BF3D',
      borderRadius: '20px',
      color: '#ffffff',
      fontSize: '14px',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '40px',
      height: 'auto'
    },
    'span': {
      display: 'block',
      position: 'absolute',
      bottom: '4px',
      left: '4px',
      right:'4px',
      width: 'calc(100% - 8px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '10px'
    }
  }
};

export default function TaskEdit({ onClose }) {
  const { state, updateState } = useContext(TaskContext);

  const [persistState, setPersistState] = useState(state);
  const [hasDateError, setHasDateError] = useState(false);
  const [files, setFiles] = useState([]);
  const [removeAttachmentsIds, setRemoveAttachmentsIds] = useState([]);

  const updateTaskState = useCallback((fieldName, value) => {
    setPersistState((prev) => ({ ...prev, [fieldName]: value }));
  }, []);

  const saveChanges = useCallback(() => {
    if (persistState.assignee) {
      delete persistState.assignee
    }

    const newPersistState = {...persistState};

    const filteredAttachments = [];
    if(newPersistState.attachments) {
      newPersistState.attachments.forEach((element) => {
        if(element?.uploaded === false) {
          filteredAttachments.push(element);
        }
      });
    }

    newPersistState.attachments = filteredAttachments;

    updateState(newPersistState);
    if (onClose instanceof Function) {
      onClose();
    }
  }, [persistState, updateState, onClose]);

  const resetChanges = useCallback(() => setPersistState(state), [state]);

  const handleChangeField = useCallback((fieldName) => ({ target: { value } }) => {
    updateTaskState(fieldName, value);
  }, [updateTaskState]);

  const handleChangeAssignee = useCallback(fieldName => value => {
    if (fieldName === "assignee") {
      updateTaskState("assigneeId", Number(value.id));
    }
  }, [updateTaskState])

  const handleChangeDate = useCallback((value) => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      dateValue = new Date(value);
      dateValue = dateValue.toISOString();
    }

    updateTaskState('dueAt', dateValue);
  }, [updateTaskState]);

  const handleChangeTime = useCallback((value) => {
    let timeValue = null;

    if (value && dayjs(value).isValid()) {
      timeValue = new Date(value);
      timeValue = timeValue.toISOString();
      updateTaskState('certainTime', true);
    } else {
      updateTaskState('certainTime', false);
    }

    updateTaskState('dueAt2', timeValue);
  }, [updateTaskState]);

  const handleDownloadedDeleteFile = (id) => {
    const newPersistState = {...persistState};
    const newRemoveAttachmentsIds = [...removeAttachmentsIds];
    const findedIndex = newRemoveAttachmentsIds.findIndex((element) => (element === id));
    const notLoadedRemove = newPersistState?.attachments.findIndex((element) => (element?.uploaded === false && element?.imageId === id));

    if(notLoadedRemove === -1) {
      if (findedIndex !== -1) {
        newRemoveAttachmentsIds.splice(findedIndex, 1);
        setRemoveAttachmentsIds(newRemoveAttachmentsIds);
      } else {
        newRemoveAttachmentsIds.push(id);
        setRemoveAttachmentsIds(newRemoveAttachmentsIds);
      }
      newPersistState.removeAttachmentsIds = JSON.stringify(newRemoveAttachmentsIds);
      setPersistState(newPersistState);
    } else {
      newPersistState.attachments.splice(notLoadedRemove, 1);
      setPersistState(newPersistState);
    }
  };

  const elPreview = (fileItem) => {
    if((fileItem?.fileExt && ['jpg', 'jpeg', 'png', 'bmp'].includes(fileItem.fileExt)) || fileItem?.type === 'image/jpeg') {
      return (
        <img
          alt={fileItem?.file?.name || fileItem?.name || fileItem.filename}
          src={fileItem?.file?.preview || fileItem?.preview || fileItem.url}
          width="120px"
        />
      );
    }

    return <AttachFile sx={{fill: '#ccc'}} />;
  };

  useEffect(() => {
    setHasDateError(persistState.dueAt === null);
  }, [persistState]);

  useEffect(() => {
    if(persistState) {
      const newPersistState = {...persistState};
      let newFiles = [];

      files.forEach((file) => {
        newFiles.push(
          file
        );
      });

      if(persistState.attachments) {
        newPersistState.attachments = newPersistState.attachments.concat(newFiles);
      } else {
        newPersistState.attachments = [...newFiles];
      }

      setPersistState(newPersistState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <Box>
      <Box>
        <FilterModalBox title="Заголовок">
          <TextField
            fullWidth
            onChange={handleChangeField('title')}
            size="small"
            value={persistState?.title || ''}
          />
        </FilterModalBox>
        <FilterModalBox title="Описание задачи">
          <TextField
            fullWidth
            minRows={3}
            multiline
            onChange={handleChangeField('description')}
            size="small"
            value={persistState?.description || ''}
          />
        </FilterModalBox>
        <FilterModalBox title="Приоритет">
          <Select
            displayEmpty
            fullWidth
            onChange={handleChangeField('priority')}
            value={persistState?.priority || ''}
          >
            <MenuItem disabled value="">Выбрать</MenuItem>
            <MenuItem value="URGENT">Высокий</MenuItem>
            <MenuItem value="MEDIUM">Средний</MenuItem>
            <MenuItem value="LOW">Низкий</MenuItem>
          </Select>
        </FilterModalBox>
        <FilterModalBox title="Исполнитель">
          <AutocompleteTaskUsers
            onChange={handleChangeAssignee('assignee')}
            value={persistState?.assignee || ''}
          />
        </FilterModalBox>
        <FilterModalBox title="Срок до">
          <Box sx={{ display: 'flex', gap: 4, width: 1 }}>
            <DatePickerBox
              hasError={hasDateError}
              onChange={handleChangeDate}
              size="small"
              value={persistState?.dueAt || null}
            />
            <CustomTimePickerBox
              onChange={handleChangeTime}
              value={persistState?.certainTime ? persistState?.dueAt2 : null}
            />
          </Box>
        </FilterModalBox>
        <FilterModalBox
          style={styles.files}
          title="Прикрепить файл"
        >
          <Box sx={{width: '100%'}}>
            <DropzoneImages
              accept="image/*, application/*, .heic"
              onFilesDropped={setFiles}
            />
          </Box>
          {persistState.attachments && persistState.attachments.length > 0 && (
            <>
              <Box sx={ styles.wrapperUpImg }>
                {persistState.attachments.map((fileItem, index) => (
                  <Box className={(fileItem?.attachmentId && removeAttachmentsIds.includes(parseInt(fileItem.attachmentId, 10))) ? 'PURGE' : ''} key={index} sx={styles.blockImg}>
                    <Box className={'remove'} onClick={handleDownloadedDeleteFile.bind(this, fileItem?.attachmentId ? parseInt(fileItem.attachmentId, 10) : fileItem.imageId)}>x</Box>
                    <Box className={'index'}>{index + 1}</Box>
                    {elPreview(fileItem)}
                    <span>{fileItem?.file?.name || fileItem?.name || fileItem.filename}</span>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </FilterModalBox>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
        <Button
          disabled={isEqual(persistState, state)}
          onClick={resetChanges}
        >
          Сбросить
        </Button>
        <Button
          disabled={isEqual(persistState, state) || hasDateError}
          onClick={saveChanges}
          sx={{ color: 'white' }}
          variant="contained"
        >
          Применить
        </Button>
      </Box>
    </Box>
  );
}