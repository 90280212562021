import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';

import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import searchIcon from './image/search.svg';

Search.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.string,
};

Search.defaultProps = {
  placeholder: "Введите поисковой запрос",
  value: "",
  disabled: false,
}

export default function Search({disabled, onChange, placeholder, sx, value}) {
  const [searchQuery, setSearchQuery] = useState(value);

  useEffect(() => {
    setSearchQuery(value)
  }, [value])

  const handleSearchChange = event => {
    let term = event.target.value;

    if (onChange && typeof onChange == "function") {
      onChange(term);
    }

    setSearchQuery(term);
  }

  const handleSearchFilter = () => {
    if (onChange && typeof onChange == "function") {
      onChange("");
    }

    setSearchQuery("");
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 34,
        mt: 2,
        mb: 3,
        backgroundColor: 'white',
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
        ...sx,
      }}
    >
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {searchQuery?.length > 0 ? (
                <IconButton onClick={handleSearchFilter} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
            startAdornment: (
            <InputAdornment position="start">
              <Box component="img" height={24} src={searchIcon} />
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        fullWidth
        onChange={handleSearchChange}
        placeholder={placeholder}
        size="small"
        sx={{
          '& .MuiInputBase-root': { paddingLeft:'12px', paddingRight: '12px' },
          '& input': { fontSize: 14, height: 'calc(100% + 2px)' },
          '& fieldset': { border: 'none' },
        }}
        type={"text"}
        value={searchQuery}
      />
    </Box>
);
}