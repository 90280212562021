import PropTypes from "prop-types";
import React, {useMemo, useState} from 'react';

import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { INVOICES_CHANGE_STATUS } from "../graphql/mutations/invoicesChangeStatus";

BillingModalReject.propTypes = {
  disabledButton: PropTypes.bool,
  row: PropTypes.object,
  setMessageError: PropTypes.func,
  showErrors: PropTypes.func,
  updateInvoice: PropTypes.func,
};

export default function BillingModalReject({ disabledButton, row, setMessageError, showErrors, updateInvoice }) {
  const styles = useMemo(() => ({
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '400px',
        borderRadius: '16px',
      }
    },
    blueButton: {
      color: '#ffffff',
      boxShadow: 'none',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    redButton: {
      background: '#FF5650',
      color: '#FFFFFF',
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '& span': {
        fontSize: '14px !important',
        fontWeight: '500 !important',
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        background: '#FF7773',
        boxShadow: '0px 6px 16px 0px rgba(229, 87, 87, 0.30)',
      },
      '&:active': {
        background: '#D94843',
      },
      '&:disabled': {
        background: '#CED2D6',
        color: '#868E98'
      },
    }
  }), []);

  const [invoiceChangeStatus, { loading: loadingSuppliesChangeStatus }] = useMutation(INVOICES_CHANGE_STATUS, {
    onCompleted: res => {
      updateInvoice(res.invoicesChangeStatus.invoice)
      toggleShowModal();
    },
    onError: error => {
      setMessageError(error)
      showErrors();
    }
  });

  const onRemoveInvoice = () => {
    invoiceChangeStatus({
      variables: {
        invoiceId: row.id,
        transition: "CANCEL"
      }
    })
  }

  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => {
    setShowModal(!showModal)
  }

  if (row.status === 'PAID') {
    return (
      <Typography>
        Счёт оплачен
      </Typography>
    )
  }

  if (row.status === 'CANCELED') {
    return (
      <Typography>
        Счёт отклонён
      </Typography>
    )
  }

  return (
    <>
      <Button disabled={disabledButton} onClick={toggleShowModal} sx={ styles.redButton }>
        Отклонить
      </Button>

      {showModal && (
        <Dialog fullWidth open={showModal} scroll="body" sx={ styles.modal }>
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
              Отклонение счёта № {row.id}
            </Typography>
            {" "}
            <IconButton onClick={toggleShowModal}>
              <CloseIcon sx={{ color: '#B6BBC2' }} />
            </IconButton>
          </DialogTitle>

          <DialogActions sx={{ padding: '24px' }}>
              <Button
                disabled={loadingSuppliesChangeStatus}
                onClick={onRemoveInvoice}
                sx={ styles.redButton }
              >
                Да
              </Button>

              <Button
                onClick={toggleShowModal}
                sx={ styles.blueButton }
              >
                Нет
              </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}