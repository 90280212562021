import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import DayJsUtils from '@date-io/dayjs';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import noop from 'lodash/noop';

dayjs.locale('ru');

const styles = {
  picker: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      m: 0,
      left: 0,
      p: 0,
    },
  },
};

const INVALID_DATE_ERROR_MESSAGE = 'Неверный формат даты';

export const TimePickerBox = ({
  label,
  onChange,
  onError,
  placeholder,
  value,
  variant = 'inline',
}) => {
  const handleError = useCallback((error, value) => {
    if (onError instanceof Function) {
      onError(Boolean(error));
    }
  }, [onError]);

  return (
    <MuiPickersUtilsProvider
      libInstance={dayjs}
      locale="ru"
      utils={DayJsUtils}
    >
      <KeyboardTimePicker
        ampm={false}
        autoOk
        format="HH:mm"
        invalidDateMessage={INVALID_DATE_ERROR_MESSAGE}
        label={Boolean(label) ? label : undefined}
        mask="__:__"
        onChange={onChange}
        onError={handleError}
        openTo="hours"
        placeholder={!Boolean(label) ? placeholder : undefined}
        size="small"
        sx={styles.picker}
        value={value}
        variant={variant}
      />
    </MuiPickersUtilsProvider>
  );
};

TimePickerBox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
};

TimePickerBox.defaultProps = {
  onChange: noop,
  placeholder: '',
  value: '',
};