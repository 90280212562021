import PropTypes from "prop-types";
import React, {useMemo, useState} from 'react';

import {
  Grid,
  TableHeaderRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {GridRoot} from "../../deals/components/helpers";
import {
  COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING_MODAL_SKU,
  COLUMNS_MODEL_CREATOR_SHOP_LEASING_MODAL_SKU,
} from "../constants";

ContentModalSKU.propTypes = {
  row: PropTypes.object,
};

export default function ContentModalSKU({ row }) {
  const styles = useMemo(() => ({
    title: {
      cursor: "pointer",
      color: '#1771e6',

      '&:hover': {
        textDecoration: 'underline',
      }
    },
    modal: {
      '& .MuiDialog-paper': {
        maxWidth: '1000px',
        borderRadius: '16px',
      }
    },
    wrapperTable: {
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
      marginBottom: '40px',

      '& div': {
        height: 'auto !important',
      },
      '& .MuiTableHead-root tr': {
        backgroundColor: '#F5F6F7 !important',

        '& span': {
          color: '#6E7884',
          fontSize: '12px',
        },
      },
      '& table': {
        marginBottom: '0 !important',

        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root:hover path': {
          color: '#1771e6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root path': {
          color: '#CFD2D6'
        },
        '& thead .MuiTableRow-head .MuiTableCell-head .Mui-checked path': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root:hover': {
          color: '#1771e6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .MuiCheckbox-root': {
          color: '#CFD2D6'
        },
        '& tbody .MuiTableCell-paddingCheckbox .Mui-checked': {
          color: '#1771e6'
        },
        '& tr:hover': {
          background: 'rgba(61, 108, 231, 0.05)',
        },
        '& th:last-child': {
          paddingRight: '16px',
        },
        '& td:last-child': {
          paddingRight: '16px',
        },
        '& tr:last-child': {
          '& td': {
            borderBottom: 'none'
          }
        },
      },
      '& .CustomPaging': {
        marginTop: '16px',
        marginBottom: '16px',
      },
      '& .CustomPaging > div:nth-child(1)': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-child(2) > span': {
        display: 'none',
      },
      '& .CustomPaging > div:nth-of-type(2) .MuiButton-text': {
        color: '#6E7884',
        width: '46px',
        height: '46px',
        margin: '0 1px',
        borderRadius: '50%',
      },
      '& .CustomPaging > div:nth-of-type(2) button:hover': {
        backgroundColor: '#3D6CE7 !important',
        color: '#ffffff',
      },
      '& .CustomPaging > div:nth-of-type(2) button[tabindex="-1"]:not([disabled])': {
        backgroundColor: '#3D6CE7',
        color: '#ffffff',
      },
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    }
  }), []);

  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <Typography component={"span"} onClick={toggleShowModal} sx={ styles.title }>
        {row?.shippedEquipmentsCount}
      </Typography>

      {showModal && (
        <Dialog fullWidth open={showModal} scroll="body" sx={ styles.modal }>
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
              Кол-во SKU
            </Typography>
            <IconButton onClick={toggleShowModal} >
              <CloseIcon sx={{ color: '#B6BBC2' }} />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Box sx={ styles.wrapperTable }>
              <Grid
                columns={COLUMNS_MODEL_CREATOR_SHOP_LEASING_MODAL_SKU}
                getRowId={(row) => row.id}
                rootComponent={GridRoot}
                rows={row.shippedEquipments}
              >
                <VirtualTable
                  cellComponent={(props) => (
                    <VirtualTable.Cell className="OrdersTableCell" {...props} />
                  )}
                  columnExtensions={COLUMN_EXTENSIONS_STRUCTURE_SHOP_LEASING_MODAL_SKU}
                  messages={{ noData: 'Нет данных' }}
                />
                <TableHeaderRow />
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '32px', }}>
              <Button onClick={toggleShowModal} sx={ styles.blueButton }>Закрыть</Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}