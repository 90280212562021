import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import OrderDetails from "./OrderDetails";
import Orders from "./Orders";
import { ShopProvider } from "./ShopContext";

export default function ShopRoutes() {
  return (
    <ShopProvider>
      <Box component={Router} sx={{ height: '100%',  backgroundColor: '#f3f4f5', overflow: 'auto', }}>
        <Orders id={"orders"} path="/" />
        <OrderDetails id={"details"} path="/details/:id" />
      </Box>
    </ShopProvider>
  );
}