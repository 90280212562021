import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Box, Drawer, useTheme } from '@material-ui/core';
import { navigate, useLocation, useParams } from '@reach/router';
import _isUndefined from 'lodash.isundefined';
import _pickBy from 'lodash.pickby';
import qs from 'querystringify';

import GridOrders, { FILTERS } from './GridOrders';

DrawerOrders.propTypes = {
  disabledEdit: PropTypes.bool,
  from: PropTypes.string,
};

export default function DrawerOrders({ disabledEdit, from }) {

  const [state, setState] = useState(false);
  const { search } = useLocation();
  const { bankStatementId } = useParams();
  const { transitions } = useTheme();

  const filter = useMemo(() => {
    function predicate(value, key) {
      return FILTERS.includes(key) && !_isUndefined(value);
    }
    return _pickBy(qs.parse(search), predicate);
  }, [search]);

  useEffect(() => {
    if (Object.keys(filter).length) {
      setState(true);
    }
  }, [filter]);

  function handleDrawerClose() {
    setState(false);
    setTimeout(() => {
      navigate(from ? from : bankStatementId);
    }, transitions.duration.leavingScreen);
  }

  if (!Object.keys(filter).length) {
    return null;
  }

  return (
    <Drawer
      ModalProps={{ disableEnforceFocus: true }}
      anchor="right"
      onClose={handleDrawerClose}
      open={state}
    >
      <Box width="50vw">
        <GridOrders bankStatementId={bankStatementId} disabledEdit={disabledEdit} />
      </Box>
    </Drawer>
  );
}
