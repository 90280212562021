import PropTypes from 'prop-types';

import { mask, createDefaultMaskGenerator } from 'react-hook-mask';

import {PHONE_FORMAT} from "../../core/constants/Phone";

Phone.propTypes = {
  phone: PropTypes.node,
};

export default function Phone({ phone }) {
  const maskGenerator = createDefaultMaskGenerator(PHONE_FORMAT);

  if (!phone || !phone?.length) {
    return '---'
  }

  return (
    mask(phone, maskGenerator)
  );
}