import { gql } from '@apollo/client';

export const VERIFICATION_ASSIGN_PROCESS = gql`
  mutation VerificationAssignProcess($id: ID!) {
    verificationAssignProcess(verificationProcessId: $id) {
      verificationProcess {
        id
        resolutionText
        state
        verificator {
          id
          login
        }
      }
    }
  }
`;