import PropTypes from 'prop-types';
import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import logo from 'assets/mvd.svg';
import _get from 'lodash.get';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import {
  FMS_START_PASSPORT_CHECK,
} from './graphql/mutations/fmsStartPassportCheck';
import { GET_FMS_PASSPORT_CHECKS } from './graphql/queries/fmsPassportChecks';

FmsPassportCheck.propTypes = {
  contactId: PropTypes.string,
};

const ICON = {
  active: <CheckIcon fontSize="large" style={{ color: 'green' }} />,
  invalid: <ReportProblemIcon fontSize="large" style={{ color: 'orange' }} />,
  inactive: <ErrorIcon fontSize="large" style={{ color: 'red' }} />, //5307 634718
};
// const STATUS_COLORS = {
//   active: 'rgb(34, 160, 83)',
//   invalid: 'orange',
//   inactive: 'rgb(221, 86, 86)', // rgb(224, 31, 25)
// };

export default function FmsPassportCheck({ contactId }) {
  const { data, loading } = useQuery(GET_FMS_PASSPORT_CHECKS, {
    variables: { contactId },
    onError: (error) => {
      alert(`Произошла ошибка при запуске проверки ФМС ${error.message}`);
    },
  });

  const fmsPassportChecks = _get(data, 'fmsPassportChecks', []);

  const [startFmsPassportCheck] = useMutation(FMS_START_PASSPORT_CHECK, {
    variables: { contactId },
    onError: (error) => {
      alert(`Произошла ошибка при запуске проверки ФМС ${error.message}`);
    },
    refetchQueries: [{
      query: GET_FMS_PASSPORT_CHECKS,
      variables: { contactId },
    }],
  });

  function handleClick() {
    startFmsPassportCheck();
  }

  if (loading) {
    return 'Загрузка...';
  }

  if (!fmsPassportChecks.length) {
    return 'Проверка паспорта не выполнялась';
  }

  return (
    <Box maxWidth={1256} p={4}>
      <Box alignItems="center" display="flex" mb={3} py={2}>
        <Box alt="Logo" component="img" height={40} mr={2} src={logo} />
        <Typography variant="h3">ГУВМ МВД России</Typography>
      </Box>
      <Box
        bgcolor="#F4F5F7"
        borderRadius={4}
        flexShrink={0}
        maxWidth={576}
        mr={2}
        pb={2}
        px={2}
      >
        <Toolbar>
          <Box alignSelf="center" flexGrow={1}>
            {/* FIXME: to locales */}
            <Typography variant="h4">Выполненные проверки</Typography>
          </Box>
          {/* FIXME: to locales */}
          <Tooltip title="Запросить данные из ГУВМ МВД России">
            <IconButton disabled={loading} edge="end" onClick={handleClick}>
              <RenewIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Box bgcolor="white" borderRadius={4} maxHeight={360} overflow="auto">
          {fmsPassportChecks.map(
            ({ checkedPassportNumber, createdAt, id, status }) => (
              <React.Fragment key={id}>
                <ListItem>
                  <ListItemIcon>{ICON[status] || status}</ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id={`passportCheckStatus.${status}.message`}
                        values={{
                          number: (
                            <Box component="span" fontWeight={500}>
                              {checkedPassportNumber}
                            </Box>
                          ),
                        }}
                      />
                    }
                    secondary={
                      DateTime.fromISO(createdAt)
                        .toLocaleString(DateTime.DATETIME_SHORT)
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

// checkedPassportNumber: "5307 634718" id: "6" number: "634718" series: "53 07" status:
// "inactive" __typename: "FmsPassportCheck"
