import { gql } from '@apollo/client';

import { FEDRESURS_PROCESS_FRAGMENT } from '../fragments';

export const FEDRESURS_START_FETCHING_PROCESS = gql`
  mutation FedresursStartFetchingProcess($entityId: ID!) {
    fedresursStartFetchingProcess(entityId: $entityId) {
      fedresursFetchingProcess {
        ...FedresursProcessFragment
      }
    }
  }
  ${FEDRESURS_PROCESS_FRAGMENT}
`;