import { gql } from '@apollo/client/core';

export const GET_INVOICE_DEFAULT_VALUES = gql`
  query InvoiceDefaultValues (
    $targetId: ID!
    $targetType: InvoiceTargetType!
  ) {
    invoiceDefaultValues (
      targetId: $targetId
      targetType: $targetType
    ) {
      number
    }
  }
`;