import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Chip } from '@material-ui/core';

import { FilterStateField } from './helpers';
import { TasksFilterContext } from '../TasksFilterContext';

export default function TasksFilterState() {
  const { state, updateState } = useContext(TasksFilterContext);
  const [values, setValues] = useState(Object.entries(state));

  const handleResetFilterKey = useCallback((fieldName) => () => {
    const currentState = { ...state };

    delete currentState[fieldName];
    updateState(currentState);
  }, [state, updateState]);

  useEffect(() => setValues(Object.entries(state)), [state]);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, paddingTop: "16px", paddingBottom: "16px", }}>
      {values.map(([fieldName, value]) => (
        <Chip
          key={fieldName}
          label={<FilterStateField fieldName={fieldName} value={value} />}
          onDelete={handleResetFilterKey(fieldName)}
          variant="outlined"
        />
      ))}
    </Box>
  );
}