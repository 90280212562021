import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  CircularProgress,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import _pick from 'lodash.pick';
import qs from 'querystringify';
import { FormattedDate } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';

import CurrencyFormatter from 'components/CurrencyFormatter';
import DelayedRender from 'components/DelayedRender';
import ErrorAlert from 'components/ErrorAlert';

import DetailsOrder from './DetailsOrder';
import FormattedCurrency from "../../../components/FormattedCurrency";
import { GET_BANK_STATEMENT_DATA } from '../graphql/queries/bankStatementData';

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ row, value }) => (
      <Box color={row.income ? '#22a053' : undefined}>
        <Typography variant="h5">
          {row.income ? '+' : '−'}
          <CurrencyFormatter value={value} />
        </Typography>
      </Box>
    )}
    {...props}
  />
);

const DateFormatter = ({ value }) => (
  <FormattedDate day="numeric" month="numeric" value={value} year="numeric" />
);

DateFormatter.propTypes = {
  value: PropTypes.string,
};
const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

DescriptionProvider.propTypes = {
  row: PropTypes.shape({
    income: PropTypes.any,
    payer: PropTypes.any,
    recipient: PropTypes.any,
  }),
  value: PropTypes.any,
};
function DescriptionProvider({ row, value }) {
  return (
    <Box my={-2} whiteSpace="normal">
      <Typography variant="body2">
        {row.income ? row.payer : row.recipient}
      </Typography>
      <Typography color="textSecondary" noWrap variant="body2">
        {value}
      </Typography>
    </Box>
  );
}

const DescriptionTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DescriptionProvider} {...props} />
);

const DEFAULT_LIMIT = 25;
const DEFAULT_SORT_FIELD = 'order_date';
const DEFAULT_SORT_ORDER = 'desc';

export function Loading() {
  return (
    <Box
      bgcolor="rgba(255, 255, 255, .6)"
      height="100%"
      left={0}
      position="absolute"
      top={0}
      width="100%"
    >
      <Box
        fontSize={20}
        left="calc(50% - 10px)"
        position="absolute"
        top="calc(45% - 10px)"
      >
        <CircularProgress />
      </Box>
    </Box>
  );
}

export const FILTERS = [
  'payerCategory',
  'recipientCategory',
  'orderDate',
  'periodStart',
  'periodEnd',
  'operation',
  'payerTin',
  'recipientTin',
  'tin',
  'titleAndPurpose',
  'payerId',
  'recipientId',
];

GridOrders.propTypes = {
  bankStatementId: PropTypes.any,
  disabledEdit: PropTypes.bool,
  filter: PropTypes.shape({
    payerTin: PropTypes.string,
    recipientTin: PropTypes.string,
    titleAndPurpose: PropTypes.string,
  }),
};

export default function GridOrders({ bankStatementId, disabledEdit }) {
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [
    fetchOrders,
    { data, error, loading },
  ] = useLazyQuery(GET_BANK_STATEMENT_DATA);

  const [rows, setRows] = useState([]);
  const [columns] = useState([
    { name: 'orderDate', title: 'Дата' },
    { name: 'paymentPurpose', title: 'Контрагент и назначение платежа' },
    { name: 'number', title: 'Номер платежа' },
    { name: 'amount', title: 'Сумма' },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSizes] = useState([10, 25, 50]);
  const [tableColumnExtensions] = useState([
    { columnName: 'orderDate', width: 100 },
    { columnName: 'paymentPurpose', width: 'auto' },
    { columnName: 'number', width: 'auto' },
    { columnName: 'amount', align: 'right', width: 128 },
  ]);
  const [currencyColumns] = useState(['amount']);
  const [dateColumns] = useState(['orderDate']);
  const [descriptionColumns] = useState(['paymentPurpose']);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['number']);
  const [selection, setSelection] = useState([]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'paymentPurpose', sortingEnabled: false },
    { columnName: 'number', sortingEnabled: false },
  ]);

  //
  // ─── DEFAULT VALUES ───────────────────────────────────────────────────────
  //

  const { currentPage, pageSize, searchValue, sorting } = useMemo(() => {
    const query = qs.parse(location.search);

    return {
      searchValue: query.titleAndPurpose || '',
      currentPage: query.page ? Number(query.page) - 1 : 0,
      sorting: [
        {
          columnName: query.sort_by || DEFAULT_SORT_FIELD,
          direction: query.order || DEFAULT_SORT_ORDER,
        },
      ],
      pageSize: query.limit ? Number(query.limit) : DEFAULT_LIMIT,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [allAmounts, setAllAmounts] = useState({
    totalCredit: 0,
    totalDebit: 0,
    totalCount: 0,
  })

  useEffect(() => {
    if (data) {
      const {
        bankStatementAccount: { filteredOrders },
      } = data;
      setRows(filteredOrders.orders.collection);
      setAllAmounts({
        total: filteredOrders.totals.total,
        totalCredit: filteredOrders.totals.totalCredit,
        totalDebit: filteredOrders.totals.totalDebit,
        totalCount: filteredOrders.orders.metadata.totalCount,
      });
      setTotalCount(filteredOrders.orders.metadata.totalCount);
      if (filteredOrders.orders.metadata.currentPage > filteredOrders.orders.metadata.totalPages) {
        const query = qs.parse(location.search);
        const nextQuery = {
          ...query,
          page: filteredOrders.orders.metadata.totalPages || 1,
        };
        navigate(qs.stringify(nextQuery, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (mobile) {
      setHiddenColumnNames(['paymentPurpose']);
    } else {
      setHiddenColumnNames(['number']);
    }
  }, [mobile]);

  useEffect(() => {
    const parsedQuery = qs.parse(location.search);

    const { limit, order, page, sort_by, ...filter } = _pick(parsedQuery, [
      'limit',
      'order',
      'page',
      'sort_by',
      ...FILTERS,
    ]);

    fetchOrders({
      variables: {
        accountId: bankStatementId,
        page: Number(page || 1),
        limit: Number(limit || DEFAULT_LIMIT),
        filter: Object.keys(filter).length ? filter : undefined,
        sort: {
          field: sort_by || DEFAULT_SORT_FIELD,
          order: order || DEFAULT_SORT_ORDER,
        },
      },
    });
  }, [bankStatementId, fetchOrders, location.search]);

  function updateSearchLocation(params) {
    const parsedQuery = qs.parse(location.search);

    const query = {
      ...parsedQuery,
      ...params,
    };

    navigate(qs.stringify(query, true));
  }

  //
  // ─── SEARCH INPUT CHANGE ──────────────────────────────────────────────────
  //

  const debounced = useDebouncedCallback((value) => {
    const parsedQuery = qs.parse(location.search);

    const { titleAndPurpose, ...restQuery } = parsedQuery;
    const nextQuery = {
      ...restQuery,
      ...(value ? { titleAndPurpose: value } : {}),
    };

    if (Object.keys(nextQuery).length) {
      nextQuery.page = 1;
      navigate(qs.stringify(nextQuery, true));
    } else {
      navigate(location.pathname);
    }
  }, 500);

  function handleValueChange(value) {
    debounced.callback(value);
  }

  function handleSelectionChange(selectedRows = []) {
    setSelection(selectedRows.filter((row) => !selection.includes(row)));
  }

  function handleDrawerClose() {
    setSelection([]);
  }

  // Pagination handlers
  function handleCurrentPageChange(page) {
    updateSearchLocation({ page: page + 1 });
  }

  function handlePageSizeChange(value) {
    updateSearchLocation({ limit: value });
  }

  // Sorting handlers
  function handleSortingChange(sortingChanges) {
    // Изменение дефолтного ордера сортировки с 'asc' на 'desc';
    const processedSortingChanges = sortingChanges.map(
      ({ columnName, direction }) => ({
        columnName,
        direction: columnName === sorting[0].columnName ? direction : 'desc',
      })
    );

    // Подготовка данных для location.search
    const [sortingConfig] = processedSortingChanges.map(
      ({ columnName, direction }) => ({
        sort_by: columnName,
        order: direction,
      })
    );

    updateSearchLocation(sortingConfig);
  }

  return (
    <DelayedRender>
      <Box position="relative">
        <Box>
          <Box sx={{ display: 'flex', }}>
            Всего операций:
            <Typography sx={{fontWeight: '600', marginLeft: '6px', marginRight: '6px'}}>{allAmounts.totalCount}</Typography>
            платежей
          </Box>

          <Box sx={{ display: 'flex', }}>
            Поступлений: <Typography sx={{color: 'green', fontWeight: '600', marginLeft: '6px'}}>+<FormattedCurrency value={allAmounts.totalDebit} /></Typography>.
            Списаний: <Typography sx={{color: 'red', fontWeight: '600', marginLeft: '6px'}}>-<FormattedCurrency value={allAmounts.totalCredit} /></Typography>.
            Общая сумма операций:
            <Typography sx={{color: allAmounts.totalDebit - allAmounts.totalCredit < 0 ? 'red' : 'green', fontWeight: '600', marginLeft: '6px'}}>
              {allAmounts.totalDebit - allAmounts.totalCredit >= 0 && ("+")}
              <FormattedCurrency value={allAmounts.totalDebit - allAmounts.totalCredit} />
            </Typography>.
          </Box>
        </Box>
        <Grid columns={columns} rows={rows}>
          <CurrencyTypeProvider for={currencyColumns} />
          <DateTypeProvider for={dateColumns} />
          <DescriptionTypeProvider for={descriptionColumns} />
          <SortingState
            columnExtensions={sortingStateColumnExtensions}
            defaultSorting={sorting}
            onSortingChange={handleSortingChange}
          />
          <PagingState
            currentPage={currentPage}
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onCurrentPageChange={handleCurrentPageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          <SearchState
            defaultValue={searchValue}
            onValueChange={handleValueChange}
          />
          <CustomPaging totalCount={totalCount} />
          <SelectionState
            onSelectionChange={handleSelectionChange}
            selection={selection}
          />
          <Table columnExtensions={tableColumnExtensions} />
          <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
          <TableHeaderRow showSortingControls />
          <TableSelection
            highlightRow
            selectByRowClick
            showSelectionColumn={false}
          />
          <PagingPanel pageSizes={pageSizes} />
          <Toolbar />
          <SearchPanel
            color="secondary"
            messages={{ searchPlaceholder: 'Поиск...' }}
          />
          {error && (
            <ErrorAlert
              message={error.message}
              title="Ошибка при выполнении запроса"
            />
          )}
          {loading && <Loading />}
        </Grid>
      </Box>
      <Drawer
        ModalProps={{ disableEnforceFocus: true }}
        anchor="right"
        onClose={handleDrawerClose}
        open={selection.length ? true : false}
      >
        <DetailsOrder disabledEdit={disabledEdit} onClose={handleDrawerClose} row={rows[selection[0]]} />
      </Drawer>
    </DelayedRender>
  );
}
