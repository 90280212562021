import { gql } from '@apollo/client/core';

export const FINMONITORING_CHANGE_MESSAGE_STATUS_TO_REJECTED = gql`
  mutation FinmonitoringChangeReportingMessageStatusToRejected(
    $messageId: ID!
  ) {
    finmonitoringChangeReportingMessageStatusToRejected(
      finmonitoringReportingMessageId: $messageId
    ) {
      finmonitoringReportingMessage {
        id
        sentAt
        state
        updatedAt
      }
    }
  }
`;