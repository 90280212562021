import { gql } from '@apollo/client';

export const GET_CONTACT_CHECK_PROCESSES = gql`
  query GetContactCheckProcesses($contactId: ID!) {
    contact(contactId: $contactId) {
      id
      fsspCheckProcesses {
        id
        createdAt
        finishedAt
        itemsCount
        state
        unpaidItemsCount
        unpaidSum
      }
      fmsPassportChecks {
        checkedPassportNumber
        id
        number
        series
        status
        createdAt
      }
      criminalActivityCheckProcesses {
        id
        createdAt
        failedAt
        finishedAt
        startedAt
        contactData {
          id
          passportNumber
          passportSeries
        }
        state
        financialFraud
        terrorism
        weaponDistribution
      }
      creditHistoryFetchProcesses {
        id
        state
        createdAt
        startedAt
        finishedAt
        failedAt
        summary {
          activeCreditsCount
          activeCreditsSum
        }
      }
    }
  }
`;