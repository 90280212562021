import React, {useEffect, useMemo, useState} from 'react';

import { Box, Typography } from '@material-ui/core';
import {navigate, useLocation} from "@reach/router";
import { pickBy, identity } from "lodash";
import qs from "querystringify";
import {useDebouncedCallback} from "use-debounce";

import { FinmonitoringProvider } from './FinmonitoringContext';
import MessageTypesStatusBar from './MessageTypesStatusBar';
import TableFinmonitoringReporting from './TableFinmonitoringReporting';
import FinmonitoringFilter from './TableFinmonitoringReporting/FinmonitoringFilter';
import Search from "../../components/Search";
import {SEARCH_DEBOUNCE} from "../../core/constants/searchDebounce";

export default function PageFinmonitoringReporting() {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const debounced = useDebouncedCallback(value => {
    handleSearchChange(value)
  }, SEARCH_DEBOUNCE)

  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(parsedQuery.q || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchChange = (value => {
    const query = pickBy({ ...parsedQuery, q: value.trim() }, identity);
    const search = qs.stringify(query, true);

    navigate(search);
  })

  return (
    <Box
      sx={{
        mb: 8,
        px: 3,
        py: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FinmonitoringProvider>
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 5 }}>
          <Typography gutterBottom variant="h2">
            Отчетность в Финмониторинг
          </Typography>
          <FinmonitoringFilter />
        </Box>

        <Search
          onChange={debounced.callback}
          placeholder={"Инн или название лизингополучателя"}
          value={search || parsedQuery.q}
        />

        <MessageTypesStatusBar />

        <Box sx={{ flex: 0.999, minHeight: 0 }}>
          <Box
            sx={{
              border: '8px solid whitesmoke',
              borderRadius: '8px',
              borderTopLeftRadius: 0,
              height: 1,
            }}
          >
            <Box height={1} px={3}>
              <TableFinmonitoringReporting />
            </Box>
          </Box>
        </Box>
      </FinmonitoringProvider>
    </Box>
  );
}
