import React from 'react';

import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageTasks from './PageTasks';

export default function TasksRoutes() {
  return (
    <Box component={Router} sx={{ height: '100%' }}>
      <PageTasks path="/" />
    </Box>
  );
}