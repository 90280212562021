import PropTypes from "prop-types";
import React from 'react';

import {
  Box,
  Link,
  Typography,
  Tooltip
} from "@material-ui/core";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { DATE_TIME_FORMAT_VIEW } from "../../../core/constants/Formats";

ShipmentsDocument.propTypes = {
  row: PropTypes.object,
  showErrors: PropTypes.func,
};

export default function ShipmentsDocument({ row, showErrors }) {
  const { createdAt, id } = row.order;
  
  return (
    <Box key={id}>
      {id}

      <Box sx={{ fontSize: '12px', display: 'flex', }}>
        <Typography sx={{ color: row.status === 'REJECTED' ? '#ec6258' : '#1FCAD4', fontWeight: '700' }}>
          <FormattedMessage id={'shop.order.supply.status.' + row.status} />
        </Typography>
        &nbsp;
      </Box>

      <Box>
        {row?.shopSupplyUpds?.length > 0 && row?.shopSupplyUpds.map(doc => (
          <Box key={doc?.id}>
            <Tooltip title={doc?.assetOriginalFilename}>
              <Link
                href={doc?.assetUrl}
                target={"_blank"}>
                Просмотреть
              </Link>
            </Tooltip>
          </Box>
        ))}

        <Box sx={{ fontSize: '12px', whiteSpace: "normal" }}>
          Описание: {row?.shopSupplyUpds[0]?.explanation || "---"}
        </Box>
      </Box>

      <Box sx={{ color: '#6E7884', fontSize: '12px', fontWeight: 500 }}>
        Загружен: {dayjs(createdAt).format(DATE_TIME_FORMAT_VIEW)}
      </Box>
    </Box>
  )
}