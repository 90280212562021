import { gql } from '@apollo/client';

export const ENTITY_COMPLETE_CONTACT_QUESTIONNAIRE = gql`
  mutation EntityCompleteContactQuestionnaire($questionnaireId: ID!) {
    entityCompleteContactQuestionnaire(contactEntityQuestionnaireId: $questionnaireId) {
      contactEntityQuestionnaire {
        id
      }
    }
  }
`;