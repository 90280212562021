import { gql } from '@apollo/client/core';

export const DELETE_SCHEDULE_ITEM = gql`
  mutation FinanceDeleteScheduleItem($scheduleId: ID!, $key: String!) {
    financeDeleteScheduleItem(scheduleId: $scheduleId, itemKey: $key) {
      schedule {
        id
        items {
          key
        }
      }
    }
  }
`;