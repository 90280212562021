import { gql } from '@apollo/client';

export const ENTITY_CREATE_CONTACT_QUESTIONNAIRE = gql`
  mutation EntityCreateContactQuestionnaire($entityId: ID!) {
    entityCreateContactQuestionnaire(entityId: $entityId) {
      contactEntityQuestionnaire {
        data
      }
    }
  }
`;