import PropTypes from 'prop-types';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Divider } from '@material-ui/core';

import DelayedRender from 'components/DelayedRender';

import FilteredOrdersDrawer from './DrawerOrders';
import WidgetTopCounterparties from './WidgetTopCounterparties';
import { GET_BANK_ACCOUNT_TOPS } from '../graphql/queries/bankAccountTops';

TabCounterparties.propTypes = {
  bankStatementId: PropTypes.string,
  disabledEdit: PropTypes.bool,
};

export default function TabCounterparties({ bankStatementId, disabledEdit }) {
  const { data, error, loading } = useQuery(GET_BANK_ACCOUNT_TOPS, {
    variables: { accountId: bankStatementId },
    errorPolicy: 'all',
  });

  if (error) {
    return error.message;
  }

  if (loading) {
    return 'Загрузка...';
  }

  const { topPayers, topRecipients } = data.bankStatementAccount;

  return (
    <>
      <DelayedRender>
        <Box display="flex" flexWrap="wrap" mt={4}>
          <Box maxWidth={520} pr={{ lg: 3 }} width={{ xs: 1, lg: 1 / 2 }}>
            <WidgetTopCounterparties
              bankStatementId={bankStatementId}
              disabledEdit={disabledEdit}
              operationsType="income"
              topCompanies={topPayers}
            />
          </Box>
          <Divider
            component={Box}
            display={{ xs: 'none', lg: 'block' }}
            flexItem
            orientation="vertical"
          />
          <Box maxWidth={520} pl={{ lg: 3 }} width={{ xs: 1, lg: 1 / 2 }}>
            <WidgetTopCounterparties
              bankStatementId={bankStatementId}
              disabledEdit={disabledEdit}
              operationsType="outcome"
              topCompanies={topRecipients}
            />
          </Box>
        </Box>
      </DelayedRender>

      <FilteredOrdersDrawer disabledEdit={disabledEdit} from="counterparties/" />
    </>
  );
}
