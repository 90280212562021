import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_UPLOAD_ORIGINAL = gql`
  mutation SourceDocumentsUploadOrigial($file: Upload!, $documentId: ID!) {
    sourceDocumentsUploadOriginal(file: $file, sourceDocumentId: $documentId) {
      sourceDocument {
        id
        documentUrl
        documentFilename
        isOriginalReceived
        recognizingProcess {
          status
        }
        status
        createdAt
      }
    }
  }
`;