import React, {useState} from 'react';

import {Box, Typography} from "@material-ui/core";

import {OrdersProvider} from "./OrdersContext";
import OrdersFilters from "./OrdersFilters";
import OrdersSort from "./OrdersSort";
import OrdersTable from "./OrdersTable";
import OrdersTabs from "./OrdersTabs";

export default function PageOrders() {
  const [infTabs, setInfTabs] = useState({});

  const updateInfTabs = (val) => {
    setInfTabs(val)
  }

  return (
    <OrdersProvider>
      <Box sx={{ backgroundColor: '#f3f4f5', paddingBottom: '20px',}}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 24px', backgroundColor: '#ffffff', height: '100px' }}>
          <Typography gutterBottom sx={{ fontSize: '32px !important', margin: '0 !important', width: '140px', }} variant="h2">
            Заказы
          </Typography>

          <OrdersFilters />
        </Box>

        <OrdersTabs
          infTabs={infTabs}
        />

        <OrdersSort
          infTabs={infTabs}
        />

        <Box sx={{ height: '100%', width: '100%' }}>
          <Box sx={{
            display: 'flex',
            margin: '0 24px 24px',
            padding: '24px',
            background: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.12)'
          }}>
            <OrdersTable
              updateInfTabs={updateInfTabs}
            />
          </Box>
        </Box>
      </Box>
    </OrdersProvider>
  );
}