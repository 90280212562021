import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'utils/dateTimeFormatters';

CellContract.propTypes = {
  row: PropTypes.shape({
    contractDate: PropTypes.string,
    contractNumber: PropTypes.string,
    kind: PropTypes.string,
  }),
};
export default function CellContract({ row }) {
  if (!row) {
    return null;
  }
  const { contractDate, contractNumber, kind } = row;

  return (
    <>
      <Typography
        component="span"
        sx={{ fontFamily: 'Open Sans', fontWeight: 500 }}
        variant="inherit"
      >
        {contractNumber}
      </Typography>{' '}
      от{' '}
      <Typography
        component="span"
        sx={{ fontFamily: 'Open Sans', fontWeight: 500 }}
        variant="inherit"
      >
        {formatDate(contractDate)}
      </Typography>
      <Typography component="div" variant="caption">
        <FormattedMessage id={`fedresurs.messageType.${kind}`} />
      </Typography>
    </>
  );
}
