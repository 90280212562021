import React from 'react';

import { Router } from '@reach/router';

import PageSupplyingOrderItem from './PageSupplyingOrderItem';
import PageSupplyingOrderServiceAcceptanceCertificate from './PageSupplyingOrderServiceAcceptanceCertificate';

export default function SupplyingOrdersRoutes() {
  return (
    <Router>
      <PageSupplyingOrderItem path=":supplyingOrderId/documents/create_specification" />
      <PageSupplyingOrderServiceAcceptanceCertificate path=":supplyingOrderId/documents/create_service_acceptance_certificate" />
    </Router>
  );
}
