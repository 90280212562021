import { gql } from '@apollo/client';

export const GET_BANK_STATEMENT_DATA = gql`
  query GetBankStatementData(
    $accountId: ID!
    $limit: Int
    $page: Int
    $filter: BankStatementOrdersFilter
    $sort: BankStatementOrdersSort
  ) {
    bankStatementAccount(accountId: $accountId) {
      id
      filteredOrders(limit: $limit, page: $page, filter: $filter, sort: $sort) {
        totals {
          total
          totalCredit
          totalDebit
        }
        orders {
          metadata {
            currentPage
            limitValue
            totalCount
            totalPages
          }
          collection {
            amount
            code
            orderDate
            number
            okato
            paymentPurpose
            paymentType
            receivedDate
            type
            writeOffDate
            income
            # payer
            payer
            payerId
            payerAccount
            payerBank
            payerBic
            payerKpp
            payerTin
            payerCheckingAccount
            # recipient
            recipient
            recipientId
            recipientAccount
            recipientBank
            recipientBic
            recipientKpp
            recipientTin
            recipientCheckingAccount
          } 
        }
      }
      
    }
  }
`;