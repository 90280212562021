// IDEA: Sticky table https://codesandbox.io/s/goofy-river-2n40y?file=/sticky.js
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSummary,
  RowDetailState,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableGroupRow,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, IconButton, Link, Popover, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import _get from 'lodash.get';
import _uniq from 'lodash.uniq';
import { DateTime } from 'luxon';
import transformUrl from 'transform-url';

import FormattedCurrency from 'components/FormattedCurrency';
import { TableLoader } from 'components/TableLoader';
import { FSSP_FIND_BY_IP_URL, FSSP_LEGAL_ACT_URL } from 'core/constants';

import { GET_FSSP_CHECK_PROCESS } from './graphql/queries/fsspCheckProcess';

function GridRootComponent(props) {
  return <Box component={Grid.Root} {...props} maxHeight="inherit" />;
}

function TableComponent(props) {
  return <Box component={Table.Table} {...props} px="12px" />;
}

function InfoFormatter({ row }) {
  const { department, details, exeProduction } = row;
  const [ipNumber] = exeProduction.match(/\d+\/\d{2}\/\d+(-ДСП)?-ИП/) ||
    exeProduction.match(/\d+\/\d{2}\/\d{2}\/\d{2}/) || [''];

  const [, ipRestString] = exeProduction.split(ipNumber);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box maxWidth={412} p={4}>
          <Typography paragraph variant="h5">
            <Link
              href={encodeURI(`${FSSP_FIND_BY_IP_URL}${ipNumber}`)}
              target="_blank"
            >
              {ipNumber}
            </Link>
            {ipRestString}
          </Typography>
          <Typography component="div" gutterBottom variant="h6">
            Реквизиты исполнительного документа
          </Typography>
          <Typography gutterBottom>{details}</Typography>
          <Typography component="div" gutterBottom variant="h6">
            Отдел судебных приставов
          </Typography>
          <Typography>{department}</Typography>
        </Box>
      </Popover>
    </>
  );
}
InfoFormatter.propTypes = {
  row: PropTypes.shape({
    department: PropTypes.string,
    details: PropTypes.string,
    exeProduction: PropTypes.string,
  }).isRequired,
};

function InfoTypeProvider(props) {
  return <DataTypeProvider formatterComponent={InfoFormatter} {...props} />;
}

function DateFormatter({ value }) {
  if (!value) {
    return null;
  }
  return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
}
DateFormatter.propTypes = { value: PropTypes.string };

function DateTypeProvider(props) {
  return <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
}

function CurrencyFormatter({ value }) {
  if (!value) {
    return 0;
  }
  return <FormattedCurrency hideCurrency value={value} />;
}
CurrencyFormatter.propTypes = { value: PropTypes.number };

function CurrencyTypeProvider(props) {
  return <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;
}

function IpEndFormatter({ value }) {
  if (!value) {
    return null;
  }
  const [ipEndDate, ipEndArticle, ipEndPart, ipEndParagraph] = value.split(',');

  return (
    <>
      <Typography noWrap variant="body2">
        {DateTime.fromISO(ipEndDate).toLocaleString(DateTime.DATE_SHORT)}
      </Typography>
      <Typography noWrap variant="caption">
        <Link
          href={
            transformUrl(FSSP_LEGAL_ACT_URL, {
              article: `statja-${ipEndArticle.trim()}`,
            })
          }
          target="_blank"
        >
          ст. {ipEndArticle}, ч. {ipEndPart}, п. {ipEndParagraph}
        </Link>
      </Typography>
    </>
  );
}
IpEndFormatter.propTypes = { value: PropTypes.string };

function IpEndProvider(props) {
  return <DataTypeProvider formatterComponent={IpEndFormatter} {...props} />;
}

FsspTable.propTypes = {
  id: PropTypes.string,
  pendingMessage: PropTypes.string,
};
export default function FsspTable({ id, pendingMessage }) {
  const [rows, setRows] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);

  function onCompleted(data) {
    const items = _get(data, 'fsspCheckProcess.items', []);
    const regions = items.map(({ region }) => region);
    setExpandedGroups(_uniq(regions));
    setRows(items);
  }

  function onError(error) {
    alert(`Произошла ошибка при получении данных ${error.message}`);
  }

  const { data, loading } = useQuery(GET_FSSP_CHECK_PROCESS, {
    variables: { id },
    onCompleted,
    onError,
  });

  const checkProcessState = _get(data, 'fsspCheckProcess.state');

  // columns
  const [columns] = useState([
    {
      name: 'region',
      title: 'Регион',
      getCellValue: (row) => _get(row, 'region.name'),
    },
    { name: 'exeProductionDate', title: 'Дата' },
    {
      name: 'subject',
      title: 'Предмет исполнения',
      getCellValue: (row) => _get(row, 'subject') || '---',
    },
    { name: 'info', title: ' ' },
    { name: 'ipEnd', title: 'Прекращено' },
    { name: 'debtAmount', title: 'Задолженность, ₽' },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'region', width: 140, wordWrapEnabled: true },
    { columnName: 'exeProductionDate', width: 100 },
    { columnName: 'subject', wordWrapEnabled: true },
    { columnName: 'info', width: 56 },
    { columnName: 'ipEnd', width: 124 },
    { columnName: 'debtAmount', align: 'right', width: 160 },
  ]);

  // providers
  const [dateColumns] = useState(['exeProductionDate']);
  const [infoColumns] = useState(['info']);
  const [currencyColumns] = useState(['debtAmount']);
  const [ipEndColumns] = useState(['ipEnd']);

  // sorting
  const [sorting, setSorting] = useState([{
    columnName: 'exeProductionDate',
    direction: 'asc',
  }]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'subject', sortingEnabled: false },
    { columnName: 'ipEnd', sortingEnabled: false },
  ]);

  // summary
  const [totalSummaryItems] = useState([
    { columnName: 'exeProductionDate', type: 'count' },
    { columnName: 'debtAmount', type: 'sum' },
  ]);

  // grouping
  const [grouping] = useState([{ columnName: 'region' }]);
  const [groupSummaryItems] = useState([
    {
      columnName: 'debtAmount',
      type: 'sum',
      showInGroupFooter: false,
      alignByColumn: true,
    },
  ]);

  return (
    <Box bgcolor="#f4f5f7" borderRadius={4} p={2}>
      <Box
        bgcolor="white"
        borderRadius={4}
        maxHeight="88vh"
        position="relative"
      >
        <Grid columns={columns} rootComponent={GridRootComponent} rows={rows}>
          {/* Providers */}
          <DateTypeProvider for={dateColumns} />
          <InfoTypeProvider for={infoColumns} />
          <CurrencyTypeProvider for={currencyColumns} />
          <IpEndProvider for={ipEndColumns} />
          {/* Sorting */}
          <SortingState
            columnExtensions={sortingStateColumnExtensions}
            onSortingChange={setSorting}
            sorting={sorting}
          />
          <IntegratedSorting />
          {/* Grouping */}
          <GroupingState
            expandedGroups={expandedGroups}
            grouping={grouping}
            onExpandedGroupsChange={setExpandedGroups}
          />
          <IntegratedGrouping />

          {/* Summary */}
          <SummaryState
            groupItems={groupSummaryItems}
            totalItems={totalSummaryItems}
          />
          <IntegratedSummary />

          {/* Table */}
          <Table
            columnExtensions={tableColumnExtensions}
            messages={{
              noData:
                checkProcessState === 'failed'
                  ? 'Запрос завершен с ошибкой'
                  : checkProcessState !== 'finished'
                    ? 'Запрос выполняется...'
                    : 'Задолженность не найдена',
            }}
            tableComponent={TableComponent}
          />
          <TableHeaderRow showSortingControls />
          <TableGroupRow messages={{ sum: 'По региону' }} />
          <TableSummaryRow messages={{ count: 'Записей', sum: 'Итого' }} />

          {/* Row detail */}
          <RowDetailState />
        </Grid>
        {loading && <TableLoader />}
      </Box>
    </Box>
  );
}
