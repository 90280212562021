import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Box, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { CATEGORY_DOCUMENTS, DocumentStatusMap} from './constants';
import { useAssignPermissions } from '../useAssignPermissions';

CellActions.propTypes = {
  row: PropTypes.object,
  selectAssignCallback: PropTypes.func,
  selectCheckRecognitionResult: PropTypes.func,
  selectPreviewCallback: PropTypes.func,
  selectShowOcrDataCallback: PropTypes.func,
  selectUploadOriginalCallback: PropTypes.func,
};

export default function CellActions({
  row,
  selectAssignCallback,
  selectCheckRecognitionResult,
  selectPreviewCallback,
  selectShowOcrDataCallback,
  selectUploadOriginalCallback,
}) {
  const canAssignDocument = useAssignPermissions();

  const defaultAction = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleAssign = useCallback((e) => {
    defaultAction(e);
    selectAssignCallback(row.id);
  }, [selectAssignCallback, row.id, defaultAction]);

  const handlePreview = useCallback((e) => {
    defaultAction(e);
    selectPreviewCallback(row.id);
  }, [selectPreviewCallback, row.id, defaultAction]);

  const handleShowOcrData = useCallback((e) => {
    defaultAction(e);
    selectShowOcrDataCallback(row?.recognizingProcess?.ocrData);
  }, [selectShowOcrDataCallback, row, defaultAction]);

  const handleCheckRecogitionResult = useCallback((e) => {
    defaultAction(e);
    selectCheckRecognitionResult(row.id);
  }, [selectCheckRecognitionResult, row.id, defaultAction]);

  const handleUploadOriginal = useCallback((e) => {
    defaultAction(e);
    selectUploadOriginalCallback(row.id);
  }, [selectUploadOriginalCallback, row.id, defaultAction]);

  const primaryButton = useMemo(() => {
    let showButtonAssign = false;

    switch (row?.type?.category) {
      case CATEGORY_DOCUMENTS.dispute:
        showButtonAssign = canAssignDocument?.DISPUTE
        break;

      case CATEGORY_DOCUMENTS.loanSupply:
        showButtonAssign = canAssignDocument?.LOAN_SUPPLY
        break;

      case CATEGORY_DOCUMENTS.applicationSupplyingOrder:
        showButtonAssign = canAssignDocument?.APPLICATION_SUPPLYING_ORDER
        break;

      default:
        showButtonAssign = false
    }

    if (Boolean(row?.recognizingProcess?.longTimeRecognizing)) {
      return (
        <Button
          disableElevation
          onClick={handleCheckRecogitionResult}
          sx={{ color: 'white', width: 105, height: 40, lineHeight: '15px' }}
          variant="contained"
        >
          Повторить<br />запрос
        </Button>
      );
    }

    if (row?.status === DocumentStatusMap.assigned &&
      !Boolean(row?.isOriginalReceived)) {
      return (
        <Button
          disableElevation
          onClick={handleUploadOriginal}
          sx={{ color: 'white', width: 105, height: 40, lineHeight: '15px' }}
          variant="contained"
        >
          Загрузить<br />оригинал
        </Button>
      );
    }

    if (row.status === DocumentStatusMap.pending && showButtonAssign) {
      return (
        <Button
          disableElevation
          onClick={handleAssign}
          sx={{ color: 'white', width: 105, height: 40 }}
          variant="contained"
        >
          Разнести
        </Button>
      );
    }

    return null;
  }, [
    row,
    canAssignDocument,
    handleAssign,
    handleCheckRecogitionResult,
    handleUploadOriginal,
  ]);

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ width: 105 }}>
        {primaryButton}
      </Box>

      <Box sx={{ width: 105 }}>
        <Button
          color="success"
          disableElevation
          onClick={handlePreview}
          sx={{ color: 'white', width: 105, height: 40 }}
          variant="contained"
        >
          Открыть
        </Button>
      </Box>

      <Box sx={{ width: 50 }}>
        {row?.recognizingProcess?.ocrData && row?.type?.recognizable && (
          <Button
            color="success"
            disableElevation
            onClick={handleShowOcrData}
            sx={{ color: 'white', minWidth: 36, width: 50, height: 40 }}
            variant="contained"
          >
            <VisibilityIcon fontSize="inherit" />
          </Button>
        )}
      </Box>
    </Box>
  );
}