import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { formatDate } from 'utils/dateTimeFormatters';

BodyResolution.propTypes = {
  author: PropTypes.shape({
    login: PropTypes.string,
    contact: PropTypes.shape({
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    }),
  }),
  createdAt: PropTypes.string,
  text: PropTypes.string,
};

export default function BodyResolution({ author, createdAt, text }) {
  return (
    <Box>
      <Typography color="primary" component="span" variant="h5">
        {author.contact.lastName}&nbsp;{author.contact.firstName}
      </Typography>
      ,&nbsp;&nbsp;
      <Typography color="textSecondary" component="span" variant="body2">
        {formatDate(createdAt, 'short_date_time')}
      </Typography>
      <Box mt={2}>
        <Typography paragraph variant="body2">
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
