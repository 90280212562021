import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import FormattedCurrency from 'components/FormattedCurrency';

import { calcPaymentScheduleTally } from '../utils';

function getColor(amount) {
  if (amount === 0) {
    return '#ec8e00';
  }
  if (amount < 0) {
    return '#F44336';
  }
  return '#009688';
}

function HighlightedAmount({ amount }) {
  return (
    <Box color={getColor(amount)} component="span">
      <FormattedCurrency hideCurrency value={amount} />
    </Box>
  );
}
HighlightedAmount.propTypes = {
  amount: PropTypes.number,
};

export default function ScheduleTally({ comparedItems, items }) {
  const tally = useMemo(() => calcPaymentScheduleTally(items), [items]);
  const comparedTally = useMemo(() => calcPaymentScheduleTally(comparedItems), [comparedItems]);

  const { baseAmount, commissionAmount, fullAmount, vatAmount } = tally;

  const {
    baseAmountDiff,
    commissionAmountDiff,
    fullAmountDiff,
    vatAmountDiff,
  } = comparedItems.length
    ? {
        fullAmountDiff: fullAmount - comparedTally.fullAmount,
        baseAmountDiff: baseAmount - comparedTally.baseAmount,
        commissionAmountDiff: commissionAmount - comparedTally.commissionAmount,
        vatAmountDiff: vatAmount - comparedTally.vatAmount,
      }
    : {};

  const amountComposition = [
    // FIXME: to locales
    { amount: baseAmount, diff: baseAmountDiff, caption: 'Основной долг' },
    { amount: commissionAmount, diff: commissionAmountDiff, caption: 'Финансирование' },
    { amount: vatAmount, diff: vatAmountDiff, caption: 'НДС' },
  ];

  return (
    <>
      <Box>
        <Typography variant="h3">
          <FormattedCurrency value={fullAmount} />
          <Box component="sup" lineHeight={1} ml={1} whiteSpace="nowrap">
            <HighlightedAmount amount={fullAmountDiff} />
          </Box>
        </Typography>
        <Typography color="textSecondary" component="div" variant="caption">
          Сумма лизингового платежа
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex">
        {amountComposition.map(({ amount, caption, diff }) => (
          <Box key={caption} mr={2}>
            <Typography variant="h6">
              <FormattedCurrency hideCurrency value={amount} />
              <Box component="sup" lineHeight={1} ml={1} whiteSpace="nowrap">
                <HighlightedAmount amount={diff} />
              </Box>
            </Typography>
            <Typography color="textSecondary" component="div" variant="caption">
              {caption}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

ScheduleTally.propTypes = {
  comparedItems: PropTypes.array,
  items: PropTypes.array,
};
