export const INDUSTRY_TYPES = {
  retail: 'Розничная и оптовая торговля',
  catering: 'Производство продуктов общественного питания',
  industrial: 'Промышленное оборудование',
  raw_materials: 'Обработка сырья',
  restaurants: 'Деятельность ресторанов',
  transport: 'Деятельность транспорта и связи',
  medicine: 'Медицинские услуги',
  beauty: 'Услуги салонов красоты, фитнес-центров',
  building: 'Строительство',
  other: 'Другое',
};

export const TAXATION_TYPES = {
  '6%': 'УСН 6%',
  '15%': 'УСН 15%',
  general: 'ОСНО',
  envd: 'ЕНВД',
  eshn: 'ЕСХН',
  trade_fee: 'Торговый сбор',
  patent: 'Патентная система',
};

export const ACCOUNTING_MAINTAINER_TYPES = {
  staff: 'В штате',
  outsourced: 'Аутсорс',
  ceo: 'Ген. Директор',
};

export const CUSTOMER_TYPES = {
  individuals: 'Физические лица',
  entities: 'Юридические лица',
  government: 'Государство и бюджетные организации',
};