import { gql } from '@apollo/client/core';

export const GET_SHOP_GOODS = gql`
query ShopGoods(
  $page: Int
  $limit: Int
  $sort: Sort
  $filter: ShopGoodFilter!
) {
  shopGoods(page: $page, limit: $limit, sort: $sort, filter: $filter) {
    tabs {
      count
      status
    }
    goods {
      collection {
        id
        shopUid
        status
        name
        description
        price
        createdAt
        priceWithDiscount
        vat
        vatPercent
        sellingPrice
        producedAt
        quality
        serialNumber
        shippedEquipment{
          displayEquipmentTypeName
          displaySupplyingOrderNumber
          displaySupplyingOrderUrl
          displaySupplierLegalName
          dispayLoanSupplyDate
          application {
            id
          }
        }
        storageAcceptance {
          displayDisputeNumber
          displayDisputeUrl
          dispayLoanLeaseAgreement
        }
      }

      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
}
`;