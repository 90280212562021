import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

const GET_INIT_DATA = gql`
  query GetInitData {
    currentUser {
      avatar
      id
      contact {
        firstName
        lastName
      }
      openedTasksCounter {
        today
        past
      }
      roles
    }
    sidebarElements {
      children {
        title
        url
      }
      title
      url
    }
  }
`;

const appDefaultData = {
  currentUser: null,
  sidebarElements: [],
}

export const AppContext = createContext({
  appData: appDefaultData,
  error: undefined,
  loading: false,
});

export const AppProvider = ({ children }) => {
  const { data, error, loading } = useQuery(GET_INIT_DATA);

  const [appData, setAppData] = useState(appDefaultData);

  useEffect(() => {
    setAppData((prev) => ({
      ...prev,
      currentUser: data?.currentUser || null,
      sidebarElements: data?.sidebarElements || [],
    }));
  }, [data]);

  return (
    <AppContext.Provider value={{ appData, error, loading }}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};