import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_ASSIGN_TO_DISPUTE = gql`
  mutation SourceDocumentsAssignToDispute($assignParams: SourceDocumentDisputeAssignParams!) {
    sourceDocumentsAssignToDispute(assignParams: $assignParams) {
      sourceDocument {
        id
      }
    }
  }
`;