import PropTypes from "prop-types";
import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  Breadcrumbs,
  Chip,
  Link,
  Skeleton,
  Typography,
} from '@material-ui/core';
import {
  emphasize,
  experimentalStyled as styled,
} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink, useLocation } from '@reach/router';
import gql from 'graphql-tag';
import _get from 'lodash.get';

const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[800],
  fontWeight: theme.typography.fontWeightRegular,
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: theme.palette.grey[300],
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: emphasize(theme.palette.grey[300], 0.12),
  },
}));

export default function BreadcrumbsNav() {
  const location = useLocation();

  const pathnames = location.pathname
    .replace(/\/$/, '')
    .split('/')
    .filter(Boolean);

  const breadcrumbs = useMemo(() => {
    const paths = pathnames.map(
      (value) => (value.match(/[0-9]+/) ? 'children' : value)
    );

    const getComponent = (value, url = undefined) => ({ url, value });

    const crumbs = pathnames.map((value, index) => {
      return _get(
        {
          applications: {
            component: getComponent('Заявки'),
            children: {
              component: getComponent(value),
            },
          },
          tasks: {
            component: getComponent('To Do'),
            children: {
              component: getComponent(value),
            },
          },
          deals: {
            component: getComponent('Сделки'),
            'fedresurs-reporting': {
              component: getComponent('Отчетность в Федресурс'),
            },
            'finmonitoring-reporting': {
              component: getComponent('Отчетность в Финмониторинг'),
            },
          },
          workspaces: {
            component: getComponent('Workspaces'),
            'incoming-documents': {
              component: getComponent('Входящие документы'),
            },
            'shop': {
              component: getComponent('Магазин'),
              leasing: {
                component: getComponent('Карта объекта лизинга'),
              },
              sku: {
                component: getComponent('Карта SKU'),
              },
              kit: {
                component: getComponent('Комплект'),
              },
              orders: {
                component: getComponent('Заявки Arenza.Renew'),

                'details': {
                  children: {
                    component: getComponent(`Заявка №${value}`),
                  },
                },
              },
            },
            'receipt-to-warehouse': {
              component: getComponent('Приемка на склад'),
              'goods-seizure-act': {
                component: getComponent(`Товары в акте изъятия`),
              },
              'seizure-act': {
                component: getComponent(`Акт изъятия`),
              },
              'parameters-warehouse': {
                component: getComponent(`Акт изъятия`),
              },
            },
          },
          contacts: {
            component: getComponent('Контакты'),
            children: {
              component: getComponent(<BreadcrumbContact id={value} />),
              'credit-history': {
                component: getComponent('Кредитная история'),
              },
            },
          },
          entities: {
            component: getComponent(
              'Компании',
              `${process.env.REACT_APP_HOST}/entities`,
            ),
            children: {
              component: getComponent(
                <BreadcrumbCompany id={value} />,
                `${process.env.REACT_APP_HOST}/entities/${value}`,
              ),
              bank_statements: {
                component: getComponent('Счета и выписки'),
                children: {
                  component: getComponent(value),
                },
              },
              questionnaires: {
                component: getComponent('Анкеты компании'),
                children: {
                  component: getComponent(`№ ${value}`),
                },
              },
            },
          },
          orders: {
            component: getComponent(
              'Заказы',
              `${process.env.REACT_APP_HOST}/orders`,
            ),
            children: {
              component: getComponent(value),
            },
          },
          collaborators: {
            component: getComponent(
              'Сотрудники',
              `${process.env.REACT_APP_HOST}/collaborators`,
            ),
            children: {
              component: getComponent(value),
            },
          },
        },
        [...paths.slice(0, index + 1), 'component'],
      );
    });

    return crumbs.filter(Boolean);
  }, [pathnames]);

  if (!pathnames.length) {
    return null;
  }

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      <StyledBreadcrumb
        component={Link}
        href={process.env.REACT_APP_HOST}
        icon={<HomeIcon fontSize="small" />}
        label="Главная"
        sx={{ pl: 1 }}
        underline="none"
      />
      {breadcrumbs.map(({ url, value }, index) => {
        const last = index === breadcrumbs.length - 1;
        const to = url ? url : `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography
            color="textPrimary"
            key={to}
            sx={{ fontSize: '14px', fontWeight: 500 }}
          >
            {value}
          </Typography>
        ) : (
          <StyledBreadcrumb
            component={url ? Link : RouterLink}
            href={to}
            key={to}
            label={value}
            to={to}
            underline="none"
          />
        );
      })}
    </Breadcrumbs>
  );
}

BreadcrumbCompany.propTypes = {
  id: PropTypes.string,
};

function BreadcrumbCompany({ id }) {
  const { data, error, loading } = useQuery(
    gql`
      query FetchEntityName($entityId: ID!) {
        entity(entityId: $entityId) {
          id
          shortLegalName
        }
      }
    `,
    { variables: { entityId: id } },
  );

  if (error) {
    return 'Ошибка';
  }

  if (loading) {
    return <Skeleton width={160} />;
  }

  return data?.entity.shortLegalName || '';
}

BreadcrumbContact.propTypes = {
  id: PropTypes.string,
};

function BreadcrumbContact({ id }) {
  const { data, error, loading } = useQuery(
    gql`
      query FetchContactName($contactId: ID!) {
        contact(contactId: $contactId) {
          id
          firstName
          middleName
          lastName
          fullName @client
        }
      }
    `,
    { variables: { contactId: id } },
  );

  if (error) {
    return 'Ошибка';
  }

  if (loading) {
    return <Skeleton width={160} />;
  }

  return data?.contact.fullName || '';
}
