import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormattedMessage } from "react-intl";

Dropdown.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  langPath: PropTypes.string,
  onChange: PropTypes.func,
};

export default function Dropdown({ items, label, langPath, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    if (onChange && typeof onChange === 'function') {
      onChange(items[index])
    }

    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex',  margin: '6px' }}>
        <Typography sx={{ marginRight: '6px' }}>{label}</Typography>
        <Typography onClick={handleClickListItem} sx={{ cursor: 'pointer' }}>
          {langPath ? (
            <FormattedMessage id={langPath + items[selectedIndex]} />
          ) : (
            items[selectedIndex]
          )}
          <ArrowDropDownIcon sx={{ position: 'absolute' }} />
        </Typography>
      </Box>

      <Menu
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox"
        }}
        anchorEl={anchorEl}
        id="lock-menu"
        onClose={handleClose}
        open={open}
      >
        {items.map((option, index) => (
          <MenuItem
            // disabled={index === 0}
            key={option}
            onClick={(event) => handleMenuItemClick(event, index)}
            selected={index === selectedIndex}
          >
            {langPath ? (
              <FormattedMessage id={langPath + option} />
            ) : (
              option
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
