import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import {
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import currency from 'currency.js';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import FormattedCurrency from 'components/FormattedCurrency';
import { InlineEdit } from 'components/InlineEdit';

import TimelineItemMenu from './TimelineItemMenu';
import CurrentScheduleItemMenu from '../components/CurrentScheduleItemMenu';
import DateInlinePicker from '../components/DateInlinePicker';
import {
  UPDATE_SCHEDULE_ITEM,
} from '../graphql/mutations/financeUpdateScheduleItem';

export default function TimelineItemContainer({
  meta,
  paymentData,
  scheduleId,
}) {
  const [updateItem] = useMutation(UPDATE_SCHEDULE_ITEM, {
    onError: (error) => {
      alert(`Произошла ошибка при сохранении записи ${error.message}`);
    },
  });
  const {
    baseAmount,
    commissionAmount,
    forfeitAmount,
    fullAmount,
    key,
    monthlyPaymentNumber,
    paid,
    startAt,
    type,
    vatAmount,
  } = paymentData;
  const { isEditable, isLast } = meta;
  const paymentDate = DateTime.fromISO(startAt);
  const { days: diffNowDays } = paymentDate.diffNow('days');

  const { months: diffNowMonth } = paymentDate.diffNow('months');

  function getColor() {
    if (paid) return '#4caf50';
    if (diffNowDays < -1) return 'red';
    if (diffNowMonth < 1) return '#E87F40';
    return '#bdbdbd';
  }

  const color = getColor();

  const handleChange = (fieldName) => (value) => {
    let item = {};

    if (fieldName === 'baseAmount') {
      item = {
        [fieldName]: currency(value).value,
        vatAmount: currency(
          (currency(value).value + commissionAmount) * 0.2
        ).value,
      };
    }

    if (fieldName === 'commissionAmount') {
      item = {
        [fieldName]: currency(value).value,
        vatAmount: currency(
          (baseAmount + currency(value).value) * 0.2
        ).value,
      };
    }

    if (fieldName === 'startAt') {
      item = {
        [fieldName]: value.toISODate(),
      };
    }

    updateItem({
      variables: {
        scheduleId,
        key,
        item,
      },
    });
  };

  return (
    <Box
      animate={{ opacity: 1 }}
      component={motion.div}
      display="flex"
      enter={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <Box flexGrow={1}>
        <TimelineItem>
          <TimelineOppositeContent style={{ flex: 'unset' }}>
            <Box width={96}>
              <Typography
                color="textSecondary"
                component="div"
                variant="body1"
              >
                {isEditable ? (
                  <DateInlinePicker
                    onChange={handleChange('startAt')}
                    value={startAt}
                  />
                ) : (
                  DateTime.fromISO(startAt).toLocaleString(DateTime.DATE_SHORT)
                )}
              </Typography>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Box
              sx={{
                bgcolor: color,
                borderRadius: '50%',
                color: 'white',
                fontSize: 13,
                fontWeight: 500,
                height: 24,
                lineHeight: '24px',
                my: 2,
                textAlign: 'center',
                width: 24,
              }}
            >
              {monthlyPaymentNumber}
            </Box>
            {!isLast && (
              <Box
                bgcolor={paid ? '#4caf50' : '#bdbdbd'}
                flexGrow={1}
                width={2}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Box alignItems="center" display="flex">
              <Box>
                <Box alignItems="baseline" display="flex">
                  <Typography style={{ fontWeight: 500 }} variant="body1">
                    <FormattedCurrency value={fullAmount} />
                  </Typography>
                  <Box mx="3px">=</Box>
                  {isEditable ? (
                    <Box maxWidth={80}>
                      <InlineEdit
                        ViewComponent={
                          <FormattedCurrency hideCurrency value={baseAmount} />
                        }
                        onChange={handleChange('baseAmount')}
                        value={baseAmount}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body2">
                      <FormattedCurrency hideCurrency value={baseAmount} />
                    </Typography>
                  )}
                  <Box mx="3px">+</Box>
                  {isEditable ? (
                    <Box maxWidth={80}>
                      <Typography component="div" variant="body2">
                        <InlineEdit
                          ViewComponent={
                            <FormattedCurrency
                              hideCurrency
                              value={commissionAmount}
                            />
                          }
                          onChange={handleChange('commissionAmount')}
                          value={commissionAmount}
                        />
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2">
                      <FormattedCurrency
                        hideCurrency
                        value={commissionAmount}
                      />
                    </Typography>
                  )}
                  <Box mx="3px">+</Box>
                  <Typography variant="body2">
                    <FormattedCurrency hideCurrency value={vatAmount} />
                  </Typography>
                </Box>
                <Typography
                  aria-haspopup="true"
                  color="textSecondary"
                  variant="body2"
                >
                  <FormattedMessage id={type} />
                </Typography>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        {forfeitAmount ? (
          <TimelineItem>
            <TimelineOppositeContent style={{ flex: 'unset' }}>
              <Box width={96} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <Box
                bgcolor="red"
                borderRadius="50%"
                color="white"
                fontSize={13}
                fontWeight={500}
                height={24}
                lineHeight="24px"
                my={2}
                textAlign="center"
                width={24}
              >
                {monthlyPaymentNumber}
              </Box>
              {!isLast && (
                <Box
                  bgcolor={paid ? 'primary.main' : '#bdbdbd'}
                  flexGrow={1}
                  width={2}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Box alignItems="center" display="flex">
                <Box>
                  <Box alignItems="baseline" display="flex">
                    <Box
                      color="money.outcome"
                      component="span"
                      fontWeight={500}
                    >
                      <Typography
                        color="inherit"
                        style={{ fontWeight: 500 }}
                        variant="body1"
                      >
                        <FormattedCurrency value={forfeitAmount} />
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    aria-haspopup="true"
                    color="textSecondary"
                    variant="body2"
                  >
                    Пени за нарушение срока оплаты
                  </Typography>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
        ) : null}
      </Box>
      {paid ? (
        <Box>
          <CurrentScheduleItemMenu paymentId={key} />
        </Box>
      ) : null}
      {meta.isEditable && (
        <Box>
          <TimelineItemMenu
            paymentData={paymentData}
            scheduleId={scheduleId}
          />
        </Box>
      )}
    </Box>
  );
}

TimelineItemContainer.propTypes = {
  meta: PropTypes.shape({
    paymentIndex: PropTypes.number,
    isLast: PropTypes.bool,
    isEditable: PropTypes.bool,
  }),
  paymentData: PropTypes.shape({
    type: PropTypes.string,
    paid: PropTypes.bool,
    startAt: PropTypes.string,
    key: PropTypes.string,
    fullAmount: PropTypes.number,
    forfeitAmount: PropTypes.number,
    baseAmount: PropTypes.number,
    commissionAmount: PropTypes.number,
    vatAmount: PropTypes.number,
    monthlyPaymentNumber: PropTypes.number,
  }),
  scheduleId: PropTypes.string,
};
