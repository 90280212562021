import React, { useCallback, useMemo, useState } from 'react';

import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { navigate, useLocation } from '@reach/router';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import qs from 'querystringify';
import { useDebouncedCallback } from 'use-debounce';

export default function TasksDashboardFilter() {
  const location = useLocation();
  const parsedQuery = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  const [term, setTerm] = useState(parsedQuery.q || '');

  const debounced = useDebouncedCallback((value) => {
    setTerm(value.trim());
    const query = pickBy({ ...parsedQuery, q: value.trim() }, identity);
    query.page = 1;
    const search = qs.stringify(query, true);
    navigate(search ? search : location.pathname);
  }, 500);

  const handleQueryChange = useCallback(({ target: { value } }) => {
    setTerm(value);
    debounced.callback(value);
  }, [debounced]);

  const handleResetFilter = useCallback(() => {
    setTerm('');
    debounced.callback('');
  }, [debounced]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 34,
        mt: 2,
        mb: 3,
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
      }}
    >
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {term !== '' ? (
                <IconButton onClick={handleResetFilter} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
        fullWidth
        onChange={handleQueryChange}
        placeholder="Начните вводить название или описание задачи"
        size="small"
        sx={{
          '& .MuiInputBase-root': { px: 3 },
          '& input': { fontSize: 14, height: 'calc(100% + 2px)' },
          '& fieldset': { border: 'none' },
        }}
        value={term}
      />
    </Box>
  );
}