import PropTypes from 'prop-types';
import React from 'react';

import { Button, darken, makeStyles } from '@material-ui/core';
import _get from 'lodash.get';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => _get(theme.palette, props.bgcolor, props.bgcolor),
    color: 'white',
    padding: '0 8px',
    margin: 4,
    fontWeight: 500,
    textTransform: 'unset',
    '&:hover': {
      background: (props) => darken(_get(theme.palette, props.bgcolor, '#eee'), 0.1),
    },
  },
}));

LabelButton.propTypes = {
  bgcolor: PropTypes.string,
};

export default function LabelButton({ bgcolor, ...props }) {
  const classes = useStyles({ bgcolor });
  return <Button className={classes.root} disableElevation variant="contained" {...props} />;
}
