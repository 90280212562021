import { gql } from '@apollo/client';

export const NOTES_FRAGMENT = gql`
  fragment NoteFragment on Note {
    id
    author {
      ... on User {
        avatar
        roles
        displayName
        contact {
          id
          lastName
          firstName
        }
      }
    }
    body
    createdAt
    priority
  }
`;

export const GOODS_FRAGMENT = gql`
  fragment GoodsFragment on ShopGood {
    id
    name
    status
    priceWithDiscount
    discount
    currentLocationAddress
    currentLocation
    storage {
      id
      address
    }
    vat
  }
`;

export const MANAGER_FRAGMENT = gql`
  fragment ManagerFragment on User {
    id
    avatar
    displayName
    contact {
      email
      phone
      firstName
      lastName
    }
  }
`;

export const ENTITY_FRAGMENT = gql`
  fragment EntityFragment on Entity {
    id
    legalName
    legalNameWithShortEntityType
    tin
    trrc
    psrn
    addresses {
      id
      fullAddress
    }
  }
`;

export const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    id
    number
    status
    createdAt
    amount
    vatRate
    expirationDate
    cashless
    comment {
      body
    }
  }
`;

export const SUPPLY_FRAGMENT = gql`
  fragment SupplyFragment on ShopSupply {
    id
    amount
    deliveredAt
    status
    shopSupplyMiscs {
      id
      assetUrl
      assetOriginalFilename
      explanation
    }
    shopSupplyUpds {
      id
      assetUrl
      assetOriginalFilename
      explanation
    }
    order {
      id
      createdAt
    }
  }
`;


export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    firstName
    middleName
    lastName
    email
    phone
  }
`;