import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import { MenuItem, Typography } from '@material-ui/core';

import { SimpleMenu } from 'components/SimpleMenu';

import {
  CANCEL_SCHEDULE_PAYMENT,
} from '../graphql/mutations/financeCancelScheduleItemPayment';

CurrentScheduleItemMenu.propTypes = {
  paymentId: PropTypes.string,
};

export default function CurrentScheduleItemMenu({ paymentId }) {
  const [cancelScheduleItem, canceledItem] = useMutation(
    CANCEL_SCHEDULE_PAYMENT,
    {
      variables: { key: paymentId },
      onError: (error) => {
        alert(`Произошла ошибка: ${error}`);
      },
    },
  );

  function handleCancelClick() {
    cancelScheduleItem();
  }

  return (
    <SimpleMenu
      menuItems={[
        <MenuItem disabled={canceledItem.loading} key={1} onClick={handleCancelClick}>
          <Typography noWrap variant="inherit">
            Отменить оплату
          </Typography>
        </MenuItem>,
      ]}
    />
  );
}
