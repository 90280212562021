import PropTypes from "prop-types";
import React, { useState, useMemo } from 'react';

import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import {FormattedMessage} from "react-intl";

import { DatePickerBox } from "components/DatePickerBox";

import { SHOP_GOODS_UPDATE } from '../../../graphql/mutations/shopGoodsUpdate';

const GOOD_PARAMS_INIT = {
  serialNumber: null,
  producedAt: null,
  quality: null
}


const ChangeGoodProperties = ({ data, id, onChangeGood, onChangeGoodError, onCloseEvent, open }) => {
  const styles = useMemo(() => ({
    wrapperBlock: {
      marginBottom: '24px'
    },
    labelSmall: {
      fontSize: '14px',
      fontWeight: 600,
      marginBottom: '8px',
      lineHeight: '24px'
    },
    select: {
      border: '1px solid #CFD2D6',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: 0,
      color: '#6E7884',
      maxHeight: '48px',

      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        border: '1px solid #0D1D32',
        background: '#FFFFFF',
      },
      '&:active': {
        border: '1px solid #3D6CE7',
        background: '#FFFFFF',
      },
      '& > div': {
        padding: '12.5px 0 12.5px 14px',
        background: 'transparent !important',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    selectItem: {
      '&': {
        color: '#6E7884',
      },
      '&:hover': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&:active': {
        color: '#0D1D32',
        background: 'rgba(61, 108, 231, 0.05)',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff !important',
        color: '#3D6CE7 !important',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    input: {
      maxHeight: '48px',

      '& > div': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
        zIndex: 1,

        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
      '& .Mui-disabled': {
        '& input': {
          color: '#6E7884',
          zIndex: 1,
        },
        '& fieldset': {
          border: '1px solid #CFD2D6 !important',
          background: '#F5F6F7',
        },
        '&:hover fieldset': {
          border: '1px solid #CFD2D6 !important',
        }
      },
    },
    label: {
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '8px',
      lineHeight: '24px'
    },
    inputDate: {
      marginTop: '1px',

      '& > div': {
        paddingRight: '0',
      },
      '& > button': {
        paddingRight: '6px',
      },
      '& input': {
        padding: '12.5px 0px 12.5px 14px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#6E7884',
      },
      '& fieldset': {
        border: '1px solid #CFD2D6',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '1px solid #0D1D32 !important',
        borderRadius: '8px',
      },
      '& > div.Mui-focused fieldset': {
        border: '1px solid #3D6CE7 !important',
        borderRadius: '8px',
      },
    },
    blueButton: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: 'none',
      background: '#3D6CE7',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '10px 24px',

      '&:hover': {
        background: '#588EFF',
        boxShadow: '0px 6px 16px rgba(61, 108, 230, 0.3)',
      },
      '&:active': {
        background: '#365CBF',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#E7E8EA',
        color: '#9EA5AD',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    whiteButton: {
      background: 'rgba(13, 29, 50, 0.1)',
      border: '1px solid rgba(13, 29, 50, 0.1)',
      boxShadow: 'none',
      borderRadius: '8px',
      color: '#0D1D32',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'none',
      padding: '10px 24px',
      maxHeight: '48px',
      marginRight: '16px',

      '&:hover': {
        background: 'rgba(61, 108, 230, 0.05)',
        border: '1px solid #365CBF',
        color: '#365CBF',
        boxShadow: 'none',
      },
      '&:active': {
        background: '#3D6CE7',
        border: '1px solid #3D6CE7',
        color: '#ffffff',
        boxShadow: 'none',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const [formState, setFormState] = useState({...GOOD_PARAMS_INIT, ...data});
  const [canClick, setCanClick] = useState(true);

  const onChangeField = (type, event) => {
    let value;
    const newFormState = cloneDeep(formState);

    if (type === "producedAt") {
      if (event && dayjs(event).isValid()) {
        value = dayjs(event).format('YYYY-MM-DD');
      }
    } else {
      value = event.target.value;
    }
    newFormState[type] = value;

    setFormState(newFormState);
  };

  const onClose = () => {
    onCloseEvent(false);
  };

  const [changeGood] = useMutation(SHOP_GOODS_UPDATE, {
    onCompleted: (result) => {
      onChangeGood(result);
      onClose();
      setCanClick(true);
    },
    onError: () => {
      onChangeGoodError();
      onClose();
      setCanClick(true);
    },
  });

  const handleConfirm = () => {
    if(canClick) {


      changeGood({
        variables: {
          params: formState,
          goodId: id,
        }
      });
    }
  };

  return (
    <Dialog fullWidth open={open} scroll="body">
      <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '26px', fontWeight: 700, }} variant="h3">
          Редактировать характеристики
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={ styles.wrapperBlock }>
          <Typography sx={ styles.labelSmall }>
            Серийный номер:
          </Typography>
          <TextField
            fullWidth
            onChange={onChangeField.bind(this, 'serialNumber')}
            sx={{
              ...styles.input,
              '& fieldset': {
                background: '#ffffff',
                borderRadius: '8px',
              }
            }}
            type="text"
            value={formState.serialNumber}
          />
        </Box>
        <Box sx={ styles.wrapperBlock }>
          <Typography sx={ styles.labelSmall }>
            Дата производства:
          </Typography>
          <DatePickerBox
            onChange={onChangeField.bind(this, 'producedAt')}
            placeholder={"15.02.2020"}
            size="small"
            sx={ styles.inputDate }
            value={formState.producedAt}
          />
        </Box>
        <Box sx={ styles.wrapperBlock }>
          <Typography sx={ styles.labelSmall }>
            Состояние:
          </Typography>
          <Select
            fullWidth
            onChange={onChangeField.bind(this, 'quality')}
            sx={{
              ...styles.select,
            }}
            value={formState.quality}
          >
            <MenuItem sx={ styles.selectItem } value={'SATISFACTORY'}><FormattedMessage id={'shop.status.SATISFACTORY'} /></MenuItem>
            <MenuItem sx={ styles.selectItem } value={'GOOD'}><FormattedMessage id={'shop.status.GOOD'} /></MenuItem>
            <MenuItem sx={ styles.selectItem } value={'PERFECT'}><FormattedMessage id={'shop.status.PERFECT'} /></MenuItem>
            <MenuItem sx={ styles.selectItem } value={'NEWEST'}><FormattedMessage id={'shop.status.NEWEST'} /></MenuItem>
          </Select>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={onClose}
            sx={ styles.whiteButton }
            variant="outlined"
          >
            Отмена
          </Button>
          <Button
            onClick={handleConfirm}
            sx={ styles.blueButton }
            variant="outlined"
          >
            Сохранить
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ChangeGoodProperties.propTypes = {
  data: PropTypes.object,
  id: PropTypes.number,
  onChangeGood: PropTypes.func,
  onChangeGoodError: PropTypes.func,
  onCloseEvent: PropTypes.func,
  open: PropTypes.bool
};

export default ChangeGoodProperties;