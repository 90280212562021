import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import _noop from 'lodash.noop';

import NakedInput from 'components/NakedInput';

const useStyles = makeStyles((theme) => ({
  editBox: {
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 'inherit',
    width: '100%',
  },
  editButton: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: 8,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default function InlineEdit({
  value = 'Default value',
  ViewComponent,
  onChange = _noop,
  ...rest
}) {
  const [_value, setValue] = useState(value);
  const [mode, setMode] = useState('read');

  const classes = useStyles();

  useEffect(() => setValue(value), [value]);

  function handleClick() {
    setMode('edit');
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleFocus(e) {
    e.target.select();
  }
  function handleBlur() {
    onChange(_value);
    setMode('read');
  }

  return (
    <Box className={classes.editBox}>
      {mode === 'read' && (
        <>
          {ViewComponent
            ? React.cloneElement(ViewComponent, { value: _value })
            : _value}
          <Button className={classes.editButton} onClick={handleClick}>
            <EditIcon sx={{ fontSize: 18 }} />
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <NakedInput
          autoFocus
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          value={_value}
          {...rest}
        />
      )}
    </Box>
  );
}

InlineEdit.propTypes = {
  ViewComponent: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
