import React, { useCallback, useState } from 'react';

import { Link } from '@material-ui/core';

import FinmonitoringFilterModal from './FinmonitoringFilterModal';
import FinmonitoringFilterState from './FinmonitoringFilterState';

export default function FinmonitoringFilter() {
  const [modalOpened, setModalOpened] = useState(false);

  const handleOpenFilter = useCallback(() => setModalOpened(true), []);
  const handleCloseFilter = useCallback(() => setModalOpened(false), []);

  return (
    <React.Fragment>
      <Link
        component="button"
        onClick={handleOpenFilter}
        sx={{
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px dashed',
          mx: 3,
        }}
        underline="none"
        variant="body1"
      >
        Фильтр
      </Link>
      {modalOpened && <FinmonitoringFilterModal onClose={handleCloseFilter} />}
      <FinmonitoringFilterState />
    </React.Fragment>
  );
}
