import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';

import { FsspCheckProcessListItem } from './FsspCheckProcessListItem';
import {
  FSSP_START_CHECK_PROCESS,
} from './graphql/mutations/fsspStartCheckProcess';
import {
  GET_FSSP_CHECK_PROCESSES,
} from './graphql/queries/fsspCheckProcesses';

FsspCheckProcessList.propTypes = {
  contactId: PropTypes.string,
  items: PropTypes.array,
};

export function FsspCheckProcessList({ contactId, items = [], ...props }) {
  const [startFsspCheckProcess, { loading }] = useMutation(
    FSSP_START_CHECK_PROCESS,
    {
      variables: { contactId },
      onError: (error) => {
        alert(`Произошла ошибка при запуске проверки ФССП ${error.message}`);
      },
      refetchQueries: [{
        query: GET_FSSP_CHECK_PROCESSES,
        variables: { contactId },
      }],
    },
  );

  function handleClick() {
    startFsspCheckProcess();
  }

  return (
    <Box
      bgcolor="#F4F5F7"
      borderRadius={4}
      flexShrink={0}
      mr={2}
      pb={2}
      px={2}
      width={320}
    >
      <Toolbar>
        <Box alignSelf="center" flexGrow={1}>
          {/* FIXME: to locales */}
          <Typography variant="h4">Выполненные проверки</Typography>
        </Box>
        {/* FIXME: to locales */}
        <Tooltip title="Запросить данные из ФССП">
          <IconButton disabled={loading} edge="end" onClick={handleClick}>
            <RenewIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Box bgcolor="white" borderRadius={4} maxHeight={360} overflow="auto">
        {items.map((item, index) => (
          <FsspCheckProcessListItem
            data={item}
            index={index}
            key={index}
            {...props}
          />
        ))}
      </Box>
    </Box>
  );
}
