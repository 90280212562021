import PropTypes from 'prop-types';
import React, {useEffect, useState, useRef, useCallback} from 'react';

import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import ModeEditOutlineOutlinedIcon from '@material-ui/icons/ModeEditOutlineOutlined';

EditNumberRFM.propTypes = {
  onEditNumberRFM: PropTypes.func,
  row: PropTypes.object,
};

export default function EditNumberRFM({ onEditNumberRFM, row }) {
  const [ editField, setEditField ] = useState(false);
  const [ value, setValue ] = useState(row.externalId || "");
  const [ error, setError ] = useState(false);
  const refInput = useRef(null);
  const prevValue = useRef(null);
  const domParent = React.useRef(null);

  const sendNumberRFM = useCallback(() => {
    if (editField === false && typeof prevValue.current === "string" && prevValue.current.trim() !== value.trim()) {
      onEditNumberRFM(row.id, value.trim());
    }
  }, [onEditNumberRFM, editField, row.id, value])

  useEffect(() => {
    if (!editField) return;

    const handleClick = e => {
      if (!domParent.current) return;

      if (!domParent.current.contains(e.target)) {
        if (value.trim().length === 0) {
          setValue(prevValue.current);
          setError(false)
        }
        setEditField(false)
        sendNumberRFM()
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [editField, value, sendNumberRFM])


  const allowsEditField = () => {
    if (onEditNumberRFM && typeof onEditNumberRFM === "function") {
      setEditField(!editField);

      if (!editField) {
        setValue(value.trim());
      }
    }
  }

  useEffect(() => {
    if (editField) {
      refInput.current.focus();
    }

    if (error || value.trim().length === 0) {
      return;
    } else {
      sendNumberRFM();
    }
  }, [editField, error, value, sendNumberRFM])

  const onChangeField = (event) => {
    let target = event.target.value;

    if (value.trim().length > 0 && value.trim() !== target.trim()) {
      prevValue.current = value;
    }

    setValue(target);

    if (target.trim().length === 0) {
      setError(true)
    } else {
      setError(false)
    }
  }

  return (
    <Box ref={domParent} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 3 }}>
      {editField || error ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 34,
            backgroundColor: 'white',
            border: error ? '1px solid #e70b39' : '1px solid #E0E0E0',
            borderRadius: '4px',
            '& .MuiInputBase-root': {
              paddingLeft: '6px !important',
              paddingRight: '6px !important',
            }
          }}
        >
          <TextField
            fullWidth
            inputRef={refInput}
            onChange={onChangeField}
            size="small"
            sx={{
              '& .MuiInputBase-root': { px: 3 },
              '& input': { fontSize: 14, height: 'calc(100% + 2px)', padding: '0px', },
              '& fieldset': { border: 'none' },
            }}
            type={"text"}
            value={value}
          />
        </Box>
      ) : (
        <Tooltip disableHoverListener={!value.length} title={value}>
          <span style={{ width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value.length ? value : "-"}</span>
        </Tooltip>
      )}

      <Tooltip disableHoverListener={value.trim().length === 0} title="Редактировать номер сообщения в РФМ">
        <span>
          <IconButton disabled={value.trim().length === 0} onClick={allowsEditField} sx={{ p: '6px' }}>
            <ModeEditOutlineOutlinedIcon sx={{ fontSize: '20px !important' }} />
          </IconButton>
          </span>
      </Tooltip>
    </Box>
  );
}