import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Alert, AlertTitle } from '@material-ui/core';

TemporalAlert.propTypes = {
  action: PropTypes.node,
  message: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  title: PropTypes.string,
};

export default function TemporalAlert({ action, message, severity, title }) {
  const [isAlertShown, setAlertShown] = useState(true);

  useEffect(() => {
    let timer;
    if (isAlertShown) {
      timer = setTimeout(() => setAlertShown(false), 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isAlertShown]);

  if (!isAlertShown) {
    return null;
  }

  return (
    <Alert action={action} severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}