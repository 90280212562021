import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import { navigate, useLocation } from '@reach/router';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import qs from 'querystringify';

const defaultState = {
  filter: {},
  problems: [],
};

export const FinmonitoringContext = createContext({
  state: defaultState,
  setProblems: () => {},
  updateFilter: () => {},
});

export const FinmonitoringProvider = ({ children }) => {
  const location = useLocation();
  const parsedQuery = useMemo(() => qs.parse(location.search), [location.search]);

  const [state, setState] = useState(defaultState);

  const setProblems = (problems = []) => {
    setState((prev) => ({ ...prev, problems }));
  };

  const updateFilter = useCallback(
    (filter) => {
      setState((prev) => {
        if (!isEqual(prev.filter, filter)) {
          const query = pickBy(
            {
              ...parsedQuery,
              page: 1,
              filters: JSON.stringify(filter),
            },
            identity,
          );

          const search = qs.stringify(query, true);
          navigate(search ? search : location.pathname);
        }

        return { ...prev, filter };
      });
    },
    [location.pathname, parsedQuery],
  );

  return (
    <FinmonitoringContext.Provider value={{ state, setProblems, updateFilter }}>
      {children}
    </FinmonitoringContext.Provider>
  );
};

FinmonitoringProvider.propTypes = {
  children: PropTypes.node,
};
