import { gql } from '@apollo/client';

export const ON_ENTITY_BANK_STATEMENTS_LIST_UPDATED_FULL_DATA = gql`
  subscription OnEntityBankStatementsListUpdatedFullData($entityId: ID!) {
    entityBankStatementsListUpdated(entityId: $entityId) {
      id
      bankStatements {
        id
        status
        createdAt
        info {
          startDate
          endDate
          sender
          accountNumber
        }
        document {
          assetUrl
          assetOriginalFilename
          assetFileExt
          id
        }
        statementFile {
          fileExt
          filename
          url
        }
        uploaderContact {
          id
          firstName
          lastName
          middleName
        }
      }
    }
  }
`;