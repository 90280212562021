import { gql } from '@apollo/client';

export const QUESTIONNAIRE_FRAGMENT = gql`
  fragment QuestionnaireFragment on ContactEntityQuestionnaire {
    contact {
      id
      firstName
      middleName
      lastName
      fullName @client
    }
    createdAt
    data
    id
    state
    updatedAt
  }
`;

export const FEDRESURS_PROCESS_FRAGMENT = gql`
  fragment FedresursProcessFragment on FedresursFetchingProcess {
    id
    state
    startedAt
    finishedAt
    failedAt
    createdAt
  }
`;

export const FEDRESURS_FETCHING_CONTRACT_FRAGMENT = gql`
  fragment FedresursFetchingContractFragment on FedresursFetchingContract {
    contractNumber
    startDate
    stopDate
    stopReason
    leasingStatus
    messages {
      id
      kind
      data
      details
    }
    lessors {
      inn
      name
      ogrn
      type
    }
    lessees {
      inn
      name
      ogrn
      type
    }
    subjects {
      classifierCode
      classifierName
      description
    }
  }
`;