import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

export const Loader = () => (
  <Box
    bgcolor="rgba(255, 255, 255, .6)"
    height="100%"
    left={0}
    position="absolute"
    top={0}
    width="100%"
    zIndex="1000"
  >
    <Box
      fontSize={20}
      left="calc(50% - 10px)"
      position="absolute"
      top="calc(45% - 10px)"
    >
      <CircularProgress />
    </Box>
  </Box>
);