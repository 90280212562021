import PropTypes from 'prop-types';
import React from 'react';

import { useMutation } from '@apollo/client';
import { ListItem } from '@material-ui/core';
import _get from 'lodash.get';

import { CREATE_SCHEDULE } from '../graphql/mutations/financeCreateSchedule';

CreateScheduleButton.propTypes = {
  loanId: PropTypes.string,
  onCreate: PropTypes.func,
};

export default function CreateScheduleButton({ loanId, onCreate }) {
  const [createSchedule] = useMutation(CREATE_SCHEDULE, {
    variables: { loanId },
    onCompleted: (data) => {
      const newScheduleId = _get(data, 'financeCreateSchedule.schedule.id');
      onCreate(newScheduleId);
    },
    onError: (error) => {
      alert(`Произошла ошибка при создании графика ${error.message}`);
    },
    update: (cache, result) => {
      const newScheduleId = _get(
        result,
        'data.financeCreateSchedule.schedule.id',
      );

      cache.modify({
        id: cache.identify({ __typename: 'Loan', id: loanId }),
        fields: {
          schedules(existingScheduleRefs = [], { readField, toReference }) {
            const newScheduleRef = toReference(`Schedule:${newScheduleId}`);
            // Quick safety check - if the new schedule is already
            // present in the cache, we don't need to add it again.
            if (existingScheduleRefs.some(
              (ref) => readField('id', ref) === newScheduleId
            )) {
              return existingScheduleRefs;
            }

            return [newScheduleRef, ...existingScheduleRefs];
          },
        },
      });
    },
  });

  function handleCreateDraftClick() {
    createSchedule();
  }

  return (
    <ListItem button onClick={handleCreateDraftClick}>
      Создать проект
    </ListItem>
  );
}
