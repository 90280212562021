import { gql } from '@apollo/client';

export const ENTITY_UPDATE_CONTACT_QUESTIONNAIRE = gql`
  mutation EntityUpdateContactQuestionnaire(
    $questionnaireId: ID!,
    $attributes: Json!
  ) {
    entityUpdateContactQuestionnaire(
      contactEntityQuestionnaireId: $questionnaireId,
      attributes: $attributes
    ) {
      contactEntityQuestionnaire {
        data
        updatedAt
        versions {
          createdAt
          createdBy {
            id
            contact {
              id
              firstName
              middleName
              lastName
              fullName @client
            }
          }
          version
        }
      }
    }
  }
`;