import { gql } from '@apollo/client/core';

export const SOURCE_DOCUMENTS_UPLOAD_OTHER_DOCUMENTS = gql`
  mutation SourceDocumentsUploadOtherDocuments(
    $documentId: ID!,
    $otherDocuments: [LoanSupplyOtherDocumentInput!]!
  ) {
    sourceDocumentsUploadOtherDocuments(
      otherDocuments: $otherDocuments,
      sourceDocumentId: $documentId
    ) {
      sourceDocument {
        id
      }
    }
  }
`;