import { InMemoryCache, gql, makeVar } from '@apollo/client';
import { ORGANIZATIONS, APPLICATIONS } from 'data';
import { DateTime } from 'luxon';

export const organizationsVar = makeVar(ORGANIZATIONS);
// export const employeesVar = makeVar(EMPLOYEES);
export const applicationsVar = makeVar(APPLICATIONS);

function opfShortener(name) {
  return name
    .replace(/Непубличное акционерное общество/gi, 'НАО')
    .replace(/Публичное акционерное общество/gi, 'ПАО')
    .replace(/Акционерное общество/gi, 'АО')
    .replace(/Общество с ограниченной ответственностью/gi, 'ООО')
    .replace(/Индивидуальный предприниматель/gi, 'ИП');
}
const cache = new InMemoryCache({
  typePolicies: {
    BankStatementContractor: {
      fields: {
        title: {
          read: opfShortener,
        },
      },
    },
    BankStatementOrder: {
      fields: {
        orderDate: {
          read(exist) {
            const isoDate = DateTime.fromFormat(exist, 'dd.MM.yyyy').toISODate();

            return isoDate;
          },
        },
      },
    },
    Contact: {
      fields: {
        fullName: {
          read(_, { readField }) {
            const lastName = readField('lastName');
            const firstName = readField('firstName');
            const middleName = readField('middleName');
            return `${lastName || ''} ${firstName || ''} ${middleName || ''}`.trim();
          },
        },
        shortName: {
          read(_, { readField }) {
            const lastName = readField('lastName');
            const firstName = readField('firstName');
            const middleName = readField('middleName');

            return `${firstName ? firstName[0] + '.' : ''}${
              middleName ? middleName[0] + '.' : ''
            } ${lastName || ''}`.trim();
          },
        },
      },
    },
    FedresursContractMember: {
      fields: {
        name: {
          read: opfShortener,
        },
      },
    },
    Schedule: {
      fields: {
        items: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ScheduleItem: {
      keyFields: ['key'],
    },
    Query: {
      fields: {
        application: {
          read(existing, { args, toReference }) {
            const application = toReference(`Application:${args.applicationId}`);
            return application;
          },
        },
        bankStatementContractor: {
          read(existing, { toReference, variables }) {
            const contractorRef = toReference(`BankStatementContractor:${variables.id}`);
            return contractorRef;
          },
        },
        loan: {
          read(existing, { toReference, variables }) {
            const loan = toReference(`Loan:${variables.id}`);
            return loan;
          },
        },
        schedule: {
          read(existing, { toReference, variables }) {
            const schedule = toReference(`Schedule:${variables.id}`);
            return schedule;
          },
        },
        verificationProcess: {
          read(existing, { toReference, variables }) {
            const process = toReference(`VerificationProcess:${variables.id}`);
            return process;
          },
        },
        questionnaire: {
          read(existing, { args, toReference }) {
            const questionnaire = toReference(`ContactEntityQuestionnaire:${args.questionnaireId}`);
            return questionnaire;
          },
        },
      },
    },
  },
});

// cache.writeQuery({
//   query: gql`
//     query GetApplications {
//       applications {
//         id
//         loan {
//           advanceRate
//           advanceVatRate
//           amountFinancing
//           amountForLeasingCompany
//           discountFromSupplier
//           effectiveRate
//           forIssuingCommission
//           forfeitRate
//           id
//           interestRate
//           monthlyInterestRate
//           redemptionPayment
//           term
//           vatRate
//           withVat
//           schedules {
//             id
//             state
//             items
//           }
//         }
//       }
//     }
//   `,
//   data: {
//     applications: applicationsVar(),
//   },
// });

cache.writeQuery({
  query: gql`
    query GetOrganizations {
      organizations {
        id
        name
        inn
      }
    }
  `,
  data: {
    organizations: organizationsVar(),
  },
});

export default cache;
