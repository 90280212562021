import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useReducer } from 'react';

import { useQuery } from '@apollo/client';
import _get from 'lodash.get';
import _groupBy from 'lodash.groupby';
import _orderBy from 'lodash.orderby';

import {
  GET_SCHEDULE_LIST_FOR_LOAN,
} from '../graphql/queries/scheduleListForLoan';

const initialState = {
  groupedSchedules: {
    active: [],
    draft: [],
    archived: [],
  },
  activeScheduleId: undefined,
  comparedScheduleId: undefined,
};
export const ScheduleContext = createContext(initialState);
const { Provider } = ScheduleContext;

ScheduleState.propTypes = {
  children: PropTypes.node.isRequired,
  loanId: PropTypes.string,
};

export function orderSchedules(schedules) {
  const iterator = ({ state }) => ({
    draft: 0,
    archive: 1,
    active: -1,
  }[state]);
  return _orderBy(schedules, [iterator, 'id'], ['asc', 'desc']);
}

function prepareScheduleList(data) {
  const schedules = _get(data, 'loan.schedules', []);
  const orderedSchedules = orderSchedules(schedules);
  const groupedSchedules = _groupBy(orderedSchedules, 'state');

  return { orderedSchedules, groupedSchedules };
}

export default function ScheduleState({ children, loanId }) {
  const { data } = useQuery(GET_SCHEDULE_LIST_FOR_LOAN, {
    variables: { id: loanId },
    fetchPolicy: 'cache-only',
  });

  const { groupedSchedules, orderedSchedules } = useMemo(
    () => prepareScheduleList(data),
    [data],
  );

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'INIT': {
        const activeScheduleId = _get(orderedSchedules, '[0].id');
        return {
          ...state,
          loanId,
          groupedSchedules,
          activeScheduleId,
        };
      }
      case 'REORDER': {
        return {
          ...state,
          groupedSchedules,
        };
      }
      case 'SELECT_ITEM': {
        return {
          ...state,
          ...action.payload,
        };
      }
      case 'ADD_ITEM': {
        const activeScheduleId = _get(action, 'payload.id');
        return {
          ...state,
          groupedSchedules,
          activeScheduleId,
        };
      }
      case 'MIGRATE_COMPLETE': {
        const activeScheduleId = _get(action, 'payload.id');
        return {
          ...state,
          groupedSchedules,
          activeScheduleId,
        };
      }
      case 'ACTIVATE_ITEM': {
        const activeScheduleId = _get(action, 'payload.id');
        return {
          ...state,
          groupedSchedules,
          activeScheduleId,
        };
      }
      case 'DELETE_ITEM': {
        const deletedScheduleId = _get(action, 'payload.id');
        const draft = _get(state, 'groupedSchedules.draft', []);
        const deletedScheduleIndex = draft.findIndex(
          ({ id }) => id === deletedScheduleId
        );
        const activeScheduleId =
          _get(draft, `${[deletedScheduleIndex - 1]}.id`) ||
          _get(orderedSchedules, '[0].id');

        return {
          ...state,
          groupedSchedules,
          activeScheduleId,
        };
      }
      case 'COMPARED_MODE_ON': {
        const defaultComparedSchedule = orderedSchedules.find(
          ({ state }) => state === 'draft' || state === 'archived',
        );
        const comparedScheduleId = _get(defaultComparedSchedule, 'id');
        return {
          ...state,
          comparedScheduleId,
        };
      }
      case 'COMPARED_MODE_OFF': {
        return {
          ...state,
          comparedScheduleId: undefined,
        };
      }
      default:
        throw new Error();
    }
  }, initialState);

  useEffect(() => {
    dispatch({ type: 'INIT' });
  }, [loanId]);

  // FIXME: Подумать, как исправить обновление списка
  useEffect(() => {
    dispatch({ type: 'REORDER' });
  }, [groupedSchedules]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
