import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import dayjs from 'dayjs';

import { DatePickerBox } from 'components/DatePickerBox';
import { formatDate } from 'utils/dateTimeFormatters';

import { DocumentStatusMap } from '../constants';

export const AssignDateField = ({ date, documentStatus, onChange, required, title }) => {
  const [newDate, setNewDate] = useState(date);
  const [hasDateError, setHasDateError] = useState(false);


  const handleChangeDate = (value) => {
    let dateValue = null;

    if (value && dayjs(value).isValid()) {
      dateValue = dayjs(value).format();
    }

    setNewDate(dateValue);
  }

  useEffect(() => {
    if (onChange instanceof Function) {
      onChange(!hasDateError ? newDate : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDate, hasDateError]);

  if (documentStatus === DocumentStatusMap.pending) { // recognizing
    return (
      <DatePickerBox
        label={title}
        onChange={handleChangeDate}
        onError={setHasDateError}
        required={required}
        value={newDate ? new Date(newDate) : newDate}
      />
    );
  }

  return (
    <TextField
      InputProps={{ sx: { bgcolor: grey[50] } }}
      defaultValue={date ? formatDate(date) : '—'}
      disabled
      label={title}
      variant="outlined"
    />
  );
};

AssignDateField.propTypes = {
  date: PropTypes.string,
  documentStatus: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
};

AssignDateField.defaultProps = {
  title: "Дата документа",
  date: null,
}