import { gql } from '@apollo/client';

import {
  FEDRESURS_FETCHING_CONTRACT_FRAGMENT,
  FEDRESURS_PROCESS_FRAGMENT,
} from '../fragments';

export const FEDRESURS_FETCHING_PROCESS_UPDATED = gql`
  subscription OnFedresursFetchingProcessUpdated($processId: ID!) {
    fedresursFetchingProcessUpdated(processId: $processId) {
      ...FedresursProcessFragment
      contracts {
        ...FedresursFetchingContractFragment
      }
    }
  }
  ${FEDRESURS_PROCESS_FRAGMENT}
  ${FEDRESURS_FETCHING_CONTRACT_FRAGMENT}
`;