import PropTypes from 'prop-types';
import React from 'react';

import { FormattedNumber } from 'react-intl';

export default function FormattedCurrency({
  currency,
  currencyDisplay,
  hideCurrency,
  placeholder = null,
  value,
  ...rest
}) {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return placeholder;
  }

  const props = hideCurrency
    ? {
        style: 'decimal',
        minimumFractionDigits: 2,
      }
    : {
        style: 'currency',
        currencyDisplay,
      };

  return <FormattedNumber currency={currency} value={value} {...props} {...rest} />;
}

FormattedNumber.defaultProps = {
  currency: 'RUB',
  currencyDisplay: 'symbol',
};

FormattedCurrency.propTypes = {
  currency: PropTypes.string,
  currencyDisplay: PropTypes.string,
  hideCurrency: PropTypes.bool,
  placeholder: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
