import PropTypes from "prop-types";
import React, { useCallback, useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import DashboardFilterModal from './DashboardFilterModal';
import TasksFilterModal from './TasksFilterModal';
import TasksFilterState from './TasksFilterState';

TasksFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default function TasksFilter({ type }) {
  const [modalOpened, setModalOpened] = useState(false);

  const handleOpenFilter = useCallback(() => setModalOpened(true), []);
  const handleCloseFilter = useCallback(() => setModalOpened(false), []);

  const renderFilterModal = () => {
    if (type === "table") {
      return <TasksFilterModal onClose={handleCloseFilter} />
    }

    if (type === "dashboard") {
      return <DashboardFilterModal onClose={handleCloseFilter} />
    }
  }

  return (
    <React.Fragment>
      <Tooltip title="Фильтры">
        <IconButton onClick={handleOpenFilter}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      {modalOpened && renderFilterModal()}

      <TasksFilterState />
    </React.Fragment>
  );
}
