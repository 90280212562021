import { gql } from '@apollo/client/core';

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    id
    priority
    certainTime
    dueAt
    title
    description
    attachments {
      attachmentId
      filename
      fileExt
      url
    }
    target {
      ... on Application {
        id
        contact {
          id
          firstName
          lastName
          middleName
          phone
        }
        status
        subject
        borrowerEntity {
          id
          shortLegalName
        }
        supplyingEntityContracts {
          supplyingOrder {
            supplier {
              id
              legalNameWithShortEntityType
            }
          }
        }
      }
    }
  }
`;