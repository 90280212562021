/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from 'react';

import { Box, Divider, IconButton, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/Download';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import { PDFRendererPagination } from './PDFRendererPagination';
import { PDFRendererContext } from '../state';
import {
  setPDFPaginated,
  setRotationDegree,
  setZoomLevel,
} from '../state/actions';
import { initialPDFRendererState } from '../state/reducer';

export const PDFRendererControls = () => {
  const {
    dispatch,
    state: { mainState, numPages, paginated, rotationDegree, zoomLevel },
  } = useContext(PDFRendererContext);

  const handleZoomOut = useCallback(
    () => dispatch(setZoomLevel(zoomLevel - 0.1)),
    [dispatch, zoomLevel],
  );

  const handleZoomIn = useCallback(
    () => dispatch(setZoomLevel(zoomLevel + 0.1)),
    [dispatch, zoomLevel],
  );

  const handleResetZoom = useCallback(
    () => dispatch(setZoomLevel(initialPDFRendererState.zoomLevel)),
    [dispatch],
  );

  const handleRotateСounterСlockwise = useCallback(
    () => dispatch(setRotationDegree(rotationDegree - 90)),
    [dispatch, rotationDegree],
  );

  const handleRotateСlockwise = useCallback(
    () => dispatch(setRotationDegree(rotationDegree + 90)),
    [dispatch, rotationDegree],
  );

  const handleTogglePaginated = useCallback(
    () => dispatch(setPDFPaginated(!paginated)),
    [dispatch, paginated],
  );

  const currentDocument = mainState?.currentDocument || null;

  return (
    <Container>
      <Box sx={{ display: 'flex' }}>
        {paginated && numPages > 1 && <PDFRendererPagination />}
      </Box>

      <Box sx={{ display: 'flex', gap: 3 }}>
        <ControlButton onClick={handleZoomOut}>
          <Tooltip title="Уменьшить масштаб">
            <ZoomOutIcon />
          </Tooltip>
        </ControlButton>

        <ControlButton onClick={handleZoomIn}>
          <Tooltip title="Увеличить масштаб">
            <ZoomInIcon />
          </Tooltip>
        </ControlButton>

        <ControlButton
          disabled={zoomLevel === initialPDFRendererState.zoomLevel}
          onClick={handleResetZoom}
        >
          <Tooltip title="Сбросить масштаб">
            <ZoomOutMapIcon />
          </Tooltip>
        </ControlButton>

        <Divider flexItem orientation="vertical" />

        <ControlButton onClick={handleRotateСounterСlockwise}>
          <Tooltip title="Повернуть против часовой стрелки">
            <RotateLeftIcon />
          </Tooltip>
        </ControlButton>

        <ControlButton onClick={handleRotateСlockwise}>
          <Tooltip title="Повернуть по часовой стрелке">
            <RotateRightIcon />
          </Tooltip>
        </ControlButton>

        {numPages > 1 && (
          <>
            <Divider flexItem orientation="vertical" />
            <ControlButton onClick={handleTogglePaginated}>
              <Tooltip
                title={
                  `${paginated ? 'Отключить' : 'Включить'} постраничный вывод`
                }
              >
                {paginated ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
              </Tooltip>
            </ControlButton>
          </>
        )}

        {currentDocument?.fileData && (
          <>
            <Divider flexItem orientation="vertical" />
            <ControlButton
              component="a"
              download="document.pdf"
              href={currentDocument?.uri}
            >
              <Tooltip title="Скачать документ">
                <DownloadIcon />
              </Tooltip>
            </ControlButton>
          </>
        )}
      </Box>
    </Container>
  );
};

const Container = ({ children, ...rest }) => (
  <Box
    {...rest}
    sx={{
      display: 'flex',
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 1,
      justifyContent: 'space-between',
      p: { xs: 1, md: 2 },
      backgroundColor: 'rgba(240, 240, 240, 0.95)',
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
    }}
  >
    {children}
  </Box>
);

const ControlButton = ({ children, ...rest }) => (
  <IconButton {...rest} color="primary" size="small">
    {children}
  </IconButton>
);