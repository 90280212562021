import React from 'react';

import { Box } from '@material-ui/core';
import { Router, Redirect } from '@reach/router';

import IncomingDocuments from './IncomingDocuments';
import ReceiptToWarehouse from '../receiptToWarehouse'
import Shop from '../shop';
import ShopOrders from '../shopOrders';

export default function WorkspacesRoutes() {
  return (
    <Box component={Router} sx={{ height: '100%' }}>
      <IncomingDocuments path="/incoming-documents" />
      <Redirect exact from="/" to="incoming-documents" />
      <Shop path="/shop/*" />
      <ReceiptToWarehouse path="/receipt-to-warehouse/*" />
      <ShopOrders path="/shop/orders/*" />
    </Box>
  );
}