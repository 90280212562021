import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import logo from 'assets/fssp.svg';
import { DateTime } from 'luxon';

import FormattedCurrency from 'components/FormattedCurrency';

export function FsspHeader({ lastFinishedCheck = {} }) {
  const { finishedAt, unpaidSum } = lastFinishedCheck;

  function Message() {
    if (!finishedAt) {
      return '';
    }

    const NoDebt = (
      <Box bgcolor="success.main" borderRadius={4} color="white" px={2} py={1}>
        {/* FIXME: to locales */}
        <Typography variant="h4">
          Задолженность на{' '}
          <Box color="white" component="span" fontWeight={700}>
            {DateTime.fromISO(finishedAt).toFormat('d MMMM yyyy')}
          </Box>{' '}
          <Box color="white" component="span">
            не найдена
          </Box>
        </Typography>
      </Box>
    );
    const DebtExist = (
      <>
        <Typography>
          {/* FIXME: to locales */}
          Задолженность на{' '}
          <Box component="span" fontWeight={500}>
            {DateTime.fromISO(finishedAt).toFormat('d MMMM yyyy')}
          </Box>
        </Typography>
        <Box mr={2} />
        <Typography color={unpaidSum ? 'error' : undefined} variant="h2">
          {unpaidSum ? <FormattedCurrency value={unpaidSum} /> : '---'}
        </Typography>
      </>
    );

    return unpaidSum ? DebtExist : NoDebt;
  }

  return (
    <Box alignItems="center" display="flex" mb={3} py={2}>
      <Box alt="Logo" component="img" height={40} mr={2} src={logo} />
      <Box alignItems="baseline" display="flex" flexGrow={1}>
        {/* FIXME: to locales */}
        <Typography variant="h3">ФССП</Typography>
        <Box flexGrow={1} mr={2} />
        <Message />
      </Box>
    </Box>
  );
}

FsspHeader.propTypes = {
  lastFinishedCheck: PropTypes.shape({
    finishedAt: PropTypes.string,
    unpaidSum: PropTypes.number,
  }),
};
