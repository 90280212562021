import { gql } from '@apollo/client';

export const BANK_STATEMENT_SET_CONTRACT_CATEGORY = gql`
  mutation BankStatementSetContractorCategory(
    $contractorId: ID!,
    $category: String!
  ) {
    bankStatementSetContractorCategory(
      contractorId: $contractorId,
      category: $category
    ) {
      contractor {
        category
        id
      }
    }
  }
`;