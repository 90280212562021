/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import { PDFRendererContext } from '../state';
import { setCurrentPage } from '../state/actions';

export const PDFRendererPagination = () => {
  const {
    dispatch,
    state: { currentPage, numPages },
  } = useContext(PDFRendererContext);

  return (
    <Container>
      <PageNavButtonPrevious
        disabled={currentPage === 1}
        onClick={() => dispatch(setCurrentPage(currentPage - 1))}
      >
        <Tooltip title="Предыдущая страница">
          <SkipPreviousIcon />
        </Tooltip>
      </PageNavButtonPrevious>

      <PageTag>Страница {currentPage} / {numPages}</PageTag>

      <PageNavButtonNext
        disabled={currentPage >= numPages}
        onClick={() => dispatch(setCurrentPage(currentPage + 1))}
      >
        <Tooltip title="Следующая страница">
          <SkipNextIcon />
        </Tooltip>
      </PageNavButtonNext>
    </Container>
  );
};

const Container = ({ children, ...rest }) => (
  <Box {...rest} sx={{ display: 'flex', alignItems: 'center' }}>
    {children}
  </Box>
);

const PageNavButtonPrevious = ({ children, ...rest }) => (
  <IconButton {...rest} color="primary" size="small" sx={{ mx: 2 }}>
    {children}
  </IconButton>
);

const PageNavButtonNext = ({ children, ...rest }) => (
  <PageNavButtonPrevious {...rest}>
    {children}
  </PageNavButtonPrevious>
);

const PageTag = ({ children, ...rest }) => (
  <Box {...rest} sx={{ fontSize: { xs: 10, md: 14 } }}>
    {children}
  </Box>
);